import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { MoveObjectiveDialogComponent } from "../move-objective-dialog/move-objective-dialog.component";
import { ObjectivesService } from "../objectives.service";
import { ObjectivesRouteService } from "../objectives-route.service";
import { ObjectivesUiService } from "../objectives-ui.service";

@Component({
    selector: "adapt-objective-actions",
    templateUrl: "./objective-actions.component.html",
})
export class ObjectiveActionsComponent extends BaseComponent implements OnChanges {
    @Input() public objective!: Objective;
    @Output() public objectiveDeleted = new EventEmitter<Objective>();

    private menuItemTemplate?: TemplateRef<any>;
    @ViewChild("itemTemplate") public set menuItemTemplateSetter(template: TemplateRef<any> | undefined) {
        this.menuItemTemplate = template;
        this.setMenu();
    }

    public menuItems: IAdaptMenuItem[] = [];
    public previousSubscription?: Subscription;

    public hasActiveTeamWithObjectiveFeature = true;

    constructor(
        private objectivesUiService: ObjectivesUiService,
        private objectivesService: ObjectivesService,
        private objectivesRouteService: ObjectivesRouteService,
        private commonDialogService: AdaptCommonDialogService,
        private rxjsBreezeService: RxjsBreezeService,
        teamsService: CommonTeamsService,
        featuresService: FeaturesService,
    ) {
        super();

        this.rxjsBreezeService.entityTypeChanged(Team).pipe(
            startWith(undefined),
            switchMap(() => teamsService.promiseToGetAllActiveTeams()),
            map((activeTeams) => activeTeams.filter((team) => featuresService.isFeatureActive(FeatureName.StewardshipObjectives, team))),
            this.takeUntilDestroyed(),
        ).subscribe((activeObjTeams) => {
            const hasActiveTeams = activeObjTeams.length > 0;
            if (hasActiveTeams !== this.hasActiveTeamWithObjectiveFeature) {
                this.hasActiveTeamWithObjectiveFeature = hasActiveTeams;
                this.setMenu();
            }
        });
    }

    public ngOnChanges() {
        this.previousSubscription?.unsubscribe();

        this.previousSubscription = this.rxjsBreezeService.entityPropertyChanged(this.objective).pipe(
            startWith(undefined),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.setMenu();
        });
    }

    public setMenu() {
        this.menuItems = [];
        const items: IAdaptMenuItem[] = [];

        // edit
        items.push({
            text: "Edit objective",
            icon: "fal fa-fw fa-folder-open",
            onClick: () => {
                this.objectivesUiService.editObjective(this.objective).pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((objective) => this.objectivesService.emitObjectiveUpdate(objective));
            },
        });

        // set status
        if (this.objective.status !== ObjectiveStatus.Closed) {
            const subItems: IAdaptMenuItem[] = [];
            ObjectiveStatusMetadata.All
                .filter((x) => x.status !== ObjectiveStatus.Closed && x.status !== this.objective.status)
                .forEach((i) => {
                    subItems.push({
                        templateRef: this.menuItemTemplate,
                        templateData: i,
                        onClick: () => this.setStatus(i.status),

                    });
                });

            items.push({
                closeMenuOnClick: false,
                text: "Change status to",
                icon: "fal fa-fw fa-tag",
                items: subItems,
            });
        }

        // close/re-open Objective
        if (this.objective.status !== ObjectiveStatus.Closed) {
            items.push({
                beginGroup: true,
                icon: "fal fa-fw fa-archive",
                text: "Close objective",
                onClick: this.closeObjective,
            });
        } else if (this.objective.status === ObjectiveStatus.Closed) {
            items.push({
                beginGroup: true,
                icon: "fal fa-fw fa-inbox-out",
                text: "Re-Open objective",
                onClick: () => this.setStatus(ObjectiveStatus.OnTrack),
            });
        }

        if (this.hasActiveTeamWithObjectiveFeature) {
            items.push({
                beginGroup: true,
                text: "Move objective",
                icon: "fal fa-fw fa-share",
                onClick: () => {
                    this.commonDialogService.open(MoveObjectiveDialogComponent, this.objective).subscribe();
                },
            });
        }

        // Duplication
        items.push({
            text: "Duplicate objective",
            icon: "fal fa-fw fa-clone",
            onClick: this.duplicateObjective,
        });

        // delete
        items.push({
            beginGroup: true,
            text: "Delete objective",
            icon: "fal fa-fw fa-trash-alt",
            onClick: () => {
                this.objectivesUiService.promptToDeleteObjective(this.objective)
                    .subscribe(() => {
                        this.objectiveDeleted.emit(this.objective);
                        this.objectivesService.emitObjectiveUpdate(this.objective);
                    });
            },
        });

        // root
        this.menuItems.push({
            icon: MenuComponent.SmallRootMenu.icon,
            items,
        });
    }

    @Autobind
    private closeObjective() {
        const confirmationDialog: IConfirmationDialogData = {
            title: "Close objective",
            message: "Are you sure you want to close this objective? This will hide the objective in the standard objective tree and list views.",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        };

        this.commonDialogService.openConfirmationDialog(confirmationDialog).pipe(
            switchMap(() => {
                this.objective.status = ObjectiveStatus.Closed;
                return this.objectivesService.saveEntities([this.objective]);
            }),
            catchError((err) => {
                this.objective!.entityAspect.rejectChanges();
                return this.commonDialogService.showErrorDialog("Error Closing Objective", ErrorHandlingUtilities.getHttpResponseMessage(err));
            }),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.objectivesService.emitObjectiveUpdate(this.objective));
    }

    private setStatus(status: ObjectiveStatus) {
        this.objective.status = status;
        this.objectivesService.saveEntities([this.objective]).pipe(
            catchError((err) => {
                this.objective!.entityAspect.rejectChanges();
                return this.commonDialogService.showErrorDialog("Error Updating Objective", ErrorHandlingUtilities.getHttpResponseMessage(err));
            }),
        ).subscribe(() => {
            this.objectivesService.emitObjectiveUpdate(this.objective);
        });
    }

    @Autobind
    private duplicateObjective() {
        if (this.objective.entityAspect.entityState.isAddedModifiedOrDeleted() ||
            this.objective.comments.some((o) => o.entityAspect.entityState.isModified())) {
            return this.commonDialogService.showErrorDialog("Error duplicating Objective", "Please save your changes before attempting to duplicate this objective").subscribe();
        }

        const dueDateText = this.objective.type === ObjectiveType.Annual ? "1 year" : "3 months";
        return this.commonDialogService.openConfirmationDialog({
            title: "Duplicate objective",
            message: `<p>The objective
            <b>${this.objective.title}</b>
            will be duplicated with the following changes:</p>
            <ul>
                <li>Parent objective relationship will be inherited</li>
                <li>Description and assignee will be copied</li>
                <li>Creation date will be set to the current time and due date will be set to ${dueDateText} later</li>
                <li>Key results, labels, related objective and item links will be copied</li>
                <li>Status will be changed to "To Do"</li>
            </ul>
            <p>The following will NOT be copied:</p>
            <ul>
                <li>Comments</li>
                <li>Child objectives</li>
                <li>Key result values</li>
            </ul>
            <p>Please confirm that you want to duplicate the objective and redirect to the duplicated objective after the save.</p>`,
            confirmButtonPreset: "duplicateAndRedirect",
        } as IConfirmationDialogData).pipe(
            switchMap(() => this.objectivesService.cloneAndSaveObjective(this.objective)),
            switchMap((newObjective) => this.objectivesRouteService.gotoEditObjectivePageRoute(newObjective.objectiveId, newObjective.teamId)),
        ).subscribe();
    }
}

<adapt-dialog [maxWidth]="900">
    <h3 adapt-dialog-title>{{isNew ? "Add" : "Edit"}} {{inputTypeLabel}}</h3>
    <div adapt-dialog-content
         class="h-100">
        <div class="d-flex flex-column flex-lg-row">
            <div class="left-column d-block pe-3">
                <adapt-styled-implementation-kit [articleId]="articleId"
                                                 contentStyle="embedded"></adapt-styled-implementation-kit>
            </div>

            <div class="d-flex flex-column mt-4 mt-lg-0 right-column">
                <label class="mt-3">{{inputTypeLabel}}</label>
                <dx-text-box [(value)]="input.name"
                             [maxLength]="60"
                             [adaptValidateEntity]="input"
                             adaptValidateEntityProperty="name"></dx-text-box>

                <label class="mt-3">Description</label>
                <adapt-html-editor [(contents)]="input.description"
                                   [minHeight]="300"
                                   forceSize="compact"></adapt-html-editor>
            </div>
        </div>
    </div>
    <div adapt-dialog-footer
         class="d-flex w-100">
        <button adaptButton="delete"
                *ngIf="!isNew"
                [adaptBlockingClick]="deleteInput"></button>
        <adapt-standard-dialog-buttons [saveIsDisabled]="input.entityAspect.hasValidationErrors"
                                       [saveBlockingClick]="saveAndClose"
                                       (cancelClick)="cancel()"
                                       cancelText="Cancel">
        </adapt-standard-dialog-buttons>
    </div>
</adapt-dialog>

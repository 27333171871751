import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IConfigGroup, IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";
import { allPersonalConfigGroups } from "./personal-configuration-page-elements";

@Component({
    selector: "adapt-personal-configuration-page",
    templateUrl: "./personal-configuration-page.component.html",
})
export class PersonalConfigurationPageComponent extends BaseRoutedComponent implements OnInit {
    public ConfigArea = ConfigArea;
    public configGroups: IConfigGroup[] = [];
    public selectedItem?: IConfigItem;

    public constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit() {
        this.configGroups = allPersonalConfigGroups();
        this.notifyActivated();
    }
}

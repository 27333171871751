import { Component, Inject } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { ObjectivesRouteService } from "../objectives-route.service";

@Component({
    selector: "adapt-move-objective-dialog",
    templateUrl: "./move-objective-dialog.component.html",
    styleUrls: ["./move-objective-dialog.component.scss"],
})
export class MoveObjectiveDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Objective> {
    public readonly dialogName = "MoveObjectiveDialog";

    public originTeam?: Team;
    public makeOrganisationObjective?: boolean | null = false;

    constructor(
        private objectiveRouteService: ObjectivesRouteService,
        private featuresService: FeaturesService,
        @Inject(ADAPT_DIALOG_DATA) public objective: Objective,
    ) {
        super();

        this.originTeam = objective.team;
        objective.team = undefined;
        this.autoResolveData = objective;
    }

    public get entitiesToConfirm() {
        return [this.objective];
    }

    public saveIsDisabled() {
        if (!this.makeOrganisationObjective && ObjectUtilities.isNullOrUndefined(this.objective.team)) {
            return true;
        }

        return false;
    }

    public onTeamChange() {
        if (this.makeOrganisationObjective && this.objective.team) {
            this.makeOrganisationObjective = false;
        }
    }

    public onMakeOrgObjectiveChange() {
        if (this.objective.team && this.makeOrganisationObjective) {
            this.objective.team = undefined;
        }
    }

    public save() {
        from(super.saveAndClose()).pipe(
            switchMap(() => this.objectiveRouteService.gotoObjectivePageRoute(this.objective.teamId, ObjectiveViewType.ObjectiveTreeView)),
        ).subscribe();
    }

    @Autobind
    public hasObjectivesEnabled(team: Team) {
        return this.featuresService.isFeatureActive(FeatureName.StewardshipObjectives, team) && team !== this.originTeam;
    }
}

import { DefaultCancelTourButton, DefaultFinishButtons, DefaultNextButtons, GuidedTour, nextStep } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export const AttachBullseyeStatementsTour: GuidedTour = [{
    title: "Introduction",
    text: `<p>This tour will walk you through the process of attaching bullseye inputs to the strategy board.</p>
        <p>If you already know how to do it, you can cancel this tour.</p>`,
    buttons: [DefaultCancelTourButton, {
        classes: "btn btn-primary",
        text: "Proceed",
        action: nextStep,
    }],
}, {
    title: "Attaching bullseye inputs to a zone",
    text: `<p>To attach bullseye inputs to a zone, use the corresponding action item in the menu.</p>
        <p><b>Click on it now</b> to reveal the associated options.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="zone-action-menu-EconomicEngine"]`,
    attachTo: {
        on: "left",
    },
    advanceOnAttachedElementEvent: "click",
    modalOverlayOpeningPadding: 3,
}, {
    title: "Attach bullseye inputs dialog",
    text: `<p>This is the menu item which will display the action dialog to attach bullseye inputs to the zone.</p>
        <p>This is not clickable during the tour as it would disrupt the flow. You can try interacting with it later, after completing the tour.</p>
        <p><b>Click 'Next' to continue</b>.</p>`,
    waitForAndAttachToElementSelector: ".dx-menu-item",
    elementSelectorTextContent: "Attach Bullseye Inputs",
    advanceOn: { selector: "body", event: "click" }, // clicking on anywhere else will make the menu goes away - move onto the next step
    attachTo: {
        on: "left",
    },
    canClickTarget: false, // have to prevent attached element from being clickable or the menu item will disappear and the guided will lose the attach handle
    buttons: DefaultNextButtons,
}, {
    title: "Attaching bullseye inputs to a theme",
    text: `<p>Besides attaching bullseye inputs to a zone, you can also attach bullseye inputs to a strategic theme.</p>
        <p>This can be done through a similar action from the menu here.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="theme-action-menu"]`,
    attachTo: {
        on: "bottom",
    },
    modalOverlayOpeningPadding: 3,
    canClickTarget: false,
    buttons: DefaultNextButtons,
    showOn: () => {
        // this step will only be shown if Organisation Diagnostic node is not there
        const themeMenu = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="theme-action-menu"]`);
        return GuidedTourUtils.isElementVisible(themeMenu);
    },
}, {
    title: "End of attach bullseye inputs tour",
    text: `<p>This concludes the tour to attach bullseye inputs to your strategy board.</p>
        <p>Now, proceed to attach bullseye inputs to your preferred zone or theme.</p>`,
    buttons: DefaultFinishButtons,
}];

import { DOCUMENT, NgClass } from "@angular/common";
import { AfterContentChecked, AfterContentInit, Component, ElementRef, Inject, OnChanges, OnDestroy, OnInit, Self } from "@angular/core";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { Options as SortablejsOptions, SortableEvent } from "sortablejs";
import { ILayoutColumn } from "../layout.interface";
import { LayoutService } from "../layout.service";
import { LayoutBaseComponent } from "../layout-base.component";
import { LayoutManagerComponent } from "../layout-manager/layout-manager.component";

@Component({
    selector: "adapt-layout-column",
    templateUrl: "./layout-column.component.html",
    styleUrls: ["./layout-column.component.scss"],
    providers: [NgClass],
})
export class LayoutColumnComponent extends LayoutBaseComponent<ILayoutColumn> implements OnInit, OnChanges, OnDestroy, AfterContentInit, AfterContentChecked {
    public layoutColumn!: ILayoutColumn;
    public sortableOptions: SortablejsOptions = {};

    // store the size of an item in a column
    // used to restore original size when moving item between columns
    private sizeInColumn: { [k: string]: (string | undefined)[] } = {};

    constructor(
        @Self() protected ngClass: NgClass,
        protected elementRef: ElementRef<HTMLElement>,
        protected layoutManager: LayoutManagerComponent,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(ngClass, elementRef, layoutManager);
    }

    public get layoutEntity() {
        return this.layoutColumn;
    }

    public get columnIsEmpty() {
        return this.layoutEntity?.options.components.every((cmp) => !cmp.options?.enabled) ?? true;
    }

    public get columnIsVisible() {
        return !this.columnIsEmpty || (this.layoutEntity?.options.manuallyEnabled ?? false);
    }

    public ngOnInit() {
        this.layoutColumn = this.layoutManager.getColumn(
            this,
            this.elementRef.nativeElement.querySelector(`.sortable`)!,
            this.defaultSize,
        );

        this.sortableOptions = {
            animation: 150,
            disabled: !this.isEditing,
            handle: ".move-component",
            draggable: "adapt-layout-component:not(.d-none):not([style*='display: none'])",
            group: this.layoutManager.groupKey,
            onChange: (e) => this.onSortableChange(e),
            onStart: (e) => this.onSortableChange(e),
            onEnd: (e) => this.onSortableEnd(e),
            forceFallback: (!LocalStorage.get("layoutFallback")) ?? true,
            scroll: true,
            bubbleScroll: true,
            revertOnSpill: true,
        };

        this.ngOnChanges();
    }

    public ngAfterContentInit() {
        // add all components to this column in the order they appear in the DOM
        const components = Array.from(this.elementRef.nativeElement.querySelectorAll<HTMLElement>("adapt-layout-component"));
        this.layoutManager.resolvePendingComponents(components, this.layoutColumn);
    }

    public ngAfterContentChecked() {
        // this makes sure any components added after initial load will also be added to the layout
        this.ngAfterContentInit();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        this.layoutManager.removeColumn(this.layoutEntity);
    }

    public handleSortableJsInit() {
        this.updateSortableEditingState();
    }

    public ngOnChanges() {
        this.customHostClasses = {
            "overflow-auto": this.layoutManager.fullPage,
            "h-md-100": !this.isEditing && this.layoutManager.fullPage,
        };

        this.updateSortableEditingState();

        super.ngOnChanges();
    }

    private updateSortableEditingState() {
        this.sortableOptions = {
            ...this.sortableOptions,
            disabled: !this.isEditing,
        };
    }

    private onSortableChange(e: SortableEvent) {
        this.sortableRemoveDropTargets();
        e.to.classList.add("drop-target");

        const layoutColumns = this.layoutManager.layout.columns;
        const { component } = LayoutService.findComponentInLayout(layoutColumns, e.item.id);

        if (component) {
            const srcColumn = this.getColumnFromElement(e.from)!;
            const destColumn = this.getColumnFromElement(e.to)!;

            const srcColIdx = layoutColumns.indexOf(srcColumn);
            const destColIdx = layoutColumns.indexOf(destColumn);

            if (!Array.isArray(this.sizeInColumn[component.id])) {
                this.sizeInColumn[component.id] = [];
            }

            // store the current size of this component in the current column
            this.sizeInColumn[component.id][srcColIdx] = component.size;

            if (destColumn.size !== srcColumn.size) {
                // if the columns are different sizes then set the component size to what we previously stored
                // if there is no size stored, the size will reset
                LayoutService.setSize(component, this.sizeInColumn[component.id][destColIdx]);
                this.sizeInColumn[component.id][destColIdx] = component.size;
            }

            component.ref?.onUpdate(component);
        }
    }

    private onSortableEnd(e: SortableEvent) {
        // remove focus so the move button deactivates
        const moveButton = e.item.querySelector(".move-component");
        if (moveButton) {
            (moveButton as HTMLElement).blur();
        }

        this.sortableRemoveDropTargets();

        const component = this.getComponentFromElement(e.item);
        const destColumn = this.getColumnFromElement(e.to);

        if (component && destColumn) {
            this.layoutManager.moveComponent(component, this.layoutEntity, destColumn, e.newDraggableIndex!);
            this.layoutManager.updateLayout();
        }
    }

    private sortableRemoveDropTargets() {
        this.document.querySelectorAll("adapt-layout-column > .drop-target").forEach((target) => target.classList.remove("drop-target"));
    }

    private getComponentFromElement(element: HTMLElement) {
        return this.layoutEntity.options.components.find((cmp) => cmp.element === element);
    }

    private getColumnFromElement(element: HTMLElement) {
        return this.layoutManager.layout.columns.find((col) => col.element === element);
    }
}


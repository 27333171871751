<div class="about-controller">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h2>Build Details</h2>
                </div>
                <div class="card-body">
                    <p>Information about the version of {{projectLabel}} currently running.</p>
                    <table class="table-striped table-hover table-bordered">
                        <tr>
                            <td width="200"><b>Build Date</b></td>
                            <td>
                                {{buildDetails.buildDate}}
                            </td>
                        </tr>
                        <tr>
                            <td><b>Build Time</b></td>
                            <td>{{buildDetails.buildTime}}</td>
                        </tr>
                        <tr>
                            <td><b>Source Tag</b></td>
                            <td>{{buildDetails.sourceTag}}</td>
                        </tr>
                        <tr>
                            <td><b>Source Date</b></td>
                            <td>{{buildDetails.sourceDate}}</td>
                        </tr>
                        <tr>
                            <td><b>Source Branch</b></td>
                            <td>{{buildDetails.sourceBranch}}</td>
                        </tr>
                        <tr>
                            <td><b>Source Hash</b></td>
                            <td>{{buildDetails.sourceHash}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h2>Diagnostics</h2>
                </div>
                <div class="card-body">
                    <div>
                        <h3>Clear browser file cache</h3>
                        <p>You may need to do this if images are not loading.</p>
                        <a class="btn btn-primary"
                           target="_blank"
                           href="{{storageUri}}/ClearCache">Click here to clear cache</a>
                    </div>
                    <hr>
                    <div>
                        <h3>Send information to the ADAPT Team which will assist in diagnosing
                            your issue</h3>
                        <button class="btn btn-primary"
                                (click)="sendDiagnosticInformation()">
                            Send Diagnostic Information to ADAPT
                        </button>
                    </div>
                    <hr>
                    <div>
                        <h3>Remove all non-critical data stored by ADAPT which may be causing
                            you issues</h3>
                        <button class="btn btn-warning"
                                (click)="clearAdaptData()">
                            Clear local ADAPT data
                        </button>
                    </div>
                    <hr>
                    <div>
                        <h3>Clear ALL local data, and force you to login again.</h3>
                        <button class="btn btn-danger"
                                (click)="clearAllLocalData()">
                            Clear ALL local data
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h2>Attributions</h2>
                </div>
                <div class="card-body">
                    Icon's made by
                    <a href="https://www.freepik.com/"
                       target="_blank"
                       rel="noreferrer noopener"
                       title=Freepik>Freepik</a>,
                    <a href="https://www.flaticon.com/authors/ddara"
                       target="_blank"
                       rel="noreferrer noopener"
                       title=dDara>dDara</a>,
                    <a href="https://www.flaticon.com/authors/monkik"
                       target="_blank"
                       rel="noreferrer noopener"
                       title=monkik>monkik</a>
                    and
                    <a href="https://www.flaticon.com/authors/smartline"
                       target="_blank"
                       rel="noreferrer noopener"
                       title=Smartline>Smartline</a>
                    from
                    <a href="https://www.flaticon.com/"
                       target="_blank"
                       rel="noreferrer noopener"
                       title=Flaticon>www.flaticon.com</a>
                </div>
            </div>
        </div>
    </div>
</div>

import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SessionStorage } from "@common/lib/storage/session-storage";
import { Observable, of, throwError } from "rxjs";
import { catchError, finalize, map, tap, timeout } from "rxjs/operators";
import { AdaptClientConfiguration, AdaptEnvironment } from "../configuration/adapt-client-configuration";
import { ErrorHandlingUtilities } from "../lib/utilities/error-handling-utilities";
import { cacheLatest } from "../lib/utilities/rxjs-utilities";
import { ImplementationKitArticle } from "./implementation-kit-article.enum";

export interface IHelpJuiceArticle {
    id: number;
    answer: string;
    answer_sample: string;
    name: string;
    url: string;
}

export const SupportUrlHome = "https://support.adaptbydesign.com.au";
export const SupportTimeoutInMs = 10_000;

@Injectable({
    providedIn: "root",
})
export class ImplementationKitService {
    private static articleCache = new Map<string, IHelpJuiceArticle>();
    private static articleFetchObservables = new Map<string, Observable<IHelpJuiceArticle>>();
    private static questionFragment = "/_questions/";
    private readonly BaseUrl = SupportUrlHome + "/api/questions";
    private readonly ImplementationKitClassName = "ik-content";

    public constructor(
        private httpClient: HttpClient,
        @Inject(DOCUMENT) document: Document,
    ) {
        // For non production builds, we inject some simple HTML that indicates to the user that the content comes from Helpjuice.
        // It also gives the person easy access to the Helpjuice article edit link and a way to stop this injection (e.g. for screenshots/demos).
        if (AdaptClientConfiguration.AdaptEnvironment !== AdaptEnvironment.Production) {
            const observer = new MutationObserver((mutationList, _observer) => {
                const ikElement = mutationList.find((mutation) => ((mutation.target as HTMLElement).firstChild as HTMLElement)?.classList?.contains(this.ImplementationKitClassName));
                if (ikElement) {
                    const helpJuiceArticleHideKey = "HideHJArticleEditLink";
                    if (!SessionStorage.containsKey(helpJuiceArticleHideKey)) {
                        const ikContent = ikElement.target.firstChild! as HTMLElement;

                        const editingElement = document.createElement("div");
                        editingElement.className = "helpjuice-editing-link d-flex justify-content-between";
                        editingElement.innerHTML = `<b>During testing phase, this content is editable -
                                    <a href="https://adaptbydesign.helpjuice.com/admin/questions/${ikContent.dataset.articleId}"
                                    target="_blank">[Edit It]</a></b>`;

                        const link = document.createElement("a");
                        link.className = "btn btn-link";
                        link.innerHTML = "[Hide This]";
                        link.onclick = (e: MouseEvent) => {
                            (e.target as HTMLElement).parentElement!.className = "d-none";
                            if (confirm("Hide all subsequent HelpJuice hints? (Ok = Yes, Cancel = No)") === true) {
                                SessionStorage.set(helpJuiceArticleHideKey, 1);
                            }
                        };
                        editingElement.appendChild(link);

                        ikContent.prepend(editingElement);
                    }
                }
            });
            observer.observe(document.body, { childList: true, subtree: true });
        }
    }

    public static GetArticleLink(articleId: ImplementationKitArticle) {
        return SupportUrlHome + ImplementationKitService.questionFragment + articleId;
    }

    public getArticle(articleId: ImplementationKitArticle) {
        const article = ImplementationKitService.articleCache.get(articleId);
        if (!article) {
            // reuse an existing fetch observable if it exists so we don't make multiple requests
            const existingObservable = ImplementationKitService.articleFetchObservables.get(articleId);
            if (existingObservable) {
                return existingObservable;
            }

            const fullUrl = `${this.BaseUrl}/${articleId}`;
            const requestObservable = this.httpClient.get<IHelpJuiceArticle>(fullUrl).pipe(
                timeout({
                    each: SupportTimeoutInMs,
                    with: () => throwError(() => new Error("Timeout fetching article")),
                }),
                catchError((err) => throwError(() => new Error(ErrorHandlingUtilities.getHttpResponseMessage(err)))),
                map((data) => {
                    const element = document.createElement("div");
                    element.innerHTML = data.answer;
                    element.className = this.ImplementationKitClassName;
                    element.dataset.articleId = articleId;
                    this.parseArticleContent(element, articleId);
                    data.answer = element.outerHTML;
                    return data;
                }),
                tap((data) => ImplementationKitService.articleCache.set(articleId, data)),
                cacheLatest(),
                finalize(() => ImplementationKitService.articleFetchObservables.delete(articleId)),
            );
            ImplementationKitService.articleFetchObservables.set(articleId, requestObservable);
            return requestObservable;
        } else {
            return of(article);
        }
    }

    private parseArticleContent(element: HTMLElement, _articleId: string) {
        // convert the HJ alerts to use the standard bootstrap alerts (so we get our standard alert styling)
        const alerts = Array.from(element.querySelectorAll(".hj-alert-block.hj-info-block"));
        for (const alert of alerts) {
            alert.className = "alert alert-info";
        }

        // remove all styling on the HJ data definitions (their glossary popups)
        const dataDefinitions: HTMLElement[] = Array.from(element.querySelectorAll("*[data-definition]"));
        for (const dataDefinition of dataDefinitions) {
            dataDefinition.style.cssText = "";
        }

        // add allow=fullscreen to iframes
        const iframes = Array.from(element.querySelectorAll("iframe"));
        for (const iframe of iframes) {
            iframe.allow = "fullscreen";
            iframe.allowFullscreen = true;
        }

        // change relative path for HJ questions to be an absolute path
        const links = Array.from(element.querySelectorAll("a"));
        for (const link of links) {
            const h = link.getAttribute("href");
            if (h?.startsWith(ImplementationKitService.questionFragment)) {
                link.setAttribute("href", SupportUrlHome + h);
                link.setAttribute("target", "_none");
            }
        }

        // inserted article fixups (these are where an article includes/inserts an entirely different article)
        // we are just hiding the HJ content and adding a link to the article which will open in a new tab
        const insertedArticles: HTMLElement[] = Array.from(element.querySelectorAll(".inserted-article"));
        for (const insertedArticle of insertedArticles) {

            // hide all the HJ DOM
            insertedArticle.style.display = "none";

            const bodyDiv = insertedArticle.querySelector("div");
            if (bodyDiv) {
                // find the first fragment, and insert the new <a>
                const fragments = Array.prototype.slice.call(bodyDiv.getElementsByClassName("article-insert-fragment"));
                for (const fragment of fragments) {
                    const fragmentArticleId = fragment.className.substring(fragment.className.indexOf("insert-name-") + 12);

                    const link = document.createElement("a");
                    link.innerHTML = fragment.innerHTML;
                    link.setAttribute("href", SupportUrlHome + ImplementationKitService.questionFragment + fragmentArticleId);
                    link.setAttribute("target", "_none");

                    const para = document.createElement("p");
                    para.innerHTML = link.outerHTML;

                    // insert our new fragments before the existing article
                    insertedArticle.parentNode!.insertBefore(para, insertedArticle);
                    break;
                }
            }
        }
    }
}

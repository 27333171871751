<adapt-dialog [maxWidth]="900">
    <h3 adapt-dialog-title>Reordering Anchors</h3>
    <div adapt-dialog-content
         class="h-100">
        <div class="alert alert-light">
            You can rearrange the existing strategic anchors by dragging the handle to the right with each item.
        </div>

        <dx-list *ngIf="anchors.length > 0; else noAnchor"
                 [activeStateEnabled]="false"
                 [hoverStateEnabled]="false"
                 [focusStateEnabled]="false"
                 [dataSource]="anchors"
                 (onItemReordered)="reorderAnchors($event)">
            <dxo-item-dragging [data]="anchors"
                               [allowReordering]="true"></dxo-item-dragging>
            <div *dxTemplate="let anchor of 'item'">
                <adapt-strategic-anchor [anchor]="anchor"
                                        [isEditing]="false"
                                        [isCollapsible]="false"
                                        [collapsed]="true"></adapt-strategic-anchor>
            </div>
        </dx-list>

        <ng-template #noAnchor>
            <div class="alert alert-warning">There are no anchors to reorder</div>
        </ng-template>
    </div>
    <div adapt-dialog-footer
         class="d-flex w-100">
        <adapt-standard-dialog-buttons [saveIsDisabled]="hasNoChanges"
                                       [saveBlockingClick]="saveAndClose"
                                       (cancelClick)="cancel()"
                                       cancelText="Cancel">
        </adapt-standard-dialog-buttons>
    </div>
</adapt-dialog>

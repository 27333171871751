import { Injector } from "@angular/core";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { UserService } from "@common/user/user.service";
import { DirectorySharedService } from "../../directory-shared/directory-shared.service";
import { analyseEngagementSurveyPageRoute } from "../analyse-survey-page/analyse-survey-page.route";
import { SurveyDetails } from "../survey-details";
import { ISurveyTypeUtils } from "../survey-type-utils.interface";
import { EmployeeEngagementConstants } from "./employee-engagement.constants";
import { EmployeeEngagementQuestions } from "./employee-engagement-questions";
import { manageEmployeeEngagementsPageRoute } from "./manage-employee-engagements-page/manage-employee-engagements-page.route";

export class EmployeeEngagementUtils implements ISurveyTypeUtils {
    // this will get overwritten by the corresponding project module constructor using
    // - SurveyService.registerLearnMoreUrlForSurveyType()
    // If that's not called, the CTA simply won't show the learn more button.
    public learnMoreUrl = "";

    public singularParticipant = "employee";
    public pluralParticipant = "employees";
    public analyseTargetLabel = "";
    public responseGroups = [SurveyResponseGroup.All];
    public surveyDescription = "The Employee Engagement survey provides a way to measure all employees' engagement. Your anonymous input will help the leaders better understand the organisations' strengths and weaknesses.";
    public goodPercentageThreshold = EmployeeEngagementConstants.EngagedPercentageThreshold;

    public get resultCategories() {
        return EmployeeEngagementConstants.Categories;
    }

    public filterQuestionResponse() {
        return true;
    }

    public getManageSurveyPageRoute$() {
        return manageEmployeeEngagementsPageRoute.getRoute();
    }

    public gotoManageSurveyPageRoute$() {
        return manageEmployeeEngagementsPageRoute.gotoRoute();
    }

    public getAnalyseSurveyPageRoute$(surveyId?: number) {
        return analyseEngagementSurveyPageRoute.getRouteObject(undefined, { surveyId });
    }

    public gotoAnalyseSurveyPageRoute$(surveyId?: number) {
        return analyseEngagementSurveyPageRoute.gotoRoute(undefined, { surveyId });
    }

    public getSurveyQuestions(surveyDetails: SurveyDetails) {
        return new EmployeeEngagementQuestions(surveyDetails);
    }

    public convertScoreToPercentage(score: number) {
        return (score - 1) * 100 / (EmployeeEngagementConstants.MaxScore - 1);
    }

    public getStatusColorFromPercentage(percentage: number) {
        if (percentage >= EmployeeEngagementConstants.HighlyEngagedPercentageThreshold) {
            return EmployeeEngagementConstants.HighlyEngagedColor;
        } else if (percentage >= EmployeeEngagementConstants.EngagedPercentageThreshold) {
            return EmployeeEngagementConstants.EngagedColor;
        } else if (percentage >= EmployeeEngagementConstants.DisengagedPercentageThreshold) {
            return EmployeeEngagementConstants.DisengagedColor;
        } else {
            return EmployeeEngagementConstants.HighlyDisengagedColor;
        }
    }

    public getStatusBackgroundFromPercentage(percentage: number) {
        if (percentage >= EmployeeEngagementConstants.HighlyEngagedPercentageThreshold) {
            return EmployeeEngagementConstants.HighlyEngagedBackgroundColor;
        } else if (percentage >= EmployeeEngagementConstants.EngagedPercentageThreshold) {
            return EmployeeEngagementConstants.EngagedBackgroundColor;
        } else if (percentage >= EmployeeEngagementConstants.DisengagedPercentageThreshold) {
            return EmployeeEngagementConstants.DisengagedBackgroundColor;
        } else {
            return EmployeeEngagementConstants.HighlyDisengagedBackgroundColor;
        }
    }

    public getResultCategoryChartTooltipText(count: number, totalResponses: number, resultCategoryText: string) {
        return `${count} out of ${totalResponses} employees are categorised as ${resultCategoryText}`;
    }

    public getScoreGrade(percentage: number) {
        for (const resultCategory of EmployeeEngagementConstants.Categories) {
            if (percentage >= resultCategory.percentageThreshold) {
                return resultCategory.name;
            }
        }

        return "No Grade";
    }

    public async getPotentialSurveyInvitees(injector: Injector) {
        const connection = await injector.get(DirectorySharedService).promiseToGetActiveConnectionsByPredicate();
        const currentPersonId = injector.get(UserService).getCurrentPersonId();
        return connection.filter((c) => c.connectionType === ConnectionType.Employee && c.personId !== currentPersonId);
    }
}

import { DefaultCancelTourButton, DefaultFinishButtons, DefaultNextButtons, GuidedTour, nextStep } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { HasAnnualObjectiveFunction, HasNoAnnualObjectiveFunction } from "./objectives-wrapup-tour";

export const HasAddKeyResultButton = () => {
    const addKeyResultButton = GuidedTourUtils.getFirstElementWithTextContent(`button[title="Add key result"]`);
    return GuidedTourUtils.isElementVisible(addKeyResultButton);
};

export const CreateKeyResultsForAnnualObjectiveTour: GuidedTour = [{
    title: "Create key results for annual objectives",
    text: `<p>This tour will guide you through the process of creating key results for annual objectives on adapt HQ.</p>
        <p>If you already know how to do it, you can cancel this tour now.</p>`,
    buttons: [DefaultCancelTourButton, {
        classes: "btn btn-primary",
        text: "Proceed",
        action: nextStep,
    }],
}, {
    title: "Navigate to objective page",
    text: `<p>To create key results for an objective, you will need to navigate to the objective page first.</p>
        <p>This can be accomplished by clicking on the corresponding objective link here.</p>
        <p><b>Click on it now</b> to navigate to the objective page.</p>`,
    waitForAndAttachToElementSelector: `.objective-type[title="Annual Objective"] ~ a`,
    attachTo: {
        on: "bottom",
    },
    advanceOnAttachedElementEvent: "click",
    waitForPageActivationAfterEvent: true,
    showOn: HasAnnualObjectiveFunction,
}, {
    title: "Add key result",
    text: `<p>Click on this button to add a key result.</p>
        <p>Please do so after completing the tour to avoid interrupting its flow.</p>`,
    waitForAndAttachToElementSelector: `button[title="Add key result"]`,
    canClickTarget: false,
    attachTo: {
        on: "left",
    },
    showOn: HasAddKeyResultButton,
    buttons: DefaultNextButtons,
}, {
    title: "Return to objectives page",
    text: `<p>You can return to the previous objectives page using the navigation breadcrumbs here.</p>
         <p><b>Click on it now</b> to navigate back to the objectives page.</p>`,
    waitForAndAttachToElementSelector: "adapt-navigation-breadcrumbs adapt-display-navigation-node span",
    elementSelectorTextContent: "Objectives",
    advanceOnAttachedElementEvent: "click",
    waitForPageActivationAfterEvent: true,
    attachTo: {
        on: "bottom",
    },
    showOn: HasAddKeyResultButton,
}, {
    title: "Return to meeting",
    text: `<p>After you have finished creating the key results for your annual objectives, click here to return to the pathway meeting.</p>`,
    waitForAndAttachToElementSelector: `[id="sidebar-meeting"]`,
    canClickTarget: false,
    attachTo: {
        on: "right",
    },
    buttons: DefaultNextButtons,
    showOn: HasAnnualObjectiveFunction,
}, {
    title: "End of create key results tour",
    text: `<p>This concludes the tour to create key results for annual objectives on adapt HQ.</p>`,
    buttons: DefaultFinishButtons,
    showOn: HasAnnualObjectiveFunction,
}, {
    title: "No annual objectives",
    text: `<p>There are no annual objectives which are supposed to be defined in the previous step.</p>
        <p>Please return to the meeting to create some annual objectives before proceeding.</p>`,
    buttons: DefaultFinishButtons,
    showOn: HasNoAnnualObjectiveFunction,
}];

<ng-container *ngIf="(node$ | async) as node">
    <a *ngIf="(url$ | async) as url; else noUrl"
       class="navigation-node-text"
       (click)="onNodeClicked(node)"
       [ngClass]="{'navigation-node-link': !!url}"
       [routerLink]="url?.path"
       [queryParams]="url?.queryParams"
       [state]="currentState">
        <ng-container *ngTemplateOutlet="showNodeContent"></ng-container>
    </a>
    <ng-template #noUrl>
        <span class="navigation-node-text">
            <ng-container *ngTemplateOutlet="showNodeContent"></ng-container>
        </span>
    </ng-template>
    <ng-template #showNodeContent>
        <span *ngIf="node?.iconPositionRight && node?.title as title"
              [ngClass]="{'me-1': !isInBreadcrumb}">{{title}}</span>
        <i *ngIf="(!isInBreadcrumb || !node?.hideIconInBreadcrumb) && node?.iconClass as iconClass"
           [ngClass]="iconClass + (isInBreadcrumb ? ' me-1' : '')"></i>
        <span *ngIf="!node?.iconPositionRight && node?.title as title">{{title}}</span>
    </ng-template>
</ng-container>

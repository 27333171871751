import { FroalaActionUtilities, IRangeOffset } from "@common/ux/html-editor/html-editor-froala/froala-action-utilities";
import FroalaEditor from "froala-editor";
import { lastValueFrom, switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { KanbanUiService } from "../kanban-ui.service";
import { itemPageRoute } from "./item-page/item-page.route";
import { ISelectItemDialogResolveData } from "./select-item-dialog/select-item-dialog.component";

export class ItemLinkingFroalaAction {

    public static registerAction(kanbanUiService: KanbanUiService) {
        // There is no angular way of doing this - has to call $.FroalaEditor
        FroalaEditor.DefineIcon("insertEmbedLink", { NAME: "at" });
        FroalaEditor.RegisterShortcut(FroalaEditor.KEYCODE.E, "insertEmbedLink", null, "E");
        FroalaEditor.RegisterCommand("insertEmbedLink", {
            title: "Insert embedADAPT Link",
            focus: true,
            undo: false,
            refreshAfterCallback: false,
            callback: selectDocLink,
        });

        function selectDocLink() {
            const editor: FroalaEditor = this; // from the callback context, this is the editor (not getting passed through argument!)

            if (!editor) {
                // without editor instance, we can do nothing on the editor!
                // - this happens if we try to trigger link without link in the toolbar!
                return;
            }

            editor.events.disableBlur();
            const anchorNode = editor.selection.element();
            const range = editor.selection.ranges(0) as Range;
            const offsets: IRangeOffset = {
                startOffset: 0,
                endOffset: 0,
            };
            if (range) {
                offsets.startOffset = range.startOffset;
                offsets.endOffset = range.endOffset;
            }
            // hide all popups or it will look weird having 2 active dialogs with ours popup later
            editor.popups.hideAll();

            // need to save selection here as after the dialog is closed, the cursor can be moved anywhere!
            editor.selection.save();

            kanbanUiService.openItemLinkDialog().pipe(
                switchMap(async (result: ISelectItemDialogResolveData) => {
                    if (result.item) {
                        // inserting a link using: https://www.froala.com/wysiwyg-editor/docs/methods#link.insert
                        const item = result.item;
                        const route = await lastValueFrom(itemPageRoute.getRoute(item));
                        const text = item.code + ": " + item.summary;

                        editor.link.insert(route, text, { target: "_blank" });

                        const linkElement = editor.selection.element();
                        if (linkElement) {
                            editor.selection.setAfter(linkElement);
                            editor.selection.restore();
                        }
                    } else {
                        FroalaActionUtilities.restoreCursor(editor, anchorNode, offsets);
                    }
                }),
                tap(() => editor.events.enableBlur()),
            ).subscribe();
        }
    }
}

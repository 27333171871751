import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ProgressEvent as FileUploaderProgressEvent, UploadedEvent, UploadErrorEvent, ValueChangedEvent } from "devextreme/ui/file_uploader";
import { IdentityService } from "../../identity/identity.service";
import { Autobind } from "../../lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "../../lib/utilities/error-handling-utilities";
import { BaseComponent } from "../../ux/base.component/base.component";
import { StorageImageService } from "../storage-image.service";

interface IUploadHeaders {
    Accept: string;
    Authorization: string;
}

@Component({
    selector: "adapt-select-image",
    templateUrl: "./select-image.component.html",
    styleUrls: ["./select-image.component.scss"],
})
export class SelectImageComponent extends BaseComponent implements OnInit, OnChanges {
    private static defaultResizeWidth = 120;
    private static defaultResizeHeight = 120;

    @Input() public id!: string;

    @Input() public imageIdentifier?: string;
    @Output() public imageIdentifierChange = new EventEmitter<string>();

    /** Width of image to to display */
    @Input() public imageWidth?: number | string;
    /** Height of image to to display */
    @Input() public imageHeight?: number | string;
    /** Image source to show when image identifier is not given */
    @Input() public defaultImageSrc?: string;
    /** Don't allow selecting a new image */
    @Input() public disabled = false;
    /** Width to resize image to on upload */
    @Input() public resizeWidth = 0;
    /** Height to resize image to on upload */
    @Input() public resizeHeight = 0;
    /** Apply inline editing styles to component */
    @Input() public inline = false;

    public imageDataUrl?: string;

    public uploadMode?: string;
    public uploadMethod?: string;
    public uploadUrl?: string;
    public uploadHeaders?: IUploadHeaders;

    public uploading = false;
    public progress = 0;

    public error?: string;

    public constructor(
        private storageImageService: StorageImageService,
        private identityService: IdentityService,
    ) {
        super();
    }

    public ngOnInit() {
        this.setUploadUrl();
        this.setUploadHeaders();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.imageHeight) {
            this.setImageHeight();
        }

        if (changes.imageWidth) {
            this.setImageWidth();
        }

        if (changes.imageIdentifier) {
            this.setUploadUrl();
        }
    }

    public get imageStyle() {
        return {
            "max-height": this.buildDimension(this.imageHeight),
            "max-width": this.buildDimension(this.imageWidth),
        };

    }

    private buildDimension(dimension: number | string | undefined) {
        if (!dimension) {
            return "";
        }

        return typeof dimension === "number"
            ? `${dimension}px`
            : dimension;
    }

    public get dialogTrigger() {
        return this.imageIdentifier || this.defaultImageSrc ? `#storage-image_${this.id}` : `#upload-trigger_${this.id}`;
    }

    @Autobind
    private async setUploadHeaders() {
        const token = await this.identityService.promiseToGetAccessToken();

        this.uploadHeaders = {
            Accept: "application/json, text/plain, */*",
            Authorization: "Bearer " + token,
        };

    }

    @Autobind
    private setUploadUrl() {
        const resizeSizes = {
            maxWidth: SelectImageComponent.defaultResizeWidth,
            maxHeight: SelectImageComponent.defaultResizeHeight,
        };

        // If even if only one is set, set them both as we want to scale only to that one dimension
        // And we need to set it to zero for the server to ignore that dimension.
        // e.g. resizeHeight should be 120, set resizeWidth to 0 so that it can be any width to match a
        // height of 120.
        if (this.resizeHeight > 0 || this.resizeWidth > 0) {
            resizeSizes.maxWidth = this.resizeWidth;
            resizeSizes.maxHeight = this.resizeHeight;
        }

        if (this.imageIdentifier) {
            this.uploadUrl = this.storageImageService.getReplaceImageUri(
                this.imageIdentifier,
                undefined,
                resizeSizes.maxWidth,
                resizeSizes.maxHeight,
            );
            this.uploadMethod = "PUT";
        } else {
            this.uploadUrl = this.storageImageService.getStoreImageUri(
                undefined,
                resizeSizes.maxWidth,
                resizeSizes.maxHeight,
            );
            this.uploadMethod = "POST";
        }
    }

    @Autobind
    private setImageHeight() {
        this.imageHeight = this.imageHeight
            ? this.imageHeight
            : this.imageWidth
                ? undefined
                : 60;
    }

    @Autobind
    private setImageWidth() {
        this.imageWidth = this.imageWidth
            ? this.imageWidth
            : this.imageHeight
                ? undefined
                : 60;
    }

    @Autobind
    public onUploadStarted() {
        this.uploading = true;
    }

    @Autobind
    public onProgress(e: FileUploaderProgressEvent) {
        this.progress = e.bytesLoaded / e.bytesTotal * 100;
    }

    @Autobind
    public onValueChanged(e: ValueChangedEvent) {
        // is the File API supported?
        const supported = !!(window.File && window.FileReader && window.FileList && window.Blob);

        if (!supported || !e.value?.length) {
            return;
        }

        const image = e.value[0];

        const fileReader = new FileReader();
        fileReader.onload = (progressEvent: ProgressEvent) => {
            this.imageDataUrl = ((progressEvent.target! as FileReader).result as string);
        };

        fileReader.readAsDataURL(image);
    }

    @Autobind
    public onUploaded(e: UploadedEvent) {
        if (!this.imageIdentifier) {
            const response = JSON.parse(e.request.response);
            this.imageIdentifier = Object.keys(response)[0];
            this.imageIdentifierChange.emit(this.imageIdentifier);
            this.setUploadUrl();
        }

        this.storageImageService.updateImage(this.imageIdentifier);

        this.uploading = false;
    }

    @Autobind
    public onUploadError(e: UploadErrorEvent) {
        const message = ErrorHandlingUtilities.getHttpResponseMessage(e.request);

        if (message) {
            this.error = message;
        }
    }

    public showRealImage() {
        this.imageDataUrl = undefined;
    }
}

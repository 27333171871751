<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <p>
            This operation will convert the user type of {{dialogData.connection.person.fullName}} from
            <b>{{UserTypeExtensions.singularLabel(existingUserType)}}</b>
            to <b>{{UserTypeExtensions.singularLabel(dialogData.userType)}}</b>.
        </p>

        The implications for this change are:
        <ul class="list-content">
            <li *ngIf="priceDecrease">The monthly cost will <b>decrease</b> for this user</li>
            <li *ngIf="!priceDecrease">The monthly cost will <b>increase</b> for this user</li>

            <li *ngIf="changingToFullAccess">This user's permissions can be fully customised</li>
            <li *ngIf="!changingToFullAccess">This user's permissions will be set to the standard
                <b>{{UserTypeExtensions.singularLabel(this.dialogData.userType)}}</b> access level</li>
            <li *ngIf="noCulturalLeadershipFrameworkAccess">This user will no longer have access to cultural leadership framework
                and any existing cultural cohort led by them will be disbanded</li>

            <ng-container *ngIf="changeToNone">
                <li>This user will be removed from all teams he is participating in</li>
                <li *ngIf="isTier1Leader">This user can no longer lead any key function or value stream</li>
            </ng-container>
        </ul>

        <div class="mt-2"
             *ngIf="dialogData.userType === UserType.Leader || dialogData.userType === UserType.Collaborator">
            <label>Set the access level {{dialogData.connection.person.fullName}} will have:</label>
            <adapt-select-access-level [(accessLevel)]="selectedAccessLevel"
                                       [userType]="dialogData.userType"
                                       (accessLevelChange)="onAccessLevelChange()"
                                       [filter]="roleFilter"
                                       data-test="select-access-level"></adapt-select-access-level>
        </div>

        <div class="bottom-confirmation">
            <dx-check-box [(value)]="confirmed"
                          text="Check to confirm that you understand the implications for user access and billing"
                          data-test="confirm-checkbox"></dx-check-box>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!confirmed || !hasUnsavedEntity || !isInitialised || !isValid"
                                   saveText="Convert & Close"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

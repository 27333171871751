import { EventSeriesType } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventSeriesDayOfWeekMetadata } from "@common/ADAPT.Common.Model/organisation/event-series-day-of-week";
import { EventSeriesWeekIndex, EventSeriesWeekIndexMetadata } from "@common/ADAPT.Common.Model/organisation/event-series-week-index";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import moment from "moment";
import { IScheduleRecurrenceConfig, IScheduleRecurrenceField } from "./schedule-recurrence/schedule-recurrence.interface";

const dateField: IScheduleRecurrenceField = {
    label: "date",
    eventSeriesField: "startDate",
    width: 80,
    choices: [],
};

const weekIndexField: IScheduleRecurrenceField = {
    label: "week",
    eventSeriesField: "weekIndex",
    width: 80,
    choices: EventSeriesWeekIndexMetadata.All.map((weekIndex) => ({
        text: weekIndex.name,
        value: weekIndex.weekIndex,
    })),
};

const dayOfWeekField: IScheduleRecurrenceField = {
    label: "day",
    eventSeriesField: "dayOfWeek",
    choices: EventSeriesDayOfWeekMetadata.All.map((dayOfWeek) => ({
        text: dayOfWeek.name,
        value: dayOfWeek.dayOfWeek,
    })),
};

const monthField: IScheduleRecurrenceField = {
    label: "month",
    eventSeriesField: "month",
    prefix: "of",
    choices: moment.months().map((month, idx) => ({
        text: month,
        value: idx + 1,
    })),
    afterFieldUpdated: (obj) => {
        // update the month of the startDate to be the newly selected month
        if (obj.month) {
            obj.startDate = moment(obj.startDate)
                .month(obj.month - 1)
                .toDate();
        }
    },
};

export const EventSeriesDefaults: { [eventType in EventTypePreset]: IScheduleRecurrenceConfig } = {
    [EventTypePreset.AnnualStrategy]: {
        eventSeriesDefaults: {
            eventSeriesType: EventSeriesType.Once,
            interval: 1,
        },
        fields: [dateField],
    },
    [EventTypePreset.QuarterlyStrategy]: {
        eventSeriesDefaults: {
            eventSeriesType: EventSeriesType.RelativeMonthly,
            interval: 3,
        },
        fields: [
            { ...weekIndexField, prefix: "3 months on the" },
            dayOfWeekField,
            { ...monthField, prefix: "starting" },
        ],
        getStartDate: (presetRecurrences) => {
            const annualStrategy = presetRecurrences.get(EventTypePreset.AnnualStrategy);
            if (annualStrategy) {
                return moment(annualStrategy.eventSeries.startDate)
                    .date(1)            // start of the month
                    .add(3, "months")   // 3 months after AS
                    .toDate();
            }
            return undefined;
        },
    },
    [EventTypePreset.MonthlyStrategy]: {
        eventSeriesDefaults: {
            eventSeriesType: EventSeriesType.RelativeMonthly,
            interval: 1,
        },
        fields: [
            weekIndexField,
            { ...dayOfWeekField, suffix: "of every month" },
            { ...monthField, prefix: " starting" },
        ],
        skipEventTypeConflict: [EventTypePreset.QuarterlyStrategy],
        getStartDate: (presetRecurrences) => {
            const annualStrategy = presetRecurrences.get(EventTypePreset.AnnualStrategy);
            if (annualStrategy) {
                return moment(annualStrategy.eventSeries.startDate)
                    .date(1) // start of the month
                    .add(1, "month")
                    .toDate();
            }
            return undefined;
        },
    },
    [EventTypePreset.OKRCheckIn]: {
        eventSeriesDefaults: {
            eventSeriesType: EventSeriesType.RelativeMonthly,
            interval: 1,
            // offset by 2 weeks from monthly
            weekIndex: EventSeriesWeekIndex.Third,
        },
        fields: [
            weekIndexField,
            { ...dayOfWeekField, suffix: "of every month" },
            { ...monthField, prefix: " starting" },
        ],
        getStartDate: (presetRecurrences) => {
            // start same time as AS
            const annualStrategy = presetRecurrences.get(EventTypePreset.AnnualStrategy);
            if (annualStrategy) {
                return annualStrategy.eventSeries.startDate;
            }
            return undefined;
        },
    },
};

<adapt-dialog [maxWidth]="720">
    <h3 adapt-dialog-title>{{ isSystem ? "View" : (isNew ? "Add" : "Edit")}} Agenda Template</h3>
    <ng-container adapt-dialog-content>
        <h3>Template Name</h3>
        <dx-text-box class="mb-4"
                     [(value)]="template.name"
                     [readOnly]="isSystem"
                     placeholder="Enter a name"
                     [adaptValidateEntity]="template"
                     adaptValidateEntityProperty="name"
                     data-test="template-name-input"></dx-text-box>
        <h3>Agenda Items</h3>
        <adapt-edit-agenda [agendaTemplate]="template"
                           [hideNotesAndItems]="true"
                           [saveOnChange]="false"
                           (agendaItemsChange)="agendaItems = $event"></adapt-edit-agenda>
    </ng-container>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>

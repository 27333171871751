import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { StrategicGoalsService } from "@org-common/lib/strategic-goals/strategic-goals.service";
import { StrategicInputsService } from "@org-common/lib/strategic-inputs/strategic-inputs.service";
import { StrategyService } from "@org-common/lib/strategy/strategy.service";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { StrategicViewIcon, StrategicViewOption } from "@org-common/lib/strategy/strategy-view-constants";
import { BehaviorSubject, forkJoin, switchMap } from "rxjs";
import { StrategyZoneComponent } from "../strategy-zone/strategy-zone.component";

interface IStrategyBoardViewOption {
    option: StrategicViewOption;
    iconClass: string;
    text: string;
    tour?: string;
}

@Component({
    selector: "adapt-strategy-board",
    templateUrl: "./strategy-board.component.html",
    styleUrls: ["./strategy-board.component.scss"],
})
export class StrategyBoardComponent extends BaseComponent implements OnChanges {
    public readonly StrategicViewOption = StrategicViewOption;
    public readonly FullLayoutBreakpoint = Breakpoint.XXL;

    @Input() public isEditing = false;
    @Input() public expandGoals = false;

    @Input() public showToolbarMenu = false;
    @Input() public views = [StrategicViewOption.SWTInputs, StrategicViewOption.CAInputs, StrategicViewOption.Bullseye, StrategicViewOption.Goals];
    @Output() public viewsChange = new EventEmitter<StrategicViewOption[]>();

    public hasEditAccess = false;
    public hasTheme = false;
    public readonly BullseyeViewOption = {
        option: StrategicViewOption.Bullseye,
        iconClass: StrategicViewIcon.BullseyeIcon,
        text: "Bullseye",
    };
    public readonly SWTInputsViewOption = {
        option: StrategicViewOption.SWTInputs,
        iconClass: StrategicViewIcon.InputsCanvasIcon,
        text: "SWT Inputs",
    };
    public readonly CAInputsViewOptions = {
        option: StrategicViewOption.CAInputs,
        iconClass: StrategicViewIcon.CompetitorAnalysisIcon,
        text: "Competitor Analysis",
    };
    public readonly GoalsViewOption = {
        option: StrategicViewOption.Goals,
        iconClass: StrategicViewIcon.GoalIcon,
        text: "Goals",
    };

    public themeViewOption: IStrategyBoardViewOption = {
        option: StrategicViewOption.Themes,
        iconClass: StrategicViewIcon.ThemeDescriptionIcon,
        text: "Theme Descriptions",
        tour: "themes-descriptions",
    };

    public viewOptions: IStrategyBoardViewOption[] = [this.themeViewOption];

    public zoneMenuItemsMap?: { [zone in Zone]: IAdaptMenuItem[] };

    public expandGoalsItem: IAdaptMenuItem = {
        text: "Expand All Goals",
        icon: "fal fa-chevrons-down",
        onClick: () => {
            this.expandGoals = true;
            this.detectChanges();
        },
    };
    public collapseGoalsItem: IAdaptMenuItem = {
        text: "Collapse All Goals",
        icon: "fal fa-chevrons-up",
        onClick: () => {
            this.expandGoals = false;
            this.detectChanges();
        },
    };
    public menuItems: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [this.expandGoalsItem, this.collapseGoalsItem],
    }];

    public viewToggleButtonsTemplate?: TemplateRef<HTMLElement> = undefined;
    private viewToggleButtonsTemplateUpdater = this.createThrottledUpdater<TemplateRef<HTMLElement>>((value) => this.viewToggleButtonsTemplate = value);

    @ViewChild("viewToggleButtons")
    public set viewToggleButtons(value: TemplateRef<HTMLElement>) {
        this.viewToggleButtonsTemplateUpdater.next(value);
    }

    @ViewChildren(StrategyZoneComponent) private zoneList?: QueryList<StrategyZoneComponent>;

    private updater$ = new BehaviorSubject<void>(undefined);

    public constructor(
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
        private authorisationService: AuthorisationService,
        private strategyService: StrategyService,
        private inputsService: StrategicInputsService,
        private bullseyeService: BullseyeService,
        private goalsService: StrategicGoalsService,
    ) {
        super(elementRef);

        this.updater$.pipe(
            switchMap(() => strategyService.getAllThemes()),
            this.takeUntilDestroyed(),
        ).subscribe((themes) => {
            this.hasTheme = themes.length > 0;
            this.isInitialised = true;
        });

        this.updater$.pipe(
            switchMap(() => forkJoin([
                this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditStrategyBoard),
                PromiseUtilities.fromObject({
                    [Zone.EconomicEngine]: this.createMenuItemsForZone(Zone.EconomicEngine),
                    [Zone.OrganisationDesign]: this.createMenuItemsForZone(Zone.OrganisationDesign),
                    [Zone.HealthyCulture]: this.createMenuItemsForZone(Zone.HealthyCulture),
                    [Zone.FinanceAndOwnership]: this.createMenuItemsForZone(Zone.FinanceAndOwnership),
                    [Zone.ResearchAndDevelopment]: Promise.resolve([]),
                }),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([hasEditAccess, zoneMenus]) => {
            this.hasEditAccess = hasEditAccess;
            this.zoneMenuItemsMap = zoneMenus;
        });

        rxjsBreezeService.entityTypeChanged(Theme).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updater$.next());
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.views && this.views) {
            // make sure we have the valid views populated so that the below check works
            this.viewOptions = await this.getViewOptions();

            // need to check if the view is valid
            const filteredViews = this.views.filter((view) => !!this.viewOptions.find((option) => option.option === view));
            if (filteredViews.length !== this.views.length) {
                // do it next digest cycle as this comes in after view checked, which would otherwise causes ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    this.views = filteredViews;
                    this.viewsChange.emit(this.views);
                });
            }
        }
    }

    private async getViewOptions() {
        const viewOptions = [this.themeViewOption];

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategicInputs)) {
            viewOptions.push(this.SWTInputsViewOption);
            viewOptions.push(this.CAInputsViewOptions);
        }

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadBullseye)) {
            viewOptions.push(this.BullseyeViewOption);
        }

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategicGoals)) {
            viewOptions.push(this.GoalsViewOption);
        }

        return viewOptions;
    }

    public detectChanges() {
        this.zoneList?.forEach((zone: StrategyZoneComponent) => zone.detectChanges());
    }

    public isViewSelected(view: StrategicViewOption) {
        return this.views.includes(view);
    }

    public toggleView(view: StrategicViewOption) {
        if (this.isViewSelected(view)) {
            ArrayUtilities.removeElementFromArray(view, this.views);
        } else {
            this.views.push(view);
        }

        // this is to toggle ngOnChanges for zones
        this.views = [...this.views];
        this.viewsChange.emit(this.views);
    }

    public getMenuItemsForZone(zone: Zone) {
        // can't use ZoneMenuItemsMap directly in template as we cannot explicit type the let-zone context
        // - wrapping around a function like this is fine.
        return this.zoneMenuItemsMap?.[zone];
    }

    private async createMenuItemsForZone(zone: Zone) {
        const result = [{
            icon: MenuComponent.SmallRootMenu.icon,
            items: [
                this.strategyService.getAddStrategicThemeMenuItem(zone),
            ],
        }];

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditStrategicInputs)) {
            result[0].items.push(this.inputsService.getAttachSWTInputMenuItem(zone));
            result[0].items.push(this.inputsService.getAttachCompetitorAnalysisInputMenuItem(zone));
        }

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditBullseye)) {
            result[0].items.push(this.bullseyeService.getAttachBullseyeStatementMenuItem(zone));
        }

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditStrategicGoals)) {
            result[0].items.push(this.goalsService.getAddStrategicGoalMenuItem(zone));
        }

        return result;
    }
}

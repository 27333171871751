import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { Value } from "@common/ADAPT.Common.Model/organisation/value";
import { ValuesConstitution } from "@common/ADAPT.Common.Model/organisation/values-constitution";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buttonPreset } from "@common/ux/button/button-preset";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "../workflow-identifier.enum";

export const createValueWorkflowName = "Create a value";

export interface ICreateValueData {
    value?: Value;
    valuesConstitution?: ValuesConstitution;
}

export const createValueWorkflow = buildLocalWorkflow({
    name: createValueWorkflowName,
    workflowId: WorkflowIdentifier.CreateValue,
    type: WorkflowType.Workflow,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    featuresToEnable: [FeatureName.CompassValuesConstitution],
    showBreadcrumbs: true,
    showBreadcrumbTitles: true,
    private: true,
    isStateless: true,
    steps: [
        {
            name: "Name of the value",
            ordinal: 0,
            allowBack: false,
            canSkip: false,
            articleSlug: ImplementationKitArticle.CreateValueName,
            componentSelector: "adapt-create-value-name",
            articlePosition: WorkflowStepGuidancePosition.Left,
        },
        {
            name: "Meaning of the value",
            ordinal: 1,
            allowBack: true,
            canSkip: false,
            articleSlug: ImplementationKitArticle.CreateValueMeaning,
            componentSelector: "adapt-create-value-meaning",
            articlePosition: WorkflowStepGuidancePosition.Left,
        },
        {
            name: "Behaviours of the value",
            ordinal: 2,
            allowBack: true,
            canSkip: false,
            articleSlug: ImplementationKitArticle.CreateValueBehaviours,
            componentSelector: "adapt-create-value-behaviours",
            articlePosition: WorkflowStepGuidancePosition.Left,
        },
        {
            name: "Review",
            ordinal: 3,
            allowBack: true,
            canSkip: false,
            articleSlug: ImplementationKitArticle.CreateValueReview,
            componentSelector: "adapt-create-value-review",
            workflowStepNextText: buttonPreset.saveAndClose.buttonText,
            workflowStepNextIcon: `${buttonPreset.saveAndClose.iconClass} fa-fw`,
            articlePosition: WorkflowStepGuidancePosition.Left,
        },
    ],
});

import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { GridBase, GridBaseOptions } from "devextreme/common/grids";
import { InitializedEvent } from "devextreme/ui/data_grid";
import { DxGridWrapper } from "./dx-grid-wrapper";
import { DxStatefulGridWrapper } from "./dx-stateful-grid-wrapper";

export class DxGridWrapperHelper {
    public readonly queuedGrid = new QueuedCaller<DxGridWrapper>();

    /** Set this on the stateStoring DX grid option before the grid has initialised */
    public readonly stateStoringOptions = {
        enabled: true,
        type: "custom", // can't just use localStorage type as we need to overwrite customLoad to only resolve after columns are ready
        customSave: this.customSave,
        customLoad: this.customLoad,
        ignoreColumnOptionNames: [],
    } as const;

    private keySuffix?: string;

    public constructor(
        private readonly componentId: string,
        private readonly element: JQuery<any>,
    ) { }

    /** Legacy method to allow backwards compatibility with baseDirectory-factory.
     * Use saveGridState instead and explicitely add the toolbar button if you want it.
     * @deprecated
     */
    public setupStateSavingOnOptions(gridOptions: GridBaseOptions<GridBase>, keySuffix?: string) {
        gridOptions.stateStoring = this.stateStoringOptions;
        gridOptions.onToolbarPreparing = this.addResetToolbarButtonIfOtherButtons;
        this.keySuffix = keySuffix ?? "all";
        return this;
    }

    /** Register this grid as one which saves the column states. You must call this before the grid initialises
     * as otherwise it will take no effect.
     */
    public saveGridState(keySuffix?: string) {
        if (this.queuedGrid.isSet) {
            throw new Error("Queued grid is probably being called in the wrong order");
        }

        this.keySuffix = keySuffix;
        return this;
    }

    /** Call this method in the onInitialized event of the grid.
     * @returns The wrapped grid instance
     */
    public initialiseGrid(e: InitializedEvent) {
        const grid = this.keySuffix
            ? new DxStatefulGridWrapper(this.componentId, this.element, e, this.componentId + this.keySuffix)
            : new DxGridWrapper(this.componentId, this.element, e);
        this.queuedGrid.setCallee(grid);
        return grid;
    }

    public callGrid(callback: (grid: DxGridWrapper) => void) {
        this.queuedGrid.call(callback);
    }

    @Autobind
    public addResetToolbarButtonIfOtherButtons(e: any) {
        // only add the revert button if there are already some buttons there; otherwise, it is
        // likely we are extracting the buttons into our own toolbar - not required to be added here
        if (e.toolbarOptions && Array.isArray(e.toolbarOptions.items) && e.toolbarOptions.items.length > 0) {
            this.addResetToolbarButton(e);
        }
    }

    /** Call this in the onToolbarPreparing event of the grid if you would like a button to reset the
     * state in the dxGridToolbar.
     */
    @Autobind
    public addResetToolbarButton(e: any) {
        e.toolbarOptions.items.push({
            widget: "dxButton",
            options: {
                icon: "revert",
                hint: "Reset Columns",
                onClick: () => this.callGrid((grid) => grid.resetState()),
            },
            location: "after",
        });
    }

    @Autobind
    private customSave(s: any) {
        return this.queuedGrid.promiseToCall((g) => {
            if (g instanceof DxStatefulGridWrapper) {
                g.onCustomSave(s);
            }
        });
    }

    @Autobind
    private customLoad() {
        return this.queuedGrid.promiseToCall((g) => {
            if (g instanceof DxStatefulGridWrapper) {
                return g.onCustomLoad();
            }
        });
    }
}

import { DefaultBackNextButtons, DefaultCancelTourButton, DefaultFinishButtons, DefaultNextButtons, GuidedTour, nextStep } from "@common/lib/guided-tour/guided-tour.service";

export const RecordQuarterlyObjectivesTour: GuidedTour = [{
    title: "Recording quarterly objectives",
    text: `<p>This tour will guide you through the process of recording quarterly objectives on adapt HQ.</p>
        <p>If you already know how to do it, you can cancel this tour now.</p>`,
    buttons: [DefaultCancelTourButton, {
        classes: "btn btn-primary",
        text: "Proceed",
        action: nextStep,
    }],
}, {
    title: "Add a new objective",
    text: `<p>To add a new objective on adapt HQ, click on the <i>Add Objective</i> button on the toolbar.</p>
    <p><b>Click on it now</b> to open the edit objective dialog.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="add-objective-button"]`,
    attachTo: {
        on: "bottom",
    },
    advanceOnAttachedElementEvent: "click",
    modalOverlayOpeningPadding: 3,
}, {
    title: "Enter a title for your objective",
    text: `<p>To begin, please enter a title for your objective here.</p>
        <p>Please do so after completing the tour to avoid interrupting its flow.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="objective-title-input"]`,
    canClickTarget: false,
    attachTo: {
        on: "bottom",
    },
    buttons: DefaultNextButtons,
}, {
    title: "Select the objective type",
    text: `<p>The default selection for the objective type is set to "Annual Objective".
        Therefore, you will need to change it to "Quarterly" for this task.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="quarterly-radio-input"]`,
    canClickTarget: false,
    attachTo: {
        on: "bottom",
    },
    buttons: DefaultBackNextButtons,
}, {
    title: "Assign a person for this objective",
    text: `<p>Here, you can select the person who will be responsible for this objective.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="objective-owner-dropdown"]`,
    canClickTarget: false,
    attachTo: {
        on: "left",
    },
    buttons: DefaultBackNextButtons,
}, {
    title: "Save the objective",
    text: `<p>These are the minimum entries required to define an objective.
        Once you have entered them, this save button will be enabled, allowing you to save your changes.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="standard-dialog-save-button"]`,
    canClickTarget: false,
    attachTo: {
        on: "top",
    },
    buttons: DefaultBackNextButtons,
}, {
    title: "Discard changes",
    text: `<p>To discard the changes made to the objective, simply click on this button.</p>
        <p>For this tour, as you have not entered anything yet. <b>Click on it now</b> to cancel.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="standard-dialog-cancel-button"]`,
    attachTo: {
        on: "top",
    },
    advanceOnAttachedElementEvent: "click",
}, {
    title: "End of add quarterly objectives tour",
    text: `<p>This concludes the tour to record quarterly objectives on adapt HQ.</p>`,
    buttons: DefaultFinishButtons,
}];

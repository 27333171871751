import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptBullseyeModule } from "@org-common/lib/bullseye/bullseye.module";
import { AdaptStrategicInputsModule } from "@org-common/lib/strategic-inputs/strategic-inputs.module";
import { AdaptStrategyModule } from "@org-common/lib/strategy/strategy.module";
import { AdaptCommonTeamsModule } from "@org-common/lib/teams/common-teams.module";
import { DxTextBoxModule } from "devextreme-angular";
import { GatherInputsCompetitorsAnalysisAgendaItemComponent } from "./gather-inputs-competitors-analysis-agenda-item/gather-inputs-competitors-analysis-agenda-item.component";
import { GatherInputsSWTAgendaItemComponent } from "./gather-inputs-swt-agenda-item/gather-inputs-swt-agenda-item.component";

@NgModule({
    declarations: [
        GatherInputsSWTAgendaItemComponent,
        GatherInputsCompetitorsAnalysisAgendaItemComponent,
    ],
    imports: [
        CommonModule,
        DxTextBoxModule,
        AdaptLoadingSpinnerModule,
        AdaptAuthorisationModule,
        AdaptImplementationKitModule,
        AdaptButtonModule,
        AdaptBullseyeModule,
        AdaptHtmlEditorModule,
        AdaptSeeMoreModule,
        AdaptCommonTeamsModule,
        AdaptStrategicInputsModule,
        AdaptStrategyModule,
    ],
})
export class AdaptGatherInputsWorkflowModule {
    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        GatherInputsSWTAgendaItemComponent,
        GatherInputsCompetitorsAnalysisAgendaItemComponent,
    ];
}

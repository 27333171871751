import { Component, ElementRef, Injector } from "@angular/core";
import { Measurement, MeasurementType } from "@common/ADAPT.Common.Model/organisation/measurement";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ChatAiService } from "@common/lib/chat-ai/chat-ai.service";
import { Logger } from "@common/lib/logger/logger";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { catchError, tap } from "rxjs";
import { EditStrategicGoalBaseComponent } from "../edit-strategic-goal-base/edit-strategic-goal-base.component";
import { StrategicGoalsService } from "../strategic-goals.service";

@WorkflowStepComponent("adapt-edit-goal-description")
@Component({
    selector: "adapt-edit-goal-description",
    templateUrl: "./edit-goal-description.component.html",
})
export class EditGoalDescriptionComponent extends EditStrategicGoalBaseComponent {
    public readonly GuidanceArticleId = ImplementationKitArticle.StrategicGoalDescriptionEdit;
    public placeholderText = "Type your strategic goal description";

    public targets: Measurement[] = [];

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        goalsService: StrategicGoalsService,
        private chatAiService: ChatAiService,
    ) {
        super(injector, elementRef, goalsService);
    }

    public workflowStepOnInit() {
        super.workflowStepOnInit();
        if (this.goal) {
            this.targets = this.goal.measurements
                .filter((i) => i.type === MeasurementType.Target);
            this.strategicGoalsService.orderMeasurementsByTimestamp(this.targets);
        }

        this.workflowStepCompleted.next(true); // description is optional so you can run through this step without doing anything
    }

    @Autobind
    public generateGoalDescription() {
        return this.chatAiService.generateGoalDescription(
            this.goal!.name,
            `${this.targets[0].value} ${this.goal!.unit ?? ""}`,
            `${this.targets[this.targets.length - 1].value} ${this.goal!.unit ?? ""}`,
        ).pipe(
            tap((response) => this.goal!.description = response),
            catchError((error) => {
                this.placeholderText = "Type your strategic goal description";
                Logger.getLogger("edit-goal-description").error(ErrorHandlingUtilities.getHttpResponseMessage(error.message));
                throw error;
            }),
            this.takeUntilDestroyed(),
        );
    }

    @Autobind
    public paraphrase() {
        if (this.goal?.description) {
            return this.chatAiService.paraphraseStatement(this.goal.description).pipe(
                tap((response) => this.goal!.description = response),
                this.takeUntilDestroyed(),
            );
        } else {
            return this.generateGoalDescription();
        }
    }
}

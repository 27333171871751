<div class="card objective-container mb-0">
    <div class="card-body objective-body"
         [class.has-progress]="hasBeenMeasured">
        <adapt-link-objective [objective]="objective"
                              [showIcon]="true"
                              [showStatus]="true"
                              [showStatusIcon]="false"></adapt-link-objective>
        <div class="d-flex flex-row align-items-center">
            <div class="me-2 objective-due-date">Due: {{objective.dueDate | adaptDate}}</div>
            <adapt-display-labels *ngIf="objective.labelLocations?.length"
                                  class="my-1"
                                  [labelLocations]="objective.labelLocations"></adapt-display-labels>
        </div>
    </div>

    <div *ngIf="hasBeenMeasured"
         class="card-footer objective-footer">
        <adapt-progress-bar [percentageProgress]="objectiveProgress"
                            [scaleHeight]="1.25"
                            colour="var(--adapt-open-blue-500)"
                            backgroundColour="#f5f5f5"></adapt-progress-bar>
    </div>
</div>

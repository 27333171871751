<div class="h-100"
     *adaptLoading="!goal">
    <div class="d-flex flex-column flex-lg-row">
        <div class="left-column d-block pe-3"
             *ngIf="guidanceArticleId">
            <adapt-strategic-guidance [articleId]="guidanceArticleId"
                                      area="strategic goals"></adapt-strategic-guidance>
        </div>
        <div class="flex-fill d-flex flex-column mt-4 mt-lg-0">
            <ng-content></ng-content>
        </div>
    </div>
</div>

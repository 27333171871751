<div *ngIf="hasUpcomingOrLiveMeeting"
     class="d-grid gap-3">
    <adapt-workflow-card-action *ngIf="meeting"
                                [workflow]="workflow"
                                [connection]="workflowConnection"
                                [disabled]="!!nonStartableMeetingInfo"
                                [disabledTooltip]="nonStartableMeetingInfo"
                                [action]="isLiveMeeting ? joinMeeting : runMeeting"
                                [actionText]="isLiveMeeting ? 'Join Meeting' : 'Run Meeting'"
                                actionIcon="fal fa-fw fa-arrow-right">
        <adapt-display-meeting-summary action-content
                                       [meeting]="meeting"
                                       [compact]="true"
                                       [showStartButton]="false"
                                       [showInfoIcon]="false"
                                       [linkDisabled]="true"></adapt-display-meeting-summary>
    </adapt-workflow-card-action>
</div>

<div *ngIf="!meeting">
    <adapt-workflow-card-action [workflow]="workflow"
                                [connection]="workflowConnection"
                                actionText="Schedule meeting">
        <div action-content>Meeting not scheduled</div>
    </adapt-workflow-card-action>
</div>


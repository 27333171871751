import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { ServiceUri } from "@common/configuration/service-uri";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { Logger } from "@common/lib/logger/logger";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { SetUtilities } from "@common/lib/utilities/set-utilities";
import { RouteService } from "@common/route/route.service";
import { UserService } from "@common/user/user.service";
import { DurationSelector } from "@common/ux/duration-selector";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import isEqual from "lodash.isequal";
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, ReplaySubject, Subject, throwError, timeout } from "rxjs";
import { catchError, debounceTime, filter, map, startWith, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { GuidanceType, IGuidanceResult, IKeyFunctionSearchResult, IProductServiceSearchResult, IPurposeVisionSearchResult, IResilientBusinessGoalSearchResult, ISearchApiParams, ISearchGroup, ISearchOptions, ISearchResults, ITier1Result, IValueSearchResult, IValueStreamSearchResult, SearchType } from "./search.interface";
import { searchPageRoute } from "./search-page.route";
import { SEARCH_PROVIDERS, SearchProvider } from "./search-provider";

// show slow search banner
export const SearchSlowTimeout = 5_000;
// report to sentry that search is slow
export const SearchSlowErrorTimeout = 10_000;
// server search took too long, cancel timeout
export const SearchTimeout = 20_000;

// we have extracted these out from the search service due to an interaction with Angular 15.1 (https://github.com/angular/angular/issues/48764) and TS (https://github.com/microsoft/TypeScript/issues/52004)
export const searchGroupMapping: ISearchGroup[] = [];
function updateSearchTypeMapping() {
    return Object.fromEntries(searchGroupMapping
        .flatMap((group) => {
            return Array.isArray(group.value)
                // store original values within the mapping so we can restore all types if only one of a group is present
                ? group.value.map((v) => [v, { ...group, value: v, allValues: group.value as SearchType[] }])
                : [[group.value, { ...group, allValues: [group.value] }]];
        }));
}


@Injectable({
    providedIn: "root",
})
export class SearchService {
    public static readonly NameBreadcrumb = "Name";
    public static readonly ProductServicesBreadcrumb = "Products & Services";

    public static readonly PageSearchGroup: ISearchGroup = {
        value: SearchType.Page,
        name: "Pages",
        icon: "fa-fw fal fa-file-alt",
        remote: false,
    };
    public static readonly PeopleTeamsRolesSearchGroup: ISearchGroup = {
        value: [SearchType.People, SearchType.Team, SearchType.Role],
        name: "People, Teams & Roles",
        icon: "fa-fw fal fa-users",
        remote: false,
    };
    public static readonly KanbanSearchGroup: ISearchGroup = {
        value: SearchType.Kanban,
        name: "Actions",
        icon: "fa-fw fal fa-columns",
        remote: true,
    };
    public static readonly MeetingSearchGroup: ISearchGroup = {
        value: SearchType.Meeting,
        name: "Meetings",
        icon: "fa-fw fal fa-clipboard-list",
        remote: true,
    };
    public static readonly ObjectivesSearchGroup: ISearchGroup = {
        value: SearchType.Objective,
        name: "Objectives & Key Results",
        icon: "fa-fw fal fa-th-large",
        remote: true,
    };
    public static readonly SystemSearchGroup: ISearchGroup = {
        value: SearchType.System,
        name: "Systems",
        icon: "fa-fw fal fa-solar-system",
        remote: true,
    };
    public static readonly Tier1SearchGroup: ISearchGroup = {
        value: SearchType.Tier1,
        name: "Value Streams & Key Functions",
        icon: "fa-fw fal fa-cube",
        remote: true,
    };
    public static readonly GuidanceSearchGroup: ISearchGroup = {
        value: SearchType.Guidance,
        name: "Guidance",
        icon: "fa-fw fal fa-hand-holding-seedling",
        remote: true,
    };
    public static readonly ImplementationKitSearchGroup: ISearchGroup = {
        value: SearchType.ImplementationKit,
        name: "Support Articles",
        icon: "fa-fw fal fa-circle-question",
        remote: false,
    };

    public static SearchElementRegistrar?: IComponentRender<any>;
    public static ApplicationBarSearchElementRegistrar?: IComponentRender<any>;

    // mapping as dict of {SearchType: ISearchGroup}
    public static SearchTypeMapping = updateSearchTypeMapping();

    private readonly BaseUri = `${ServiceUri.MethodologyServicesServiceBaseUri}/Search`;

    private readonly log = Logger.getLogger(this.constructor.name);

    private searchDefaults: { [k in keyof ISearchOptions]: () => ISearchOptions[k] } = {
        keyword: () => undefined,
        types: () => new Set(searchGroupMapping.flatMap((type) => type.value)),
        activeOnly: () => true,
        personId: () => undefined,
        teamId: () => undefined,
        updatedSince: () => undefined,
        labelIds: () => new Set(),
    };
    private searchOptions = new BehaviorSubject<ISearchOptions>(this.getDefaultOptions());
    public searchOptions$ = this.searchOptions.asObservable();
    public searchLabels$: Observable<Label[]>;

    private lastSearchQuery = new BehaviorSubject<ISearchOptions | undefined>(undefined);
    public searchQuery$: Observable<ISearchOptions>;

    private isLoading = new BehaviorSubject<boolean>(false);
    public isLoading$ = this.isLoading.asObservable();

    private lastCompletedResults = new BehaviorSubject<ISearchResults | undefined>(undefined);
    private searchResults = new BehaviorSubject<ISearchResults | undefined>(undefined);
    public searchResults$ = this.searchResults.asObservable();

    private showSearchResults = new Subject<void>();
    public showSearchResults$ = this.showSearchResults.asObservable();

    private searchError = new ReplaySubject<any>(1);
    public searchError$ = this.searchError.asObservable();
    public providerSearchErrors: Map<SearchType, any[]> = new Map();

    constructor(
        private http: HttpClient,
        private orgService: OrganisationService,
        private labellingService: LabellingService,
        private routeService: RouteService,
        @Inject(SEARCH_PROVIDERS) private searchProviders: SearchProvider[],
        userService: UserService,
    ) {
        this.searchQuery$ = this.searchOptions.pipe(
            debounceTime(400),
            switchMap((options) => {
                // update the url if we are already on the search page
                if (this.routeService.currentControllerId === searchPageRoute.id) {
                    return this.gotoSearchRoute(options).pipe(map(() => options));
                }
                return of(options);
            }),
            // only allow keyword search once there are more than 2 characters, or a label is selected
            filter(({ keyword, labelIds }) => this.shouldPerformSearch(keyword, labelIds)),
        );

        this.searchLabels$ = this.searchOptions.pipe(
            debounceTime(400),
            switchMap(({ labelIds }) => {
                if (labelIds && labelIds.size > 0) {
                    const predicate = new MethodologyPredicate<Label>("labelId", "in", [...labelIds!]);
                    return this.labellingService.getLabelsByPredicate(predicate);
                }
                return of([]);
            }),
        );

        this.searchQuery$.pipe(
            switchMap((options) => this.search(options, true)),
            tap(() => this.showResults()),
        ).subscribe(this.searchResults);

        this.showSearchResults.pipe(
            debounceTime(100),
            switchMap(() => this.searchQuery$),
            switchMap((options) => this.gotoSearchRoute(options)),
        ).subscribe();

        // reset search completely when changing org or user
        // and force local providers to reinitialise for fresh data
        this.orgService.organisationChanged$.subscribe(this.resetService);
        userService.userChanged$.subscribe(this.resetService);
    }

    public static registerSearchElementComponent(sec: IComponentRender<any>) {
        SearchService.SearchElementRegistrar = sec;
    }

    public static registerApplicationBarSearchElementComponent(sec: IComponentRender<any>) {
        SearchService.ApplicationBarSearchElementRegistrar = sec;
    }

    public static SortByTeamId(results: any) {
        return [...results!].sort((a, b) => {
            if (!("TeamId" in a) || !("TeamId" in b)) return -1;
            else return a.TeamId! - b.TeamId!;
        });
    }

    public static isValueStream(result: ITier1Result) {
        if (Object.keys(result).includes("ValueStreamId")) {
            return result as IValueStreamSearchResult;
        }

        return undefined;
    }

    public static isKeyFunction(result: ITier1Result) {
        if (Object.keys(result).includes("KeyFunctionId")) {
            return result as IKeyFunctionSearchResult;
        }

        return undefined;
    }

    public static isProduct(result: ITier1Result) {
        if (Object.keys(result).includes("ProductId")) {
            return result as IProductServiceSearchResult;
        }

        return undefined;
    }

    public static isPurposeVision(result: IGuidanceResult) {
        if (result.Type === GuidanceType.PurposeVision) {
            return result as IPurposeVisionSearchResult;
        }

        return undefined;
    }

    public static isValue(result: IGuidanceResult) {
        if (result.Type === GuidanceType.Value) {
            return result as IValueSearchResult;
        }

        return undefined;
    }

    public static isResilientBusinessGoal(result: IGuidanceResult) {
        if (result.Type === GuidanceType.ResilientBusinessGoal) {
            return result as IResilientBusinessGoalSearchResult;
        }

        return undefined;
    }

    public registerSearchGroup(searchGroup: ISearchGroup) {
        searchGroupMapping.push(searchGroup);
        SearchService.SearchTypeMapping = updateSearchTypeMapping();
    }

    public shouldPerformSearch(keyword?: string, labelIds?: Set<number>) {
        const keywordIsValid = !!keyword && !!(keyword.trim()) && keyword.trim().length >= 2;
        const labelIsValid = !!labelIds && labelIds.size > 0;
        return keywordIsValid || labelIsValid;
    }

    public optionsFromSearchParams(params: Record<string, any>) {
        const options: Partial<ISearchOptions> = {
            keyword: params.q,
            activeOnly: !parseInt(params.inactive, 2),
            personId: params.personId
                ? parseInt(params.personId, 10) || undefined
                : undefined,
            teamId: params.teamId
                ? parseInt(params.teamId, 10) || undefined
                : undefined,
            updatedSince: params.since
                ? DurationSelector.DataSource.find((i) => i.slug === params.since)
                : undefined,
            labelIds: params.labelIds
                ? this.urlToLabelIds(params.labelIds)
                : new Set<number>(),
        };
        if ("types" in params) {
            options.types = this.urlToSearchTypes(params.types);
        }
        return options;
    }

    public searchParamsFromOptions(options: Partial<ISearchOptions>) {
        const defaults = this.getDefaultOptions();

        const params = {
            q: options.keyword || undefined,
            labelIds: options.labelIds
                ? this.labelsToUrl(options.labelIds) || undefined
                : undefined,
            types: options.types
                ? this.searchTypesToUrl(options.types) || undefined
                : undefined,
            inactive: defaults.activeOnly !== options.activeOnly && options.activeOnly !== undefined
                ? options.activeOnly ? 0 : 1
                : undefined,
            personId: options.personId,
            teamId: options.teamId,
            since: options.updatedSince
                ? options.updatedSince.slug
                : undefined,
        };

        return ObjectUtilities.cleanNullAndUndefinedValues(params);
    }

    public search(searchOptions: ISearchOptions, sideEffects = false) {
        return of(searchOptions).pipe(
            tap(() => {
                if (sideEffects) {
                    this.isLoading.next(true);
                    this.providerSearchErrors.clear();
                }
            }),
            withLatestFrom(this.lastSearchQuery, this.lastCompletedResults),
            switchMap(([options, prev, lastSearchResults]) => {
                if (sideEffects && prev && lastSearchResults) {
                    // take out types to compare superset, everything else can be compared directly.
                    const { types, ...opt } = options;
                    const { types: prevTypes, ...prevOpt } = prev;
                    if (isEqual(opt, prevOpt) && SetUtilities.isSuperset(prevTypes, types)) {
                        // we already have all the data requested, we don't have to do another search.
                        // we should return a filtered view of the results.
                        const filteredResults = Object.entries(lastSearchResults!).map(
                            ([category, matches]) => options.types.has(SearchType[category as keyof typeof SearchType])
                                ? [category, matches]
                                : [category, []]);
                        return of([Object.fromEntries(filteredResults)] as ISearchResults[]);
                    } else {
                        // clear the last results as they don't match anymore
                        this.clearResultCache();
                    }
                }

                // need to create a new set else the types may get updated externally
                this.lastSearchQuery.next({ ...options, types: new Set(options.types) });

                return this.performSearch(options);
            }),
            tap((results) => {
                // set loading done once all sources are defined
                if (sideEffects && results.every((set) => set && Object.values(set).every((res) => res))) {
                    this.isLoading.next(false);
                }
            }),
            withLatestFrom(this.lastCompletedResults, this.isLoading),
            map(([results, lastCompletedResults, isLoading]) => {
                const combinedResults = this.combineResults(results);

                // save complete results once loading has finished
                if (sideEffects && !lastCompletedResults && !isLoading) {
                    this.lastCompletedResults.next({ ...combinedResults });
                }

                return combinedResults;
            }),
            debounceTime(100),
            switchMap((results) => {
                if (results) {
                    // get the label location Ids provided for any entity and preload them
                    const labelLocationIds = Object.values(results)
                        .flatMap((i: ISearchResults[keyof ISearchResults]) => i?.flatMap((ii: any) => ii?.LabelLocationIds ?? []) ?? []);

                    if (labelLocationIds.length > 0) {
                        return this.getAllLabelLocations(labelLocationIds).pipe(
                            map(() => results),
                        );
                    }
                }
                return of(results);
            }),
        );
    }

    public getSearchUrl$(options: Partial<ISearchOptions>) {
        const params = this.searchParamsFromOptions({ ...this.getDefaultOptions(), ...options });
        return searchPageRoute.getRouteObject({}, params);
    }

    public gotoSearchRoute(options: Partial<ISearchOptions>) {
        const params = this.searchParamsFromOptions(options);
        return searchPageRoute.gotoRoute({}, params, false, true);
    }

    public setSearchOptions(options: Partial<ISearchOptions>) {
        this.searchOptions.next(Object.assign({}, this.searchOptions.value, options));
    }

    public setKeyword(keyword?: string) {
        this.setSearchOptions({ keyword });
    }

    public setTypes(types: Set<SearchType> | SearchType[]) {
        // clone the set so it does not get modified externally
        this.setSearchOptions({ types: new Set(types) });
    }

    public toggleType(type: SearchType | SearchType[]) {
        // clone the set so it does not get modified externally
        const types = new Set(this.searchOptions.value.types);
        if (Array.isArray(type)) {
            this.toggleMultiType(types, type);
        } else {
            this.toggleSingleType(types, type);
        }
        this.setTypes(types);
    }

    public reset() {
        this.clearResultCache();

        const defaultOptions = this.getDefaultOptions();
        this.setSearchOptions(defaultOptions);
    }

    public clearResultCache() {
        this.lastCompletedResults.next(undefined);
        this.lastSearchQuery.next(undefined);
        this.searchResults.next(undefined);
    }

    public showResults() {
        this.showSearchResults.next();
    }

    public getDefaultOptions() {
        // build searchOptions from the defaults. have to cast to unknown as Typescript cannot follow the types in entries -> fromEntries
        return Object.fromEntries(Object.entries(this.searchDefaults).map(([k, v]) => [k, v()])) as unknown as ISearchOptions;
    }

    public getEmptyOptions() {
        return { ...this.getDefaultOptions(), types: new Set<SearchType>() };
    }

    private toggleMultiType(types: Set<SearchType>, type: SearchType[]) {
        if (type.some((t) => types.has(t))) {
            type.forEach((t) => types.delete(t));
        } else {
            type.forEach((t) => types.add(t));
        }
    }

    private toggleSingleType(types: Set<SearchType>, type: SearchType) {
        if (types.has(type)) {
            types.delete(type);
        } else {
            types.add(type);
        }
    }

    private urlToSearchTypes(urlString: string) {
        const types = urlString.split(",")
            .map((type) => parseInt(type, 10))
            .filter((type) => !Number.isNaN(type))
            .sort((a, b) => a - b);

        // get the matching types from the type mapping so that we don't have partial types
        // e.g. if "People, Teams and Roles" is selected, 8,9,10 should be in the types.
        // this fixes the URL so that if not all 3 are in the array, then all will be added.
        const newTypes = Object.values(SearchService.SearchTypeMapping)
            .filter((type) => types.includes(type.value as SearchType))
            .flatMap((type) => type.allValues);

        return new Set<SearchType>(newTypes);
    }

    private urlToLabelIds(urlString: string) {
        const labelIds = urlString.split(",")
            .map((type) => parseInt(type, 10))
            .filter((type) => !Number.isNaN(type))
            .sort((a, b) => a - b);

        return new Set<number>(labelIds);
    }

    private searchTypesToUrl(types: Set<SearchType>) {
        return [...types].sort((a, b) => a - b).join(",");
    }

    private labelsToUrl(labels: Set<number>) {
        return [...labels].sort((a, b) => a - b).join(",");
    }

    @Autobind
    private resetService() {
        this.reset();
        this.resetLocalProviders();
    }

    private resetLocalProviders() {
        this.searchProviders.forEach((provider) => provider.isInitialised = false);
    }

    private performSearch(options: ISearchOptions) {
        const searchTypes = [...options.types];
        const remoteTypes = searchTypes.filter((type) => SearchService.SearchTypeMapping[type].remote);
        const localTypes = searchTypes.filter((type) => !SearchService.SearchTypeMapping[type].remote);

        const labelIds = [...(options.labelIds ?? [])];
        const updatedSince = options.updatedSince?.value;

        const keyword = options.keyword ? options.keyword.trim() : options.keyword!;

        return combineLatest([
            this.performSearchRequest({
                ...options,
                keyword,
                types: remoteTypes,
                updatedSince,
                labelIds,
            }).pipe(
                // this allows local search results to stream in while search API loads
                startWith(undefined),
                tap(() => this.searchError.next(undefined)),
                timeout({
                    each: SearchTimeout,
                    with: () => throwError(() => "Search took too long. Please try again."),
                }),
                catchError((err) => {
                    this.log.error(err);
                    this.searchError.next(err);
                    this.isLoading.next(false);
                    return of({});
                }),
            ),
            ...this.getLocalSearchObservables({ ...options, keyword, types: localTypes, labelIds }),
        ]);
    }

    private getLocalSearchObservables(params: Omit<ISearchApiParams, "organisationId" | "updatedSince">) {
        const options = { ...params, types: new Set(params.types), labelIds: new Set(params.labelIds) };

        return this.searchProviders
            .filter((provider) => params.types.includes(provider.Type))
            .map((searchProvider) => searchProvider.isInitialised
                ? of(searchProvider)
                : searchProvider.initialise().pipe(
                    tap(() => searchProvider.isInitialised = true),
                    map(() => searchProvider),
                ))
            .map((searchProvider) => searchProvider.pipe(
                switchMap((provider) => {
                    if (provider.shouldSkip(options)) {
                        return of([] as any);
                    }

                    return provider.execute(options).pipe(
                        startWith(undefined),
                        catchError((err) => {
                            this.log.error(err);
                            if (!this.providerSearchErrors.has(provider.Type)) {
                                this.providerSearchErrors.set(provider.Type, []);
                            }
                            this.providerSearchErrors.get(provider.Type)?.push(err);
                            return of([]);
                        }),
                        map((results) => ({ [SearchType[provider.Type]]: results } as ISearchResults)),
                    );
                }),
            ));
    }

    private performSearchRequest(params: Omit<ISearchApiParams, "organisationId">) {
        if (params.types.length === 0) {
            // don't bother sending a query if no types selected
            return of({});
        }

        const organisationId = this.orgService.getOrganisationId();
        return this.http.post<ISearchResults>(this.BaseUri, { ...params, organisationId }, {
            responseType: "json",
        }).pipe(
            catchError((err) => throwError(() => ErrorHandlingUtilities.getHttpResponseMessage(err))),
        );
    }

    private combineResults(results: (ISearchResults | undefined)[]): ISearchResults | undefined {
        return results.reduce((acc: ISearchResults, res) => Object.assign(acc, res ?? {}), {} as ISearchResults);
    }

    private getAllLabelLocations(locationIds: number[]) {
        const breezeNodeLimit = 10;
        const predicates = ArrayUtilities.splitAndProcessArrayChunks(
            locationIds,
            breezeNodeLimit,
            (locationIdChunk) => new MethodologyPredicate<LabelLocation>("labelLocationId", "in", locationIdChunk),
        );
        const queries = predicates.map((p) => this.labellingService.getLabelLocationsByPredicate(p));

        return forkJoin(queries).pipe(
            map(ArrayUtilities.mergeArrays),
        );
    }
}

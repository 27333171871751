<div class="card">
    <div class="d-flex mb-3">
        <div class="btn-group section-selector ms-auto"
             role="group">
            <button class="btn btn-primary"
                    *ngFor="let section of sections"
                    [ngClass]="{active: !section.visible}"
                    (click)="toggleSection(section)"
                    [adaptTooltip]="section.tooltip ?? section.name">
                <dx-check-box (onContentReady)="onCheckBoxReady($event, section)"
                              [text]="section.code"
                              [value]="isSectionConfigured(section)"
                              [readOnly]="true"></dx-check-box>
            </button>
        </div>
    </div>

    <div class="card-body px-1">
        <adapt-time-scheduler #scheduler
                              [items]="items"
                              [periods]="periods"
                              [sections]="sections"
                              [showSections]="true"
                              [start]="start"
                              [cycle]="cycle"
                              (initialised)="queuedScheduler.setCallee($event)"
                              (itemMouseEnter)="onItemHover($event)"
                              (itemMouseLeave)="onItemHover($event, true)"
                              [selectedItem]="selectedItem"
                              (selectedItemChange)="selectedItemChange.emit($event)"></adapt-time-scheduler>

        <dx-popover *ngIf="popupItem"
                    [target]="popupItem.element"
                    [visible]="popupVisible">
            <div *dxTemplate="let data = model of 'content'">
                <ng-container *ngIf="popupItem?.item as item">
                    <div *ngIf="item.metadata?.label as label">{{label}}</div>
                    <div><i class="fal fa-fw fa-calendar"></i> {{item.start | adaptDate}}</div>
                    <div><i class="fal fa-fw fa-timer"></i> {{formatTime(item)}}</div>
                    <div *ngIf="item.metadata?.location as location"><i class="fal fa-fw fa-location-dot"></i> {{location}}</div>
                    <div *ngIf="item.disabled"><br /><small>Note: This meeting doesn't exist in the platform yet.
                            <br />It will be created by the platform when the previous meeting in the series has completed.</small></div>
                </ng-container>
            </div>
        </dx-popover>
    </div>

    <div class="d-flex mt-3">
        <div class="btn-group period-selector ms-auto"
             role="group">
            <button class="btn btn-primary btn-start-time"
                    (click)="scheduler.gotoOriginalDate()"
                    adaptTooltip="Go to current time">
                <i class="fal fa-fw fa-grid-2"></i>
            </button>
            <button class="btn btn-primary"
                    *ngFor="let period of scheduler.periods"
                    [class]="period.classes"
                    [ngClass]="{active: scheduler.currentPeriod === period}"
                    (click)="scheduler.changePeriod(period)"
                    [adaptTooltip]="period.tooltip ? period.tooltip : ''">{{period.name}}</button>
        </div>
    </div>
</div>

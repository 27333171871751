<div class="person-image profile-image-container profile-image-container-{{imageSize}}"
     [ngClass]="[containerClass]"
     [ngSwitch]="!!person">
    <ng-container *ngSwitchCase="true">
        <adapt-storage-image *ngIf="person?.imageIdentifier"
                             [imageIdentifier]="person?.imageIdentifier"
                             [defaultImageSrc]="defaultImageSrc">
        </adapt-storage-image>
        <adapt-person-default-image *ngIf="!person?.imageIdentifier"
                                    [imageSize]="imageSize"
                                    [person]="person"></adapt-person-default-image>
    </ng-container>
    <i *ngSwitchDefault
       class="unknown-person-profile-image fal fa-question"
       aria-hidden="true"></i>
</div>

<div class="d-flex row">
    <div class="col-auto d-flex align-items-center">
        <adapt-link-person [showImage]="true"
                           [showText]="false"
                           [personId]="item.assigneeId"></adapt-link-person>
    </div>
    <div class="col-2 col-sm-1 p-0 d-flex align-items-center">
        <adapt-item-link [item]="item"
                         [showSummary]="false"></adapt-item-link>
    </div>
    <div class="col flex-grow-1 text-break d-flex align-items-center">
        {{item.summary}}
    </div>
    <div class="col-12 mt-3 col-md-5 col-lg-4 mt-md-0 d-flex align-items-center justify-content-end">
        <div class="row d-flex flex-fill">
            <div class="d-flex col justify-content-center justify-content-md-end text-center text-md-right">
                <div [ngClass]="itemStatusMetadata.ByStatus[item.status].iconClass"
                     class="badge">
                    {{itemStatusMetadata.ByStatus[item.status].name}}
                </div>
            </div>
            <div class="d-flex col justify-content-center justify-content-md-end text-center text-md-right"
                 *ngIf="!!item.board">
                <adapt-link-team [team]="item.board.team"></adapt-link-team>
            </div>
        </div>
    </div>
</div>

import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { PositionConfig } from "devextreme/animation/position";
import dxDevices from "devextreme/core/devices";
import dxTooltip, { InitializedEvent, Properties } from "devextreme/ui/tooltip";

export type TooltipPlacement = "top" | "bottom" | "left" | "right" | "bottom-left" | "top-left";

export class TooltipHelper {
    private static readonly placementMappings: { [name in TooltipPlacement]: PositionConfig } = {
        "top": { my: "bottom", at: "top" },
        "bottom": { my: "top", at: "bottom" },
        "left": { my: "right", at: "left" },
        "right": { my: "left", at: "right" },
        "bottom-left": { my: "left top", at: "left bottom" },
        "top-left": { my: "left bottom", at: "left top" },
    };

    private queuedDxTooltip = new QueuedCaller<dxTooltip>();
    private tooltipIsEnabled = true;
    private contentText = "";
    private contentIsHtml = false;
    private initialisingPointerHasLeft = false;

    public constructor(
        private element: JQuery,
        private timeout: (callback: () => void) => void,
        private position?: TooltipPlacement,
    ) {
        element.addClass("adapt-tooltip");

        element.on(this.initialiseEvent, this.createAndAttachTooltip);
        element.on(this.cancelInitialTooltipShowEvent, this.flagInitialisingPointerHasLeft);
    }

    @Autobind
    private createAndAttachTooltip() {
        this.element.off(this.initialiseEvent, this.createAndAttachTooltip);

        const tooltipOptions: Properties = {
            maxWidth: 400, // Corresponds with max width in SCSS
            onInitialized: (e: InitializedEvent) => this.queuedDxTooltip.setCallee(e.component!),
            // Remove fallback tooltip (html title) if we get shown as pointer events are enabled
            onShowing: () => this.element.removeAttr("title"),
            onHiding: () => this.element.attr("title", this.contentText),
            // CM-5081 DX 21.1 has screwed up types - waiting on a fix for this possibly
            target: this.element[0],
            position: this.getDxTooltipPosition(this.position),
            showEvent: this.dxShowEvent,
            hideEvent: this.dxHideEvent,
            hideOnOutsideClick: this.isTouchDevice,
        };

        // Initialise the content so we don't get an empty tooltip if there is no text
        this.setTooltipContent(this.contentText, this.contentIsHtml);

        // CM-5081 DX 21.1 has screwed up types - waiting on a fix
        // console.log(tooltipOptions);
        // TODO: fix the type after dx releases a fix - for the time being, make it work first
        const dxTooltipElement = jQuery(`<div class="dx-tooltip-element"></div>`);
        new dxTooltip(dxTooltipElement[0], tooltipOptions);

        this.element.after(dxTooltipElement as any);

        // Immediately show the tooltip as the initial event which would have triggered
        // DX to show the tooltip has already occurred
        this.queuedDxTooltip.call((tooltip) => {
            this.element.removeAttr("title");

            // DX Tooltip won't show if it isn't in a $timeout, but then it will show both the
            // DX tooltip and the title as the browser shows the title before the timeout
            // resolves and DX removes it in onShowing above, so manually remove the title before
            // the timeout to prevent this from occurring
            this.timeout(() => {
                if (this.initialisingPointerHasLeft || !this.tooltipIsEnabled || !this.contentText) {
                    return;
                }

                tooltip.show();
            });
        });
    }

    @Autobind
    private flagInitialisingPointerHasLeft() {
        this.element.off(this.cancelInitialTooltipShowEvent, this.flagInitialisingPointerHasLeft);
        this.initialisingPointerHasLeft = true;
    }

    @Autobind
    public setTooltipIsEnabled(isEnabled: boolean) {
        this.tooltipIsEnabled = isEnabled;
        this.setTooltipIsEnabledInternal();
    }

    @Autobind
    public setTooltipContent(content: string, tooltipIsHtml: boolean) {
        this.contentText = tooltipIsHtml ? content : StringUtilities.htmlToPlaintext(content);
        this.contentIsHtml = tooltipIsHtml;
        this.setTooltipIsEnabledInternal();

        if (!this.contentText) {
            return;
        }

        // Wrap in span to handle raw text
        const htmlElement = jQuery(`<span class="adapt-tooltip-content">${content}</span>`);
        const templateCallback = (tooltipElement: JQuery<any>) => tooltipElement.append(htmlElement);
        this.setDxOption("contentTemplate", templateCallback);
    }

    public destroyTooltip() {
        this.queuedDxTooltip.call((tooltip) => {
            tooltip.dispose();
        });
    }

    public hideTooltip() {
        this.queuedDxTooltip.call((tooltip) => {
            tooltip.hide();
        });
    }

    private setTooltipIsEnabledInternal() {
        if (this.tooltipIsEnabled && this.contentText) {
            this.element.addClass("adapt-tooltip-enabled");
            this.element.attr("title", this.contentText);
            this.setDxOption("showEvent", this.dxShowEvent);
        } else {
            this.element.removeClass("adapt-tooltip-enabled");
            this.element.removeAttr("title");
            this.setDxOption("showEvent", undefined);
        }
    }

    private setDxOption(optionName: string, optionValue: any) {
        this.queuedDxTooltip.call((tooltip) => {
            tooltip.option(optionName, optionValue);
        });
    }

    private getDxTooltipPosition(position?: TooltipPlacement): PositionConfig {
        let config: PositionConfig = {
            my: "bottom",
            at: "top",
            collision: {
                x: "flipfit",
                y: "flipfit",
            },
        };

        if (!position) {
            return config;
        }

        const mappedPosition = TooltipHelper.placementMappings[position];
        if (mappedPosition) {
            config = Object.assign(config, mappedPosition);
        }

        return config;
    }

    private get dxShowEvent() {
        return this.isTouchDevice
            ? "dxpointerdown"
            : "dxpointerenter";
    }

    private get dxHideEvent() {
        return this.isTouchDevice
            ? undefined
            : "dxpointerleave";
    }

    private get initialiseEvent() {
        return this.isTouchDevice
            ? "touchstart"
            : "mouseenter";
    }

    private get cancelInitialTooltipShowEvent() {
        return "mouseleave";
    }

    private get isTouchDevice() {
        const device = dxDevices.current();
        return device.phone || device.tablet;
    }
}

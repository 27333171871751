import { Component, Injector, OnInit } from "@angular/core";
import { OrganisationCategoryValue, Workflow, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStep } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { MeetingBreezeModel, MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItemBreezeModel, MeetingAgendaItemType } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaItemSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item-supplementary-data";
import { MeetingAttendeeBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { OutstandingSurveyResponseBreezeModel } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { SurveyBreezeModel, SurveyStatus, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { SurveyResultModel } from "@common/ADAPT.Common.Model/organisation/survey-result";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { GuidedTourRegistry } from "@common/lib/guided-tour/guided-tour-registrar";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { IRunWorkshopCustomData } from "@org-common/lib/workflow/workflow-meeting.service";
import { WorkflowRunDialogComponent } from "@org-common/lib/workflow/workflow-run-dialog/workflow-run-dialog.component";
import moment from "moment";
import { lastValueFrom } from "rxjs";
import { filter } from "rxjs/operators";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";
import { createValueWorkflow } from "../../../workflows/create-value/create-value-workflow";
import { IOrganisationDiagnosticStatusCustomData } from "../../../workflows/organisation-diagnostic/organisation-diagnostic-status-custom-data";
import { WorkflowIdentifier } from "../../../workflows/workflow-identifier.enum";
import { createActivityBriefWorkflow } from "../../../workflows/workflow-utilities";
import { Workflows } from "../../../workflows/workflows";

@Component({
    selector: "adapt-alto-components-page",
    templateUrl: "./alto-components-page.component.html",
    styleUrls: ["./alto-components-page.component.scss"],
})
export class AltoComponentsPageComponent extends BaseRoutedComponent implements OnInit {
    public allTours: TourIdentifier[] = Object.values(TourIdentifier);

    public understandJourneySurveyCompleteMeetingNotScheduled?: WorkflowConnection;
    public understandJourneySurveyInProgress?: WorkflowConnection;
    public understandJourneySurveyInProgressCurrentUserOutstanding?: WorkflowConnection;
    public understandJourneySurveyComplete?: WorkflowConnection;

    public workflowWelcomeOnboarding = Workflows[WorkflowIdentifier.WelcomeOnboarding];
    public workflowUpNext = Workflows[WorkflowIdentifier.DefineOwnersWhy];
    public workflowLocked = Workflows[WorkflowIdentifier.SetFirstObjectives];
    public workflowComingSoon = Workflows[WorkflowIdentifier.MeaningfulConversations];
    public workflowStarted?: WorkflowConnection;
    public workflowMeetingNotScheduled?: WorkflowConnection;
    public workflowMeetingScheduled?: WorkflowConnection;
    public workflowMeetingRunning?: WorkflowConnection;

    constructor(
        injector: Injector,
        private organisationService: OrganisationService,
        private dialogService: AdaptCommonDialogService,
        private workflowService: WorkflowService,
        private commonDataService: CommonDataService,
        private userService: UserService,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        this.organisationService.currentOrganisation$.pipe(
            this.takeUntilDestroyed(),
            filter((org) => org !== undefined),
        ).subscribe(() => {
            this.notifyActivated();
        });

        // need to mark this as completed so that "Understand Your Journey" doesn't show as locked due to prereq
        await this.workflowService.completeWorkflow(Workflows[WorkflowIdentifier.WelcomeOnboarding], false);

        this.understandJourneySurveyCompleteMeetingNotScheduled = await this.createUnderstandJourneySurveyCompleteMeetingNotScheduledConnection();
        this.understandJourneySurveyInProgress = await this.createUnderstandJourneySurvey(true, false);
        this.understandJourneySurveyInProgressCurrentUserOutstanding = await this.createUnderstandJourneySurvey(true, true);
        this.understandJourneySurveyComplete = await this.createUnderstandJourneySurvey(false);

        this.workflowStarted = await this.createWorkflowStarted();

        this.workflowMeetingNotScheduled = await this.createWorkflowMeetingNotScheduled();
        this.workflowMeetingScheduled = await this.createWorkflowMeetingScheduled(false);
        this.workflowMeetingRunning = await this.createWorkflowMeetingScheduled(true);
    }

    public showDialog() {
        this.dialogService.open(WorkflowRunDialogComponent, {
            workflow: createValueWorkflow,
        }).subscribe();
    }

    public runWrapUpTour(tour: TourIdentifier) {
        this.guidedTourService.run(GuidedTourRegistry.get(tour));
    }

    private async createUnderstandJourneySurveyCompleteMeetingNotScheduledConnection() {
        const workflow = Workflows[WorkflowIdentifier.UnderstandJourney];
        const scheduleMeetingWorkflow = workflow.workflows![1];
        const connection = await this.completeJourneyUntilStep(workflow, scheduleMeetingWorkflow, scheduleMeetingWorkflow.steps![0]);

        const survey = await lastValueFrom(this.commonDataService.create(SurveyBreezeModel, {
            organisationId: this.organisationService.getOrganisationId(),
            status: SurveyStatus.Ended,
            surveyType: SurveyType.OrganisationDiagnostic,
            name: "Survey",
            endTime: new Date(),
        }));

        await lastValueFrom(this.workflowService.updateWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(connection, {
            surveyId: survey.surveyId,
            meetingId: 0,
        }, false));

        return connection;
    }

    private async createUnderstandJourneySurvey(inProgress = true, currentOutstanding = true) {
        const workflow = Workflows[WorkflowIdentifier.UnderstandJourney];
        const surveyWorkflow = workflow.workflows![0];
        const connection = await this.completeJourneyUntilStep(workflow, surveyWorkflow, surveyWorkflow.steps![surveyWorkflow.steps!.length - 1]);

        const survey = await lastValueFrom(this.commonDataService.create(SurveyBreezeModel, {
            organisationId: this.organisationService.getOrganisationId(),
            status: inProgress ? SurveyStatus.Started : SurveyStatus.Ended,
            surveyType: SurveyType.OrganisationDiagnostic,
            name: "Survey",
            endTime: moment().add(5, "days").toDate(),
        }));

        await lastValueFrom(this.commonDataService.create(SurveyResultModel, {
            survey,
            responseGroup: SurveyResponseGroup.All,
            percentageParticipation: inProgress ? 50 : 100,
        }));

        const currentPerson = await this.userService.getCurrentPerson();
        if (currentOutstanding || inProgress) {
            await lastValueFrom(this.commonDataService.create(OutstandingSurveyResponseBreezeModel, {
                survey,
                connection: currentOutstanding ? currentPerson?.getLatestConnection() : undefined,
            }));
        }

        await lastValueFrom(this.workflowService.updateWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(connection, {
            surveyId: survey.surveyId,
            meetingId: 0,
            forceLocal: true,
        }, false));

        return connection;
    }

    private async createWorkflowStarted() {
        const workflow = buildLocalWorkflow({
            name: "Test journey",
            workflowId: "TEST",
            type: WorkflowType.Journey,
            time: "3 hours",
            people: "Leadership team",
            mindset: "Collaborative",
            overview: "Text that relates to the content is shown here.",
            dialogWidth: WorkflowDialogWidth.ExtraLarge,
            category: OrganisationCategoryValue.CriticalNumbers,
            articleSlug: ImplementationKitArticle.ComingSoon, // a coming soon HelpJuice document
            ordinal: 20,
            workflows: [
                createActivityBriefWorkflow("test", WorkflowIdentifier.MeaningfulConversations),
            ],
        });

        this.workflowService.setLocalWorkflows([...Object.values(Workflows), workflow]);

        const innerWorkflow = workflow.workflows![0];
        const connection = await this.completeJourneyUntilStep(workflow, innerWorkflow, innerWorkflow.steps![0]);
        return connection;
    }

    private async createWorkflowMeetingNotScheduled() {
        const workflow = Workflows[WorkflowIdentifier.SetValues];
        const scheduleMeetingWorkflow = workflow.workflows![1];
        const connection = await this.completeJourneyUntilStep(workflow, scheduleMeetingWorkflow, scheduleMeetingWorkflow.steps![0]);
        return connection;
    }

    private async createWorkflowMeetingScheduled(meetingInProgress = false, hasPreWork = true) {
        const workflow = Workflows[WorkflowIdentifier.SetValues];
        const runMeetingWorkflow = workflow.workflows![2];
        const connection = await this.completeJourneyUntilStep(workflow, runMeetingWorkflow, runMeetingWorkflow.steps![0]);

        const meeting = await lastValueFrom(this.commonDataService.create(MeetingBreezeModel, {
            name: "Meeting name",
            status: meetingInProgress ? MeetingStatus.InProgress : MeetingStatus.NotStarted,
        }));

        const currentPerson = await this.userService.getCurrentPerson();
        await lastValueFrom(this.commonDataService.create(MeetingAttendeeBreezeModel, {
            meeting,
            attendee: currentPerson,
        }));

        await lastValueFrom(this.workflowService.updateWorkflowCustomData<IRunWorkshopCustomData>(connection, {
            meetingId: meeting.meetingId,
        }, false));

        if (hasPreWork) {
            const meetingAgendaItem = await lastValueFrom(this.commonDataService.create(MeetingAgendaItemBreezeModel, {
                meeting,
                name: "Pre-work",
                ordinal: 0,
                type: MeetingAgendaItemType.PreWork,
            }));

            await lastValueFrom(this.commonDataService.create(MeetingAgendaItemSupplementaryDataBreezeModel, {
                meetingAgendaItem,
                itemDescription: "<p>Test pre-work content</p>",
            }));
        }

        return connection;
    }

    private async completeJourneyUntilStep(journey: Workflow, workflow?: Workflow, step?: WorkflowStep) {
        for (const prerequisiteWorkflows of (journey.compulsoryPrerequisites ?? [])) {
            await this.workflowService.completeWorkflow(Workflows[prerequisiteWorkflows as WorkflowIdentifier], false);
        }

        const { workflowConnection } = await lastValueFrom(this.workflowService.createWorkflowConnectionForWorkflow(journey, undefined, undefined, false, true));

        const statuses = await lastValueFrom(this.workflowService.getUpdateStatusForJourneyHierarchy(workflowConnection));

        for (const status of statuses) {
            // mark journey status as current
            if (status.workflowId === null && status.workflowStepId === null) {
                this.workflowService.setWorkflowStatusCurrent(status);
                continue;
            }

            // no workflow or step to complete, exit.
            if (!workflow || !step) {
                break;
            }

            // complete all statuses up until the desired workflow
            if (status.workflowId === workflow.workflowId) {
                this.workflowService.setWorkflowStatusCurrent(status);
                continue;
            }

            if (status.workflowStepId === step.workflowStepId) {
                this.workflowService.setWorkflowStatusCurrent(status);
                break;
            }

            this.workflowService.setWorkflowStatusCompleted(status);
        }

        console.log({ workflowConnection, statuses });

        return workflowConnection;
    }
}

import { Component, Inject } from "@angular/core";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { SeedEntity } from "@org-common/lib/purpose-vision/common-purpose-vision.service";

@Component({
    selector: "adapt-seeds-dialog",
    templateUrl: "./seeds-dialog.component.html",
})
export class SeedsDialogComponent extends BaseDialogComponent<SeedEntity> {
    public readonly dialogName = "seeds";

    public dialogTitle = this.seedEntity === SeedEntity.Purpose
        ? "Owners ‘Why’"
        : "Vision";

    public constructor(@Inject(ADAPT_DIALOG_DATA) public seedEntity: SeedEntity) {
        super(DialogResolveData.NotRequired);
    }
}

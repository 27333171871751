import { Component, Input } from "@angular/core";
import { Anchor } from "@common/ADAPT.Common.Model/organisation/anchor";

@Component({
    selector: "adapt-strategic-anchors",
    templateUrl: "./strategic-anchors.component.html",
    styleUrls: ["./strategic-anchors.component.scss"],
})
export class StrategicAnchorsComponent {
    @Input() public anchors: Anchor[] = [];
    @Input() public isEditing = true;
}

<div class="link-person-contents-directive"
     *ngIf="(person$ | async) as person">
    <div class="d-flex">
        <adapt-person-image [person]="person"></adapt-person-image>
        <div class="ms-2">
            <ng-container *ngIf="(isStakeholderManager$ | async); else normalUser">
                <h3>{{person.fullName}}</h3>
                <p>This person is an ADAPT team member</p>
            </ng-container>
            <ng-template #normalUser>
                <h3>
                    <ng-container *ngIf="(profileUrl$ | async) as profileUrl; else noProfileUrl">
                        <a [routerLink]="profileUrl">
                            {{person.fullName}}
                        </a>
                    </ng-container>
                    <ng-template #noProfileUrl>
                        <a>
                            {{person.fullName}}
                        </a>
                    </ng-template>
                    <small *ngIf="person && !person.isActive()">(inactive)</small>
                </h3>
                <p>{{positionName$ | async}}</p>
            </ng-template>
        </div>
    </div>
    <div class="mt-2"
         *ngIf="!(isStakeholderManager$ | async)">
        <ul *ngIf="hasPermissionToReadContactDetails$ | async"
            class="mb-0">
            <li *ngFor="let detail of (contactDetails$ | async)">
                <adapt-contact-detail [detail]="detail"></adapt-contact-detail>
            </li>
        </ul>
    </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ValueQuestion } from "@common/ADAPT.Common.Model/organisation/value-question";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ButtonType } from "devextreme/common";
import { DxValidationGroupComponent } from "devextreme-angular";

@Component({
    selector: "adapt-edit-behaviour",
    templateUrl: "./edit-behaviour.component.html",
    styleUrls: ["./edit-behaviour.component.scss"],
})
export class EditBehaviourComponent implements OnInit {
    @Input() public behaviour!: ValueQuestion;
    @Input() public readonly = false;
    @Output() public behaviourChange = new EventEmitter<ValueQuestion>();

    @ViewChild("validationGroup", { static: false }) public validationGroup?: DxValidationGroupComponent;

    public selectedBehaviour: boolean[] = [];
    public behaviourMetadata = {
        good: {
            isGood: true,
            type: "success" as ButtonType,
            buttonClass: "btn-outline-success",
            buttonSelectedClass: "btn-success",
            tooltip: "Behaviour that supports, demonstrates or represents this value",
            icon: "fas fa-fw fa-thumbs-up",
        },
        bad: {
            isGood: false,
            type: "danger" as ButtonType,
            buttonClass: "btn-outline-danger",
            buttonSelectedClass: "btn-danger",
            tooltip: "Behaviour that does not support, demonstrate or represent this value",
            icon: "fas fa-fw fa-thumbs-down",
        },
    };

    public behaviourOptions = [this.behaviourMetadata.good, this.behaviourMetadata.bad];

    public get currentBehaviour() {
        switch (this.behaviour.goodBehaviour) {
            case true:
                return this.behaviourMetadata.good;
            case false:
                return this.behaviourMetadata.bad;
            default:
                return undefined;
        }
    }

    public ngOnInit() {
        this.selectedBehaviour = this.behaviour.goodBehaviour !== null ? [this.behaviour.goodBehaviour!] : [];

        // validate the input next digest
        setTimeout(() => this.validationGroup?.instance.validate());
    }

    @Autobind
    public validateBehaviourTypeSelected() {
        return this.behaviour.goodBehaviour !== null;
    }

    public updateBehaviourType(isGood: boolean) {
        this.behaviour.goodBehaviour = isGood;
        this.behaviourChange.emit(this.behaviour);
        this.validationGroup?.instance.validate();
    }
}

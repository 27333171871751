<adapt-feature-guidance [configItem]="configItem">
    <p>
        Control what notifications you receive for stewardship items in {{projectLabel}}.
    </p>
</adapt-feature-guidance>

<ng-container *adaptLoading="!person">
    <h3>Item Notifications</h3>
    <dx-check-box class="d-block"
                  text="Send me a daily summary of actions assigned to me that were changed by other people"
                  [(ngModel)]="person!.preferences.notifyDailyItemSummary"></dx-check-box>
    <dx-check-box class="d-block mt-1"
                  text="Send me a weekly summary of actions assigned to me that are due in the coming week"
                  [(ngModel)]="person!.preferences.notifyUpcomingDueItems"></dx-check-box>

    <h3 class="mt-3">Objectives Notifications</h3>
    <dx-check-box class="d-block"
                  text="Send me a daily summary of objectives assigned to me that were changed by other people"
                  [(ngModel)]="person!.preferences.notifyDailyObjectiveSummary"></dx-check-box>
    <dx-check-box class="d-block mt-1"
                  text="Send me a weekly summary of objectives assigned to me that are due in the coming week"
                  [(ngModel)]="person!.preferences.notifyUpcomingDueObjectives"></dx-check-box>

    <div role="alert"
         class="alert alert-warning mt-3">
        Note: These settings are system wide settings.
        <br />
        If you are subscribed to multiple organisations, these settings apply to all organisations.
    </div>
</ng-container>

<div *adaptIfAlto="true"
     class="d-flex pt-5 w-100 flex-column align-items-center h-100 justify-content-center">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</div>
<adapt-page-with-splash-image *adaptIfAlto="false">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</adapt-page-with-splash-image>

<ng-template #pageContent>
    <img src="/content/shell/images/adaptplatformlogo.svg"
         width="140"
         class="mb-4" />

    <div class="row w-100 justify-content-center">
        <div *adaptIfAlto="true"
             class="col-12 col-sm-9 col-lg-6 col-xl-3">
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
        </div>
        <div *adaptIfAlto="false">
            <h1>Forgot Password</h1>
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
        </div>
    </div>

    <ng-template #formContent>
        <p>Please enter your login email address, and we will send you an email with instructions on how to reset your
            password.</p>

        <form (ngSubmit)="submit()">
            <dx-validation-group #validationGroup>
                <div class="form-group">
                    <label class="visually-hidden"
                           for="emailInput">Email</label>

                    <dx-text-box mode="email"
                                 id="emailInput"
                                 [(value)]="email"
                                 (onValueChanged)="validateGroup()"
                                 placeholder="Email address"
                                 data-test="email-input">
                        <dx-validator>
                            <dxi-validation-rule type="required"
                                                 message="Email is required"></dxi-validation-rule>
                            <dxi-validation-rule type="email"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>

                <button [adaptBlockingClick]="submit"
                        class="btn btn-primary w-100"
                        [disabled]="isDisabled || isProcessing"
                        data-test="submit-button">
                    <ng-container *adaptIfAlto="true">Send</ng-container>
                    <ng-container *adaptIfAlto="false">Send Forgot Password Email</ng-container>
                </button>
            </dx-validation-group>
        </form>

        <div *adaptIfAlto="true"
             class="text-center">
            <div class="mt-3">
                <a routerLink="/account/login">Return to login</a>
            </div>
        </div>
        <div *adaptIfAlto="false">
            <div class="mt-3">
                <a routerLink="/account/login">Return to login</a>
            </div>
        </div>

        <div *ngIf="submitMessage"
             role="alert"
             [ngClass]="{'alert-danger': isError, 'alert-success': !isError}"
             class="alert mt-5"
             [innerHtml]="submitMessage">
        </div>
    </ng-template>
</ng-template>

import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { combineLatest, Subject } from "rxjs";
import { filter, finalize, switchMap } from "rxjs/operators";
import { KanbanService } from "../../kanban.service";
import { KanbanAuthService } from "../../kanban-auth.service";
import { KanbanNavigationService } from "../../kanban-navigation.service";
import { KanbanUiService } from "../../kanban-ui.service";

@Component({
    selector: "adapt-item-action-menu",
    template: `<adapt-menu [items]="itemMenu" data-test="item-action-menu"></adapt-menu>`,
})
export class ItemActionMenuComponent extends BaseComponent implements OnChanges {
    @Input() public item!: Item;
    @Output() public itemReopened = new EventEmitter<Item>();
    @Output() public itemClosed = new EventEmitter<Item>();
    @Output() public itemMoved = new EventEmitter<Item>();
    @Output() public itemDeleted = new EventEmitter<Item>();

    @Input() public showOpenOption = false;
    @Output() public dialogOpened = new EventEmitter<Item>();
    @Output() public dialogClosed = new EventEmitter<Item>();

    public itemMenu: IAdaptMenuItem[] = [];
    private triggerMenuUpdate$ = new Subject<void>();

    public constructor(
        private kanbanService: KanbanService,
        private kanbanAuthService: KanbanAuthService,
        private kanbanUiService: KanbanUiService,
        private kanbanNavService: KanbanNavigationService,
        private dialogService: AdaptCommonDialogService,
        private meetingsService: MeetingsService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.triggerMenuUpdate$.pipe(
            filter(() => !!this.item),
            switchMap(() => combineLatest([
                this.kanbanNavService.getItemUrl(this.item),
                this.kanbanAuthService.hasEditAccessToItem(this.item),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([itemUrl, canEdit]) => this.setupMenu(itemUrl, canEdit));

        rxjsBreezeService.entityTypeChanged(Item).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((item) => {
            if (item === this.item) {
                this.triggerMenuUpdate$.next();
            }
        });
    }

    @HostListener("click", ["$event"])
    public onClick(e: MouseEvent) {
        // This will prevent kanban card click propagation, causing the preview pane to appear, pushing the menu away
        e.stopPropagation();
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.item) {
            this.triggerMenuUpdate$.next();
        }
    }

    private setupMenu(itemUrl: string, canEdit: boolean) {
        const items: IAdaptMenuItem[] = [];

        if (this.showOpenOption) {
            items.push({
                text: "Open action",
                icon: "fal fa-fw fa-folder-open",
                onClick: () => {
                    this.dialogOpened.emit(this.item);
                    this.kanbanUiService.openItem(this.item).pipe(
                        finalize(() => this.dialogClosed.emit(this.item)),
                    ).subscribe();
                },
            });
        }

        if (canEdit) {
            if (this.item.status === ItemStatus.Closed) {
                if (!this.item.board?.team || (this.item.board?.team && this.item.board?.team.isActive())) {
                    items.push({
                        beginGroup: true,
                        text: "Re-open action",
                        icon: "fal fa-fw fa-inbox-out",
                        onClick: this.reopenItem,
                    });

                    items.push({
                        text: "Copy action URL to clipboard",
                        icon: "fal fa-fw fa-copy",
                        onClick: () => window.navigator.clipboard.writeText(`${window.location.origin}${itemUrl}`),
                    });
                }
            } else {

                items.push({
                    beginGroup: true,
                    text: "Duplicate action",
                    icon: "fal fa-fw fa-clone",
                    onClick: this.cloneItem,
                });

                if (this.showOpenOption && (!this.item.board?.team || (this.item.board?.team && this.item.board?.team.isActive()))) {
                    items.push({
                        text: "Archive action",
                        icon: "fal fa-fw fa-archive",
                        onClick: this.closeItem,
                    });
                }

                items.push({
                    text: "Add link",
                    icon: "fal fa-fw fa-paperclip",
                    onClick: this.addItemLink,
                });

                items.push({
                    text: "Set meeting association",
                    icon: "fal fa-fw fa-link",
                    onClick: this.toggleMeetingAssociation,
                });

                items.push({
                    text: "Move action to another board",
                    icon: "fal fa-fw fa-share",
                    onClick: this.moveItem,
                });

                items.push({
                    text: "Copy action URL to clipboard",
                    icon: "fal fa-fw fa-copy",
                    onClick: () => window.navigator.clipboard.writeText(`${window.location.origin}${itemUrl}`),
                });

                items.push({
                    beginGroup: true,
                    text: "Delete action",
                    icon: "fal fa-fw fa-trash-alt",
                    onClick: this.deleteItem,
                });

            }
        }

        if (items.length) {
            this.itemMenu = [{
                text: "",
                icon: MenuComponent.SmallRootMenu.icon,
                items,
            }];
        } else {
            this.itemMenu = [];
        }
    }

    @Autobind
    private moveItem() {
        return this.kanbanUiService.openMoveItemDialog(this.item).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.itemMoved.emit(this.item));
    }

    @Autobind
    private toggleMeetingAssociation() {
        this.meetingsService.getMeetingItemsForItem(this.item.itemId).pipe(
            switchMap((meetingItems) => {
                if (!meetingItems || meetingItems.length < 1) {
                    return this.kanbanUiService.openLinkMeetingAgendaItemDialog(this.item);
                } else {
                    return this.kanbanUiService.removeMeetingItem(meetingItems[0]);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private addItemLink() {
        // always save after add here as this menu won't be visible if item is newly added and not committed
        return this.kanbanUiService.openAddItemLinkDialog(this.item, true).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private cloneItem() {
        return this.kanbanUiService.cloneItem(this.item);
    }

    @Autobind
    private deleteItem() {
        return this.kanbanUiService.deleteItem(this.item).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.itemDeleted.emit(this.item));
    }

    @Autobind
    private closeItem() {
        return this.dialogService.openConfirmationDialog({
            title: "Archive action?",
            message: "Are you sure you wish to archive this action? This will cause it to be hidden in the standard actions view.",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).pipe(
            switchMap(() => {
                this.item.status = ItemStatus.Closed;
                return this.kanbanService.saveEntities([this.item]);
            }),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.itemClosed.emit(this.item));
    }

    @Autobind
    private reopenItem() {
        this.item.status = ItemStatus.ToDo;
        this.kanbanService.saveEntities([this.item])
            .subscribe(() => this.itemReopened.emit(this.item));
    }
}

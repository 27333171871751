<div class="d-flex justify-content-start flex-column">
    <!-- Do not use zoom here as the content has a dxMenu which will dx will do a transform translate relative to
        getBoundingClientRect() of the dxMenu component in the overlay hierarchy. Zoom will not affect result from
        that call resulting in the menu appearing way off from the clicking point.
    -->
    <adapt-display-objectives-hierarchy *ngFor="let group of objectiveGroups; let first=first"
                                        class="ms-3"
                                        [ngClass]="{ 'mt-3' : first, 'mt-5' : !first }"
                                        [objectiveGroup]="group"
                                        [(hasAnnualObjective)]="hasAnnualObjective"
                                        [(hasExternalParent)]="hasExternalParent"
                                        [currentTeamId]="currentTeamId"
                                        [isRoot]="true"
                                        [isCompactView]="isCompactView"></adapt-display-objectives-hierarchy>
</div>

import { Component, Inject, Injector } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { BullseyeQuadrantStatement } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { BullseyeStatementLocation } from "@common/ADAPT.Common.Model/organisation/bullseye-statement-location";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { StrategicViewIcon } from "@org-common/lib/strategy/strategy-view-constants";
import { debounceTime, startWith, Subject, switchMap } from "rxjs";
import { BullseyeService } from "../bullseye.service";

export interface IAttachBullseyeStatementDialogData {
    zone?: Zone;
    theme?: Theme;
}

@Component({
    selector: "adapt-attach-bullseye-statements-dialog",
    templateUrl: "./attach-bullseye-statements-dialog.component.html",
    styleUrls: ["./attach-bullseye-statements-dialog.component.scss"],
})
export class AttachBullseyeStatementsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<BullseyeStatementLocation[]> {
    public readonly dialogName = "AttachBullseyeStatement";
    public readonly StrategicViewIcon = StrategicViewIcon;

    public hasBullseyeStatements = false;
    public selectedZone?: Zone;
    public selectedTheme?: Theme;
    public selectedStatements: BullseyeQuadrantStatement[] = [];

    protected entitiesToConfirm: BullseyeStatementLocation[] = [];

    private updateDebouncer = new Subject<void>();
    private existingLocations: BullseyeStatementLocation[] = [];

    public constructor(
        injector: Injector,
        private bullseyeService: BullseyeService,
        @Inject(ADAPT_DIALOG_DATA) public data?: IAttachBullseyeStatementDialogData,
    ) {
        super(injector);

        this.selectedZone = data?.zone;
        this.selectedTheme = data?.theme;
        this.autoResolveData = this.entitiesToConfirm;
        bullseyeService.getBullseye().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((bullseye) => {
            this.isInitialised = true;
            this.hasBullseyeStatements = !!bullseye.quadrantStatements.find((statement) => statement.inBullseye);
        });

        this.updateDebouncer.pipe(
            startWith(undefined),
            debounceTime(200), // just want 1 update if both theme and zone changes, theme changed can cause zone to change
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateBullseyeStatementLocations());
    }

    public get hasValidationErrors() {
        return this.entitiesToConfirm.length < 1 || this.entitiesToConfirm.some((entity) => entity.entityAspect.hasValidationErrors);
    }

    public filterZone(zone: Zone) {
        return zone !== Zone.ResearchAndDevelopment;
    }

    public onStatementSelected(statement: BullseyeQuadrantStatement) {
        if (statement && this.selectedZone) {
            this.bullseyeService.createBullseyeStatementLocationWithStatement(statement).pipe(
                switchMap((newLocation) => {
                    newLocation.zone = this.selectedZone!;
                    newLocation.theme = this.selectedTheme;
                    this.entitiesToConfirm.push(newLocation);
                    return this.bullseyeService.updateBullseyeStatementLocationOrdinal(newLocation);
                }),
                this.takeUntilDestroyed(),
            ).subscribe(() => this.updateDebouncer.next());
        }
    }

    public onStatementDeselected(statement: BullseyeQuadrantStatement) {
        const unsavedLocation = this.entitiesToConfirm.find((location) =>
            location.bullseyeStatementId === statement.bullseyeQuadrantStatementId &&
            location.entityAspect.entityState.isAdded());
        if (unsavedLocation) {
            ArrayUtilities.removeElementFromArray(unsavedLocation, this.entitiesToConfirm);
            unsavedLocation.entityAspect.rejectChanges();
            this.updateDebouncer.next();
        } else {
            const existingLocation = this.existingLocations.find((location) => location.bullseyeStatementId === statement.bullseyeQuadrantStatementId);
            if (existingLocation) {
                this.bullseyeService.remove(existingLocation)
                    .subscribe(() => {
                        ArrayUtilities.addElementIfNotAlreadyExists(this.entitiesToConfirm, existingLocation);
                        this.updateDebouncer.next();
                    });
            }
        }
    }

    public onZoneChanged(zone: Zone) {
        this.selectedZone = zone;
        if (this.selectedTheme && this.selectedTheme.zone !== zone) {
            this.selectedTheme = undefined;
        }

        this.updateDebouncer.next();
    }

    public onThemeChanged(theme?: Theme) {
        this.selectedTheme = theme;
        if (theme) {
            this.selectedZone = theme.zone;
        }

        this.updateDebouncer.next();
    }

    private updateBullseyeStatementLocations() {
        if (this.selectedZone) {
            this.bullseyeService.getBullseyeStatementLocationsForZoneTheme(this.selectedZone, this.selectedTheme).pipe(
                this.takeUntilDestroyed(),
            ).subscribe((locations) => {
                this.existingLocations = locations;
                this.selectedStatements = locations.map((location) => location.bullseyeStatement);
            });
        }
    }
}

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";

@Component({
    selector: "adapt-display-item-status",
    templateUrl: "./display-item-status.component.html",
    styleUrls: ["./display-item-status.component.scss"],
})
export class DisplayItemStatusComponent implements OnChanges {
    @Input() public itemStatus?: ItemStatus;

    public metadata?: ItemStatusMetadata;
    public badgeClass = "";

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.itemStatus && this.itemStatus) {
            this.metadata = ItemStatusMetadata.All.find((i) => i.status === this.itemStatus);
            this.badgeClass = `badge rounded-pill badge-${this.itemStatus.toLowerCase()}`;
        }
    }
}

import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FeaturePermission } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-location";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { FeaturePermissionTranslatorService } from "@common/feature/feature-permission-translator.service";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { PersonService } from "@org-common/lib/person/person.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { Column } from "devextreme/ui/data_grid";
import { lastValueFrom } from "rxjs";

interface IGridData {
    access: string,
    module: string,
}

interface ITabItem {
    readonly title: string;
    readonly tooltip?: string;
    grid: {
        data: IGridData[];
        columns: GridColumn[];
    };
}

type GridColumn = string | Column;

@Component({
    selector: "adapt-user-access-summary-grid",
    templateUrl: "./user-access-summary-grid.component.html",
    styleUrls: ["./user-access-summary-grid.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class UserAccessSummaryGridComponent extends BaseComponent implements OnInit {
    @Input() public team?: Team;
    @Input() public person?: Person;
    @Input() public connection?: Connection;

    public sectionTabs: ITabItem[] = [];
    public hideEmptyRows: boolean | undefined = true;
    private hideEmptyRowsUpdater = this.createThrottledUpdater<boolean>((hideEmptyRows) => {
        this.hideEmptyRows = hideEmptyRows;
        return this.fetchData();
    });

    public loading: boolean = true;
    private loadingUpdater = this.createThrottledUpdater<boolean>((loading) => this.loading = loading);

    private allRoles: Role[] = []; // storing roles for the person from $onInit
    private allVisibleFeaturePermissions: FeaturePermission[] = [];

    public constructor(
        private personService: PersonService,
        private teamsService: CommonTeamsService,
        private translatorService: FeaturePermissionTranslatorService,
        private featureFactory: FeaturesService,
        private commonDataService: CommonDataService,
    ) {
        super();
    }

    public async ngOnInit() {
        // need to get all roles this person has to form columns
        // then get all feature permissions for those roles
        const allFeaturePermissions: FeaturePermission[] = await this.featureFactory.promiseToGetAllFeaturePermissions();
        this.allVisibleFeaturePermissions = allFeaturePermissions.filter((featurePermission) =>
            !this.featureFactory.isPlatformFeature(featurePermission.feature) &&
            !FeaturesService.NonConfigurableFeaturePermissions.includes(featurePermission.name));

        await this.fetchData();
    }

    public async toggleFilter(shouldHide?: boolean | null) {
        this.hideEmptyRowsUpdater.next(shouldHide ?? false);
    }

    private async fetchData() {
        this.loadingUpdater.next(true);

        // this makes sure the loading shows
        await new Promise(requestAnimationFrame);

        this.sectionTabs = [];
        const roles = await this.getRoles();

        const userAccessRoles = roles
            .filter(DirectorySharedService.isAccessLevelRole)
            .filter(DirectorySharedService.isNotTeamBasedRole)
            .filter((role) => !role.extensions.isCulturalLeaderRole());
        this.addTab("Organisation Access", userAccessRoles, "These access levels apply to the whole organisation, and can be managed in the Configuration -> Manage Access page");

        const culturalLeaderRoles = roles.filter((role) => role.extensions.isCulturalLeaderRole());
        this.addTab("Cultural Leader", culturalLeaderRoles, "Cultural Leader access can be managed in the Cultural Leadership Framework page");

        const teamLeaderRoles = roles.filter((role) => role.roleType?.code === RoleTypeCode.TeamLeader);
        this.addTab("Team Leader", teamLeaderRoles, "Team Leader and Team Member access can be managed in that teams Configuration Page");

        const teamMemberRoles = roles.filter((role) =>
            role.roleType?.code === RoleTypeCode.TeamMember || role.roleType?.code === RoleTypeCode.TeamParticipant);
        this.addTab("Team Member", teamMemberRoles, "Team Leader and Team Member access can be managed in that teams Configuration Page");

        const tier1LeaderRoles = roles.filter((role) => role.roleType?.code === RoleTypeCode.Tier1);
        if (tier1LeaderRoles.length > 0) {
            // make sure locations are primed
            await lastValueFrom(this.commonDataService.getAll(RoleLocationBreezeModel));

            // roleLocations is only used by value streams at the moment
            const [kfLeaderRoles, vsLeaderRoles] = ArrayUtilities.partition(tier1LeaderRoles, (role) => role.roleLocations.length === 0);
            this.addTab("Key Function Leader", kfLeaderRoles);
            this.addTab("Value Stream Leader", vsLeaderRoles);
        }

        this.loadingUpdater.next(false);
    }

    private addTab(title: string, roles: Role[], tooltip?: string) {
        if (roles.length > 0) {
            this.sectionTabs.push({
                title,
                tooltip,
                grid: {
                    data: this.getRowData(roles),
                    columns: this.getRoleColumns(roles),
                },
            });
        }
    }

    private async getRoles() {
        let roles: Role[];

        if (this.person) {
            const roleConnections = await lastValueFrom(this.personService.getActiveRoleConnections(this.person.personId));
            roles = roleConnections.map((rc) => rc.role);
        } else if (this.connection) {
            roles = this.connection.roleConnections
                .filter((roleConnection) => roleConnection.isActive() && roleConnection.role)
                .map((rc) => rc.role);
        } else if (this.team) {
            roles = await this.teamsService.promiseToGetTeamRoles(this.team);
        } else {
            throw new Error("Person, connection or team must be set.");
        }

        // KF/VS roles do not have permissions (yet), this will let them through once they do.
        roles = roles.filter((role) => role.extensions.hasAccessPermissions());

        return roles;
    }

    private getRoleColumns(roles: Role[]) {
        const gridColumns: GridColumn[] = [
            {
                caption: "",
                dataField: "access",
                dataType: "string",
                fixed: true,
                fixedPosition: "left",
                minWidth: 300,
            },
            {
                caption: "Module",
                dataField: "module",
                dataType: "string",
                allowGrouping: true,
                groupIndex: 0,
                groupCellTemplate: "moduleGroupCellTemplate",
            },
        ];

        for (const role of roles) {
            this.allRoles.push(role);

            const roleColumn: GridColumn = {
                caption: role.label,
                dataField: role.roleId.toString(),
                headerCellTemplate: this.team
                    ? "teamRoleHeaderTemplate"
                    : "roleHeaderTemplate",
                cellTemplate: "checkboxCellTemplate",
                dataType: "boolean",
                allowSorting: false,
                allowFixing: false,
                width: 100,
            };

            gridColumns.push(roleColumn);
        }

        return gridColumns;
    }

    private getRowData(roles: Role[]) {
        const gridData: IGridData[] = [];

        if (!this.hideEmptyRows) {
            for (const featurePermission of this.allVisibleFeaturePermissions) {
                this.getRow(gridData, featurePermission, this.team);
            }
        }

        for (const role of roles) {
            const filteredFeaturePermissions = role.roleFeaturePermissions.filter((roleFeaturePermission) => !this.featureFactory.isPlatformFeature(roleFeaturePermission.featurePermission.feature))
                .filter((roleFeaturePermission) => this.featureFactory.isRoleFeaturePermissionForActiveFeature(roleFeaturePermission));
            for (const roleFeaturePermission of filteredFeaturePermissions) {
                const row = this.getRow(gridData, roleFeaturePermission.featurePermission, role.team);
                row[role.roleId.toString()] = true;
            }
        }

        return gridData;
    }

    private getRow(gridData: IGridData[], featurePermission: FeaturePermission, team?: Team): any {
        const moduleName = this.translatorService.translateFeatureModule(featurePermission.feature.featureModule);
        const featurePermissionDesc = this.translatorService.translateFeaturePermission(featurePermission, team);

        const existingRow = gridData.find((row) => row.access === featurePermissionDesc && row.module === moduleName);
        if (existingRow) {
            return existingRow;
        }

        const newRow: IGridData = {
            access: featurePermissionDesc,
            module: moduleName,
        };

        gridData.push(newRow);
        return newRow;
    }

    public getRoleFromId(roleIdString: string) {
        const roleId = Number(roleIdString);
        return this.allRoles.find((role) => role.roleId === roleId);
    }
}

<div class="card">
    <div class="card-header">
        <h3 class="card-title">Payment Method</h3>
    </div>
    <div class="card-body"
         *adaptLoadingUntilFirstEmit="let account of account$">
        <ng-container *ngIf="!account?.extensions?.isActive && !account?.extensions?.isTrial; else activeSubscription">
            <p>Your organisation's subscription status is currently not active.</p>
            <button class="btn btn-primary"
                    (click)="showUpdateInvoicePaymentMethodDialog(account)">{{getSubscriptionButtonText(account)}}</button>
        </ng-container>
        <ng-template #activeSubscription>
            <ng-container *ngIf="account && account.extensions.isBilledUsingCreditCard">
                <adapt-configure-credit-card [organisationId]="account.organisationId"></adapt-configure-credit-card>
            </ng-container>
            <ng-container *ngIf="account && !account.extensions.isBilledUsingCreditCard">
                <p>Your organisation is being invoiced.</p>
                <button class="btn btn-primary"
                        (click)="showUpdateInvoicePaymentMethodDialog(account)">Update payment method</button>
            </ng-container>
        </ng-template>
    </div>
</div>

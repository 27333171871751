import { Component, ElementRef, Injector, Input } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { setPageActivationSearchParam, setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { StrategicViewOption } from "@org-common/lib/strategy/strategy-view-constants";
import { StrategyBoardPageRoute, StrategyBoardViewParam } from "@org-common/lib/strategy-board/strategy-board-page/strategy-board-page.component";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";

@MeetingAgendaItemComponent("adapt-strategy-board-agenda-item")
@Component({
    selector: "adapt-strategy-board-agenda-item",
    templateUrl: "./strategy-board-agenda-item.component.html",
})
export class StrategyBoardAgendaItemComponent extends BaseComponent {
    @Input() public buttonText = "See The Strategy Board";
    @Input() public pageActivationTours: TourIdentifier[] = [];
    public readonly ReadStrategyBoard = StrategyAuthService.ReadStrategyBoard;

    public constructor(
        elementRef: ElementRef,
        private injector: Injector,
    ) {
        super(elementRef);
    }

    @Autobind
    public gotoStrategyBoard() {
        const views = [
            StrategicViewOption.CAInputs,
            StrategicViewOption.SWTInputs,
            StrategicViewOption.Bullseye,
            StrategicViewOption.Goals,
            StrategicViewOption.HealthCheck,
        ];

        let searchParams = setReturnPathSearchParam(
            this.injector,
            { [StrategyBoardViewParam]: views.join(",") },
            StrategyBoardPageRoute.id,
            "Return to meeting");
        if (this.pageActivationTours?.length) {
            searchParams = setPageActivationSearchParam(searchParams, StrategyBoardPageRoute.id, this.pageActivationTours);
        }

        return StrategyBoardPageRoute.gotoRoute(undefined, searchParams);
    }
}

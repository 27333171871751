import { Component } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { EditStrategicGoalBaseComponent } from "../edit-strategic-goal-base/edit-strategic-goal-base.component";

@WorkflowStepComponent("adapt-edit-goal-location-name")
@Component({
    selector: "adapt-edit-goal-location-name",
    templateUrl: "./edit-goal-location-name.component.html",
    styleUrls: ["./edit-goal-location-name.component.scss"],
})
export class EditGoalLocationNameComponent extends EditStrategicGoalBaseComponent {
    public readonly GuidanceArticleId = ImplementationKitArticle.StrategicGoalLocationNameEdit;

    public get isNew() {
        return this.goal?.entityAspect.entityState.isAdded();
    }

    public workflowStepOnInit() {
        super.workflowStepOnInit(); // this will extra goal from runData
        this.updateCompletion();
    }

    public updateCompletion() {
        this.workflowStepCompleted.next(!!this.goal?.name.trim());
    }

    // Not defining goals for R&D
    public filterZone(zone: Zone) {
        return zone !== Zone.ResearchAndDevelopment;
    }
}

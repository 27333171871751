import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { Zone } from "../methodology/zone";
import { GoalExtensions } from "./goal-extensions";
import { Measurement } from "./measurement";
import { Organisation } from "./organisation";
import { Theme } from "./theme";

export enum GoalStatus {
    OnTrack = "OnTrack",
    Slow = "Slow",
    Stuck = "Stuck",
    Closed = "Closed",
}

export const OrderedGoalStatuses: GoalStatus[] = [
    GoalStatus.OnTrack,
    GoalStatus.Slow,
    GoalStatus.Stuck,
    // TODO: Add Closed state here if we want to see that in select-goal-status component - disabled for now
];

export const GoalStatusLabel: { [status in GoalStatus]: string } = {
    [GoalStatus.OnTrack]: "On Track",
    [GoalStatus.Slow]: "Slow",
    [GoalStatus.Stuck]: "Stuck",
    [GoalStatus.Closed]: "Closed",
};

export class Goal extends BaseEntity<Goal> {
    public goalId!: number;
    public organisationId!: number;
    public name!: string;
    public unit?: string;
    public zone!: Zone;
    public status!: GoalStatus;
    public ordinal!: number;
    public description?: string;
    public themeId?: number;

    // navigation properties
    public organisation!: Organisation;
    public measurements!: Measurement[];
    public theme?: Theme;

    @EntityExtensions(GoalExtensions)
    public extensions!: GoalExtensions;
}

export const GoalBreezeModel = new BreezeModelBuilder("Goal", Goal)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .alwaysFetchingNavigationProperty("measurements")
    .build();

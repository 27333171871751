import { Component, Inject } from "@angular/core";
import { IAddPeopleBindingModel, IAddPeopleConfig } from "@common/identity/add-people-binding-model.interface";
import { IdentityService } from "@common/identity/identity.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";

@Component({
    selector: "adapt-add-people-dialog",
    templateUrl: "./add-people-dialog.component.html",
})
export class AddPeopleDialogComponent extends BaseDialogComponent<IAddPeopleConfig | undefined, void> {
    public readonly dialogName = "AddPeople";

    public isValid = false;
    public errorMessage?: string;
    public addPeopleModel?: IAddPeopleBindingModel;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public config: IAddPeopleConfig | undefined,
        private identityService: IdentityService,
        private userManagementService: UserManagementService,
    ) {
        super(DialogResolveData.NotRequired);
    }

    @Autobind
    public async addAndSave() {
        try {
            await this.identityService.addPeople(this.addPeopleModel!);
            this.userManagementService.emitPeopleAdded();
            this.resolve();
        } catch (error) {
            const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(error);
            this.errorMessage = errorMessage;
        }
    }
}

import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { CollapseDirective } from "ngx-bootstrap/collapse";

@Component({
    selector: "adapt-collapsible-dashboard-element",
    templateUrl: "./collapsible-dashboard-element.component.html",
    styleUrls: ["./collapsible-dashboard-element.component.scss"],
})
export class CollapsibleDashboardElementComponent {
    @Input() public name?: string;
    @Input() public iconClass?: string;
    @Input() public iconTooltip?: string;
    @Input() public handleId?: string;

    @Input() public isExpandable = true;
    @Input() public isCollapsible = true;
    @Input() public collapsed = false;
    @Input() public info?: string;
    @Output() public collapsedChange = new EventEmitter<boolean>();
    @Output() public afterAnimation = new EventEmitter<boolean>();

    public linkObject?: IAdaptLinkObject;
    @Input() public set url(value: string | IAdaptLinkObject | undefined) {
        this.linkObject = this.routeService.parseRouterObject(value);
    }

    public constructor(private routeService: RouteService, private elementRef: ElementRef) { }

    public scrollIntoView() {
        this.elementRef.nativeElement.scrollIntoView({ behavior: "smooth" });
    }

    public toggle() {
        if (this.isCollapsible) {
            this.collapsed = !this.collapsed;
            this.collapsedChange.emit(this.collapsed);
        }
    }

    public animationCompleted(e: CollapseDirective) {
        // ngx-bootstrap actually calls this before the animation completed from (collapsed/expanded)
        // - add a bit more delay there
        setTimeout(() => this.afterAnimation.emit(e.collapse), 250);
    }
}

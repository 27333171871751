import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { combineLatest, Observable, of, ReplaySubject } from "rxjs";
import { debounceTime, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { ObjectiveFilter } from "../objective-filter/objective-filter";
import { ObjectiveFilterParamKeys } from "../objective-filter/objective-filter.component";
import { ObjectiveFilterService } from "../objective-filter/objective-filter.service";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { IObjectiveGroup, ObjectivesService } from "../objectives.service";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesRouteService } from "../objectives-route.service";
import { ObjectivesUiService } from "../objectives-ui.service";

@Component({
    selector: "adapt-display-team-objectives",
    templateUrl: "./objectives-page.component.html",
})
export class ObjectivesPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    // can't use enum in template without this
    public readonly ViewType = ObjectiveViewType;
    public readonly ObjectiveFilterParamKeys = Object.values(ObjectiveFilterParamKeys);

    public objectiveFilter$ = new ReplaySubject<ObjectiveFilter>(1);
    public teamId$ = new ReplaySubject<number | undefined>(1);
    public teamObjectivesGroups$: Observable<IObjectiveGroup[]>;
    public currentView = ObjectiveViewType.ObjectiveTreeView;
    public teamId?: number;
    public hasEditPermissions$: Observable<boolean>;
    public team$: Observable<Team | undefined>;
    private readonly ViewQueryParam = "view";

    private article = this.isAlto ? ImplementationKitArticle.ObjectivesOverview : ImplementationKitArticle.IntroducingObjectivesAndKeyResults;
    public learnMoreUrl = ImplementationKitService.GetArticleLink(this.article);

    public constructor(
        injector: Injector,
        private objectivesService: ObjectivesService,
        private objectiveRouteService: ObjectivesRouteService,
        private objectivesUiService: ObjectivesUiService,
        private objectivesAuthService: ObjectivesAuthService,
        public filterService: ObjectiveFilterService,
        teamsService: CommonTeamsService,
        labellingService: LabellingService,
    ) {
        super(injector);

        this.teamObjectivesGroups$ = combineLatest([this.teamId$, this.objectiveFilter$]).pipe(
            tap(() => this.isInitialised = false),
            debounceTime(200),
            switchMap(([teamId, filter]) => {
                if (filter.objectiveStatuses.size > 0 && !(filter.onlyClosedAndSupporting)) {
                    return this.objectivesService.getPrimedObjectives(filter, teamId);
                } else {
                    return of([]);
                }
            }),
            // prime label locations
            switchMap((objectives) => labellingService.primeLabelLocationsForObjectives(this.objectivesService.getAllObjectives(objectives)).pipe(
                map(() => objectives),
            )),
            withLatestFrom(this.teamId$, this.objectiveFilter$),
            map(([data, teamId, filter]) => {
                if (!filter.showExternalObjectives && teamId) {
                    // parents won't be included when not showing related on a team page.
                    // we don't need to group differently for org. objectives
                    // since child team objectives can be filtered out later
                    return this.objectivesService.groupObjectives(data);
                }
                return this.objectivesService.groupObjectivesIncludingExternalParents(data);
            }),
            tap(() => this.isInitialised = true),
        );

        this.hasEditPermissions$ = this.teamId$.pipe(
            switchMap((teamId) => this.objectivesAuthService.hasWriteAccessToObjective(teamId)),
        );

        this.team$ = this.teamId$.pipe(
            switchMap((teamId) => teamId ? teamsService.getTeamById(teamId) : of(undefined)),
        );
    }

    public ngOnInit() {
        this.updateData();

        this.objectivesService.objectiveUpdated$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.onObjectiveChanged());

        this.updateViewType();

        this.navigationEnd.subscribe(() => this.updateData());
    }

    private updateData() {
        this.teamId = this.getRouteParamInt("teamId");
        this.teamId$.next(this.teamId);

        this.verifyHasAccessToRoute(this.objectivesAuthService.hasReadAccessToObjective(this.teamId));

        const sub = this.teamObjectivesGroups$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            sub.unsubscribe();
            this.notifyActivated();
        });
    }

    private updateViewType() {
        const viewParam = this.getSearchParameterIntValue(this.ViewQueryParam);
        if (viewParam) {
            this.currentView = viewParam;
        } else {
            this.currentView = ObjectiveViewType.ObjectiveTreeView;
        }
    }

    public onViewChanged(view: ObjectiveViewType) {
        this.setSearchParameterValue(this.ViewQueryParam, view);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        this.teamId$.complete();
        this.objectiveFilter$.complete();
    }

    public filterChanged(filter: ObjectiveFilter) {
        this.objectiveFilter$.next(filter);
    }

    @Autobind
    public addObjective() {
        if (AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto) {
            return this.objectivesUiService.createObjectiveForEventTypePreset(EventTypePreset.AnnualStrategy).pipe(
                switchMap(() => of(undefined)));
        } else {
            return this.objectivesUiService.createObjective(this.teamId).pipe(
                switchMap((objective) => this.objectiveRouteService.gotoEditObjectivePageRoute(objective.objectiveId, objective.teamId)),
            );
        }

    }

    public onObjectiveChanged() {
        this.teamId$.next(this.teamId);
    }
}

type Promisified<T> = {
    [P in keyof T]: Promise<T[P]>;
};

export interface IDeferred<T> {
    resolve(value?: T | Promise<T>): void;

    reject(reason?: any): void;

    promise: Promise<T>;
}

export class PromiseUtilities {
    /**
     * Promise to validate whether a promise resolves or rejects
     * @param promise The promise to validate
     * @returns Whether the promise resolves or rejects
     */
    public static promiseToValidatePromiseResolution(promise: Promise<any>) {
        return promise
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Given an object of {key: Promise}, resolve all the promises and return an object of {key: result}
     * @param promises object of promises to resolve
     */
    public static async fromObject<T extends { [key: string]: any }>(promises: Promisified<T>): Promise<T> {
        const promiseList = Object.entries(promises)
            .map(([name, promise]: [string, Promise<any>]) => promise.then((result) => [name, result]));
        const entries = await Promise.all(promiseList);
        return Object.fromEntries(entries);
    }

    /**
     * Implementation of $q.defer() using native Promises.
     */
    public static defer<T = void>(): IDeferred<T> {
        return (() => {
            let resolve!: (value: T | Promise<T>) => void;
            let reject!: (reason?: any) => void;

            const p = new Promise<T>((res, rej) => {
                resolve = res;
                reject = rej;
            });

            return {
                promise: p,
                reject,
                resolve,
            };
        })();
    }
}

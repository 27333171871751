import { Component, ElementRef, Injector, Input } from "@angular/core";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { Measurement } from "@common/ADAPT.Common.Model/organisation/measurement";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject } from "rxjs";
import { StrategicGoalsService } from "../strategic-goals.service";

@Component({
    selector: "adapt-edit-strategic-goal-base",
    templateUrl: "./edit-strategic-goal-base.component.html",
    styleUrls: ["./edit-strategic-goal-base.component.scss"],
})
export class EditStrategicGoalBaseComponent extends WorkflowStepComponentAdapter {
    @Input() public guidanceArticleId?: ImplementationKitArticle;
    @Input() public goal?: Goal;

    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        protected strategicGoalsService: StrategicGoalsService,
    ) {
        super(elementRef);

        this.subscribeToEmitForEntityTypeChange(injector, Goal);
        this.subscribeToEmitForEntityTypeChange(injector, Measurement);
    }

    public workflowStepOnInit() {
        this.goal = this.workflowStep?.workflow.runData;
    }
}

<adapt-dashboard-element *adaptIfAlto="false"
                         [name]="dashboardTitle"
                         [disableClick]="(canEditTeamGuidance$ | async) === false"
                         (headerClicked)="editTeamGuidance()">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-dashboard-element>

<adapt-dashboard-element-hq *adaptIfAlto="true"
                            [name]="dashboardTitle"
                            [disableClick]="(canEditTeamGuidance$ | async) === false"
                            (headerClicked)="editTeamGuidance()"
                            linkText="Define the team guidance"
                            [contentCard]="true">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-dashboard-element-hq>

<ng-template #content>
    <div *adaptLoadingUntilFirstEmit="let content of content$">
        <div *ngIf="content; else noContent">
            <p [froalaView]="content"></p>
        </div>
        <ng-template #noContent>
            <adapt-call-to-action-inline>
                <p>Setting your team guidance allows you to define:</p>
                <ul class="list-content">
                    <li>Your team purpose</li>
                    <li>Your team vision</li>
                    <li>Resources your team uses</li>
                    <li>General Team information</li>
                </ul>

                <ng-container *adaptIfAlto="false">
                    <button adaptCallToActionButton
                            *adaptIfAuthorised="EditPurposeVision;Entity:team$ | async"
                            adaptButtonIcon="edit"
                            (click)="editTeamGuidance()">
                        Define the team guidance
                    </button>
                </ng-container>
            </adapt-call-to-action-inline>
        </ng-template>
    </div>
</ng-template>

<div *adaptLoading="!value"
     class="row">
    <div class="col-12 mb-3 mb-xl-0 col-xl d-flex align-items-center justify-content-center">
        <h2 class="mb-0 text-center">{{value!.name}}</h2>
    </div>
    <div class="col-12 col-xl-9 col-xxl-10">
        <div class="row g-3">
            <div class="col-12 col-lg-6 d-flex flex-column">
                <h4>Meaning</h4>
                <div class="value-content flex-grow-1">
                    <div *ngIf="value!.meaning; else noMeaningTemplate"
                         [froalaView]="value!.meaning"></div>
                    <ng-template #noMeaningTemplate>
                        This value's meaning has not been defined.
                    </ng-template>
                </div>
            </div>
            <div class="col-12 col-lg-6 d-flex flex-column">
                <h4 *adaptIfAlto="false">Ask Yourself</h4>
                <h4 *adaptIfAlto="true">Behaviours</h4>

                <div class="value-content value-questions flex-grow-1">
                    <ng-container *adaptIfAlto="false">
                        <ul *ngIf="value!.valueQuestions.length > 0; else noQuestionsTemplate">
                            <!-- TODO Handle ArrayChanged event in and order by ordinal -->
                            <li *ngFor="let valueQuestion of value!.valueQuestions"
                                [froalaView]="valueQuestion.question"></li>
                        </ul>
                        <ng-template #noQuestionsTemplate>
                            No questions have been defined.
                        </ng-template>
                    </ng-container>

                    <ng-container *adaptIfAlto="true">
                        <div *ngIf="value!.valueQuestions.length > 0; else noBehavioursTemplate"
                             class="d-flex flex-column gap-3">
                            <div *ngFor="let valueQuestion of value!.valueQuestions">
                                <adapt-edit-behaviour [behaviour]="valueQuestion"
                                                      [readonly]="true"></adapt-edit-behaviour>
                            </div>
                        </div>
                        <ng-template #noBehavioursTemplate>
                            No behaviours have been defined.
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

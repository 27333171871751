<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>Start adapt HQ subscription</h3>

    <ng-container adapt-dialog-content>
        <div class="p-2 fs-3">
            <h2 class="mb-3"><strong>Your organisation's journey is just getting started!</strong></h2>
            <p>Subscribe now to continue growing your business with adapt HQ.</p>

            <ul class="feature-list">
                <li><i class="fas fa-check"></i> Set a firm foundation for your business strategy</li>
                <li><i class="fas fa-check"></i> Grow a culture of resilience</li>
                <li><i class="fas fa-check"></i> Align your leadership team for long term success</li>
                <li><i class="fas fa-check"></i> Cancel at any time</li>
            </ul>

            <p class="mt-3 mb-0">Our introductory subscription price is only ${{monthlyPrice}} <small>(ex. GST)</small> per month.</p>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   (cancelClick)="cancel()"
                                   saveButtonPreset="primary"
                                   saveText="Subscribe now"
                                   (saveClick)="save()"></adapt-standard-dialog-buttons>
</adapt-dialog>

<div class="card p-0"
     [ngClass]="{highlight: highlight, editable: editable, 'deny-drag': denyDrag}">
    <ng-container *ngIf="!isInitialised; else displayCardDetails">
        <!-- swap this in with min height to not render content when not in view to resolve performance issue -->
        <div class="card-body p-0">
            <div class="card-placeholder"></div>
        </div>
    </ng-container>
    <ng-template #displayCardDetails>
        <div class="card-body">
            <div class="d-flex flex-row align-items-center"
                 data-test="kanban-header">
                <adapt-link-person *ngIf="showAssignee"
                                   class="me-1"
                                   [person]="item.assignee"
                                   [imageOnly]="true"></adapt-link-person>
                <adapt-item-link [item]="item"
                                 [displayAsBadge]="true"
                                 [showSummary]="true"
                                 (itemDialogOpened)="dialogOpened.emit($event)"
                                 (itemDialogClosed)="dialogClosed.emit($event)"></adapt-item-link>
                <adapt-item-action-menu class="ms-auto me-n1"
                                        [item]="item"
                                        [showOpenOption]="true"
                                        (itemClosed)="itemSelected.emit(undefined)"
                                        (itemDeleted)="itemSelected.emit(undefined)"
                                        (dialogOpened)="dialogOpened.emit($event)"
                                        (dialogClosed)="dialogClosed.emit($event)"></adapt-item-action-menu>
            </div>
            <adapt-display-labels [labelLocations]="item.labelLocations"></adapt-display-labels>
            <div class="d-flex flex-row">
                <div *ngIf="item.comments && item.comments.length > 0"
                     adaptTooltip="{{item.comments.length}} comments"
                     class="mt-1 ms-auto align-items-center">
                    <span class="fal fa-fw fa-comment-dots"></span>
                    <span>{{item.comments.length}}</span>
                </div>
            </div>
            <div *ngIf="item.dueDate"
                 class="d-flex flex-row-reverse small">
                Due: {{item.dueDate | adaptDate}}
            </div>
        </div>
        <div *ngIf="sortedLinks?.length || item.objectiveItemLinks?.length"
             class="card-footer p-0">
            <div class="d-flex flex-column item-links-section">
                <adapt-item-link *ngFor="let link of sortedLinks"
                                 [item]="link.secondaryItem"
                                 [showDialogLink]="true"
                                 [showStatus]="true"
                                 [showSummary]="true"></adapt-item-link>
                <adapt-link-objective *ngFor="let link of item.objectiveItemLinks"
                                      [objective]="link.objective"></adapt-link-objective>
            </div>
        </div>
    </ng-template>
</div>

import { DefaultBackNextButtons, DefaultFinishButtons, GuidedTour, nextStep } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

function createHasObjectiveFunction(exist: boolean, title: string) {
    return () => {
        const objectiveTitle = GuidedTourUtils.getFirstElementWithTextContent(`.objective-type[title="${title}"]`);
        return GuidedTourUtils.isElementVisible(objectiveTitle) === exist;
    };
}

export const HasNoAnnualObjectiveFunction = createHasObjectiveFunction(false, "Annual Objective");
export const HasAnnualObjectiveFunction = createHasObjectiveFunction(true, "Annual Objective");
export const HasNoQuarterlyObjectiveFunction = createHasObjectiveFunction(false, "Quarterly Objective");
export const HasQuarterlyObjectiveFunction = createHasObjectiveFunction(true, "Quarterly Objective");


export const ObjectivesWrapUpTour: GuidedTour = [{
    title: "Introduction",
    text: "This tour will walk you through what you have just defined in your <i>Set First Objectives</i> pathway.",
    buttons: [{
        classes: "btn btn-primary",
        text: "Proceed",
        action: nextStep,
    }],
}, {
    title: "Open the menu",
    text: `<p>This menu allows you to navigate between pages on the platform.
        Your objectives can be accessed from under the <b>Organisation</b> menu section.
        <p><b>Click on here now</b> to open the menu.</p>`,
    attachTo: {
        element: "#sidebar-menu i",
        on: "right",
    },
    advanceOn: {
        selector: "#sidebar-menu",
        event: "click",
    },
    showOn: () => {
        const objectivesNode = GuidedTourUtils.getFirstElementWithTextContent(
            "adapt-display-link-hierarchy adapt-display-navigation-node span",
            "Objectives");
        return !GuidedTourUtils.isElementVisible(objectivesNode);
    },
}, {
    waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
    elementSelectorTextContent: "Objectives",
    advanceOnAttachedElementEvent: "click",
    waitForPageActivationAfterEvent: true,
    attachTo: {
        on: "bottom-end",
    },
    title: "Access objectives",
    text: `<p>Your organisation's objectives are available here.</p>
        <p><b>Click on it now</b> to show it.</p>`,
    modalOverlayOpeningPadding: 3,
}, {
    title: "No annual objectives",
    text: `<p>There are no annual objectives which are supposed to be defined in the previous pathway.</p>
        <p>The guided tour cannot proceed unless there are annual objectives defined.</p>`,
    buttons: DefaultFinishButtons,
    showOn: HasNoAnnualObjectiveFunction,
}, {
    title: "No quarterly objectives",
    text: `<p>There are no quarterly objectives which are supposed to be defined in the previous pathway.</p>
        <p>The guided tour cannot proceed unless there are annual objectives defined.</p>`,
    buttons: DefaultFinishButtons,
    showOn: HasNoQuarterlyObjectiveFunction,
}, {
    title: "Annual objectives",
    text: `<p>Annual objectives are indicated by <b>AO</b> as shown here.</p>`,
    waitForAndAttachToElementSelector: `.objective-type[title="Annual Objective"]`,
    attachTo: {
        on: "right",
    },
    showOn: HasAnnualObjectiveFunction,
    buttons: DefaultBackNextButtons,
    modalOverlayOpeningPadding: 3,
}, {
    title: "Quarterly objectives",
    text: `<p>Quarterly objectives are indicated by <b>QO</b> as shown here.</p>`,
    waitForAndAttachToElementSelector: `.objective-type[title="Quarterly Objective"]`,
    attachTo: {
        on: "right",
    },
    showOn: HasQuarterlyObjectiveFunction,
    buttons: DefaultBackNextButtons,
    modalOverlayOpeningPadding: 3,
}, {
    title: "Focusing on objective type",
    text: `<p>You can set focus on any particular type of objectives through the filter option here</p>
        <p><b>Click on here now</b> to see the options.</p>`,
    waitForAndAttachToElementSelector: `[data-test="filter-button"]`,
    advanceOnAttachedElementEvent: "click",
    attachTo: {
        on: "left",
    },
    modalOverlayOpeningPadding: 3,
    showOn: HasQuarterlyObjectiveFunction,
}, {
    title: "Focusing on quarterly objectives",
    text: `<p>Here is where you can select the particular type of objectives</p>
        <p><b>Click on here now</b> to try it out.</p>`,
    waitForAndAttachToElementSelector: `[data-test="filter-objective-type"]`,
    advanceOnAttachedElementEvent: "click",
    attachTo: {
        on: "top",
    },
    modalOverlayOpeningPadding: 3,
    showOn: HasQuarterlyObjectiveFunction,
}, {
    title: "Select quarterly objectives",
    text: `<p><b>Click here</b> to select Quarterly Objectives</p>`,
    waitForAndAttachToElementSelector: `.dx-list-item-content`,
    elementSelectorTextContent: "Quarterly Objectives",
    advanceOnAttachedElementEvent: "click",
    attachTo: {
        on: "top",
    },
    modalOverlayOpeningPadding: 3,
    showOn: HasQuarterlyObjectiveFunction,
}, {
    title: "Remove filter type",
    text: `<p>Notice that the quarterly objectives are now in focus, while the annual objectives are greyed out.</p>
        <p><b>Click here</b> to remove the focus filter</p>`,
    waitForAndAttachToElementSelector: `[data-test="filter-objective-type"] .dx-clear-button-area`,
    advanceOnAttachedElementEvent: "click",
    attachTo: {
        on: "top",
    },
    modalOverlayOpeningPadding: 3,
    showOn: HasQuarterlyObjectiveFunction,
}, {
    title: "Edit an objective",
    text: `<p>This is the menu which will consist of actions that can be performed on an objective.</p>
        <p><b>Click on it now</b> to see what's available.</p>`,
    waitForAndAttachToElementSelector: `[data-test="objective-actions"]`,
    modalOverlayOpeningPadding: 3,
    attachTo: {
        on: "bottom",
    },
    advanceOnAttachedElementEvent: "click",
    showOn: HasQuarterlyObjectiveFunction,
}, {
    title: "Continue",
    text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
    waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
    attachTo: {
        on: "right",
    },
    canClickTarget: false,
    buttons: DefaultFinishButtons,
    showOn: HasQuarterlyObjectiveFunction,
}];

<ng-container *adaptLoading="!inputGroup">
    <div class="card">
        <h3 class="card-header">{{inputGroup?.name}}
            <button *adaptIfAuthorised="EditStrategicInputs"
                    adaptButton="editBorderless"
                    class="ms-auto me-2"
                    [adaptBlockingClick]="editInputGroup"></button>
        </h3>
        <div class="card-body d-flex flex-column">
            <div [froalaView]="inputGroup?.description"></div>
            <adapt-collapsible-dashboard-element *ngFor="let inputType of inputTypes"
                                                 class="mt-2"
                                                 [name]="InputTypeMetadata[inputType].singularLabel"
                                                 [iconClass]="InputTypeMetadata[inputType].iconClass"
                                                 [collapsed]="!inputsOfType.get(inputType)?.length"
                                                 [isCollapsible]="false">
                <button *adaptIfAuthorised="EditStrategicInputs"
                        adapt-collapsible-header-action-right
                        adaptButton="addBorderless"
                        [adaptBlockingClick]="createInputOfType"
                        [adaptBlockingClickParam]="inputType"></button>
                <ng-container *ngIf="inputsOfType.get(inputType) as inputs">
                    <adapt-strategic-input *ngFor="let input of inputs; let first = first"
                                           [ngClass]="{'mt-2': !first}"
                                           [input]="input"
                                           [isCollapsible]="true"
                                           [isEditing]="true"></adapt-strategic-input>
                </ng-container>
            </adapt-collapsible-dashboard-element>
        </div>
    </div>
</ng-container>

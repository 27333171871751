import { Directive, Input, OnInit } from "@angular/core";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ISearchResultMatch } from "./search.interface";
import { SearchService } from "./search.service";

@Directive()
export abstract class SearchRowBaseComponent<TSearchResult> extends BaseComponent implements OnInit {
    @Input() public result!: TSearchResult;
    @Input() public matches: ISearchResultMatch[] = [];

    @Input() public keyword?: string;
    @Input() public showContainer = true;

    public labelLocations: LabelLocation[] = [];

    public constructor(protected searchService: SearchService) {
        super();
    }

    public ngOnInit() {
        if (!this.keyword) {
            this.searchService.searchOptions$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe(({ keyword }) => this.keyword = keyword!);
        }
    }

    public get firstMatch() {
        return this.matches[0];
    }

    public get remainingMatches() {
        return this.matches.slice(1);
    }

    public excludeBreadcrumbs(exclude: string[], matches: ISearchResultMatch[]) {
        return matches.filter((m) => exclude.every((f) => !m.Field.includes(f)));
    }

    public excludeNameBreadcrumb(matches: ISearchResultMatch[]) {
        return this.excludeBreadcrumbs([SearchService.NameBreadcrumb], matches);
    }

    public withoutLastBreadcrumb(matches: ISearchResultMatch[]) {
        return matches.reduce((acc, result) => {
            acc.push({
                // remove the last breadcrumb if more than 1
                // (it's the field e.g. Name or Comment, normally redundant)
                ...result,
                Field: result.Field.length > 1 ? result.Field.slice(0, result.Field.length - 1) : result.Field,
            });
            return acc;
        }, [] as ISearchResultMatch[]);
    }
}

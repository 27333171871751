import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptDashboardElementHqModule } from "@common/ux/dashboard-element-hq/dashboard-element-hq.module";
import { AdaptOrgCommonPagesModule } from "@org-common/lib/pages/pages.module";
import { provideThemeTestPage } from "@org-common/lib/pages/theme-test/theme-test-page";
import { AdaptWorkflowModule } from "@org-common/lib/workflow/workflow.module";
import { AltoComponentsPageComponent } from "./theme-test/alto-components-page/alto-components-page.component";
import { altoComponentsPageRoute } from "./theme-test/alto-components-page/alto-components-page.route";

@NgModule({
    imports: [
        CommonModule,
        AdaptOrgCommonPagesModule,
        AdaptWorkflowModule,
        AdaptDashboardElementHqModule,
    ],
    declarations: [
        AltoComponentsPageComponent,
    ],
    providers: [
        provideThemeTestPage({ route: altoComponentsPageRoute, title: "2c. Alto Components" }),
    ],
})
export class AdaptAltoAboutModule {
    public static readonly Routes = [
        ...altoComponentsPageRoute.routes,
    ];
}

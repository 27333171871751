import { Component, Injector, ViewChild } from "@angular/core";
import { Event } from "@common/ADAPT.Common.Model/organisation/event";
import { EventSeries } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { IItem, ISection, TimeSchedulerPeriodYear, TimeSchedulerPeriodYearPlusHalf } from "@common/ux/time-scheduler/time-scheduler.interface";
import { TimeSchedulerWrapperComponent } from "@common/ux/time-scheduler/time-scheduler-wrapper/time-scheduler-wrapper.component";
import { CalendarIntegrationUtilities } from "@org-common/lib/calendar/calendar-integration-utilities";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { OAuthService } from "@org-common/lib/oauth/oauth.service";
import { IReviewRecurrencesMap } from "@org-common/lib/schedule/review-recurrences/review-recurrences.component";
import { ISetCadenceRunData } from "@org-common/lib/schedule/schedule.interface";
import { ScheduleService } from "@org-common/lib/schedule/schedule.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import moment from "moment";
import { BehaviorSubject, catchError, forkJoin, lastValueFrom, of, switchMap, throwError } from "rxjs";
import { tap } from "rxjs/operators";

@WorkflowStepComponent("adapt-review-cadence-step")
@Component({
    selector: "adapt-review-cadence-step",
    templateUrl: "./review-cadence-step.component.html",
})
export class ReviewCadenceStepComponent extends WorkflowStepComponentAdapter {
    public runData!: ISetCadenceRunData;
    public workflowStepCompleted = new BehaviorSubject<boolean>(true);

    // start display at this month (doesn't make sense to be scheduling in the past)
    public schedulerStartTime = moment().startOf("month").hour(9);

    public recurrences?: IReviewRecurrencesMap;

    @ViewChild(TimeSchedulerWrapperComponent) public scheduler?: TimeSchedulerWrapperComponent;
    public sections: ISection[] = [];
    public items: IItem<Event>[] = [];

    public currentPeriod = TimeSchedulerPeriodYearPlusHalf;

    private calendarIntegrationUtilities: CalendarIntegrationUtilities;

    public constructor(
        injector: Injector,
        private commonDataService: CommonDataService,
        private oauthService: OAuthService,
        private scheduleService: ScheduleService,
        private meetingsService: MeetingsService,
    ) {
        super();

        this.calendarIntegrationUtilities = new CalendarIntegrationUtilities(injector);

        this.subscribeToEmitForEntityTypeChange(injector, Event);
        this.subscribeToEmitForEntityTypeChange(injector, EventSeries);
        this.subscribeToEmitForEntityTypeChange(injector, Meeting);
    }

    public async workflowStepOnInit() {
        const workflow = this.workflowStep?.workflow;
        if (!workflow?.runData) {
            throw new Error("Workflow runData not available");
        }

        // runData holds the configured schedules
        this.runData = workflow.runData;

        this.sections = [];
        this.items = [];
        this.recurrences = new Map<EventTypePreset, EventSeries>();

        for (const { eventSeries, eventTypePreset } of this.runData.scheduledPresets.values()) {
            this.recurrences.set(eventTypePreset!, eventSeries);

            this.sections.push(ScheduleService.SchedulerSectionFromEventType(eventSeries.eventType));
            this.items.push(...eventSeries.events.map(ScheduleService.SchedulerItemFromEvent));
        }

        const cadenceCycle = await lastValueFrom(this.scheduleService.getEventCadenceCycle());
        if (!cadenceCycle) {
            throw new Error("EventCadenceCycle must exist to continue setting cadence");
        }
        // always use the AS startDate for calculating the cycle endDate
        // if this is the first preset calculation, we need to default to the same cadenceStartDate, as the scheduledPresets map won't have the AS preset yet.
        const annualStartDate = this.recurrences.get(EventTypePreset.AnnualStrategy)?.startDate;
        if (annualStartDate) {
            // update the current period view based on the cycle length
            const cycleMonths = cadenceCycle.extensions.getCycleLength(annualStartDate);
            this.currentPeriod = cycleMonths > 13
                ? TimeSchedulerPeriodYearPlusHalf
                : TimeSchedulerPeriodYear;
        }
    }

    public workflowStepNext() {
        const scheduledPresets = Array.from(this.runData.scheduledPresets.values());

        const allEventSeries = scheduledPresets
            .flatMap((recurrence) => recurrence.eventSeries);

        const allEntities = ArrayUtilities.distinct(scheduledPresets
            .flatMap(({ eventSeries, deletedEntities = [] }) => [eventSeries, ...eventSeries.events, ...deletedEntities] as IBreezeEntity[])
            .concat(this.runData.deletedEntities));

        const needToSave = allEntities.some((entity) => entity.entityAspect.entityState.isAddedModifiedOrDeleted());
        if (!needToSave) {
            return of([]);
        }

        return this.oauthService.isAuthedWithProvider(CalendarIntegrationProvider.Microsoft).pipe(
            switchMap((authed) => authed
                ? forkJoin(allEventSeries.map((series) => series.calendarIntegrationId
                    ? this.calendarIntegrationUtilities.updateMicrosoftRecurringEvent(series)
                    : this.calendarIntegrationUtilities.scheduleMicrosoftRecurringEvent(series)))
                : of(undefined)),
            switchMap(() => this.commonDataService.saveEntities(allEntities)),
            switchMap(() => forkJoin(allEventSeries.map((es) => this.scheduleService.processEventSeries(es)))),
            switchMap((eventSeriesMeetingIds) => {
                // update each meeting that got changed/created
                const ids = eventSeriesMeetingIds.flat();
                return ids.length > 0
                    ? forkJoin(ids.map((id) => this.meetingsService.getMeetingById(id)))
                    : of([]);
            }),
            tap((meetings) => {
                // this will cause meeting lists to update (e.g. Upcoming meetings on meetings page)
                this.meetingsService.notifyMeetingIdChange(meetings[0]?.meetingId ?? 0);
            }),
            catchError((error) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(error);
                this.workflowStepErrorMessage.next(errorMessage);
                return throwError(() => new AdaptError(errorMessage));
            }),
        );
    }
}

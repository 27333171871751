import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxTagBoxCustomItemCreatingEvent } from "@common/ux/dx.types";
import isEqual from "lodash.isequal";
import { lastValueFrom, of } from "rxjs";
import { catchError, debounceTime, map, switchMap, tap } from "rxjs/operators";
import { LabellingService } from "../labelling.service";

@Component({
    selector: "adapt-select-label",
    templateUrl: "./select-label.component.html",
})
export class SelectLabelComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public labels: Label[] = [];
    @Input() public allowLabelCreation = true;
    @Output() public labelsChange = new EventEmitter<Label[]>();
    public orgLabels: Label[] = [];
    public initialSelections: number[] = [];

    // use this to track selection by search text to prevent that from removing existing entity
    private labelIdBySearchText?: number;
    private orgLabelsUpdater = this.createThrottledUpdater((labels: Label[]) => {
        this.orgLabels = labels;
        this.isInitialised = true;
    });
    private lastSelections?: Label[];

    public constructor(
        private commonDataService: CommonDataService,
        private labellingService: LabellingService,
        private dialogService: AdaptCommonDialogService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        rxjsBreezeService.entityTypeChanged(Label).pipe(
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnInit());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.labels && this.labels && (
            (!isEqual(this.labels, changes.labels.previousValue) && !isEqual(this.labels, this.lastSelections)) ||
            // this is to allow reselection of already existing label when typing it from search text (which dx will deselect)
            // which was fixed in CM-5327
            (this.labelIdBySearchText && !this.labels.find((l) => l.labelId === this.labelIdBySearchText))
        )) {
            // cannot use map in template (angular language service raised error) -> so get initial set of labelIds here
            this.initialSelections = this.labels.map((i) => i.labelId);
            if (this.labelIdBySearchText) {
                const persistLabelId = this.labelIdBySearchText; // to be used in setTimeout scope
                // do this in the next digest cycle as changes from ngOnChanges will cause ExpressionChangedAfterCheckedError
                setTimeout(() => {
                    if (this.initialSelections.indexOf(persistLabelId) < 0) {
                        // removed by search text - add it back in
                        this.initialSelections.push(persistLabelId);
                    }
                });
                this.labelIdBySearchText = undefined;
            }
        }
    }

    public ngOnInit() {
        this.labellingService.getAllLabels().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((labels) => {
            this.orgLabelsUpdater.next(labels);
        });
    }

    public onLabelCreating(e: IDxTagBoxCustomItemCreatingEvent) {
        // any custom label will be created and saved - this component will emit saved label entities
        const customLabel = this.orgLabels.find((i) => StringUtilities.equalsIgnoreCase(i.name, e.text!));
        if (!customLabel) {
            let newLabel: Label | undefined;
            // from dxTagBox doc, customItem accepts both promise and actual item
            e.customItem = lastValueFrom(this.labellingService.createLabel(e.text!).pipe(
                // save and return the label as the label entity is required by customItem in dx event
                switchMap((label) => {
                    newLabel = label;
                    return this.commonDataService.saveEntities(label).pipe(map(() => label));
                }),
                tap((label) => this.orgLabels.push(label)),
                catchError((error) => {
                    const cleanup = newLabel
                        ? this.commonDataService.remove(newLabel)
                        : of(undefined);
                    return cleanup.pipe(
                        switchMap(() => this.dialogService.showErrorDialog("Label Creation Failed", error.message)),
                    );
                }),
            ));

            this.labelIdBySearchText = undefined;
        } else {
            e.customItem = customLabel;
            this.labelIdBySearchText = customLabel.labelId;
        }
    }

    public onValueChanged(values: number[]) {
        if (this.labelIdBySearchText) {
            // this can also be add by search text -> don't have to remember to do anything special about it
            if (values.indexOf(this.labelIdBySearchText) >= 0) {
                this.labelIdBySearchText = undefined;
            }
        }

        if (this.isInitialised) {
            // this will emit the labels in the order they appear in the tag box
            this.lastSelections = values
                // ! here as we are going to filter out the missing label next - otherwise ts will not allow the type cast
                .map((labelId) => this.orgLabels.find((l) => l.labelId === labelId)!)
                .filter((label) => !!label);
            this.labelsChange.emit(this.lastSelections);
        }
    }
}

<!-- ordinal of fifty to allow the page to have buttons to the left -->
<div adaptToolbarContent
     [ordinal]="50">
    <span class="dx-datagrid-export-button">
        <button adaptButtonIcon="dx-icon dx-icon-xlsxfile"
                adaptTooltip="Export all data"
                (click)="exportAllData()"
                data-test="export-data-button"></button>
    </span>
    <button adaptButtonIcon="dx-icon dx-icon-column-chooser"
            adaptTooltip="Column chooser"
            (click)="showColumnChooser()"></button>
    <button adaptButtonIcon="dx-icon dx-icon-revert"
            adaptTooltip="Reset columns"
            (click)="resetColumns()"></button>
</div>

<dx-data-grid [dataSource]="peopleData"
              [stateStoring]="dxGridWrapperHelper.stateStoringOptions"
              (onInitialized)="onInitialized($event)"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true">
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-paging [pageSize]="25">
    </dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>
    <dxo-scrolling [useNative]="false">
    </dxo-scrolling>
    <dxo-column-chooser [enabled]="false">
    </dxo-column-chooser>
    <dxo-load-panel [enabled]="true">
    </dxo-load-panel>
    <dxo-column-fixing [enabled]="true">
    </dxo-column-fixing>

    <dxi-sort-by-group-summary-info summaryItem="custom"></dxi-sort-by-group-summary-info>
    <dxo-summary [calculateCustomSummary]="calcConnectionTypeCustomSummary">
        <dxi-group-item summaryType="custom"
                        name="connectionTypeCustomSummary"
                        showInColumn="Connection Type"></dxi-group-item>
        <dxi-group-item summaryType="count"
                        name="connectionTypeCountSummary"
                        showInColumn="Connection Type"></dxi-group-item>
        <dxi-total-item displayFormat="Total People: {0}"
                        showInColumn="People"
                        summaryType="count">
        </dxi-total-item>
    </dxo-summary>

    <!-- COLUMNS -->
    <dxi-column caption="Connection Type"
                dataField="connectionType"
                dataType="string"
                [groupIndex]="0"
                groupCellTemplate="connectionTypeTemplate"
                [calculateGroupValue]="calculateGroupValue">
    </dxi-column>

    <dxi-column caption="Full Name"
                dataField="person.fullName"
                dataType="string"
                name="People"
                cellTemplate="fullNameCellTemplate"
                [width]="210"
                sortOrder="asc">
    </dxi-column>

    <dxi-column caption="Status"
                dataField="status"
                [calculateCellValue]="calculateActiveCellValue"
                dataType="boolean"
                trueText="Active"
                falseText="Inactive"
                [allowSorting]="true"
                [allowFiltering]="true"
                [showEditorAlways]="false"
                [filterValue]="true">
    </dxi-column>

    <dxi-column *ngIf="isAlto"
                caption="Role In Organisation"
                dataField="roleInOrganisation"
                dataType="string"
                [allowSorting]="true"
                [allowFiltering]="true"
                [showEditorAlways]="false"
                [calculateCellValue]="calculateRoleInOrganisationCellValue"
                [visible]="true">
        <dxo-header-filter [dataSource]="roleInOrganisationHeaderItems">
        </dxo-header-filter>
    </dxi-column>

    <dxi-column *ngIf="!isAlto"
                caption="Login Allowed"
                dataField="hasAccess"
                dataType="boolean"
                [allowSorting]="true"
                [allowFiltering]="true"
                [showEditorAlways]="false"
                [visible]="true"
                cellTemplate="hasAccessCellTemplate">
    </dxi-column>

    <dxi-column *ngIf="!isAlto"
                caption="User Type"
                dataField="userType"
                dataType="string"
                [allowSorting]="true"
                [allowFiltering]="true"
                [showEditorAlways]="false"
                [calculateCellValue]="calculateAccessLevelCellValue"
                [visible]="true">
    </dxi-column>

    <dxi-column *ngIf="!isAlto"
                caption="Access Levels"
                cellTemplate="accessLevelsCellTemplate"
                [calculateCellValue]="calculateAccessLevelsCellValue"
                dataType="string"
                dataField="accessLevel"
                [allowSorting]="true"
                [allowFiltering]="true"
                [filterValue]="accessLevelLabelFilter">
        <dxo-header-filter [dataSource]="accessLevelHeaderItems">
        </dxo-header-filter>
    </dxi-column>

    <dxi-column *ngIf="!isAlto"
                caption="Roles"
                cellTemplate="roleCellTemplate"
                [calculateCellValue]="calculateRolesCellValue"
                dataType="string"
                dataField="role"
                [allowSorting]="true"
                [allowFiltering]="true"
                [filterValue]="roleLabelFilter">
        <dxo-header-filter [dataSource]="roleHeaderItems">
        </dxo-header-filter>
    </dxi-column>

    <dxi-column caption=""
                dataType="string"
                dataField="actions"
                cellTemplate="actionCellTemplate"
                [allowSorting]="false"
                [allowReordering]="false"
                [allowHiding]="false"
                [allowFiltering]="false"
                [allowResizing]="false"
                [fixed]="true"
                fixedPosition="right"
                [width]="50">
    </dxi-column>

    <dxi-column caption="Last Access"
                [calculateCellValue]="calculateLastAccessDate"
                dataField="dummyLastAccess"
                dataType="date"
                [format]="dateFormat"
                [allowFiltering]="true"
                [allowSorting]="true"
                [visible]="false"
                [width]="180">
    </dxi-column>

    <dxi-column caption="Invitation Email Sent"
                [calculateCellValue]="calculateWelcomeEmailDate"
                dataField="dummyWelcomeEmail"
                dataType="date"
                [format]="dateFormat"
                [allowFiltering]="true"
                [allowSorting]="true"
                [visible]="false"
                [width]="210">
    </dxi-column>

    <dxi-column caption="Start Date"
                dataType="date"
                dataField="startDate"
                [format]="ShortDate"
                [allowFiltering]="true"
                [allowSorting]="true"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="End Date"
                dataType="date"
                dataField="endDate"
                [format]="ShortDate"
                [allowFiltering]="true"
                [allowSorting]="true"
                [visible]="false">
    </dxi-column>
    <!-- END COLUMNS -->

    <!-- TEMPLATES -->
    <div *dxTemplate="let item of 'connectionTypeTemplate'">{{item.value}} ({{getConnectionTypeSummaryCount(item)}})</div>

    <div *dxTemplate="let item of 'fullNameCellTemplate'">
        <adapt-link-person [person]="item.data.person"
                           [showImage]="true"
                           imageSize="sm"></adapt-link-person>
    </div>

    <div *dxTemplate="let item of 'roleCellTemplate'"
         class="roles-cell">
        <ul>
            <li
                *ngFor="let roleConnection of getNonTeamBasedRoleConnectionsForConnection(item.data, false); trackBy:trackByRoleConnectionId">
                <adapt-link-role [role]="roleConnection.role"></adapt-link-role>
            </li>
        </ul>
    </div>

    <div *dxTemplate="let item of 'accessLevelsCellTemplate'"
         class="roles-cell">
        <ul>
            <li
                *ngFor="let roleConnection of getNonTeamBasedRoleConnectionsForConnection(item.data, true); trackBy:trackByRoleConnectionId">
                <adapt-link-access-level [role]="roleConnection.role"></adapt-link-access-level>
            </li>
        </ul>
    </div>

    <div *dxTemplate="let item of 'hasAccessCellTemplate'">
        <dx-check-box *ngIf="item.value"
                      [value]="true"
                      [readOnly]="true"></dx-check-box>
    </div>

    <div *dxTemplate="let item of 'actionCellTemplate'">
        <adapt-user-role-actions [connection]="item.data"
                                 (connectionChanged)="promiseToGetPeopleData()"
                                 (refreshRequired)="updateGridDimensions()"></adapt-user-role-actions>
    </div>
    <!-- END TEMPLATES -->
</dx-data-grid>

<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.WritingGreatObjectives"
                                          context="dialog"></adapt-context-sidebar-implementation-kit>

<adapt-dialog [maxWidth]="950"
              [padding]="false">
    <h3 adapt-dialog-title>{{isNew ? 'Add' : 'Edit'}} Objective</h3>
    <div adapt-dialog-content
         class="h-100">
        <div class="row objective-content-container h-100">
            <div class="mainbar d-flex flex-column flex-fill">
                <div class="form-group">
                    <label for="objective">Title</label>
                    <dx-text-box id="objective"
                                 #focus
                                 [adaptValidateEntity]="objective"
                                 adaptValidateEntityProperty="title"
                                 placeholder="Type your objective here"
                                 (onKeyUp)="validateObjective()"
                                 (valueChange)="objectiveTitleChanged = true"
                                 [(value)]="objective.title"
                                 data-test="objective-title-input"
                                 data-tour="objective-title-input"></dx-text-box>
                </div>

                <div class="mt-3 form-group">
                    <legend>Objective Type</legend>
                    <div class="d-inline-block"
                         [adaptTooltip]="hasChild ? 'Objective type cannot be changed if it has a supporting objective' : undefined">
                        <div class="form-check form-check-inline"
                             data-tour="annual-radio-input">
                            <input type="radio"
                                   id="annualObjective"
                                   name="ObjectiveType"
                                   [disabled]="hasChild"
                                   [value]="ObjectiveTypeEnum.Annual"
                                   [ngModel]="objective.type"
                                   (ngModelChange)="onObjectiveTypeChanged($event)"
                                   class="form-check-input"
                                   data-test="annual-radio-input">
                            <label class="form-check-label"
                                   for="annualObjective">Annual</label>
                        </div>
                        <div class="form-check form-check-inline"
                             data-tour="quarterly-radio-input">
                            <input type="radio"
                                   id="quarterlyObjective"
                                   name="ObjectiveType"
                                   [disabled]="hasChild"
                                   [value]="ObjectiveTypeEnum.Quarterly"
                                   [ngModel]="objective.type"
                                   (ngModelChange)="onObjectiveTypeChanged($event)"
                                   class="form-check-input"
                                   data-test="quarterly-radio-input">
                            <label class="form-check-label"
                                   for="quarterlyObjective">Quarterly</label>
                        </div>
                    </div>
                </div>

                <div class="mt-3 form-group">
                    <legend>Select the objective this supports</legend>
                    <dx-select-box class="objective-support-parent-selection"
                                   [(value)]="objective.parentObjective"
                                   [items]="groupedSupportiveObjectives"
                                   [showClearButton]="true"
                                   [grouped]="true"
                                   displayExpr="title"
                                   fieldTemplate="field"
                                   groupTemplate="group"
                                   data-test="objective-supports-dropdown">
                        <div *dxTemplate="let data of 'group'">
                            <div *ngIf="!data.key; else teamTemplate">Organisation Objectives</div>
                            <ng-template #teamTemplate>
                                <div>Team Objectives</div>
                            </ng-template>
                        </div>
                        <div *dxTemplate="let obj of 'field'">
                            <div *ngIf="!!obj; else noParent">
                                <adapt-link-objective class="m-2"
                                                      [objective]="obj"
                                                      [generateHref]="false"></adapt-link-objective>
                                <dx-text-box class="d-none"
                                             value="hidden element required by dxTemplate"
                                             [readOnly]="true"></dx-text-box>
                            </div>
                        </div>
                        <div *dxTemplate="let obj of 'item'">
                            <adapt-link-objective [objective]="obj"
                                                  [generateHref]="false"
                                                  [showIcon]="false"
                                                  [showStatusIcon]="false"></adapt-link-objective>
                        </div>
                        <ng-template #noParent>
                            <dx-text-box value=""
                                         [readOnly]="true"></dx-text-box>
                        </ng-template>
                    </dx-select-box>
                </div>

                <div class="mt-3 form-group">
                    <label for="description">Description</label>

                    <adapt-html-editor [(contents)]="objective.description"
                                       data-test="objective-description-input"></adapt-html-editor>

                </div>
            </div>
            <div class="sidebar">
                <div class="form-group">
                    <label for="owner">Owner</label>
                    <adapt-select-person id="owner"
                                         [person]="objective.assigneePerson"
                                         (personChange)="onPersonChange($event)"
                                         [userTypes]="allowedObjectiveOwnerUserTypes"
                                         [teamGroup]="objective.team"
                                         [required]="true"
                                         [filter]="filterAssignees"
                                         data-test="objective-owner-dropdown"
                                         data-tour="objective-owner-dropdown"></adapt-select-person>
                </div>

                <div class="mt-3 form-group">
                    <label for="status">Status</label>
                    <dx-select-box id="status"
                                   [dataSource]="statusOptions"
                                   displayExpr="name"
                                   valueExpr="value"
                                   [(value)]="objective.status"
                                   data-test="objective-status-dropdown"
                                   data-tour="objective-status-dropdown">
                    </dx-select-box>
                </div>

                <div class="mt-3 form-group">

                    <div class="d-flex flex-column flex-lg-row">
                        <label for="dueDate">Due Date</label>
                        <i *ngIf="isAutoDueDate"
                           class="ms-1 fal fa-circle-info align-self-center"
                           [adaptTooltipIsHtml]="true"
                           adaptTooltip="This date is pre-filled as the next <b>{{objective.type}} Strategy</b> meeting"></i>
                    </div>
                    <dx-date-box id="dueDate"
                                 width="100%"
                                 [value]="objective.dueDate"
                                 (valueChange)="dueDateChanged($event)"
                                 (onOpened)="dueDateTouched = true"
                                 data-test="objective-date-dropdown">
                    </dx-date-box>
                </div>

                <div class="mt-3 form-group">
                    <label for="objectiveLabels">Labels</label>
                    <adapt-select-label-for-related-entity [objective]="objective"
                                                           (entitiesChange)="changedLabelLocations = $event"
                                                           data-test="objective-labels">
                    </adapt-select-label-for-related-entity>
                </div>
            </div>
        </div>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!isObjectiveValid || !hasUnsavedEntity"
                                   [saveBlockingClick]="save"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Account, AccountBreezeModel } from "@common/ADAPT.Common.Model/account/account";
import { PricingModelBreezeModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { ServiceUri } from "@common/configuration/service-uri";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseService } from "@common/service/base.service";
import { IBannerSpec } from "@common/shell/shell.interface";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { CancelSubscriptionDialogComponent } from "@org-common/lib/configuration/organisation/cancel-subscription-dialog/cancel-subscription-dialog.component";
import moment from "moment";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { OrganisationService } from "../organisation.service";
import { SetInitialSubscriptionDialogComponent } from "./set-initial-subscription-dialog/set-initial-subscription-dialog.component";
import { StartSubscriptionDialogComponent } from "./start-subscription-dialog/start-subscription-dialog.component";

@Injectable({
    providedIn: "root",
})
export class AccountService extends BaseService {
    private readonly subscriptionBanner: IBannerSpec = {
        class: "account-subscription-banner",
        text: "",
        buttonText: "Start subscription",
        buttonAction: () => this.showSubscriptionDialog().subscribe(),
        isDismissible: true,
    };

    constructor(
        injector: Injector,
        private authorisationService: AuthorisationService,
        private authorisationNotificationService: AuthorisationNotificationService,
        private httpClient: HttpClient,
        private shellUiService: ShellUiService,
        private commonDialogService: AdaptCommonDialogService,
        private organisationService: OrganisationService,
    ) {
        super(injector);
    }

    public addAccountBanner() {
        // remove the banner when logging out
        this.organisationService.organisationEntityUpdated$.subscribe((org) => {
            if (!org) {
                this.shellUiService.removeBanner(this.subscriptionBanner);
            }
        });

        // need authorisationChanged$ for promiseToGetHasAccess to work as expected
        this.authorisationNotificationService.authorisationChanged$.pipe(
            switchMap(() => this.authorisationService.promiseToGetHasAccess(ConfigurationAuthService.ConfigureOrganisationBilling)),
            filter((hasAccess) => hasAccess),
            switchMap(() => this.getAccount()),
            map((account) => {
                this.shellUiService.removeBanner(this.subscriptionBanner);

                if (!account || (!account.extensions.isTrial && !account.extensions.isInactive)) {
                    return;
                }

                const now = moment();
                const nextInvoiceDate = moment(account.nextSubscriptionInvoiceDate);
                const ended = now.isAfter(nextInvoiceDate);
                if (ended || account.extensions.isInactive) {
                    this.subscriptionBanner.text = "The trial period for adapt HQ has ended. To continue growing your business, start your subscription now!";
                } else {
                    const days = nextInvoiceDate.diff(now, "days", true);
                    const roundedDays = Math.round(days);
                    const dayText = roundedDays === 1 ? "day" : "days";
                    const message = "Start your subscription now to continue access to adapt HQ after the trial ends.";
                    this.subscriptionBanner.text = days < 1
                        ? `Your free trial is ending today. ${message}`
                        : `You have ${roundedDays} ${dayText} remaining on your free trial. ${message}`;
                }

                this.shellUiService.addBanner(this.subscriptionBanner);
            }),
        ).subscribe();
    }

    public getAccount() {
        const key = "allAccountsPrimed";
        return this.commonDataService.getWithOptions(AccountBreezeModel, key, {
            navProperty: "currency, eulaPerson, pricingModel.pricingModelUsers",
            forceRemote: true,
        }).pipe(
            map((accounts) => ArrayUtilities.getSingleFromArray(accounts)),
        );
    }

    public getPricingModels() {
        return this.commonDataService.getAll(PricingModelBreezeModel);
    }

    public forceUpdateAccount(account: Account) {
        return this.commonDataService.getById(AccountBreezeModel, account.accountId, true);
    }

    @Autobind
    public showSubscriptionDialog() {
        return this.commonDialogService.open(StartSubscriptionDialogComponent).pipe(
            switchMap(() => this.getAccount()),
            switchMap((account) => this.commonDialogService.open(SetInitialSubscriptionDialogComponent, account)),
            tap(() => this.authorisationNotificationService.refreshPermissions()),
            switchMap(() => {
                let message = "</p>Your payment has been received and a receipt has been sent to your email.</p>";
                message += "<p>We're looking forward to helping your business get to the next level!</p>";
                return this.commonDialogService.showMessageDialog("Congratulations!", message);
            }),
        );
    }

    @Autobind
    public cancelSubscriptionDialog() {
        return this.commonDialogService.open(CancelSubscriptionDialogComponent).pipe(
            switchMap((result) => this.sendSurveyResponseEmail(this.organisationService.getOrganisationId(), result.reason, result.freeText)),
            switchMap(() => this.archiveOrganisation(this.organisationService.getOrganisationId())),
            switchMap(() => this.commonDialogService.showMessageDialog("Success!", "Subscription canceled. Please refresh the page.", "Refresh")),
            tap(() => window.location.reload()),
            catchError((e: HttpErrorResponse) => {
                return this.commonDialogService.showErrorDialog("Failed To Save", ErrorHandlingUtilities.getHttpResponseMessage(e));
            }),
        );
    }

    private sendSurveyResponseEmail(organisationId: number, reason: string, freeText: string) {
        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/SendExitSurveyResponseEmail`;
        return this.httpClient.post(uri, null, {
            params: {
                organisationId,
                reason,
                freeText,
            },
        });
    }

    private archiveOrganisation(organisationId: number) {
        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/ArchiveOrganisation`;
        return this.httpClient.post(uri, null, {
            params: {
                organisationId,
            },
        });
    }
}

import { Injectable, Injector } from "@angular/core";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { KeyResult } from "@common/ADAPT.Common.Model/organisation/key-result";
import { KeyResultValue } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveComment } from "@common/ADAPT.Common.Model/organisation/objective-comment";
import { ObjectiveItemLink } from "@common/ADAPT.Common.Model/organisation/objective-item-link";
import { ObjectiveLink } from "@common/ADAPT.Common.Model/organisation/objective-link";
import { ObjectiveStatus } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { UserService } from "@common/user/user.service";
import { BaseUiService } from "@common/ux/base-ui.service/base-ui.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import moment from "moment";
import { forkJoin, throwError } from "rxjs";
import { catchError, filter, map, switchMap, take } from "rxjs/operators";
import { ISelectItemDialogInputData, ISelectItemDialogResolveData, SelectItemDialogComponent } from "../kanban/items/select-item-dialog/select-item-dialog.component";
import { ScheduleService } from "../schedule/schedule.service";
import { AddObjectiveLinkDialogComponent } from "./add-objective-link-dialog.component/add-objective-link-dialog.component";
import { DeleteKeyResultConfirmationDialogComponent, IConfirmDeleteKeyResultData } from "./delete-key-result-confirmation-dialog/delete-key-result-confirmation-dialog.component";
import { DeleteObjectiveConfirmationDialogComponent, IConfirmDeleteObjectiveData } from "./delete-objective-confirmation-dialog/delete-objective-confirmation-dialog.component";
import { EditKeyResultDialogComponent } from "./edit-key-result-dialog/edit-key-result-dialog.component";
import { EditObjectiveDialogComponent } from "./edit-objective-dialog/edit-objective-dialog.component";
import { ObjectivesService } from "./objectives.service";
import { UpdateKeyResultDialogComponent } from "./update-key-result-dialog/update-key-result-dialog.component";

@Injectable({
    providedIn: "root",
})
export class ObjectivesUiService extends BaseUiService {
    public constructor(
        injector: Injector,
        private objectivesService: ObjectivesService,
        private userService: UserService,
        private organisationService: OrganisationService,
        private scheduleService: ScheduleService,
    ) {
        super(injector);
    }

    public createObjectiveForEventTypePreset(preset: EventTypePreset) {
        return this.scheduleService.getLatestEventAndSeriesForEventTypePreset(preset).pipe(
            switchMap((event) => this.createObjective(undefined, event?.event?.startDate)),
        );
    }

    public createObjective(teamId?: number, startDate?: Date) {
        const initialData: Partial<Objective> = {
            creationDate: new Date(),
            dueDate: startDate ?? moment().add(1, "year").toDate(),
            modifiedDate: new Date(),
            status: ObjectiveStatus.OnTrack,
            type: ObjectiveType.Annual,
            teamId,
            description: "", // or froala will chuck an empty string here causing an entity change
            organisationId: this.organisationService.getOrganisationId(),
        };
        return this.objectivesService.createObjective(initialData)
            .pipe(
                switchMap((objective) => this.dialogService.open(EditObjectiveDialogComponent, objective)),
            );
    }

    public editObjective(objective: Objective) {
        return this.dialogService.open(EditObjectiveDialogComponent, objective);
    }

    public createKeyResultForObjective(objective: Objective) {
        const initialData: Partial<KeyResult> = {
            objective,
            ordinal: objective.keyResults.length,
            targetValue: 100,
        };
        return this.objectivesService.createKeyResult(initialData).pipe(
            switchMap((keyResult) => this.dialogService.open(EditKeyResultDialogComponent, keyResult)),
        );
    }

    public editKeyResult(keyResult: KeyResult) {
        return this.dialogService.open(EditKeyResultDialogComponent, keyResult);
    }

    public updateKeyResult(keyResult: KeyResult) {
        const currentKeyResultValue = keyResult.currentKeyResultValue;
        return this.userService.currentPerson$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(Person)),
            take(1),
            switchMap((person) => {
                const objectiveComment$ = this.objectivesService.createObjectiveComment(keyResult.objective);
                const keyResultValue$ = this.objectivesService.createKeyResultValue({
                    keyResult,
                    value: keyResult.currentValue,
                    dateTime: new Date(),
                    person,
                });

                return forkJoin(objectiveComment$, keyResultValue$);
            }),
            switchMap((createdEntities) => this.dialogService.open(UpdateKeyResultDialogComponent, {
                objectiveComment: createdEntities[0],
                keyResultValue: createdEntities[1],
                currentKeyResultValue,
            })),
        );
    }

    public editKeyResultValue(keyResultValue: KeyResultValue, updateComment: ObjectiveComment, previousKeyResultValue?: KeyResultValue) {
        return this.dialogService.open(UpdateKeyResultDialogComponent, {
            objectiveComment: updateComment,
            keyResultValue,
            currentKeyResultValue: previousKeyResultValue,
        });
    }

    public addObjectiveLinkForObjective(objective: Objective) {
        return this.dialogService.open(AddObjectiveLinkDialogComponent, objective);
    }

    public addObjectiveItemLinkForObjective(objective: Objective) {
        return this.dialogService.open<ISelectItemDialogInputData, ISelectItemDialogResolveData>(
            SelectItemDialogComponent,
            { team: objective.team, linking: true, excludedItemIds: objective.itemLinks.map((l) => l.itemId) },
        ).pipe(
            map((result: ISelectItemDialogResolveData) => result.item),
            emptyIfUndefinedOrNull(),
            switchMap((item: Item) => {
                const createData: Partial<ObjectiveItemLink> = {
                    objectiveId: objective.objectiveId,
                    itemId: item.itemId,
                };

                return this.objectivesService.createObjectiveItemLink(createData);
            }),
            switchMap((link) => this.objectivesService.saveEntities(link)),
            catchError((err: AdaptError) => this.dialogService.showMessageDialog("Error saving link", err.message).pipe(
                switchMap(() => throwError(() => err)),
            )),
        );
    }

    public promptToDeleteObjectiveLink(link: ObjectiveLink) {
        return this.promptToDeleteAndSaveEntities(
            [link],
            "Delete objective link",
            `<p>The objective link between <i>${link.objective1.title}</i> and <i>${link.objective2.title}</i> will be deleted.</p>
                <p>Are you sure you want to delete?</p>`,
        );
    }

    public promptToDeleteObjectiveItemLink(link: ObjectiveItemLink) {
        return this.promptToDeleteAndSaveEntities(
            [link],
            "Delete objective action link",
            `<p>The link between objective <i>${link.objective.title}</i> and action <i>${link.item.code}</i> will be deleted.</p>
                <p>Are you sure you want to delete?</p>`,
        );
    }

    public promptToDeleteKeyResult(keyResult: KeyResult) {
        // don't even have to delete the KR values and it will cascade delete
        const dialogData: IConfirmDeleteKeyResultData = { keyResult };
        const confirmationDialog$ = this.dialogService.open(
            DeleteKeyResultConfirmationDialogComponent,
            dialogData,
        ).pipe(
            map((dialogResponse) => dialogResponse!.result),
        );

        // identify all update comments for the KR
        const updateComments = keyResult.objective.comments.filter((comment) =>
            keyResult.values.some((value) => value.dateTime.getTime() === comment.dateTime.getTime()));

        return this.deleteAndSaveEntities(
            confirmationDialog$,
            [keyResult, ...updateComments],
            "Error Deleting Key Result",
        );
    }

    public promptToDeleteObjective(objective: Objective) {
        const dialogData: IConfirmDeleteObjectiveData = { objective };
        const confirmationDialog$ = this.dialogService.open(
            DeleteObjectiveConfirmationDialogComponent,
            dialogData,
        ).pipe(
            map((dialogResponse) => dialogResponse!.result),
        );

        return this.deleteAndSaveEntities(
            confirmationDialog$,
            [objective],
            "Error Deleting Objective",
        );
    }

    public promptToDeleteKeyResultValue(keyResultValue: KeyResultValue, updateComment?: ObjectiveComment) {
        const deleteEntities: IBreezeEntity[] = [keyResultValue];
        if (updateComment) {
            deleteEntities.push(updateComment);
        }

        return this.promptToDeleteAndSaveEntities(
            deleteEntities,
            "Delete Key Result Value?",
            `<p>The key result value together with its comment will be deleted and cannot be restored.</p>
                <p>Are you sure you want to delete?</p>`);
    }
}

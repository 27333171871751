<div class="card"
     [ngClass]="{disabled: loading || isLocked}"
     [style.--category-colour]="categoryMetadata.colour">
    <div class="card-body">
        <ng-content select="[action-content]"></ng-content>
    </div>
    <div class="card-footer"
         #workflowCardAction
         [ngClass]="{disabled: loading || isLocked}"
         (click)="onActionClicked()">
        <div>{{startWorkflowButtonText}}</div>
        <div class="card-footer-icon"><i [ngClass]="startWorkflowButtonIcon"></i></div>
    </div>
</div>

<dx-tooltip *ngIf="isLocked || state === WorkflowState.Completed"
            [target]="workflowCardAction"
            class="dx-swatch-dark dx-tooltip-element"
            [showEvent]="{name: 'dxhoverstart', delay: 250}"
            hideEvent="dxhoverend"
            contentTemplate="tooltipContent"
            [maxWidth]="300"
            [position]="{my: 'bottom', at: 'top', collision: {x: 'flipfit', y: 'flipfit'}}">
    <div *dxTemplate="let data of 'tooltipContent'"
         [ngSwitch]="state">
        <div *ngSwitchCase="WorkflowState.Completed"
             class="adapt-tooltip-content text-start">You can restart the pathway by clicking here</div>
        <div *ngSwitchCase="WorkflowState.NoPermission"
             class="adapt-tooltip-content text-start">You do not have permission to run pathways</div>
        <div *ngSwitchCase="WorkflowState.PrerequisitesNotSatisfied"
             class="adapt-tooltip-content text-start">
            The following pathways need to be completed prior to starting this pathway:
            <ul class="mt-1 mb-0 list-content">
                <li *ngFor="let flow of compulsoryPrerequisiteWorkflows">{{flow.name}}</li>
            </ul>
        </div>
        <div *ngSwitchCase="WorkflowState.MissingRoles"
             class="adapt-tooltip-content text-start">
            <p>Unfortunately, you do not possess the necessary roles within the organisation to start this pathway.</p>
            <p>One of the following roles is required:</p>
            <ul class="mt-1 mb-0 list-content">
                <li *ngFor="let role of workflow?.requiredRoles">{{RoleInOrganisationLabel[role]}}</li>
            </ul>
        </div>
        <div *ngSwitchCase="WorkflowState.NotInLeadershipTeam"
             class="adapt-tooltip-content text-start">You cannot start the pathway because you are not in the leadership team</div>
        <div *ngSwitchCase="WorkflowState.NotInStartedTeam"
             class="adapt-tooltip-content text-start">You cannot continue the pathway as you are not in the team that started it</div>
        <div *ngSwitchCase="WorkflowState.ComingSoon"
             class="adapt-tooltip-content text-start">This pathway is coming soon!</div>
        <div *ngSwitchCase="WorkflowState.Disabled"
             class="adapt-tooltip-content text-start">{{disabledTooltip}}</div>
    </div>
</dx-tooltip>

import { SurveyDetails } from "../survey-details";
import { ISurveyQuestionCategory, ISurveyQuestions } from "../survey-questions.interface";
import { ISurveyResponseChoice } from "../survey-response-choice.interface";

const OrganisationName = "ORGANISATION_NAME";
const ResilientBusinessAssessmentQuestionList: { [surveyQuestionId: number]: string } = {
    [1]: "The purpose, vision and values are well documented and communicated throughout the organisation",
    [2]: "The values of the organisation are shared and supported by the employees",
    [3]: "The leadership team is effective at communicating the key objectives and priorities of the organisation to employees",
    [4]: "The leadership team care for the wellbeing of the people in the organisation above profits",
    [5]: "The behaviour of leaders is aligned with the stated values of the organisation",
    [6]: "The organisation involves employees in decisions that impact their work",
    [7]: "The organisation welcomes new ideas from employees",
    [8]: "The organisation has a culture of open and honest communication",
    [9]: "The organisation values the unique skills and talents of each employee",
    [10]: "The organisation encourages employees to feed back problems on issues they see within the organisation",
    [11]: "People can be truly open and authentic",
    [12]: "The current recruitment processes ensure that people who join the organisation are a good fit",
    [13]: "The organisation provides good opportunities for training and development",
    [14]: "The current appraisal system thoroughly and accurately evaluates employee performance",
    [15]: "The current reward system recognises people fairly for the work they do and the level of responsibility they hold",

    [16]: "The organisation is not overly hierarchial and has the flexibility for teams and individuals to make decisions",
    [17]: "The role that each team has in helping the organisation achieve its goals is clear",
    [18]: "There is transparent and effective communication across all teams within the organisation",
    [19]: "The organisation effectively documents the flow of work",
    [20]: "The organisation has a clear process for producing consistently high quality products and services",
    [21]: "Critical information is effectively captured and made available",
    [22]: "The organisation encourages the sharing of knowledge between individuals and teams",
    [23]: "Knowledge around core processes are documented and shared within the organisation",
    [24]: "Guidance on tasks is captured to enable the handover of knowledge and experience",

    [25]: "The organisation has clear and measurable financial indicators",
    [26]: "The organisation has a robust profit and loss forecasting process",
    [27]: "The organisation has an effective sales forecasting process",
    [28]: "The key financial indicators are regularly communicated and transparent to all employees",
    [29]: "The key financial indicators are understood across the organisation",
    [30]: "The organisation is in a healthy liquidity/cash-flow position",

    [31]: "The organisation invests in the continuous development of its products and services",
    [32]: "The organisation actively encourages innovative thinking towards product and service development",
    [33]: "There is a well documented marketing strategy that creates brand recognition in target markets",
    [34]: "There is a clear plan for attracting and maintaining sales",
    [35]: "Industry trends and movements in market conditions are monitored and considered",
    [36]: "The organisation actively plans and prepares for times of adversity",
    [37]: "The organisation has strong connections with its industry partners",
    [38]: "The organisation actively develops trusted relationships with suppliers and key customers",
    [39]: "The organisation understands the needs and buying behaviours of its current and potential customers",
    [40]: "There is a process in place to ensure regular feedback is received from customers",
};

const ResilientBusinessAssessmentQuestionChoices: { [score: number]: string } = {
    [1]: "Strongly Disagree",
    [2]: "Disagree",
    [3]: "Agree",
    [4]: "Strongly Agree",
    [5]: "N/A",
};

export enum ResilientBusinessAssessmentCategoryGrade {
    Bad = "Having Major Issues",
    Warning = "Having Minor Issues",
    Good = "Doing Well",
}

const ResilientBusinessAssessmentQuestionCategories: { [id: number]: ISurveyQuestionCategory } = {
    [1]: {
        categoryName: "Leadership & Culture",
        categoryPageIntro: `<p>Effective leadership is one of the key parts of a resilient organisation - and it seeds and sustains the organisation's culture.
            An organisation’s culture is the shared assumptions, values and beliefs that determine how people behave.
            Any group of people who come together regularly will develop a culture - some will be positive and constructive and some will be less so.</p>
            <p>A healthy culture is critical to a business's ability to be effective and adaptive for long-term sustainability.</p>`,
        questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        gradeSummary: {
            [ResilientBusinessAssessmentCategoryGrade.Bad]: "",
            [ResilientBusinessAssessmentCategoryGrade.Warning]: "",
            [ResilientBusinessAssessmentCategoryGrade.Good]: "",
        },
    },
    [2]: {
        categoryName: "Organisation Design",
        categoryPageIntro: `<p>Organisation design and work-flow need to be well documented but flexible so everyone has the same view across an organisation.</p>
            <p>Information and knowledge should be easily captured and shared throughout the organisation
            to ensure customers maintain that ‘organisation memory’ and everyone has the same picture.</p>`,
        questionIds: [16, 17, 18, 19, 20, 21, 22, 23, 24],
        gradeSummary: {
            [ResilientBusinessAssessmentCategoryGrade.Bad]: "",
            [ResilientBusinessAssessmentCategoryGrade.Warning]: "",
            [ResilientBusinessAssessmentCategoryGrade.Good]: "",
        },
    },
    [3]: {
        categoryName: "Financial Security",
        categoryPageIntro: `<p>In a resilient organisation, it is important there is a high level of business literacy.
            It isn’t just the responsibility of the ‘finance department’ or an external accountant to manage.
            It is the responsibility of all leaders to have access to, and be able to recall, the critical numbers, and that these are transparent to the entire organisation.</p>`,
        questionIds: [25, 26, 27, 28, 29, 30],
        gradeSummary: {
            [ResilientBusinessAssessmentCategoryGrade.Bad]: "",
            [ResilientBusinessAssessmentCategoryGrade.Warning]: "",
            [ResilientBusinessAssessmentCategoryGrade.Good]: "",
        },
    },
    [4]: {
        categoryName: "Economic Engine",
        categoryPageIntro: `<p>An economic engine is at the heart of a business.
            If it isn’t robust and viable - it is likely there will be no business at all!
            The way to engage, deliver and support customers is so important and can be the differentiating factor between organisations offering the same product or service in the market.</p>`,
        questionIds: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
        gradeSummary: {
            [ResilientBusinessAssessmentCategoryGrade.Bad]: "",
            [ResilientBusinessAssessmentCategoryGrade.Warning]: "",
            [ResilientBusinessAssessmentCategoryGrade.Good]: "",
        },
    },
};

export class ResilientBusinessAssessmentQuestions implements ISurveyQuestions {
    public questionIds = this.orderedQuestionIds;

    public get orderedQuestionIds() {
        return Object.keys(ResilientBusinessAssessmentQuestionList).map(Number);
    }

    public get categoryIds() {
        return Object.keys(ResilientBusinessAssessmentQuestionCategories).map(Number);
    }

    public get surveyResponseChoices() {
        return Object.keys(ResilientBusinessAssessmentQuestionChoices).map(Number)
            .map((value) => ({ text: ResilientBusinessAssessmentQuestionChoices[value], value } as ISurveyResponseChoice));
    }

    public constructor(private surveyDetails: SurveyDetails) { }

    public getQuestion(questionId: number) {
        const question = ResilientBusinessAssessmentQuestionList[questionId];
        if (question) {
            return question.replace(OrganisationName, this.surveyDetails.organisationName);
        } else {
            return undefined;
        }
    }

    public getCategory(categoryId: number) {
        return ResilientBusinessAssessmentQuestionCategories[categoryId];
    }

    public getQuestionIdsForCategory(categoryId: number) {
        const questionCategory = ResilientBusinessAssessmentQuestionCategories[categoryId];
        if (questionCategory) {
            return questionCategory.questionIds;
        } else {
            return undefined;
        }
    }
}

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { DxPopupComponent } from "devextreme-angular";
import { FilterComponent, IFilterComponentController } from "../filter.component";
import { UrlFilterService } from "../url-filter.service";

@Component({
    selector: "adapt-filter-content[urlParamNames]",
    templateUrl: "./filter-content.component.html",
    styleUrls: ["./filter-content.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FilterContentComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() public resetEnabled = true;
    @Input() public urlParamNames: string[] = [];
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public reset = new EventEmitter<void>();

    @Input() public isDefault = true;

    @ViewChild(DxPopupComponent) public popupInstance?: DxPopupComponent;

    private filterController: IFilterComponentController;
    private delayedPopupRepaint = this.createThrottledUpdater(async (show: boolean) => {
        // somehow using [visible]="isOpen" in the template won't work -> doing toggle in code is working fine for me
        await this.popupInstance?.instance.toggle(show);
        try {
            this.popupInstance?.instance.repaint();
        } catch (e) {
            // this can fail with "TypeError: Cannot read property 'onPositioning' of null"
            // when being redirected to the error page for example.
            // just silently let this fail.
        }
    }, 200);
    private filterOpenUpdater = this.createThrottledUpdater((isOpen: boolean) => {
        this.filterController.setFilterIsOpen(isOpen);
        this.delayedPopupRepaint.next(isOpen);
    });

    public constructor(
        elementRef: ElementRef<HTMLElement>,
        private responsiveService: ResponsiveService,
        private urlFilterService: UrlFilterService,
    ) {
        super(elementRef);
        const filterController = FilterComponent.Instance;
        if (!filterController) {
            throw new Error("FilterComponent controller not available before content is being set");
        }

        this.filterController = filterController;
        this.isOpen = false;
    }

    public ngOnInit() {
        this.filterController.setFilterContent(this.elementRef!.nativeElement);
    }

    public get isOpen() {
        return this.filterController.isOpen;
    }

    @Input()
    public set isOpen(value: boolean) {
        this.filterOpenUpdater.next(value);
    }

    public closeFilter() {
        this.isOpen = false;
    }

    public get breakpoint() {
        return this.responsiveService.currentBreakpoint;
    }

    public ngOnDestroy() {
        this.filterController.clearFilterContent();
    }

    public onHidden() { // handle hidden from background click - toggle button to reflect the popup state
        if (this.isOpen) {
            this.filterOpenUpdater.next(false);
        }
    }

    public async onReset() {
        this.reset.emit();
        await this.urlFilterService.clearFilters(this.urlParamNames);
    }
}

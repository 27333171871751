import { Component, Inject, OnInit } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { Organisation, OrganisationBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ICommonDataBase } from "@common/lib/data/common-data.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ORGANISATION_DASHBOARD_PAGE, PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { RouteService } from "@common/route/route.service";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { InitializedEvent } from "devextreme/ui/data_grid";
import { lastValueFrom } from "rxjs";

interface IGridItem {
    organisation: Organisation;
    coaches: string[];
    active: boolean;
}

@Component({
    selector: "adapt-all-organisations-grid",
    templateUrl: "./all-organisations-grid.component.html",
})
export class AllOrganisationsGridComponent implements OnInit {
    public gridData: IGridItem[] = [];
    public gridHelper?: DxGridWrapperHelper;

    public isDisabled = false;

    public constructor(
        public commonDataService: CommonDataService,
        public organisationService: OrganisationService,
        public routeService: RouteService,
        @Inject(ORGANISATION_DASHBOARD_PAGE) private organisationDashboardPageRoute: IAdaptRoute<{}>,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
    ) {
    }

    public async ngOnInit() {
        this.organisationService.promiseToGetOrganisation()
            .then(this.commonDataService.promiseToCreateTransientCommonDataInstance)
            .then(this.fetchData);

        this.gridHelper = new DxGridWrapperHelper("adapt-all-organisations-grid", jQuery("#allOrganisationsGrid"));
        this.gridHelper.saveGridState("adapt-all-organisations-grid");
        this.gridHelper.callGrid((grid) => grid.setColumnsReady());
    }

    @Autobind
    public onGridInitialized(e: InitializedEvent) {
        this.gridHelper?.initialiseGrid(e);
    }

    private async promiseToGetAllCoachConnections(transientCommonDataInstance: ICommonDataBase) {
        const key = "allCoachConnections";
        const options = {
            namedParams: {
                allowStakeholderOverride: true,
            },
            navProperty: "person",
        };

        const entities = await lastValueFrom(transientCommonDataInstance.getWithOptions(ConnectionBreezeModel, key, options));
        return entities as Connection[];
    }

    private async promiseToGetAllOrganisations(transientCommonDataInstance: ICommonDataBase) {
        const key = "allOrganisations";
        const options = {
            namedParams: {
                allowStakeholderOverride: true,
            },
            navProperty: "accounts",
        };

        return lastValueFrom(transientCommonDataInstance.getWithOptions(OrganisationBreezeModel, key, options));
    }

    @Autobind
    private async fetchData(transientCommonDataInstance: ICommonDataBase) {
        try {
            const connections = await this.promiseToGetAllCoachConnections(transientCommonDataInstance);
            const organisations = await this.promiseToGetAllOrganisations(transientCommonDataInstance);

            this.setData(connections, organisations ?? []);
        } finally {
            transientCommonDataInstance.cleanupInstance?.();
        }
    }

    private setData(connections: Connection[], organisations: Organisation[]) {
        this.gridData = organisations.map((organisation: Organisation) => {
            const coaches = ArrayUtilities.distinct(connections
                .filter((connection: Connection) => connection.organisationId === organisation.organisationId)
                .map((connection: Connection) => connection.person.fullName));

            const active = organisation.accounts.some((account: Account) => account.extensions.isActive && account.extensions.isStandardOrganisation);

            return {
                organisation,
                coaches,
                active,
            };
        });
    }

    public switchToOrganisation(organisation: Organisation) {
        this.isDisabled = true;
        const route = AdaptClientConfiguration.AdaptProject === AdaptProject.Alto
            ? this.personalDashboardPageRoute
            : this.organisationDashboardPageRoute;
        this.routeService.gotoControllerRoute(route.id, {
            organisationUrlIdentifier: organisation.urlIdentifier,
        });
    }
}

<adapt-dialog [maxWidth]="900">
    <h3 adapt-dialog-title>{{isNew ? "Add": "Edit"}} Key Result</h3>
    <ng-container adapt-dialog-content>
        <div class="d-flex">
            <div class="alert alert-info me-3 guidance">
                <p>A key result is a metric associated with an objective. <b>Ideally, key results measure outcomes, not actions.</b></p>

                A good way to set key results is to follow the SMART Model:
                <ul>
                    <li><strong>Specific:</strong> is the key result well-defined and understandable for everyone?</li>
                    <li><strong>Measurable:</strong> can you measure success or failure?</li>
                    <li><strong>Achievable:</strong> is it realistically possible to do?</li>
                    <li><strong>Relevant:</strong> is this key result important for your objective?</li>
                    <li><strong>Time-bound:</strong> Objectives are already time-bound, but if you need to have something sooner, then
                        specify that</li>
                </ul>
                <br />
                <b>For example:</b>
                <ul>
                    <li>For a product team: Complete 20% of stadium project</li>
                    <li>For a sales team: Gain 10 customers</li>
                    <li>For a leadership team: Everyone in the business knows the values</li>
                </ul>
            </div>

            <form name="edit-key-result"
                  class="flex-fill">

                <div class="form-group">
                    <label for="name">Key result name</label>
                    <dx-text-box #focus
                                 id="name"
                                 [adaptValidateEntity]="keyResult"
                                 adaptValidateEntityProperty="title"
                                 [(value)]="keyResult.title"
                                 data-test="key-result-title-input">
                    </dx-text-box>
                </div>

                <div class="form-group">
                    <label>Key result target value</label>
                    <div class="input-group">
                        <!-- <label><small>Prefix</small></label> -->
                        <dx-select-box name="prefix"
                                       [adaptValidateEntity]="keyResult"
                                       adaptValidateEntityProperty="targetValuePrefix"
                                       placeholder="Prefix e.g. $"
                                       [showClearButton]="true"
                                       [acceptCustomValue]="true"
                                       [items]="['$']"
                                       [(value)]="keyResult.targetValuePrefix"
                                       [width]="135"
                                       data-test="key-result-prefix-input"></dx-select-box>
                        <dx-number-box name="targetValue"
                                       class="ms-2 me-2"
                                       [adaptValidateEntity]="keyResult"
                                       adaptValidateEntityProperty="targetValue"
                                       [showSpinButtons]="true"
                                       [step]="1"
                                       [min]="1"
                                       [max]="2147483647"
                                       [disabled]="hasValue"
                                       [(value)]="keyResult.targetValue"
                                       [width]="90"
                                       data-test="key-result-target-input"></dx-number-box>
                        <dx-select-box name="suffix"
                                       class="flex-fill"
                                       [adaptValidateEntity]="keyResult"
                                       adaptValidateEntityProperty="targetValueSuffix"
                                       placeholder="Suffix e.g. per month"
                                       [showClearButton]="true"
                                       [acceptCustomValue]="true"
                                       [items]="['customers', 'per month', '%']"
                                       [(value)]="keyResult.targetValueSuffix"
                                       [width]="225"
                                       data-test="key-result-suffix-input"></dx-select-box>
                    </div>
                    <div class="alert alert-info mt-3"
                         role="alert">
                        The key result target value is broken down into three sections:
                        <ol class="my-3">
                            <li>An optional prefix for what you are measuring. E.g. '$'</li>
                            <li>The target value</li>
                            <li>An optional suffix for what you are measuring. E.g. 'customers', '%', 'per month' or 'MRR'</li>
                        </ol>
                        <small>Note: You can set your own prefix or suffix</small>
                    </div>
                </div>

                <div *ngIf="keyResult.targetValue && keyResult.targetValue !== 0 && keyResult.title"
                     class="alert alert-secondary">
                    <p>The key result you have specified reads like this:</p>
                    <span class="ms-3 ps-3 example">The key result <span class="fst-italic">'{{keyResult.title}}'</span>&nbsp;has achieved
                        <span class="fst-italic">'{{keyResult.getFormattedValue(1)}}'</span>&nbsp; on the way to the target of
                        <span class="fst-italic">'{{keyResult.getFormattedValue(keyResult.targetValue)}}'.</span></span>
                </div>

                <div *ngIf="hasValue"
                     class="alert alert-warning">
                    <p>The target value for this key result cannot be changed as there are values attached to it.</p>
                    <p>If you really want to change the target value, you will have to delete the key result and recreate
                        a new one.</p>
                </div>
            </form>
        </div>
    </ng-container>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="hasValidationErrors || keyResult.targetValue < 1"
                                   [saveBlockingClick]="save"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

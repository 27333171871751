import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Item } from "./item";
import { Meeting } from "./meeting";
import { MeetingAgendaItem } from "./meeting-agenda-item";

export class MeetingItem extends BaseEntity<MeetingItem> {
    public itemId!: number;
    public meetingId!: number;
    public meetingAgendaItemId!: number;
    public item!: Item;
    public meeting!: Meeting;
    public meetingAgendaItem!: MeetingAgendaItem;
}

export const MeetingItemBreezeModel = new BreezeModelBuilder("MeetingItem", MeetingItem)
    .hasSource()
    .alwaysFetchingNavigationProperty("meeting")
    .isOrganisationEntity()
    .build();

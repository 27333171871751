import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { IAdaptLinkObject } from "@common/route/route.service";
import { EMPTY, Observable, of } from "rxjs";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesRouteService } from "../objectives-route.service";

@Component({
    selector: "adapt-link-objectives-page",
    templateUrl: "./link-objectives-page.component.html",
})
export class LinkObjectivesPageComponent implements OnChanges {
    @Input() public team?: Team;
    @Input() public view?: ObjectiveViewType;
    @Input() public addObjectiveTextSuffix = false;

    public href$: Observable<IAdaptLinkObject> = EMPTY;
    public canReadPage$: Observable<boolean> = of(false);
    public linkTooltipName = "";
    public linkIcon = "";
    public linkText = "";

    public constructor(
        private objectivesRouteService: ObjectivesRouteService,
        private objectivesAuthService: ObjectivesAuthService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.team) {
            this.canReadPage$ = this.objectivesAuthService.hasReadAccessToObjective(this.team?.teamId);
            this.linkTooltipName = this.team ? `'${this.team.name}' team` : "organisation";
            this.linkIcon = this.team ? "fal fa-users" : "fal fa-cubes";

            this.linkText = this.team ? this.team.name : "Organisation";
            if (this.addObjectiveTextSuffix) {
                if (this.team) {
                    this.linkText += " Team";
                }

                this.linkText += " Objectives";
            }

            const teamId = this.team ? this.team.teamId : undefined;
            this.href$ = this.objectivesRouteService.getObjectivePageRoute(teamId, this.view);
        }
    }
}

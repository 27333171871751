import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAttendee } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { MeetingSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-supplementary-data";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxScrollViewEvent } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { teamKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { DxScrollViewComponent } from "devextreme-angular";
import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { DisplayAgendaItemsComponent } from "../display-agenda-items/display-agenda-items.component";
import { EditAgendaComponent } from "../edit-agenda/edit-agenda.component";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "./../meetings-ui.service";

@Component({
    selector: "adapt-meeting-details",
    templateUrl: "./meeting-details.component.html",
    styleUrls: ["./meeting-details.component.scss"],
})
export class MeetingDetailsComponent extends BaseComponent implements OnChanges {
    @Input() public meeting!: Meeting;
    @Output() public meetingChange = new EventEmitter<Meeting>();
    @Output() public meetingDeleted = new EventEmitter<Meeting>();
    @Output() public meetingStarted = new EventEmitter<Meeting>();
    @Output() public meetingDetailsScrolled = new EventEmitter<IDxScrollViewEvent>();

    @Output() public scrollViewInitialised = new EventEmitter<DxScrollViewComponent>();
    @ViewChild("meetingDetailsScrollView") public set meetingDetailsScrollViewComponent(value: DxScrollViewComponent) {
        this.scrollViewInitialised.emit(value);
    }

    public participants: Person[] = [];
    public agendaItems: MeetingAgendaItem[] = [];

    public agendaExpanded = true;
    public purposeExpanded = false;
    public nonStartableMeetingInfo = "";
    public showAgendaEditor = false;
    public isCurrentPersonInMeeting = false;
    public canExpand = false;
    public canEditMeeting = false;

    // to get around ExpressionChangedAfterItHasBeenCheckedError
    public isInProgress = false;

    private meetingSubscription?: Subscription;
    private triggerUpdateSubject = new BehaviorSubject<void>(undefined);
    public triggerUpdate$ = this.triggerUpdateSubject.asObservable();

    @ViewChild(DisplayAgendaItemsComponent) private displayAgendaItemsComponent?: DisplayAgendaItemsComponent;
    @ViewChild(EditAgendaComponent) private editAgendaComponent?: EditAgendaComponent;

    public constructor(
        public responsiveService: ResponsiveService,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        private userService: UserService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        rxjsBreezeService.entityTypeChanged(MeetingAttendee).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((attendee) => {
            if (attendee.meetingId === this.meeting.meetingId) {
                this.triggerUpdate();
            }
        });

        rxjsBreezeService.entityTypeChanged(MeetingSupplementaryData).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((suppData) => {
            if (suppData.meetingId === this.meeting.meetingId) {
                this.triggerUpdate();
            }
        });

        rxjsBreezeService.entityTypeChanged(Meeting).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((meeting) => {
            if (meeting.meetingId === this.meeting.meetingId) {
                this.triggerUpdate();
                this.showAgendaEditor = this.meeting.extensions.isNotStarted;
            }
        });

        rxjsBreezeService.entityTypeChanged(MeetingAgendaItem).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((agendaItem) => {
            if (agendaItem.meetingId === this.meeting.meetingId) {
                this.triggerUpdate();
            }
        });
    }

    public get canStart() {
        return this.meeting.extensions.isNotStarted && !this.nonStartableMeetingInfo;
    }

    public get currentPersonIsAttendee() {
        const personId = this.userService.getCurrentPersonId();
        return !!this.participants.find((p) => p.personId === personId);
    }

    @Autobind
    public startMeeting() {
        return this.meetingsService.saveEntities(this.meeting.extensions.startMeeting()).pipe(
            tap(() => this.meetingStarted.next(this.meeting)),
            switchMap(() => this.meetingsUiService.isUsingActiveMeetingPage),
            map((isUsingActiveMeetingPage) => {
                if (isUsingActiveMeetingPage) {
                    this.meetingsService.gotoActiveMeetingPage(this.meeting, undefined, true);
                } else {
                    teamKanbanPageRoute.gotoRoute({ teamId: this.meeting.teamId });
                }
            }),
        );
    }

    public triggerUpdate() {
        this.triggerUpdateSubject.next();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.meeting) {
            if (!this.meeting) {
                this.participants = [];
            } else {
                this.canEditMeeting = this.meetingsService.canEditMeetingForTeam(this.meeting.team!);
                this.showAgendaEditor = this.meeting.extensions.isNotStarted;
                if (this.meetingSubscription) { // prevent overlapping subscription as same detail instance for all meeting selections
                    this.meetingSubscription.unsubscribe();
                }

                // force fetch agenda item (w/ supp data) if all agenda items do not have suppData.
                // if some suppData are present it probably means we fetched it all (or the agenda items don't have supp data...)
                const fetchAgendaItemSuppData = this.meeting.meetingAgendaItems?.every((agendaItem) => !agendaItem.supplementaryData);
                
                this.meetingSubscription = this.triggerUpdate$.pipe(
                    debounceTime(10),
                    switchMap(() => forkJoin([
                        this.meetingsService.getAgendaItemsForMeeting(this.meeting, true, fetchAgendaItemSuppData),
                        this.meetingsService.getMeetingAttendeesForMeeting(this.meeting),
                        this.meetingsService.getNonStartableMeetingInfo(this.meeting),
                        this.meetingsService.getSupplementaryDataForMeeting(this.meeting.meetingId),
                    ])),
                    this.takeUntilDestroyed(),
                ).subscribe(([agendaItems, meetingAttendees, nonStartableMeetingInfo]) => {
                    this.agendaItems = agendaItems;
                    this.participants = meetingAttendees.map((a) => a.attendee);
                    this.agendaExpanded = !this.meeting.extensions.isNotStarted;
                    this.nonStartableMeetingInfo = nonStartableMeetingInfo;
                    this.isCurrentPersonInMeeting = this.meetingsService.isCurrentPersonInMeeting(this.meeting);
                    this.isInProgress = this.meeting.extensions.isInProgress;
                });

                this.triggerUpdate();
            }
        }
    }

    public toggleExpand() {
        this.agendaExpanded = !this.agendaExpanded;
        this.displayAgendaItemsComponent?.setExpandAll(this.agendaExpanded);
        this.editAgendaComponent?.setExpandAll(this.agendaExpanded);
    }
}

import { Component, Injector } from "@angular/core";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { CommonPurposeVisionService, SeedEntityConfigs } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, defer, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ISeed, ISeedAnswers, ISeedStepData } from "./seed.interface";

@WorkflowStepComponent("adapt-define-seed")
@Component({
    selector: "adapt-define-seed",
    templateUrl: "./define-seed.component.html",
})
export class DefineSeedComponent<T extends string> extends WorkflowStepComponentAdapter {
    public isValid = new BehaviorSubject<boolean>(false);
    public workflowStepCompleted = this.isValid.asObservable();

    public selectedSeed?: ISeed<T>;
    public seedAnswers?: ISeedAnswers;

    private entityConfiguration?: SeedEntityConfigs;

    public constructor(
        injector: Injector,
        private commonDataService: CommonDataService,
        private commonPurposeVisionService: CommonPurposeVisionService,
    ) {
        super();

        this.subscribeToEmitForEntityTypeChange(injector, GuidingPhilosophy);
        this.subscribeToEmitForEntityTypeChange(injector, Purpose);
    }

    public async workflowStepOnInit() {
        const stepData = this.workflowStep?.customData as ISeedStepData<T>;
        if (!stepData) {
            throw new Error("Seed data is not available");
        }

        this.seedAnswers = this.seedAnswers
            ?? Object.fromEntries(stepData.seeds.map((seed) => [seed.key, ""])) as ISeedAnswers;

        this.selectedSeed = stepData.seed;

        this.entityConfiguration = await this.commonPurposeVisionService.getSeedEntity(stepData.entity, true);
        if (this.entityConfiguration) {
            const value = this.entityConfiguration.entity![this.entityConfiguration.field];
            if (value) {
                this.seedAnswers = JSON.parse(value);
            }
        }

        this.validateEntity();
    }

    public workflowStepNext() {
        return defer(() => this.commonDataService.saveEntities(this.entityConfiguration!.entity)).pipe(
            tap(() => this.workflowStepErrorMessage.next(undefined)),
            catchError((err) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(err);
                this.workflowStepErrorMessage.next(errorMessage);
                return throwError(() => new AdaptError(errorMessage));
            }),
        );
    }

    public async onAnswerChange(key: string, value: string) {
        if (this.entityConfiguration && this.seedAnswers) {
            this.seedAnswers[key] = value;

            this.entityConfiguration.entity![this.entityConfiguration.field] = Object.values(this.seedAnswers).some((answer) => !!answer)
                ? JSON.stringify(this.seedAnswers)
                : undefined;
        }

        this.validateEntity();
    }

    private validateEntity() {
        this.isValid.next(this.entityConfiguration?.entity?.entityAspect.validateEntity() ?? false);
    }
}

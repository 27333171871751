import { Injectable, Injector } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BaseService } from "@common/service/base.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { lastValueFrom } from "rxjs";
import { CommonTeamsService } from "./common-teams.service";
import { ConfigureTeamStewardshipDialogComponent } from "./configure-team-stewardship-dialog/configure-team-stewardship-dialog.component";

@Injectable({
    providedIn: "root",
})
export class CommonTeamsUiService extends BaseService {
    public constructor(
        injector: Injector,
        private commonDialogService: AdaptCommonDialogService,
        private teamsService: CommonTeamsService,
    ) {
        super(injector);
    }

    public async promiseToReactivateTeam(team: Team) {
        const dialogData: IConfirmationDialogData = {
            title: "Reactivate Team",
            checkboxMessage: "Confirm that you wish to reactivate this team",
        };

        const shouldReactivate = await lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean(dialogData));
        if (shouldReactivate) {
            await this.teamsService.promiseToReactivateTeam(team);
        }
    }

    public configureTeamStewardship(team: Team) {
        return this.commonDialogService.open(ConfigureTeamStewardshipDialogComponent, team);
    }
}

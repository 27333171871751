import { Injectable } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { map, of, switchMap } from "rxjs";
import { IMeetingWorkflowCustomData } from "../meetings/meeting-workflow-custom-data.interface";
import { MeetingsService } from "../meetings/meetings.service";
import { WorkflowService } from "./workflow.service";

export interface IWorkflowMeetingDetails {
    template?: MeetingAgendaTemplate;
}

export interface IRunWorkshopCustomData {
    meetingId?: number;
}

@Injectable({
    providedIn: "root",
})
export class WorkflowMeetingService {
    public constructor(
        private meetingsService: MeetingsService,
        private workflowService: WorkflowService,
    ) {
    }

    public createWorkflowMeeting(meeting: Meeting, template: MeetingAgendaTemplate) {
        meeting.name = template.name;
        return this.meetingsService.getAgendaItemsForMeetingAgendaTemplate(template.meetingAgendaTemplateId).pipe(
            switchMap((templateItems) => this.meetingsService.importAgendaItems(templateItems, meeting.meetingId)),
            switchMap((newItems) => this.meetingsService.fetchArticlesForMeetingSuppData(meeting, template.meetingDescriptionArticleSlug).pipe(
                map(() => newItems),
            )),
            map((newItems) => [
                meeting,
                ...(meeting.supplementaryData ? [meeting.supplementaryData] : []),
                ...newItems,
                ...newItems.map((i) => i.supplementaryData).filter((suppData) => !!suppData),
            ]),
        );
    }

    public getMeetingWorkflowConnection(meeting?: Meeting) {
        const meetingCustomData = meeting?.extensions.getCustomData<IMeetingWorkflowCustomData>();
        return meetingCustomData?.workflowConnectionId
            ? this.workflowService.getWorkflowConnectionById(meetingCustomData.workflowConnectionId)
            : of(undefined);
    }

    public getMeetingFromWorkflowConnection(workflowConnection: WorkflowConnection) {
        return this.workflowService.getWorkflowCustomData<IRunWorkshopCustomData>(workflowConnection).pipe(
            switchMap((customData) => customData.meetingId
                ? this.meetingsService.getMeetingById(customData.meetingId)
                : of(undefined)),
        );
    }
}

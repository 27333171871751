import { Component, Injector } from "@angular/core";
import { EventCadenceCycle } from "@common/ADAPT.Common.Model/organisation/event-cadence-cycle";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ISetCadenceRunData } from "@org-common/lib/schedule/schedule.interface";
import { ScheduleService } from "@org-common/lib/schedule/schedule.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import moment from "moment/moment";
import { defer, lastValueFrom } from "rxjs";
import { EventTypePresets } from "../establish-cadence-workflows";

@WorkflowStepComponent("adapt-set-cadence-cycle-step")
@Component({
    selector: "adapt-set-cadence-cycle-step",
    templateUrl: "./set-cadence-cycle-step.component.html",
})
export class SetCadenceCycleStepComponent extends WorkflowStepComponentAdapter {
    public readonly currentMonth = moment().month() + 1;
    public readonly months = moment.months()
        .map((text, value) => ({ text, value: value + 1 }));

    public runData!: ISetCadenceRunData;
    public cadenceCycle!: EventCadenceCycle;

    public constructor(
        injector: Injector,
        private commonDataService: CommonDataService,
        private teamsService: CommonTeamsService,
        private scheduleService: ScheduleService,
    ) {
        super();

        this.subscribeToEmitForEntityTypeChange(injector, EventCadenceCycle);
    }

    public async workflowStepOnInit() {
        this.cadenceCycle = await lastValueFrom(this.scheduleService.getOrCreateEventCadenceCycle({ month: this.currentMonth }));

        const team = await this.teamsService.promiseToGetLeadershipTeam();
        if (!team) {
            throw new Error("Leadership team not found");
        }

        const workflow = this.workflowStep?.workflow;
        if (workflow) {
            this.runData = workflow.runData = workflow.runData
                ?? await this.scheduleService.getCadenceRunData(EventTypePresets, team, false);
        }
    }

    public workflowStepNext() {
        return defer(async () => {
            const runData = this.runData;
            if (this.hasExistingCadences && this.cadenceCycle.entityAspect.entityState.isModified()) {
                await this.scheduleService.promiseToClearConfiguredCadences(runData);
            }

            return lastValueFrom(this.commonDataService.saveEntities(this.cadenceCycle));
        });
    }

    public async undoChanges() {
        await lastValueFrom(this.commonDataService.rejectChanges(this.cadenceCycle));
    }

    public get hasExistingCadences() {
        const cadences = this.runData?.scheduledPresets;
        return cadences && cadences.size > 0;
    }
}

<div class="display-tree-objective d-flex flex-column"
     *ngIf="!!objective"
     [ngClass]="{'root': !objective.parentObjectiveId, 'external-objective': isExternalObjective, 'unfocussed': !isFocussed, 'compact': isCompactView}"
     [attr.id]="objective.objectiveId">
    <div class="objective-body"
         [class.has-progress]="showProgress">
        <div class="d-flex flex-column align-middle mb-1">
            <div class="d-flex flex-row objective-title">
                <adapt-link-person *ngIf="objective.assigneePersonId && isFocussed"
                                   class="ms-1"
                                   [personId]="objective.assigneePersonId"
                                   [showImage]="true"
                                   [showText]="false"></adapt-link-person>

                <adapt-link-objective class="me-auto"
                                      [objective]="objective"
                                      [showStatus]="false"
                                      [showIcon]="true"
                                      [showStatusIcon]="false"></adapt-link-objective>
                <adapt-link-objectives-page *ngIf="isExternalObjective && !isFocussed"
                                            class="align-self-center px-2"
                                            [team]="objective.team"
                                            [view]="treeViewType"></adapt-link-objectives-page>
                <adapt-display-objective-status *ngIf="!isCompactView"
                                                class="align-self-center me-1"
                                                [objectiveStatus]="objectiveStatus"></adapt-display-objective-status>

                <adapt-objective-actions *ngIf="hasEditPermissions"
                                         class="align-self-center"
                                         [objective]="objective"></adapt-objective-actions>
            </div>
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex flex-row flex-wrap align-items-center gap-2">
                <ng-container *ngIf="isFocussed">
                    <span><b>Due</b> {{objective.dueDate | adaptDate}}</span>
                    <adapt-link-objectives-page *ngIf="isExternalObjective"
                                                [team]="objective.team"
                                                [view]="treeViewType"></adapt-link-objectives-page>
                </ng-container>
                <adapt-display-objective-status *ngIf="isCompactView"
                                                [isCompactView]="isCompactView"
                                                class="d-inline-flex"
                                                [objectiveStatus]="objectiveStatus"></adapt-display-objective-status>
                <adapt-display-labels *ngIf="objective.labelLocations?.length"
                                      [labelLocations]="objective.labelLocations"></adapt-display-labels>
            </div>

            <ng-container *ngIf="isFocussed && !isCompactView">
                <div *ngIf="objective.objectiveLinks.length > 0"
                     class="related">
                    <strong>Related Objectives</strong>
                    <div *ngFor="let objectiveLink of objective.objectiveLinks"
                         class="d-flex me-1">
                        <adapt-link-objective class="item-title me-auto"
                                              [objective]="objectiveLink.objective2"></adapt-link-objective>
                        <adapt-link-objectives-page class="team-link"
                                                    [team]="objectiveLink.objective2.team"
                                                    [view]="treeViewType"></adapt-link-objectives-page>
                    </div>
                </div>
                <div *ngIf="objective.itemLinks.length > 0"
                     class="related">
                    <strong>Related Actions</strong>
                    <div *ngFor="let itemLink of objective.itemLinks">
                        <adapt-item-link class="item-title"
                                         [item]="itemLink.item"
                                         [showSummary]="true"
                                         [showStatus]="true"></adapt-item-link>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="showProgress"
         class="objective-footer">
        <adapt-progress-bar [percentageProgress]="objective.currentProgress"
                            [scaleHeight]="1.25"
                            colour="var(--adapt-open-blue-500)"
                            backgroundColour="#f5f5f5"></adapt-progress-bar>
    </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IEntityWithLabelLocations } from "@common/ADAPT.Common.Model/organisation/entity-with-label-locations";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { forkJoin, merge, Observable, of } from "rxjs";
import { map, startWith, switchMap, tap } from "rxjs/operators";
import { LabellingService } from "../labelling.service";

@Component({
    selector: "adapt-select-label-for-related-entity",
    templateUrl: "./select-label-for-related-entity.component.html",
})
export class SelectLabelForRelatedEntityComponent extends BaseComponent implements OnInit {
    // only set one of these input
    @Input() public set item(value: Item) {
        this.setEntity(value);
        this.getLabelLocationsObservable = this.labellingService.getLabelLocationsForItem(value.itemId);
    }

    @Input() public set keyFunction(value: KeyFunction) {
        this.setEntity(value);
        this.getLabelLocationsObservable = this.labellingService.getLabelLocationsForKeyFunction(value.keyFunctionId);
    }

    @Input() public set team(value: Team) {
        this.setEntity(value);
        this.getLabelLocationsObservable = this.labellingService.getLabelLocationsForTeam(value.teamId);
    }

    @Input() public set objective(value: Objective) {
        this.setEntity(value);
        this.getLabelLocationsObservable = this.labellingService.getLabelLocationsForObjective(value.objectiveId);
    }

    @Input() public set system(value: SystemEntity) {
        this.setEntity(value);
        this.getLabelLocationsObservable = this.labellingService.getLabelLocationsForSystem(value.systemEntityId);
    }

    @Input() public set processStep(value: ProcessStep) {
        this.setEntity(value);
        this.getLabelLocationsObservable = this.labellingService.getLabelLocationsForProcessStep(value.processStepId);
    }

    @Input() public set role(value: Role) {
        this.setEntity(value);
        this.getLabelLocationsObservable = this.labellingService.getLabelLocationsForRole(value.roleId);
    }

    @Output() public entitiesChange = new EventEmitter<LabelLocation[]>();

    public labels$: Observable<Label[]> = of([]);
    private relatedEntity?: IEntityWithLabelLocations;
    private removedLabelLocations: LabelLocation[] = [];
    private getLabelLocationsObservable?: Observable<LabelLocation[]>;

    public constructor(
        private labellingService: LabellingService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
    }

    public ngOnInit() {
        if (!this.getLabelLocationsObservable || !this.relatedEntity) {
            throw new Error("At least one of related entity is expected to be set");
        }

        // Only prime label locations if there isn't any before.
        // e.g. Issuing a separate query instead of relying on the kanban to do a single query
        // as the edit item dialog can possibly be spawn from other pages other than kanban page
        // - should only do this if label location is not primed however we can't really tell
        //   if an item has no label or not primed (both Array(0))
        const getLabelLocations = this.relatedEntity.labelLocations?.length
            ? of(this.relatedEntity.labelLocations)
            : this.getLabelLocationsObservable;

        this.labels$ = getLabelLocations.pipe(
            // prime labels if there is any unprimed label from labelLocations
            switchMap((labelLocations) => labelLocations.some((l) => !l.label) ? this.labellingService.getAllLabels() : of([])),
            // The following emit to handle update of label when label location deleted / add cancelled
            switchMap(() => merge(
                this.rxjsBreezeService.entityTypeDetached(LabelLocation).pipe(startWith(undefined)),
                this.rxjsBreezeService.entityTypeUndo(LabelLocation), // cancel deletion
            )),
            map(() => this.relatedEntity!.labelLocations),
            map((labelLocations) => labelLocations.map((i) => i.label)),
        );
    }

    public onLabelsChanged(labels: Label[]) {
        const removeEntities: LabelLocation[] = [];
        this.relatedEntity!.labelLocations.forEach((existingLocation) => {
            if (!labels.find((i) => i.labelId === existingLocation.labelId)) {
                // label removed from select-label
                removeEntities.push(existingLocation);
            }
        });

        const addLabels = labels
            .filter((i) => !this.relatedEntity!.labelLocations.find((existingLocation) => existingLocation.labelId === i.labelId))
            .map((label) => this.labellingService.createLabelLocationFromLabel(label).pipe(
                tap((labelLocation) => {
                    labelLocation.itemId = this.relatedEntity?.itemId;
                    labelLocation.keyFunctionId = this.relatedEntity?.keyFunctionId;
                    labelLocation.objectiveId = this.relatedEntity?.objectiveId;
                    labelLocation.systemId = this.relatedEntity?.systemEntityId;
                    labelLocation.processStepId = this.relatedEntity?.processStepId;

                    if (this.relatedEntity instanceof Team) {
                        // other related entity can have teamId too, e.g. objective
                        labelLocation.teamId = this.relatedEntity?.teamId;
                    }

                    if (this.relatedEntity instanceof Role) {
                        // other related entity can have roleId too, e.g. process step
                        labelLocation.roleId = this.relatedEntity?.roleId;
                    }
                }),
            ));

        const removeObservables = removeEntities.map((i) => this.labellingService.remove(i));
        // last emit for the case where there is no remove observables
        removeObservables.push(of(undefined));
        forkJoin(removeObservables).pipe(
            tap(() => this.removedLabelLocations = this.removedLabelLocations.concat(removeEntities)),
            switchMap(() => addLabels.length > 0 ? forkJoin(addLabels) : of(undefined)),
            tap((newLabelLocations) => {
                if (newLabelLocations?.length) {
                    // newly created label locations -> reorder labelLocationId so that latest created will be largest
                    // otherwise server will return ids that's the other way round, e.g.
                    // new label location with id of -3, -4, will get LabelLocationId of 26 (from -3), 25 (from -4) as -4 is smaller than -3
                    const newLocations = this.relatedEntity!.labelLocations.filter((i) => i.entityAspect.entityState.isAdded());
                    const newLocationIds = newLocations.map((i) => i.labelLocationId).sort((a, b) => a - b);
                    let smallestId = newLocationIds[0];
                    for (let i = 0; i < newLocations.length; i++) {
                        // breeze won't allow id key that's already in use
                        const conflictLocation = newLocations.find((l) => l.labelLocationId === newLocationIds[i]);
                        if (conflictLocation) {
                            // this will get reassigned later - just move it away to some unused id to free up the id
                            // to be used by this current label location
                            conflictLocation.labelLocationId = --smallestId;
                        }

                        newLocations[i].labelLocationId = newLocationIds[i];
                    }
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.entitiesChange.emit([...this.removedLabelLocations, ...this.relatedEntity!.labelLocations]));
    }

    private setEntity(entity: IEntityWithLabelLocations) {
        if (this.relatedEntity) {
            throw new Error("Exactly 1 related entity is expected through @Input()");
        }

        this.relatedEntity = entity;
    }
}

<ng-container *adaptLoading="!isInitialised">
    <div *ngIf="hasTheme; else noGoalsOrThemesTemplate">
        <div *ngIf="showToolbarMenu"
             class="d-flex">
            <div class="d-flex justify-content-center flex-grow-1">
                <ng-container [ngTemplateOutlet]="viewToggleButtons">
                </ng-container>
            </div>
            <adapt-menu *ngIf="views.includes(StrategicViewOption.Goals); else notGoalsPadding"
                        class="ms-auto"
                        [items]="menuItems"></adapt-menu>
            <ng-template #notGoalsPadding>
                <i class="fa fa-fw fa-2x"></i>
            </ng-template>
        </div>
        <ng-template #viewToggleButtons>
            <div class="view-toggle-buttons">
                <button *ngFor="let option of viewOptions"
                        [adaptButtonToggle]="isViewSelected(option.option)"
                        [adaptButtonIcon]="option.iconClass"
                        [attr.data-tour]="option.tour"
                        (click)="toggleView(option.option)">{{option.text}}</button>
            </div>
        </ng-template>

        <adapt-render-zone-map [zoneContentTemplate]="zoneContentTemplate"
                               [zoneMenuTemplate]="zoneMenuTemplate"
                               [fullLayoutBreakpoint]="FullLayoutBreakpoint"></adapt-render-zone-map>
    </div>
</ng-container>

<ng-template #zoneContentTemplate
             let-zone>
    <adapt-strategy-zone [zone]="zone"
                         [expandGoals]="expandGoals"
                         [isEditing]="isEditing && hasEditAccess"
                         [views]="views"></adapt-strategy-zone>
</ng-template>

<ng-template #zoneMenuTemplate
             let-zone>
    <adapt-menu *ngIf="isEditing && hasEditAccess && getMenuItemsForZone(zone) as menuItems"
                [items]="menuItems"
                [attr.data-tour]="'zone-action-menu-' + zone"></adapt-menu>
</ng-template>

<ng-template #noGoalsOrThemesTemplate>
    The strategic themes have not been defined at the moment.
</ng-template>

<div class="card collapsible-card">
    <div class="header card-header gap-2"
         [ngClass]="{'collapsible': isCollapsible, 'collapsed': collapsed}"
         (click)="toggle()">
        <i *ngIf="handleId"
           class="fal fa-grip-dots-vertical pe-1 gripper"
           [attr.id]="handleId"></i>
        <i *ngIf="iconClass"
           [ngClass]="iconClass"
           class="flex-shrink-0"
           [adaptTooltip]="iconTooltip"></i>
        <h4 class="m-0 d-flex align-items-baseline w-100">
            <a *ngIf="linkObject; else noLink"
               class="me-1"
               [routerLink]="linkObject.path"
               [queryParams]="linkObject.queryParams">
                {{name}}<i class="fal fa-angle-right ms-2"></i>
            </a>
            <i *ngIf="info"
               class="ms-1 fal fa-circle-info"
               [adaptTooltip]="info"></i>

            <ng-template #noLink>
                <span class="me-1">{{name}}</span>
            </ng-template>

            <ng-content select="[adapt-collapsible-header-action]"></ng-content>
        </h4>

        <ng-content select="[adapt-collapsible-header-action-right]"></ng-content>
        <i *ngIf="isCollapsible && isExpandable"
           class="ms-auto fal {{collapsed ? 'fa-chevron-down' : 'fa-chevron-up'}}"></i>
    </div>
    <div *ngIf="isExpandable"
         [collapse]="collapsed"
         (collapsed)="animationCompleted($event)"
         (expanded)="animationCompleted($event)"
         [isAnimated]="true">
        <div class="card-body element-body">
            <dx-scroll-view [scrollByContent]="true"
                            direction="horizontal">
                <div class="body">
                    <ng-content></ng-content>
                </div>
            </dx-scroll-view>
        </div>
    </div>
</div>

<div class="form-group">
    <label for="card">Card</label>
    <dx-text-box class="form-control mb-1"
                 name="name"
                 placeholder="Card name"
                 [(value)]="cardDetails!.name"
                 required>
        <dx-validator>
            <dxi-validation-rule type="required"
                                 message="Card name is required"></dxi-validation-rule>
        </dx-validator>
    </dx-text-box>
    <div class="form-control"
         name="card">
        <div id="card-element">
            <!-- a Stripe Element will be inserted here. -->
        </div>
    </div>
</div>

<div class="card-security">
    <a href="
     https://stripe.com/docs/security/stripe"
       target="_blank"
       rel="noreferrer noopener"
       adaptTooltip="Security and privacy are important to us. All communication is over a secure connection, and credit card details are only stored by our payment processor.">
        <!-- got this link from here: https://brandfolder.com/s/99gctvbpwgvzbc7mz3j9g4x via https://stripe.com/au/newsroom/information -->
        <img src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg">
    </a>
</div>

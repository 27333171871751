import { Component, ElementRef } from "@angular/core";
import { Bullseye } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ChatAiService } from "@common/lib/chat-ai/chat-ai.service";
import { Logger } from "@common/lib/logger/logger";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, catchError, of, tap } from "rxjs";

@WorkflowStepComponent("adapt-custom-bullseye-statement-step")
@Component({
    selector: "adapt-custom-bullseye-statement-step",
    templateUrl: "./custom-bullseye-statement-step.component.html",
    styleUrls: ["./custom-bullseye-statement-step.component.scss"],
})
export class CustomBullseyeStatementStepComponent extends WorkflowStepComponentAdapter {
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    public bullseye?: Bullseye;

    public placeholderText?: string;

    public constructor(
        elementRef: ElementRef,
        private bullseyeService: BullseyeService,
        private chatAiService: ChatAiService,
    ) {
        super(elementRef);

        this.bullseyeService.getBullseye().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((bullseye) => setTimeout(() => {
            this.bullseye = bullseye;

            this.workflowStepCompleted.next(!!this.bullseye!.statement);
            this.isInitialised = true;
        }));
    }

    public workflowStepNext() {
        return this.bullseye
            ? this.bullseyeService.saveEntities(this.bullseye)
            : of(undefined);
    }

    @Autobind
    public defineCustomStatement() {
        return this.chatAiService.generateBullseyeStatement(
            this.bullseye!.quadrantStatements
                .filter((s) => s.inBullseye)
                .map((s) => s.statement),
            250,
        ).pipe(
            tap((response) => this.onStatementChanged(response)),
            catchError((error) => {
                this.placeholderText = "Type your bullseye statement here";
                Logger.getLogger("custom-bullseye-statement-step").error(ErrorHandlingUtilities.getHttpResponseMessage(error.message));
                throw error;
            }),
            this.takeUntilDestroyed(),
        );
    }

    @Autobind
    public paraphrase() {
        if (this.bullseye?.statement) {
            return this.chatAiService.paraphraseStatement(this.bullseye.statement).pipe(
                tap((response) => this.onStatementChanged(response)),
                this.takeUntilDestroyed(),
            );
        } else {
            return this.defineCustomStatement();
        }
    }

    public onStatementChanged(statement: string) {
        this.bullseye!.statement = statement;
        this.emitEntityChange(this.bullseye!);
        this.workflowStepCompleted.next(!!this.bullseye?.statement);
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { DxPopoverModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { AdaptFeaturesModule } from "../features/features.modules";
import { AdaptZoneMapModule } from "../zone-map/zone-map.module";
import { DisplayThemeInfoComponent } from "./display-theme-info/display-theme-info.component";
import { EditThemeDialogComponent } from "./edit-theme-dialog/edit-theme-dialog.component";
import { SelectThemeComponent } from "./select-theme/select-theme.component";
import { StrategicGuidanceComponent } from "./strategic-guidance/strategic-guidance.component";
import { StrategyAuthService } from "./strategy-auth.service";

@NgModule({
    declarations: [
        SelectThemeComponent,
        DisplayThemeInfoComponent,
        EditThemeDialogComponent,
        StrategicGuidanceComponent,
    ],
    imports: [
        CommonModule,

        AdaptHtmlEditorModule,
        AdaptCommonDialogModule,
        AdaptZoneMapModule,
        AdaptEntityValidatorModule,
        AdaptButtonModule,
        AdaptImplementationKitModule,
        AdaptFeaturesModule,

        DxSelectBoxModule,
        DxTextBoxModule,
        DxScrollViewModule,
        DxPopoverModule,
    ],
    exports: [
        SelectThemeComponent,
        DisplayThemeInfoComponent,
        StrategicGuidanceComponent,
    ],
})
export class AdaptStrategyModule {
    constructor(authorisationService: AuthorisationService) {
        StrategyAuthService.registerAccess(authorisationService);
    }
}

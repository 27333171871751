import { Injectable, Injector } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { KeyResult, KeyResultBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-result";
import { KeyResultValue, KeyResultValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { LabelLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Objective, ObjectiveBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveComment, ObjectiveCommentBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective-comment";
import { ObjectiveItemLink, ObjectiveItemLinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective-item-link";
import { ObjectiveLink, ObjectiveLinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective-link";
import { ObjectiveStatus } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { ValidationError } from "breeze-client";
import moment from "moment";
import { EMPTY, forkJoin, lastValueFrom, Observable, of, Subject } from "rxjs";
import { catchError, filter, first, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { ObjectiveFilter } from "./objective-filter/objective-filter";
import { ObjectivesAuthService } from "./objectives-auth.service";

const ObjQueryChunkSize = 20; // change this to 21 and we will get 'The node count limit of '100' has been exceeded.' error

export interface IObjectiveGroup {
    objective: Objective;
    childGroups: IObjectiveGroup[];
}

export interface IObjectiveTeamGroup {
    team?: Team;
    objectives: Objective[];
}

@Injectable({
    providedIn: "root",
})
export class ObjectivesService extends BaseOrganisationService {
    public get keyResultValueUpdated$() {
        return this._keyResultValueUpdated$.asObservable();
    }

    public get objectiveUpdated$() {
        return this._objectiveUpdated$.asObservable();
    }

    private _keyResultValueUpdated$ = new Subject<KeyResultValue>();
    private _objectiveUpdated$ = new Subject<Objective>();

    public constructor(
        injector: Injector,
        private userService: UserService,
        private commonDialogService: AdaptCommonDialogService,
        private objectivesAuthService: ObjectivesAuthService,
        private labellingService: LabellingService,
    ) {
        super(injector);
    }

    public getObjectiveById(objectiveId: number) {
        return this.commonDataService.getById(ObjectiveBreezeModel, objectiveId);
    }

    public getObjectivesByIds(objectiveIds: number[]) {
        if (objectiveIds.length > 0) {
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(objectiveIds, ObjQueryChunkSize);
            return forkJoin(chunksOfIds.map((ids) => this.getObjectivesByPredicate(new MethodologyPredicate<Objective>("objectiveId", "in", ids)))).pipe(
                map((queryResults) => ArrayUtilities.mergeArrays(queryResults)),
            );
        } else {
            return of([] as Objective[]);
        }
    }

    public getObjectivesWithParentId(parentObjectiveId?: number) {
        return this.commonDataService.getByPredicate(ObjectiveBreezeModel,
            new MethodologyPredicate<Objective>("parentObjectiveId", "==", parentObjectiveId ?? null));
    }

    public getObjectivesWithParentIds(parentObjectiveIds: number[]) {
        if (parentObjectiveIds.length > 0) {
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(parentObjectiveIds, ObjQueryChunkSize);
            return forkJoin(chunksOfIds.map((ids) => this.commonDataService.getByPredicate(ObjectiveBreezeModel,
                new MethodologyPredicate<Objective>("parentObjectiveId", "in", ids)))).pipe(
                    map((queryResults) => ArrayUtilities.mergeArrays(queryResults)),
                );
        } else {
            return of([] as Objective[]);
        }
    }

    public getKeyResultsForObjectiveId(objectiveId: number) {
        const key = `getKeyResultsForObjectiveId${objectiveId}`;
        return this.commonDataService.getWithOptions(KeyResultBreezeModel, key, {
            predicate: new MethodologyPredicate<KeyResult>("objectiveId", "==", objectiveId),
            navProperty: "values.person",
        });
    }

    public getKeyResultsForObjectives(objectives: Objective[]) {
        const ids = objectives.map((i) => i.objectiveId);
        return this.getKeyResultsForObjectiveIds(ids);
    }

    public getKeyResultsForObjectiveIds(objectiveIds: number[]) {
        if (objectiveIds.length > 0) {
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(objectiveIds, ObjQueryChunkSize);
            return forkJoin(chunksOfIds.map((ids) => this.commonDataService.getWithOptions(
                KeyResultBreezeModel,
                `getKeyResultsForObjectiveIds${ids.join("_")}`,
                {
                    predicate: new MethodologyPredicate<KeyResult>("objectiveId", "in", ids),
                    navProperty: "values.person",
                })))
                .pipe(
                    map((queryResults) => ArrayUtilities.mergeArrays(queryResults)),
                );
        } else {
            return of([] as KeyResult[]);
        }
    }

    public getObjectiveCommentsForObjectiveId(objectiveId: number) {
        return this.commonDataService.getByPredicate(ObjectiveCommentBreezeModel, new MethodologyPredicate<ObjectiveComment>("objectiveId", "==", objectiveId));
    }

    public getObjectivesByPredicate(predicate: MethodologyPredicate<Objective>) {
        return this.commonDataService.getByPredicate(ObjectiveBreezeModel, predicate);
    }

    public createObjective(initialData?: Partial<Objective>) {
        return this.commonDataService.create(ObjectiveBreezeModel, initialData);
    }

    public createKeyResult(initialData?: Partial<KeyResult>) {
        return this.commonDataService.create(KeyResultBreezeModel, initialData);
    }

    public createKeyResultValue(initialData?: Partial<KeyResultValue>) {
        return this.commonDataService.create(KeyResultValueBreezeModel, initialData);
    }

    public createObjectiveLink(initialData?: Partial<ObjectiveLink>) {
        return this.commonDataService.create(ObjectiveLinkBreezeModel, initialData);
    }

    public createObjectiveItemLink(initialData?: Partial<ObjectiveItemLink>) {
        return this.commonDataService.create(ObjectiveItemLinkBreezeModel, initialData);
    }

    public emitKeyResultValueUpdate(keyResultValue: KeyResultValue) {
        this._keyResultValueUpdated$.next(keyResultValue);
    }

    public emitObjectiveUpdate(objective: Objective) {
        this._objectiveUpdated$.next(objective);
    }

    /** Fetches the InProgress objectives for the organisation or specified team, priming all associated KeyResults and values */
    public getInProgressObjectives(team?: Team, showExternalObjectives = true) {
        return this.objectivesAuthService.hasReadAccessToObjective(team ? team.teamId : undefined).pipe(
            switchMap((hasAccess) => {
                if (hasAccess) {
                    const objectiveFilter = new ObjectiveFilter();
                    objectiveFilter.showExternalObjectives = showExternalObjectives;
                    return this.getPrimedObjectives(objectiveFilter, team?.teamId);
                } else {
                    return of([]);
                }
            }),
        );
    }

    public getUnclosedPotentialSupportiveObjectives(objective: Objective) {
        // annual org objectives cant be supported by anything, so return immediately
        if (objective.type === ObjectiveType.Annual && !objective.teamId) {
            return of([]);
        }

        // build a predicate that will be reused across multiple queries to this function
        // the predicate is intentionally of a wider scope than required, but it should result in less queries to the backend
        // further filtering will be done after the larger list of objectives is returned from the server
        const predicate = new MethodologyPredicate<Objective>("status", "!=", ObjectiveStatus.Closed);
        if (objective.team) {
            const orgPredicate = new MethodologyPredicate<Objective>("teamId", "==", null);
            const teamPredicate = new MethodologyPredicate<Objective>("teamId", "==", objective.team.teamId);
            predicate.and(orgPredicate.or(teamPredicate));
        } else {
            predicate.and(new MethodologyPredicate<Objective>("teamId", "==", null));
        }

        return this.getObjectivesByPredicate(predicate)
            .pipe(map((objectives) => objectives.filter((filterObjective) => {
                if (objective.team) {
                    if (objective.type === ObjectiveType.Annual) {
                        return filterObjective.teamId === null;
                    } else {
                        return filterObjective.type === ObjectiveType.Annual
                            || filterObjective.teamId === null;
                    }
                } else {
                    return filterObjective.type === ObjectiveType.Annual;
                }
            })));
    }

    public getInProgressObjectivesForPerson(person: Person) {
        // current person need to have at least permission to read from objective table
        return this.objectivesAuthService.hasAnyReadAccessToObjective$.pipe(
            switchMap((hasReadAccess) => {
                if (hasReadAccess) {
                    const key = `inProgressPersonObjectives${person.personId}`;
                    const predicate = new MethodologyPredicate<Objective>("assigneePersonId", "==", person.personId);
                    predicate.and(new MethodologyPredicate<Objective>("status", "!=", ObjectiveStatus.Closed));

                    return this.commonDataService.getWithOptions(ObjectiveBreezeModel, key, {
                        predicate,
                        orderBy: "dueDate ASC",
                    }).pipe(
                        switchMap((objectives) => this.getKeyResultsForObjectives(objectives).pipe(
                            map(() => objectives),
                        )),
                    );
                } else {
                    return of([]);
                }
            }),
        );
    }

    public getAllObjectives(objectives: Objective[]) {
        const results: Objective[] = [];
        for (const objective of objectives) {
            getObjectivesRecurse(objective, results);
        }

        // check the base objectives parent objective and add that to the list too
        // we are checking using a new array iterator in case we have a recursive objective situation
        for (const objective of objectives) {
            if (objective.parentObjective && !results.includes(objective.parentObjective)) {
                results.push(objective.parentObjective);
            }
        }

        return results;

        function getObjectivesRecurse(objective: Objective, collection: Objective[]) {
            if (!collection.includes(objective)) {
                collection.push(objective);
                for (const child of objective.childObjectives) {
                    getObjectivesRecurse(child, collection);
                }
            }
        }
    }

    /** Gets all objectives matching the passed in filter, priming child objectives and associated key results and values */
    public getPrimedObjectives(objectiveFilter: ObjectiveFilter, teamId?: number) {
        const self = this;
        const predicate = objectiveFilter.buildObjectivesPredicate(teamId);
        return this.getObjectivesByPredicate(predicate).pipe(
            //filter by label before we navigate up the objectives
            switchMap((objectives) => this.labellingService.primeLabelLocationsForObjectives(this.getAllObjectives(objectives)).pipe(
                map(() => objectives),
            )),
            map((objectives) => objectiveFilter.labels.length > 0 ? objectives.filter((o) => o.labelLocations.some((labelLocation) => objectiveFilter.labelIds!.some((l) => labelLocation.labelId === l))) : objectives),
            switchMap(async (objectives) => {
                if (!teamId || objectiveFilter.showExternalObjectives) {
                    const allObjectives = [...objectives];
                    await getObjectivesRecurse(objectives, allObjectives, !!teamId);
                    return allObjectives;
                } else {
                    return objectives;
                }
            }),
            switchMap((objectives) => {
                // prime key results of all obj, child and parent (break into so many queries as we can't use expand/nav property due to lack of security)
                return this.getKeyResultsForObjectives(objectives).pipe(
                    // also prime links so that they are not required to be primed for each objective separately
                    switchMap(() => this.primeLinksForObjectives(objectives)),
                    map(() => objectives),
                );
            }),
        );

        /**
         * taking a list of objectives (objectives) as an input it will go to each set of objective's parents till it cannot find any more parents
         * and add those parents to a list (finalObjectives) as the output
         * @param objectives The child objectives who's parents are being added to finalObjectives
         * @param finalObjectives all the objectives that are being received
         * @param onlyOnce prevent getting more than the first set of parents
         */
        async function getObjectivesRecurse(objectives: Objective[], finalObjectives: Objective[], onlyOnce?: boolean) {
            if (!objectives.some((o) => !!o.parentObjectiveId)) {
                return;
            }
            const objectiveWithParentsNotInFinalObjectives = objectives.filter((o) => !!o.parentObjectiveId)
                .filter((p) => !finalObjectives.some((id) => id.objectiveId === p.parentObjectiveId));

            const cachedParents = objectiveWithParentsNotInFinalObjectives.filter((o) => o.parentObjective !== null).map((o) => o.parentObjective);
            const results = ArrayUtilities.distinct(cachedParents);

            const parentIdsToFetch = objectiveWithParentsNotInFinalObjectives.filter((o) => o.parentObjective === null).map((o) => o.parentObjectiveId!);
            const distinctParentIds = ArrayUtilities.distinct(parentIdsToFetch);

            if (distinctParentIds.length > 0) {
                const fetchedObjectives = await lastValueFrom(self.getObjectivesByIds(distinctParentIds));
                results.push(...fetchedObjectives);
            }

            if (results.length > 0) {
                finalObjectives.push(...results);

                if (!onlyOnce) {
                    await getObjectivesRecurse(results, finalObjectives);
                }
            }
        }
    }

    public getObjectiveLinksForObjective(objective: Objective) {
        const objectiveId = objective.objectiveId;
        const key = `objectiveLinksForObjective${objectiveId}`;
        const predicate = new MethodologyPredicate<ObjectiveLink>("objective1Id", "==", objectiveId);

        return this.commonDataService.getWithOptions(ObjectiveLinkBreezeModel, key, {
            predicate,
            navProperty: "objective2", // team would have been primed when building nav sidebar - don't include that here
        });
    }

    public getObjectiveItemLinksForItem(item: Item) {
        return this.objectivesAuthService.hasAnyReadAccessToObjective$.pipe(
            switchMap((hasReadAccess) => {
                if (hasReadAccess) {
                    const key = `ObjectiveItemLinksForItem${item.itemId}`;
                    const predicate = new MethodologyPredicate<ObjectiveItemLink>("itemId", "==", item.itemId);

                    return this.commonDataService.getWithOptions(ObjectiveItemLinkBreezeModel, key, {
                        forceLocal: item.itemId < 0,
                        predicate,
                        navProperty: "objective",
                    });
                } else {
                    return of([]);
                }
            }),
        );
    }

    public getObjectiveItemLinksForItemsInTeam(teamId: number, statuses?: ItemStatus[]) {
        return this.objectivesAuthService.hasAnyReadAccessToObjective$.pipe(
            switchMap((hasReadAccess) => {
                if (hasReadAccess) {
                    const predicate = new MethodologyPredicate<ObjectiveItemLink>("item.board.teamId", "==", teamId);
                    if (statuses) {
                        predicate.and(new MethodologyPredicate<ObjectiveItemLink>("item.status", "in", statuses));
                    }

                    const key = `ObjectiveItemLinksForItemsInTeam${predicate.getKey()}`;
                    return this.commonDataService.getWithOptions(ObjectiveItemLinkBreezeModel, key, {
                        predicate,
                        navProperty: "objective",
                    });
                } else {
                    return of([]);
                }
            }),
        );
    }

    public getObjectiveItemLinksForItemsAssignedToPerson(personId: number) {
        return this.objectivesAuthService.hasAnyReadAccessToObjective$.pipe(
            switchMap((hasReadAccess) => {
                if (hasReadAccess) {
                    const predicate = new MethodologyPredicate<ObjectiveItemLink>("item.assigneeId", "==", personId);
                    predicate.and(new MethodologyPredicate<ObjectiveItemLink>("item.status", "!=", ItemStatus.Closed));

                    const key = `ObjectiveItemLinksForItemsAssignedToPerson${predicate.getKey()}`;
                    return this.commonDataService.getWithOptions(ObjectiveItemLinkBreezeModel, key, {
                        predicate,
                        navProperty: "objective",
                    });
                } else {
                    return of([]);
                }
            }),
        );
    }

    /** Get the specified objective, priming all useful navigation properties */
    public getPrimedObjective(objectiveId: number) {
        return this.getObjectiveById(objectiveId).pipe(
            filter((objective) => !!objective),
            // prime key results, parent and child objectives, and comments
            switchMap((objective) => {
                const primeQueries = [
                    this.getKeyResultsForObjectiveId(objective!.objectiveId),
                    this.getObjectivesWithParentId(objective!.objectiveId),
                    this.getObjectiveCommentsForObjectiveId(objective!.objectiveId),
                ] as Observable<any>[];

                if (objective!.parentObjectiveId) {
                    primeQueries.push(this.getObjectiveById(objective!.parentObjectiveId));
                }

                return forkJoin(primeQueries).pipe(
                    map(() => objective),
                );
            }),
            // TODO: original nav property will prime all person entities for assignee, values person, comments.person - is that needed?
            tap((objective) => {
                if (objective) {
                    this.getItemLinksForObjective(objective).subscribe();
                    this.getObjectiveLinksForObjective(objective).subscribe();
                }
            }),
        );
    }

    public createObjectiveComment(objective: Objective) {
        return this.userService.currentPerson$.pipe(
            first(),
            switchMap((currentPerson) => this.commonDataService.create(ObjectiveCommentBreezeModel, {
                objective,
                dateTime: new Date(),
                person: currentPerson,
            })),
        );
    }

    /**
     * Groups the given objectives into a tree structure, but also adding parent objectives
     * which aren't in the passed-in objective list
     */
    public groupObjectivesIncludingExternalParents(objectives: Objective[]) {
        return this.groupObjectivesInternal(objectives, (ungroupedObjectives) => {
            // Handle parents not in the original list
            const otherRoots = ungroupedObjectives.map((o) => o.parentObjective)
                .filter((p, idx, arr) => arr.indexOf(p) === idx) // Get unique values
                .filter((p) => ungroupedObjectives.indexOf(p) < 0);
            return otherRoots;
        });
    }

    /** Groups the given objectives into a tree structure but if a parent doesn't exist
     * in the original list of objectives, just place it at the top level.
     */
    public groupObjectives(objectives: Objective[]) {
        return this.groupObjectivesInternal(objectives, (ungroupedObjectives) => {
            const otherRoots = ungroupedObjectives.filter((o) => {
                return ungroupedObjectives.indexOf(o.parentObjective) < 0;
            });
            return otherRoots;
        });
    }

    private groupObjectivesInternal(objectives: Objective[], findOtherRoots: (objectives: Objective[]) => Objective[]) {
        const ungroupedObjectives = [...objectives];
        const sortFunction = this.getObjectiveSortFn<IObjectiveGroup>((g) => g.objective, null);

        const rootObjectives = ungroupedObjectives.filter((o) => !o.parentObjective);
        const groups = rootObjectives.map(generateGroup);

        // Pass a copy of the array as we modify the original in place in the method, and we don't
        // want the callback affecting the logic in here if it also modifies it in place.
        const otherRoots = findOtherRoots([...ungroupedObjectives]);
        const otherGroups = otherRoots.map(generateGroup);

        const allGroups = groups.concat(otherGroups);
        return allGroups.sort(sortFunction);

        function generateGroup(parent: Objective): IObjectiveGroup {
            ArrayUtilities.removeElementFromArray(parent, ungroupedObjectives);
            const children = ungroupedObjectives.filter((o) => o.parentObjective === parent);
            children.forEach((c) => ArrayUtilities.removeElementFromArray(c, ungroupedObjectives));

            return {
                objective: parent,
                childGroups: children.map(generateGroup).sort(sortFunction),
            };
        }
    }

    public sortGroup(currentTeamId: number | null) {
        return this.getObjectiveSortFn<IObjectiveGroup>((g) => g.objective, currentTeamId);
    }

    private getObjectiveSortFn<T>(getObjective: (o: T) => Objective, currentTeamId: number | null) {
        return SortUtilities.getSortByFieldFunction<T>(
            (o) => getObjective(o).status === ObjectiveStatus.Closed ? 1 : 0,
            (o) => getObjective(o).teamId !== currentTeamId ? 1 : 0,
            (o) => getObjective(o).dueDate.getTime() * (getObjective(o).status === ObjectiveStatus.Closed ? -1 : 1),
            (o) => getObjective(o).objectiveId,
        );
    }

    public groupObjectivesByTeam(objectives: Objective[]): IObjectiveTeamGroup[] {
        const objectivesByTeam = ArrayUtilities.groupArrayBy(objectives, (o) => o.team);
        const byTeam = objectivesByTeam.map((group) => ({
            team: group.key,
            objectives: group.items.sort(this.getObjectiveSortFn((o) => o, null)),
        }));
        return byTeam.sort((g1, g2) => {
            if (!g1.team) {
                return -1;
            } else if (!g2.team) {
                return 1;
            } else {
                return g1.team.name.localeCompare(g2.team.name);
            }
        });
    }

    public cloneAndSaveObjective(objective: Objective) {
        const getObjectiveEntities = (newObjective: Objective) => [
            newObjective,
            ...newObjective.comments,
            ...newObjective.keyResults,
            ...newObjective.labelLocations,
            ...newObjective.objectiveLinks,
            ...newObjective.itemLinks,
        ];

        let clonedObjective: Objective;

        if (!objective.entityAspect.validateEntity()) {
            const error = objective.entityAspect.getValidationErrors();
            return this.commonDialogService.showErrorDialog("Error duplicating objective", error.map((e) => e.errorMessage).join(", "));
        }
        const oldObjective$ = this.getPrimedObjective(objective.objectiveId).pipe(emptyIfUndefinedOrNull());
        const newObjective$ = oldObjective$.pipe(
            switchMap((oldObjective) => {
                const newDate = new Date();
                return this.commonDataService.create(ObjectiveBreezeModel, {
                    organisation: oldObjective.organisation,
                    team: oldObjective.team,
                    parentObjective: oldObjective.parentObjective,
                    title: `Copy of ${oldObjective.title}`,
                    description: oldObjective.description,
                    assigneePerson: oldObjective.assigneePerson,
                    creationDate: newDate,
                    modifiedDate: newDate,
                    dueDate: oldObjective.type === ObjectiveType.Annual
                        ? moment(newDate).add(1, "year").toDate()
                        : moment(newDate).add(3, "months").toDate(),
                    status: ObjectiveStatus.OnTrack,
                    type: oldObjective.type,
                } as Partial<Objective>);
            }),
            switchMap((newObjective: Objective) => {
                const titleError = newObjective.entityAspect.getValidationErrors()
                    .find((validationError) => validationError.propertyName === "title" && validationError.key === "maxLength:title") as any;

                if (titleError) {
                    newObjective.title = newObjective.title.substr(0, titleError.property.maxLength);
                    newObjective.entityAspect.validateEntity();
                }

                if (!newObjective.entityAspect.hasValidationErrors) {
                    return of(newObjective);
                } else {
                    const validationErrors = newObjective.entityAspect.getValidationErrors().map((validationError: ValidationError) => validationError.errorMessage).join(" ");
                    return this.commonDialogService.showMessageDialog("Error duplicating objective", validationErrors, "OK")
                        .pipe(
                            switchMap(() => this.commonDataService.rejectChanges(newObjective)),
                            map(() => undefined),
                        );
                }
            }),
        );

        return newObjective$.pipe(
            emptyIfUndefinedOrNull(),
            withLatestFrom(oldObjective$),
            switchMap(([newObjective, oldObjective]) => this.cloneObjectiveKeyResults(oldObjective, newObjective)),
            switchMap(([newObjective, oldObjective]) => this.cloneObjectiveLabelsLocations(oldObjective, newObjective)),
            switchMap(([newObjective, oldObjective]) => this.cloneObjectiveLinks(oldObjective, newObjective)),
            switchMap(([newObjective, oldObjective]) => this.cloneObjectiveItemLinks(oldObjective, newObjective)),
            switchMap((newObjective: Objective) => {
                clonedObjective = newObjective;
                return this.commonDataService.saveEntities(getObjectiveEntities(clonedObjective)).pipe(
                    map(() => clonedObjective),
                );
            }),
            catchError((err: AdaptError) => this.commonDialogService.showMessageDialog("Error duplicating objective", err.message, "OK").pipe(
                switchMap(() => this.commonDataService.rejectChanges(getObjectiveEntities(clonedObjective))),
                switchMap(() => EMPTY),
            )),
        );
    }

    private cloneObjectiveLinks(source: Objective, destination: Objective) {
        if (!source.objectiveLinks.length) {
            return of([destination, source]);
        }

        return forkJoin(source.objectiveLinks.map((link) => {
            if (link.objective1 === null || link.objective2 === null) {
                return of(null);
            }

            return this.createObjectiveLink({
                objective1: destination,
                objective2: link.objective2,
            });
        }),
        ).pipe(
            map(() => [destination, source]),
        );
    }

    private cloneObjectiveItemLinks(source: Objective, destination: Objective) {
        if (!source.itemLinks.length) {
            return of(destination);
        }

        return forkJoin(source.itemLinks.map((link) => {
            if (link.item === null) {
                return of(null);
            }

            return this.createObjectiveItemLink({
                objective: destination,
                item: link.item,
            });
        }),
        ).pipe(
            map(() => destination),
        );
    }

    private cloneObjectiveKeyResults(source: Objective, destination: Objective) {
        if (!source.keyResults.length) {
            return of([destination, source]);
        }

        return forkJoin(source.keyResults.map((keyResult: KeyResult) => {
            return this.createKeyResult({
                objective: destination,
                title: keyResult.title,
                targetValue: keyResult.targetValue,
                targetValuePrefix: keyResult.targetValuePrefix,
                targetValueSuffix: keyResult.targetValueSuffix,
                ordinal: keyResult.ordinal,
            });
        })).pipe(
            map(() => [destination, source]),
        );
    }

    private cloneObjectiveLabelsLocations(source: Objective, destination: Objective) {
        if (!source.labelLocations.length) {
            return of([destination, source]);
        }

        return forkJoin(source.labelLocations.map((labelLocation) => {
            return this.commonDataService.create(LabelLocationBreezeModel, {
                label: labelLocation.label,
                objective: destination,
            });
        })).pipe(
            map(() => [destination, source]),
        );
    }

    private getItemLinksForObjective(objective: Objective) {
        const objectiveId = objective.objectiveId;
        const key = `itemLinksForObjective${objectiveId}`;
        const predicate = new MethodologyPredicate<ObjectiveItemLink>("objectiveId", "==", objectiveId);

        return this.commonDataService.getWithOptions(ObjectiveItemLinkBreezeModel, key, {
            predicate,
            navProperty: "item",
        });
    }

    private primeLinksForObjectives(objectives: Objective[]) {
        const objectiveIds = objectives.map((i) => i.objectiveId);
        return this.getObjectiveLinksForObjectiveIds(objectiveIds).pipe(
            switchMap(() => this.getItemLinksForObjectiveIds(objectiveIds)),
            map(() => objectives),
        );
    }

    private getObjectiveLinksForObjectiveIds(objectiveIds: number[]) {
        if (objectiveIds.length > 0) {
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(objectiveIds, ObjQueryChunkSize);
            return forkJoin(chunksOfIds.map((ids) => {
                const predicate = new MethodologyPredicate<ObjectiveLink>("objective1Id", "in", ids);
                return this.commonDataService.getWithOptions(
                    ObjectiveLinkBreezeModel,
                    predicate.getKey(ObjectiveLinkBreezeModel.identifier),
                    {
                        predicate,
                        navProperty: "objective2", // don't need to prime team as all accessible teams are already primed when forming sidebar nav
                    });
            })).pipe(
                map((queryResults) => ArrayUtilities.mergeArrays(queryResults)),
            );
        } else {
            return of([] as ObjectiveLink[]);
        }
    }

    private getItemLinksForObjectiveIds(objectiveIds: number[]) {
        if (objectiveIds.length > 0) {
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(objectiveIds, ObjQueryChunkSize);
            return forkJoin(chunksOfIds.map((ids) => {
                const predicate = new MethodologyPredicate<ObjectiveItemLink>("objectiveId", "in", ids);
                return this.commonDataService.getWithOptions(
                    ObjectiveItemLinkBreezeModel,
                    predicate.getKey(),
                    {
                        predicate,
                        navProperty: "item",
                    });
            })).pipe();
        } else {
            return of([] as ObjectiveItemLink[]);
        }
    }
}

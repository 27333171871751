<adapt-dialog [maxWidth]="600"
              [expandable]="false"
              [showHelpButton]="false">
    <h3 adapt-dialog-title>Unsaved Changes</h3>

    <div adapt-dialog-content>
        <div *ngIf="dialogData.message"
             [froalaView]="dialogData.message"></div>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button *ngIf="dialogData.entitiesValid"
                class="btn btn-primary"
                (click)="save()">Save and continue</button>
        <button class="btn btn-danger"
                (click)="discard()">Discard and continue</button>
        <button class="btn btn-default"
                (click)="stay()">Stay here</button>
    </div>
</adapt-dialog>

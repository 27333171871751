<ng-container *adaptLoadingUntilFirstEmit="let person of person$">
    <adapt-toolbar-inactive [entity]="person"
                            type="account">
    </adapt-toolbar-inactive>

    <div adaptToolbarContent
         *ngIf="person">
        <ng-container *ngIf="canEditSomethingOnPage$ | async">
            <button adaptButton="tour"
                    data-test="tour-run"
                    (click)="runTour()"></button>
            <button *ngIf="isEditing"
                    adaptButton="view"
                    data-test="view-mode-button"
                    data-tour="view-mode-button"
                    (click)="isEditing = false"></button>
            <button *ngIf="!isEditing"
                    adaptButton="edit"
                    data-test="edit-mode-button"
                    data-tour="edit-mode-button"
                    (click)="isEditing = true"></button>
        </ng-container>
        <button *adaptIfAuthorised="ChangePasswordForPerson;Entity:person"
                adaptButtonIcon="fal fa-key"
                (click)="changePassword(person)"
                data-test="change-password-button">Change Password</button>
        <ng-container *adaptIfAuthorised="ManagePositionAndRoles;Entity:person">
            <adapt-user-role-actions [connection]="person.getLatestConnection()"></adapt-user-role-actions>
        </ng-container>
    </div>

    <adapt-two-column-page *ngIf="person">
        <div left-column>
            <adapt-dashboard-element>
                <div adapt-dashboard-title-buttons>
                    <button *ngIf="isEditing"
                            data-tour="edit-profile-name"
                            adaptButton="editBorderless"
                            (click)="editName()"></button>
                </div>
                <div adapt-dashboard-title
                     class="profile-header pt-3">
                    <adapt-person-default-image *ngIf="!isEditing && !person.imageIdentifier"
                                                class="me-3"
                                                imageSize="lg"
                                                [person]="person"></adapt-person-default-image>

                    <adapt-select-image id="logo"
                                        [ngClass]="{'d-none': !person.imageIdentifier && !isEditing}"
                                        [imageIdentifier]="person.imageIdentifier"
                                        (imageIdentifierChange)="updateImageIdentifier(person, $event)"
                                        [imageWidth]="100"
                                        [defaultImageSrc]="defaultImageSrc"
                                        [disabled]="!isEditing"
                                        [inline]="true"
                                        class="profile-image-container profile-image-container-lg me-3"></adapt-select-image>

                    <div class="profile-name">
                        <span>{{person.firstName}} {{person.lastName}}</span>
                    </div>
                </div>
            </adapt-dashboard-element>

            <adapt-person-profile-contacts [person]="person"
                                           [editMode]="isEditing">
            </adapt-person-profile-contacts>
        </div>

        <div right-column>
            <adapt-person-profile-items [person]="person"
                                        [editMode]="isEditing">
            </adapt-person-profile-items>
        </div>
    </adapt-two-column-page>
</ng-container>

<div class="d-flex flex-column card goal-card">
    <div class="card-header d-flex align-items-center goal-header"
         role="button"
         (click)="isExpanded = !isExpanded">
        <div class="d-flex align-items-center flex-fill gap-2"
             [ngClass]="{'me-2': !isEditing}">
            <i *ngIf="isDraggable"
               class="fal fa-grip-dots-vertical pe-1 gripper"
               id="goalGripper"></i>
            <i [ngClass]="GoalIconClass"
                adaptTooltip="Goal"></i>
            <h4 class="my-0">
                <span [ngClass]="{'me-2': !!goal.description}">{{goal.name}}</span>
                <adapt-display-goal-description *ngIf="goal.description"
                                                [goal]="goal"></adapt-display-goal-description>
            </h4>
            <adapt-goal-status *ngIf="!isEditing; else editingStatus"
                               [status]="goal.status"
                               class="ms-auto"></adapt-goal-status>
            <ng-template #editingStatus>
                <adapt-select-goal-status [status]="goal.status"
                                          (statusChange)="saveGoalStatus($event)"
                                          [useTransparentBackground]="true"
                                          class="ms-auto"></adapt-select-goal-status>
            </ng-template>
        </div>
        <button class="ms-start btn btn-blank"
                [adaptButtonIcon]="expandButtonIcon"></button>
    </div>

    <div [collapse]="!isExpanded"
         [isAnimated]="true"
         class="goal-card-collapse"
         [ngClass]="{'goal-editing': isEditing}">
        <div class="card-body d-flex flex-column"
             *ngIf="isExpanded || graphRendered">
            <small *ngIf="statusText"
                   class="ms-auto">{{statusText}}</small>
            <adapt-goal-measurements-graph [goal]="goal"
                                           [isEditing]="isEditing"
                                           [width]="graphWidth"
                                           (contentReady)="graphRendered = $event"></adapt-goal-measurements-graph>

            <div class="d-flex mt-3"
                 *ngIf="isEditing">
                <button adaptButton="addBorderless"
                        [adaptBlockingClick]="recordNewMeasurement"
                        adaptTooltip="Record new measurement"></button>
                <button class="ms-auto"
                        adaptButton="editBorderless"
                        [adaptBlockingClick]="editGoal"
                        adaptTooltip="Edit this goal"></button>
                <button adaptButton="deleteBorderless"
                        [adaptBlockingClick]="deleteGoal"
                        adaptTooltip="Delete this goal"></button>
            </div>
        </div>
    </div>
</div>

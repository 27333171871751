<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.IntroducingObjectivesAndKeyResults"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-button-toggle-group adaptToolbarContent
                           alignment="center"
                           [(value)]="currentView"
                           (valueChange)="onViewChanged($event)">
    <button [adaptToggleGroupButton]="ViewType.ObjectiveListView"
            adaptButtonIcon="fal fa-indent"
            data-test="list-view-button">List View</button>
    <button [adaptToggleGroupButton]="ViewType.ObjectiveTreeView"
            adaptButtonIcon="fal fa-sitemap"
            data-test="tree-view-button">Tree View</button>
</adapt-button-toggle-group>
<div adaptToolbarContent
     *ngIf="hasEditPermissions$ | async">
    <button adaptButtonIcon="add"
            [adaptBlockingClick]="addObjective"
            data-test="add-objective-button"
            data-tour="add-objective-button">Add Objective</button>
</div>
<adapt-toolbar-title>
    <h2 *ngIf="team$ | async as team; else orgTitle">
        {{team.name}} Team Objectives
    </h2>
    <ng-template #orgTitle>
        <h2>Organisation Objectives</h2>
    </ng-template>
</adapt-toolbar-title>

<adapt-filter-content [isDefault]="filterService.isDefault"
                      (reset)="filterComponent.reset()"
                      [urlParamNames]="ObjectiveFilterParamKeys">
    <adapt-objective-filter #filterComponent
                            [team]="team$ | async"
                            (changed)="filterChanged($event)"></adapt-objective-filter>
</adapt-filter-content>

<ng-container *ngIf="(teamObjectivesGroups$ | async) as teamObjectiveGroups">
    <div *ngIf="teamObjectiveGroups.length > 0; else objectivesGuidance"
         [ngSwitch]="currentView">
        <ng-container *adaptLoading="!isInitialised">
            <ng-container *ngSwitchCase="ViewType.ObjectiveListView">
                <adapt-objective-list *ngFor="let teamObjectiveGroup of teamObjectiveGroups"
                                      [currentTeamId]="teamId ? teamId : null"
                                      [objectiveGroup]="teamObjectiveGroup"
                                      (objectiveChange)="onObjectiveChanged()"></adapt-objective-list>
            </ng-container>
            <adapt-objective-tree-view *ngSwitchCase="ViewType.ObjectiveTreeView"
                                       [currentTeamId]="teamId ? teamId : null"
                                       [objectiveGroups]="teamObjectiveGroups"></adapt-objective-tree-view>
            <div *ngSwitchDefault
                 class="alert alert-danger">
                <span>Unexpected view option!</span>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-template #objectivesGuidance>
    <adapt-call-to-action *ngIf="filterService.isDefault && isInitialised; else noResultsTemplate"
                          [learnMoreUrl]="learnMoreUrl">
        <adapt-call-to-action-title>Objectives</adapt-call-to-action-title>

        <p>
            Figuring out exactly what you have to accomplish is hard for any business.
            You may have a great idea, an inspiring vision, and an impeccable gut
            feeling, but that's not enough.
        </p>
        <p>
            Objectives and Key Results are about that: getting the right tasks done.
            It's about setting and communicating the right goals and understanding
            how to achieve them.
        </p>
        <p>
            Objectives and Key Results (OKRs), on a team and organisation level make up a system that shows
            how everything one person does connects to the work of others.
        </p>

        <button *ngIf="hasEditPermissions$ | async"
                adaptCallToActionButton
                adaptButtonIcon="add"
                [adaptBlockingClick]="addObjective">
            Add an objective
        </button>
    </adapt-call-to-action>
</ng-template>
<ng-template #noResultsTemplate>
    <div *ngIf="isInitialised"
         class="row">
        <div class="alert alert-secondary col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <p>
                There are no objectives matching the current filter.
            </p>
            <p>
                Click the 'Reset' button on the filter panel to restore the default objectives filter or try a different filter.
            </p>
        </div>
    </div>
</ng-template>

import { Component, Inject, OnInit } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { ValidationError, Validator } from "breeze-client";
import { KanbanService } from "../kanban.service";

@Component({
    selector: "adapt-configure-board-details-dialog",
    templateUrl: "./configure-board-details-dialog.component.html",
})
export class ConfigureBoardDetailsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Board> implements OnInit {
    public readonly dialogName = "ConfigureBoardDetails";
    public allBoards!: Board[];

    public publicReadAccessOptions = [
        { value: false, text: "Everyone in the team" },
        { value: true, text: "Everyone in the team and anyone with read access to public boards" },
    ];
    public publicEditAccessOptions = [
        { value: false, text: "Everyone in the team" },
        { value: true, text: "Everyone in the team and anyone with edit access to public boards" },
    ];
    public addMode: boolean;

    private readonly itemPrefixError: ValidationError;
    private readonly nameError: ValidationError;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public board: Board,
        private kanbanService: KanbanService,
        entityPersistenceService: EntityPersistentService,
    ) {
        super();
        this.addMode = board.entityAspect.entityState.isAdded();
        this.autoResolveData = board;

        const boardType = entityPersistenceService.entityManager!.metadataStore.getAsEntityType("Board");
        this.itemPrefixError = new ValidationError(
            undefined as unknown as Validator,
            {
                property: boardType.getProperty("itemPrefix"),
            },
            "Kanban action prefix already in use.",
            "validItemPrefix",
        );
        this.nameError = new ValidationError(
            undefined as unknown as Validator,
            {
                property: boardType.getProperty("name"),
            },
            "Kanban board name already in use.",
            "validBoardName",
        );
    }

    public get entitiesToConfirm() {
        const entities: IBreezeEntity[] = [this.board];
        return entities;
    }

    public ngOnInit() {
        this.kanbanService.getAllBoards().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((boards) => this.allBoards = boards);
    }

    @Autobind
    public onIsPublicReadAccessChange() {
        // don't allow write if no read
        if (this.board && !this.board.isPublicReadAccess) {
            this.board.isPublicWriteAccess = false;
        }
    }

    @Autobind
    public onIsPublicWriteAccessChange() {
        // ensure read if write
        if (this.board && this.board.isPublicWriteAccess) {
            this.board.isPublicReadAccess = true;
        }
    }

    @Autobind
    public onBoardNameChange() {
        const board = this.board;
        const nameError = this.nameError;

        if (this.allBoards && this.allBoards.some(boardExists)) {
            board.entityAspect.addValidationError(nameError);
        } else {
            board.entityAspect.removeValidationError(nameError);
        }

        function boardExists(testBoard: Board) {
            if (board.boardId === testBoard.boardId) {
                return false;
            }

            return board.teamId === testBoard.teamId
                && board.personId === testBoard.personId
                && board.name.toLowerCase() === testBoard.name.toLowerCase();
        }
    }

    @Autobind
    public onItemPrefixChange() {
        const itemPrefixError = this.itemPrefixError;

        this.board.itemPrefix = this.board.itemPrefix.toUpperCase();

        this.kanbanService.getBoardByAbbreviation(this.board.itemPrefix, this.board.boardId).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((board) => {
            if (board) {
                this.board.entityAspect.addValidationError(itemPrefixError);
            } else {
                this.board.entityAspect.removeValidationError(itemPrefixError);
            }
        });
    }
}

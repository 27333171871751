<adapt-content-with-splash-image [fullPage]="fullPage"
                                 [whiteBackground]="whiteBackground"
                                 [image]="image"
                                 [imageStyles]="imageStyles"
                                 [leftColumnWidthClasses]="leftColumnWidthClasses">
    <span *ngIf="showGetStartedBadge"
          class="badge badge-get-started align-self-baseline">Get Started</span>

    <ng-content select="adapt-call-to-action-title"></ng-content>

    <div>
        <ng-container #contentContainerViewChild></ng-container>
        <ng-content></ng-content>
    </div>

    <div class="me-1 mb-1 d-inline-flex">
        <ng-content select="[adaptCallToActionButton]"></ng-content>
    </div>
    <a *ngIf="learnMoreUrl"
       class="btn btn-secondary me-1 mb-1"
       [href]="learnMoreUrl"
       target="_blank"
       rel="noopener">
        Learn more
        <i class="fal fa-external-link-square ms-1"></i>
    </a>
</adapt-content-with-splash-image>

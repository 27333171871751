import { Directive, ElementRef, Injector, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourRegistry } from "@common/lib/guided-tour/guided-tour-registrar";
import { NumberUtilities } from "@common/lib/utilities/number-utilities";
import { RouteService, SearchParamValue } from "@common/route/route.service";
import { GuardFailureType, RouteEventsService } from "@common/route/route-events.service";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { ReturnPathButtonComponent } from "@common/shell/toolbar/return-path-button/return-path-button.component";
import { fromEvent, Observable } from "rxjs";
import { filter, take, tap } from "rxjs/operators";
import { BaseComponent } from "./base.component/base.component";
import { ChangeManagerService } from "./change-manager/change-manager.service";

const ReturnPathParamKey = "ReturnPath";
const ReturnPathTextKey = "ReturnPathText";
const TargetControllerIdKey = "TargetCtrlId";

export function setReturnPathSearchParam(
    injector: Injector,
    existingSearchParam: Params,
    targetControllerId: string,
    buttonText?: string,
) {
    const routeService = injector.get(RouteService);
    existingSearchParam[ReturnPathParamKey] = routeService.currentUrl;
    existingSearchParam[TargetControllerIdKey] = targetControllerId; // need this just in case page is redirect by guard and button is added to nowhere

    if (buttonText) {
        existingSearchParam[ReturnPathTextKey] = buttonText;
    }

    return existingSearchParam;
}

export function setPageActivationSearchParam(
    existingSearchParam: Params,
    targetControllerId: string,
    pageActivationTours: string[],
) {
    existingSearchParam[TargetControllerIdKey] = targetControllerId;
    existingSearchParam[BaseRoutedComponent.PageActivationToursKey] = pageActivationTours.join(",");

    return existingSearchParam;
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseRoutedComponent extends BaseComponent {
    public static PageActivationToursKey = "PageActivationTours";
    public isActivated = false;
    protected shellUiService: ShellUiService;
    protected activatedRoute?: ActivatedRoute;
    protected routeService: RouteService;
    protected guidedTourService: GuidedTourService;

    protected routeEventsService: RouteEventsService;
    private viewContainerRef: ViewContainerRef;
    private returnPathButtonAdded = false;
    private toursQueue = false;

    protected constructor(injector: Injector, elementRef?: ElementRef) {
        super(elementRef);

        // activatedRoute is only valid if injector is injected from Component - not global
        this.activatedRoute = injector.get(ActivatedRoute);

        this.viewContainerRef = injector.get(ViewContainerRef);
        this.shellUiService = injector.get(ShellUiService);
        this.guidedTourService = injector.get(GuidedTourService);
        this.routeService = injector.get(RouteService);
        this.routeService.currentActivatedRoute = this.activatedRoute;

        this.routeEventsService = injector.get(RouteEventsService);

        const changeManager = injector.get(ChangeManagerService);
        changeManager.blockRouteOnUnsavedChanges();
    }

    protected removeDefaultShellPadding() {
        this.shellUiService.removeDefaultShellPadding();
    }

    @Autobind
    public notifyActivated(): void {
        this.routeEventsService.emitComponentActivated(this);
        this.isActivated = true;

        // this additional target controller id is to prevent creation of a component if the page is redirected away
        const targetControllerId = this.getSearchParameterValue(TargetControllerIdKey);
        if (targetControllerId && this.routeService.currentControllerId === targetControllerId) {
            const tourIdsRawString = this.routeService.getSearchParameterValue(BaseRoutedComponent.PageActivationToursKey);
            // tours first before return button
            if (tourIdsRawString && !this.toursQueue) {
                this.toursQueue = true;
                const tourIds = tourIdsRawString.split(",");
                tourIds.forEach((id) => this.guidedTourService.run(GuidedTourRegistry.get(id)));
                this.routeService.deleteSearchParameter(BaseRoutedComponent.PageActivationToursKey, true);
            }

            const returnPath = this.getSearchParameterValue(ReturnPathParamKey);
            if (returnPath && !this.returnPathButtonAdded) {
                this.returnPathButtonAdded = true;
                const addedToolbarComponent = this.viewContainerRef.createComponent(ReturnPathButtonComponent);
                addedToolbarComponent.instance.returnPath = returnPath;
                const buttonText = this.getSearchParameterValue(ReturnPathTextKey);
                if (buttonText) {
                    addedToolbarComponent.instance.buttonText = buttonText;
                }
            }
        }
    }

    protected get location() {
        return window.location;
    }

    public get pageTitle() {
        if (!this.activatedRoute) {
            throw new Error("Injector is required from component constructor to use this function!");
        }

        return this.activatedRoute.routeConfig?.data?.title;
    }

    protected listenForBrowserPrint() {
        return fromEvent(document, "keydown").pipe(
            //need to listen for metaKey for mac users.
            filter((e: KeyboardEvent) => (e.ctrlKey || e.metaKey) && e.key === "p"),
            tap((e) => e.preventDefault()),
            this.takeUntilDestroyed());
    }

    protected getRouteParamInt(paramName: any) {
        return NumberUtilities.parseNumber(this.getRouteParam(paramName));
    }

    protected getSearchParameters() {
        return this.routeService.getSearchParameters();
    }

    protected getSearchParameterValue(paramName: string) {
        return this.routeService.getSearchParameterValue(paramName);
    }

    protected getSearchParameterIntValue(paramName: string) {
        return NumberUtilities.parseNumber(this.getSearchParameterValue(paramName));
    }

    protected getSearchParameterBoolValue(paramName: string) {
        return String(this.getSearchParameterValue(paramName)).toLowerCase() === "true";
    }

    protected setSearchParameterValue(paramName: string, paramValue: SearchParamValue) {
        return this.routeService.updateSearchParameterValue(paramName, paramValue);
    }

    protected deleteSearchParameter(paramName: string) {
        return this.routeService.deleteSearchParameter(paramName);
    }

    protected getRouteParam(paramName: string) {
        if (!this.activatedRoute) {
            throw new Error("Injector is required from component constructor to use this function!");
        }

        return this.routeService.getRouteParamForSnapshot(this.activatedRoute.snapshot, paramName);
    }

    // this is not hot observable -> will complete on destroy
    protected get navigationStart() {
        // consistent with old common route service where registerLocationChangeListener is listening to $locationChangeStart
        return this.routeEventsService.navigationStart$.pipe(
            this.takeUntilDestroyed(),
        );
    }

    protected get navigationEnd() {
        return this.routeEventsService.navigationEnd$.pipe(
            this.takeUntilDestroyed(),
        );
    }

    protected get searchParameterChanged() {
        return this.routeEventsService.searchParameterChanged$.pipe(
            this.takeUntilDestroyed(),
        );
    }

    protected verifyHasAccessToRoute(verifier: Observable<boolean>) {
        verifier.pipe(
            take(1),
            this.takeUntilDestroyed(),
        ).subscribe((result) => {
            if (!result) {
                this.handleUnauthorisedAccess();
            }
        });
    }

    protected handleUnauthorisedAccess() {
        this.routeEventsService.emitGuardFailureEvent(
            GuardFailureType.AccessGuardFailed,
            this.routeService.currentActivatedRouteSnapshot!,
            this.routeService.currentRouterState,
        );
    }
}

import { Component, HostBinding, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EMPTY, Observable, of } from "rxjs";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesRouteService } from "../objectives-route.service";

@Component({
    selector: "adapt-link-objective",
    templateUrl: "./link-objective.component.html",
    styleUrls: ["./link-objective.component.scss"],
})
export class LinkObjectiveComponent extends BaseComponent implements OnChanges {
    @Input() public objective?: Objective;
    @Input() public showIcon = false;
    @Input() public iconSize = 1;
    @Input() public generateHref = true;
    @Input() public showStatus = false;
    @Input() public showStatusIcon = true; // this will be the style similar to item-link

    @Input()
    @HostBinding("class.link-objective-inline")
    public displayInline = false;

    public objectiveHref$: Observable<string> = EMPTY;
    public canReadObjective$: Observable<boolean> = of(false);


    public isOrganisationObjective = false;
    public iconClasses = {
        "badge badge-organisation-objective": false,
    };

    public get badgeStatus() {
        return ObjectiveStatusMetadata.ByStatus[this.objective?.status ?? ObjectiveStatus.OnTrack];
    }

    constructor(
        private objectivesRouteService: ObjectivesRouteService,
        private objectivesAuthService: ObjectivesAuthService,
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.objective && this.objective) {
            this.isOrganisationObjective = this.objective.isOrganisationObjective;
            // always org objective in alto, don't highlight the icon
            this.iconClasses["badge badge-organisation-objective"] = this.isOrganisationObjective && !this.isAlto;
            this.canReadObjective$ = this.objectivesAuthService.hasReadAccessToObjective(this.objective.teamId);
            if (this.generateHref && this.objective.objectiveId) {
                this.objectiveHref$ = this.objectivesRouteService.getEditObjectivePageRoute(this.objective.objectiveId, this.objective.teamId);
            }
        }
    }
}

<ng-container *adaptLoading="loadingItems">
    <dx-scroll-view direction="horizontal"
                    showScrollbar="always">
        <div class="d-flex flex-row">
            <div class="vertical-lane"
                 [ngStyle]="verticalLaneStyle"
                 *ngFor="let status of showStatuses">
                <div class="lane-title d-flex align-items-center">
                    <span class="me-1"
                          [ngClass]="ItemStatuses[status].iconClass"></span>
                    <span>{{ItemStatuses[status].name}}</span>
                    <ng-container *ngIf="showBacklogSuggestion(status)">
                        <i id="suggestionSource"
                           class="ms-2 fal fa-lightbulb-on backlog-reminder"
                           (mouseenter)="backlogSuggestionVisible = true"></i>
                        <!-- Use popover instead of tooltip here to allow more control over clickable content -->
                        <dx-popover target="#suggestionSource"
                                    position="top"
                                    showEvent="dxpointerenter"
                                    [hideEvent]="{ name: 'dxpointerleave', delay: 300 }"
                                    [width]="300"
                                    [(visible)]="backlogSuggestionVisible"
                                    (onContentReady)="onPopoverContentReady($event)"
                                    (onShowing)="popoverCancelHiding = false"
                                    (onHiding)="onPopoverHiding($event)">
                            <div *dxTemplate="let content of 'content'">
                                <p>There are currently {{itemCollection.ToDo.length}} items in this column.</p>
                                <p>Please consider moving some items into the backlog queue to be addressed later.</p>
                                <p>For more information on how a backlog can be used, please <a
                                       href="https://intercom.help/adaptbydesign/en/articles/5459774-using-a-kanban-backlog"
                                       target="_blank">read this article</a>.</p>
                                <button class="btn btn-primary"
                                        (click)="showBacklogClick.emit(true)"
                                        adaptButtonIcon="fal fa-fw item-status-badge-backlog text-white">Show Backlog</button>
                            </div>
                        </dx-popover>
                    </ng-container>
                    <button *ngIf="status === Backlog"
                            class="ms-1"
                            adaptButton="collapseLeft"
                            adaptTooltip="Hide backlog column"
                            (click)="showBacklogClick.emit(false)"></button>
                </div>
                <dx-scroll-view *ngIf="items?.length"
                                direction="vertical"
                                [ngClass]="getVerticalLaneContentClass(status)"
                                id="scrollViewStatus{{status}}"
                                showScrollbar="always">
                    <dx-sortable class="ms-2"
                                 [ngStyle]="sortableStyle"
                                 group="adaptKanban"
                                 [data]="itemCollection[status]"
                                 (onDragMove)="onDragMoved($event)"
                                 (onDragStart)="onDragStart($event)"
                                 (onDragEnd)="onDragEnd($event)"
                                 (onReorder)="onOrderChanged($event)">
                        <adapt-kanban-card *ngFor="let item of itemCollection[status]"
                                           [ngClass]="{'selected-item': item === selectedItem}"
                                           (itemSelected)="onItemSelected($event)"
                                           [item]="item"
                                           [showAssignee]="showAssignees"
                                           [highlight]="item.extensions.isOverdue"
                                           (dialogOpened)="itemDialogOpened.emit($event)"
                                           (dialogClosed)="itemDialogClosed.emit($event)"
                                           (contentRendered)="refreshRequired = true"
                                           data-test="kanban-item"></adapt-kanban-card>
                    </dx-sortable>
                </dx-scroll-view>
            </div>
        </div>
        <div class="ms-3"
             *ngIf="!items?.length">There are no items matching the filter criteria</div>
    </dx-scroll-view>
</ng-container>

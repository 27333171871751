<div *ngIf="survey"
     class="w-100 hidden-panel-container">
    <div class="d-flex">
        <h3 class="me-1 mt-1 align-self-baseline survey-title"
            [ngClass]="notStarted || forceLightBackground ? 'upcoming-survey-text' : 'ongoing-survey-text'">{{survey.name}}</h3>
        <span class="badge align-self-baseline ms-2"
              [ngClass]="badgeClass">{{statusText}}</span>
        <div *ngIf="canEdit"
             class="hidden-panel align-self-baseline ms-2">
            <ng-container *ngIf="notStarted; else ongoingActions">
                <button adaptButtonIcon="edit"
                        class="bg-transparent override-button-color p-0"
                        adaptTooltip="Edit survey details"
                        (click)="editSurvey()"
                        data-test="edit-survey-button"></button>
                <button adaptButtonIcon="delete"
                        class="bg-transparent override-button-color p-0 ms-1"
                        adaptTooltip="Delete survey"
                        (click)="promptToDeleteUpcomingSurvey()"
                        data-test="delete-survey-button"></button>
            </ng-container>
            <ng-template #ongoingActions>
                <button adaptButtonIcon="edit"
                        class="bg-transparent p-0"
                        adaptTooltip="Edit survey details"
                        (click)="editSurvey()"
                        data-test="edit-active-survey-button"></button>
                <button adaptButtonIcon="browse"
                        *ngIf="participationRate < 100"
                        class="bg-transparent p-0 ms-2"
                        adaptTooltip="Show outstanding responses"
                        (click)="viewOutstandingResponses()"></button>
            </ng-template>
        </div>
    </div>
    <div *ngIf="notStarted; else showClosingDate">
        <span [adaptTooltip]="'Starting ' + (survey.startTime | adaptDateTime)">{{survey.startTime | adaptDate}}</span> &mdash;
        <span [adaptTooltip]="'Closing ' + (survey.endTime | adaptDateTime)">{{survey.endTime | adaptDate}}</span>
    </div>
    <ng-template #showClosingDate>
        <div class="d-flex flex-column">
            <small class="me-3">Closing: {{survey.endTime | adaptDateTime}}</small>
            <adapt-progress-bar class="mt-2"
                                *ngIf="!hideParticipation"
                                [leftAligned]="true"
                                [percentageProgress]="participationRate"
                                [colour]="getStatusColorFromPercentage(participationRate)"
                                [backgroundColour]="getStatusBackgroundFromPercentage(participationRate)"
                                [overrideText]="participationText"></adapt-progress-bar>
        </div>
    </ng-template>
</div>

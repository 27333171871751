import { Component } from "@angular/core";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

export interface ICancelSubscriptionResult {
    reason: string
    freeText: string
}
@Component({
    selector: "adapt-cancel-subscription-dialog",
    templateUrl: "./cancel-subscription-dialog.component.html",
})
export class CancelSubscriptionDialogComponent extends BaseDialogComponent<ICancelSubscriptionResult>{
    public readonly dialogName = "CancelSubscriptionDialog";
    public readonly CancellationReasons = [
        "Too expensive",
        "I'm not using this anymore",
        "I'm switching to another product",
        "Other",
    ];

    public cancellationReason = "";
    public improvementText = "";

    public isEmpty = StringUtilities.isEmpty;

    public constructor() {
        super(DialogResolveData.NotRequired);
    }

    public cancelSubscription() {
        const result = {
            reason: this.cancellationReason,
            freeText: this.improvementText,
        };
        this.resolve(result);
    }
}

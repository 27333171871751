import { Component, Input, TemplateRef } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

const zoneClassMapping: { [zone in Zone]: string } = {
    [Zone.EconomicEngine]: "economic-engine",
    [Zone.FinanceAndOwnership]: "finance-and-ownership",
    [Zone.HealthyCulture]: "healthy-culture",
    [Zone.OrganisationDesign]: "org-design",
    [Zone.ResearchAndDevelopment]: "",
};

@Component({
    selector: "adapt-render-zone-map-internal",
    templateUrl: "./render-zone-map-internal.component.html",
    styleUrls: ["./render-zone-map-internal.component.scss"],
})
export class RenderZoneMapInternalComponent {
    @Input() public set verticalLayout(value: boolean) {
        this.verticalLayout$.next(value);
    }

    @Input() public zoneContentContainerTemplate?: TemplateRef<unknown>;
    @Input() public globalZoneContentContainerTemplate?: TemplateRef<unknown>;
    @Input() public showGlobalZoneLocation = false;
    @Input() public fullLayoutBreakpoint?: Breakpoint;
    @Input() public zoneMenuTemplate?: TemplateRef<unknown>;

    public Zone = Zone;
    public ZoneMetadata = ZoneMetadata;
    public zoneClassMapping = zoneClassMapping;

    public isFullLayout$: Observable<boolean>;
    private verticalLayout$ = new BehaviorSubject<boolean>(false);

    public constructor(
        responsiveService: ResponsiveService,
    ) {
        this.isFullLayout$ = combineLatest([responsiveService.currentBreakpoint$, this.verticalLayout$],
        ).pipe(
            map(([breakpoint, verticalLayoutRequested]) => {
                return !verticalLayoutRequested &&
                    this.fullLayoutBreakpoint
                    ? breakpoint.is(this.fullLayoutBreakpoint)
                    : breakpoint.isDesktopSize;
            }),
        );
    }
}

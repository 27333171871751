<div class="d-flex flex-column align-items-center my-3 overflow-hidden">
    <adapt-see-more-popup [minWidth]="500"
                          popupTitle="Bullseye"
                          buttonText="View bullseye">
        <ng-template>
            <div class="w-100 d-flex mt-3 align-items-end pb-3 mb-3 border-bottom">
                <adapt-strategic-guidance area="bullseye"></adapt-strategic-guidance>
                <button *adaptIfAuthorised="EditBullseye"
                        adaptButton="primary"
                        class="ms-4"
                        [adaptBlockingClick]="runBullseyeWorkflow">Define bullseye
                </button>
            </div>
            <adapt-bullseye-view *ngIf="bullseye?.extensions?.areQuadrantsCompleted()"
                                 [hideOuter]="true"
                                 [showZoom]="false"
                                 class="bullseye-container"></adapt-bullseye-view>
            <div class="my-4"
                 *ngIf="bullseye?.statement">
                <label>Bullseye statement</label>
                <div class="mt-2 purpose-statement lead"
                     [froalaView]="bullseye!.statement"></div>
            </div>
        </ng-template>
    </adapt-see-more-popup>
</div>

import { Injectable, Injector } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { MeetingItem } from "@common/ADAPT.Common.Model/organisation/meeting-item";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { RouteService } from "@common/route/route.service";
import { BaseService } from "@common/service/base.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { forkJoin, lastValueFrom, Observable, of, throwError } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings/meetings.service";
import { CommonTeamsService } from "../teams/common-teams.service";
import { ConfigureBoardDetailsDialogComponent } from "./configure-board-details-dialog/configure-board-details-dialog.component";
import { DeleteBoardDialogComponent, IConfirmDeleteBoardData } from "./delete-board-dialog/delete-board-dialog.component";
import { DeleteItemDialogComponent } from "./items/delete-item-dialog/delete-item-dialog.component";
import { EditItemDialogComponent } from "./items/edit-item-dialog/edit-item-dialog.component";
import { ItemUtilities } from "./items/item-utilities";
import { MoveItemDialogComponent } from "./items/move-item-dialog/move-item-dialog.component";
import { ISelectItemDialogInputData, ISelectItemDialogResolveData, SelectItemDialogComponent } from "./items/select-item-dialog/select-item-dialog.component";
import { ICreateItemOptions, KanbanService } from "./kanban.service";
import { KanbanAuthService } from "./kanban-auth.service";
import { LinkMeetingAgendaItemDialogComponent } from "./link-meeting-agenda-item-dialog/link-meeting-agenda-item-dialog.component";

@Injectable({
    providedIn: "root",
})
export class KanbanUiService extends BaseService {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        private routeService: RouteService,
        private meetingsService: MeetingsService,
        private teamsService: CommonTeamsService,
        private kanbanService: KanbanService,
        private authService: AuthorisationService, // TODO: remove this after moving items into kanban module
        injector: Injector,
    ) {
        super(injector);
    }

    public createBoardForTeam(team: Team) {
        return this.kanbanService.createBoardForTeam(team).pipe(
            switchMap((board) => this.openEditBoardDialog(board)),
        );
    }

    public createBoardForPerson(person: Person) {
        return this.kanbanService.createBoardForPerson(person).pipe(
            switchMap((board) => this.openEditBoardDialog(board)),
        );
    }

    public openEditBoardDialog(board: Board) {
        return this.dialogService.open(ConfigureBoardDetailsDialogComponent, board);
    }

    public openMoveItemDialog(item: Item) {
        return this.dialogService.open(MoveItemDialogComponent, item);
    }

    public openLinkMeetingAgendaItemDialog(item: Item) {
        return this.dialogService.open(LinkMeetingAgendaItemDialogComponent, item);
    }

    public deleteBoard(board: Board) {
        // this is previously in stewardship.service
        const otherBoards = board.isPersonalBoard
            ? board.person.boards
            : board.team.boards;

        const dialogData: IConfirmDeleteBoardData = { board };
        return this.dialogService.open(DeleteBoardDialogComponent, dialogData).pipe(
            filter((dialogResponse) => !!dialogResponse.result),
            switchMap(() => this.commonDataService.remove(board)),
            switchMap(() => {
                SortUtilities.updateIntegerSortedArrayAfterItemRemoval(otherBoards, "ordinal", board.ordinal);
                return this.commonDataService.saveEntities([board, ...otherBoards]);
            }),
            catchError(() => this.commonDataService.rejectChanges([board, ...otherBoards])),
        );
    }

    public deleteItem(item: Item) {
        return this.dialogService.open(DeleteItemDialogComponent, { item }).pipe(
            filter((dialogResponse) => !!dialogResponse.result),
            switchMap(() => this.commonDataService.remove(item)),
            switchMap(() => this.commonDataService.saveEntities([item])),
            tap(() => this.dialogService.closeAll()),
        );
    }

    public removeMeetingItem(meetingItem: MeetingItem) {
        const canRemove = this.meetingsService.canEditMeetingForTeam(meetingItem.meeting.team!);
        if (canRemove) {
            let affectedItem: Item;
            return this.dialogService.openConfirmationDialog({
                title: "Remove meeting agenda action association",
                message: `<p>This action is currently associated with a meeting:</p>
                    <ul><li>${meetingItem.meeting.name}</li></ul>
                    <p>In the meeting agenda item:</p>
                    <ul><li>${meetingItem.meetingAgendaItem.name}</li></ul>
                    <p>Are you sure you want to remove the association?</p>`,
                confirmButtonText: "Remove & Save",
                cancelButtonText: "Cancel",
            }).pipe(
                tap(() => affectedItem = meetingItem.item), // after promiseToRemove, meetingItem.item will be null -> take a reference for saving
                switchMap(() => this.commonDataService.remove(meetingItem)),
                // item entity state changed to Modified even if there is nothing changed, will have to save it as well to prevent change manager
                // from detecting it as unsaved changes
                switchMap(() => this.commonDataService.saveEntities([affectedItem, meetingItem])),
            );
        } else {
            return this.dialogService.showMessageDialog(
                "No permission to remove meeting association",
                `<p>This action is currently associated with a meeting:</p>
                    <ul><li>${meetingItem.meeting.name}</li></ul>
                    <p>In the meeting agenda item:</p>
                    <ul><li>${meetingItem.meetingAgendaItem.name}</li></ul>
                    <p>However, you do not have sufficient permission to remove it from the meeting.
                    Please ask a team member from ${meetingItem.meeting.team!.name} team to do so.</p>`,
                "OK");
        }
    }

    public openItem(item: Item, options?: ICreateItemOptions) {
        return this.dialogService.open(EditItemDialogComponent, { item, ...(options ?? {}) });
    }

    public openItemLinkDialog() {
        const teamId = this.routeService.getRouteParamInt("teamId");
        const team$: Observable<Team | undefined> = teamId
            ? this.teamsService.getTeamById(teamId)
            : of(undefined);

        return team$.pipe(
            switchMap((team) => this.dialogService.open<ISelectItemDialogInputData, ISelectItemDialogResolveData>(
                SelectItemDialogComponent,
                { team, linking: true },
            )),
        );
    }

    public openAddItemLinkDialog(item: Item, saveAfterAdd = false) {
        const excludedItemIds = [item.itemId, ...item.links.map((i) => i.primaryItemId), ...item.links.map((i) => i.secondaryItemId)];
        return this.dialogService.open<ISelectItemDialogInputData, ISelectItemDialogResolveData>(
            SelectItemDialogComponent,
            { team: item.board?.team, linking: true, excludedItemIds: [...new Set(excludedItemIds)] },
        ).pipe(
            map((result: ISelectItemDialogResolveData) => result.item),
            emptyIfUndefinedOrNull(),
            switchMap((destItem: Item) => this.kanbanService.createItemLink(item, destItem)),
            tap(() => {
                if (item.links?.length) {
                    const statusAndItemCodeComparator = ItemUtilities.getItemLinkStatusAndItemCodeSortComparator(item);
                    item.links.sort(statusAndItemCodeComparator);
                }
            }),
            switchMap((link) => {
                if (saveAfterAdd) {
                    return this.kanbanService.saveEntities(link).pipe(
                        map(() => link),
                    );
                } else {
                    return of(link);
                }
            }),
            catchError((err: AdaptError) => this.dialogService.showMessageDialog("Error saving link", err.message).pipe(
                switchMap(() => throwError(() => err)),
            )),
        );
    }

    public openItemSearchDialog(team?: Team) {
        return this.dialogService.open(SelectItemDialogComponent, { team });
    }

    public createItemOnFirstEditableBoard(boardChoices?: Board[], options?: ICreateItemOptions) {
        if (!boardChoices || !boardChoices.length) {
            return this.createAndEditItem(undefined, options);
        }

        const hasEditAccessPromises = boardChoices.map((board: Board) =>
            this.authService.promiseToGetHasAccess(KanbanAuthService.EditBoard, board));

        return forkJoin(hasEditAccessPromises).pipe(
            switchMap((hasEditAccess: boolean[]) => {
                const firstHasAccess = hasEditAccess.indexOf(true);
                if (firstHasAccess >= 0) {
                    return of(boardChoices[firstHasAccess]);
                }
                throw new Error("No editable boards found");
            }),
            switchMap((editableBoard) => this.createAndEditItem(editableBoard, options)),
        );
    }

    public createAndEditItem(board?: Board, options?: ICreateItemOptions) {
        return this.kanbanService.createItem(board, options?.itemOptions ?? {}).pipe(
            switchMap((item: Item) => this.openItem(item, options)),
        );
    }

    public createItem(boardChoices?: Board[], options?: ICreateItemOptions) {
        return this.createItemOnFirstEditableBoard(boardChoices, options).pipe(
            // check if there is a live meeting to prompt after item creation
            switchMap((item: Item) => {
                if (item.board?.isTeamBoard) {
                    return this.meetingsService.getFirstActiveMeetingForCurrentPerson().pipe(
                        map((meeting) => ({
                            currentAgendaItem: meeting?.extensions.firstInProgressItem,
                            item,
                        })),
                    );
                } else {
                    return of({
                        currentAgendaItem: undefined,
                        item,
                    });
                }
            }),
            switchMap((result) => {
                if (result.currentAgendaItem) {
                    // need to check if you have permission to write meeting item
                    if (!this.meetingsService.canEditMeetingForTeam(result.currentAgendaItem.meeting.team!)) {
                        return this.dialogService.showMessageDialog("No permission to link meeting item",
                            `You are currently in an active meeting at agenda item:</p>
                            <ul><li>${result.currentAgendaItem.name}</li></ul>
                            <p>However, you do not have sufficient permission to link your newly created action to the meeting.
                            You will have to ask a team member from ${result.currentAgendaItem.meeting.team!.name} team to associate the
                            action to the meeting if required.</p>`,
                            "OK").pipe(
                                map(() => {
                                    // set to undefined to not prompt to link item
                                    result.currentAgendaItem = undefined;
                                    return result;
                                }),
                            );
                    }
                }

                return of(result);
            }),
            switchMap((result) => {
                if (result.currentAgendaItem) {
                    return this.dialogService.openConfirmationDialogWithBoolean({
                        title: "Link action to meeting agenda item?",
                        message: `<p>You are currently in an active meeting at agenda item:</p>
                            <ul><li>${result.currentAgendaItem.name}</li></ul>
                            <p>Do you want to link the newly created action to the agenda item?</p>`,
                        confirmButtonPreset: "default",
                        confirmButtonText: "Link & Save",
                        cancelButtonText: "No",
                    }).pipe(
                        switchMap((confirm) => {
                            if (confirm) {
                                return this.meetingsService.createMeetingItemForAgendaItem(
                                    result.currentAgendaItem!, // won't get here if no currentAgendaItem
                                    result.item.itemId,
                                ).pipe(
                                    switchMap((meetingItem) => this.meetingsService.saveEntities([meetingItem])),
                                    map(() => result.item),
                                );
                            } else {
                                return of(result.item);
                            }
                        }),
                    );
                } else {
                    return of(result.item);
                }
            }),
        );
    }

    public async cloneItem(item: Item) {
        if (item.entityAspect.entityState.isAddedModifiedOrDeleted() ||
            item.comments.some((c) => c.entityAspect.entityState.isModified())) {
            // emptyError when not setting defaultValue
            return lastValueFrom(
                this.dialogService.showErrorDialog("Error duplicating action", "Please save your changes before attempting to duplicate this action."),
                { defaultValue: undefined },
            );
        }

        const confirm = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean({
            title: "Duplicate action",
            message: `<p>Are you sure you would like to duplicate this action?</p>
            <p>The action details, comments and links you are allowed to create will be copied to the new action.</p>
            <p>The edit action dialog will open for the duplicated action.</p>`,
            confirmButtonPreset: "duplicateAndEdit",
        }));

        if (confirm) {
            this.dialogService.closeAll();
            // emptyError when not setting defaultValue
            return lastValueFrom(this.kanbanService.cloneAndSaveItem(item).pipe(
                switchMap((newItem: Item) => this.openItem(newItem)),
            ), { defaultValue: undefined });
        }

        return null;
    }
}

import { Injectable } from "@angular/core";
import { Feature, FeatureModule } from "@common/ADAPT.Common.Model/embed/feature";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermission } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { featurePermissionTeamTranslations, featurePermissionTranslations } from "./feature-permission-translations";

@Injectable({
    providedIn: "root",
})
export class FeaturePermissionTranslatorService {
    private readonly FeatureModuleLiterals: { [key in FeatureModule]: string } = {
        [FeatureModule.Architecture]: "Architecture",
        [FeatureModule.Compass]: "Compass",
        [FeatureModule.CulturalNetwork]: "Cultural Network",
        [FeatureModule.People]: "People",
        [FeatureModule.Platform]: "Platform",
        [FeatureModule.Stewardship]: "Stewardship",
        [FeatureModule.Organisation]: "Organisation",
        [FeatureModule.Stakeholders]: "Stakeholders",
    };

    private readonly FeatureGroupLiterals: { [key: string]: string } = {
        ["Profile"]: "Profile",
        ["Position"]: "Profile",
        ["People"]: "Teams",
        ["Team"]: "Teams",
        ["PeerCatchUp"]: "Peer Catch Up",
        ["NetworkHealth"]: "Network Health",
        ["Health"]: "Network Health",
        ["Cohort"]: "Cultural Leadership",
        ["CulturalLeadership"]: "Cultural Leadership",
        ["CareerValuation"]: "Career Valuation",
        ["CulturalIndex"]: "Cultural Index",
        ["Kanban"]: "Kanban",
        ["Meetings"]: "Meetings",
        ["Guidance"]: "Guidance",
        ["Stewardship"]: "Organisation Stewardship",
        ["MyGuidance"]: "Personal Guidance",
        ["GuidingPhilosophy"]: "Purpose & Vision",
        ["ResilientBusinessGoals"]: "Resilient Business Goals",
        ["ValuesConstitution"]: "Values",
        ["TaskMaps"]: "Task Maps",
        ["TaskGuidance"]: "Task Guidance",
        ["RoleDescriptions"]: "Architectural Role",
        ["ValueStreams"]: "Value Streams",
        ["Tier1"]: "Tier 1 Architecture",
        ["Tier2"]: "Tier 2 Architecture",
        ["AccessManagement"]: "Access Management",
        ["Billing"]: "Billing",
        ["Api"]: "Developer API",
        ["Standard"]: "Standard",
        ["ExternalDashboard"]: "External Dashboard",
        ["EmployeeEngagement"]: "Employee Engagement Surveys",
        ["TeamAssessment"]: "Team Assessment Surveys",
        ["ResilientBusinessAssessment"]: "Resilient Business Assessment",
        ["ValueStreamContents"]: "Value Stream Contents",
        ["Bullseye"]: "Bullseye",
        ["StrategyBoard"]: "Strategy Board",
        ["StrategicInputs"]: "Strategic Inputs",
        ["StrategicGoals"]: "Strategic Goals",
        ["StrategicAnchors"]: "Strategic Anchors",
    };

    private readonly FeatureGroupOverrides: { [key: string]: string } = {
        [FeatureName.StakeholdersPeople]: "People",
        [FeatureName.OrganisationBilling]: "Configuration",
        [FeatureName.Organisation]: "Configuration",
    };

    public translateFeaturePermission(featurePermission: FeaturePermission, team?: Team) {
        if (!featurePermission.name) {
            // TODO We should make this property not null in the DB
            throw new Error(`FeaturePermission should have a name ${featurePermission.featurePermissionId}`);
        }

        if (team?.name) {
            return featurePermissionTeamTranslations[featurePermission.name];
        }

        return featurePermissionTranslations[featurePermission.name];
    }

    public translatePermissionLiteral(featurePermissionName: FeaturePermissionName, team?: Team) {
        return team?.name
            ? featurePermissionTeamTranslations[featurePermissionName]
            : featurePermissionTranslations[featurePermissionName];
    }

    public translateFeatureModule(featureModule: FeatureModule) {
        return this.FeatureModuleLiterals[featureModule];
    }

    public getFeatureGroupName(feature: Feature) {
        const featureGroupNameOverride = this.FeatureGroupOverrides[feature.name];
        if (featureGroupNameOverride) {
            return featureGroupNameOverride;
        }

        const lastDot = feature.name.lastIndexOf(".");
        if (lastDot > 0) {
            const groupKey = feature.name.substring(lastDot + 1);
            let groupName = this.FeatureGroupLiterals[groupKey];

            if (!groupName) {
                groupName = groupKey;
            }

            return groupName;
        } else {
            return "Others";
        }
    }
}

import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, RoleInOrganisationLabel } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType, ConnectionTypeLabel } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonDetailNames } from "@common/ADAPT.Common.Model/person/person-detail";
import { FeaturePermissionTranslatorService } from "@common/feature/feature-permission-translator.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DateFormats } from "@common/ux/date-formats";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import dxDataGrid, { ColumnGroupCellTemplateData, CustomSummaryInfo, InitializedEvent } from "devextreme/ui/data_grid";
import { debounceTime } from "rxjs/operators";
import { OrganisationService } from "../../organisation/organisation.service";
import { UserManagementService } from "../user-management.service";

interface IRoleHeaderItem {
    text: string;
    value: (((connection: Connection) => string) | string)[];
}

@Component({
    selector: "adapt-manage-people-grid",
    templateUrl: "./manage-people-grid.component.html",
})
export class ManagePeopleGridComponent extends BaseRoutedComponent implements OnInit {
    public static readonly AccessLevelLabelFilterSearchParam = "access";
    public static readonly RoleLabelFilterSearchParam = "role";
    public readonly ConnectionTypes = [ConnectionType.Employee, ConnectionType.Stakeholder, ConnectionType.Coach];
    public readonly ShortDate = DateFormats.globalize.short;

    public roleHeaderItems: IRoleHeaderItem[] = [];
    public accessLevelHeaderItems: IRoleHeaderItem[] = [];
    public peopleData: Connection[] = [];
    public accessHeaderItems: IRoleHeaderItem[] = [];
    public roleInOrganisationHeaderItems: IRoleHeaderItem[] = [];

    private gridInstance?: dxDataGrid;
    private statusColumnShowing = false;

    public dxGridWrapperHelper: DxGridWrapperHelper;

    public dateFormat = DateFormats.globalize.longdatetime;

    public accessLevelLabelFilter?: string;
    public roleLabelFilter?: string;

    public constructor(
        private directorySharedService: DirectorySharedService,
        private translatorService: FeaturePermissionTranslatorService,
        private commonUserManagementService: UserManagementService,
        private orgService: OrganisationService,
        private rxjsBreezeService: RxjsBreezeService,
        elementRef: ElementRef,
        injector: Injector,
    ) {
        super(injector);

        this.dxGridWrapperHelper = new DxGridWrapperHelper("adapt-manage-people-grid", jQuery(elementRef.nativeElement));
    }

    public async ngOnInit() {
        this.roleLabelFilter = this.getSearchParameterValue(ManagePeopleGridComponent.RoleLabelFilterSearchParam);
        if (this.roleLabelFilter) {
            this.deleteSearchParameter(ManagePeopleGridComponent.RoleLabelFilterSearchParam);
        }

        this.accessLevelLabelFilter = this.getSearchParameterValue(ManagePeopleGridComponent.AccessLevelLabelFilterSearchParam);
        if (this.accessLevelLabelFilter) {
            this.deleteSearchParameter(ManagePeopleGridComponent.AccessLevelLabelFilterSearchParam);
        }

        this.rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
            debounceTime(1000),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.refreshGrid());

        this.commonUserManagementService.peopleAdded$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.refreshGrid());

        if (!this.accessLevelLabelFilter && !this.roleLabelFilter) {
            this.dxGridWrapperHelper.saveGridState(String(this.orgService.getOrganisationId()));
        }

        await this.promiseToGetRoles();
        await this.promiseToGetPeopleData();
        this.dxGridWrapperHelper.callGrid((grid) => grid.setColumnsReady());

        this.notifyActivated();
    }

    public onInitialized(e: InitializedEvent) {
        this.dxGridWrapperHelper.initialiseGrid(e);
        this.gridInstance = e.component;

        // this is an undocumented option so we can't set it in the HTML
        this.gridInstance?.option("onColumnsChanging", this.onColumnsChanging);
    }

    public updateGridDimensions() {
        if (this.gridInstance) {
            // roles column changed after cancel causing the column height to be still wrong!
            this.gridInstance.updateDimensions();
        }
    }

    @Autobind
    public calcConnectionTypeCustomSummary(info: CustomSummaryInfo) {
        if (info.name === "connectionTypeCustomSummary") {
            if (info.summaryProcess === "calculate") {
                info.totalValue = ConnectionTypeLabel.ordinal(info.value.connectionType);
            }
        }
    }

    private async promiseToGetRoles() {
        const roles: Role[] = await this.directorySharedService.promiseToGetActiveRolesByPredicate();

        const accessPermissions: string[] = [];

        this.roleHeaderItems = roles.filter(DirectorySharedService.isNotTeamBasedRole)
            .filter((r) => !r.extensions.hasAccessPermissions())
            .map((r) => this.convertToHeaderItem(r, accessPermissions));
        this.roleHeaderItems.unshift({
            text: "(Blanks)",
            value: [this.calculateRolesCellValue, "=", ""],
        });

        this.accessLevelHeaderItems = roles.filter(DirectorySharedService.isNotTeamBasedRole)
            .filter((r) => r.extensions.hasAccessPermissions())
            .map((r) => ({
                text: r.label,
                // wrap value with unused characters so only exact matches work
                value: [this.calculateAccessLevelsCellValue, "contains", `<${r.label}>`],
            }));
        this.accessLevelHeaderItems.unshift({
            text: "(Blanks)",
            value: [this.calculateAccessLevelsCellValue, "=", ""],
        });

        this.accessHeaderItems = accessPermissions.map((ap) => this.convertToAccessHeaderItem(ap));
        this.accessHeaderItems.unshift({
            text: "(Blanks)",
            value: [this.calculateAccessCellValue, "=", ""],
        });
    }

    private convertToHeaderItem(role: Role, accessPermissions: string[]) {
        this.updateRolePermissions(role, accessPermissions);

        return {
            text: role.label,
            value: [this.calculateRolesCellValue, "contains", role.label],
        };
    }

    private convertToAccessHeaderItem(accessPermission: string) {
        return {
            text: accessPermission,
            value: [this.calculateAccessCellValue, "contains", accessPermission],
        };
    }

    private updateRolePermissions(role: Role, accessPermissions: string[]) {
        if (role.roleFeaturePermissions) {
            for (const roleFeaturePermission of role.roleFeaturePermissions) {
                let permissionDesc = this.translatorService.translateFeaturePermission(roleFeaturePermission.featurePermission, role.team);
                if (role.team && role.team.name) {
                    permissionDesc += ` '${role.team.name}'`;
                }

                if (!accessPermissions.includes(permissionDesc)) {
                    accessPermissions.push(permissionDesc);
                }
            }
        }
    }

    @Autobind
    private refreshGrid() {
        if (this.gridInstance) {
            this.promiseToGetPeopleData(true);
        }
    }

    public exportAllData() {
        if (this.gridInstance) {
            return DxUtilities.exportGridToExcel("People", this.gridInstance);
        }
    }

    public showColumnChooser() {
        this.gridInstance?.showColumnChooser();
    }

    public resetColumns() {
        this.accessLevelLabelFilter = undefined;
        this.roleLabelFilter = undefined;
        this.dxGridWrapperHelper.callGrid((grid) => grid.resetState());
    }

    @Autobind
    public onColumnsChanging() {
        if (this.gridInstance) {
            const previousStatusColumn = this.statusColumnShowing;
            this.statusColumnShowing = false;
            for (const column of this.gridInstance.getVisibleColumns()) {
                if (column.dataField === "status") {
                    this.statusColumnShowing = true;
                    break;
                }
            }

            if (previousStatusColumn !== this.statusColumnShowing) {
                this.promiseToGetPeopleData();
            }
        }
    }

    @Autobind
    public calculateRolesCellValue(connection: Connection) {
        return this.getNonTeamBasedRoleConnectionsForConnection(connection, false)
            .map((rc) => this.getRoleLabel(rc))
            .join(",");
    }

    private getRoleLabel(roleConnection: RoleConnection) {
        if (roleConnection && roleConnection.role) {
            return roleConnection.role.label;
        }

        return undefined;
    }

    public calculateAccessLevelCellValue(connection: Connection) {
        return UserTypeExtensions.singularLabel(connection.userType);
    }

    public calculateRoleInOrganisationCellValue(connection: Connection) {
        return RoleInOrganisationLabel[connection.roleInOrganisation];
    }

    @Autobind
    public calculateAccessLevelsCellValue(connection: Connection) {
        return this.getNonTeamBasedRoleConnectionsForConnection(connection, true)
            // wrap value with unused characters so only exact matches work
            .map((rc) => `<${this.getRoleLabel(rc)}>`)
            .join(",");
    }

    @Autobind
    public calculateAccessCellValue(connection: Connection) {
        return this.getAccessTexts(this.getNonTeamBasedRoleConnectionsForConnection(connection, true))
            .join(", ");
    }

    private getAccessTexts(roleConnections: RoleConnection[]): string[] {
        const results: string[] = [];
        for (const roleConnection of roleConnections) {
            if (roleConnection.role && roleConnection.role.roleFeaturePermissions) {
                for (const roleFeaturePermission of roleConnection.role.roleFeaturePermissions) {
                    let permissionDesc = this.translatorService.translateFeaturePermission(roleFeaturePermission.featurePermission, roleConnection.role.team);
                    if (roleConnection.role.team && roleConnection.role.team.name) {
                        permissionDesc += ` '${roleConnection.role.team.name}'`;
                    }

                    if (!results.includes(permissionDesc)) {
                        results.push(permissionDesc);
                    }
                }
            } else if (!roleConnection.role) {
                this.log.error("Invalid role entity for roleConnection: " + roleConnection.roleConnectionId + " with roleId: " + roleConnection.roleId);
            }
        }

        return results;
    }

    @Autobind
    public getNonTeamBasedRoleConnectionsForConnection(connection: Connection, asAccessLevel: boolean): RoleConnection[] {
        if (connection.roleConnections) {
            return connection.roleConnections
                .filter((roleConnection) => roleConnection.isActive())
                .filter((roleConnection) => {
                    if (!roleConnection.role) {
                        return true;
                    }

                    return asAccessLevel
                        ? roleConnection.role.extensions.hasAccessPermissions()
                        : !roleConnection.role.extensions.hasAccessPermissions();
                })
                .filter(DirectorySharedService.isNotTeamBasedRoleConnection);
        } else {
            return [];
        }
    }

    public trackByRoleConnectionId(_: number, roleConnection: RoleConnection) {
        return roleConnection.roleConnectionId;
    }

    private async promiseToGetAllLatestConnections() {
        const connections: Connection[] = [];

        const people: Person[] = await this.directorySharedService.promiseToGetAllPeople();
        for (const connectionType of this.ConnectionTypes) {
            for (const person of people) {
                const connection = person.getLatestConnection(connectionType);
                if (connection) {
                    connections.push(connection);
                }
            }
        }

        return connections;
    }

    @Autobind
    public async promiseToGetPeopleData(forceRemote?: boolean) {
        // queries that happened in the background
        this.directorySharedService.promiseToGetAllRoles(); // this is already updated by signalr - don't have to force remote
        this.directorySharedService.promiseToGetAllPersonDetails(forceRemote);
        this.directorySharedService.promiseToGetAllConnections(forceRemote);

        const connections: Connection[] = await this.promiseToGetAllLatestConnections();
        this.peopleData = connections.filter((c) => this.activeOnlyIfStatusColumnNotVisible(c));

        this.roleInOrganisationHeaderItems = ArrayUtilities.distinct(connections.map((connection) => connection.roleInOrganisation))
            .map((roleInOrganisation) => {
                const text = RoleInOrganisationLabel[roleInOrganisation];
                return ({
                    text,
                    value: [this.calculateRoleInOrganisationCellValue, "contains", text],
                });
            });
    }

    private activeOnlyIfStatusColumnNotVisible(connection: Connection) {
        return this.statusColumnShowing || connection.isActive();
    }

    public calculateActiveCellValue(connection: Connection) {
        return connection.connectionId && connection.isActive();
    }

    @Autobind
    public calculateLastAccessDate(connection: Connection) {
        return connection.person && this.getDetailDateValue(connection.person, PersonDetailNames.LastAccess);
    }

    @Autobind
    public calculateWelcomeEmailDate(connection: Connection) {
        return connection.person && this.getDetailDateValue(connection.person, PersonDetailNames.WelcomeEmailDate);
    }

    public calculateGroupValue(connection: Connection) {
        return ConnectionTypeLabel.plural(connection.connectionType);
    }

    public getConnectionTypeSummaryCount(item: ColumnGroupCellTemplateData) {
        const count = item.summaryItems.find((summary: { name: string }) => summary.name === "connectionTypeCountSummary").value;
        return count;
    }

    private getDetailDateValue(person: Person, detailName: string) {
        const value = person.getDetailValue(detailName);

        return value ? new Date(value) : undefined;
    }
}

<div *ngIf="data?.value as value"
     class="position-relative">
    <div class="value-info">
        <h2 class="m-0">{{value.name}}</h2>
    </div>
    <div class="value-info"
         *ngIf="value.meaning"
         [froalaView]="value.meaning"></div>

    <h3>Behaviours of the value</h3>
    <div *ngIf="!value.valueQuestions || value.valueQuestions.length === 0"
         class="alert alert-warning my-3">
        We recommend you provide at least one behaviour for a value. This allows value alignment to be measured more accurately.
    </div>

    <dx-sortable [data]="value.valueQuestions"
                 [allowReordering]="true"
                 (onReorder)="reorderItems($event)"
                 handle=".dx-list-reorder-handle">
        <div *ngFor="let behaviour of value.valueQuestions"
             class="py-2 d-flex align-items-start behaviour">
            <adapt-edit-behaviour class="flex-grow-1"
                                  [behaviour]="behaviour"
                                  (behaviourChange)="validateEntities()"></adapt-edit-behaviour>

            <button class="btn border btn-outline-secondary ms-1 btn-delete"
                    type="button"
                    (click)="removeBehaviour(behaviour)"
                    adaptTooltip="Delete"><i class="fal fa-fw fa-close"></i></button>

            <div class="dx-list-item-after-bag dx-list-reorder-handle-container">
                <div class="dx-list-reorder-handle"></div>
            </div>
        </div>
    </dx-sortable>

    <button #addBehaviourBtn
            class="btn border btn-outline-secondary"
            type="button"
            (click)="addBehaviour()"
            adaptTooltip="Add new behaviour"><i class="fal fa-fw fa-plus"></i></button>
</div>

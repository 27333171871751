import { Component, ViewChild } from "@angular/core";
import { Measurement, MeasurementType } from "@common/ADAPT.Common.Model/organisation/measurement";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import moment from "moment";
import { forkJoin, startWith, tap } from "rxjs";
import { EditStrategicGoalBaseComponent } from "../edit-strategic-goal-base/edit-strategic-goal-base.component";
import { GoalMeasurementsGraphComponent } from "../goal-measurements-graph/goal-measurements-graph.component";

@WorkflowStepComponent("adapt-edit-goal-measurements")
@Component({
    selector: "adapt-edit-goal-measurements",
    templateUrl: "./edit-goal-measurements.component.html",
    styleUrls: ["./edit-goal-measurements.component.scss"],
})
export class EditGoalMeasurementsComponent extends EditStrategicGoalBaseComponent {
    public readonly GuidanceArticleId = ImplementationKitArticle.StrategicGoalMeasurementsEdit;

    public targets: Measurement[] = [];
    public existingUnits: string[] = [];

    @ViewChild("measurementsGraph", { static: false }) private measurementsGraph?: GoalMeasurementsGraphComponent;

    public workflowStepOnInit() {
        super.workflowStepOnInit(); // this will extra goal from runData
        if (this.goal) {
            this.workflowStepEntityChange.pipe(
                startWith(undefined),
                this.takeUntilDestroyed(),
            ).subscribe(() => this.workflowStepCompleted.next(this.goal!.extensions.isComplete));

            this.targets = this.goal.measurements
                .filter((i) => i.type === MeasurementType.Target);
            this.orderTargets();
            if (this.targets.length < 2) {
                forkJoin(Array.from({ length: 2 - this.targets.length })
                    .map(() => this.strategicGoalsService.createMeasurementForGoal(this.goal!, MeasurementType.Target))).pipe(
                        this.takeUntilDestroyed(),
                    ).subscribe((measurements) => {
                        const lastTimestamp = measurements.length > 1
                            ? measurements[0].timestamp
                            : this.targets[this.targets.length - 1].timestamp;
                        const lastTarget = measurements[measurements.length - 1];
                        lastTarget.timestamp = moment(lastTimestamp).add(3, "years").endOf("month").startOf("day").toDate();
                        this.targets.push(...measurements);
                    });
            }

            this.strategicGoalsService.getExistingUnits().pipe(
                this.takeUntilDestroyed(),
            ).subscribe((units) => {
                this.existingUnits = units;
                if (!this.existingUnits.includes("%")) {
                    // at least have a percentage here to start with
                    this.existingUnits.unshift("%");
                }
            });
        }
    }

    // don't have to check for targets.length as there is an *ngIf in the template around anything to do with targets
    public get minTargetTimestamp() {
        return moment(this.targets[0].timestamp)
            .add(1, "day")
            .toDate();
    }

    public get maxTargetTimestamp() {
        return moment(this.targets[this.targets.length - 1].timestamp)
            .subtract(1, "day")
            .toDate();
    }

    // this is for the final target - cannot be less than the last milestone
    public get minFinalTarget() {
        return moment(this.targets[this.targets.length - 2].timestamp)
            .add(1, "day")
            .toDate();
    }

    public onUnitValueChanged(value?: string) {
        value = value?.trim();
        if (value) {
            const existingValue = this.existingUnits.find((existing) => existing.toLowerCase() === value!.toLowerCase());
            if (existingValue) {
                value = existingValue; // if case not the same, use the existing value which has already been saved
            } else {
                this.existingUnits.push(value);
            }
        }

        this.goal!.unit = value;
    }

    @Autobind
    public addTarget() {
        return this.strategicGoalsService.createMeasurementForGoal(this.goal!, MeasurementType.Target).pipe(
            tap((measurement) => {
                // workflowStepOnInit has ensured that targets will have at least 2 elements - don't have to check here anymore
                // - set the value to the last milestone value and date in between last milestone and final if adding 3 months to it exceeding the final
                const lastMilestone = this.targets[this.targets.length - 2];
                const finalTarget = this.targets[this.targets.length - 1];
                measurement.value = lastMilestone.value;
                measurement.timestamp = moment(lastMilestone.timestamp).add(3, "months").endOf("month").startOf("day").toDate();
                if (measurement.timestamp.getTime() > finalTarget.timestamp.getTime()) {
                    // past the final target -> make it in between
                    measurement.timestamp = new Date((finalTarget.timestamp.getTime() + lastMilestone.timestamp.getTime()) / 2);
                }

                this.targets.push(measurement);
                this.orderTargets();
                this.updateGraph();
                // wait for additional component to be rendered first before scrolling
                setTimeout(() => this.measurementsGraph?.scrollIntoView(), 500);
            }),
            this.takeUntilDestroyed(),
        );
    }

    public deleteTarget(target: Measurement) {
        ArrayUtilities.removeElementFromArray(target, this.targets);
        this.strategicGoalsService.remove(target).subscribe(() => this.updateGraph());
        // don't have to save here as the dialog saveAndClose will take care of it
    }

    public onTargetDateChanged(target: Measurement, timestamp: Date) {
        target.timestamp = moment(timestamp).endOf("month").startOf("day").toDate();
        this.orderTargets();
        this.updateGraph();
    }

    public updateGraph() { // call from emits from template input changes
        this.measurementsGraph?.update();
    }

    private orderTargets() {
        this.strategicGoalsService.orderMeasurementsByTimestamp(this.targets);
    }
}

<div class="d-flex flex-column align-items-center my-4">
    <adapt-strategic-guidance class="mb-4"
                              area="purpose"></adapt-strategic-guidance>

    <ng-container *ngIf="purpose?.content">
        <div class="mb-4">
            <label>Your Purpose statement</label>
            <div class="mt-2 px-3 text-muted purpose-statement"
                 [froalaView]="purpose!.content"></div>
        </div>
    </ng-container>

    <button *adaptIfAuthorised="CompassGuidingPhilosophyEdit"
            class="btn btn-primary px-4"
            [adaptBlockingClick]="recordPurposeStatement"
            role="button">{{!!purpose?.content ? "Update" : "Record"}} Purpose statement
    </button>
</div>

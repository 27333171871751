import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";
import { ToggleGroupButtonDirective } from "./toggle-group-button.directive";

/**
 * The usage of this would be similar to material button button from
 * (https://material.angular.io/components/button-toggle/overview)
 * e.g.
 * <adapt-button-toggle-group [(value)]="initialValue">
 *  <button adaptToggleGroupButton="string1"></button>
 *  <button [adaptToggleGroupButton]="123"></button>
 *  <button [adaptToggleGroupButton]="valueVariable"></button>
 * </adapt-button-toggle-group>
 */
@Component({
    selector: "adapt-button-toggle-group",
    templateUrl: "./button-toggle-group.component.html",
    styleUrls: ["./button-toggle-group.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ButtonToggleGroupComponent<T> implements AfterViewInit, OnDestroy, OnChanges {
    @Input() public value!: T;
    @Output() public valueChange = new EventEmitter<T>();

    private toggleButtonSubscriptions: Subscription[] = [];

    @ContentChildren(ToggleGroupButtonDirective) private toggleButtons!: QueryList<ToggleGroupButtonDirective<T>>;

    public ngAfterViewInit() {
        this.updateToggleButtonStates();
        this.toggleButtons.forEach((toggleButton) => {
            const subscription = toggleButton.click.subscribe((newValue) => {
                this.value = newValue;
                this.valueChange.emit(newValue);
                this.updateToggleButtonStates();
            });
            this.toggleButtonSubscriptions.push(subscription);
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            // update toggle button states if the external value changes
            this.updateToggleButtonStates();
        }
    }

    public ngOnDestroy() {
        this.toggleButtonSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private updateToggleButtonStates() {
        if (this.toggleButtons) { // guard against first onChanges before AfterViewInit where toggleButtons is undefined
            this.toggleButtons.forEach((toggleButton) => toggleButton.isActive = toggleButton.value === this.value);
        }
    }
}

import { Injectable } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { lastValueFrom } from "rxjs";
import { KanbanService } from "../kanban/kanban.service";
import { KanbanAuthService } from "../kanban/kanban-auth.service";
import { CommonTeamsService } from "./common-teams.service";

@Injectable({
    providedIn: "root",
})
export class CommonTeamsAuthService {
    public static readonly ConfigureTeam = "configureTeam";
    public static readonly ConfigureTeamKanban = "configureTeamKanban";
    public static readonly EditTeamKanban = "editTeamKanban";
    public static readonly ViewTeamKanban = "viewTeamKanban";
    public static readonly ViewTeamMeetings = "viewTeamMeetings";
    public static readonly ViewAnyTeamMeeting = "viewAnyTeamMeeting";
    public static readonly ViewTeamAccessPermissions = "viewTeamAccessPermissions";
    public static readonly AccessPrivateTeam = "accessPrivateTeam";

    public constructor(private authService: AuthorisationService) { }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ConfigureTeam,
            {
                requirePermissions: [
                    FeaturePermissionName.OrganisationTeamConfigure,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.AccessPrivateTeam,
            {
                requirePermissions: [
                    FeaturePermissionName.OrganisationTeamRead,
                ],
            },
        );

        // TODO CM-3810 this should be covered by Organisation.Team.Configure
        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ConfigureTeamKanban,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipWorkKanbanEdit,
                ],
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.EditTeamKanban,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToEditTeamKanban(
                        injector.get(KanbanService),
                        injector.get(KanbanAuthService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewTeamKanban,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToViewTeamKanban(
                        injector.get(KanbanService),
                        injector.get(KanbanAuthService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewTeamMeetings,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToViewTeamMeeting(
                        injector.get(KanbanAuthService),
                        injector.get(FeaturesService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewAnyTeamMeeting,
            {
                invokeToGetAccessVerifier: (injector) =>
                    getVerifyAccessToViewAnyTeamMeeting(
                        injector.get(CommonTeamsService),
                        injector.get(FeaturesService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewTeamAccessPermissions,
            {
                invokeToGetEntityAccessVerifier: () =>
                    () => AdaptClientConfiguration.IsDebug ? Promise.resolve() : Promise.reject(),
            },
        );

        function getVerifyAccessToViewTeamKanban(kanbanService: KanbanService, kanbanAuthService: KanbanAuthService) {
            return async (currentPerson: Person, team?: Team) => {
                if (!team) {
                    return kanbanAuthService.personCanReadBoards(currentPerson)
                        ? Promise.resolve()
                        : Promise.reject();
                } else {
                    // prime boards first before checking (getVerifyAccessToEditTeamKanban already did that)
                    // - otherwise refresh on a team work page will result false negative
                    if (!kanbanAuthService.personCanReadBoards(currentPerson)) {
                        return Promise.reject();
                    }

                    await lastValueFrom(kanbanService.getBoardsByTeam(team.teamId));
                    return kanbanAuthService.personCanReadBoardsForTeam(currentPerson, team)
                        ? Promise.resolve()
                        : Promise.reject();
                }
            };
        }

        function getVerifyAccessToViewTeamMeeting(kanbanAuthService: KanbanAuthService, featuresService: FeaturesService) {
            return async (currentPerson: Person, team?: Team) => {
                if (!team) {
                    // not allow if no team (no public read here and global org permission is covered by the KanbanAuthService.personCanRead...)
                    return Promise.reject();
                } else {
                    // needs both kanban and meetings to enable meetings
                    const teamWorkEnabled = featuresService.isFeatureActive(FeatureName.StewardshipWorkKanban, team);
                    const teamMeetingsEnabled = featuresService.isFeatureActive(FeatureName.StewardshipWorkMeetings, team);
                    return (teamWorkEnabled && teamMeetingsEnabled && kanbanAuthService.personCanReadNonPublicBoardsForTeam(currentPerson, team))
                        ? Promise.resolve()
                        : Promise.reject();
                }
            };
        }

        function getVerifyAccessToEditTeamKanban(kanbanService: KanbanService, kanbanAuthService: KanbanAuthService) {
            return async (currentPerson: Person, team?: Team) => {
                if (!team || !kanbanAuthService.personCanReadBoards(currentPerson)) {
                    return Promise.reject();
                }

                const boards = await lastValueFrom(kanbanService.getBoardsByTeam(team.teamId));
                return boards.some((b) => kanbanAuthService.personCanEditBoard(currentPerson, b))
                    ? Promise.resolve()
                    : Promise.reject();
            };
        }

        // this permission is only used when getting first live meeting and from personal dashboard to determine if meeting element should be loaded
        function getVerifyAccessToViewAnyTeamMeeting(teamsService: CommonTeamsService, featuresService: FeaturesService) {
            return async (currentPerson: Person) => {
                // allow this if person has org level meeting permission
                const personHasGlobalKanbanRead = authorisationService.personHasPermission(currentPerson, FeaturePermissionName.StewardshipWorkKanbanRead) ||
                    authorisationService.personHasPermission(currentPerson, FeaturePermissionName.StewardshipWorkKanbanEdit);
                // need to check meeting feature only - work feature already checked in permission test above
                if (personHasGlobalKanbanRead && featuresService.isFeatureActive(FeatureName.StewardshipWorkMeetings)) {
                    return Promise.resolve();
                }

                const teams = await teamsService.promiseToGetActiveTeamsForCurrentPerson() as Team[];
                if (teams?.length) {
                    for (const team of teams) {
                        const canViewTeamMeetings = await authorisationService.promiseToGetHasAccess(CommonTeamsAuthService.ViewTeamMeetings, team);
                        if (canViewTeamMeetings) {
                            return Promise.resolve();
                        }
                    }
                }

                return Promise.reject();
            };
        }
    }

    public currentPersonCanEditTeam(team: Team) {
        return this.authService.currentPersonHasPermission(FeaturePermissionName.OrganisationTeamConfigure, team) &&
            (!team.isPrivate || this.authService.currentPersonHasPermission(FeaturePermissionName.OrganisationTeamRead, team));
    }
}

import { Component, ElementRef, Injector, OnInit, ViewChild } from "@angular/core";
import { Anchor, AnchorMetadata } from "@common/ADAPT.Common.Model/organisation/anchor";
import { Bullseye } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { CollapsibleDashboardElementComponent } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { BullseyePageRoute } from "@org-common/lib/bullseye/bullseye-page/bullseye-page.component";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { organisationObjectivesPageRoute } from "@org-common/lib/objectives/objectives-page/objectives-page.route";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StrategicAnchorsService } from "@org-common/lib/strategic-anchors/strategic-anchors.service";
import { StrategicAnchorsPageRoute } from "@org-common/lib/strategic-anchors/strategic-anchors-page/strategic-anchors-page.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { StrategicViewOption } from "@org-common/lib/strategy/strategy-view-constants";
import { StrategyBoardComponent } from "@org-common/lib/strategy-board/strategy-board/strategy-board.component";
import { StrategyBoardPageRoute } from "@org-common/lib/strategy-board/strategy-board-page/strategy-board-page.component";
import { ValuesConstitutionAuthService } from "@org-common/lib/values-constitution/values-constitution-auth.service";
import { valuesConstitutionRoute } from "@org-common/lib/values-constitution/values-constitution-page/values-constitution-page.route";
import { debounceTime, forkJoin, Observable, of, Subject } from "rxjs";
import { purposeVisionPageRoute } from "../../purpose-vision/purpose-vision-page/purpose-vision-page.route";

interface IDisplayViewItem {
    icon: string;
    text: string;
    collapsed: boolean;
    element?: CollapsibleDashboardElementComponent;
    permission?: string;
    route?: Observable<string>;
}

enum DisplayViewKey {
    ObjectivesView = "ObjectivesView",
    StrategyGoalsView = "StrategyGoalsView",
    StrategicAnchorsView = "StrategicAnchorsView",
    VisionView = "VisionView",
    BullseyeView = "BullseyeView",
    PurposeView = "PurposeView",
    ValuesView = "ValuesView",
}

@Component({
    selector: "adapt-strategy-dashboard-page",
    templateUrl: "./strategy-dashboard-page.component.html",
    styleUrls: ["./strategy-dashboard-page.component.scss"],
})
export class StrategyDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly ObjectiveType = ObjectiveType;
    public readonly DisplayViewKey = DisplayViewKey;
    public readonly AllViewButtons = Object.values(DisplayViewKey) as DisplayViewKey[];
    public readonly StrategicViewOption = StrategicViewOption;

    public views: Record<DisplayViewKey, IDisplayViewItem> = {
        [DisplayViewKey.ObjectivesView]: { icon: "fal fa-tasks", text: "Objectives", collapsed: false, permission: ObjectivesAuthService.ReadObjectives, route: organisationObjectivesPageRoute.getRoute() },
        [DisplayViewKey.StrategyGoalsView]: { icon: "fal fa-chess-board", text: "Strategic Goals", collapsed: false, permission: StrategyAuthService.ReadStrategicGoals, route: StrategyBoardPageRoute.getRoute() },
        [DisplayViewKey.StrategicAnchorsView]: { icon: AnchorMetadata.iconClass, text: "Strategic Anchors", collapsed: true, permission: StrategyAuthService.ReadStrategicAnchors, route: StrategicAnchorsPageRoute.getRoute() },
        [DisplayViewKey.VisionView]: { icon: "fal fa-binoculars", text: "Vision", collapsed: true, permission: CommonPurposeVisionAuthService.ReadPurposeVision, route: purposeVisionPageRoute.getRoute() },
        [DisplayViewKey.PurposeView]: { icon: "fal fa-compass", text: "Purpose", collapsed: true, permission: CommonPurposeVisionAuthService.ReadPurposeVision, route: purposeVisionPageRoute.getRoute() },
        [DisplayViewKey.ValuesView]: { icon: "fal fa-heart", text: "Values", collapsed: true, permission: ValuesConstitutionAuthService.ReadValuesConstitution, route: valuesConstitutionRoute.getRoute() },
        [DisplayViewKey.BullseyeView]: { icon: "fal fa-bullseye", text: "Bullseye Statement", collapsed: true, route: BullseyePageRoute.getRoute(), permission: StrategyAuthService.ReadBullseye },
    };

    public hasContent = true;
    public hasPermissionMap = new Map<DisplayViewKey, boolean>();

    public purpose?: Purpose;
    public vision?: GuidingPhilosophy;
    public bullseye?: Bullseye;
    public anchors: Anchor[] = [];

    public selectedView?: DisplayViewKey;

    @ViewChild("objectivesElement")
    public set objectivesElement(value: CollapsibleDashboardElementComponent) {
        this.views[DisplayViewKey.ObjectivesView].element = value;
    }

    @ViewChild("strategyBoardElement")
    public set strategyBoardElement(value: CollapsibleDashboardElementComponent) {
        this.views[DisplayViewKey.StrategyGoalsView].element = value;
    }

    @ViewChild("strategicAnchorsElement")
    public set strategicAnchorsElement(value: CollapsibleDashboardElementComponent) {
        this.views[DisplayViewKey.StrategicAnchorsView].element = value;
    }

    @ViewChild("visionElement")
    public set visionElement(value: CollapsibleDashboardElementComponent) {
        this.views[DisplayViewKey.VisionView].element = value;
    }

    @ViewChild("bullseyeElement")
    public set bullseyeElement(value: CollapsibleDashboardElementComponent) {
        this.views[DisplayViewKey.VisionView].element = value;
    }

    @ViewChild("purposeElement")
    public set purposeElement(value: CollapsibleDashboardElementComponent) {
        this.views[DisplayViewKey.PurposeView].element = value;
    }

    @ViewChild("valuesElement")
    public set valuesElement(value: CollapsibleDashboardElementComponent) {
        this.views[DisplayViewKey.ValuesView].element = value;
    }

    public strategyBoardMenu: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [],
    }];

    @ViewChild(StrategyBoardComponent)
    public set mapComponent(component: StrategyBoardComponent | undefined) {
        if (component) {
            // after view checked - do this next digest to prevent ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => this.strategyBoardMenu[0].items = [component.expandGoalsItem, component.collapseGoalsItem]);
        }
    }

    private triggerResetSelectedView$ = new Subject<void>();

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private authorisationService: AuthorisationService,
        private commonPurposeVisionService: CommonPurposeVisionService,
        private bullseyeService: BullseyeService,
        private anchorsService: StrategicAnchorsService,
    ) {
        super(injector, elementRef);

        this.triggerResetSelectedView$.pipe(
            debounceTime(3000),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.selectedView = undefined);
    }

    public get viewNames() {
        return Object.values(this.views).map((view) => view.text);
    }

    public async ngOnInit() {
        const featurePromises = Object.entries(this.views)
            .map(async ([displayKey, view]) => {
                const promise = view.permission
                    ? this.authorisationService.promiseToGetHasAccess(view.permission)
                    : Promise.resolve(true);
                return [displayKey, await promise];
            }) as Promise<[DisplayViewKey, boolean]>[];

        const permissions = await Promise.all(featurePromises);
        this.hasPermissionMap = new Map(permissions);
        if (this.hasPermissionMap.get(DisplayViewKey.StrategyGoalsView)) {
            // goals are currently shown in a board - need board permission as well to see goals
            this.hasPermissionMap.set(DisplayViewKey.StrategyGoalsView, await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategyBoard));
        }
        this.hasContent = Array.from(this.hasPermissionMap.values()).some((hasPermission) => hasPermission);

        // getBullseye will create the bullseye entity if it doesn't exist
        // skip if no bullseye access
        const getBullseyeObservable = this.hasPermissionMap.get(DisplayViewKey.BullseyeView)
            ? this.bullseyeService.getBullseye()
            : of(undefined);

        const getAnchorsObservable = this.hasPermissionMap.get(DisplayViewKey.StrategicAnchorsView)
            ? this.anchorsService.getAllAnchors()
            : of(undefined);

        forkJoin([
            getBullseyeObservable,
            getAnchorsObservable,
            this.commonPurposeVisionService.getPurpose(),
            this.commonPurposeVisionService.getVision(),
        ]).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(([bullseye, anchors, purpose, vision]) => {
            this.bullseye = bullseye;
            this.anchors = anchors ?? [];
            this.purpose = purpose;
            this.vision = vision;
            this.notifyActivated();
        });
    }

    public expandView(viewButton: DisplayViewKey) {
        if (this.views[viewButton].collapsed) {
            this.views[viewButton].collapsed = false;
            // only wait for animation if it was previously collapsed - expanding card has a 200ms animation
            setTimeout(() => {
                this.selectedView = viewButton;
                this.views[viewButton].element?.scrollIntoView();
            }, 200);
        } else {
            this.selectedView = viewButton;
            this.views[viewButton].element?.scrollIntoView();
        }

        this.triggerResetSelectedView$.next();
    }
}

export const StrategyDashboardPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-strategy-dashboard-page", StrategyDashboardPageComponent)
    .atOrganisationUrl("/strategy-dashboard")
    .reloadOnSearch(false)
    .withTitle("Strategy on a Page")
    .build();

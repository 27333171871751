<div class="card">
    <div class="card-header">
        <h3 class="card-title">Billing Details</h3>
    </div>
    <div class="card-body"
         *adaptLoadingUntilFirstEmit="let account of account$">

        <ng-container *ngIf="contactDetailsSet">
            <label>Contact</label>
            <div>{{account!.contactName}}</div>
            <div>{{account!.contactEmail}}</div>
        </ng-container>

        <ng-container *ngIf="billingAddressSet">
            <label class="mt-3">Postal Address</label>
            <div class="address">{{account!.contactAddress}}</div>
        </ng-container>

        <p *ngIf="!contactDetailsSet && !billingAddressSet">No billing details have been set</p>

        <button adaptButton="primary"
                class="mt-2"
                [adaptBlockingClick]="showUpdateBillingDetailsDialog">Update billing details
        </button>
    </div>
</div>

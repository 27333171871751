import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { BullseyeQuadrant, BullseyeQuadrantText } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buttonPreset } from "@common/ux/button/button-preset";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { IInputQuadrantStatementsData } from "./input-quadrant-statements/input-quadrant-statements.component";

export const DefineBullseyeWorkflow = buildLocalWorkflow({
    name: "Setting up the bullseye",
    workflowId: "SettingUpTheBullseye",
    type: WorkflowType.Workflow,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    featuresToEnable: [FeatureName.Bullseye],
    showBreadcrumbs: true,
    showBreadcrumbTitles: true,
    steps: [{
        ordinal: 0,
        name: BullseyeQuadrantText.PassionateAbout,
        componentSelector: "adapt-input-quadrant-statements",
        customData: {
            guidanceArticleSlug: ImplementationKitArticle.DefineBullseyePassionateAbout,
            quadrant: BullseyeQuadrant.PassionateAbout,
        } as IInputQuadrantStatementsData,
    }, {
        ordinal: 1,
        name: BullseyeQuadrantText.GoodAt,
        allowBack: true,
        componentSelector: "adapt-input-quadrant-statements",
        customData: {
            guidanceArticleSlug: ImplementationKitArticle.DefineBullseyeGoodAt,
            quadrant: BullseyeQuadrant.GoodAt,
        } as IInputQuadrantStatementsData,
    }, {
        ordinal: 2,
        name: "Set the internal bullseye",
        allowBack: true,
        componentSelector: "adapt-set-bullseye-step",
        customData: {
            guidanceArticleSlug: ImplementationKitArticle.BullseyeSetHalfway,
            requiredQuadrants: [BullseyeQuadrant.PassionateAbout, BullseyeQuadrant.GoodAt],
        } as IInputQuadrantStatementsData,
    }, {
        ordinal: 3,
        name: BullseyeQuadrantText.CustomerNeed,
        allowBack: true,
        componentSelector: "adapt-input-quadrant-statements",
        customData: {
            guidanceArticleSlug: ImplementationKitArticle.DefineBullseyeCustomerNeed,
            quadrant: BullseyeQuadrant.CustomerNeed,
        } as IInputQuadrantStatementsData,
    }, {
        ordinal: 4,
        name: BullseyeQuadrantText.GetPaidFor,
        allowBack: true,
        componentSelector: "adapt-input-quadrant-statements",
        customData: {
            guidanceArticleSlug: ImplementationKitArticle.DefineBullseyeGetPaidFor,
            quadrant: BullseyeQuadrant.GetPaidFor,
        } as IInputQuadrantStatementsData,
    }, {
        ordinal: 5,
        name: "Set the bullseye",
        allowBack: true,
        componentSelector: "adapt-set-bullseye-step",
        customData: {
            guidanceArticleSlug: ImplementationKitArticle.BullseyeSet,
        } as IInputQuadrantStatementsData,
    }, {
        ordinal: 6,
        name: "Craft the bullseye statement",
        allowBack: true,
        componentSelector: "adapt-custom-bullseye-statement-step",
        workflowStepNextText: buttonPreset.saveAndClose.buttonText,
        workflowStepNextIcon: `${buttonPreset.saveAndClose.iconClass} fa-fw`,
    }],
});

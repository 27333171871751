import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IHelpJuiceArticle, ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { firstValueFrom, of, Subject } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { ContextSidebarSource, ContextSidebarSourceInput, ContextSidebarState } from "../context-sidebar.interface";
import { ContextSidebarService } from "../context-sidebar.service";

interface IArticle {
    name: string;
    articleId: ImplementationKitArticle;
}

@Component({
    selector: "adapt-context-sidebar-implementation-kit",
    templateUrl: "./context-sidebar-implementation-kit.component.html",
    // we don't want to accidentally see the heading or content before its projected
    styles: [":host { display: none }"],
})
export class ContextSidebarImplementationKitComponent extends BaseComponent implements OnChanges {
    @Input() public articleId?: ImplementationKitArticle | ImplementationKitArticle[];
    @Input() public context: ContextSidebarSourceInput = ContextSidebarSource.Page;
    @Input() public fullScreen?: boolean;
    @Input() public contentStyle?: "embedded";
    @Input() public title?: string;

    public articles: IArticle[] = [];
    public articleName = "";
    public articleNameUpdater = this.createThrottledUpdater<string>((articleName) => this.articleName = this.title ?? articleName);

    private loadOnEmitter = new Subject<void>();
    public loadOnEmit = this.loadOnEmitter.asObservable();

    public constructor(
        private contextSidebarService: ContextSidebarService,
        private implementationKitService: ImplementationKitService,
    ) {
        super();

        // emit load for implementation-kit-content when sidebar is open
        contextSidebarService.contextSidebarState$.pipe(
            filter((state) => state !== ContextSidebarState.Closed),
            map(() => void 0),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.loadArticles());
    }

    public get firstArticle() {
        return this.articles.length
            ? this.articles[0].articleId
            : undefined;
    }

    public loadArticles() {
        if (!this.articleId) {
            this.log.warn("Odd implementation kit articles");
            return;
        }

        const temporaryArticles = Array.isArray(this.articleId)
            ? [...this.articleId]
            : [this.articleId];

        this.articles = temporaryArticles.map((articleId) => {
            const article: IArticle = {
                name: "",
                articleId,
            };

            // fetch the article, so we can put the title of the article in our tab control
            this.implementationKitService.getArticle(articleId).pipe(
                catchError((err) => of({
                    name: "Error",
                    answer: err,
                    answer_sample: "",
                    url: "",
                } as IHelpJuiceArticle)),
            ).subscribe((data) => {
                article.name = data.name;
                this.loadOnEmitter.next();
            });

            return article;
        });
    }

    public async ngOnChanges(changes: SimpleChanges) {
        const sidebarState = await firstValueFrom(this.contextSidebarService.contextSidebarState$);
        if (changes.articleId && sidebarState !== ContextSidebarState.Closed) {
            this.loadArticles();
            this.loadOnEmitter.next();
        }
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { PricingModelExtensions } from "./pricing-model-extensions";
import { PricingModelUser } from "./pricing-model-user";

export class PricingModel extends BaseEntity<PricingModel> {
    // names duplicated in PricingModel.cs
    public static readonly PerUserPricingModelDescription = "Per User";
    public static readonly FixedPricingModelDescription = "Fixed";
    public static readonly NotApplicablePricingModelDescription = "N/A";

    public pricingModelId!: number;
    public name!: string;
    public isDefault!: boolean;
    public monthlyFeeDollars!: number;

    public pricingModelUsers!: PricingModelUser[];

    @EntityExtensions(PricingModelExtensions)
    public extensions!: PricingModelExtensions;
}

export const PricingModelBreezeModel = new BreezeModelBuilder("PricingModel", PricingModel)
    .hasSource()
    .build();

import { Component } from "@angular/core";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";

@MeetingAgendaItemComponent("adapt-attach-bullseye-statements-agenda-item")
@Component({
    selector: "adapt-attach-bullseye-statements-agenda-item",
    templateUrl: "./attach-bullseye-statements-agenda-item.component.html",
})
export class AttachBullseyeStatementsAgendaItemComponent {
    public pageActivationTours = [TourIdentifier.AttachBullseyeStatementsTour];
    public readonly EditBullseye = StrategyAuthService.EditBullseye;
}

import { ComponentFactoryResolver, Inject, Injectable, Injector, Optional, Type, ViewContainerRef } from "@angular/core";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { finalizeWithLastValue } from "@common/lib/utilities/rxjs-utilities";
import { defer, EMPTY, of, throwError } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { ADAPT_DIALOG_DATA } from "./adapt-common-dialog.globals";
import { BaseDialogComponent } from "./base-dialog.component/base-dialog.component";
import { ICommonDialogService } from "./common-dialog-service.interface";
import { ConfirmationDialogComponent, IConfirmationDialogData } from "./confirmation-dialog.component/confirmation-dialog.component";
import { DIALOG_EVENT_HANDLERS, IDialogEventHandler } from "./dialog-event-handler.interface";
import { DialogRootComponent } from "./dialog-root.component/dialog-root.component";
import { FullscreenDialogComponent, IFullscreenDialogConfig } from "./fullscreen-dialog/fullscreen-dialog.component";
import { HandleFailedSaveDialogComponent } from "./handle-failed-save-dialog/handle-failed-save-dialog.component";

@Injectable({
    providedIn: "root",
})
export class AdaptCommonDialogService implements ICommonDialogService {
    private dialogViewContainer?: ViewContainerRef; // use this to insert(component host view)
    private isInitialised = false;

    public constructor(
        private injector: Injector,
        private factoryResolver: ComponentFactoryResolver,
        @Optional() @Inject(DIALOG_EVENT_HANDLERS) private readonly dialogEventHandlers: IDialogEventHandler[],
    ) {
        // Can't initialise inline as @Optional uses null instead of undefined
        // See https://github.com/angular/angular/issues/25395
        if (!this.dialogEventHandlers) {
            this.dialogEventHandlers = [];
        }
    }

    public setViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.dialogViewContainer = viewContainerRef;
    }

    // initialise will be called from the module constructor which will be once off
    public initialise() {
        if (!this.isInitialised) {
            this.isInitialised = true;
            DialogRootComponent.ViewContainerInitialised.subscribe((dialogRootView: ViewContainerRef) => this.setViewContainerRef(dialogRootView));
        }
    }

    public closeAll() {
        if (this.dialogViewContainer) {
            this.dialogViewContainer.clear();
        }
    }

    private createDialogComponent<TComponent, TData>(componentType: Type<TComponent>, data?: TData) {
        const injector = Injector.create({
            providers: [
                {
                    provide: ADAPT_DIALOG_DATA,
                    useValue: data,
                },
            ],
            parent: this.injector,
        });
        const componentFactory = this.factoryResolver.resolveComponentFactory(componentType);
        return componentFactory.create(injector);
    }

    public open<TInputData, TResolveData>(componentType: Type<BaseDialogComponent<TInputData, TResolveData>>, data?: TInputData) {
        return defer(() => {
            if (this.dialogViewContainer) {
                const component = this.createDialogComponent(componentType, data);

                for (const handler of this.dialogEventHandlers) {
                    handler.dialogOpened(component.instance, data);
                }

                const modalClassName = "dx-modal-open"; // if this changes, then also change adapt-org-common-print.scss
                let modalClassExisted = true;

                let dialogWasResolved = false;
                const result = component.instance.events.pipe(
                    tap(() => dialogWasResolved = true),
                    finalizeWithLastValue((resolveData) => setTimeout(() => {
                        if (dialogWasResolved) {
                            for (const handler of this.dialogEventHandlers) {
                                handler.dialogResolved(component.instance, resolveData);
                            }
                        } else {
                            for (const handler of this.dialogEventHandlers) {
                                handler.dialogCancelled(component.instance);
                            }
                        }

                        // need this in setTimeout to avoid digest on destroyed component
                        // error as this callback is from within the component to be destroyed.
                        component.destroy();

                        if (!modalClassExisted) {
                            document.body.classList.remove(modalClassName);
                        }
                    })),
                );
                this.dialogViewContainer.insert(component.hostView);
                component.instance.setVisible(true);

                if (!document.body.classList.contains(modalClassName)) {
                    document.body.classList.add(modalClassName);
                    modalClassExisted = false;
                }

                return result;
            } else {
                return throwError(() => new AdaptError("Dialog view container is not initialised. " +
                    "Have you forgotten to add <adapt-dialog-root>?"));
            }
        });
    }

    public handleSaveFailure(entities: IBreezeEntity[]) {
        return this.open(HandleFailedSaveDialogComponent, entities);
    }

    public openConfirmDiscardDialog() {
        const confirmDiscardData: IConfirmationDialogData = {
            title: "Discarding Changes",
            message: "<p>You are about to discard unsaved changes. If you choose to discard, they can no longer be recovered.</p>" +
                "<p>Are you sure you want to continue?</p>",
            confirmButtonText: "Discard",
            cancelButtonText: "Stay Here",
        };

        return this.openConfirmationDialogWithBoolean(confirmDiscardData);
    }

    public showMessageDialog(title: string, message: string, confirmText = "Close") {
        const messageDialogData: IConfirmationDialogData = {
            title,
            message,
            confirmButtonText: confirmText,
            hideCancelButton: true,
        };
        return this.openConfirmationDialogWithBoolean(messageDialogData);
    }

    /** Opens a message dialog that completes without emitting. Used to end an observable chain upon error. */
    public showErrorDialog(title: string, message: string, confirmText = "OK") {
        return this.showMessageDialog(title, message, confirmText).pipe(
            switchMap(() => EMPTY),
        );
    }

    /** Opens a confirm dialog, completing without emitting if cancel is chosen */
    public openConfirmationDialog(dialogData: IConfirmationDialogData) {
        return this.openConfirmationDialogWithBoolean(dialogData).pipe(
            switchMap((dialogResult) => dialogResult ? of(void 0) : EMPTY),
        );
    }

    /** Opens a confirm dialog, emitting with true or false based on whether it is confirmed or cancelled respectively */
    public openConfirmationDialogWithBoolean(dialogData: IConfirmationDialogData) {
        return this.open(ConfirmationDialogComponent, dialogData)
            .pipe( // TODO: just want a boolean result - should have changed the base dialog to allow this
                map((dialogResponse) => dialogResponse!.result),
            );
    }

    /** Opens a fullscreen dialog, does not emit anything (self-controlled) */
    public openFullscreenDialog(dialogData: IFullscreenDialogConfig) {
        if (this.dialogViewContainer) {
            // FullscreenDialog does not implement BaseDialogComponent
            // so manually create the component here instead of with open()
            const component = this.createDialogComponent(FullscreenDialogComponent, dialogData);

            component.instance.close.subscribe(() => {
                component.destroy();
            });

            this.dialogViewContainer.insert(component.hostView);
            component.instance.openPopup();
        }
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { DxListModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptAuthorisationModule } from "../authorisation/authorisation.module";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { EditAnchorDialogComponent } from "./edit-anchor-dialog/edit-anchor-dialog.component";
import { ReorderAnchorsDialogComponent } from "./reorder-anchors-dialog/reorder-anchors-dialog.component";
import { StrategicAnchorComponent } from "./strategic-anchor/strategic-anchor.component";
import { StrategicAnchorsComponent } from "./strategic-anchors/strategic-anchors.component";
import { StrategicAnchorsPageComponent, StrategicAnchorsPageRoute } from "./strategic-anchors-page/strategic-anchors-page.component";


@NgModule({
    declarations: [
        StrategicAnchorsPageComponent,
        EditAnchorDialogComponent,
        StrategicAnchorComponent,
        StrategicAnchorsComponent,
        ReorderAnchorsDialogComponent,
    ],
    imports: [
        CommonModule,

        AdaptLoadingSpinnerModule,
        AdaptCallToActionModule,
        AdaptButtonModule,
        AdaptToolbarModule,
        AdaptCommonDialogModule,
        AdaptImplementationKitModule,
        AdaptHtmlEditorModule,
        AdaptEntityValidatorModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptDateModule,

        DxTextBoxModule,
        DxListModule,
        AdaptAuthorisationModule,
    ],
    exports: [
        StrategicAnchorsComponent,
    ],
})
export class AdaptStrategicAnchorsModule {
    public static readonly Routes = [
        ...StrategicAnchorsPageRoute.routes,
    ];
}


export class NumberUtilities {
    /**
     * Generate a pseudo random number between zero and one based on the supplied value
     * This will generate the same result every time from the same value.
     * https://stackoverflow.com/questions/33716998/why-does-seed-9301-49297-233280-233280-0-generate-a-random-number
     *
     * @param value - value to seed from
     */
    public static generatePRNG(value: number): number {
        value = (value * 9301 + 49297) % 233280;
        return value / (233280.0);
    }

    public static parseNumber(strValue: string | null | undefined) {
        if (strValue === null || strValue === undefined) {
            return undefined;
        } else {
            return Number(strValue);
        }
    }
}

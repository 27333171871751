import { Component, ElementRef, Injector } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { CompetitorAnalysisPageRoute } from "@org-common/lib/strategic-inputs/competitor-analysis-page/competitor-analysis-page.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";

@MeetingAgendaItemComponent("adapt-gather-inputs-competitors-analysis-agenda-item")
@Component({
    selector: "adapt-gather-inputs-competitors-analysis-agenda-item",
    templateUrl: "./gather-inputs-competitors-analysis-agenda-item.component.html",
})
export class GatherInputsCompetitorsAnalysisAgendaItemComponent extends BaseComponent {
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;

    public constructor(
        elementRef: ElementRef,
        private injector: Injector,
    ) {
        super(elementRef);
    }

    @Autobind
    public recordCompetitorsAnalysis() {
        return CompetitorAnalysisPageRoute.gotoRoute(
            undefined,
            setReturnPathSearchParam(this.injector, {}, CompetitorAnalysisPageRoute.id, "Return to meeting"));
    }
}

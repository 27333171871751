<div *ngIf="data?.value as value">
    <div>
        <h3 class="d-flex">Value name
            <ng-container [ngTemplateOutlet]="editButton"
                          [ngTemplateOutletContext]="{step: 0}"></ng-container>
        </h3>
        <div class="field-value">{{value.name}}</div>
    </div>

    <div class="mt-3">
        <h3 class="d-flex">Value meaning
            <ng-container [ngTemplateOutlet]="editButton"
                          [ngTemplateOutletContext]="{step: 1}"></ng-container>
        </h3>
        <div [froalaView]="value.meaning"
             class="field-value"></div>
    </div>

    <div class="mt-3">
        <h3 class="d-flex">Value behaviours
            <ng-container [ngTemplateOutlet]="editButton"
                          [ngTemplateOutletContext]="{step: 2}"></ng-container>
        </h3>
        <div *ngIf="!value.valueQuestions || value.valueQuestions.length === 0"
             class="alert alert-warning my-3">
            We recommend you provide at least one behaviour for a value. This allows value alignment to be measured more accurately.
        </div>
        <div class="field-value d-flex flex-column gap-2">
            <div *ngFor="let behaviour of value.valueQuestions">
                <adapt-edit-behaviour [behaviour]="behaviour"
                                      [readonly]="true"></adapt-edit-behaviour>
            </div>
        </div>
    </div>
</div>

<ng-template #editButton
             let-step="step">
    <button adaptTooltip="Edit"
            (click)="navigateToStep(step)"
            role="button"
            class="btn btn-blank ms-auto"><i class="fal fa-fw fa-edit"></i></button>
</ng-template>

<div class="person-link-directive"
     (mouseenter)="popoverInitialised = true"
     #dashboardLink>
    <ng-container *ngIf="profileUrl$ | async as profileUrl; else showTextOnlyTemplate">
        <a [routerLink]="profileUrl"
           [ngClass]="{ 'person-link-nowrap': preventWrap }">
            <ng-container *ngTemplateOutlet="linkContentsTemplate"></ng-container>
        </a>
        <!-- Popover only shown if you have permission to see public profile data, i.e. profileUrl is defined -->
        <dx-popover *ngIf="(person$ | async) && popoverInitialised"
                    [target]="dashboardLink"
                    showEvent="dxpointerenter"
                    [hideEvent]="{ name: 'dxpointerleave', delay: 300 }"
                    [(visible)]="popoverVisible"
                    [minWidth]="300"
                    [position]="popoverPosition"
                    (onContentReady)="onPopoverContentReady($event)"
                    (onShowing)="popoverCancelHiding = false"
                    (onHiding)="onPopoverHiding($event)">
            <adapt-link-person-contents [person]="person$ | async"></adapt-link-person-contents>
        </dx-popover>
    </ng-container>

    <ng-template #showTextOnlyTemplate>
        <div [ngClass]="{ 'person-nowrap': preventWrap }">
            <ng-container *ngTemplateOutlet="linkContentsTemplate"></ng-container>
        </div>
    </ng-template>

    <ng-template #linkContentsTemplate>
        <adapt-person-image class="person-link-person-image"
                            *ngIf="showImage"
                            [person]="person$ | async"
                            [imageSize]="imageSize"
                            containerClass="person-link-image lh-base"></adapt-person-image>

        <div class="person-link-text"
             *ngIf="showText">
            <p *ngIf="(person$ | async) as person; else noPersonTemplate">
                {{person.fullName}}
                <small *ngIf="personIsInactive$ | async">(inactive)</small>
            </p>
            <ng-template #noPersonTemplate>
                {{nullValueText}}
            </ng-template>
        </div>
    </ng-template>
</div>

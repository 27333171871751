import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { registerGuidedTour } from "@common/lib/guided-tour/guided-tour-registrar";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { DxTextBoxModule } from "devextreme-angular";
import { ReturnPathButtonComponent } from "./return-path-button/return-path-button.component";
import { ReturnPathTour, ReturnPathTourIdentifier } from "./return-path-button/return-path-tour";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { ToolbarContentComponent } from "./toolbar-content/toolbar-content.component";
import { ToolbarContentDirective } from "./toolbar-content/toolbar-content.directive";
import { ToolbarTitleComponent } from "./toolbar-title/toolbar-title.component";

@NgModule({
    imports: [
        CommonModule,
        DxTextBoxModule,
        AdaptButtonModule,
    ],
    exports: [
        ToolbarTitleComponent,
        ToolbarContentComponent,
        ToolbarContentDirective,
        ToolbarComponent,
        ReturnPathButtonComponent,
    ],
    declarations: [
        ToolbarTitleComponent,
        ToolbarContentDirective,
        ToolbarContentComponent,
        ToolbarComponent,
        ReturnPathButtonComponent,
    ],
})
export class AdaptToolbarModule {
    public constructor() {
        // even if this may not be needed in debug, return-path-tour will be shaken off in release build.
        // - register this will ensure it will stay
        registerGuidedTour(ReturnPathTourIdentifier, ReturnPathTour);
    }
}


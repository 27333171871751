import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";

@Component({
    selector: "adapt-team-private-icon",
    templateUrl: "./team-private-icon.component.html",
    styles: `:host { display: contents; }`,
})
export class TeamPrivateIconComponent {
    public static readonly IconClass = "fal fa-lock-alt"; // this will be reused by nav node builder

    public readonly iconClass = TeamPrivateIconComponent.IconClass; // used by template
    @Input() public team?: Team;
    @Input() public showTooltip = true;
}

import { DefaultOKButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export const ReturnPathTourIdentifier = "ReturnPathButtonTour";

export const ReturnPathTour: GuidedTour = [{
    title: "How to navigate back?",
    text: "Once you've finished your tasks on this page, click here to return to your previous location.",
    waitForAndAttachToElementSelector: `[data-tour="return-to-path-button"]`,
    attachTo: {
        on: "bottom-start",
    },
    modalOverlayOpeningPadding: 3,
    canClickTarget: false, // don't allow the button to be clicked or this step will have nothing to attach to
    buttons: DefaultOKButtons,
    showOn: () => {
        // Just in case people click away before tour processed
        const cancelButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="return-to-path-button"]`);
        return GuidedTourUtils.isElementVisible(cancelButton);
    },
}];

import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { teamKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { Observable } from "rxjs";
import { debounceTime, filter, map, switchMap, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";

@Component({
    selector: "adapt-display-meeting-summary",
    templateUrl: "./display-meeting-summary.component.html",
    styleUrls: ["./display-meeting-summary.component.scss"],
})
export class DisplayMeetingSummaryComponent extends BaseComponent implements OnChanges {
    @Input() public meeting!: Meeting;
    @Input() public compact = false;
    @Input() public linkDisabled = false;
    @Input() public preWorkClickable = true;
    @Input() public preWorkVisible = true;
    @Input() public showIcon = false;
    @Input() public showInfoIcon = true;
    @Input() public showStartButton = true;
    @Input() public showStatus = true;
    @Input() public showTeamName = false;
    @Output() public meetingStarted = new EventEmitter<Meeting>();
    @Output() public canStartChanged = new EventEmitter<boolean>();

    @Input() public selected = false;
    @Output() public elementSelected = new EventEmitter<HTMLElement>();

    public meetingUrl$?: Observable<IAdaptLinkObject>;
    public nonStartableMeetingInfo = "";
    public initialised = false;

    // to get around ExpressionChangedAfterItHasBeenCheckedError
    public isInProgress = false;

    constructor(
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        rxjsBreezeService: RxjsBreezeService,
        elementRef: ElementRef,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChanged(MeetingAgendaItem).pipe(
            filter((agendaItem) => agendaItem.meetingId === this.meeting.meetingId),
            debounceTime(10),
            switchMap(() => this.meetingsService.getNonStartableMeetingInfo(this.meeting)),
            this.takeUntilDestroyed(),
        ).subscribe((meetingInfo) => {
            this.nonStartableMeetingInfo = meetingInfo;
            this.canStartChanged.emit(this.canStart);
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.meeting && this.meeting) {
            // meeting changed - need to prime agenda items or meeting status will be incorrect
            if (this.meeting.extensions.isNotStarted) {
                // only need to do this for upcoming meetings - completed or in progress meeting will have no action on this display
                this.meetingsService.getAgendaItemsForMeeting(this.meeting, false).pipe(
                    // prime meeting attendees too so that only person in the meeting can start the meeting
                    // if the meeting is upcoming ONLY
                    switchMap(() => this.meetingsService.getMeetingAttendeesForMeeting(this.meeting)),
                    switchMap(() => this.meetingsService.getNonStartableMeetingInfo(this.meeting)),
                    this.takeUntilDestroyed(),
                ).subscribe((meetingInfo) => {
                    this.nonStartableMeetingInfo = meetingInfo;
                    this.canStartChanged.emit(this.canStart);
                    this.initialised = true;
                });
            } else {
                this.initialised = true;
            }

            this.isInProgress = this.meeting.extensions.isInProgress;

            this.meetingUrl$ = this.meetingsService.getTeamMeetingsPageObj(this.meeting.teamId, this.meeting.meetingId);
        }

        if (changes.selected && this.selected && this.elementRef) {
            this.elementSelected.emit(this.elementRef.nativeElement);
        }
    }

    public get canStart() {
        return this.meeting.extensions.isNotStarted && !this.nonStartableMeetingInfo;
    }

    public get iconClass() {
        return this.meeting.extensions.isEnded
            ? "fa-calendar-alt"
            : "fa-clipboard-list";
    }

    @Autobind
    public startMeeting() {
        return this.meetingsService.saveEntities(this.meeting.extensions.startMeeting()).pipe(
            tap(() => this.meetingStarted.next(this.meeting)),
            switchMap(() => this.meetingsUiService.isUsingActiveMeetingPage),
            map((isUsingActiveMeetingPage) => {
                if (isUsingActiveMeetingPage) {
                    this.meetingsService.gotoActiveMeetingPage(this.meeting, undefined, true);
                } else {
                    teamKanbanPageRoute.gotoRoute({ teamId: this.meeting.teamId });
                }
            }),
        );
    }

    @Autobind
    public gotoMeetingPage() {
        return this.meetingsService.gotoTeamMeetingsPage(this.meeting.teamId, this.meeting.meetingId);
    }

    public showPreWork() {
        this.meetingsUiService.showMeetingPreWork(this.meeting).subscribe();
    }
}

<div *adaptLoading="!input"
     [ngClass]="{'competitor-analysis': isCompetitorAnalysis}">
    <adapt-collapsible-dashboard-element [name]="input!.name"
                                         [isCollapsible]="isCollapsible"
                                         [collapsed]="isCollapsible"
                                         [isExpandable]="!!input!.description"
                                         [iconClass]="iconClass"
                                         [handleId]="handleId"
                                         [iconTooltip]="iconTooltip">
        <div *adaptIfAuthorised="EditStrategicInputs"
             adapt-collapsible-header-action-right
             class="d-flex gap-1">
            <button *ngIf="isEditing"
                    adaptButton="editBorderless"
                    adaptTooltip="Edit input"
                    [adaptBlockingClick]="editInput"
                    [stopPropagation]="true"></button>
            <button *ngIf="isDetachable"
                    adaptButton="deleteBorderless"
                    adaptTooltip="Detach from Strategy Board"
                    (click)="onDeleted($event)"></button>
        </div>
        <div [froalaView]="input!.description"></div>
        <div *ngIf="showCanvasNameInContent && input!.canvas"
             class="d-flex flex-column">
            <span class="canvas-name mt-3">Canvas: {{canvasName}}</span>
            <span class="canvas-name"
                  *ngIf="input?.inputGroup">{{input!.inputGroup!.type}}: {{input!.inputGroup!.name}}</span>
        </div>
    </adapt-collapsible-dashboard-element>
</div>

// staging environment shared by all apps
// each app specifies its own environment.staging.ts that is based off this file

import { RawClientConfiguration } from "./adapt-client-configuration";
import { clientConfiguration as baseClientConfiguration } from "./environment.base";

export const clientConfiguration: Partial<RawClientConfiguration> = Object.assign({}, baseClientConfiguration, {
    AdaptEnvironmentName: "Staging",
    AltoApiBaseUri: "https://staging-hq-api.theadaptway.com",
    AltoAzureTablesUrl: "https://altostagingstorage.table.core.windows.net",
    AltoBaseUri: "https://staging-hq.theadaptway.com",
    AnalyticsProductId: "a322ae76626a9a9b6b433e63c95ccdff3ac96140",
    ApiBaseUri: "https://staging-api.adaptbydesign.com.au",
    EmbedApiBaseUri: "https://staging-api.adaptbydesign.com.au",
    EmbedAzureTablesUrl: "https://adaptstagingstorage.table.core.windows.net",
    EmbedBaseUri: "https://staging-embed.adaptbydesign.com.au",
    GoogleClientId: "799098505879-ibljfqb9tlghbq5ni10n2b6qlsp4mjvl.apps.googleusercontent.com",
    MicrosoftClientId: "4b24b073-832f-44ee-b189-38ab1fad4dd5",
    OneDriveClientId: "4aab023d-d1fa-4078-9190-f3a60ea2ff5b",
    ServiceApiBaseUri: "https://staging-service.adaptbydesign.com.au",
} as Partial<RawClientConfiguration>);

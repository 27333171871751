<ng-container *ngIf="objective">
    <div class="d-flex align-items-center gap-1">
        <span *ngIf="showIcon"
              class="objective-icon"
              [ngClass]="iconClasses"
              adaptTooltip="This is an organisation objective"
              [tooltipEnabled]="isOrganisationObjective">
            <i class="fa-fw"
               [ngClass]="objective!.iconClass"
               [ngStyle]="{ 'font-size.em': iconSize }"></i>
        </span>

        <span *ngIf="showStatusIcon"
              class="align-self-start"
              [ngClass]="'status-icon-' + objective!.status.toLowerCase()"
              [adaptTooltip]="badgeStatus.name + (isOrganisationObjective ? ' Organisation ' : ' Team ') + 'Objective'">
        </span>
        <strong class="objective-type"
                [adaptTooltip]="objective!.typeName">
            {{objective!.typeCode}}
        </strong>

        <adapt-team-private-icon [team]="objective.team"></adapt-team-private-icon>
    </div>

    <ng-container *ngIf="generateHref && (canReadObjective$ | async); else showObjectiveTitle">
        <a [routerLink]="(objectiveHref$ | async)">{{objective!.title}}</a>
    </ng-container>
    <ng-template #showObjectiveTitle>
        <span>{{objective!.title}}</span>
    </ng-template>
    <adapt-display-objective-status *ngIf="showStatus"
                                    class="ms-auto"
                                    [objectiveStatus]="badgeStatus"></adapt-display-objective-status>
</ng-container>

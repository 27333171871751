import { Component, Input } from "@angular/core";
import { Params } from "@angular/router";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BehaviorSubject, map, Observable, switchMap } from "rxjs";
import { ValuesConstitutionService } from "../values-constitution.service";
import { teamValuesRoute, valuesConstitutionRoute } from "../values-constitution-page/values-constitution-page.route";
import { ValuesConstitutionUtilities } from "../values-constitution-utilities";

interface IDisplayedValue {
    name: string;
    url: string;
    queryParams: Params;
}

@Component({
    selector: "adapt-values-constitution-list-view",
    templateUrl: "./values-constitution-list-view.component.html",
    styleUrls: ["./values-constitution-list-view.component.scss"],
})
export class ValuesConstitutionListViewComponent {
    @Input() public set team(value: Team | undefined) {
        this.team$.next(value);
    }

    public vcUrl$: Observable<string>;
    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    public values$: Observable<IDisplayedValue[]>;

    public constructor(
        vcService: ValuesConstitutionService,
    ) {
        this.vcUrl$ = this.team$.pipe(
            switchMap((team) => {
                return team
                    ? teamValuesRoute.getRoute({ teamId: team.teamId })
                    : valuesConstitutionRoute.getRoute();
            }),
        );
        this.values$ = this.team$.pipe(
            map((team) => team ? team.teamId : undefined),
            switchMap((teamId) => vcService.getValuesConstitution(teamId)),
            map((vc) => {
                const values = vc ? vc.values : [];
                return values.sort((a, b) => a.ordinal - b.ordinal);
            }),
            switchMap((values) => this.vcUrl$.pipe(
                map((url) => values.map((v) => ({
                    name: v.name,
                    url,
                    queryParams: {
                        value: ValuesConstitutionUtilities.generateValueId(v),
                    },
                }))),
            )),
        );
    }
}

import { Component, Input } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { NonParticipantUserTypes } from "@common/ADAPT.Common.Model/embed/user-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { ISimpleInvoice } from "@common/payment-processing/simple-invoice.interface";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { EulaService } from "@org-common/lib/eula/eula.service";
import { EulaDialogComponent } from "@org-common/lib/eula/eula-dialog/eula-dialog.component";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { combineLatest, Observable, of } from "rxjs";
import { map, startWith, switchMap, take, tap } from "rxjs/operators";
import { ConfirmCommenceSubscriptionDialogComponent } from "../confirm-commence-subscription-dialog/confirm-commence-subscription-dialog.component";

@Component({
    selector: "adapt-configure-subscription",
    templateUrl: "./configure-subscription.component.html",
})
export class ConfigureSubscriptionComponent extends ConfigureFeatureBase {
    public readonly allowedEulaPersonUserTypes = NonParticipantUserTypes;
    @Input() public configItem?: IConfigItem;

    public account$: Observable<Account | undefined> = of(undefined);
    public invoice$: Observable<ISimpleInvoice | undefined> = of(undefined);
    public eulaPerson?: Person;
    public canChargeOrganisation$: Observable<boolean>;
    public commenceSubscriptionTooltip$: Observable<string | undefined> = of(undefined);
    public billingPeriodLabel$: Observable<string> = of("");
    public emailSentSuccessfully = false;
    public error?: string;

    constructor(
        configurationService: ConfigurationService,
        authorisationService: AuthorisationService,
        public accountService: AccountService,
        private paymentProcessingService: PaymentProcessingService,
        private eulaService: EulaService,
        private dialogService: AdaptCommonDialogService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super(configurationService);
        this.canChargeOrganisation$ = authorisationService.getHasAccess(ConfigurationAuthService.ChargeOrganisation);
    }

    @Autobind
    public commenceSubscription() {
        return this.account$.pipe(
            emptyIfUndefinedOrNull(),
            take(1),
            switchMap((account) => this.dialogService.open(ConfirmCommenceSubscriptionDialogComponent, account)),
        );
    }

    public showEula() {
        this.dialogService.open(EulaDialogComponent).subscribe();
    }

    @Autobind
    public async initialiseData() {
        this.emailSentSuccessfully = false;
        this.account$ = this.accountService.getAccount();
        this.billingPeriodLabel$ = this.account$.pipe(
            emptyIfUndefinedOrNull(),
            map((a) => a.extensions.billingPeriodLabel),
            map((l) => l[0].toLocaleUpperCase() + l.substring(1)),
        );
        this.invoice$ = this.account$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((account) => {
                return this.paymentProcessingService.getNextInvoice(account.organisationId);
            }),
        );

        const subscriptionMsg = "You cannot commence a subscription for this organisation as";
        this.commenceSubscriptionTooltip$ = this.billingButtonTooltip(subscriptionMsg);
    }

    private billingButtonTooltip(msgStart: string) {
        return combineLatest([
            this.rxjsBreezeService.entityTypeChangedInSave(Account).pipe(
                startWith(undefined),
                switchMap(() => this.account$),
                emptyIfUndefinedOrNull(),
            ),
        ]).pipe(
            map(([account]) => {
                // these warnings do not apply to alto subscriptions, configure-payment-method will handle this.
                if (AdaptClientConfiguration.AdaptProject === AdaptProject.Alto) {
                    return undefined;
                }

                if (!account.eulaAcceptedDate) {
                    return `${msgStart} the customer license agreement has not been accepted.`;
                } else if (account.extensions.isBilledUsingCreditCard && !account.extensions.isCreditCardSet) {
                    return `${msgStart} no credit card details have been registered.`;
                } else if (!account.contactEmail) {
                    return `${msgStart} no billing email has been set.`;
                } else {
                    return undefined;
                }
            }),
        );
    }

    public isEmployee(person: Person) {
        const activeConnections = person.getActiveConnections();
        return activeConnections.some((c) => c.isEmployeeConnection());
    }

    @Autobind
    public sendEulaInvite(person: Person) {
        this.emailSentSuccessfully = false;

        return this.eulaService.sendEulaInvite(person).pipe(
            tap(() => this.emailSentSuccessfully = true),
        );

    }
}

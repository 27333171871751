<ng-container *adaptLoading="!isInitialised">
    <adapt-toolbar-content *adaptIfAuthorised="EditStrategicAnchors"
                           alignment="right">
        <button *ngIf="anchors.length > 1"
                adaptButton="reorder"
                class="me-2"
                [adaptBlockingClick]="reorderAnchors"
                data-tour="reorder-anchors-button">Reorder anchors</button>
        <button adaptButton="add"
                data-tour="add-anchor-button"
                [adaptBlockingClick]="addAnchor">
            anchor
        </button>
    </adapt-toolbar-content>
    <adapt-toolbar-title>
        <h3>Strategic anchors</h3>
    </adapt-toolbar-title>


    <ng-container *ngIf="anchors.length > 0; else showCTA">
        <adapt-strategic-anchors [anchors]="anchors"></adapt-strategic-anchors>
    </ng-container>
</ng-container>

<ng-template #showCTA>
    <adapt-call-to-action>
        <adapt-call-to-action-title>Strategic anchors</adapt-call-to-action-title>
        <p>
            Strategic anchors are an essential tool in strategic planning, providing a solid foundation for decision-making and ensuring
            alignment with an organisation's long-term goals and objectives.
        </p>
        <p>
            These anchors act as guiding principles that help organisations stay focused on their core values and strategic priorities. They
            provide a reference point for evaluating potential opportunities, initiatives, and projects, ensuring that they align with the
            organisation's overall strategy.
        </p>
        <button adaptCallToActionButton
                *adaptIfAuthorised="EditStrategicAnchors"
                adaptButtonIcon="add"
                [adaptBlockingClick]="addAnchor">
            Add anchor
        </button>
    </adapt-call-to-action>
</ng-template>

import { AfterViewInit, Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import dxList from "devextreme/ui/list";
import dxScrollView from "devextreme/ui/scroll_view";
import { DxScrollViewComponent } from "devextreme-angular";
import { BehaviorSubject, forkJoin, interval, of } from "rxjs";
import { concatMap, debounceTime, delay, filter, first, switchMap, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";

const MeetingIdQueryParam = "meetingId";

@Component({
    selector: "adapt-team-meetings-page",
    styleUrls: ["./team-meetings-page.component.scss"],
    templateUrl: "./team-meetings-page.component.html",
})
export class TeamMeetingsPageComponent extends BaseRoutedComponent implements OnInit, AfterViewInit {
    public editTeamKanban = CommonTeamsAuthService.EditTeamKanban;
    public team?: Team;
    public selectedMeeting?: Meeting;
    public ongoingMeetings: Meeting[] = [];
    public upcomingMeetings: Meeting[] = [];
    public endedMeetings: Meeting[] = [];
    public updateData$ = new BehaviorSubject<void>(undefined);
    public meetingListSelectionChanged$ = new BehaviorSubject<void>(undefined);
    public meetingsFetched$ = new BehaviorSubject<void>(undefined);
    public ongoingMeetingList?: dxList;
    public upcomingMeetingList?: dxList;
    public endedMeetingList?: dxList;
    public canEditTeamMeeting = false;

    protected teamId?: number;
    private focusMeetingId?: number;

    private isUpdatingSelection = false;

    private meetingListScrollView = new QueuedCaller<dxScrollView>();
    @ViewChild("meetingListScrollView") public set meetingListScrollViewComponent(value: DxScrollViewComponent) {
        if (value) {
            this.meetingListScrollView.setCallee(value.instance);
        }
    }

    public selectedMeetingElement?: HTMLElement;

    public constructor(
        private teamsService: CommonTeamsService,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        rxjsBreezeService: RxjsBreezeService,
        injector: Injector,
    ) {
        super(injector);

        rxjsBreezeService.entityTypeChanged(Meeting).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((meeting) => {
            if (meeting.teamId === this.teamId) {
                this.updateData$.next();
            }
        });
    }

    public get hasMeeting() {
        return this.endedMeetings.length > 0 || this.upcomingMeetings.length > 0 || this.ongoingMeetings.length > 0;
    }

    public ngAfterViewInit() {
        // need to wait for the contents of the meeting cards to be loaded or you can't tell if there is a scrollbar or not
        this.meetingListScrollView.call((instance) => interval(500).pipe(
            filter(() => GuidedTourUtils.isElementVisible(instance.element().get(0))),
            first(),
            concatMap(() => instance.update()), // need this to update the scrollbar visually or it can be just a dot!
            concatMap(() => {
                if (this.selectedMeetingElement) {
                    return interval(500).pipe( // a bit of delay after scrollview update() before start scrolling or scrollbar will be reset
                        filter(() => GuidedTourUtils.isElementVisible(this.selectedMeetingElement)),
                        first(),
                        tap(() => instance.scrollTo(
                            this.selectedMeetingElement!.getBoundingClientRect().top - (instance.element().offset()?.top ?? 0) - 64)));
                } else {
                    return of(undefined);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe());
    }

    public ngOnInit() {
        this.updateFromPageParams();
        this.navigationEnd.subscribe(() => this.updateFromPageParams());

        this.meetingsService.meetingIdChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            if (!this.focusMeetingId) {
                this.captureFocusMeetingId();
                this.updateData$.next();
            }
        });

        this.updateData$.pipe(
            debounceTime(10), // limit number of refresh from consecutive updates
            switchMap(() => forkJoin([
                this.teamsService.getTeamById(this.teamId!),
                this.meetingsService.getOngoingMeetingsForTeam(this.teamId!),
                this.meetingsService.getUpcomingMeetingsForTeam(this.teamId!),
                this.meetingsService.getEndedMeetingsForTeam(this.teamId!),
                this.meetingsService.primeAgendaItemsForNotStartedMeeting(this.teamId!),
                this.meetingsService.primeAttendeesForNotStartedMeeting(this.teamId!),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([team, ongoingMeetings, upcomingMeetings, endedMeetings]) => {
            this.team = team;
            this.canEditTeamMeeting = this.meetingsService.canEditMeetingForTeam(team!);
            this.ongoingMeetings = ongoingMeetings;
            this.upcomingMeetings = upcomingMeetings;
            this.endedMeetings = endedMeetings;

            if (this.focusMeetingId) {
                this.selectedMeeting = ongoingMeetings
                    .concat(upcomingMeetings)
                    .concat(endedMeetings)
                    .find((m) => m.meetingId === this.focusMeetingId);
            }

            if (!this.selectedMeeting) {
                if (ongoingMeetings.length > 0) {
                    this.selectedMeeting = ongoingMeetings[0];
                } else if (upcomingMeetings.length > 0) {
                    this.selectedMeeting = upcomingMeetings[0];
                } else if (endedMeetings.length > 0) {
                    this.selectedMeeting = endedMeetings[0];
                }

                if (this.selectedMeeting) {
                    this.focusMeetingId = this.selectedMeeting.meetingId;
                    this.routeService.updateSearchParameterValue(MeetingIdQueryParam, this.focusMeetingId);
                }
            }

            // notify any subscribers (e.g. our cadence page)
            this.meetingsFetched$.next();
            this.meetingListSelectionChanged$.next();

            // need to reapply this, as going from no meeting to having a meeting causes the CTA to disappear
            // and therefore reset shell padding
            this.removeDefaultShellPadding();

            // cannot update the list selection here as dxList not yet picking up dataSource changes, will have to wait till content ready
            this.notifyActivated();
        });
    }

    private updateFromPageParams() {
        this.teamId = this.getRouteParamInt("teamId");
        if (!this.teamId) {
            return this.handleUnauthorisedAccess();
        }

        this.verifyHasAccessToRoute(this.meetingsService.canViewTeamMeetings(this.teamId));
        this.captureFocusMeetingId();

        this.updateData$.next();
    }

    @Autobind
    public createTeamMeeting() {
        return this.meetingsUiService.createMeeting(this.teamId!).pipe(
            delay(0),
            tap((newMeeting) => {
                this.selectedMeeting = newMeeting;
                this.focusMeetingId = newMeeting.meetingId;
                this.routeService.updateSearchParameterValue(MeetingIdQueryParam, this.focusMeetingId);
                this.updateData$.next();
            }),
        );
    }

    public onMeetingDeleted() {
        this.selectedMeeting = undefined;
        this.updateData$.next();
    }

    public onSelectionChanged(e: IDxListSelectionChangedEvent<Meeting>) {
        if (e.addedItems.length > 0) {
            if (!this.isUpdatingSelection) {
                // selection from Ux
                this.selectedMeeting = e.addedItems[0];
                this.focusMeetingId = this.selectedMeeting.meetingId;
                this.routeService.updateSearchParameterValue(MeetingIdQueryParam, this.focusMeetingId);
            }

            if (this.ongoingMeetingList && this.ongoingMeetingList !== e.component) {
                this.ongoingMeetingList.unselectAll();
            }

            if (this.upcomingMeetingList && this.upcomingMeetingList !== e.component) {
                this.upcomingMeetingList.unselectAll();
            }

            if (this.endedMeetingList && this.endedMeetingList !== e.component) {
                this.endedMeetingList.unselectAll();
            }

            // notify any subscribers (e.g. our cadence page)
            this.meetingListSelectionChanged$.next();
        }
    }

    public updateMeetingListSelection() {
        if (this.selectedMeeting) {
            // there is already a selected meeting and we want to update the selection in the UI
            // - setting this flag so that we won't be updating selectedMeeting again from the callback from the subsequent selectItem.
            this.isUpdatingSelection = true;
            if (this.ongoingMeetings.indexOf(this.selectedMeeting) >= 0) {
                this.selectAndScrollToMeetingInList(this.ongoingMeetings, this.ongoingMeetingList);
            } else if (this.upcomingMeetings.indexOf(this.selectedMeeting) >= 0) {
                this.selectAndScrollToMeetingInList(this.upcomingMeetings, this.upcomingMeetingList);
            } else if (this.endedMeetings.indexOf(this.selectedMeeting) >= 0) {
                this.selectAndScrollToMeetingInList(this.endedMeetings, this.endedMeetingList);
            }
            this.isUpdatingSelection = false;
        }
    }

    private selectAndScrollToMeetingInList(targetMeetingList: Meeting[], targetMeetingDxList?: dxList) {
        if (!this.selectedMeeting || !targetMeetingDxList) {
            return;
        }

        targetMeetingDxList.selectItem(targetMeetingList.indexOf(this.selectedMeeting));

        //doing this with a set timeout to give a dx a chance to render the list before scrolling to item.
        setTimeout(() => targetMeetingDxList.scrollToItem(this.selectedMeeting), 100);
    }

    private captureFocusMeetingId() {
        const meetingIdValue = this.getSearchParameterValue(MeetingIdQueryParam);
        if (meetingIdValue) {
            this.focusMeetingId = Number(meetingIdValue);
            if (isNaN(this.focusMeetingId)) {
                this.focusMeetingId = undefined;
                this.routeService.deleteSearchParameter(MeetingIdQueryParam);
                // location.replace();
            }
        }
    }
}

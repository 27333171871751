import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Value } from "./value";

export class ValueQuestion extends BaseEntity<ValueQuestion> {
    public valueQuestionId!: number;
    public valueId!: number;
    public question!: string;
    public ordinal!: number;
    public goodBehaviour?: boolean;

    public value!: Value;
}

export const ValueQuestionBreezeModel = new BreezeModelBuilder("ValueQuestion", ValueQuestion)
    .withSortField("ordinal")
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output } from "@angular/core";
import { Input as StrategicInput } from "@common/ADAPT.Common.Model/organisation/input";
import { InputTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { CanvasType, CanvasTypeLabel } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import moment from "moment";
import { StrategicInputsService } from "../../strategic-inputs.service";

@Component({
    selector: "adapt-strategic-input",
    templateUrl: "./strategic-input.component.html",
    styleUrls: ["./strategic-input.component.scss"],
})
export class StrategicInputComponent extends BaseComponent implements OnChanges {
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;

    @Input() public input?: StrategicInput;
    @Input() public isCollapsible = false;
    @Input() public isEditing = false;
    @Input() public showIcon = false;
    @Input() public handleId?: string;
    @Input() public showCanvasNameInContent = false;
    @Input() public isDetachable = false;
    @Output() public detach = new EventEmitter<StrategicInput>();

    public canvasName = "";
    public isCompetitorAnalysis = false;

    public constructor(
        elementRef: ElementRef,
        private inputsService: StrategicInputsService,
    ) {
        super(elementRef);
    }

    public get iconClass() {
        return (this.showIcon && this.input) ? InputTypeMetadata[this.input.type].iconClass : undefined;
    }

    public get iconTooltip() {
        return (this.showIcon && this.input) ? InputTypeMetadata[this.input.type].singularLabel : undefined;
    }

    public ngOnChanges() {
        if (this.input) {
            if (!this.input.canvas && this.input.canvasId) {
                // canvas not primed - do it now
                this.inputsService.getCanvasById(this.input.canvasId).subscribe(() => this.updateCanvasName());
            } else if (this.input.canvas) {
                this.updateCanvasName();
            }
        }
    }

    private updateCanvasName() {
        if (this.input?.canvas.type === CanvasType.CompetitorAnalysis) {
            this.canvasName = `${CanvasTypeLabel[this.input!.canvas.type]}`;
            this.isCompetitorAnalysis = true;
        } else {
            this.canvasName = `${CanvasTypeLabel[this.input!.canvas.type]} ${moment(this.input!.canvas.canvasDate).format("DD/MM/YYYY HH:mm:ss")}`;
            this.isCompetitorAnalysis = false;
        }
    }

    // this won't stop propagation to container (e.g. dxList or dxSortable)
    @HostListener("click", ["$event"])
    public stopPropagation(e: Event) {
        e.stopPropagation();
    }

    @Autobind
    public editInput() {
        return this.inputsService.editInput(this.input!).pipe(
            this.takeUntilDestroyed(),
        );
    }

    public onDeleted(e: MouseEvent) {
        this.detach.emit(this.input);
        e.stopPropagation();
    }
}

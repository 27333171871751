import { Injectable } from "@angular/core";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { IMethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ModelRegistrar } from "@common/lib/data/model-registrar";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { AfterInitialisationAsync, AfterInitialisationObservable } from "@common/service/after-initialisation.decorator";
import { BaseInitialisationService } from "@common/service/base-initialisation.service";
import { SaveResult } from "breeze-client";
import isEqual from "lodash.isequal";
import { BehaviorSubject, defer, map, Subject } from "rxjs";
import { BreezeService } from "./breeze.service";
import { ICommonDataBase, ICommonDataModel } from "./common-data.interface";

@Injectable({
    providedIn: "root",
})
export class CommonDataService extends BaseInitialisationService {
    private _saveCompleted$ = new Subject<IBreezeEntity<unknown>[]>();
    private _savingInProgress$ = new BehaviorSubject<boolean>(false);
    private inProgressSavePromises: Promise<any>[] = [];
    private models: ICommonDataModel = {};

    public modelsByType: ICommonDataModel = {};

    public clearCountCacheForEntities = this.breezeService.clearCountCacheForEntities.bind(this.breezeService);
    public detachEntityFromBreeze = this.breezeService.detachEntityFromBreeze.bind(this.breezeService);
    public entitiesAreValid = this.breezeService.entitiesAreValid.bind(this.breezeService);
    public getChangedReferenceEntities = this.breezeService.getChangedReferenceEntities.bind(this.breezeService);
    public getLocalModelEntityById = this.breezeService.getLocalModelEntityById.bind(this.breezeService);
    public getUncommittedChanges = this.breezeService.getUncommittedChanges.bind(this.breezeService);
    public hasChanges = this.breezeService.hasChanges.bind(this.breezeService);
    public hasLocalEntity = this.breezeService.hasLocalEntity.bind(this.breezeService);

    /** A hot observable that emits each time a save is completed, with the entities which were saved */
    public get saveCompleted$() {
        return this._saveCompleted$.asObservable();
    }

    /** A hot observable which emits the change of saving status */
    public get savingInProgress$() {
        return this._savingInProgress$.asObservable();
    }

    public constructor(
        private breezeService: BreezeService,
    ) {
        super();
    }

    protected initialisationActions() {
        return [this.registerModels()];
    }

    private registerModels() {
        for (const model of ModelRegistrar.getAllModels()) {
            this.registerModel(model);
        }
        this.log.debug("Registered models");

        // needs to be a promise for initialisation
        return Promise.resolve();
    }

    @AfterInitialisationObservable
    public create<T extends IBreezeEntity>(model: IBreezeModel<T>, data?: Partial<T>) {
        return defer(() => this.breezeService.promiseToCreate(model, data));
    }

    @AfterInitialisationObservable
    public remove<T extends IBreezeEntity>(entity?: T) {
        return defer(() => this.breezeService.promiseToRemove(entity));
    }

    @AfterInitialisationObservable
    public cancel() {
        return defer(() => this.breezeService.promiseToCancel());
    }

    @AfterInitialisationObservable
    public save() {
        return defer(() => this.promiseToSaveEntities());
    }

    @AfterInitialisationObservable
    public saveEntities<T extends IBreezeEntity>(entities?: T | T[]) {
        return defer(() => this.promiseToSaveEntities(entities)).pipe(
            map(() => entities),
        );
    }

    @AfterInitialisationObservable
    public getAll<T extends IBreezeEntity>(model: IBreezeModel<T>, forceRemote?: boolean) {
        return defer(() => this.breezeService.promiseToGetAll(model, forceRemote));
    }

    @AfterInitialisationObservable
    public getActive<T extends IBreezeEntity>(model: IBreezeModel<T>, forceRemote?: boolean) {
        return this.getWithOptions(model, `active${model.source}`, {
            forceRemote,
            namedParams: {
                activeOnly: true,
            },
        });
    }

    @AfterInitialisationObservable
    public getActiveByPredicate<T extends IBreezeEntity>(model: IBreezeModel<T>, predicate?: IMethodologyPredicate<T>, forceRemote?: boolean) {
        const encompassingKey = `active${model.source}`;
        const key = `${encompassingKey}_${predicate?.getKey() ?? ""}`;
        const options = {
            encompassingKey,
            forceRemote,
            namedParams: {
                activeOnly: true,
            },
            predicate,
        };
        return this.getWithOptions(model, key, options);
    }

    @AfterInitialisationObservable
    public getByPredicate<T extends IBreezeEntity>(model: IBreezeModel<T>, predicate?: IMethodologyPredicate<T>, forceRemote?: boolean) {
        return defer(() => this.breezeService.promiseToGetByPredicate(model, predicate, forceRemote));
    }

    @AfterInitialisationObservable
    public getTopByPredicate<T extends IBreezeEntity>(model: IBreezeModel<T>, top: number, predicate: IMethodologyPredicate<T>, forceRemote?: boolean) {
        return defer(() => this.breezeService.promiseToGetTopByPredicate(model, top, predicate, forceRemote));
    }

    @AfterInitialisationObservable
    public getCountByPredicate<T extends IBreezeEntity>(model: IBreezeModel<T>, predicate: IMethodologyPredicate<T>) {
        return defer(() => this.breezeService.promiseToGetCountByPredicate(model, predicate));
    }

    @AfterInitialisationObservable
    public getWithOptions<T extends IBreezeEntity>(model: IBreezeModel<T>, requestKey: string, options: IBreezeQueryOptions<T>) {
        return defer(() => this.breezeService.promiseToGetWithOptions(model, requestKey, options));
    }

    @AfterInitialisationObservable
    public getById<T extends IBreezeEntity>(model: IBreezeModel<T>, id: number, forceRemote?: boolean) {
        return defer(() => this.breezeService.promiseToGetById(model, id, forceRemote));
    }

    @AfterInitialisationObservable
    public rejectChanges<T extends IBreezeEntity>(entities: T | T[]) {
        return defer(() => this.breezeService.promiseToRejectChanges(entities));
    }

    public clearCache() {
        return defer(() => this.breezeService.promiseToClearCache());
    }

    public get saveInProgress() {
        return this.inProgressSavePromises.length > 0;
    }

    public get savePromise() {
        return Promise.all(this.inProgressSavePromises);
    }

    /**
     * This will create a limited instance of the common data service, for when you need entities that shouldnt normally be present on the main service.
     * As an example, we might want to get connection information for the currently logged in person for EVERY organisation (not just the current one).
     */
    @AfterInitialisationAsync
    public promiseToCreateTransientCommonDataInstance() {
        const transientBreeze = this.breezeService.createTransientInstance();

        return Promise.resolve({
            cleanupInstance: transientBreeze.cleanupInstance.bind(transientBreeze),
            getByPredicate: (...args) => defer(() => transientBreeze.promiseToGetByPredicate(...args)),
            getTopByPredicate: (...args) => defer(() => transientBreeze.promiseToGetTopByPredicate(...args)),
            getWithOptions: (...args) => defer(() => transientBreeze.promiseToGetWithOptions(...args)),
            getById: (...args) => defer(() => transientBreeze.promiseToGetById(...args)),
            remove: (...args) => defer(() => transientBreeze.promiseToRemove(...args)),
        } as ICommonDataBase);
    }

    public optimiseAssignmentChanges(model: IBreezeModel) {
        if (ObjectUtilities.isNullOrUndefined(model)
            || !StringUtilities.isString(model.toType)
            || !StringUtilities.isString(model.sortField)
            || !Array.isArray(model.uniqueKeys)
            || model.uniqueKeys.length < 1) {
            throw new Error("Cannot optimise without the necessary fields.");
        }

        const uncommittedChanges = this.getUncommittedChanges(model);
        for (const entity of uncommittedChanges) {
            const fromValue: any = {};

            if (entity.entityAspect.entityState.isModified() || entity.entityAspect.entityState.isDeleted()) {
                if (entity.entityAspect.entityState.isDeleted() || model.uniqueKeys!.some((key) => !!entity.entityAspect.originalValues[key])) {
                    model.uniqueKeys!.forEach((key) => {
                        const originalValue = entity.entityAspect.originalValues[key];
                        fromValue[key] = originalValue ? originalValue : entity[key];
                    });

                    processEntityAssignedTo(fromValue, entity);
                }
            }

            function processEntityAssignedTo(idValue: any, fromEntity: IBreezeEntity) {
                // only expect no more than one element to assign any particular element (UI will enforce it)
                const entityAssignedToValue = ArrayUtilities.getSingleFromArray(uncommittedChanges.filter(findChangedToMatchId));
                if (!entityAssignedToValue) {
                    return;
                }

                const fromEntityFinalValue: any = {};

                const isFromDeleted = fromEntity.entityAspect.entityState.isDeleted();
                const fromEntityOrderBy = fromEntity[model.sortField!];

                model.uniqueKeys!.forEach((key: string) => fromEntityFinalValue[key] = fromEntity[key]);
                fromEntity.entityAspect.rejectChanges();
                fromEntity[model.sortField!] = entityAssignedToValue[model.sortField!];

                // entityAssignedToValue cannot be newly added as it won't
                // pass findChangedToMatchId.
                // Reject changes here as it will either be deleted or reassigned
                entityAssignedToValue.entityAspect.rejectChanges();

                if (isFromDeleted) {
                    entityAssignedToValue.entityAspect.setDeleted();
                } else {
                    // if entityAssignedToValue[assignToId] === fromEntityToValue,
                    // it will not trigger changes in entityAspect.originalValues
                    // (already double-checked that).
                    model.uniqueKeys!.forEach((key: string) => entityAssignedToValue[key] = fromEntityFinalValue[key]);
                    entityAssignedToValue[model.sortField!] = fromEntityOrderBy;
                }

                function findChangedToMatchId(destEntity: IBreezeEntity) {
                    // only modified entity with 'assignToId' changed needs to be altered.
                    // newly added entity behaves correctly without any of these.
                    // attempted to include newly added entity and treated
                    // it to be the same as modified entity; however, rejectChanges on
                    // a newly created entity doesn't undo the creation. setDeleted() on
                    // the entityAspect will just cause another delete ended up in the server
                    // attempting a delete on a newly created entity, which is not yet created
                    // on the server.
                    // After tested back and forth, is better to leave newly added entity out
                    // from here as it works correctly without problem.
                    return destEntity.entityAspect.entityState.isModified()
                        && model.uniqueKeys!.some((key) => destEntity.entityAspect.originalValues[key])
                        && model.uniqueKeys!.every((key) => isEqual(destEntity[key], idValue[key]));
                }
            }

        }
    }

    public registerModel(model: IBreezeModel) {
        if (this.models[model.identifier]) {
            this.log.debug(`Updating model definition: ${model.identifier}`);
            Object.assign(this.models[model.identifier], model);
        } else {
            this.models[model.identifier] = model;
        }

        const registeredModel = this.models[model.identifier];
        if (registeredModel.toType) {
            this.modelsByType[registeredModel.toType] = registeredModel;
        }

        this.breezeService.registerModel(registeredModel);
    }

    /**
     * Save changes to breeze entities and images.
     * @param {Entity|Entity[]} [entities] A single entity or array of
     *      entities to save. If not set will save all entities with pending changes.
     *      Elements of the array that are not entities will be filtered out.
     *      BEWARE: Will not save dependent entities so use with caution.
     * @returns {Promise} Resolves when save completed.
     */
    @AfterInitialisationAsync
    private promiseToSaveEntities(entities?: IBreezeEntity | IBreezeEntity[]) {
        let allEntities: IBreezeEntity[] | undefined;

        if (this.breezeService.isEntity(entities)) {
            allEntities = [entities as IBreezeEntity];
        } else if (Array.isArray(entities)) {
            allEntities = entities.filter(this.breezeService.isEntity);
        }

        this._savingInProgress$.next(true);
        const savePromise = this.breezeService.promiseToSave(allEntities)
            .then((breezeResult: SaveResult) => {
                if (breezeResult && breezeResult.entities) {
                    this._saveCompleted$.next(breezeResult.entities as IBreezeEntity[]);
                }
            })
            .finally(() => this._savingInProgress$.next(false));
        this.inProgressSavePromises.push(savePromise);
        savePromise.finally(() => ArrayUtilities.removeElementFromArray(savePromise, this.inProgressSavePromises));

        return savePromise;
    }
}

import { Injectable, Injector } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { Goal, GoalBreezeModel, GoalStatus } from "@common/ADAPT.Common.Model/organisation/goal";
import { Measurement, MeasurementBreezeModel, MeasurementType } from "@common/ADAPT.Common.Model/organisation/measurement";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import moment from "moment";
import { map, Subject, switchMap, tap } from "rxjs";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { StrategyService } from "../strategy/strategy.service";
import { StrategicViewIcon } from "../strategy/strategy-view-constants";
import { WorkflowRunDialogComponent } from "../workflow/workflow-run-dialog/workflow-run-dialog.component";
import { EditMeasurementScoreDialogComponent } from "./edit-measurement-score-dialog/edit-measurement-score-dialog.component";
import { EditStrategicGoalWorkflow } from "./edit-strategic-goal-workflow";

@Injectable({
    providedIn: "root",
})
export class StrategicGoalsService extends BaseOrganisationService {
    private goalAdded = new Subject<void>();

    public constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
        private strategyService: StrategyService,
    ) {
        super(injector);
    }

    public get goalAdded$() {
        return this.goalAdded.asObservable();
    }

    public getAddStrategicGoalMenuItem(zone?: Zone, theme?: Theme) {
        return {
            text: "Add Strategic Goal",
            icon: StrategicViewIcon.GoalIcon,
            onClick: () => this.editGoalAfterCreate(zone, theme).subscribe(),
            separatorTop: true,
        } as IAdaptMenuItem;
    }

    public createGoal() {
        return this.commonDataService.create(GoalBreezeModel, {
            organisationId: this.organisationId,
            status: GoalStatus.OnTrack,
            zone: ZoneMetadata.InOrder.at(0),
        });
    }

    public editGoalAfterCreate(zone?: Zone, theme?: Theme) {
        return this.createGoal().pipe(
            switchMap((goal) => {
                if (zone) {
                    goal.zone = zone;
                }

                goal.theme = theme;
                return this.editGoal(goal);
            }),
            tap(() => this.goalAdded.next()),
        );
    }

    public updateGoalOrdinal(goal: Goal) {
        return this.getGoalsByZone(goal.zone).pipe(
            tap((goals) => {
                const maxOrdinal = goals
                    .filter((g) => g.goalId !== goal.goalId)
                    .reduce((max, g) => g.ordinal > max ? g.ordinal : max, 0);
                goal.ordinal = maxOrdinal + 1;
            }),
        );
    }

    public editGoal(goal: Goal) {
        return this.dialogService.open(WorkflowRunDialogComponent, {
            workflow: EditStrategicGoalWorkflow,
            runData: goal,
        });
    }

    public createMeasurementForGoal(goal: Goal, type: MeasurementType) {
        const defaultNewMeasurementTimestamp = type === MeasurementType.Target
            // without startOf("day"), endOf("month") will be at the boundary of next month - crossing over when queried
            ? moment(new Date()).endOf("month").startOf("day").toDate()
            : moment(new Date()).startOf("day").add(1, "hours").toDate();
        return this.commonDataService.create(MeasurementBreezeModel, {
            goal,
            timestamp: defaultNewMeasurementTimestamp,
            value: 0,
            type,
        });
    }

    public recordNewMeasurement(goal: Goal) {
        return this.createMeasurementForGoal(goal, MeasurementType.Record).pipe(
            switchMap((newMeasurement) => this.editMeasurement(newMeasurement)),
        );
    }

    public editMeasurement(measurement: Measurement) {
        return this.dialogService.open(EditMeasurementScoreDialogComponent, measurement);
    }

    public getAllGoals(forceRemote?: boolean) {
        return this.commonDataService.getAll(GoalBreezeModel, forceRemote);
    }

    public getGoalsByZone(zone: Zone) {
        return this.commonDataService.getByPredicate(GoalBreezeModel, new MethodologyPredicate<Goal>("zone", "==", zone)).pipe(
            switchMap((goals) => this.strategyService.getThemesByZone(zone).pipe( // prime themes
                map(() => goals),
            )),
            map((goals) => goals.sort((g1, g2) => g1.ordinal - g2.ordinal)),
        );
    }

    public getExistingUnits() {
        return this.getAllGoals().pipe(
            map((goals) => ArrayUtilities.distinct(goals
                .filter((g) => !!g.unit)
                .map((g) => g.unit!)
                .sort((a, b) => a.localeCompare(b)))));
    }

    public orderMeasurementsByTimestamp(measurements: Measurement[]) {
        measurements.sort((m1, m2) => m1.timestamp.getTime() - m2.timestamp.getTime()); // ascending order
    }
}

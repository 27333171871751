import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Survey, SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyQuestionResponse } from "@common/ADAPT.Common.Model/organisation/survey-question-response";
import { SurveyResponseGroup, SurveyResponseGroupLabel } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartCustomizePoint, IDxChartLabelCustomiseTextArg, IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import dxChart, { DoneEvent, PointClickEvent } from "devextreme/viz/chart";
import { ReplaySubject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { SurveyService } from "../survey.service";
import { ISurveyQuestions } from "../survey-questions.interface";
import { SurveyUtils } from "../survey-utils";

interface IChartData {
    category: string;
    categoryId: number;
    // [valueField] from dxi-series cannot use "score.All" etc. Only a flat structured row.
    // score: { [key in SurveyResponseGroup]?: number };
    [key: string]: any;
}

const AnalyseSubjectLabel: { [subject in SurveyResponseGroup]: string } = {
    [SurveyResponseGroup.All]: "Overall Average",
    [SurveyResponseGroup.Leader]: "Organisation Leaders",
    [SurveyResponseGroup.NonLeader]: "Employees",
};

enum SurveyTargetType {
    Team = "team",
    Organisation = "organisation",
}

@Component({
    selector: "adapt-display-categories-summary",
    templateUrl: "./display-categories-summary.component.html",
    styleUrls: ["./display-categories-summary.component.scss"],
})
export class DisplayCategoriesSummaryComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public set survey(value: Survey) { // survey can be changed from the select box but questions won't
        this.survey$.next(value);
    }
    @Input() public surveyQuestions!: ISurveyQuestions;
    @Input() public showTitle = true;
    @Input() public selectedCategoryId?: number;
    @Output() public categoryIdClicked = new EventEmitter<number | undefined>();

    public readonly ResponseGroup = SurveyResponseGroup;
    public chartData: IChartData[] = [];
    public presentResponseGroups: SurveyResponseGroup[] = [];
    public analyseTargetLabel = "Team Attribute";
    public surveyName = "";
    public initialised = false;

    private survey$ = new ReplaySubject<Survey>(1);
    private latestSurvey?: Survey;
    private surveyTarget = SurveyTargetType.Organisation;
    private chartInstance?: dxChart;

    public constructor(
        elementRef: ElementRef,
        private surveyService: SurveyService,
    ) {
        super(elementRef);
    }

    public ngOnInit() {
        this.survey$.pipe(
            switchMap((survey) => {
                this.initialised = false;
                this.latestSurvey = survey;
                this.surveyTarget = survey.teamId ? SurveyTargetType.Team : SurveyTargetType.Organisation;
                this.analyseTargetLabel = SurveyUtils.forSurveyType(survey.surveyType).analyseTargetLabel;
                this.surveyName = SurveyTypeLabel[survey.surveyType];
                return this.surveyService.getSurveyQuestionResponses(survey);
            }),
            tap((questionResponses) => this.formChartData(questionResponses)),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.initialised = true);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedCategoryId && this.initialised) {
            this.chartInstance?.refresh();
        }
    }

    public updateChartDimensionAndSubscribeSizeChange(e: DoneEvent) {
        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    @Autobind
    public customiseChartTooltip(info: IDxChartTooltipPointInfo) {
        const scoreGrade = SurveyUtils
            .forSurveyType(this.latestSurvey!.surveyType)
            .getScoreGrade(info.point.data[SurveyResponseGroup.All])
            .toLowerCase();
        const surveySubject = info.point.series.tag === SurveyResponseGroup.All
            ? `${this.surveyTarget} is`
            : `${AnalyseSubjectLabel[info.point.series.tag as SurveyResponseGroup].toLowerCase()} assessed your ${this.surveyTarget} as`;

        return {
            text: `'${info.point.data.category}' has been scored at ${info.point.data[info.point.series.tag].toFixed(0)}%,
                which indicates your ${surveySubject} <b>${scoreGrade}</b> in this category`,
        };
    }

    @Autobind
    public customiseChartPoint(point: IDxChartCustomizePoint) {
        return {
            color: SurveyUtils
                .forSurveyType(this.latestSurvey!.surveyType)
                .getStatusColorFromPercentage(point.data[point.series.tag]),
        };
    }

    @Autobind
    public customiseChartText(e: IDxChartTooltipPointInfo) {
        return `${SurveyResponseGroupLabel[e.point.series.tag as SurveyResponseGroup]}: ${e.point.data[e.point.series.tag].toFixed(0)}%`;
    }

    @Autobind
    public customiseXAxisText(e: IDxChartLabelCustomiseTextArg) {
        if (this.selectedCategoryId) {
            const category = this.surveyQuestions.getCategory(this.selectedCategoryId);
            if (category?.categoryName === e.valueText) {
                return `<span style="font-weight: 600; font-size: 14px">${e.valueText}</span>`;
            }
        }
        return e.valueText;
    }

    public pointToPercentage(e: IDxChartCustomizePoint) {
        return `${e.value.toFixed(0)}%`;
    }

    public onPointClicked(point: PointClickEvent) {
        // only do this is there is an observer, otherwise selectedCategoryId won't be set and customiseXAxisText above
        // will not change the valueText
        if (point.target && point.target.data && point.target.data.categoryId && this.categoryIdClicked.observed) {
            point.component.hideTooltip();
            this.selectedCategoryId = (this.selectedCategoryId === point.target.data.categoryId)
                ? undefined
                : point.target.data.categoryId;
            this.categoryIdClicked.emit(this.selectedCategoryId);
            point.component.refresh();
            this.chartInstance = point.component;
        }
    }

    private formChartData(questionResponses: SurveyQuestionResponse[]) {
        this.chartData = [];
        this.presentResponseGroups = [];
        const surveyResults = this.latestSurvey?.surveyResults;
        if (surveyResults) {
            this.presentResponseGroups = surveyResults.map((r) => r.responseGroup);
        }

        if (this.surveyQuestions?.categoryIds) {
            for (const categoryId of this.surveyQuestions.categoryIds) {
                const category = this.surveyQuestions.getCategory(categoryId);
                if (category && this.latestSurvey) {
                    const chartItem: IChartData = {
                        category: category.categoryName,
                        categoryId,
                    };

                    for (const group of this.presentResponseGroups) {
                        chartItem[group] = SurveyUtils.getCategoryPercentageScore(
                            categoryId, this.surveyQuestions, questionResponses, this.latestSurvey.surveyType, group);
                    }

                    this.chartData.push(chartItem);
                }
            }
        }
    }
}

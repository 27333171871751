import { Injector, NgModule, NgZone } from "@angular/core";
import { ServiceUri } from "@common/configuration/service-uri";
import { IdentityService } from "@common/identity/identity.service";
import { ISessionData } from "@common/identity/identity-storage.service";
import { BreezeService } from "../data/breeze.service";
import { SignalRService } from "./signalr.service";

@NgModule({
    providers: [
        {
            provide: SignalRService,
            useFactory: (identityService: IdentityService, injector: Injector, ngZone: NgZone) => {
                SignalRService.registerQueryParameterFactory(
                    "access_token",
                    () => identityService.promiseToGetAccessToken());

                SignalRService.enableDetailedLogging();
                SignalRService.setSignalREndpoint(ServiceUri.SignalRServiceBaseUri);

                const signalRService = new SignalRService(injector, ngZone);

                BreezeService.addSaveTagParameter({
                    paramName: "signalRConnectionId",
                    invoker: () => signalRService.connectionId,
                    valueIsValid: () => true,
                });

                identityService.identity$.subscribe((sessionData?: ISessionData) => {
                    if (sessionData) {
                        signalRService.promiseToConnect();
                    } else {
                        signalRService.disconnect();
                    }
                });

                signalRService.setup();

                return signalRService;
            },
            deps: [IdentityService, Injector, NgZone],
        },
    ],
})
export class AdaptSignalRModule { }

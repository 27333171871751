import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import dxAccordion from "devextreme/ui/accordion";
import { DxAccordionComponent } from "devextreme-angular";
import { BehaviorSubject, merge, ReplaySubject, Subject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Autobind } from "../../lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "../../lib/utilities/array-utilities";
import { emptyIfUndefinedOrNull } from "../../lib/utilities/rxjs-utilities";
import { BaseComponent } from "../../ux/base.component/base.component";
import { ResponsiveService } from "../../ux/responsive/responsive.service";
import { NavigationHierarchyService } from "../navigation-hierarchy.service";
import { INavigationNode } from "../navigation-node.interface";

@Component({
    selector: "adapt-display-accordion-hierarchy",
    templateUrl: "./display-accordion-hierarchy.component.html",
    styleUrls: ["./display-accordion-hierarchy.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DisplayAccordionHierarchyComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @Input("hierarchy") public set rootNode(value: INavigationNode | undefined) {
        this.rootNode$.next(value);
    }
    @Input() public set hierarchyId(value: string | undefined) {
        this.hierarchyId$.next(value);
    }

    @Input() public hideRootWithoutChildren = false;

    @ViewChild(DxAccordionComponent) public dxAccordionComponent?: DxAccordionComponent;

    public rootNode$ = new BehaviorSubject<INavigationNode | undefined>(undefined);
    public hierarchyId$ = new ReplaySubject<string | undefined>(1);
    public nonAccordionNodes: INavigationNode[];
    public accordionNodes: INavigationNode[];
    public isMobileSize$ = this.responsiveService.isMobileSize$;

    private updateBranchSelectionSubscription$ = new Subject<INavigationNode[]>();
    private inViewAccordionInstance = new QueuedCaller<dxAccordion | undefined>();

    public constructor(
        private navHierarchyService: NavigationHierarchyService,
        private responsiveService: ResponsiveService,
        elementRef: ElementRef,
    ) {
        super(elementRef);

        this.nonAccordionNodes = [];
        this.accordionNodes = [];
    }

    public get rootVisible() {
        return !this.hideRootWithoutChildren
            || (this.nonAccordionNodes.length + this.accordionNodes.length) !== 0;
    }

    public ngAfterViewChecked() {
        if (!this.isInitialised) {
            // need the second condition to check bounding client rect as the dom will be there after switching organisation with 0 width
            if (this.elementRef?.nativeElement.offsetParent && this.elementRef.nativeElement.getBoundingClientRect().width > 0) {
                if (this.dxAccordionComponent?.instance) {
                    this.isInitialised = true;
                    this.inViewAccordionInstance.setCallee(this.dxAccordionComponent?.instance);
                }
            }
        }
    }

    public ngOnInit() {
        this.hierarchyId$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((id) => this.navHierarchyService.hierarchyChanged(id)),
            this.takeUntilDestroyed(),
        ).subscribe((node) => {
            this.rootNode$.next(node);
        });

        // Only ever get an emit from this after accordionNodes have been initialised
        // and each time after rootNode is changed from the subscription below
        this.updateBranchSelectionSubscription$.pipe(
            switchMap((accordionNodes) => merge(
                ...accordionNodes.map((n) => n.currentOrChildSelected$),
            )),
            this.takeUntilDestroyed(),
        ).subscribe((accordionNode) => {
            // if any of the descendants is selected -> expand accordion item
            const expandIndex = this.accordionNodes.indexOf(accordionNode);
            this.dxAccordionComponent?.instance.expandItem(expandIndex);
        });

        this.rootNode$.pipe(
            map((node) => {
                if (!node) {
                    this.accordionNodes = [];
                    this.nonAccordionNodes = [];
                } else {
                    [this.accordionNodes, this.nonAccordionNodes] = ArrayUtilities.partition(
                        node.children,
                        (n) => n.customData.displayAsAccordionInHierarchy!,
                    );
                    // set initial visibility of the accordion nodes - if accordion has no children -> won't be shown
                    this.accordionNodes.forEach(this.updateAccordionNodeVisiblity);
                }

                setTimeout(this.expandAccordionItems);
                this.updateBranchSelectionSubscription$.next(this.accordionNodes);
                return this.accordionNodes;
            }),
            switchMap((accordionNodes) => merge(
                ...accordionNodes.map((n) => n.childAdded$),
                ...accordionNodes.map((n) => n.childRemoved$),
            )),
            this.takeUntilDestroyed(),
        ).subscribe((accordionNode) => {
            // this subscription will now update the visibility of the accordion node which has been changed
            this.updateAccordionNodeVisiblity(accordionNode);
        });
    }

    public ngAfterViewInit() {
        this.expandAccordionItems();
    }

    @Autobind
    private expandAccordionItems() {
        if (this.dxAccordionComponent) {
            let hasCollapseItem = false;
            for (let i = 0; i < this.accordionNodes.length; i++) {
                if (this.accordionNodes[i].customData.expandOnLoad) {
                    this.dxAccordionComponent.instance.expandItem(i);
                } else {
                    this.dxAccordionComponent.instance.collapseItem(i);
                    hasCollapseItem = true;
                }
            }

            // collapse item will cause the item height of 0px and won't be visible
            if (hasCollapseItem) {
                this.inViewAccordionInstance.setCallee(undefined);
                this.inViewAccordionInstance.clearQueuedCalls();
                this.isInitialised = false;

                // have to wait till the accordion instance is in view or the update dimension won't work
                // - instance cannot be undefined or queued caller won't invoke this callback
                this.inViewAccordionInstance.call((instance) => instance!.updateDimensions());
            }
        }
    }

    private updateAccordionNodeVisiblity(node: INavigationNode) {
        node.visible = node.children.length > 0;
    }
}

import { Component, Input, OnChanges } from "@angular/core";
import { buttonPreset, IButtonType } from "../button/button-preset";

@Component({
    selector: "adapt-icon",
    template: `
        <i *ngIf="iconClass"
           [ngClass]="iconClass"
           aria-hidden="true"></i>
    `,
})
export class AdaptIconComponent implements OnChanges {
    @Input() public icon?: keyof typeof buttonPreset | string;
    public iconClass?: string;

    public ngOnChanges() {
        const genericButtonPreset = buttonPreset as unknown as { [type: string]: IButtonType };
        const buttonConfig = this.icon && genericButtonPreset[this.icon];
        this.iconClass = buttonConfig
            ? buttonConfig.iconClass
            : this.icon;
    }
}

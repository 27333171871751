<dx-validation-group *ngIf="person && organisation">
    <div class="mx-3">
        <div class="form-group">
            <label for="personImage">Set your profile picture</label>
            <adapt-select-image id="personImage"
                                class="profile-image-container profile-image-container-lg"
                                [imageIdentifier]="person.imageIdentifier"
                                [imageWidth]="100"
                                (imageIdentifierChange)="updateProfileImageIdentifier(person, $event)"
                                [defaultImageSrc]="defaultImageSrc"></adapt-select-image>
        </div>

        <div *ngIf="!currentPersonIsSubscriber"
             class="row justify-content-between align-items-center mb-3">
            <div class="form-group">
                <label>First name</label>
                <dx-text-box [(value)]="person.firstName">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                                             message="Name is required"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength"
                                             [min]="2"
                                             message="Name must have at least 2 letters"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
            <div class="form-group">
                <label>Last name</label>
                <dx-text-box [(value)]="person.lastName">
                    <dx-validator>
                        <dxi-validation-rule type="stringLength"
                                             [min]="2"
                                             message="Name must have at least 2 letters"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>

        <div *ngIf="showSetYourOrgLogo"
             class="form-group">
            <label for="orgImage">Set your organisation logo</label>
            <adapt-select-image id="orgImage"
                                [imageIdentifier]="organisation.imageIdentifier"
                                (imageIdentifierChange)="updateOrganisationImageIdentifier($event)"
                                [imageWidth]="180"
                                [imageHeight]="100"
                                [defaultImageSrc]="defaultImageSrcOrg"
                                [resizeHeight]="100"></adapt-select-image>
        </div>
    </div>
</dx-validation-group>

<ng-template #buttonTemplate>
    <button class="btn btn-outline-secondary border-0"
            (click)="skipInviteTeamLeaders()">
        Finish
    </button>
</ng-template>

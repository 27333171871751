<div *ngIf="person$ | async as person"
     class="px-0 px-md-3">
    <adapt-toolbar-content *ngIf="tourDismissed"
                           alignment="right">
        <button data-tour="personal-dashboard-tour"
                adaptButton="tour"
                (click)="runTour()"></button>
    </adapt-toolbar-content>

    <!-- Just a way to check for email confirmed  -->
    <div class="my-4 alert alert-info alert-dismissible d-none"
         role="alert"
         *ngIf="!emailConfirmed"
         left-column>
        <!-- bs alert dismissible not working anymore?? tried both bs-dismiss and dismiss - both not working
            ended up just using a (click) instead -->
        <span type="button"
              (click)="emailConfirmed = true"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"></span>
        Please confirm your account - a confirmation email has been sent
    </div>

    <div class="dashboard">
        <adapt-alto-shell-tour-prompt-dashboard-element [person]="person"></adapt-alto-shell-tour-prompt-dashboard-element>

        <adapt-workflows-dashboard-element [person]="person"></adapt-workflows-dashboard-element>

        <div class="split">
            <adapt-team-meetings-dashboard-element *adaptIfAuthorised="CanReadMeetings"
                                                   [person]="person"></adapt-team-meetings-dashboard-element>

            <adapt-personal-objectives-dashboard-element *adaptIfAuthorised="ReadObjectives"
                                                         [person]="person"></adapt-personal-objectives-dashboard-element>
        </div>
    </div>
</div>

import { Component, Inject, Injector } from "@angular/core";
import { uniqueValueNameValidator, Value } from "@common/ADAPT.Common.Model/organisation/value";
import { ValueQuestion } from "@common/ADAPT.Common.Model/organisation/value-question";
import { ValuesConstitution } from "@common/ADAPT.Common.Model/organisation/values-constitution";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { DxTextBoxComponent } from "devextreme-angular";
import { from } from "rxjs";
import { tap } from "rxjs/operators";
import { ValuesConstitutionService } from "../values-constitution.service";
import { ValuesConstitutionUiService } from "../values-constitution-ui.service";

@Component({
    selector: "adapt-edit-value-dialog",
    templateUrl: "./edit-value-dialog.component.html",
    styleUrls: ["./edit-value-dialog.component.scss"],
})
@PersistableDialog("EditValueDialog")
export class EditValueDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Value> {
    public readonly dialogName = "EditValue";

    public title: string;

    public questions: ValueQuestion[];
    public uniqueValueNameValidator = uniqueValueNameValidator;
    private removedQuestions: ValueQuestion[] = [];
    private isDetached = false;

    public constructor(
        private injector: Injector,
        private vcService: ValuesConstitutionService,
        @Inject(ADAPT_DIALOG_DATA) public value: Value,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
        this.autoResolveData = value;

        this.title = this.value.entityAspect.entityState.isAdded()
            ? "Add Value"
            : "Edit Value";

        this.questions = [...this.value.valueQuestions].sort((a, b) => a.ordinal - b.ordinal);

        rxjsBreezeService.entityTypeChanged(Value).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((v) => {
            if (v === this.value && v.entityAspect.entityState.isDetached()) {
                this.isDetached = true;
                this.setErrorMessage(`This value has been deleted from another session and can no longer be saved.
                    Please copy the information you want to preserve from this dialog and cancel to close the dialog.`);
            }
        });

        rxjsBreezeService.entityTypeChanged(ValuesConstitution).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((vc) => {
            if (!vc.entityAspect.entityState.isDetached() &&
                this.value.valuesConstitution?.entityAspect.entityState.isAdded() &&
                vc.teamId === this.value.valuesConstitution.teamId) {
                // newly created VC but another session has the VC created -> discard this one and use other
                this.value.valuesConstitution.entityAspect.rejectChanges();
                this.value.valuesConstitution = vc;
            }
        });
    }

    // Lazy load this service to avoid circular imports
    private get vcUiService() {
        return this.injector.get(ValuesConstitutionUiService);
    }

    public get entitiesToConfirm() {
        return [this.value.valuesConstitution, this.value, ...this.value.valueQuestions, ...this.removedQuestions]
            .filter((i) => !!i);
    }

    public updateValueQuestionOrdinals(e: IDxListItemReorderedEvent<ValueQuestion>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.questions, "ordinal", e.fromIndex, e.toIndex);
    }

    @Autobind
    public saveAndClose() {
        if (this.value.entityAspect.entityState.isAdded()) {
            // newly added entity - expect this ordinal to be the last (set from creation but other value can be added
            // from another session making this no longer true)
            this.value.ordinal = this.value.valuesConstitution.values.length - 1;
        }

        return from(super.saveAndClose()).pipe(
            tap(() => this.vcService.valueAdded$.next(this.value)),
        );
    }

    @Autobind
    public addQuestion() {
        return this.vcUiService.createAndEditValueQuestion(this.value).pipe(
            tap((vq) => this.questions.push(vq)),
        );
    }

    @Autobind
    public editQuestion(question: ValueQuestion) {
        return this.vcUiService.editValueQuestion(question);
    }

    @Autobind
    public deleteQuestion(question: ValueQuestion) {
        return this.vcUiService.deleteValueQuestion(question).pipe(
            tap(() => {
                ArrayUtilities.removeElementFromArray(question, this.questions);

                // Newly added questions that are deleted will be detached
                // and don't need to be saved
                if (question.entityAspect.entityState.isDeleted()) {
                    this.removedQuestions.push(question);
                }
            }),
        );
    }

    public get isInvalidOrNoChanges() {
        return this.value.entityAspect.hasValidationErrors
            || !this.hasUnsavedEntity || this.isDetached;
    }

    public focusTextBox(textBox: DxTextBoxComponent) {
        textBox.instance.focus();
    }
}

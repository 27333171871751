import { Component, Inject, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { NonParticipantUserTypes } from "@common/ADAPT.Common.Model/embed/user-type";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { IEntityWithRequiredTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import moment from "moment";
import { lastValueFrom, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ScheduleService } from "../../schedule/schedule.service";
import { ObjectivesService } from "../objectives.service";
import { ObjectivesAuthService } from "../objectives-auth.service";

@PersistableDialog("EditObjectiveDialogComponent")
@Component({
    selector: "adapt-edit-objective-dialog",
    templateUrl: "./edit-objective-dialog.component.html",
    styleUrls: ["./edit-objective-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditObjectiveDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Objective> implements OnInit {
    public readonly dialogName = "EditObjective";
    public readonly allowedObjectiveOwnerUserTypes = NonParticipantUserTypes;

    public isSaving = false;
    public isObjectiveValid = false;
    public statusOptions = ObjectiveStatusMetadata.All.map((metadata) => {
        return {
            value: metadata.status,
            name: metadata.name,
        };
    });
    public dueDateTouched = false;
    public groupedSupportiveObjectives: IGroupedData<number | undefined, Objective>[] = [];
    public titleErrorMessage?: { message: string } = undefined;
    public objectiveTitleChanged = false;
    public changedLabelLocations: LabelLocation[] = [];

    // Cannot use ObjectiveType enum in template
    public readonly ObjectiveTypeEnum = ObjectiveType;
    public isAutoDueDate = false;

    public constructor(
        private objectivesService: ObjectivesService,
        private objectivesAuthService: ObjectivesAuthService,
        @Inject(ADAPT_DIALOG_DATA) public objective: Objective,
        injector: Injector,
        private navHierarchyService: NavigationHierarchyService,
        private entityPersistentService: EntityPersistentService,
        private scheduleService: ScheduleService,
    ) {
        super(injector);
        this.initialiseGroupedSupportiveObjectives();
    }

    @Autobind
    public filterAssignees(person: Person) {
        return this.objective.teamId
            ? this.objectivesAuthService.personCanEditObjectivesForTeam(person, this.objective as unknown as IEntityWithRequiredTeam)
            : true;
    }

    public get hasChild() {
        return this.objective.childObjectives.length > 0;
    }

    public get isNew() {
        return this.objective.entityAspect.entityState.isAdded();
    }

    public get entitiesToConfirm() {
        return [this.objective, ...this.changedLabelLocations];
    }

    public get hasUnsavedEntity() {
        return this.entityPersistentService.isUnsavedEntity(this.objective) ||
            this.changedLabelLocations.length > 0;
    }

    public async ngOnInit() {
        this.validateObjective();
        await this.autoUpdateDueDate();
    }

    @Autobind
    public save() {
        this.isSaving = true;
        this.objective.modifiedDate = new Date(); // automatically set modified date
        return this.objectivesService.saveEntities(this.entitiesToConfirm)
            .pipe(
                tap(() => {
                    this.changedLabelLocations = [];
                    if (this.objectiveTitleChanged) {
                        this.navHierarchyService.updateActiveNodeFromUrlIfRouteParamMatchesValue("objectiveId", this.objective.objectiveId);
                    }

                    this.objectivesService.emitObjectiveUpdate(this.objective);
                    this.resolve(this.objective);
                }),
                catchError((err: AdaptError) => {
                    this.setErrorMessage(err.message);
                    this.isSaving = false;
                    return throwError(() => err);
                }),
            );
    }

    public onPersonChange(person: Person) {
        this.objective.assigneePerson = person;
        this.validateObjective();
    }

    public validateObjective() {
        if (this.objective && this.objective.entityAspect) {
            this.isObjectiveValid = !this.objective.entityAspect.hasValidationErrors &&
                !!this.objective.assigneePersonId && !!this.objective.title && !!this.objective.title.trim();
            if (this.isObjectiveValid) {
                this.setErrorMessage(undefined);
            }
        }
    }

    public async onObjectiveTypeChanged(objType: ObjectiveType) {
        this.objective.type = objType;
        await this.autoUpdateDueDate();

        if (objType === ObjectiveType.Annual) {
            this.objective.parentObjectiveId = undefined;
        }

        this.initialiseGroupedSupportiveObjectives();
    }

    private initialiseGroupedSupportiveObjectives() {
        this.objectivesService.getUnclosedPotentialSupportiveObjectives(this.objective)
            .subscribe((objectives) => {
                this.groupedSupportiveObjectives = ArrayUtilities.groupArrayBy(objectives.filter((obj) => obj !== this.objective), (o) => o.teamId);
            });
    }

    private async autoUpdateDueDate() {
        if (!this.objective.entityAspect.entityState.isAdded() || this.dueDateTouched) {
            return;
        }

        switch (this.objective.type) {
            case ObjectiveType.Annual:
                if (AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto) {
                    const cadenceEventCycle = await lastValueFrom(this.scheduleService.getEventCadenceCycle());

                    if (!cadenceEventCycle) {
                        this.objective.dueDate = moment().add(1, "year").toDate();
                        this.isAutoDueDate = false;
                    } else {
                        const annualEvent = await lastValueFrom(this.scheduleService.getLatestEventAndSeriesForEventTypePreset(EventTypePreset.AnnualStrategy));

                        this.objective.dueDate = annualEvent && annualEvent.event && moment().isBefore(annualEvent.event.startDate)
                            ? annualEvent.event.startDate
                            : cadenceEventCycle.extensions.nextCycleStartDate;

                        this.isAutoDueDate = true;
                    }
                } else {
                    this.objective.dueDate = moment().add(1, "year").toDate();
                    this.isAutoDueDate = false;
                }
                break;
            case ObjectiveType.Quarterly:
                if (AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto) {
                    const quarterlyEvent = await lastValueFrom(this.scheduleService.getLatestEventAndSeriesForEventTypePreset(EventTypePreset.QuarterlyStrategy));

                    if (quarterlyEvent && quarterlyEvent.series && quarterlyEvent.event) {
                        this.objective.dueDate = moment().isAfter(quarterlyEvent.event.startDate)
                            ? quarterlyEvent.series.extensions.getNextEvent(quarterlyEvent.event)?.startDate ?? quarterlyEvent.series.endDate
                            : quarterlyEvent.event.startDate;

                        this.isAutoDueDate = true;
                    } else {
                        this.objective.dueDate = moment().add(3, "months").toDate();
                        this.isAutoDueDate = false;
                    }
                } else {
                    this.objective.dueDate = moment().add(3, "months").toDate();
                    this.isAutoDueDate = false;
                }
                break;
            default:
                throw new Error("Unknown Objective type");
        }
    }

    public dueDateChanged(event: Date | number | string) {
        if (this.dueDateTouched) {
            this.isAutoDueDate = false;
        }
        this.objective.dueDate = event as Date;
    }
}

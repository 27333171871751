import later from "@breejs/later";
import { Event } from "@common/ADAPT.Common.Model/organisation/event";
import moment from "moment";
import { EventSeries, EventSeriesType } from "./event-series";
import { EventSeriesDayOfWeekMetadata } from "./event-series-day-of-week";
import { EventSeriesWeekIndexMetadata } from "./event-series-week-index";

export class EventSeriesExtensions {
    public constructor(private eventSeries: EventSeries) {
    }

    public get schedule() {
        let schedule = later.parse.recur();

        if (this.eventSeries.eventSeriesType === EventSeriesType.Once) {
            schedule = schedule
                .on(this.eventSeries.startDate).fullDate();
        }

        if (this.eventSeries.eventSeriesType === EventSeriesType.RelativeYearly
            && this.eventSeries.dayOfWeek && this.eventSeries.weekIndex && this.eventSeries.month) {
            schedule = schedule
                .every(this.eventSeries.interval).year()
                .on(this.eventSeries.month).month()
                .on(EventSeriesWeekIndexMetadata.ByWeekIndex[this.eventSeries.weekIndex].value).dayOfWeekCount()
                .on(EventSeriesDayOfWeekMetadata.ByDayOfWeek[this.eventSeries.dayOfWeek].value).dayOfWeek();
        }

        if (this.eventSeries.eventSeriesType === EventSeriesType.RelativeMonthly
            && this.eventSeries.dayOfWeek && this.eventSeries.weekIndex && this.eventSeries.month) {
            schedule = schedule
                .on(EventSeriesWeekIndexMetadata.ByWeekIndex[this.eventSeries.weekIndex].value).dayOfWeekCount()
                .on(EventSeriesDayOfWeekMetadata.ByDayOfWeek[this.eventSeries.dayOfWeek].value).dayOfWeek();

            // above doesn't generate as we expect. manually take over
            // given interval=3, month=6, this results in [3, 6, 9, 12] (i.e. march, june, sept, dec)
            schedule.schedules[0].M = Array(12 / this.eventSeries.interval)
                .fill(0)
                .map((_, idx) => (((this.eventSeries.month! - 1) + (this.eventSeries.interval * idx)) % 12) + 1)
                .sort((a, b) => a - b);
        }

        const start = moment(this.eventSeries.startDate).utc();
        schedule = schedule
            .on(start.hour()).hour()
            .on(start.minute()).minute();

        return schedule;
    }

    public getNextTimes(dateFrom: Date, dateTo: Date) {
        const laterInstance = later.schedule(this.schedule);

        // maximum cadence length is 18 months, so generate that many events.
        const nextTimes = laterInstance.next(18, dateFrom, dateTo);

        // not an array if only one date
        const times = Array.isArray(nextTimes) ? nextTimes : [nextTimes];

        // dateTo doesn't seem to work as expected, so manually filter the times for all times less than dateTo.
        return times.filter((time) => time.getTime() < dateTo.getTime());
    }

    public getNextEvent(event: Event) {
        return this.eventSeries.events[this.eventSeries.events.indexOf(event) + 1];
    }
}

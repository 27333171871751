<a *ngIf="cardData"
   class="card mb-0 h-100"
   [routerLink]="organisationUrl$ | async"
   [ngClass]="{'is-current-organisation': isCurrentOrganisation$ | async}"
   adaptTooltip="Switch to this organisation"
   [tooltipEnabled]="(isCurrentOrganisation$ | async) === false">
    <div class="card-header pt-3">
        <adapt-storage-image [imageIdentifier]="cardData!.organisation.imageIdentifier"
                             [imageHeight]="40"
                             [imageWidth]="215"
                             defaultImageSrc="/content/images/default_organisation.png"></adapt-storage-image>
    </div>
    <div class="card-body pt-2">
        <h2 class="card-title">
            <span *ngIf="isCurrentOrganisation$ | async"
                  class="is-current-organisation-icon fa-stack fa-2x"
                  adaptTooltip="This is the organisation you are currently viewing">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fal fa-check fa-stack-1x fa-inverse"></i>
            </span>

            {{cardData!.organisation.name}}
        </h2>
        <div class="d-flex mt-2">
            <span *ngFor="let connectionType of cardData!.connectionTypes"
                  class="connection-type-badge badge"
                  [ngClass]="connectionTypeToBadgeClass[connectionType]">
                {{connectionType}}
            </span>
        </div>
    </div>
</a>

import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { TEAM_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import isEqual from "lodash.isequal";
import { BehaviorSubject, EMPTY, forkJoin, lastValueFrom, Observable, of, Subject } from "rxjs";
import { debounceTime, filter, finalize, map, switchMap, tap } from "rxjs/operators";
import { ICreateItemOptions, KanbanService } from "../kanban.service";
import { FilterDueDate, IFilterParams } from "../kanban-filter/kanban-filter.component";
import { KanbanUiService } from "../kanban-ui.service";

@Component({
    selector: "adapt-kanban-view",
    templateUrl: "./kanban-view.component.html",
    styleUrls: ["./kanban-view.component.scss"],
})
export class KanbanViewComponent extends BaseComponent implements OnChanges {
    @Input() public items$?: Observable<Item[]>;

    // this will allow customisation of the status columns shown in kanban - default to the 3 statuses
    @Input() public visibleItemStatuses = [ItemStatus.ToDo, ItemStatus.InProgress, ItemStatus.Done];

    // the following indicate if the kanban view is for personal or team work
    @Input() public team?: Team;
    @Input() public person?: Person;

    // to handle item selection from page query param
    @Input() public selectedItemId?: number;

    @Output() public selectedItemChange = new EventEmitter<Item | undefined>();

    @Input() public filter?: IFilterParams;
    @Output() public filterChange = new EventEmitter<IFilterParams>();

    @Output() public boardIdsChange = new EventEmitter<number[]>();
    @Input() public boardIds?: number[];

    @Output() public itemDialogOpened = new EventEmitter<Item>();
    @Output() public itemDialogClosed = new EventEmitter<Item>();
    @Input() public openDialog = false;

    @Input() public backlogShown = false;
    @Output() public backlogShownChange = new EventEmitter<boolean>();

    public readonly ConfigureTeamVerifier = CommonTeamsAuthService.ConfigureTeam;
    public readonly BacklogIconClass = ItemStatusMetadata.Backlog.iconClass;
    public showLeftToolbarInSmallerScreen = true;
    public previewClosed = false;

    public selectedItem?: Item;
    public filterByBoards: Board[] = [];
    private filterByText?: string;
    private skipReselectionAfterFilter = false;

    public items?: Item[]; // starts with undefined for board to show a spinner on startup rather than 'no item' text
    public unfilteredItems: Item[] = [];
    public isXL = false;
    public isMD = false;
    public hasEditableBoard$: Observable<boolean>;
    public backlogCount = 0;
    public isTeamViewWithoutBoard = false;
    private article = this.isAlto ? ImplementationKitArticle.WorkOverview : ImplementationKitArticle.UsingKanbanBoards;
    public learnMoreUrl = ImplementationKitService.GetArticleLink(this.article);

    private triggerUpdateItems$ = new Subject<void>();
    private triggerApplyFilter$ = new Subject<void>();
    private triggerEditableBoardCheck$ = new BehaviorSubject<void>(undefined);
    private openEntranceItem = false;

    private throttledFilterHandler = this.createThrottledUpdater((f: IFilterParams) => {
        this.items = undefined; // trigger spinner
        this.skipReselectionAfterFilter = true; // instead of clearing selection, this will just stop the item dialog from popping up if no longer visible after filter
        this.filter = { ...f };
        this.triggerApplyFilter$.next();
        this.filterChange.emit(this.filter);
    });

    public constructor(
        elementRef: ElementRef,
        responsiveService: ResponsiveService,
        rxjsBreezeService: RxjsBreezeService,
        private kanbanService: KanbanService,
        private kanbanUiService: KanbanUiService,
        private labellingService: LabellingService,
        @Inject(TEAM_CONFIGURATION_PAGE) private teamConfigurationPageRoute: IAdaptRoute<{ teamId: number }>,
    ) {
        super(elementRef);

        responsiveService.currentBreakpoint$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((breakpoint) => {
            this.isXL = breakpoint.is(Breakpoint.XL);
            this.isMD = breakpoint.is(Breakpoint.MD);
        });

        this.triggerUpdateItems$.pipe(
            debounceTime(100),
            switchMap(() => this.updateItems()),
            this.takeUntilDestroyed(),
        ).subscribe();

        rxjsBreezeService.entityTypeChanged(Item).pipe(
            filter(() => this.isInitialised), // initialisation will update items and apply filter -> don't care about item change before that
            map((item) => {
                if (item.entityAspect.entityState.isUnchanged() || // added from another session
                    (item.entityAspect.entityState.isDetached() || // deletion
                        this.visibleItemStatuses.indexOf(item.status) < 0 || // status change causing item to not visible
                        (this.team && item.board?.teamId !== this.team.teamId)) // item moved away to different board
                    && this.unfilteredItems.find((i) => i.itemId === item.itemId)) {
                    // item deletion or status changed affecting current unfiltered collection - rerun query
                    this.triggerUpdateItems$.next();
                    return undefined; // updateItems will trigger applyFilter() at the end -> return undefined to not doing it in this chain
                } else {
                    return item;
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe((item) => {
            if (item && (item.assigneeId === this.person?.personId || item.board?.teamId === this.team?.teamId)) {
                // only reapply filter if item affecting current personal or team work
                this.triggerApplyFilter$.next();
            }
        });

        rxjsBreezeService.entityTypeChanged(Board).pipe(
            // this will trigger editable board check if any board changed in person work page or board from the corresponding team
            filter((board) => this.isInitialised && (!!this.person || (board.teamId === this.team?.teamId))),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.triggerUpdateItems$.next(); // board change may affect items
            this.triggerEditableBoardCheck$.next();
        });

        this.triggerApplyFilter$.pipe(
            filter(() => this.isInitialised), // not going to apply filter if not initialised -> updateItems() will apply filters at the end
            debounceTime(50),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.applyFilter());

        this.hasEditableBoard$ = this.triggerEditableBoardCheck$.pipe(
            switchMap(() => this.kanbanService.getAllEditableBoards()),
            map((editableBoards) => {
                if (this.team) {
                    return !!editableBoards.find((b) => b.teamId === this.team!.teamId);
                } else {
                    return editableBoards.length > 0;
                }
            }),
        );
    }

    public get showBacklogBadge() {
        // only show the backlog badge if backlog column is not currently visible
        return this.visibleItemStatuses.indexOf(ItemStatus.Backlog) < 0
            && this.backlogCount > 0;
    }

    public toggleBacklog() {
        this.backlogShown = !this.backlogShown;
        this.handleBacklogChanged();
    }

    // This is called from a button action from kanban board (lightbulb popover action)
    public showBacklogColumn(visible: boolean) {
        this.backlogShown = visible;
        this.handleBacklogChanged();
    }

    private handleBacklogChanged() {
        if (this.backlogShown) {
            this.visibleItemStatuses = [ItemStatus.Backlog, ItemStatus.ToDo, ItemStatus.InProgress, ItemStatus.Done];
        } else {
            this.visibleItemStatuses = [ItemStatus.ToDo, ItemStatus.InProgress, ItemStatus.Done];
        }

        this.backlogShownChange.emit(this.backlogShown);
        this.triggerApplyFilter$.next();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.items$ && this.items$) {
            this.items = undefined; // clear items to remove residues while switching board - the trigger below will update items again
            this.triggerUpdateItems$.next();
        }

        if (changes.boardIds && this.boardIds) {
            // crude equality check to stop board entering loading state if unnecessary
            if (!isEqual(changes.boardIds.previousValue, changes.boardIds.currentValue)) {
                this.items = undefined;
            }

            forkJoin(this.boardIds.map((boardId) => this.kanbanService.getBoardById(boardId))).pipe(
                this.takeUntilDestroyed(),
            ).subscribe((boards) => {
                this.filterByBoards = boards.filter((b) => !!b) as Board[];
                this.triggerApplyFilter$.next();
            });
        }

        if (changes.backlogShown && this.backlogShown) {
            this.handleBacklogChanged();
        }

        if (changes.filter && !isEqual(changes.filter.previousValue, changes.filter.currentValue)) {
            // this one only comes from kanban page (through the @Input interface)
            // as this.filter has been set here, the event emitted from kanban filter won't trigger update
            // as this.filter and emitted filter are equals
            this.triggerApplyFilter$.next();
        }

        if (changes.team) {
            // team @Input changed -> trigger editable board check
            this.triggerEditableBoardCheck$.next();
        }
    }

    public onSelectedItemChanged(item?: Item) {
        if (item) { // if item selected -> reopen preview panel
            this.previewClosed = false;
        }

        this.selectedItem = item;
        this.selectedItemChange.emit(item);

        if (!this.isMD && item) {
            // smaller screen where item preview panel is not going to be shown - show dialog instead
            this.openItem(item).subscribe();
        }
    }

    public onFilterChanged(f: IFilterParams) {
        if (!isEqual(this.filter, f)) {
            this.throttledFilterHandler.next(f);
        }
    }

    @Autobind
    public searchItem() {
        return this.kanbanUiService.openItemSearchDialog(this.team);
    }

    public onBoardSelectionChanged(boards: Board[]) {
        this.filterByBoards = boards;
        this.triggerApplyFilter$.next();
        this.boardIdsChange.emit(this.filterByBoards.map((b) => b.boardId));
    }

    public onSearchTextValueChanged(value: string) {
        this.filterByText = value;
        this.triggerApplyFilter$.next();
    }

    @Autobind
    public addItem() {
        // this is from kanbanBoard-directive - filterByBoards may not necessarily has edit permission
        // - append team boards to evaluate all
        let boardChoices: Board[] = this.filterByBoards;
        if (this.team?.boards.length) {
            boardChoices = boardChoices.concat(this.team.boards);
        }

        const createItemOptions: ICreateItemOptions = {
            forceAllBoards: ObjectUtilities.isNullOrUndefined(this.team),
            itemOptions: {
                assignee: this.person,
                status: ItemStatus.Backlog,
            },
        };

        if (this.visibleItemStatuses.length > 0) {
            createItemOptions.itemOptions!.status = this.visibleItemStatuses[0];
        }

        return this.kanbanUiService.createItem(boardChoices, createItemOptions).pipe(
            tap((newItem) => {
                // won't get here on cancel
                this.onSelectedItemChanged(newItem); // select newly created item
                this.triggerUpdateItems$.next();
            }),
        );
    }

    public configureTeam() {
        this.teamConfigurationPageRoute.gotoRoute({ teamId: this.team!.teamId })
            .subscribe();
    }

    private updateItems() {
        if (this.items$) {
            this.selectedItem = undefined;
            return this.items$.pipe(
                tap((items) => {
                    this.unfilteredItems = items;
                    this.isInitialised = true;
                    this.triggerApplyFilter$.next();

                    // items query will ensure all boards are primed - so do it after item query
                    if (!this.team) {
                        this.isTeamViewWithoutBoard = false;
                    } else {
                        this.isTeamViewWithoutBoard = (this.team.boards.length === 0);
                    }
                }),
            );
        } else {
            return EMPTY;
        }
    }

    // TODO: filter should be done in a service after we converge stewardship items with kanban service
    // currently filtering is done in stewardship service which I have issue with circular dependency
    private async applyFilter() {
        this.items = Array.from(this.unfilteredItems);
        if (this.filter) {
            if (this.filter.assignee) {
                this.items = this.items.filter((i) => i.assignee === this.filter?.assignee);
            }

            const now = new Date().getTime();
            if (this.filter.dueDate === FilterDueDate.OverdueItems) {
                this.items = this.items.filter((i) => i.dueDate && i.dueDate.getTime() < now && i.status !== ItemStatus.Done && i.status !== ItemStatus.Closed);
            } else if (this.filter.dueDate === FilterDueDate.OnScheduleItems) {
                this.items = this.items.filter((i) => i.dueDate && i.dueDate.getTime() > now);
            }

            if (this.filter.createdWithin) {
                this.items = this.items.filter((i) => i.createdDateTime.getTime() >= this.filter!.createdWithin!.value.getTime());
            }

            if (this.filter.updatedWithin) {
                this.items = this.items.filter((i) => i.lastUpdatedDateTime.getTime() >= this.filter!.updatedWithin!.value.getTime());
            }

            if (this.filter.labels?.length) {
                const labelLocations = await lastValueFrom(this.labellingService.getLabelLocationsForLabels(this.filter.labels.map((l) => l.labelId)));
                this.items = this.items.filter((i) => !!labelLocations.find((l) => l.itemId === i.itemId));
            }
        }

        if (this.filterByBoards.length) {
            this.items = this.items.filter((i) => !!i.board && this.filterByBoards.indexOf(i.board) >= 0);
        }

        if (this.filterByText) {
            const searchText = this.filterByText.toLowerCase();
            this.items = this.items.filter((i) => i.code.toLowerCase().indexOf(searchText) >= 0 ||
                i.summary.toLowerCase().indexOf(searchText) >= 0 ||
                i.description && i.description.toLowerCase().indexOf(searchText) >= 0);
        }

        // backlog count only calculated after filter followed by status filter
        this.backlogCount = this.items.filter((i) => i.status === ItemStatus.Backlog).length;
        this.items = this.items.filter((i) => this.visibleItemStatuses.indexOf(i.status) >= 0);

        if (this.selectedItemId) {
            this.selectedItem = this.items.find((i) => i.itemId === this.selectedItemId);
        }

        // have to ensure the selected item exists in the list, if not need to reset
        if (this.selectedItem && this.items.indexOf(this.selectedItem) < 0) {
            this.onSelectedItemChanged(undefined); // set to undefined and emit
        }

        if (!this.selectedItem && this.selectedItemId) {
            if (!this.openEntranceItem) {
                // only open item dialog once on page loaded if defined by query param but not shown in the board
                if (!this.skipReselectionAfterFilter) {
                    // won't open if skipped from filter
                    this.kanbanService.getItemById(this.selectedItemId).pipe(
                        switchMap((item) => {
                            if (item) {
                                this.openDialog = false;
                                return this.openItem(item);
                            } else {
                                // inaccessible selectedItemId -> clear it
                                this.selectedItemId = undefined;
                                this.selectedItemChange.emit(undefined);
                                return of();
                            }
                        }),
                        this.takeUntilDestroyed(),
                    ).subscribe();
                }
            }
        } else if (this.selectedItem && this.openDialog) {
            // selected item found - item preview opened but still need to open dialog
            this.openDialog = false;
            this.openItem(this.selectedItem).subscribe();
        }

        if (this.skipReselectionAfterFilter) { // reset after applying filter
            this.skipReselectionAfterFilter = false;
        }

        if (!this.openEntranceItem) { // after filter applied for the first time, won't keep popping up the dialog again
            this.openEntranceItem = true;
        }
    }

    private openItem(item: Item) {
        this.itemDialogOpened.emit(item);
        return this.kanbanUiService.openItem(item).pipe(
            finalize(() => this.itemDialogClosed.emit(item)),
        );
    }
}

import { Component, Inject } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseConfirmationDialogComponent } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/base-confirmation-dialog.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { KanbanService } from "../kanban.service";

export interface IConfirmDeleteBoardData extends IConfirmationDialogData {
    board: Board;
}

@Component({
    selector: "adapt-delete-board-dialog",
    templateUrl: "./delete-board-dialog.component.html",
    styleUrls: ["./delete-board-dialog.component.scss"],
})
export class DeleteBoardDialogComponent extends BaseConfirmationDialogComponent {
    public readonly dialogName = "DeleteBoardConfirmation";

    public board: Board;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IConfirmDeleteBoardData,
        kanbanService: KanbanService,
    ) {
        super(dialogData);

        dialogData.title = "Delete Board: " + dialogData.board.name;
        dialogData.confirmButtonText = "Delete & Save";

        this.board = dialogData.board;
        // prime board items
        kanbanService.getItemsByBoardId(this.board.boardId).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }
}

<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.EffectiveStewardship"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div adaptToolbarContent>
    <button *ngIf="canEditTeamMeeting"
            adaptButtonIcon="add"
            [adaptBlockingClick]="createTeamMeeting"
            data-test="schedule-meetings-button">Schedule a meeting</button>
</div>

<ng-container *adaptLoading="!isActivated">
    <div *ngIf="hasMeeting; else noMeeting"
         class="row h-100 meetings-container">
        <div class="col-12 col-xl-8 d-flex flex-column gap-3">
            <adapt-collapsible-dashboard-element name="Meeting Calendar"
                                                 [collapsed]="cadenceCollapsed"
                                                 (collapsedChange)="onCadenceCollapseChanged($event)"
                                                 (afterAnimation)="updateMeetingDetailsScroll()">
                <adapt-time-scheduler-wrapper [items]="items"
                                              [sections]="sections"
                                              [start]="schedulerStartTime"
                                              [selectedItem]="selectedCadenceItem"
                                              [currentPeriod]="defaultCadencePeriod"
                                              (selectedItemChange)="handleCadenceSelectionChange($event)"
                                              (selectedSectionsChange)="updateMeetingDetailsScroll()"></adapt-time-scheduler-wrapper>
            </adapt-collapsible-dashboard-element>

            <ng-container *ngIf="selectedMeeting">
                <adapt-meeting-details [meeting]="selectedMeeting"
                                       (meetingChange)="updateData$.next()"
                                       (meetingDeleted)="onMeetingDeleted()"
                                       (meetingStarted)="selectedMeeting = $event"
                                       (meetingDetailsScrolled)="meetingDetailsScrolled($event)"
                                       (scrollViewInitialised)="meetingDetailsScrollViewComponentInitialised($event)"
                                       [class.hide-scroll-overflow]="hideOverflow"></adapt-meeting-details>
            </ng-container>
        </div>
        <div class="col-12 col-xl-4 meetings-column">
            <!-- no point showing if you can't create or edit meetings -->
            <adapt-meeting-integration-prompt *ngIf="canEditTeamMeeting"></adapt-meeting-integration-prompt>

            <dx-scroll-view #meetingListScrollView
                            showScrollbar="always">
                <adapt-dashboard-element name="Ongoing Meetings"
                                         *ngIf="ongoingMeetings.length > 0">
                    <dx-list [dataSource]="ongoingMeetings"
                             selectionMode="single"
                             (onInitialized)="ongoingMeetingList = $event.component"
                             (onSelectionChanged)="onSelectionChanged($event)"
                             (onContentReady)="updateMeetingListSelection()"
                             [focusStateEnabled]="false"
                             class="dx-list-boxed">
                        <adapt-meeting-dashboard-card *dxTemplate="let meeting of 'item'"
                                                      [meeting]="meeting"
                                                      [showStatus]="false"
                                                      [showCard]="false"
                                                      [selected]="selectedMeeting === meeting"
                                                      [highlightPreWork]="false"
                                                      [notSelectable]="false"
                                                      (elementSelected)="selectedMeetingElement = $event"
                                                      [preWorkClickable]="false"></adapt-meeting-dashboard-card>
                    </dx-list>
                </adapt-dashboard-element>

                <adapt-dashboard-element name="Upcoming Meetings"
                                         *ngIf="upcomingMeetings.length > 0">
                    <dx-list [dataSource]="upcomingMeetings"
                             selectionMode="single"
                             (onInitialized)="upcomingMeetingList = $event.component"
                             (onSelectionChanged)="onSelectionChanged($event)"
                             (onContentReady)="updateMeetingListSelection()"
                             [focusStateEnabled]="false"
                             class="dx-list-boxed">
                        <adapt-meeting-dashboard-card *dxTemplate="let meeting of 'item'"
                                                      [selected]="selectedMeeting === meeting"
                                                      [meeting]="meeting"
                                                      [showCard]="false"
                                                      [highlightPreWork]="false"
                                                      [preWorkClickable]="false"
                                                      [notSelectable]="false"
                                                      (elementSelected)="selectedMeetingElement = $event"
                                                      (meetingStarted)="selectedMeeting = meeting"></adapt-meeting-dashboard-card>
                    </dx-list>
                </adapt-dashboard-element>

                <adapt-dashboard-element name="Past Meetings"
                                         *ngIf="endedMeetings.length > 0">
                    <dx-list [dataSource]="endedMeetings"
                             selectionMode="single"
                             (onInitialized)="endedMeetingList = $event.component"
                             (onSelectionChanged)="onSelectionChanged($event)"
                             (onContentReady)="updateMeetingListSelection()"
                             [focusStateEnabled]="false"
                             class="dx-list-boxed">
                        <adapt-meeting-dashboard-card *dxTemplate="let meeting of 'item'"
                                                      [selected]="selectedMeeting === meeting"
                                                      [meeting]="meeting"
                                                      [showCard]="false"
                                                      [highlightPreWork]="false"
                                                      [preWorkVisible]="false"
                                                      [notSelectable]="false"
                                                      (elementSelected)="selectedMeetingElement = $event"
                                                      [preWorkClickable]="false"></adapt-meeting-dashboard-card>
                    </dx-list>
                </adapt-dashboard-element>
            </dx-scroll-view>
        </div>
    </div>
    <ng-template #noMeeting>
        <adapt-call-to-action learnMoreUrl="https://support.adaptbydesign.com.au/teams/good-team-stewardship">
            <adapt-call-to-action-title>Team Meetings</adapt-call-to-action-title>

            <p>
                A good meeting will have clear guidance. As part of this, facilitate the team to determine what that is and capture on the
                team dashboard. For each meeting type, they need clarity on the purpose of the meeting.
            </p>

            <p>
                The frequency and duration of the meeting will clarify an appropriate cadence and time to devote to the meeting. Guide the
                team on what is best for each meeting type and try not to let them shorten the meetings – each is really important.
            </p>

            <!-- Don't have to check for meetings feature as this page won't be loaded without it -->
            <button adaptCallToActionButton
                    *adaptIfAuthorised="editTeamKanban; Entity:team"
                    adaptButtonIcon="add"
                    [adaptBlockingClick]="createTeamMeeting">
                Add Team Meeting
            </button>
        </adapt-call-to-action>
    </ng-template>
</ng-container>

<adapt-edit-strategic-goal-base [goal]="goal"
                                [guidanceArticleId]="GuidanceArticleId">
    <h3>{{goal?.name}}</h3>

    <div class="mt-3 d-flex flex-column">
        <label>Unit <i class="fa fa-circle-info"
               adaptTooltip="What we are measuring"></i></label>
        <dx-select-box [dataSource]="existingUnits"
                       [value]="goal!.unit"
                       (valueChange)="onUnitValueChanged($event)"
                       [searchEnabled]="true"
                       [acceptCustomValue]="true"
                       fieldTemplate="field"
                       [width]="200">
            <div *dxTemplate="let unit of 'field'">
                <dx-text-box [value]="unit"
                             [maxLength]="16"></dx-text-box>
            </div>
        </dx-select-box>
    </div>

    <div class="mt-3 d-flex flex-column"
         *ngIf="targets.length > 1">
        <div class="d-flex flex-column flex-fill">
            <div *ngFor="let target of targets; let first = first; let last = last; let index = index"
                 class="me-2">
                <div *ngIf="first; else targetWaypoint"
                     class="d-flex border-bottom pb-2">
                    <div class="value-column">
                        <label>Where we are starting</label>
                        <dx-number-box format="decimal"
                                       [step]="0.1"
                                       [(value)]="target.value"
                                       (valueChange)="updateGraph()"></dx-number-box>
                    </div>
                    <div class="date-column">
                        <label>Date</label>
                        <dx-date-box type="date"
                                     pickerType="calendar"
                                     displayFormat="MM/yyyy"
                                     format="MM/yyyy"
                                     [value]="target.timestamp"
                                     (valueChange)="onTargetDateChanged(target, $any($event))"
                                     [adaptValidateEntity]="target"
                                     adaptValidateEntityProperty="timestamp"></dx-date-box>
                    </div>
                    <i class="ms-2 fal fa-fw"></i>
                </div>
                <ng-template #targetWaypoint>
                    <div *ngIf="!last; else finalTarget"
                         class="d-flex mt-2 align-items-end ms-3">
                        <div class="value-column">
                            <label *ngIf="index === 1">Milestones</label>
                            <dx-number-box format="decimal"
                                           [step]="0.1"
                                           [(value)]="target.value"
                                           (valueChange)="updateGraph()"></dx-number-box>
                        </div>
                        <div class="date-column">
                            <label *ngIf="index === 1">Date</label>
                            <dx-date-box type="date"
                                         pickerType="calendar"
                                         displayFormat="MM/yyyy"
                                         format="MM/yyyy"
                                         [value]="target.timestamp"
                                         [min]="minTargetTimestamp"
                                         [max]="maxTargetTimestamp"
                                         (valueChange)="onTargetDateChanged(target, $any($event))"
                                         [adaptValidateEntity]="target"
                                         adaptValidateEntityProperty="timestamp"></dx-date-box>
                        </div>
                        <button adaptButton="deleteBorderless"
                                class="mb-2"
                                adaptTooltip="Delete this waypoint"
                                (click)="deleteTarget(target)"></button>
                    </div>
                </ng-template>
                <ng-template #finalTarget>
                    <div class="my-2 ms-2">
                        <button adaptButton="addBorderless"
                                [adaptBlockingClick]="addTarget">Add Milestone</button>
                    </div>
                    <div class="d-flex mt-2 align-items-end border-top pt-2">
                        <div class="value-column">
                            <label>Where we want to be</label>
                            <dx-number-box format="decimal"
                                           [step]="0.1"
                                           [(value)]="target.value"
                                           (valueChange)="updateGraph()"></dx-number-box>
                        </div>
                        <div class="date-column">
                            <label>Date</label>
                            <dx-date-box type="date"
                                         pickerType="calendar"
                                         displayFormat="MM/yyyy"
                                         format="MM/yyyy"
                                         [min]="minFinalTarget"
                                         [value]="target.timestamp"
                                         (valueChange)="onTargetDateChanged(target, $any($event))"
                                         [adaptValidateEntity]="target"
                                         adaptValidateEntityProperty="timestamp"></dx-date-box>
                        </div>
                        <i class="ms-2 fal fa-fw"></i>
                    </div>
                </ng-template>
            </div>
        </div>

        <!-- Steve said not to show this before milestone is added -->
        <adapt-goal-measurements-graph #measurementsGraph
                                       *ngIf="targets.length > 2"
                                       class="mt-3 pt-3 border-top"
                                       [goal]="goal"
                                       [isEditing]="true"></adapt-goal-measurements-graph>
    </div>
</adapt-edit-strategic-goal-base>

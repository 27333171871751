<dx-list class="configure-boards-list"
         [dataSource]="boards"
         (onItemReordered)="onItemReordered($event)">
    <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>
    <div *dxTemplate="let board of 'item'">
        <div class="configure-boards-item"
             data-test="configure-boards-item">
            <div class="board-name"
                 data-test="board-name">
                {{board.name}}
            </div>

            <div class="board-prefix-permission">
                <div class="board-prefix">
                    <span adaptTooltip="Actions begin with the prefix {{board.itemPrefix}}-"
                          class="badge bg-primary">{{board.itemPrefix}}</span>
                </div>
                <div class="board-permission">
                    <span *ngIf="!board.isPublicReadAccess"
                          adaptTooltip="Actions are {{board.isPersonalBoard ? 'only visible to and editable by you' : 'private to the team'}}"
                          class="badge bg-warning">
                        <i class="fal fa-fw fa-lock"></i>
                    </span>
                    <span *ngIf="board.isPublicReadAccess && !board.isPublicWriteAccess"
                          adaptTooltip="Actions are visible to but not editable by the public"
                          class="badge bg-warning">
                        <i class="fal fa-fw fa-eye"></i>
                    </span>
                    <span *ngIf="board.isPublicWriteAccess"
                          adaptTooltip="Actions are visible to and editable by the public"
                          class="badge bg-warning">
                        <i class="fal fa-fw fa-pen-square"></i>
                    </span>
                </div>
            </div>

            <div class="board-actions">
                <button adaptButton="editBorderless"
                        adaptTooltip="Edit board"
                        [adaptBlockingClick]="editBoard"
                        [adaptBlockingClickParam]="board"
                        data-test="edit-board">
                </button>
                <button adaptButton="deleteBorderless"
                        adaptTooltip="Delete board"
                        [adaptBlockingClick]="removeBoard"
                        [adaptBlockingClickParam]="board"
                        data-test="delete-board">
                </button>
            </div>
        </div>
    </div>
</dx-list>
<button class="mt-2"
        adaptButton="add"
        [adaptBlockingClick]="addBoard"
        data-test="add-board">board</button>

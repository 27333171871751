import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDocumentSelectorModule } from "@common/ux/document-selector/document-selector.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { DxButtonGroupModule, DxListModule, DxSortableModule, DxTextBoxModule } from "devextreme-angular";
import { CreateValueBehavioursComponent } from "./create-value-behaviours/create-value-behaviours.component";
import { CreateValueMeaningComponent } from "./create-value-meaning/create-value-meaning.component";
import { CreateValueNameComponent } from "./create-value-name/create-value-name.component";
import { CreateValueReviewComponent } from "./create-value-review/create-value-review.component";


@NgModule({
    declarations: [
        CreateValueNameComponent,
        CreateValueMeaningComponent,
        CreateValueBehavioursComponent,
        CreateValueReviewComponent,
    ],
    imports: [
        CommonModule,

        AdaptEntityValidatorModule,
        AdaptHtmlEditorModule,
        AdaptTooltipModule,
        AdaptValuesConstitutionModule,
        AdaptButtonModule,
        AdaptDocumentSelectorModule,

        DxTextBoxModule,
        DxButtonGroupModule,
        DxListModule,
        DxSortableModule,
    ],
})
export class AdaptCreateValueWorkflowModule {
    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        CreateValueNameComponent,
        CreateValueMeaningComponent,
        CreateValueBehavioursComponent,
        CreateValueReviewComponent,
    ];
}

import { Role as RoleEntity } from "@common/ADAPT.Common.Model/organisation/role";
import { Team as TeamEntity } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { IDuration } from "@common/ux/duration-selector";

export enum SearchType {
    // Remote (search API)
    Kanban,
    Meeting,
    Objective,
    System,
    Tier1,
    Guidance,

    // Local
    Page,
    ImplementationKit,
    People,
    Role,
    Team,
}

export interface ISearchOptions {
    keyword: string | undefined;
    types: Set<SearchType>;
    labelIds?: Set<number>;
    activeOnly?: boolean;
    personId?: number;
    teamId?: number;
    updatedSince?: IDuration;
}

export interface ISearchProviderOptions extends ISearchOptions {
    keyword: string;
}

export interface ISearchApiParams extends Omit<ISearchProviderOptions, "types" | "labelIds" | "updatedSince"> {
    organisationId: number;
    types: SearchType[];
    labelIds: number[];
    updatedSince?: Date;
}

export interface ISearchResultMatch {
    Field: string[];
    Snippet: string;
    Extra?: { [k: string]: any };
}

export interface IBaseSearchResult {
    Results: ISearchResultMatch[];
}

export interface ILabelledSearchResult {
    LabelLocationIds: number[];
}

export interface IKanbanSearchResult extends IBaseSearchResult, ILabelledSearchResult {
    ItemId: number;
    BoardId: number;
    TeamId: number | null;
    AssigneeId: number | null;
    Code: string;
    Summary: string;
    Status: number;
}

export interface IMeetingSearchResult extends IBaseSearchResult {
    MeetingId: number;
    TeamId: number;
    Name: string;
    StartTime: string;
    EndTime: string;
}

export interface IObjectiveSearchResult extends IBaseSearchResult, ILabelledSearchResult {
    ObjectiveId: number;
    TeamId: number | null;
    AssigneePersonId: number;
    Title: string;
    Type: number;
}

export interface ISystemSearchResult extends IBaseSearchResult, ILabelledSearchResult {
    SystemEntityId: number;
    Name: string;
}

export interface IValueStreamSearchResult extends IBaseSearchResult {
    ValueStreamId: number;
    Name: string;
}

export interface IProductServiceSearchResult extends IBaseSearchResult {
    ProductId: number;
    Name: string;
}

export interface IKeyFunctionSearchResult extends IBaseSearchResult, ILabelledSearchResult {
    KeyFunctionId: number;
    Zone: number | null;
    Name: string;
}

export enum GuidanceType {
    PurposeVision,
    Value,
    ResilientBusinessGoal
}

export interface IGuidanceSearchResult extends IBaseSearchResult {
    Type: GuidanceType;
}

export interface IValueSearchResult extends IGuidanceSearchResult {
    Name: string | null;
    TeamId: number | null;
}

export interface IResilientBusinessGoalSearchResult extends IGuidanceSearchResult {
    Zone: number;
}

export interface IPurposeVisionSearchResult extends IGuidanceSearchResult {
    TeamId: number | null;
}

export interface IPageSearchResult extends IBaseSearchResult {
    Node: INavigationNode;
}

export interface IHelpJuiceSearchResult extends IBaseSearchResult {
    name: string;
    answer_sample: string;
    long_answer_sample: string;
    host: string;
    url: string;
    parent_categories: number[];
    categories_display_for_views: string;
}

export interface IRoleSearchResult extends IBaseSearchResult {
    role: RoleEntity;
}

export type ITier1Result = IValueStreamSearchResult | IProductServiceSearchResult | IKeyFunctionSearchResult;
export type IGuidanceResult = IPurposeVisionSearchResult | IResilientBusinessGoalSearchResult | IValueSearchResult;

export interface ISearchResults {
    Kanban?: IKanbanSearchResult[];
    Meeting?: IMeetingSearchResult[];
    Objective?: IObjectiveSearchResult[];
    System?: ISystemSearchResult[];
    Tier1?: ITier1Result[];
    Guidance?: IGuidanceResult[];
    Page?: IPageSearchResult[];
    ImplementationKit?: IHelpJuiceSearchResult[];
    People?: Person[];
    Role?: IRoleSearchResult[];
    Team?: TeamEntity[];
}

export interface ISearchGroup {
    value: SearchType | SearchType[];
    allValues?: SearchType[];
    name: string;
    icon: string;
    remote: boolean;
}

<adapt-collapsible-search-row #row
                              [result]="result"
                              [matches]="matches"
                              [href$]="href$">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 me-2 align-items-center text-muted">
            <div class="d-flex align-items-center result-icon vs-icon"
                 adaptTooltip="Value">
                <i class="fal fa-hand-holding-seedling fa-inverse"></i>
            </div>
            <div class="ms-2">
                <adapt-link-team *ngIf="result.TeamId; else orgText"
                                 class="me-2"
                                 [teamId]="result.TeamId"
                                 [labelLinkDisabled]="true"></adapt-link-team>
                <ng-template #orgText>
                    <span class="me-2">Organisation</span>
                </ng-template>
                &gt;
            </div>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="result.Name"></adapt-highlighted-text>
        </div>
    </div>
</adapt-collapsible-search-row>

import { Component, DoCheck, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IActiveEntityBase } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { ToolbarService } from "@common/shell/toolbar/toolbar.service";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-toolbar-inactive",
    templateUrl: "./toolbar-inactive.component.html",
})
export class ToolbarInactiveComponent extends BaseComponent implements OnChanges, DoCheck {
    @Input() public entity?: IActiveEntityBase;
    @Input() public type?: string;

    // assume entites are active by default, and as its likely that the entity will arrive from a remote datasource, it could take some time to arrive.
    // we dont want to indicate to the user that it is inactive, when in fact we dont really know yet.
    public isActive = true;

    public constructor(
        private toolbarService: ToolbarService,
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.entity) {
            this.checkIsActive();
        }
    }

    public ngDoCheck() {
        this.checkIsActive();
    }

    private checkIsActive() {
        // if the entity is not set yet, assume active, otherwise test the activeness
        const isNowActive = !this.entity || this.entity.isActive();
        if (isNowActive !== this.isActive) {
            this.isActive = isNowActive;

            this.toolbarService.clearToolbarClass();

            if (!this.isActive) {
                this.toolbarService.addToolbarClass("inactive-entity");
            }
        }
    }
}

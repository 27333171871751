import { Injectable } from "@angular/core";
import { RouteEventsService } from "@common/route/route-events.service";
import { BehaviorSubject, delay, ReplaySubject, Subscription, take } from "rxjs";
import Shepherd from "shepherd.js";
import Step from "shepherd.js/src/types/step";
import Tour from "shepherd.js/src/types/tour";
import { AdaptCommonDialogService } from "../../ux/adapt-common-dialog/adapt-common-dialog.service";
import { ResponsiveService } from "../../ux/responsive/responsive.service";
import { Logger } from "../logger/logger";
import { FunctionUtilities } from "../utilities/function-utilities";
import { GuidedTourUtils } from "./guided-tour.utils";

type EventType = "click" | "dblclick" | "mousedown" | "mouseup" | "keydown" | "keyup" | "focus" | "blur" | "pointerdown";

interface IRegisteredEvent {
    element: Element;
    type: EventType;
    handler: EventListenerOrEventListenerObject;
}

// we only use exported types from here internally - so that we can replace shepherd.js if quired
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface GuidedTourStepOptions extends Step.StepOptions {
    /**
     * Selector string for document.querySelectorAll
     * This will override the element defined in 'attachTo' with the element once it is available.
     * If this is defined, attachTo.element does not need to be defined (even if it is defined, it will be replaced
     * by the element found from this property definition).
     * This is implemented as Shepherd cannot use span:contains and attach is not waiting for beforeShowPromise finish,
     */
    waitForAndAttachToElementSelector?: string;

    /**
     * Promise to call before waitForAndAttachToElementSelector, since raw beforeShowPromise can't be used here
     */
    beforeWaitForAndAttachToElementPromise?: () => Promise<any>;

    /**
     * if this is not defined, it will be the 1st element found from the above selector
     * otherwise, it will try to match the text content of the element
     */
    elementSelectorTextContent?: string;

    /**
     * This only applies if the waitForAndAttachToElementSelector is defined.
     * The value is the string passed to HTMLElement.addEventListener(value, ...);
     * It will move the tour forward on the event.
     * Value can be one of the event type as defined in MDN addEventListener type.
     *
     * This is added as I cannot use 'advanceOn' which only accepts string selector
     */
    advanceOnAttachedElementEvent?: EventType;

    /**
     * This is to delay moving to the next step after the above event - wait for page activation at least
     */
    waitForPageActivationAfterEvent?: boolean;

    /**
     * This will be called before 'beforeShowPromise'.
     * This, together with beforeShowPromise are used internally by waitForAndAttachToElementSelector property
     * so please make sure these are not defined if waitForAndAttachToElementSelector is used - it will throw an error.
     */
    beforeShown?: (step: GuidedStep) => void;
}

export type GuidedTour = GuidedTourStepOptions[];
export type GuidedStep = Step;
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface StepHandler {
    step: GuidedStep;
    previous?: GuidedStep;
    tour?: Tour;
}

export const DefaultStartButtons = [{
    classes: "btn btn-primary",
    text: "Start",
    action: nextStep,
}];


export const DefaultCancelTourButton = {
    classes: "btn btn-secondary",
    text: "Cancel",
    action: cancelTour,
};

export const DefaultBackTourButton = {
    classes: "btn btn-secondary",
    text: "Back",
    action: previousStep,
};

export const DefaultNextButtons = [{
    classes: "btn btn-primary",
    text: "Next",
    action: nextStep,
}];

export const DefaultBackNextButtons = [DefaultBackTourButton, ...DefaultNextButtons];

export const DefaultFinishButtons = [{
    classes: "btn btn-primary",
    text: "Finish",
    action: completeTour,
}];

export const DefaultOKButtons = [{
    classes: "btn btn-primary",
    text: "OK",
    action: completeTour,
}];

// these are only used for button action where the declaration is: (this: tour) => void
// this is the tour from the context of the call
export function nextStep() {
    this.next();
}

export function previousStep() {
    this.back();
}

export function cancelTour() {
    this.cancel();
}

export function completeTour() {
    this.complete();
}

@Injectable({
    providedIn: "root",
})
export class GuidedTourService {
    private readonly logger = Logger.getLogger("GuidedTour");

    private queuedTours: GuidedTour[] = [];
    private tourInstance?: Shepherd.Tour;
    private stepOptions: GuidedTourStepOptions[] = [];

    private registeredEvents: IRegisteredEvent[] = [];
    private existingSubscriptions: Subscription[] = [];

    private defaultStepOptions: GuidedTourStepOptions = {
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 4,
        classes: "adapt-guided-tour-step-popper", // this is added to override styles for the popper, e.g. set offset
        // buttons: [DefaultCancelTourButton], // with cancelIcon enabled true above, this is not required
    };

    private activeTourSubject = new BehaviorSubject<GuidedTour | undefined>(undefined);
    public readonly activeTour$ = this.activeTourSubject.asObservable();

    constructor(private responsiveService: ResponsiveService,
        private dialogService: AdaptCommonDialogService,
        private routeEventsService: RouteEventsService) {
    }

    public get steps() {
        return this.stepOptions;
    }

    public set steps(value: GuidedTour) {
        this.stepOptions = value;
    }

    public run(steps?: GuidedTour, discardIfActive?: boolean) {
        if (this.responsiveService.currentBreakpoint.isMobileSize) {
            this.dialogService.showMessageDialog("Tour!", "Unable to start tour on mobile devices. The tour can be run only on desktop devices.", "Ok")
                .subscribe();
            return;
        }

        if (this.tourInstance?.isActive()) {
            if (steps) {
                if (!discardIfActive) {
                    this.queuedTours.push(steps);
                } else {
                    this.logger.info("Tour discarded as there is already an ongoing one.", steps);
                }

                return;
            } else {
                throw new Error("There is currently an ongoing tour. Cannot run a service instance tour!");
            }
        }

        if (steps) {
            this.stepOptions = steps;
        }

        if (!this.stepOptions?.length) {
            throw new Error("You will need steps to run a tour!");
        }

        // clone the steps so as postProcessStepOptions will change the content
        const stepOptions = this.stepOptions.map((step) => ({ ...step }));
        stepOptions.forEach((step) => this.postProcessStepOptions(step));

        this.tourInstance = this.newTour();
        this.tourInstance.addSteps(stepOptions);
        this.tourInstance.start();
    }

    private newTour() {
        const tour = new Shepherd.Tour({
            confirmCancel: false,
            defaultStepOptions: this.defaultStepOptions,
            useModalOverlay: true,
        });

        const showHandler = (e: StepHandler) => {
            // can't believe this bloody shepherd is making me do this. The step.when.show is called too late and I can't update the step option anymore
            // when that's called. Can only call it here!
            const stepOptions = e.step.options as GuidedTourStepOptions;
            if (FunctionUtilities.isFunction(stepOptions.beforeShown)) {
                stepOptions.beforeShown(e.step);
            }
        };
        tour.on("start", () => {
            this.activeTourSubject.next(this.stepOptions);

            // when running tour - need to block body, which won't block the opening or button from the tour
            // - this is to stop clicking anywhere in the shepherd popup causing the attached DOM from dxMenu to go away and causing error
            const bodyElement = GuidedTourUtils.getFirstElementWithTextContent("body");
            if (bodyElement) {
                const clickBlocker = (e: PointerEvent) => {
                    e.stopPropagation();
                    e.preventDefault();
                };

                bodyElement.addEventListener("click", clickBlocker);
                bodyElement.addEventListener("pointerdown", clickBlocker);

                // this will make sure the registered events are removed after tour or cancelled
                this.registeredEvents.push({ element: bodyElement, type: "click", handler: clickBlocker });
                this.registeredEvents.push({ element: bodyElement, type: "pointerdown", handler: clickBlocker });
            }
        });
        tour.on("show", showHandler);
        tour.on("inactive", () => {
            // this is triggered when cancelled or completed, which still leave the handler still intact
            // - shepherd not cleaning it up causing handler still active in inactive tour!
            tour.off("show", showHandler);

            this.activeTourSubject.next(undefined);

            // clean up all registered events that we registered in beforeShown
            this.registeredEvents.forEach((e) => e.element.removeEventListener(e.type, e.handler));
            this.registeredEvents = [];

            this.existingSubscriptions.forEach((s) => s.unsubscribe());
            this.existingSubscriptions = [];

            if (this.queuedTours.length > 0) {
                const nextTour = this.queuedTours.shift();
                if (nextTour) {
                    this.run(nextTour);
                }
            }
        });

        return tour;
    }

    private postProcessStepOptions(stepOptions: GuidedTourStepOptions) {
        if (stepOptions.waitForAndAttachToElementSelector && (stepOptions.beforeShown || stepOptions.beforeShowPromise)) {
            this.logger.warn("Dev error: if you use waitForAndAttachToElementSelector, beforeShown and beforeShownPromise not have any effect");
        }

        if (!stepOptions.waitForAndAttachToElementSelector && stepOptions.beforeWaitForAndAttachToElementPromise) {
            this.logger.warn("Dev error: beforeWaitForAndAttachToElementPromise has no effect if not using waitForAndAttachToElementSelector");
        }

        if (stepOptions.waitForAndAttachToElementSelector) {
            // note: can't use when: { show } from existing shepherd step options as that's called too late, i.e. after the step
            // is shown. At that time, cannot change the step options anymore and the already popped popper certainly won't move
            // or change attach element even if the options are changed and updated.

            stepOptions.beforeShowPromise = async () => {
                if (stepOptions.beforeWaitForAndAttachToElementPromise) {
                    await stepOptions.beforeWaitForAndAttachToElementPromise();
                }
                return GuidedTourUtils.waitForElementWithTextContentToBeVisible(
                    stepOptions.waitForAndAttachToElementSelector!,
                    stepOptions.elementSelectorTextContent, // this is optional - if not defined -> 1st element
                    100, // before resolving the promise, wait for 100msec so that beforeShown has enough time to override the options
                ).catch((e) => this.logger.log("Error from beforeShowPromise, which has already been handled by before shown: ", e));
            };
            stepOptions.beforeShown = async (step: GuidedStep) => {
                let errorMessage = "";
                if (stepOptions.beforeWaitForAndAttachToElementPromise) {
                    await stepOptions.beforeWaitForAndAttachToElementPromise();
                }
                await GuidedTourUtils.waitForElementWithTextContentToBeVisible(
                    stepOptions.waitForAndAttachToElementSelector!,
                    stepOptions.elementSelectorTextContent,
                    10, // this is less than the beforeShowPromise to have this resolve first and override the option before step is show
                ).catch((e) => {
                    this.logger.error("Error from guided-tour: ", e);
                    errorMessage = e;
                });
                const element = GuidedTourUtils.getFirstElementWithTextContent(
                    stepOptions.waitForAndAttachToElementSelector!,
                    stepOptions.elementSelectorTextContent);
                if (element) {
                    step.options.attachTo!.element = element as HTMLElement;
                    step.updateStepOptions(step.options);

                    // cannot use 'advanceOn' too as the selector is only accepting string selector - cannot pass in element like attachTo
                    // so if this property allows registration of event to move the tour forward
                    if (stepOptions.advanceOnAttachedElementEvent) {
                        const waitForPageActivation = new ReplaySubject<boolean>(1);
                        const elementClicked = () => {
                            element.removeEventListener(stepOptions.advanceOnAttachedElementEvent!, elementClicked);

                            // listener already removed above -> remove from the registered event list
                            let removeRegisteredEventIndex = -1;
                            this.registeredEvents.some((e, index) => {
                                if (e.element === element) {
                                    removeRegisteredEventIndex = index;
                                    return true;
                                }

                                return false;
                            });
                            if (removeRegisteredEventIndex >= 0) {
                                this.registeredEvents.splice(removeRegisteredEventIndex, 1);
                            }

                            if (!stepOptions.waitForPageActivationAfterEvent) {
                                waitForPageActivation.next(true); // unblock immediate; otherwise will be unblocked by page activation
                            }

                            waitForPageActivation.pipe(
                                take(1),
                            ).subscribe(() => step.getTour().next());
                        };

                        if (stepOptions.waitForPageActivationAfterEvent) {
                            this.existingSubscriptions.push(
                                this.routeEventsService.componentActivated$.pipe(
                                    take(1),
                                    delay(500), // half a sec delay for the page to render before processing shownOn
                                ).subscribe(() => waitForPageActivation.next(true)));
                        }

                        element.addEventListener(stepOptions.advanceOnAttachedElementEvent, elementClicked);
                        this.registeredEvents.push({
                            element,
                            type: stepOptions.advanceOnAttachedElementEvent,
                            handler: elementClicked,
                        });
                    }
                } else {
                    // this is when the error occured -> change the step title, text and button
                    step.options.attachTo = undefined;
                    step.options.title = "Error Occurred";
                    step.options.text = `<p>The follow error has been caught while running the tour:</p>
                        <p>${errorMessage}</p>
                        <p>The tour cannot continue. Please click on the Cancel button to close the tour.</p>`;
                    step.options.buttons = [DefaultCancelTourButton];
                    step.updateStepOptions(step.options);
                }
            };
        }
    }
}

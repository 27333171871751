import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { RoleInOrganisation } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { DefaultRoleLabel, Role } from "@common/ADAPT.Common.Model/organisation/role";
import { IAddPeopleBindingModel, IAddPeopleConfig } from "@common/identity/add-people-binding-model.interface";
import { IUserDetailBindingModel } from "@common/identity/user-detail-binding-model.interface";
import { setInitialPasswordPageRoute } from "@common/identity/ux/set-initial-password-page/set-initial-password-page.route";
import { PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { RouteService } from "@common/route/route.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import { DxValidationGroupComponent } from "devextreme-angular";
import { WorkflowIdentifier } from "../../workflows/workflow-identifier.enum";
import { personalDashboardPageRoute } from "../personal-dashboard-page/personal-dashboard-page.route";

export interface IAddPeople {
    firstName: string
    lastName: string;
    email: string;
    roleType: RoleInOrganisation;
}

@Component({
    selector: "adapt-add-people",
    templateUrl: "./add-people.component.html",
})
export class AddPeopleComponent implements OnInit {
    @Input() public config?: IAddPeopleConfig;

    @Output() public isValid = new EventEmitter<boolean>();
    @Output() public addPeopleBindingModelChanged = new EventEmitter<IAddPeopleBindingModel>();

    @ViewChild("validationGroup") private validationGroup?: DxValidationGroupComponent;

    public people: IAddPeople[] = [
        { firstName: "", lastName: "", email: "", roleType: RoleInOrganisation.Leader },
    ];

    private referenceData?: { welcomeRedirectUrl: string, passwordRedirectUrl: string, accessLevel: Role | undefined };

    public constructor(
        private organisationService: OrganisationService,
        private routeService: RouteService,
        private commonUserManagementService: UserManagementService,
    ) { }

    public async ngOnInit() {
        this.referenceData = await PromiseUtilities.fromObject({
            welcomeRedirectUrl: this.routeService.getControllerRoute(personalDashboardPageRoute.id),
            passwordRedirectUrl: this.routeService.getControllerRoute(setInitialPasswordPageRoute.id),
            accessLevel: this.commonUserManagementService.promiseToGetAccessLevelRoleWithLabel(UserType.Leader, DefaultRoleLabel.LeaderAdministrator),
        });
    }

    public validateGroup() {
        this.isValid.next(!!this.validationGroup?.instance.validate().isValid);
        this.emitPeopleViewModel();
    }

    public addNewRow() {
        this.people.push({ firstName: "", lastName: "", email: "", roleType: RoleInOrganisation.Leader });
        setTimeout(() => {
            this.validateGroup();
        });
    }

    public removeLastRow() {
        this.people = this.people.slice(0, -1);
        setTimeout(() => {
            this.validateGroup();
        });
    }

    private emitPeopleViewModel() {
        const addPeopleViewModel: IAddPeopleBindingModel = {
            organisationId: this.organisationService.getOrganisationId(),
            setInitialPasswordRedirectUrl: this.setUrlBase(this.referenceData!.passwordRedirectUrl, true),
            welcomeRedirectUrl: this.setUrlBase(this.referenceData!.welcomeRedirectUrl),
            workflowId: WorkflowIdentifier.WelcomeOnboarding,
            teamId: this.config?.teamId,
            userDetails: this.people.map((p) => {

                const roleIds: number[] = [...(this.config?.roleIds ?? [])];
                if (this.referenceData!.accessLevel) {
                    roleIds.push(this.referenceData!.accessLevel.roleId);
                }

                return {
                    firstName: p.firstName,
                    lastName: p.lastName,
                    email: p.email,
                    roleIds,
                    hasAccess: true,
                    connectionType: ConnectionType.Employee,
                    userType: UserType.Leader,
                    roleInOrganisation: p.roleType,
                } as IUserDetailBindingModel;
            }),
        };

        this.addPeopleBindingModelChanged.emit(addPeopleViewModel);
    }

    private setUrlBase(url: string, stripQuery = false) {
        const parsedUrl = new URL(url, window.location.href);
        if (stripQuery) {
            parsedUrl.search = "";
        }
        return parsedUrl.href;
    }
}

import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class HtmlEditorService {
    private froalaActionCallbacks: VoidFunction[] = [];

    /**
     * Register a custom action (toolbar button) that will be used when Froala is eventually initialised.
     * @param callback The callback function that will build the Froala action.
     */
    public registerEditorActionCallback(callback: VoidFunction) {
        this.froalaActionCallbacks.push(callback);
    }

    /**
     * Called by the froala integration service once only to register any callbacks into Froala
     */
    public initialiseRegisteredActionCallbacks() {
        this.froalaActionCallbacks.forEach((service) => service());
    }
}

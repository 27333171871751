<div *ngIf="recurrence && recurrence.eventSeries.eventType"
     class="card d-inline-block">
    <div class="card-body">
        <div>
            <ng-container *ngIf="recurrence.eventSeries.eventSeriesType !== EventSeriesType.Once; else singleEvent">
                {{recurrence.eventSeries.eventType.code}}'s to be done every
                <ng-container *ngFor="let field of recurrence.config.fields">
                    <ng-container *ngIf="field.prefix">{{field.prefix}}</ng-container>
                    <div class="d-inline-flex flex-column align-items-center mx-2">
                        <dx-select-box [items]="field.choices"
                                       [width]="field.width ?? 150"
                                       [disabled]="disabled"
                                       class="d-inline-block"
                                       displayExpr="text"
                                       valueExpr="value"
                                       [value]="recurrence.eventSeries[field.eventSeriesField]"
                                       (valueChange)="updateField(field, $event)"></dx-select-box>
                        <small>{{field.label}}</small>
                    </div>
                    <ng-container *ngIf="field.suffix">{{field.suffix}}</ng-container>
                </ng-container>
            </ng-container>
            <ng-template #singleEvent>
                {{recurrence.eventSeries.eventType.code}} to be done on
                <div class="d-inline-flex flex-column align-items-center mx-2">
                    <dx-date-box type="date"
                                 class="d-inline-block"
                                 [disabled]="disabled"
                                 [min]="today"
                                 [value]="recurrence.eventSeries.startDate"
                                 (valueChange)="setSeriesStartDate($any($event))"></dx-date-box>
                    <span>date</span>
                </div>
            </ng-template>
        </div>
        <div class="mt-3">
            Starting
            <div class="d-inline-flex flex-column align-items-center mx-2">
                <dx-date-box type="time"
                             class="d-inline-block"
                             pickerType="native"
                             [disabled]="disabled"
                             [value]="recurrence.eventSeries.startDate"
                             (valueChange)="setSeriesStartTime($any($event))"></dx-date-box>
                <span>time</span>
            </div>
            At
            <div class="d-inline-flex flex-column align-items-center mx-2">
                <adapt-select-meeting-location [location]="location"
                                               [potentialLocations]="potentialLocations"
                                               [required]="false"
                                               [disabled]="disabled"
                                               (locationChange)="onLocationChange($event)"></adapt-select-meeting-location>
                <span>location</span>
            </div>
        </div>
    </div>

    <div class="card-footer">
        <div class="row">
            <div class="col-4 text-center">
                Duration: {{duration}}
            </div>
            <div *ngIf="upcomingTimes as times"
                 [adaptTooltip]="times[1]"
                 class="col text-center">
                Scheduled dates: {{times[0]}}
            </div>
        </div>
    </div>
</div>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptStorageModule } from "@common/storage/storage.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { ContentWithSplashImageModule } from "@common/ux/content-with-splash-image/content-with-splash-image.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDashboardElementHqModule } from "@common/ux/dashboard-element-hq/dashboard-element-hq.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptProgressBarModule } from "@common/ux/progress-bar/progress-bar.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptSteppedProgressBarModule } from "@common/ux/stepped-progress-bar/stepped-progress-bar.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptCommonWorkflowModule } from "@common/workflow/workflow.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { DxCheckBoxModule, DxDataGridModule, DxListModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule, DxTooltipModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { DisplayWorkflowActivityBriefComponent } from "./display-workflow-activity-brief/display-workflow-activity-brief.component";
import { DisplayWorkflowCardComponent } from "./display-workflow-card/display-workflow-card.component";
import { DisplayWorkflowCategoryComponent } from "./display-workflow-category/display-workflow-category.component";
import { DisplayWorkflowOutcomeHeaderComponent } from "./display-workflow-outcome-header/display-workflow-outcome-header.component";
import { LinkWorkflowComponent } from "./link-workflow/link-workflow.component";
import { ShowWorkflowCompletionStatusComponent } from "./show-workflow-completion-status/show-workflow-completion-status.component";
import { WorkflowAuthService } from "./workflow-auth.service";
import { WorkflowCardActionComponent } from "./workflow-card-action/workflow-card-action.component";
import { WorkflowConfirmDialogComponent } from "./workflow-confirm-dialog/workflow-confirm-dialog.component";
import { WorkflowJourneyPageComponent, WorkflowJourneyPageRoute } from "./workflow-journey-page/workflow-journey-page.component";
import { WorkflowJourneyWrapUpCardComponent } from "./workflow-journey-page/workflow-journey-wrap-up-card/workflow-journey-wrap-up-card.component";
import { WorkflowMapDashboardElementComponent } from "./workflow-map-dashboard-element/workflow-map-dashboard-element.component";
import { WorkflowMapStatusFilterComponent } from "./workflow-map-filter/workflow-map-status-filter.component";
import { WorkflowRunDialogComponent } from "./workflow-run-dialog/workflow-run-dialog.component";
import { WorkflowsDashboardElementComponent } from "./workflows-dashboard-element/workflows-dashboard-element.component";
import { WorkflowsPageComponent, WorkflowsPageRoute } from "./workflows-page/workflows-page.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptCommonRouteModule,
        AdaptAuthorisationModule,
        AdaptButtonModule,
        AdaptCommonDialogModule,
        AdaptCommonWorkflowModule,
        AdaptDateModule,
        AdaptHtmlEditorModule,
        AdaptImplementationKitModule,
        AdaptLoadingSpinnerModule,
        AdaptProgressBarModule,
        AdaptSteppedProgressBarModule,
        AdaptStorageModule,
        AdaptToolbarModule,
        AdaptTooltipModule,
        AdaptDashboardElementModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptLinkDisabledModule,
        AdaptSeeMoreModule,
        ContentWithSplashImageModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxListModule,
        DxScrollViewModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxValidationGroupModule,
        DxValidatorModule,
        CollapseModule,
        DxTooltipModule,
        DxPopupModule,
        DxTextAreaModule,
        AdaptDashboardElementHqModule,
    ],
    declarations: [
        WorkflowRunDialogComponent,
        WorkflowConfirmDialogComponent,
        DisplayWorkflowCardComponent,
        WorkflowJourneyPageComponent,
        WorkflowJourneyWrapUpCardComponent,
        LinkWorkflowComponent,
        WorkflowsPageComponent,
        ShowWorkflowCompletionStatusComponent,
        WorkflowMapDashboardElementComponent,
        DisplayWorkflowCategoryComponent,
        WorkflowMapStatusFilterComponent,
        DisplayWorkflowOutcomeHeaderComponent,
        WorkflowsDashboardElementComponent,
        DisplayWorkflowActivityBriefComponent,
        WorkflowCardActionComponent,
    ],
    exports: [
        DisplayWorkflowCardComponent,
        LinkWorkflowComponent,
        WorkflowMapDashboardElementComponent,
        WorkflowsDashboardElementComponent,
        WorkflowCardActionComponent,
    ],
    providers: [
        ConfigurationService,
    ],
})
export class AdaptWorkflowModule {
    public static readonly Routes = [
        ...WorkflowJourneyPageRoute.routes,
        ...WorkflowsPageRoute.routes,
    ];

    public readonly workflowStepComponents = [
        DisplayWorkflowActivityBriefComponent,
    ];

    constructor(authorisationService: AuthorisationService) {
        WorkflowAuthService.registerAccess(authorisationService);
    }
}

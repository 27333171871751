<div *ngIf="currentSurvey"
     [ngClass]="templateStyleClass">
    <div class="card"
         data-test="survey-analysis-overview">
        <div class="card-header d-flex">
            <h2 class="card-title align-self-center">{{currentSurvey!.name}}</h2>
        </div>
        <div class="card-block m-3 d-flex flex-column">
            <span class="mb-3">{{currentSurvey!.startTime | adaptDate}} &mdash; {{currentSurvey!.endTime | adaptDate}} </span>
            <adapt-display-survey-result *ngIf="currentSurvey!.surveyResults.length === 1; else showMultiGroupSurveyResult"
                                         [hideDate]="true"
                                         [hideName]="true"
                                         [survey]="currentSurvey"></adapt-display-survey-result>
            <ng-template #showMultiGroupSurveyResult>
                <adapt-display-multi-group-survey-result [survey]="currentSurvey"></adapt-display-multi-group-survey-result>
            </ng-template>
            <div class="mt-5"
                 *ngIf="currentSurvey!.surveyResults.length === 1">
                <adapt-display-categories-summary *ngIf="surveyQuestions?.categoryIds; else noCategories"
                                                  [survey]="currentSurvey"
                                                  [surveyQuestions]="surveyQuestions"></adapt-display-categories-summary>
                <ng-template #noCategories>
                    <adapt-display-survey-category-chart [survey]="currentSurvey"></adapt-display-survey-category-chart>
                </ng-template>
            </div>
        </div>
    </div>

    <ng-container *ngIf="currentSurvey!.surveyResults.length > 1 && surveyQuestions?.categoryIds">
        <div class="card print-break-after">
            <div class="card-header">
                <h2 class="card-title">Scores by {{getAnalyseTargetLabel(currentSurvey!.surveyType)}}</h2>
            </div>
            <div class="card-block m-3">
                <adapt-display-categories-summary [survey]="currentSurvey"
                                                  [showTitle]="false"
                                                  [surveyQuestions]="surveyQuestions"></adapt-display-categories-summary>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!surveyQuestions?.categoryIds; else analyseCategories">
        <div class="card print-break-after"
             *ngFor="let questionId of questionIds"
             data-test="question-analysis">
            <div class="card-header">
                <h2 class="card-title">Question {{questionId}}</h2>
            </div>
            <div class="card-block m-3">
                <adapt-display-survey-question-stats [survey]="currentSurvey"
                                                     [maxResponseCount]="maxResponseCounts[questionId]"
                                                     (maxResponseCountChange)="onMaxResponseCountChanged($event, questionId)"
                                                     [surveyQuestions]="surveyQuestions"
                                                     [questionId]="questionId"></adapt-display-survey-question-stats>
            </div>
        </div>
    </ng-container>
    <ng-template #analyseCategories>
        <div class="card print-break-after"
             *ngFor="let categoryId of surveyQuestions?.categoryIds">
            <div class="card-header">
                <h2 class="card-title mt-3">{{surveyQuestions?.getCategory(categoryId)!.categoryName}}</h2>
            </div>
            <div class="card-block m-3">
                <adapt-display-category-questions [survey]="currentSurvey"
                                                  [surveyQuestions]="surveyQuestions"
                                                  [otherSummaryHeight]="otherCategorySummaryHeights[categoryId]"
                                                  (summaryHeightChange)="categorySummaryHeightChanged(categoryId, $event)"
                                                  [categoryId]="categoryId"
                                                  [forceInitialise]="forceInitialise"></adapt-display-category-questions>
            </div>
        </div>
    </ng-template>
</div>

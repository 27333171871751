<dx-select-box class="h-100"
               [ngClass]="{'transparent-background': useTransparentBackground}"
               fieldTemplate="field"
               [width]="useTransparentBackground ? 100 : 128"
               [showDropDownButton]="!useTransparentBackground"
               [items]="OrderedGoalStatuses"
               [displayExpr]="statusDisplayExpr"
               [value]="status"
               (valueChange)="statusChange.emit($event)">
    <div *dxTemplate="let status of 'item'"
         class="d-flex"
         [ngClass]="{'justify-content-end': useTransparentBackground}">
        <adapt-goal-status [status]="status"></adapt-goal-status>
    </div>
    <div *dxTemplate="let status of 'field'"
         class="align-self-center d-flex mx-2"
         [ngClass]="{'justify-content-end': useTransparentBackground}">
        <adapt-goal-status [status]="status"></adapt-goal-status>
        <dx-text-box class="d-none"
                     value="hidden element required by dxTemplate"
                     [readOnly]="true"></dx-text-box>
    </div>
</dx-select-box>

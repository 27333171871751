<ng-template #noDisplayContent>
    <ng-container *adaptLoading="!isInitialised">
        <div *ngIf="isEditing"
             class="alert alert-secondary restrict-alert-width">
            No items available in this strategic zone. To add items, click on the menu located at the top right corner of this zone.
        </div>
    </ng-container>
</ng-template>

<ng-container *ngIf="hasAnyContent; else noDisplayContent">
    <!-- Need this scroll view around the sortable for dragging if we have more themes within a zone or we can't possibly order those wrapped zone -->
    <dx-scroll-view [scrollByContent]="true"
                    direction="horizontal"
                    *ngIf="isEditing || !includesGoalsView; else readonlyGoalsView">
        <dx-sortable [ngClass]="(isDraggingTheme && isHorizontal)
                        ? 'dragging-horizontal-goals-zone'
                        :  isSingleColumn ? 'goals-zone-single-column' : 'goals-zone'"
                     handle="#categoryGripper"
                     [itemOrientation]="categoryDragOrientation"
                     [data]="orderedThemeNames"
                     [allowDropInsideItem]="false"
                     (onDragStart)="onThemeDragStart($event)"
                     (onDragChange)="preventDragToUncategorisedArea($event)"
                     (onReorder)="reorderThemes($event)">
            <div *ngFor="let theme of orderedThemeNames"
                 class="card category"
                 [ngClass]="{'category-multi-columns': !isSingleColumn, 'category-single-column': isSingleColumn}">
                <h5 class="card-header gap-2">
                    <i *ngIf="isThemeDraggable"
                       class="fal fa-grip-dots-vertical pe-1 gripper"
                       id="categoryGripper"></i>
                    <i [ngClass]="StrategicViewIcon.ThemeIcon"
                       adaptTooltip="Strategic theme"></i>
                    <span>{{theme}}</span>
                    <adapt-display-theme-info *ngIf="!includesThemesView && getThemeByName(theme)?.description"
                                              [theme]="getThemeByName(theme)"></adapt-display-theme-info>
                    <button *ngIf="isEditing && includesThemesView"
                            adaptButton="editBorderless"
                            data-tour="edit-theme"
                            [adaptBlockingClick]="editTheme"
                            [adaptBlockingClickParam]="getThemeByName(theme)"></button>

                    <ng-container *ngIf="getMenuItemsForTheme(theme) as menuItems">
                        <adapt-menu *ngIf="isEditing && menuItems[0].items?.length"
                                    class="ms-auto me-2"
                                    [items]="menuItems"
                                    data-tour="theme-action-menu"></adapt-menu>
                    </ng-container>
                </h5>
                <div class="card-body d-flex flex-column"
                     *ngIf="hasDisplayContent(theme)">
                    <ng-container *ngIf="includesThemesView">
                        <div *ngIf="getThemeByName(theme)?.description as description; else blankDescription"
                             [froalaView]="description"></div>
                        <ng-template #blankDescription>
                            <span>No description. Edit theme to enter a description.</span>
                        </ng-template>
                    </ng-container>
                    <div [ngClass]="{
                                    'categorised-drag-border': isDraggingGoal,
                                    'dragging-into-theme': !isDraggingCategorisedGoal && isDraggingGoal,
                                    'mt-3': includesThemesView}">
                        <!-- This this div outside dx-sortable to avoid having goals sticking to the edge of the drop zone -->

                        <dx-sortable *ngIf="includesGoalsView"
                                     class="d-flex flex-column"
                                     [attr.data-cat-name]="theme"
                                     handle="#goalGripper"
                                     [group]="goalDropGroup"
                                     [data]="categorisedGoals[theme]"
                                     [allowDropInsideItem]="false"
                                     (onDragStart)="onGoalDragStart($event)"
                                     (onDragEnd)="reorderGoal($event)">
                            <ng-container *ngFor="let goal of categorisedGoals[theme]; let first = first">
                                <adapt-strategic-goal class="input-goal"
                                                      [goal]="goal"
                                                      [ngClass]="{'mt-2': !first}"
                                                      [isExpanded]="expandGoals"
                                                      [isDraggable]="isGoalDraggable"
                                                      [isEditing]="isEditing"></adapt-strategic-goal>
                            </ng-container>
                        </dx-sortable>
                    </div>

                    <div *ngIf="includesSWTInputsView && categorisedSWTInputs[theme] as zoneInputLocations"
                         [ngClass]="{'mt-3': hasDisplayContent(theme, StrategicViewOption.SWTInputs),
                            'categorised-drag-border': isDraggingInputTheme === theme && draggingInputType === CanvasType.StrengthsWeaknessesTrends}">
                        <dx-sortable class="d-flex flex-column"
                                     handle="#inputGripper"
                                     [data]="zoneInputLocations"
                                     (onDragStart)="onInputDragStart($event)"
                                     (onDragEnd)="reorderInput($event)">
                            <ng-container *ngFor="let inputLocation of zoneInputLocations; let first = first">
                                <adapt-strategic-input class="input-swt"
                                                       [input]="inputLocation.input"
                                                       [showCanvasNameInContent]="true"
                                                       [isCollapsible]="true"
                                                       [showIcon]="true"
                                                       [isDetachable]="isEditing"
                                                       (detach)="detachSWTInputLocation(inputLocation)"
                                                       [handleId]="(isEditing && zoneInputLocations.length > 1) ? 'inputGripper' : undefined"
                                                       [ngClass]="{'mt-2': !first}"></adapt-strategic-input>
                            </ng-container>
                        </dx-sortable>
                    </div>

                    <div *ngIf="includesCAInputsView && categorisedCAInputs[theme] as zoneInputLocations"
                         [ngClass]="{'mt-3': hasDisplayContent(theme, StrategicViewOption.CAInputs),
                            'categorised-drag-border': isDraggingInputTheme === theme && draggingInputType === CanvasType.CompetitorAnalysis}">
                        <dx-sortable class="d-flex flex-column"
                                     handle="#CAInputGripper"
                                     [data]="zoneInputLocations"
                                     (onDragStart)="onInputDragStart($event)"
                                     (onDragEnd)="reorderInput($event)">
                            <ng-container *ngFor="let inputLocation of zoneInputLocations; let first = first">
                                <adapt-strategic-input class="input-ca"
                                                       [input]="inputLocation.input"
                                                       [showCanvasNameInContent]="true"
                                                       [isCollapsible]="true"
                                                       [showIcon]="true"
                                                       [isDetachable]="isEditing"
                                                       (detach)="detachCAInputLocation(inputLocation)"
                                                       [handleId]="(isEditing && zoneInputLocations.length > 1) ? 'CAInputGripper' : undefined"
                                                       [ngClass]="{'mt-2': !first}"></adapt-strategic-input>
                            </ng-container>
                        </dx-sortable>
                    </div>

                    <div *ngIf="includesBullseyeView && categorisedBullseyeStatements[theme] as zoneBullseyeStatementLocations"
                         [ngClass]="{'mt-3': hasDisplayContent(theme, StrategicViewOption.Bullseye),
                            'categorised-drag-border': isDraggingBullseyeStatementTheme === theme}">
                        <dx-sortable class="d-flex flex-column"
                                     handle="#statementGripper"
                                     [data]="zoneBullseyeStatementLocations"
                                     (onDragStart)="onBullseyeStatementDragStart($event)"
                                     (onDragEnd)="reorderBullseyeStatement($event)">
                            <ng-container *ngFor="let statementLocation of zoneBullseyeStatementLocations; let first = first"
                                          [ngTemplateOutlet]="renderBullseyeStatement"
                                          [ngTemplateOutletContext]="{
                                            first,
                                            statementLocation,
                                            statementCount: zoneBullseyeStatementLocations.length}">
                            </ng-container>
                        </dx-sortable>
                    </div>
                </div>
            </div>
            <div *ngIf="(uncategorisedGoals.length > 0 || isDraggingGoal) && includesGoalsView"
                 class="flex-column category"
                 [ngClass]="{
                    'uncategorised-drag-border': isDraggingGoal,
                    'dragging-categorised-goal': isDraggingCategorisedGoal,
                    'category-multi-columns': !isSingleColumn,
                    'category-single-column': isSingleColumn,
                }">
                <dx-sortable class="d-flex flex-column"
                             data-cat-name="uncategorised-group"
                             handle="#goalGripper"
                             [group]="goalDropGroup"
                             [data]="uncategorisedGoals"
                             [allowDropInsideItem]="false"
                             (onDragStart)="onGoalDragStart($event)"
                             (onDragEnd)="reorderGoal($event)">
                    <adapt-strategic-goal *ngFor="let goal of uncategorisedGoals; let first = first"
                                          class="input-goal"
                                          [ngClass]="{'mt-2': !first}"
                                          [goal]="goal"
                                          [isExpanded]="expandGoals"
                                          [isDraggable]="isGoalDraggable"
                                          [isEditing]="isEditing"></adapt-strategic-goal>
                </dx-sortable>
            </div>

            <div *ngIf="includesSWTInputsView && uncategorisedSWTInputLocations.length > 0"
                 [ngClass]="{'uncategorised-drag-border': isDraggingInputTheme === null && draggingInputType === CanvasType.StrengthsWeaknessesTrends}">
                <dx-sortable class="d-flex flex-column"
                             handle="#inputGripper"
                             [data]="uncategorisedSWTInputLocations"
                             (onDragStart)="onInputDragStart($event)"
                             (onDragEnd)="reorderInput($event)">
                    <ng-container *ngFor="let inputLocation of uncategorisedSWTInputLocations; let first = first">
                        <adapt-strategic-input class="input-swt"
                                               [input]="inputLocation.input"
                                               [showCanvasNameInContent]="true"
                                               [isCollapsible]="true"
                                               [showIcon]="true"
                                               [isDetachable]="isEditing"
                                               (detach)="detachSWTInputLocation(inputLocation)"
                                               [handleId]="(isEditing && uncategorisedSWTInputLocations.length > 1) ? 'inputGripper' : undefined"
                                               [ngClass]="{'mt-2': !first}"></adapt-strategic-input>
                    </ng-container>
                </dx-sortable>
            </div>

            <div *ngIf="includesCAInputsView && uncategorisedCAInputLocations.length > 0"
                 [ngClass]="{'uncategorised-drag-border': isDraggingInputTheme === null && draggingInputType === CanvasType.CompetitorAnalysis}">
                <dx-sortable class="d-flex flex-column"
                             handle="#CAinputGripper"
                             [data]="uncategorisedCAInputLocations"
                             (onDragStart)="onInputDragStart($event)"
                             (onDragEnd)="reorderInput($event)">
                    <ng-container *ngFor="let inputLocation of uncategorisedCAInputLocations; let first = first">
                        <adapt-strategic-input class="input-ca"
                                               [input]="inputLocation.input"
                                               [showCanvasNameInContent]="true"
                                               [isCollapsible]="true"
                                               [showIcon]="true"
                                               [isDetachable]="isEditing"
                                               (detach)="detachCAInputLocation(inputLocation)"
                                               [handleId]="(isEditing && uncategorisedCAInputLocations.length > 1) ? 'CAinputGripper' : undefined"
                                               [ngClass]="{'mt-2': !first}"></adapt-strategic-input>
                    </ng-container>
                </dx-sortable>
            </div>

            <div *ngIf="includesBullseyeView && uncategorisedBullseyeStatements.length > 0"
                 [ngClass]="{'uncategorised-drag-border': isDraggingBullseyeStatementTheme === null}">
                <dx-sortable class="d-flex flex-column"
                             handle="#statementGripper"
                             [data]="uncategorisedBullseyeStatements"
                             (onDragStart)="onBullseyeStatementDragStart($event)"
                             (onDragEnd)="reorderBullseyeStatement($event)">
                    <ng-container *ngFor="let statementLocation of uncategorisedBullseyeStatements; let first = first"
                                  [ngTemplateOutlet]="renderBullseyeStatement"
                                  [ngTemplateOutletContext]="{
                                            first,
                                            statementLocation,
                                            statementCount: uncategorisedBullseyeStatements.length}"></ng-container>
                </dx-sortable>
            </div>
        </dx-sortable>
    </dx-scroll-view>
</ng-container>

<ng-template #readonlyGoalsView>
    <div *ngIf="goals.length > 0"
         [ngClass]="{
            'goals-zone': !isSingleColumn,
            'goals-zone-single-column': isSingleColumn,
         }">
        <adapt-strategic-goal *ngFor="let goal of goals"
                              [goal]="goal"
                              [isExpanded]="expandGoals"
                              [isDraggable]="isGoalDraggable"
                              [isEditing]="isEditing"></adapt-strategic-goal>
    </div>
</ng-template>

<ng-template #renderBullseyeStatement
             let-first="first"
             let-statementLocation="statementLocation"
             let-statementCount="statementCount">
    <div class="bullseye-statement"
         [ngClass]="{'mt-2': !first}"
         *ngIf="statementLocation?.bullseyeStatement">
        <i *ngIf="isEditing && statementCount > 1"
           class="fal fa-grip-dots-vertical pe-1 gripper"
           id="statementGripper"></i>
        <i [ngClass]="StrategicViewIcon.BullseyeIcon"
           adaptTooltip="Bullseye statement"></i>
        <h5>{{statementLocation.bullseyeStatement.statement}}</h5>
        <button *ngIf="isEditing"
                adaptButton="deleteBorderless"
                class="ms-auto"
                adaptTooltip="Detach from Strategy Board"
                (click)="detachBullseyeStatementLocation(statementLocation)"></button>
    </div>
</ng-template>

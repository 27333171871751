import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren, ViewEncapsulation } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem, MeetingAgendaItemType } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChangeManagerService } from "@common/ux/change-manager/change-manager.service";
import { IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { InitializedEvent } from "devextreme/ui/text_box";
import isEqual from "lodash.isequal";
import { BehaviorSubject, EMPTY, lastValueFrom, Observable, of, Subscription, timer } from "rxjs";
import { catchError, concatMap, debounceTime, delay, filter, finalize, map, switchMap, tap } from "rxjs/operators";
import { EditAgendaItemComponent } from "../edit-agenda-item/edit-agenda-item.component";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";

enum ImportType {
    FromTemplate,
    FromMeeting,
}

interface IMeetingAgendaItems {
    agendaItems: MeetingAgendaItem[];
    preWork?: MeetingAgendaItem;
}

@Component({
    selector: "adapt-edit-agenda",
    templateUrl: "./edit-agenda.component.html",
    styleUrls: ["../meeting-styles.scss", "./edit-agenda.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditAgendaComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    public readonly ImportType = ImportType;

    @Input() public meeting?: Meeting;

    @Input() public agendaTemplate?: MeetingAgendaTemplate;
    @Output() public agendaItemsChange = new EventEmitter<MeetingAgendaItem[]>();

    @Input() public saveOnChange = true;
    @Input() public hideNotesAndItems = false;
    @Input() public disabled = false;

    @Input() public expandItemsOnInit = false;
    @Output() public expandChange = new EventEmitter<boolean>();
    @Output() public canExpandChange = new EventEmitter<boolean>();
    @Output() public isAddingNewAgendaItem = new EventEmitter<boolean>();
    @ViewChildren(EditAgendaItemComponent) private agendaItemComponents!: QueryList<EditAgendaItemComponent>;

    public canExpand = false;

    public agendaItems$?: Observable<IMeetingAgendaItems>;
    public newAgendaItem?: MeetingAgendaItem;
    public isImporting = false;
    public savingImport = false;

    private preWork?: MeetingAgendaItem;
    private agendaItems: MeetingAgendaItem[] = [];
    private removedItems: MeetingAgendaItem[] = [];
    private triggerUpdate$ = new BehaviorSubject<void>(undefined);

    private unregisterCleanupFunction: (() => void);
    private importSubscription?: Subscription;

    public get isReadonly() {
        const isSystem = !!this.agendaTemplate?.code;
        return isSystem || this.disabled;
    }

    public get totalPlannedDurationInMinutes() {
        return MeetingsService.getMeetingAgendaItemsTotalPlannedDurationInMinutes(this.agendaItems);
    }

    public get hasPreWork() {
        return !!this.preWork;
    }

    constructor(
        private commonDataService: CommonDataService,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        rxjsBreezeService: RxjsBreezeService,
        changeManager: ChangeManagerService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super();
        rxjsBreezeService.entityTypeChanged(MeetingAgendaItem).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((item) => {
            if ((item.meetingId && item.meetingId === this.meeting?.meetingId) ||
                (item.meetingAgendaTemplateId && item.meetingAgendaTemplateId === this.agendaTemplate?.meetingAgendaTemplateId)) {
                this.triggerUpdate$.next();
            }
        });

        this.unregisterCleanupFunction = changeManager.registerCleanupFunction(() => this.cleanupNewAgendaItem());
    }

    public async ngOnDestroy() {
        await this.cleanupNewAgendaItem();
        await this.cleanupImporting();
        this.unregisterCleanupFunction();
        super.ngOnDestroy();
    }

    public ngOnInit() {
        if (this.meeting || this.agendaTemplate) {
            this.agendaItems$ = this.triggerUpdate$.pipe(
                debounceTime(10),
                switchMap(() => this.cleanupNewAgendaItem()),
                switchMap(() => this.cleanupImporting()),
                concatMap(() => {
                    // only want to emit if preWork or agendaItems changed (e.g. entities synced from another session)
                    // locally session is already handled by the current UI
                    // - Can't use distinctUntilChanged as that will emit if previous emit is not the same, i.e. even if
                    //   the item is already reordered in this session.
                    const previousPreWork = this.preWork;
                    const previousAgendaItems = this.agendaItems;
                    return this.getAgendaItems().pipe(
                        filter((result) =>
                            (!previousPreWork && !previousAgendaItems.length) ||
                            !isEqual(previousPreWork, result.preWork) ||
                            !isEqual(previousAgendaItems, result.agendaItems)),
                    );
                }),
            );
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ((changes.meeting && this.meeting) || (changes.agendaTemplate && this.agendaTemplate)) {
            this.triggerUpdate$.next();
        }
    }

    public emitAgendaItemsChange() {
        this.agendaItemsChange.emit(this.allItems);
    }

    private get allItems() {
        return [
            ...(this.preWork ? [this.preWork] : []),
            ...this.agendaItems,
            ...this.removedItems,
        ];
    }

    public async onItemDeleted(item: MeetingAgendaItem) {
        if (!this.saveOnChange) {
            if (item.supplementaryData) {
                // if is a new entity, need to reject changes
                item.supplementaryData.entityAspect.rejectChanges();
            }

            const removedItem = await lastValueFrom(this.commonDataService.remove(item));
            if (removedItem) {
                this.removedItems.push(removedItem as MeetingAgendaItem);
            }
        } // else deletion is already saved - simply update

        this.triggerUpdate$.next();
    }

    @Autobind
    public createNewAgendaItem(isPreWork = false) {
        if (this.meeting || this.agendaTemplate) {
            const maxOrdinal = this.maxItemOrdinal;
            const type = isPreWork
                ? MeetingAgendaItemType.PreWork
                : MeetingAgendaItemType.AgendaItem;
            return this.meetingsService.createAgendaItem(
                maxOrdinal + 1,
                this.meeting?.meetingId,
                this.agendaTemplate?.meetingAgendaTemplateId,
                type,
            ).pipe(
                tap((agendaItem) => {
                    if (isPreWork) {
                        agendaItem.name = "Pre-work";
                        agendaItem.ordinal = -1;
                        // don't assign this.preWork here so that it will be emitted to refresh the display
                    }

                    this.newAgendaItem = agendaItem;
                    this.isAddingNewAgendaItem.emit(true);
                    this.emitAgendaItemsChange();
                }),
            );
        } else {
            return EMPTY;
        }
    }

    @Autobind
    public onImportClicked(importType: ImportType) {
        this.isImporting = true;
        this.savingImport = false;
        const importDialog = importType === ImportType.FromTemplate
            ? this.meetingsUiService.importFromAgendaTemplate(this.saveOnChange)
            : this.meetingsUiService.importFromOtherMeetingAgenda(this.saveOnChange, this.meeting);

        this.importSubscription = importDialog.pipe(
            delay(0), // next digest cycle to avoid ExpressionCHangedAfterItHasBeenCheckedError
            tap(() => this.savingImport = true),
            switchMap((items) => this.importFromAgendaItems(items)),
            catchError(() => this.cleanupImporting()),
            finalize(() => {
                this.isImporting = false;
                this.savingImport = false;
                this.importSubscription = undefined;
            }),
        ).subscribe();
    }

    @Autobind
    public addNewAgendaItem() {
        if (this.newAgendaItem && this.saveOnChange) {
            return this.meetingsService.saveEntities([this.newAgendaItem]).pipe(
                tap(() => {
                    this.newAgendaItem = undefined;
                    this.triggerUpdate$.next();
                }),
            );
        } else {
            // do this in the next digest cycle or there will be error with unsubscribe from the button destroy
            return timer(0).pipe(
                tap(() => {
                    this.newAgendaItem = undefined;
                    this.isAddingNewAgendaItem.emit(false);
                    this.triggerUpdate$.next();
                }),
            );
        }
    }

    @Autobind
    public async cancelNewAgendaItem() {
        if (this.newAgendaItem) {
            await lastValueFrom(this.commonDataService.remove(this.newAgendaItem));
            this.newAgendaItem = undefined;
            this.isAddingNewAgendaItem.emit(false);
            this.emitAgendaItemsChange();
        }
    }

    public canExpandChanged(canExpand: boolean) {
        if (this.agendaItemComponents) {
            this.canExpand = this.agendaItemComponents.some((i) => i.canExpand);
        } else {
            this.canExpand = canExpand;
        }

        this.canExpandChange.emit(this.canExpand);
    }

    public expandChanged(expanded: boolean) {
        if (expanded) {
            this.expandChange.emit(expanded);
        } else {
            this.expandChange.emit(this.canExpand && this.agendaItemComponents.some((i) => i.expandDetails));
        }
    }

    public setExpandAll(expanded: boolean) {
        this.agendaItemComponents.forEach((i) => i.expandDetails = expanded);
    }

    public onInitialized(e: InitializedEvent) {
        setTimeout(() => e.component?.focus());
    }

    public updateOrdinals(e: IDxListItemReorderedEvent<MeetingAgendaItem>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.agendaItems, "ordinal", e.fromIndex, e.toIndex);
        this.emitAgendaItemsChange();

        if (this.saveOnChange) {
            this.meetingsService.saveEntities(this.allItems).pipe(
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }

    private async cleanupNewAgendaItem() {
        if (this.newAgendaItem) {
            if (!this.newAgendaItem.name) {
                return this.cancelNewAgendaItem();
            } else {
                const dialogData: IConfirmationDialogData = {
                    cancelButtonText: "Discard",
                    confirmButtonText: "Save Changes",
                    title: "Unsaved Agenda Item...",
                    message: `<p>A new agenda item has been created but not yet saved.</p>
                        <p>Do you want to save the changes before continue or discard the unsaved changes?</p>
                        <p>Note that discarded changes can no longer be recovered.</p>`,
                };
                return lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
                    switchMap((save) => save
                        ? this.addNewAgendaItem()
                        : this.cancelNewAgendaItem()),
                ));
            }
        }
    }

    private async cleanupImporting() {
        if (this.isImporting) {
            this.isImporting = false;

            // wait for the message to go away before calling unsubscribe on the importSubscription as this may
            // possibly be from the import chain catchError (which unsubscribe will kill the dialog)
            await lastValueFrom(this.dialogService.showMessageDialog(
                "Discarding Agenda Items Import...",
                `<p>The importing process has been discarded. If you are in the middle of saving, the save may not be completed.</p>
                <p>This can be caused by navigating away from the meeting or the meeting has been deleted by someone else from another session
                while you are saving.</p>`));

            this.importSubscription?.unsubscribe();
            this.importSubscription = undefined;
        }
    }

    private importFromAgendaItems(agendaItems: MeetingAgendaItem[]) {
        if (agendaItems.length > 0) {
            return this.meetingsService.importAgendaItems(agendaItems, this.meeting?.meetingId, this.agendaTemplate?.meetingAgendaTemplateId, this.maxItemOrdinal, this.hasPreWork).pipe(
                switchMap(() => this.getAgendaItems()),
                switchMap((items) => {
                    // ensure pre-work has ordinal -1, others will just be reordered
                    if (items.preWork) {
                        items.preWork.ordinal = -1;
                    }
                    SortUtilities.sequenceNumberFieldInArray(items.agendaItems, "ordinal");

                    if (this.saveOnChange) {
                        const allItems = this.allItems; // this will include preWork and others, getAgendaItems() above already update them
                        return this.commonDataService.saveEntities([
                            ...allItems,
                            ...allItems
                                .filter((i) => !!i.supplementaryData) // removed items won't have supp entity
                                .map((i) => i.supplementaryData!),
                        ]);
                    } else {
                        return of(undefined);
                    }
                }),
                tap(() => {
                    this.preWork = undefined;
                    this.agendaItems = [];
                    this.triggerUpdate$.next();
                }),
                this.takeUntilDestroyed(),
            );
        } else {
            return EMPTY;
        }
    }

    private getAgendaItems() {
        const itemsObservable = this.meeting
            ? this.meetingsService.getAgendaItemsForMeeting(this.meeting)
            : this.meetingsService.getAgendaItemsForMeetingAgendaTemplate(this.agendaTemplate!.meetingAgendaTemplateId);

        return itemsObservable.pipe(
            map((agendaItems) => {
                const [preWork, agendaItemsWithoutPreWork] = ArrayUtilities.partition(agendaItems, (agendaItem) => agendaItem.extensions.isPreWork);
                this.agendaItems = agendaItemsWithoutPreWork;
                this.preWork = ArrayUtilities.getSingleFromArray(preWork);
                this.emitAgendaItemsChange();
                return {
                    agendaItems: agendaItemsWithoutPreWork,
                    preWork: this.preWork,
                } as IMeetingAgendaItems;
            }),
        );
    }

    private get maxItemOrdinal() {
        return this.agendaItems.length
            ? Math.max(...this.agendaItems.map((i) => i.ordinal))
            : -1; // max of empty array will be -infinity - set to -1 so next item will have ordinal of 0
    }
}

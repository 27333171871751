import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { KeyResult } from "@common/ADAPT.Common.Model/organisation/key-result";
import { ObjectiveStatus } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ITypedSimpleChange } from "@common/ux/base.component/typed-simple-change";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { ObjectivesService } from "../objectives.service";
import { ObjectivesUiService } from "../objectives-ui.service";

interface IKeyResultListItemChanges extends SimpleChanges {
    keyResult: ITypedSimpleChange<KeyResult>;
    layout: ITypedSimpleChange<KeyResultListItemLayout>;
}

export enum KeyResultListItemLayout {
    Sparse = "sparse",
    Compact = "compact",
}

@Component({
    selector: "adapt-key-result-list-item",
    templateUrl: "./key-result-list-item.component.html",
    styleUrls: ["./key-result-list-item.component.scss"],
})
export class KeyResultListItemComponent implements OnChanges, OnDestroy {
    @Input() public keyResult!: KeyResult;
    @Input() public isEditing = false;
    @Input() public layout = KeyResultListItemLayout.Sparse;
    @Input() public depth = 0;
    @Output() public updated = new EventEmitter<KeyResult>();

    public keyResultProgress = 0;
    public layoutClass = `key-result-list-item-${this.layout}-layout`;
    public headingStyles = {
        "margin-right.rem": 0,
    };
    public titleStyles = {
        "margin-right.rem": 0,
    };

    // when KR deleted, it is gone from ngFor collection and updated.emit event is unsubscribed
    // - caller won't know about the deletion and deleted entries left behind
    @Input() public onDeletion?: () => void;
    private breakpointSubscription: Subscription;
    private keyResultValueSubscription: Subscription;

    public constructor(
        private objectivesUiService: ObjectivesUiService,
        private responsiveService: ResponsiveService,
        objectivesService: ObjectivesService,
    ) {
        this.breakpointSubscription = responsiveService.currentBreakpoint$.subscribe(() => this.updateDepthStyles());
        this.keyResultValueSubscription = objectivesService.keyResultValueUpdated$.subscribe((keyResultValue) => this.updated.emit(keyResultValue.keyResult));
    }

    public ngOnDestroy() {
        this.breakpointSubscription.unsubscribe();
        this.keyResultValueSubscription.unsubscribe();
    }

    public ngOnChanges(changes: IKeyResultListItemChanges) {
        if (changes.keyResult) {
            this.keyResultProgress = this.keyResult.currentProgress;
        }

        if (changes.layout) {
            this.layoutClass = `key-result-list-item-${this.layout}-layout`;
        }

        if (changes.depth) {
            this.updateDepthStyles();
        }
    }

    private updateDepthStyles() {
        if (this.responsiveService.currentBreakpoint.isDesktopSize) {
            this.headingStyles["margin-right.rem"] = -this.depth;
            this.titleStyles["margin-right.rem"] = this.depth;
        } else {
            this.headingStyles["margin-right.rem"] = 0;
            this.titleStyles["margin-right.rem"] = 0;
        }
    }

    public get isEditable() {
        return this.isEditing && this.keyResult.objective && !this.isClosed;
    }

    public get isClosed() {
        return this.keyResult.objective && this.keyResult.objective.status === ObjectiveStatus.Closed;
    }

    public updateKeyResultValue() {
        this.objectivesUiService.updateKeyResult(this.keyResult)
            .subscribe();
    }

    @Autobind
    public promptToDeleteKeyResult() {
        return this.objectivesUiService.promptToDeleteKeyResult(this.keyResult).pipe(
            tap(() => {
                if (this.onDeletion) {
                    this.onDeletion();
                }
            }),
        );
    }

    @Autobind
    public editKeyResult() {
        return this.objectivesUiService.editKeyResult(this.keyResult);
    }
}

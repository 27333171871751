import { Component, ElementRef } from "@angular/core";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, of, switchMap } from "rxjs";
import { StrategicGoalsService } from "../strategic-goals.service";

@WorkflowStepComponent("adapt-edit-goal-review")
@Component({
    selector: "adapt-edit-goal-review",
    templateUrl: "./edit-goal-review.component.html",
    styleUrls: ["./edit-goal-review.component.scss"],
})
export class EditGoalReviewComponent extends WorkflowStepComponentAdapter {
    public goal?: Goal;
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);
    public workflowGoToStep = new BehaviorSubject<number | undefined>(undefined);

    public constructor(
        elementRef: ElementRef,
        private strategicGoalsService: StrategicGoalsService,
    ) {
        super(elementRef);
    }
    public get isNew() {
        return this.goal?.entityAspect.entityState.isAdded();
    }

    public workflowStepOnInit() {
        this.goal = this.workflowStep?.workflow.runData;
        this.workflowStepCompleted.next(!!this.goal?.extensions.isComplete);
    }

    public workflowStepNext() {
        const updateAction = this.goal!.entityAspect.entityState.isAdded() ||
            Object.prototype.hasOwnProperty.call(this.goal!.entityAspect.originalValues, "zone") ||
            Object.prototype.hasOwnProperty.call(this.goal!.entityAspect.originalValues, "themeId")
            ? this.strategicGoalsService.updateGoalOrdinal(this.goal!)
            : of([]);
        return updateAction.pipe(
            switchMap(() => this.strategicGoalsService.save()),
        );
    }

    public navigateToStep(stepIndex: number) {
        this.workflowGoToStep.next(stepIndex);
    }
}

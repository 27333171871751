<div class="select-image-component"
     [ngClass]="{'edit-inline': inline}">

    <adapt-storage-image id="storage-image_{{id}}"
                         [ngClass]="{'image-selector': !disabled}"
                         [hidden]="imageDataUrl || !(imageIdentifier || this.defaultImageSrc)"
                         [imageIdentifier]="imageIdentifier"
                         [imageHeight]="imageHeight"
                         [imageWidth]="imageWidth"
                         [defaultImageSrc]="defaultImageSrc"
                         (load)="showRealImage()"></adapt-storage-image>

    <img *ngIf="imageDataUrl"
         [style]="imageStyle"
         [src]="imageDataUrl">

    <div id="upload-trigger_{{id}}"
         *ngIf="!imageDataUrl && !imageIdentifier && !this.defaultImageSrc"
         [ngClass]="{'disabled': disabled}">
        <i class="fal fa-fw fa-camera"></i>
        UPLOAD
    </div>

    <dx-file-uploader accept="image/*"
                      [dialogTrigger]="dialogTrigger"
                      [visible]="false"
                      [disabled]="disabled"
                      [uploadMethod]="uploadMethod"
                      [uploadUrl]="uploadUrl"
                      [uploadHeaders]="uploadHeaders"
                      (onUploadError)="onUploadError($event)"
                      (onValueChanged)="onValueChanged($event)"
                      (onUploadStarted)="onUploadStarted()"
                      (onProgress)="onProgress($event)"
                      (onUploaded)="onUploaded($event)">
    </dx-file-uploader>

    <div *ngIf="error"
         class="alert alert-danger">{{error}}</div>
</div>

import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { Column, InitializedEvent } from "devextreme/ui/data_grid";
import { DxGridWrapper } from "./dx-grid-wrapper";

export class DxStatefulGridWrapper extends DxGridWrapper {
    private customLoadState?: any;
    private resolveCustomLoad?: (resolveData?: any) => void;
    private columnsReady = false;
    private onStateResetCallbacks: (() => void)[] = [];

    public constructor(
        componentId: string,
        element: JQuery<any>,
        e: InitializedEvent,
        private readonly persistenceKey: string,
    ) {
        super(componentId, element, e);
    }

    @Autobind
    public onCustomSave(state: any) {
        // create new state object to only holds columns without selectedRowKeys
        // - copy to not changing the original state - may change the existing grid state
        // - can't just pick the properties we want to persist it reset all other properties that
        //   were not persisted. angular.extend({}, dxGridComponent.state(), persistState) on restore
        //   not helping as dxGridComponent.state() returns empty obj.
        const persistState = { ...state };

        if (persistState.selectedRowKeys) { // this contains breeze entities which will have circular references
            delete persistState.selectedRowKeys;
        }

        LocalStorage.set(this.persistenceKey, persistState);
    }

    @Autobind
    public onCustomLoad() {
        const saveBuffer = LocalStorage.get(this.persistenceKey);

        this.customLoadState = undefined;

        const promise = new Promise((resolve) => {
            this.resolveCustomLoad = resolve;

            if (saveBuffer) {
                this.customLoadState = saveBuffer;

                if (this.columnsReady && this.inconsistentColumnsDetected(this.customLoadState)) {
                    // load state invalid - may be due to column change, e.g. adding org categories
                    this.log.warn("Discarding previously saved grid state for " + this.persistenceKey + " as columns/data type/order not matched.");
                    this.customLoadState = undefined;
                    // resolving this will discard the previously saved sate
                    resolve(undefined);
                } else {
                    if (this.columnsReady) {
                        // columns already ready (don't have to wait for promises etc.)
                        // - state can be applied immediately (otherwise columns defined through bindingOptions will overwrite this)
                        resolve(this.customLoadState);
                    } // else this will be resolved when column is ready
                }
            } else {
                // not been saved before - won't have to wait here
                resolve(undefined);
            }
        });

        return promise;
    }

    public setColumnsReady() {
        const self = this;
        if (!this.columnsReady) {
            // only resolve the deferred wait if columns not ready before - otherwise customLoad will resolve right away
            // and won't need to resolve here after column is ready
            setTimeout(resolveDeferredCustomLoad); // TODO: Add wait here after columns are ready for it to be picked up by dx bindingOptions
            // for now, performing this in the next digest cycle appears to work.
        }

        function resolveDeferredCustomLoad() {
            if (!self.columnsReady) {
                self.columnsReady = true;

                if (self.customLoadState) {
                    if (self.inconsistentColumnsDetected(self.customLoadState)) {
                        // load state invalid - may be due to column change, e.g. adding org categories
                        self.log.warn("Discarding previously saved grid state for " + self.persistenceKey + " as columns/data type/order not matched.");
                        self.customLoadState = undefined;
                        // resolving this will discard the previously saved sate
                        self.resolveCustomLoad!();
                    } else {
                        self.resolveCustomLoad!(self.customLoadState);
                    }
                }
            }
        }
    }

    // at least validate datatype of the restore columns matches grid component option
    // if not, cannot possibly restore the state to the grid which will
    // wrongly applying filter to invalid columns and causing no data to be displayed.
    private inconsistentColumnsDetected(restoreState: any): boolean {
        const self = this;
        let inconsistent = false;
        if (!this.isValid) {
            // don't do anything if the widget is already disposed - returning false won't wipe previously presisted states
            return inconsistent;
        }

        const currentGridColumns = this.component.option().columns;
        if (Array.isArray(restoreState.columns) && Array.isArray(currentGridColumns)) {
            if (restoreState.columns.length === currentGridColumns.length) {
                for (let i = 0; i < currentGridColumns.length; i++) {
                    const restoreColumn = restoreState.columns[i] as Column;
                    const currentColumn = currentGridColumns[i] as Column;

                    if (!currentColumn.dataField) {
                        this.log.warn(
                            "Grid column '"
                            + currentColumn.caption
                            + "' has no dataField specified. Set this even for fields that use a calculated value so that grid restore works more reliably");
                    }

                    if (restoreColumn.dataType !== currentColumn.dataType) {
                        this.log.warn(
                            "Grid column '"
                            + currentColumn.caption
                            + "' had a mismatched dataType specified. This would have led to possible grid state corruption.");
                        return true;
                    }

                    if (restoreColumn.dataField !== currentColumn.dataField) {
                        // commented out for now, since this will fire too often after this code is initially released
                        // (since the currently saved dataFields will not necessarily be set)
                        // base.log.warn(
                        //    "Grid column '"
                        //    + currentColumn.caption
                        //    + "' had a mismatched dataField specified. This would have led to possible grid state corruption.");
                        return true;
                    }

                    if (Array.isArray(restoreColumn.filterValues) && restoreColumn.filterValues.length > 0) {
                        restoreColumn.filterValues.forEach((filterValue: any[]) => checkRestoreFilterValue(filterValue, currentColumn));
                    }
                }

                return inconsistent;
            }
        }

        return true;

        function checkRestoreFilterValue(filterValue: any[], currentColumn: Column) {
            if (!filterValue[0]) {
                // undefined filter value -> check if currentColumn has calculateCellValue function
                if (FunctionUtilities.isFunction(currentColumn.calculateCellValue)) {
                    filterValue[0] = currentColumn.calculateCellValue;
                    self.log.info("column filter value restored to calculateCellValue function for column " + currentColumn.caption);
                } else {
                    self.log.warn("invalid filter value and calculateCellValue fn not detected for column " + currentColumn.caption);
                    inconsistent = true;
                }
            }
        }
    }

    public onStateReset(callback: () => void) {
        this.onStateResetCallbacks.push(callback);
    }

    @Autobind
    public resetState() {
        LocalStorage.delete(this.persistenceKey);

        super.resetState();

        this.onStateResetCallbacks.forEach((cb) => cb());
    }
}

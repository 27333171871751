import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { WorkflowStatus, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { PersonImageComponent } from "@common/user/person-image/person-image.component";
import { UserService } from "@common/user/user.service";
import { ConfigureOrganisationProfileComponent } from "@org-common/lib/configuration/organisation/configure-organisation-profile/configure-organisation-profile.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { PersonProfileService } from "@org-common/lib/person-profile/person-profile.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { IWorkflowStepFooterTemplate, WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { lastValueFrom, Observable, Subject, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { inviteBusinessLeaderWorkflow } from "../../invite-business-leaders/invite-business-leader-workflow";
import { WorkflowIdentifier } from "../../workflow-identifier.enum";

export const SetUpProfileComponentSelectorName = "adapt-set-up-profile";

@WorkflowStepComponent(SetUpProfileComponentSelectorName)
@Component({
    selector: "adapt-set-up-profile",
    templateUrl: "./set-up-profile.component.html",
})
export class SetUpProfileComponent extends WorkflowStepComponentAdapter implements OnInit {
    public person: Person | undefined;
    public defaultImageSrc = PersonImageComponent.DefaultProfileImageUrl;
    public defaultImageSrcOrg = ConfigureOrganisationProfileComponent.DefaultOrgImageUrl;
    public organisation: Organisation | undefined;
    public currentPersonIsSubscriber = false;
    public workflowStepFooterTemplates = new Subject<IWorkflowStepFooterTemplate[]>();
    public workflowStepFinishCurrent = new Subject<boolean>();
    public showSetYourOrgLogo = false;
    private inviteBusinessLeadersWorkflowStatus: WorkflowStatus | undefined;
    private isSkipped = false;

    @ViewChild("buttonTemplate") public buttonTemplate: TemplateRef<any> | undefined;
    constructor(
        private userService: UserService,
        private personProfileService: PersonProfileService,
        private organisationService: OrganisationService,
        private workflowService: WorkflowService,
        private commonDataService: CommonDataService,
        private teamService: CommonTeamsService) {

        super();

        this.organisationService.currentOrganisation$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((org) => {
            this.organisation = org;
            this.showSetYourOrgLogo = !this.organisation?.imageIdentifier;
        });
    }

    public async ngOnInit() {
        this.person = this.userService.currentPerson!;
        const leadershipTeam = await this.teamService.promiseToGetLeadershipTeam();
        this.currentPersonIsSubscriber = leadershipTeam && this.person.personId === leadershipTeam.teamLeaderPersonId;
        if (this.currentPersonIsSubscriber && this.buttonTemplate) {
            this.workflowStepFooterTemplates.next([
                { alignment: "right", template: this.buttonTemplate },
            ]);
        }

        this.inviteBusinessLeadersWorkflowStatus = await lastValueFrom(this.workflowService.getLatestStatusForWorkflow(inviteBusinessLeaderWorkflow));
    }

    public workflowStepNextText() {
        if (this.showNextStep) {
            return "Invite your people";
        }
    }

    public workflowStepNext(): Observable<any> {
        // this is to save the first and last name.

        return this.commonDataService.saveEntities(this.person).pipe(
            tap(() => {
                if (this.showNextStep && this.workflowStep && !this.isSkipped) {
                    this.workflowStep.workflow.extensions.rootWorkflow.nextWorkflowId = WorkflowIdentifier.InviteBusinessLeaders;
                }
            }),
        );
    }

    public updateOrganisationImageIdentifier(value: string) {
        this.organisationService.updateImageIdentifier(value).pipe(
            tap(() => this.workflowStepErrorMessage.next(undefined)),
            catchError((err) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(err);
                this.workflowStepErrorMessage.next(errorMessage);
                return throwError(() => new AdaptError(errorMessage));
            }),
        ).subscribe();

    }

    public updateProfileImageIdentifier(person: Person, imageIdentifier: string) {
        this.personProfileService.updateImageIdentifier(person, imageIdentifier).pipe(
            tap(() => this.workflowStepErrorMessage.next(undefined)),
            catchError((err) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(err);
                this.workflowStepErrorMessage.next(errorMessage);
                return throwError(() => new AdaptError(errorMessage));
            }),
        ).subscribe();
    }

    private get showNextStep() {
        return this.currentPersonIsSubscriber && this.inviteBusinessLeadersWorkflowStatus?.status !== WorkflowStatusEnum.Completed;
    }

    public skipInviteTeamLeaders() {
        this.isSkipped = true;
        this.workflowStepFinishCurrent.next(false);
    }
}

<adapt-call-to-action *ngIf="!hasAccess; else content"
                      [showGetStartedBadge]="false">
    <adapt-call-to-action-title>Organisation Dashboard</adapt-call-to-action-title>
    <p>
        As you progress through the pathways, your organisational guidance content will appear on this page.
    </p>
    <p>Once you complete the relevant pathways, you'll be able to see the following content on this page:</p>
    <ul class="list-content">
        <li>Purpose</li>
        <li>Vision</li>
        <li>Values</li>
    </ul>
</adapt-call-to-action>

<ng-template #content>
    <div class="d-flex flex-column gap-4 px-md-3">
        <div class="row gx-4">
            <div *adaptIfAuthorised="PurposeVisionRead"
                 class="col-md-6">
                <adapt-purpose-vision-part-dashboard-element part="purpose"
                                                             data-test="purpose-dashboard-element"></adapt-purpose-vision-part-dashboard-element>
            </div>
            <div *adaptIfAuthorised="PurposeVisionRead"
                 class="col-md-6">
                <adapt-purpose-vision-part-dashboard-element part="vision"
                                                             data-test="vision-dashboard-element"></adapt-purpose-vision-part-dashboard-element>
            </div>
        </div>

        <adapt-values-constitution-dashboard-element *adaptIfAuthorised="ValuesRead"
                                                     mode="wide"></adapt-values-constitution-dashboard-element>
    </div>
</ng-template>

import { IActiveEntity } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { Logger } from "../logger/logger";
import { FunctionUtilities } from "../utilities/function-utilities";
import { StringUtilities } from "../utilities/string-utilities";
import { FilterUtilities } from "./filter-utilities";

export class QueryUtilities {
    private static readonly Logger = Logger.getLogger("QueryHelper");

    public static processQueryOptions(results: any, getOptions?: IBreezeQueryOptions) {
        if (!ObjectUtilities.isObject(getOptions)) {
            // no getOptions - do nothing, simply returning the results
            // Note for CM-3140, checking it here rather than ensuring the caller stacks passing in a valid getOptions object
            // as this issue will likely happen again if new callers not aware of this requirement (such as in entityCountHelper.getLocalCount).
            // Checking here will ensure the correct results regardless of any assumptions the callers make
            return results;
        }

        // See commit d468e79 on branch feature/CM-841 for filtering by organisation
        if (FunctionUtilities.isFunction(getOptions?.customFilterFunction)) {
            results = getOptions!.customFilterFunction!(results);
        }

        // implementation of date param locally
        // TODO: Inject this some way in the provider and call programatically rather than hard coding
        if (FilterUtilities.hasDateOption(getOptions)) {
            results = FilterUtilities.filterByDate(results, getOptions);
        }

        // implementation of useLatest param locally
        // TODO: Inject this some way in the provider and call programatically rather than hard coding
        if (FilterUtilities.hasLatestOnlyOption(getOptions)) {
            results = FilterUtilities.filterByLatestOnly(results, getOptions!);
        }

        // implementation of activeOnly param locally
        if (getOptions?.namedParams?.activeOnly) {
            if (!Array.isArray(getOptions.activePaths) || getOptions.activePaths.length === 0) {
                QueryUtilities.Logger.warn("datacontext -> filterForActive: activePaths is badly configured or empty");
            } else {
                results = QueryUtilities.filterForActive(results, getOptions.activePaths, getOptions.isPartial);
            }
        }

        // implementation of organisationId param locally
        if (getOptions?.namedParams?.organisationId) {
            results = results.filter((ent: any) => {
                const orgId = ent?.organisationId;
                if (!orgId) {
                    return true;
                }
                return orgId === getOptions?.namedParams?.organisationId;
            });
        }

        // implementation of startsWith param locally
        if (getOptions?.namedParams?.startsWith) {
            if (!StringUtilities.isString(getOptions?.startsWithColumn)
                || !StringUtilities.isString(getOptions?.namedParams?.startsWith)) {
                QueryUtilities.Logger.warn("startsWith: startsWithNot not set");
            } else {
                results = QueryUtilities.filterForStartsWith(results, getOptions.startsWithColumn!, getOptions.namedParams.startsWith);
            }
        }

        if (getOptions?.top && results.length > getOptions.top) {
            results.splice(getOptions.top); // delete everything after top count
        }

        return results;
    }

    private static filterForStartsWith(data: any, startsWithColumn: string, startsWith: string) {
        if (startsWith && Array.isArray(data)) {
            data = data.filter(entityStartsWith);
        }

        return data;

        function entityStartsWith(entity: any) {
            if (entity[startsWithColumn]) {
                // String.prototype.startsWith() is not compatible with older edge/IE, safari < 9 - use this instead
                return entity[startsWithColumn].toString().indexOf(startsWith.toString()) === 0;
            } else {
                // filter is not applicable to this entity
                return true;
            }
        }
    }

    /**
     * Filters data for people active in the provided organisation.
     *
     * @param {Array} data - The data that should be filtered.
     * @param {string} activePaths - The paths to the properties or sub properties to be checked for active.
     * @param {bool} allowPartials - A boolean representation of whether to allow or reject partial records for which the activePaths is empty.
     * @returns {Array} A filtered set of data with people active for the provided organisation only.
     */
    private static filterForActive(data: any[], activePaths: string[], allowPartials: boolean = false) {
        return data.filter(isResultActive);

        function isResultActive(result: any) {
            const activeFieldValues = activePaths.map(getActiveFieldValue);

            return activeFieldValues.every(isFieldValueActiveOrPartiallyAllowed);

            function isFieldValueActiveOrPartiallyAllowed(fieldValue: IActiveEntity) {
                if (!fieldValue) {
                    return allowPartials;
                }

                if (Array.isArray(fieldValue)) {
                    // this an array of sub properties, evaluate each of them
                    return fieldValue.some((value: IActiveEntity) => value.isActive());
                } else {
                    return fieldValue.isActive();
                }
            }

            function getActiveFieldValue(path: string) {
                return ObjectUtilities.getObjectByPath(result, path);
            }
        }
    }
}

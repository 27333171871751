<dx-select-box [value]="team"
               (valueChange)="teamChange.emit($event)"
               [dataSource]="dataSource"
               [disabled]="disabled"
               [showClearButton]="!required"
               displayExpr="name"
               [searchEnabled]="true"
               [searchExpr]="$any('name')"
               placeholder="Select Team..."
               itemTemplate="itemTemplate"
               data-test="select-team">
    <div *dxTemplate="let item of 'itemTemplate'">
        <span>{{item.name}}</span>
        <adapt-team-private-icon class="ms-1"
                                 [team]="item"></adapt-team-private-icon>
    </div>
</dx-select-box>

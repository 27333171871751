import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, InjectionToken, Injector, Input, OnChanges, OnInit, Optional, Output, Provider, Renderer2, SimpleChanges } from "@angular/core";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { EndConnectionDialogComponent } from "@org-common/lib/user-management/end-connection-dialog/end-connection-dialog.component";
import { lastValueFrom } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { ActivationUrlDialogComponent } from "../activation-url-dialog/activation-url-dialog.component";
import { ConvertConnectionTypeDialogComponent } from "../convert-connection-type-dialog/convert-connection-type-dialog.component";
import { ConvertUserTypeDialogComponent, IConvertUserTypeDialogData } from "../convert-user-type-dialog/convert-user-type-dialog.component";
import { ReactivateAccountDialogComponent } from "../reactivate-account-dialog/reactivate-account-dialog.component";
import { UserManagementService } from "../user-management.service";

export const USER_ROLE_ACTIONS = new InjectionToken("USER_ROLE_ACTIONS");

type IUserRoleAction = (injector: Injector, connection: Connection) => Promise<void>;

export interface IUserRoleActions {
    viewAccess?: IUserRoleAction;
    configureAccess?: IUserRoleAction;
    configureRoles?: IUserRoleAction;
}

export function provideUserRoleActions(actionCallbacks: IUserRoleActions): Provider {
    return {
        provide: USER_ROLE_ACTIONS,
        useValue: actionCallbacks,
        multi: false,
    };
}

@Component({
    selector: "adapt-user-role-actions",
    template: `
        <adapt-menu [items]="actionMenu"
                    data-test="user-role-actions"></adapt-menu>`,
})
export class UserRoleActionsComponent extends BaseComponent implements OnChanges, OnInit, AfterViewInit {
    @Input() public connection!: Connection; // from component bindings
    @Output() public connectionChanged = new EventEmitter<Connection>();
    @Output() public refreshRequired = new EventEmitter<void>();

    public actionMenu: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [],
    }];

    private readonly viewAccessItem: IAdaptMenuItem;
    private readonly configureAccessItem: IAdaptMenuItem;
    private readonly configureRolesItem: IAdaptMenuItem;
    private readonly disallowLogin: IAdaptMenuItem;
    private readonly allowLogin: IAdaptMenuItem;
    private readonly deactivateAccount: IAdaptMenuItem;
    private readonly reactivateAccount: IAdaptMenuItem;
    private readonly sendInvitationEmailItem: IAdaptMenuItem;
    private readonly convertEmployeeAccount: IAdaptMenuItem;
    private readonly convertStakeholderAccount: IAdaptMenuItem;
    private readonly generateActivationUrl: IAdaptMenuItem;

    private readonly convertAccessMenu: IAdaptMenuItem;
    private readonly convertToLeaderUser: IAdaptMenuItem;
    private readonly convertToCollaboratorUser: IAdaptMenuItem;
    private readonly convertToViewerUser: IAdaptMenuItem;
    private readonly convertToNoneUser: IAdaptMenuItem;

    public constructor(
        private injector: Injector,
        private directorySharedService: DirectorySharedService,
        private userManagementService: UserManagementService,
        private commonDialogService: AdaptCommonDialogService,
        private userService: UserService,
        private renderer: Renderer2,
        private rxjsBreezeService: RxjsBreezeService,
        elementRef: ElementRef,
        @Optional() @Inject(USER_ROLE_ACTIONS) public userRoleActions?: IUserRoleActions,
    ) {
        super(elementRef);

        this.configureRolesItem = {
            text: "Configure roles",
            icon: "fal fa-fw fa-user-tag",
            onClick: this.configureRolesAction,
        };
        this.viewAccessItem = {
            text: "View access level summary",
            icon: "fal fa-fw fa-key-skeleton",
            onClick: this.viewAccessAction,
            separator: true,
        };
        this.configureAccessItem = {
            text: "Configure access levels",
            icon: "fal fa-fw fa-keyboard",
            onClick: this.configureAccessAction,
        };
        this.sendInvitationEmailItem = {
            text: "Send invitation email",
            icon: "fal fa-fw fa-envelope",
            onClick: this.sendWelcomeEmailAction,
            separator: true,
        };
        this.disallowLogin = {
            text: "Disallow login",
            icon: "fal fa-fw fa-user-times",
            onClick: this.disallowLoginAction,
            separator: true,
        };
        this.allowLogin = {
            text: "Allow login",
            icon: "fal fa-fw fa-user-check",
            onClick: this.allowLoginAction,
        };
        this.generateActivationUrl = {
            text: "Get activation URL",
            icon: "fal fa-fw fa-link",
            onClick: this.generateActivationUrlAction,
        };
        this.deactivateAccount = {
            text: "Deactivate account",
            icon: "fal fa-fw fa-times-circle",
            onClick: this.deactivateAccountAction,
        };
        this.reactivateAccount = {
            text: "Reactivate account",
            icon: "fal fa-fw fa-power-off",
            onClick: this.reactivateAccountAction,
        };

        this.convertEmployeeAccount = {
            text: "Convert employee account to stakeholder",
            onClick: this.convertEmployeeStakeholderAction,
            separator: true,
        };
        this.convertStakeholderAccount = {
            text: "Convert stakeholder account to employee",
            onClick: this.convertEmployeeStakeholderAction,
            separator: true,
        };

        this.convertToLeaderUser = {
            text: `Change user type to ${UserTypeExtensions.singularLabel(UserType.Leader).toLowerCase()}`,
            onClick: () => this.convertAccess(UserType.Leader),
        };

        this.convertToCollaboratorUser = {
            text: `Change user type to ${UserTypeExtensions.singularLabel(UserType.Collaborator).toLowerCase()}`,
            onClick: () => this.convertAccess(UserType.Collaborator),
        };

        this.convertToViewerUser = {
            text: `Change user type to ${UserTypeExtensions.singularLabel(UserType.Viewer).toLowerCase()}`,
            onClick: () => this.convertAccess(UserType.Viewer),
        };

        this.convertToNoneUser = {
            text: `Change user type to ${UserTypeExtensions.singularLabel(UserType.None).toLowerCase()}`,
            onClick: () => this.convertAccess(UserType.None),
        };

        this.convertAccessMenu = {
            text: "Change user type",
            items: [
                this.convertToLeaderUser,
                this.convertToCollaboratorUser,
                this.convertToViewerUser,
                this.convertToNoneUser,
            ],
        };

        this.actionMenu[0].items = [
            this.configureRolesItem,
            this.viewAccessItem,
            this.configureAccessItem,
            this.convertAccessMenu,
            this.sendInvitationEmailItem,
            this.generateActivationUrl,
            this.disallowLogin,
            this.allowLogin,
            this.deactivateAccount,
            this.reactivateAccount,
            this.convertEmployeeAccount,
            this.convertStakeholderAccount,
        ];
    }

    public get isNotAlto() {
        return AdaptClientConfiguration.AdaptProjectName !== AdaptProject.Alto;
    }

    public ngOnInit() {
        this.rxjsBreezeService.entityTypeChanged(Connection).pipe(
            filter((conn) => conn === this.connection),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.updateAccessActions();
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.connection && this.connection) {
            this.updateAccessActions();
        }
    }

    public ngAfterViewInit() {
        const element = this.elementRef!.nativeElement as HTMLElement;
        const parentTableCell = element.closest<HTMLElement>("td");
        if (parentTableCell) {
            this.renderer.setStyle(parentTableCell, "overflow", "visible");
        }
    }

    @Autobind
    private async updateAccessActions() {
        const currentPersonId = this.userService.getCurrentPersonId();
        const isCurrentPerson = currentPersonId === this.connection.personId;
        const isInactive = !this.connection.isActive();
        const roleConnectionsWithPermissions = this.connection.roleConnections
            .filter((roleConnection) => roleConnection.isActive())
            .filter(this.userManagementService.roleConnectionHasPermissionFilter);


        this.viewAccessItem.visible = !!this.userRoleActions?.viewAccess && !isInactive && roleConnectionsWithPermissions.length > 0;

        // configure access and roles
        this.configureAccessItem.visible = !!this.userRoleActions?.configureAccess && !isInactive && this.connection.userType === UserType.Leader && this.connection.connectionType !== ConnectionType.Coach;
        this.configureRolesItem.visible = !!this.userRoleActions?.configureRoles && !isInactive;

        // resend invitation invite
        this.sendInvitationEmailItem.visible = !isInactive && this.connection.hasAccess;
        this.sendInvitationEmailItem.separator = this.configureRolesItem.visible;
        this.generateActivationUrl.visible = this.sendInvitationEmailItem.visible;

        // allow / disallow login
        this.disallowLogin.visible = this.isNotAlto && !isInactive && this.connection.hasAccess && !isCurrentPerson;
        this.allowLogin.visible = this.isNotAlto && !isInactive && !this.connection.hasAccess && roleConnectionsWithPermissions.length > 0;

        // de-activation/ activation of account (start/end connection)
        this.deactivateAccount.visible = !isInactive && !isCurrentPerson;
        this.reactivateAccount.visible = isInactive;

        // convert employee <--> stakeholder
        this.convertEmployeeAccount.visible = this.isNotAlto && !isInactive && this.connection.isEmployeeConnection();
        this.convertStakeholderAccount.visible = this.isNotAlto && !isInactive && this.connection.isStakeholderConnection();
        const activeConnectionCount = this.connection.person.connections.filter((i) => i.isActive()).length;
        if (activeConnectionCount > 1) {
            this.convertEmployeeAccount.disabled = true;
            this.convertEmployeeAccount.tooltip = "This person has more than 1 connection to this organisation and cannot be converted";
            this.convertStakeholderAccount.disabled = true;
            this.convertStakeholderAccount.tooltip = this.convertEmployeeAccount.tooltip;
        } else {
            this.convertEmployeeAccount.disabled = false;
            this.convertEmployeeAccount.tooltip = undefined;
            this.convertStakeholderAccount.disabled = false;
            this.convertStakeholderAccount.tooltip = undefined;
        }

        // convert to full/team/view/none accounts
        this.convertAccessMenu.visible = this.isNotAlto && !isInactive && (this.connection.isEmployeeConnection() || this.connection.isStakeholderConnection());
        this.convertToLeaderUser.visible = this.connection.userType !== UserType.Leader;
        this.convertToCollaboratorUser.visible = this.connection.userType !== UserType.Collaborator;
        this.convertToViewerUser.visible = this.connection.userType !== UserType.Viewer;
        this.convertToNoneUser.visible = this.connection.userType !== UserType.None && this.connection.isStakeholderConnection();
    }

    @Autobind
    private async disallowLoginAction() {
        await this.directorySharedService.promiseToRemoveAccess(this.connection);
        this.updateAccessActions();
        this.notifyGridChangedEvent();
    }

    @Autobind
    private async allowLoginAction() {
        const sendEmail = await this.directorySharedService.promiseToEnableAccess(this.connection);
        if (sendEmail) {
            await this.userManagementService.promiseToSendWelcomeEmail(this.connection.person.personId);
        }

        this.updateAccessActions();
        this.notifyGridChangedEvent();
    }

    @Autobind
    private async generateActivationUrlAction() {
        this.commonDialogService.open(ActivationUrlDialogComponent, this.connection.person).subscribe();
    }

    @Autobind
    private deactivateAccountAction() {
        this.commonDialogService.open(EndConnectionDialogComponent, { connection: this.connection })
            .subscribe(() => this.updateAccessActions());
    }

    @Autobind
    private async reactivateAccountAction() {
        // new dialog returning person if accepted, undefined otherwise
        let result: Person | undefined;

        if (this.isNotAlto) {
            result = await lastValueFrom(this.commonDialogService.open(ReactivateAccountDialogComponent, this.connection.person), { defaultValue: undefined });
        } else {
            const latestConnection = this.connection.person.getLatestConnection([ConnectionType.Employee, ConnectionType.Stakeholder]) as Connection | undefined;
            if (latestConnection) {
                const leaderRole = await this.userManagementService.promiseToGetDefaultAccessLevel(UserType.Leader);
                if (leaderRole) {
                    await this.userManagementService.reactivateAccount(latestConnection, latestConnection.connectionType, UserType.Leader, [leaderRole], true);
                    result = this.connection.person;
                }
            }
        }

        if (result) {
            this.connectionChanged.emit(result.getLatestConnection());
        }
    }

    @Autobind
    private async sendWelcomeEmailAction() {
        this.userManagementService.confirmSendWelcomeEmail(this.connection.person.personId).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.notifyGridChangedEvent());
    }

    @Autobind
    private async viewAccessAction() {
        if (this.userRoleActions?.viewAccess) {
            await this.userRoleActions.viewAccess(this.injector, this.connection);
        }
    }

    @Autobind
    private async configureAccessAction() {
        if (this.userRoleActions?.configureAccess) {
            await this.userRoleActions.configureAccess(this.injector, this.connection);
        }

        this.updateAccessActions();
        this.notifyGridChangedEvent();
    }

    @Autobind
    private async configureRolesAction() {
        if (this.userRoleActions?.configureRoles) {
            await this.userRoleActions.configureRoles(this.injector, this.connection);
        }

        this.updateAccessActions();
        this.notifyGridChangedEvent();
    }

    @Autobind
    private convertEmployeeStakeholderAction() {
        this.commonDialogService.open(ConvertConnectionTypeDialogComponent, this.connection)
            .subscribe(() => this.notifyGridChangedEvent());
    }

    private convertAccess(userType: UserType) {
        const dialogData: IConvertUserTypeDialogData = {
            userType,
            connection: this.connection,
        };

        this.commonDialogService.open(ConvertUserTypeDialogComponent, dialogData)
            .subscribe(() => this.notifyGridChangedEvent());
    }

    private notifyGridChangedEvent() {
        // this is required as we are using a fixed column which height cannot be changed without updateDimensions call
        // causing cell within the row to have different heights and can't match up which row the action corresponds to
        // https://www.devexpress.com/Support/Center/Question/Details/T342376/dxdatagrid-the-row-height-is-incorrect-if-the-grid-has-a-fixed-column-and-one-of-its
        this.refreshRequired.emit();
    }
}

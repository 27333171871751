<ng-container *ngIf="currentCanvas">
    <adapt-toolbar-content alignment="left">
        <div class="d-flex align-items-center">
            <adapt-select-canvas [(canvas)]="currentCanvas"
                                 (canvasChange)="onCanvasChanged($event)"></adapt-select-canvas>
        </div>
    </adapt-toolbar-content>
    <adapt-toolbar-content *adaptIfAuthorised="EditStrategicInputs"
                           alignment="right">
        <adapt-menu [items]="menuItems"></adapt-menu>
    </adapt-toolbar-content>
</ng-container>

<adapt-input-types-map *ngIf="currentCanvas; else showCTA"
                       [canvas]="currentCanvas"></adapt-input-types-map>

<ng-template #showCTA>
    <adapt-call-to-action>
        <adapt-call-to-action-title>Strengths, weaknesses &amp; trends</adapt-call-to-action-title>
        <p>
            Strategic inputs are crucial elements that organizations consider when formulating their strategies. These inputs help in
            assessing the internal and external factors that can impact the organization's performance and competitiveness.
        </p>
        <p>
            By analyzing strengths, weaknesses, and trends, organizations can gain valuable insights into their current position and the
            external environment.
        </p>
        <button *adaptIfAuthorised="EditStrategicInputs"
                adaptCallToActionButton
                adaptButtonIcon="add"
                [adaptBlockingClick]="addSWTCanvas">
            Add strength/weakness/trend canvas
        </button>
    </adapt-call-to-action>
</ng-template>

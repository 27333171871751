import { Component, Input, OnChanges } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";

@Component({
    selector: "adapt-zone-label",
    templateUrl: "./zone-label.component.html",
    styleUrls: ["./zone-label.component.scss"],
})
export class ZoneLabelComponent implements OnChanges {
    @Input() public zone: Zone | undefined;
    @Input() public valueStream: ValueStream | undefined | null;

    public zoneIcon = "";
    public zoneText = "";
    public zoneStyle = "";

    public constructor(
        private orgService: OrganisationService,
    ) { }

    public ngOnChanges(): void {
        if (this.valueStream) {
            this.zoneText = this.valueStream.name;
            this.zoneIcon = "fal fa-cube";
            this.zoneStyle = "zone-economic-engine";
        } else if (this.zone) {
            this.zoneText = ZoneMetadata.Name[this.zone];
            if (this.zone === Zone.ResearchAndDevelopment) {
                this.orgService.getOrganisationConfiguration().subscribe((config) => this.zoneText = config.productZoneName);
            }

            this.zoneIcon = ZoneMetadata.Icon[this.zone];
            this.zoneStyle = ZoneMetadata.Style[this.zone];
        }
    }
}

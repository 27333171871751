import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { map } from "rxjs";

interface IChatApiChoice {
    text: string;
    index: number;
    finish_reason: string;
}

interface IChatApiUsage {
    completion_tokens: number;
    prompt_tokens: number;
    total_tokens: 12;
}

interface IChatApiResponse {
    id: string;
    object: string;
    created: number;
    model: string;
    choices: IChatApiChoice[];
    usage: IChatApiUsage;
}

interface IChatApiBody {
    prompt: string;
    max_tokens: number;
}

@Injectable({
    providedIn: "root",
})
export class ChatAiService {
    public constructor(
        private httpClient: HttpClient,
    ) { }

    public get completionsUrl() {
        return `${AdaptClientConfiguration.OpenAiEndpoint}/openai/deployments/${AdaptClientConfiguration.OpenAiDeploymentName}/completions`;
    }

    public generateBullseyeStatement(statements: string[], maxLength: number) {
        const prompt = `Given the following information: ${statements.join(", ")}.
            Generate a bullseye statement for the organisation with not more than ${maxLength} characters`;
        return this.getPromptCompletion(prompt);
    }

    public generateGoalDescription(goalName: string, currentMeasurement: string, targetMeasurement: string, maxLength = 200) {
        const prompt = `With my current organisation goal of "${goalName}",
            my current measurement is: ${currentMeasurement}, and I am trying to achieve ${targetMeasurement}.
            Generate a description for this goal with not more than ${maxLength} characters, outlines the desired outcomes and objectives,
            identify the gaps, identify what we need to do to bridge the gaps and what is our approach to achieving success.`;
        return this.getPromptCompletion(prompt);
    }

    public paraphraseStatement(statement: string) {
        const prompt = `Paraphrase the following statement: ${statement}`;
        return this.getPromptCompletion(prompt);
    }

    private getPromptCompletion(prompt: string) {
        return this.httpClient.post<IChatApiResponse>(this.completionsUrl, {
            prompt,
            max_tokens: AdaptClientConfiguration.OpenAiMaxToken,
        } as IChatApiBody, {
            headers: {
                "Content-Type": "application/json",
                "api-key": AdaptClientConfiguration.OpenAiKey,
            },
            responseType: "json",
            params: {
                "api-version": AdaptClientConfiguration.OpenAiApiVersion,
            },
        }).pipe(
            map((response) => {
                window.console.log(`INFO: The previous chat response costs ${response.usage.total_tokens} total tokens including ${response.usage.prompt_tokens} tokens for the prompt`);
                if (response.choices.length > 0) {
                    const responseText = response.choices
                        .map((choice) => choice.text)
                        .join(", ");
                    return this.trimLeadingGarble(responseText);
                } else {
                    return "No response from Chat API";
                }
            }),
        );
    }


    // AI generated text sometimes starts with these - remove them
    private trimLeadingGarble(text: string) {
        const trimTexts = [":", ".", "\n"];
        let result = text.trim();
        while (trimTexts.includes(result[0])) {
            result = result.substring(1).trim();
        }

        return result;
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buttonPreset } from "@common/ux/button/button-preset";
import { buildLocalWorkflow, LocalWorkflowStep } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "../../workflow-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../../workflow-utilities";

export interface ISetCadenceStepData {
    eventType: EventTypePreset;
}

export const EventTypePresets = [
    EventTypePreset.AnnualStrategy,
    EventTypePreset.QuarterlyStrategy,
    EventTypePreset.MonthlyStrategy,
    EventTypePreset.OKRCheckIn,
];

export const establishCadenceWorkflowName = "Establish business cadence";

function buildEstablishCadenceWorkflow(calendarIntegration: boolean) {
    const calendarIntegrationSteps: LocalWorkflowStep[] = [];
    if (calendarIntegration) {
        calendarIntegrationSteps.push({
            name: "Calendar Integration",
            ordinal: 0,
            allowBack: true,
            canSkip: true,
            componentSelector: "adapt-calendar-integration-step",
        });
    }

    const ordinalOffset = calendarIntegration ? 1 : 0;

    return buildLocalWorkflow({
        name: establishCadenceWorkflowName,
        workflowId: WorkflowIdentifier.PlanYourStrategyCadence,
        type: WorkflowType.Workflow,
        dialogWidth: WorkflowDialogWidth.ExtraLarge,
        iconStyle: "fal fa-chess-queen-piece",
        articleSlug: ImplementationKitArticle.EstablishCadenceOutcomes,
        wrapUpSlug: ImplementationKitArticle.EstablishCadenceWrapUp,
        isStateless: true,
        showBreadcrumbs: true,
        showBreadcrumbTitles: true,
        featuresToEnable: [FeatureName.StewardshipWorkKanban, FeatureName.StewardshipWorkMeetings],
        ordinal: 10,
        steps: [
            ...calendarIntegrationSteps,
            {
                name: "Cadence Cycle",
                ordinal: ordinalOffset + 0,
                allowBack: true,
                canSkip: false,
                articleSlug: ImplementationKitArticle.EstablishCadenceSetCadenceCycle,
                articlePosition: WorkflowStepGuidancePosition.Left,
                componentSelector: "adapt-set-cadence-cycle-step",
            },
            {
                name: "Annual Strategy",
                ordinal: ordinalOffset + 1,
                allowBack: true,
                canSkip: false,
                componentSelector: "adapt-set-cadence-step",
                customData: {
                    eventType: EventTypePreset.AnnualStrategy,
                } as ISetCadenceStepData,
            },
            {
                name: "Quarterly Strategy",
                ordinal: ordinalOffset + 2,
                allowBack: true,
                canSkip: false,
                componentSelector: "adapt-set-cadence-step",
                customData: {
                    eventType: EventTypePreset.QuarterlyStrategy,
                } as ISetCadenceStepData,
            },
            {
                name: "Monthly Strategy",
                ordinal: ordinalOffset + 3,
                allowBack: true,
                canSkip: false,
                componentSelector: "adapt-set-cadence-step",
                customData: {
                    eventType: EventTypePreset.MonthlyStrategy,
                } as ISetCadenceStepData,
            },
            {
                name: "Strategy Check In",
                ordinal: ordinalOffset + 4,
                allowBack: true,
                canSkip: false,
                componentSelector: "adapt-set-cadence-step",
                customData: {
                    eventType: EventTypePreset.OKRCheckIn,
                } as ISetCadenceStepData,
            },
            {
                name: "Review",
                ordinal: ordinalOffset + 5,
                allowBack: true,
                canSkip: false,
                componentSelector: "adapt-review-cadence-step",
                workflowStepNextText: buttonPreset.saveAndClose.buttonText,
                workflowStepNextIcon: `${buttonPreset.saveAndClose.iconClass} fa-fw`,
            },
        ],
    });
}

export const planYourStrategyCadenceWorkflow = buildEstablishCadenceWorkflow(false);
export const planYourStrategyCadenceWithCalendarIntegrationWorkflow = buildEstablishCadenceWorkflow(true);

export const establishCadenceWorkflow = buildLocalWorkflow({
    name: establishCadenceWorkflowName,
    workflowId: WorkflowIdentifier.EstablishBusinessCadence,
    type: WorkflowType.Journey,
    time: "1 hour",
    people: "Leadership team",
    mindset: "Collaborative",
    overview: "Establish how your organisation will use a regular cadence to drive, monitor & implement your strategy.",
    articleSlug: ImplementationKitArticle.EstablishCadenceOutcomes,
    category: OrganisationCategoryValue.ImplementStrategy,
    wrapUpSlug: ImplementationKitArticle.EstablishCadenceWrapUp,
    compulsoryPrerequisites: [WorkflowIdentifier.StrategicGoals],
    ordinal: 10,
    workflows: [
        createActivityBriefWorkflow(establishCadenceWorkflowName, WorkflowIdentifier.EstablishBusinessCadence, undefined, ImplementationKitArticle.EstablishCadenceMeetingDescription, ImplementationKitArticle.EstablishCadenceMeetingPreWork),
        ...createWorkshopScheduleWorkflows(establishCadenceWorkflowName, WorkflowIdentifier.EstablishBusinessCadence),
    ],
});

import { Component, Inject } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { AccountService } from "../account.service";

@Component({
    selector: "adapt-start-subscription-dialog",
    templateUrl: "./start-subscription-dialog.component.html",
    styleUrls: ["./start-subscription-dialog.component.scss"],
})
export class StartSubscriptionDialogComponent extends BaseDialogComponent<boolean, any> {
    public readonly dialogName = "StartSubscriptionDialogComponent";
    public monthlyPrice = 0;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public saveOnClose: boolean,
        accountService: AccountService,
    ) {
        super();

        accountService.getAccount()
            .subscribe((account) => this.monthlyPrice = account!.extensions.fixedMonthlyDollars);
    }

    @Autobind
    public save() {
        return this.resolve({});
    }
}

import { Injectable } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { CommonSidebarConstants } from "@common/shell/common-sidebar/common-sidebar.constants";
import { ISidebarTab, SidebarTabPosition } from "@common/shell/common-sidebar/sidebar-tab";
import { SidebarTabIconComponent } from "@common/shell/sidebar-tab-icon/sidebar-tab-icon.component";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { Observable, of } from "rxjs";
import { filter, first, map, startWith, switchMap } from "rxjs/operators";
import { OrganisationService } from "../../organisation/organisation.service";
import { ConfigurationOrganisationHierarchyNavigationService } from "./configuration-organisation-hierarchy-navigation.service";
import { ConfigurationPersonalHierarchyNavigationService } from "./configuration-personal-hierarchy-navigation.service";
import { ConfigurationTabContentComponent } from "./configuration-tab-content.component";
import { ConfigurationTeamHierarchyNavigationService } from "./configuration-team-hierarchy-navigation.service";

export const ConfigurationTabId = "sidebar-configuration";

@Injectable()
export class ConfigurationTab implements ISidebarTab {
    public id = ConfigurationTabId;
    public label = "Platform Configuration";
    public ordinal = 5;
    public position = SidebarTabPosition.Bottom;

    public isLoading$ = of(false);
    public isDisplayed$ = of(true);
    public maxWidth = CommonSidebarConstants.NavigationTabMaxWidth;

    public focusTab$: Observable<void>;

    public icon: IComponentRender<SidebarTabIconComponent> = {
        component: SidebarTabIconComponent,
        params: {
            iconClass: "fal fa-cog",
        },
    };

    public content: IComponentRender<ConfigurationTabContentComponent> = {
        component: ConfigurationTabContentComponent,
    };

    private readonly hierarchyIds = [
        ConfigurationOrganisationHierarchyNavigationService.HierarchyId,
        ConfigurationPersonalHierarchyNavigationService.HierarchyId,
        ConfigurationTeamHierarchyNavigationService.HierarchyId,
    ];

    public constructor(
        private navigationHierarchyService: NavigationHierarchyService,
        organisationService: OrganisationService,
    ) {
        this.focusTab$ = organisationService.currentOrganisation$.pipe(
            // In order to support the active tab only updating once for emit from
            // the source observable we have to wrap the following in a switch
            // map rather than flattening the chain (which would result in the
            // first() below make this only work on startup)
            switchMap(this.emitOnActiveNodeMatchesThisHierarchy),
        );

        // don't display config tab if no config pages available
        this.isDisplayed$ = navigationHierarchyService.hierarchyChange$
            .pipe(
                startWith(false),
                map(() => this.hierarchyIds.some((hierarchy) => {
                    const rootNode = this.navigationHierarchyService.getNodeByHierarchy(hierarchy);
                    return (rootNode?.children.length ?? 0) > 0;
                })),
            );
    }

    @Autobind
    private emitOnActiveNodeMatchesThisHierarchy() {
        // Take first as we only want to switch to the active tab once per time this is checked
        return this.navigationHierarchyService.activeNode$.pipe(
            map(() => this.navigationHierarchyService.getActiveHierarchy()),
            filter((hierarchy) => !!hierarchy),
            first(),
            filter((hierarchy) => this.hierarchyIds.includes(hierarchy ?? "")),
            map(FunctionUtilities.noop),
        );
    }
}

import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ValueChangedEvent } from "devextreme/ui/text_box";
import { IToolbar, IToolbarActionOptions, ToolbarContentLocation } from "../toolbar.interface";
import { ToolbarService } from "../toolbar.service";

@Component({
    selector: "adapt-toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent extends BaseComponent implements OnInit, IToolbar {
    public features = {
        toolbarEnabled: true,
        searchEnabled: false,
        titleEnabled: false,
    };
    public searchValueChangedCallback?: (e: ValueChangedEvent) => void;
    public customClasses: string[] = [];
    private customClassesUpdater = this.createThrottledUpdater((classes: string[]) => this.customClasses = classes);

    @ViewChild("title") public title?: ElementRef;

    @ViewChild("leftContent") public leftContent?: ElementRef<HTMLDivElement>;
    @ViewChild("centerContent") public centerContent?: ElementRef<HTMLDivElement>;
    @ViewChild("rightContent") public rightContent?: ElementRef<HTMLDivElement>;

    private toolbarEnabledUpdater = this.createThrottledUpdater((value: boolean) => this.features.toolbarEnabled = value);
    private titleEnabledUpdater = this.createThrottledUpdater((value: boolean) => this.features.titleEnabled = value);

    public constructor(
        private toolbarService: ToolbarService,
    ) {
        super();
    }

    public ngOnInit() {
        // Do in a timeout to allow the DOM to be generated so that queued items in toolbar factory
        // are actually going to take effect
        setTimeout(() => {
            this.toolbarService.registerToolbar(this);
        });
    }

    public addContent(domElement: HTMLElement, options?: IToolbarActionOptions) {
        if (options && options.contentOrdinal) {
            domElement.style.order = options.contentOrdinal.toString();
        }

        const alignmentContainer = this.getAlignmentContainerRef(options?.contentLocation ?? ToolbarContentLocation.Right);
        if (alignmentContainer) {
            alignmentContainer.nativeElement.append(domElement);
        }
    }

    public removeContent(element: HTMLElement) {
        const contentElements = [this.leftContent, this.centerContent, this.rightContent];

        contentElements.forEach((el) => {
            if (el && el.nativeElement.contains(element)) {
                el.nativeElement.removeChild(element);
            }
        });
    }

    public resetToolbar() {
        this.clearContent();
        this.clearTitle();
        this.clearToolbarClass();
        this.showToolbar();
    }

    public setTitle(title: string | HTMLElement) {
        this.titleEnabledUpdater.next(true);
        if (this.title) {
            this.title.nativeElement.append(title);
        }
    }

    public clearTitle() {
        this.titleEnabledUpdater.next(false);
        if (this.title) {
            this.title.nativeElement.textContent = "";
        }
    }

    public showToolbar() {
        this.toolbarEnabledUpdater.next(true);
    }

    public hideToolbar() {
        this.toolbarEnabledUpdater.next(false);
    }

    public addToolbarClass(className: string) {
        // concat won't change customClasses and this is what we need -> otherwise will case ExpressionChangedAfterItHasBeenCheckedError
        this.customClassesUpdater.next(this.customClasses.concat([className]));
    }

    public clearToolbarClass() {
        this.customClassesUpdater.next([]);
    }

    private clearContent() {
        const contentElements = [this.leftContent, this.centerContent, this.rightContent];

        contentElements.forEach((el) => {
            if (el) el.nativeElement.textContent = "";
        });
    }

    private getAlignmentContainerRef(contentLocation: ToolbarContentLocation) {
        let alignmentRef: ElementRef<HTMLDivElement> | undefined;

        switch (contentLocation) {
            case ToolbarContentLocation.Left:
                alignmentRef = this.leftContent;
                break;
            case ToolbarContentLocation.Center:
                alignmentRef = this.centerContent;
                break;
            case ToolbarContentLocation.Right:
            default:
                alignmentRef = this.rightContent;
                break;
        }

        return alignmentRef;
    }
}

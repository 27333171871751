import { Component, Input } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { FeaturesService } from "@org-common/lib/features/features.service";

@Component({
    selector: "adapt-configure-work",
    templateUrl: "./configure-work.component.html",
})
export class ConfigureWorkComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public isMeetingsFeatureEnabled = false;

    constructor(
        private featuresService: FeaturesService,
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }

    public async onMeetingsFeatureStatusValueChanged(value: boolean) {
        this.isMeetingsFeatureEnabled = value;
        await this.featuresService.promiseToSetFeatureStatus(FeatureName.StewardshipWorkMeetings, undefined, this.isMeetingsFeatureEnabled);
    }

    @Autobind
    public async initialiseData() {
        this.isMeetingsFeatureEnabled = await this.featuresService.promiseToCheckIfFeatureActive(FeatureName.StewardshipWorkMeetings, undefined);
    }
}

<ng-container *ngIf="objectiveGroup">
    <adapt-objective-list-item [objective]="objectiveGroup!.objective"
                               [depth]="depth"
                               [isExternalObjective]="isExternalObjective"
                               (objectiveChange)="onObjectiveChanged($event)"></adapt-objective-list-item>
    <ul>
        <li *ngFor="let childGroup of objectiveGroup.childGroups">
            <adapt-objective-list [objectiveGroup]="childGroup"
                                  [depth]="childDepth"
                                  [currentTeamId]="currentTeamId"
                                  (objectiveChange)="onObjectiveChanged($event)"></adapt-objective-list>
        </li>
    </ul>
</ng-container>

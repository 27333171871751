import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { DisplayObjectiveStatusComponent } from "./display-objective-status/display-objective-status.component";
import { LinkObjectiveComponent } from "./link-objective.component/link-objective.component";
import { LinkObjectivesPageComponent } from "./link-objectives-page/link-objectives-page.component";
import { ObjectiveActionsComponent } from "./objective-actions/objective-actions.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
        AdaptDateModule,
        AdaptMenuModule,
        AdaptTeamDashboardSharedModule,
    ],
    declarations: [
        LinkObjectiveComponent,
        LinkObjectivesPageComponent,
        ObjectiveActionsComponent,
        DisplayObjectiveStatusComponent,
    ],
    exports: [
        LinkObjectiveComponent,
        LinkObjectivesPageComponent,
        ObjectiveActionsComponent,
        DisplayObjectiveStatusComponent,
    ],
})
export class AdaptObjectivesSharedModule { }

import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CustomItemCreatingEvent } from "devextreme/ui/select_box";
import { BehaviorSubject, lastValueFrom, map, switchMap, tap } from "rxjs";
import { StrategyService } from "../strategy.service";

@Component({
    selector: "adapt-select-theme",
    templateUrl: "./select-theme.component.html",
})
export class SelectThemeComponent extends BaseComponent implements OnChanges {
    @Input() public placeholderText = "Select or enter a new theme";
    @Input() public disabled = false;
    @Input() public zone?: Zone;
    @Input() public theme?: Theme;
    @Input() public allowCreation = true;
    @Input() public height?: number; // optional height to force this and select-zone to vertically aligned (as select zone has a 2x icon) if they are going on the same row
    @Output() public themeChange = new EventEmitter<Theme | undefined>();

    public existingThemes: Theme[] = [];

    private triggerUpdate = new BehaviorSubject<void>(undefined);

    public constructor(
        elementRef: ElementRef,
        private strategyService: StrategyService,
    ) {
        super(elementRef);

        this.triggerUpdate.pipe(
            switchMap(() => this.strategyService.getThemesByZone(this.zone)),
            this.takeUntilDestroyed(),
        ).subscribe((themes) => this.existingThemes = themes);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.zone) {
            this.triggerUpdate.next();
        }
    }

    public onCustomThemeCreating(e: CustomItemCreatingEvent) {
        const themeName = e.text?.trim();
        if (!themeName) {
            e.customItem = undefined;
            return;
        }

        const existingTheme = this.existingThemes.find((theme) => theme.name.toLowerCase() === themeName.toLowerCase());
        if (existingTheme) {
            e.customItem = existingTheme;
        } else if (this.zone) {
            // e.customItem can be a promise (not observable), see doc:
            //  https://js.devexpress.com/Documentation/22_1/ApiReference/UI_Components/dxSelectBox/Configuration/#onCustomItemCreating
            e.customItem = lastValueFrom(this.strategyService.createThemeForZone(themeName, this.zone).pipe(
                switchMap((theme) => this.strategyService.saveEntities(theme).pipe(map(() => theme))),
                tap((theme) => {
                    this.existingThemes.push(theme);
                    e.component.getDataSource().reload();
                }),
            ));
        }
    }

    public onThemeValueChanged(theme?: Theme) {
        this.theme = theme;
        this.themeChange.emit(this.theme);
    }
}

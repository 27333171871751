import { Injectable, Injector } from "@angular/core";
import { Feature, FeatureBreezeModel } from "@common/ADAPT.Common.Model/embed/feature";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionBreezeModel } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { FeatureStatus, FeatureStatusBreezeModel } from "@common/ADAPT.Common.Model/organisation/feature-status";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { AfterInitialisationObservable } from "@common/service/after-initialisation.decorator";
import { BaseService } from "@common/service/base.service";
import { filter, first, map, mergeAll, switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class FeatureService extends BaseService {
    public constructor(injector: Injector) {
        super(injector);
    }

    public readonly getAllFeatures = () => this.commonDataService.getAll(FeatureBreezeModel);
    public readonly getAllFeaturePermissions = () => this.commonDataService.getAll(FeaturePermissionBreezeModel);

    protected initialisationActions() {
        return [this.getAllFeatures()];
    }

    @Autobind
    public isPlatformFeature(feature: Feature) {
        return feature.name.includes("Platform");
    }

    @AfterInitialisationObservable
    public getOrganisationalFeatures() {
        const predicate = new MethodologyPredicate<Feature>("name", "startswith", "Team")
            .not();

        return this.commonDataService.getByPredicate(FeatureBreezeModel, predicate);
    }

    @AfterInitialisationObservable
    public getFeaturePermissions(feature: FeatureName) {
        return this.commonDataService.getAll(FeaturePermissionBreezeModel).pipe(
            map((featurePermissions) => featurePermissions.filter((i) => i.feature.name === feature)),
        );
    }

    public getFeatureStatusesForOrganisation(organisation: Organisation) {
        const predicate = new MethodologyPredicate<FeatureStatus>("organisationId", "==", organisation.organisationId)
            .and(new MethodologyPredicate<FeatureStatus>("teamId", "==", null));

        return this.commonDataService.getByPredicate(FeatureStatusBreezeModel, predicate);
    }

    public removeFeatureStatus(featureStatus: FeatureStatus) {
        return this.commonDataService.remove(featureStatus);
    }

    public addFeatureStatus(name: FeatureName, teamOrOrg: Team | Organisation) {
        return this.getFeatureByName(name).pipe(
            switchMap((feature) => {
                const defaults: Partial<FeatureStatus> = {
                    featureId: feature.featureId,
                    organisationId: teamOrOrg.organisationId,
                };
                if (teamOrOrg instanceof Team) {
                    defaults.teamId = teamOrOrg.teamId;
                }

                return this.commonDataService.create(FeatureStatusBreezeModel, defaults);
            }),
        );
    }

    private getFeatureByName(name: FeatureName) {
        return this.getAllFeatures().pipe(
            mergeAll(),
            filter((feature) => feature.name === name),
            filter(ObjectUtilities.assertIsInstanceFilter(Feature)),
            first(),
        );
    }
}

<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ManagePeople"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div adaptToolbarContent>
    <button adaptButtonIcon="add"
            data-test="add-people-button"
            (click)="addPeople()">Add People</button>
</div>

<adapt-manage-people-grid></adapt-manage-people-grid>

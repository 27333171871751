/* eslint-disable max-classes-per-file */
import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { OutstandingSurveyResponse, OutstandingSurveyResponseBreezeModel } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { ResilientBusinessSurveyConfigurationBreezeModel } from "@common/ADAPT.Common.Model/organisation/resilient-business-survey-configuration";
import { Survey, SurveyBreezeModel, SurveyEmailStatus, SurveyStatus, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyQuestionResponse } from "@common/ADAPT.Common.Model/organisation/survey-question-response";
import { SurveyResponse, SurveyResponseBreezeModel, SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { SurveySupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/survey-supplementary-data";
import { IEntityWithOptionalTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { ServiceUri } from "@common/configuration/service-uri";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UserService } from "@common/user/user.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import saveAs from "file-saver";
import { EMPTY, lastValueFrom, of, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { IEmailTemplate } from "../email/email-template.interface";
import { OrganisationService } from "../organisation/organisation.service";
import { SurveyAuthService } from "./survey-auth.service";
import { SurveyDetails } from "./survey-details";
import { ISurveyQuestions } from "./survey-questions.interface";
import { SurveyUtils } from "./survey-utils";

export interface ISurveyResponseStats {
    min: number;
    max: number;
    average: number;
}

export interface ISurveyQuestionScore {
    questionId: number;
    score: number;
    questionText: string;
}

interface IBindingCategories {
    [k: number]: { categoryName: string, questionIds: number[] }
}

interface ISurveyExportBindingModel {
    surveyId: number;
    questions: { [k: number]: string };
    choices: { [k: number]: string };
    categories?: IBindingCategories;
}

@Injectable({
    providedIn: "root",
})
export class SurveyService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
        private authService: SurveyAuthService,
        private httpClient: HttpClient,
        private userService: UserService,
        private orgService: OrganisationService,
    ) {
        super(injector);
    }

    public getResilientBusinessSurveyConfigurations = (forceRemote?: boolean) =>
        this.commonDataService.getAll(ResilientBusinessSurveyConfigurationBreezeModel, forceRemote);

    public createResilientBusinessSurveyConfigurationForCurrentOrganisation() {
        return this.currentOrganisation$.pipe(
            switchMap((org) => this.commonDataService.create(ResilientBusinessSurveyConfigurationBreezeModel, {
                organisationId: org.organisationId,
            })),
        );
    }

    public getSurveyById(surveyId: number, forceRemote?: boolean) {
        return this.commonDataService.getById(SurveyBreezeModel, surveyId, forceRemote);
    }

    public getQuestionsForSurvey(survey: Survey) {
        const surveyDetails = {
            organisationName: survey.organisation!.name,
            surveyEndTime: survey.endTime,
            surveyName: survey.name,
            surveyType: survey.surveyType,
        } as SurveyDetails;
        return SurveyUtils.getSurveyQuestions(surveyDetails);
    }

    public createSurvey(initialData?: Partial<Survey>) {
        return this.commonDataService.create(SurveyBreezeModel, initialData).pipe(
            switchMap(async (survey) => {
                survey.supplementaryData = await lastValueFrom(this.commonDataService.create(SurveySupplementaryDataBreezeModel, { survey }));
                return survey;
            }),
        );
    }

    public getSurveyResponsesByPredicate(predicate: MethodologyPredicate<SurveyResponse>, forceRemote?: boolean) {
        return this.commonDataService.getByPredicate(SurveyResponseBreezeModel, predicate, forceRemote);
    }

    public getOutstandingSurveyResponsesForPerson(forceRemote: boolean, personId: number) {
        const activeSurveyTypes = Object.values(SurveyType).filter((surveyType) => this.authService.forSurveyType(surveyType).isFeatureActive());
        if (activeSurveyTypes.length <= 0) {
            return of([]);
        } else {
            const key = `outstandingSurveyResponsesForPerson${personId}`;
            const predicate = new MethodologyPredicate<OutstandingSurveyResponse>("connection.personId", "==", personId)
                .and(new MethodologyPredicate<OutstandingSurveyResponse>("survey.surveyType", "in", activeSurveyTypes))
                .and(new MethodologyPredicate<OutstandingSurveyResponse>("survey.status", "==", SurveyStatus.Started));

            return this.commonDataService.getWithOptions(OutstandingSurveyResponseBreezeModel, key, {
                predicate,
                orderBy: "survey.endTime desc",
                forceRemote,
            });
        }
    }

    public getSupplementaryDataForSurvey(surveyId: number) {
        return this.commonDataService.getById(SurveySupplementaryDataBreezeModel, surveyId);
    }

    public getOutstandingSurveyResponsesForSurvey(surveyId: number, forceRemote: boolean) {
        const key = `outstandingSurveyResponsesForSurvey${surveyId}`;
        const predicate = new MethodologyPredicate<OutstandingSurveyResponse>("surveyId", "==", surveyId);

        return this.commonDataService.getWithOptions(OutstandingSurveyResponseBreezeModel, key, {
            predicate,
            forceRemote,
        });
    }

    public getOutstandingSurveyResponseCountForSurvey(surveyId: number) {
        const predicate = new MethodologyPredicate<OutstandingSurveyResponse>("surveyId", "==", surveyId);
        return this.commonDataService.getCountByPredicate(OutstandingSurveyResponseBreezeModel, predicate);
    }

    public getOngoingSurveys(surveyType: SurveyType, entity?: IEntityWithOptionalTeam) {
        // sorted by end time ascending
        return this.getSurveysOfStatusIfAuthorised(SurveyStatus.Started, surveyType, true, entity).pipe(
            map(SurveyUtils.sortSurveysByEndTimeAscendingOrder),
        );
    }

    public getUpcomingSurveys(surveyType: SurveyType, entity?: IEntityWithOptionalTeam) {
        // sorted by start time ascending
        return this.getSurveysOfStatusIfAuthorised(SurveyStatus.NotStarted, surveyType, false, entity).pipe(
            map(SurveyUtils.sortSurveysByStartTimeAscendingOrder),
        );
    }

    /**
     * this is only called from display-impact-surveys component where if entity with team is not defined, we want all teams instead
     * of entity with teamId of null
     */
    public getOngoingSurveysWithoutAuthCheck(surveyType: SurveyType, entity?: IEntityWithOptionalTeam) {
        const getSurveys$ = entity
            ? this.getSurveysOfStatus(SurveyStatus.Started, surveyType, true, entity)
            : this.getSurveysOfStatusForAllTeams(SurveyStatus.Started, surveyType, true);

        return getSurveys$.pipe(
            map(SurveyUtils.sortSurveysByEndTimeAscendingOrder),
        );
    }

    /**
     * same as above - only from display-impact-surveys which will return all surveys matching the survey type if entity
     * with team is not defined
     */
    public getUpcomingSurveysWithoutAuthCheck(surveyType: SurveyType, entity?: IEntityWithOptionalTeam) {
        const getSurveys$ = entity
            ? this.getSurveysOfStatus(SurveyStatus.NotStarted, surveyType, false, entity)
            : this.getSurveysOfStatusForAllTeams(SurveyStatus.NotStarted, surveyType, false);

        return getSurveys$.pipe(
            map(SurveyUtils.sortSurveysByStartTimeAscendingOrder),
        );
    }

    public getPreviousSurveys(surveyType: SurveyType, entity?: IEntityWithOptionalTeam) {
        // sorted by end time descending
        return this.getSurveysOfStatusIfAuthorised(SurveyStatus.Ended, surveyType, false, entity).pipe(
            map(SurveyUtils.sortSurveysByEndTimeDescendingOrder),
        );
    }

    public getMostRecentEndedSurveys(count: number, surveyType: SurveyType, entity?: IEntityWithOptionalTeam) {
        return this.authService.forSurveyType(surveyType).hasReadAccessToSurveys$(entity).pipe(
            switchMap((hasAccess) => {
                if (hasAccess) {
                    const key = "mostRecentEndedSurvey" + surveyType + "TeamId" + entity?.teamId;
                    const predicate = new MethodologyPredicate<Survey>("status", "==", SurveyStatus.Ended)
                        .and(new MethodologyPredicate<Survey>("surveyType", "==", surveyType))
                        .and(new MethodologyPredicate<Survey>("teamId", "==", entity ? entity.teamId : null));

                    return this.commonDataService.getWithOptions(SurveyBreezeModel, key, {
                        predicate,
                        orderBy: "endTime desc",
                        top: count,
                    });
                } else {
                    return EMPTY;
                }
            }),
        );
    }

    // cannot get survey question responses directly if survey response is not primed, which will cause
    // inconsistent local and server response and resulting in local entities used which will be empty
    // - querying survey response with nav properties instead
    public getSurveyQuestionResponses(survey: Survey, surveyQuestionId?: number) {
        return this.authService.forSurveyType(survey.surveyType).hasReadAccessToSurveys$(survey).pipe(
            switchMap((hasAccess) => {
                if (hasAccess) {
                    // don't put questionId here to use cache as we are accessing as nav prop of survey response
                    const key = "SurveyQuestionResponsesWithSurveyId" + survey.surveyId;
                    const predicate = new MethodologyPredicate<SurveyResponse>("surveyId", "==", survey.surveyId);
                    return this.commonDataService.getWithOptions(SurveyResponseBreezeModel, key, {
                        predicate,
                        navProperty: "surveyQuestionResponses",
                    });
                } else {
                    return EMPTY;
                }
            }),
            map((surveyResponses) => surveyResponses
                .map((response) => surveyQuestionId
                    ? response.surveyQuestionResponses.filter((i) => i.surveyQuestionId === surveyQuestionId)
                    : response.surveyQuestionResponses)
                .flat()),
        );
    }

    public getTopScoreQuestions(survey: Survey, topCount: number, surveyQuestions: ISurveyQuestions, focusCategoryId?: number, descending = true) {
        if (topCount < 1) {
            throw new Error("Cannot get top < 1");
        }

        const surveyTypeUtils = SurveyUtils.forSurveyType(survey.surveyType);
        return this.getSurveyQuestionResponses(survey).pipe(
            map((responses) => {
                const questionIds = focusCategoryId ? surveyQuestions.getQuestionIdsForCategory(focusCategoryId) : surveyQuestions.orderedQuestionIds;
                if (!questionIds) {
                    throw new Error(`No questions in category ${focusCategoryId} to process top score`);
                }

                const totalScores: { [questionId: number]: number } = {};
                const responseCounts: { [questionId: number]: number } = {};
                responses.forEach((response) => {
                    if (questionIds.find((questionId) => questionId === response.surveyQuestionId)) {
                        if (!(response.surveyQuestionId in totalScores)) {
                            totalScores[response.surveyQuestionId] = 0;
                            responseCounts[response.surveyQuestionId] = 0;
                        }
                        totalScores[response.surveyQuestionId] += response.response - 1; // 0 is not answered, worst is 1
                        responseCounts[response.surveyQuestionId]++;
                    }
                });

                // clone the array as we are ordering according to the totalScores here
                const questionsSortedByScore = [...questionIds];
                questionsSortedByScore.sort((a, b) => descending
                    ? (totalScores[b] ?? 0) - (totalScores[a] ?? 0)
                    : (totalScores[a] ?? 0) - (totalScores[b] ?? 0));

                const topScoreQuestions = questionsSortedByScore.slice(0, topCount);
                return topScoreQuestions.map((questionId) => ({
                    questionId,
                    score: surveyTypeUtils.convertScoreToPercentage(1 +
                        (responseCounts[questionId] ? (totalScores[questionId] / responseCounts[questionId]) : 0)),
                    questionText: surveyQuestions.getQuestion(questionId),
                } as ISurveyQuestionScore))
                    .filter((e) => descending ? e.score > surveyTypeUtils.goodPercentageThreshold : e.score < surveyTypeUtils.goodPercentageThreshold);
            }),
        );
    }

    public getSurveyQuestionResponseStats(survey: Survey, surveyQuestionId: number, responseGroup: SurveyResponseGroup) {
        const surveyTypeUtils = SurveyUtils.forSurveyType(survey.surveyType);
        return this.getSurveyQuestionResponses(survey, surveyQuestionId).pipe(
            map((responses) => responses
                .filter(surveyTypeUtils.filterQuestionResponse)
                .filter((response) => responseGroup === SurveyResponseGroup.All || response.surveyResponse!.responseGroup === responseGroup)),
            map((responses) => {
                let min = 0;
                let max = 0;
                // total is 0-based while min/max are 1-based with 0 indicating no response
                // as strongly disagree scores 0% and stringly agree 100%
                let total = 0;
                responses.forEach((response) => {
                    if (!min || response.response < min) {
                        min = response.response;
                    }

                    if (!max || response.response > max) {
                        max = response.response;
                    }

                    total += response.response - 1;
                });

                const average = responses.length > 0 ? total / responses.length : 0;
                return {
                    min: surveyTypeUtils.convertScoreToPercentage(min),
                    max: surveyTypeUtils.convertScoreToPercentage(max),
                    average: surveyTypeUtils.convertScoreToPercentage(average + 1),
                } as ISurveyResponseStats;
            }),
        );
    }

    public getCategoryPercentageScoreForSurvey(categoryId: number, survey: Survey, surveyQuestions: ISurveyQuestions) {
        return this.getSurveyQuestionResponses(survey).pipe(
            map((questionResponses) =>
                SurveyUtils.getCategoryPercentageScore(categoryId, surveyQuestions, questionResponses, survey.surveyType)),
        );
    }

    public getSurveyDetails(token: string) {
        return this.httpClient.get<SurveyDetails>(
            `${ServiceUri.SurveyServiceBaseUri}/GetSurveyDetails`,
            { params: { token } },
        ).pipe(
            tap((surveyDetails) => surveyDetails.surveyEndTime = new Date(surveyDetails.surveyEndTime)), // convert ISO to local time
        );
    }

    public startSurvey(survey: Survey) {
        return this.httpClient.get<number | undefined>(
            `${ServiceUri.SurveyServiceBaseUri}/StartSurvey`,
            { params: { surveyId: survey.surveyId, organisationId: survey.organisationId } },
        ).pipe(
            switchMap((changedSurveyId) => {
                if (changedSurveyId) {
                    return this.getSurveyById(changedSurveyId);
                } else {
                    return of(undefined);
                }
            }),
        );
    }

    public resendSurveyEmail(token: string) {
        return this.httpClient.post<string>(
            `${ServiceUri.SurveyServiceBaseUri}/ResendSurveyEmail`,
            undefined,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Survey-Token": token,
                },
            },
        );
    }

    public postSurveyResponse(response: SurveyResponseBindingModel) {
        return this.httpClient.post<SurveyResponseBindingModel>(
            `${ServiceUri.SurveyServiceBaseUri}/SubmitSurveyResponse`,
            response,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    }

    public surveyExcelExport(survey: Survey) {
        const surveyTypeUtils = SurveyUtils.forSurveyType(survey.surveyType);

        const surveyDetails = {
            organisationName: survey.organisation!.name,
            surveyEndTime: survey.endTime,
            surveyName: survey.name,
            surveyType: survey.surveyType,
        } as SurveyDetails;

        const questions = surveyTypeUtils.getSurveyQuestions(surveyDetails);

        const bindingModel: ISurveyExportBindingModel = {
            surveyId: survey.surveyId,
            questions: questions.orderedQuestionIds.reduce((acc, qId) => {
                acc[qId] = questions.getQuestion(qId)!;
                return acc;
            }, {} as { [k: number]: string }),
            choices: questions.surveyResponseChoices.reduce((acc, choice) => {
                acc[choice.value] = choice.text;
                return acc;
            }, {} as { [k: number]: string }),
            categories: undefined,
        };

        if (questions.categoryIds) {
            bindingModel.categories = questions.categoryIds.reduce((acc, categoryId) => {
                const category = questions.getCategory(categoryId)!;
                acc[categoryId] = {
                    categoryName: category.categoryName,
                    questionIds: category.questionIds,
                };
                return acc;
            }, {} as IBindingCategories);
        }

        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/SurveyResponsesExport`;
        return this.httpClient.post(
            uri,
            bindingModel,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob", // makes response.body a Blob object
                observe: "response", // without this, response.body will be unknown
            },
        ).pipe(
            catchError((e) => throwError(() => ErrorHandlingUtilities.getHttpResponseMessage(e))),
            tap((response) => {
                const name = `${survey.surveyType}_${survey.name}`;
                saveAs(response.body!, `${name}.xlsx`);
            }),
        );
    }

    // this is only called from edit survey dialog (which already has edit permission - don't have to check for permission again)
    public getFirstOverlappingSurvey(survey: Survey) {
        // there will only be a few non-completed survey in each org - get all and use entity cache for subsequent call
        const predicate = new MethodologyPredicate<Survey>("status", "!=", SurveyStatus.Ended)
            .and(new MethodologyPredicate<Survey>("surveyType", "==", survey.surveyType))
            .and(new MethodologyPredicate<Survey>("teamId", "==", survey.teamId));
        return this.getSurveysByPredicate(predicate, false).pipe(
            map((surveys) => surveys.find((s) => {
                return s !== survey
                    && (overlap(survey.startTime, s)
                        || overlap(survey.endTime, s)
                        || overlap(s.startTime, survey)
                        || overlap(s.endTime, survey));
            })),
        );

        function overlap(time: Date, s: Survey) {
            return s.startTime <= time && s.endTime >= time;
        }
    }

    public getSurveyTemplate(survey: Survey, useCustomMessage = true, personId?: number) {
        const url = `${ServiceUri.SurveyServiceBaseUri}/GetSurveyEmailPreview`;
        return this.currentOrganisation$.pipe(
            switchMap((organisation) => this.httpClient.post<IEmailTemplate>(url, {
                Name: survey.name,
                OrganisationId: organisation.organisationId,
                TeamId: survey.teamId,
                CreatedById: personId ?? survey.createdById,
                SurveyorId: survey.surveyorId,
                CustomMessage: useCustomMessage ? survey.supplementaryData.message : null,
                SurveyType: survey.surveyType,
                EndTime: survey.endTime,
            }, {
                responseType: "json",
                headers: {
                    "Content-Type": "application/json",
                },
            })),
        );
    }

    public registerLearnMoreUrlForSurveyType(surveyType: SurveyType, url: string) {
        SurveyUtils.forSurveyType(surveyType).learnMoreUrl = url;
    }

    public launchOrganisationDiagnostic(customMessageText?: string) {
        const surveyData = {
            organisationId: this.orgService.getOrganisationId(),
            createdById: this.userService.getCurrentPersonId(),
            name: "Health Check",
            status: SurveyStatus.NotStarted,
            startTime: SurveyUtils.startOfHour(),
            endTime: SurveyUtils.defaultEndTime(),
            surveyType: SurveyType.OrganisationDiagnostic,
            emailStatus: SurveyEmailStatus.NotSent,
            surveyorId: this.userService.getCurrentPersonId(),
        };
        return this.createSurvey(surveyData).pipe(
            tap((survey) => survey.supplementaryData.message = customMessageText),
            switchMap((survey) => this.commonDataService.saveEntities([survey, survey.supplementaryData]).pipe(
                map(() => survey),
            )),
        );
    }

    public primeSurveysIfAuthorised(surveyType: SurveyType, entity?: IEntityWithOptionalTeam) {
        return this.authService.forSurveyType(surveyType).hasReadAccessToSurveys$(entity).pipe(
            switchMap((hasAccess) => {
                if (hasAccess) {
                    const predicate = new MethodologyPredicate<Survey>("surveyType", "==", surveyType)
                        .and(new MethodologyPredicate<Survey>("teamId", "==", entity ? entity.teamId : null));
                    return this.getSurveysByPredicate(predicate);
                } else {
                    return of([]);
                }
            }),
        );
    }

    private getSurveysOfStatusIfAuthorised(status: SurveyStatus, surveyType: SurveyType, forceRemote = false, entity?: IEntityWithOptionalTeam) {
        return this.authService.forSurveyType(surveyType).hasReadAccessToSurveys$(entity).pipe(
            switchMap((hasAccess) => {
                if (hasAccess) {
                    return this.getSurveysOfStatus(status, surveyType, forceRemote, entity);
                } else {
                    return of([]);
                }
            }),
        );
    }

    private getSurveysOfStatus(status: SurveyStatus, surveyType: SurveyType, forceRemote = false, entity?: IEntityWithOptionalTeam) {
        const predicateWithoutStatus = new MethodologyPredicate<Survey>("surveyType", "==", surveyType)
            .and(new MethodologyPredicate<Survey>("teamId", "==", entity ? entity.teamId : null));
        const predicate = new MethodologyPredicate<Survey>("status", "==", status)
            .and(predicateWithoutStatus);

        return this.getSurveysByPredicate(predicate, forceRemote, predicateWithoutStatus.getKey(SurveyBreezeModel.identifier));
    }

    private getSurveysOfStatusForAllTeams(status: SurveyStatus, surveyType: SurveyType, forceRemote = false) {
        const predicate = new MethodologyPredicate<Survey>("status", "==", status)
            .and(new MethodologyPredicate<Survey>("surveyType", "==", surveyType));

        return this.getSurveysByPredicate(predicate, forceRemote);
    }

    private getSurveysByPredicate(predicate: MethodologyPredicate<Survey>, forceRemote = false, encompassingKey?: string) {
        if (!encompassingKey) {
            return this.commonDataService.getByPredicate(SurveyBreezeModel, predicate, forceRemote);
        } else {
            return this.commonDataService.getWithOptions(SurveyBreezeModel, predicate.getKey(SurveyBreezeModel.identifier), {
                encompassingKey,
                predicate,
            });
        }
    }
}

export class SurveyResponseBindingModel {
    public token!: string;
    public surveyType!: SurveyType;
    public questionResponses!: Partial<SurveyQuestionResponse>[];
}

import { INavigationHierarchy } from "@common/route/navigation-hierarchy";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { defer, EMPTY, merge, Observable, ObservableInput } from "rxjs";
import { map, switchMap } from "rxjs/operators";

export abstract class BaseOrgCommonNavigationHierarchyService implements INavigationHierarchy {
    public abstract id: string;
    public hierarchyNode$: Observable<INavigationNode | undefined>;

    /** Set this in the implementing class to rebuild the hierarchy on a custom event */
    protected rebuildHierarchy$: Observable<unknown> = EMPTY;

    public constructor(
        orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
    ) {
        const deregisterHierarchy$ = merge(
            orgService.organisationChanging$,
            authNotification.authorisationChanging$,
        ).pipe(
            map(() => undefined),
        );
        const buildHierarchy$ = merge(
            authNotification.authorisationChanged$,
            // Defer so that if rebuildHierarchy is set by the implementing class
            // then it will actually be it's final value by the time this is
            // subscribed to
            defer(() => this.rebuildHierarchy$),
        ).pipe(
            switchMap(() => this.buildHierarchy()),
        );
        this.hierarchyNode$ = merge(
            deregisterHierarchy$,
            buildHierarchy$,
        );
    }

    protected abstract buildHierarchy(): ObservableInput<INavigationNode>;
}

<div class="card"
     [ngClass]="cardClasses"
     [ngStyle]="cardStyles">
    <div class="objective-list-item-body">
        <div class="objective-heading"
             [ngStyle]="headingStyles">
            <div class="objective-title align-items-center">
                <adapt-link-objective class="me-auto"
                                      [objective]="objective"
                                      [showStatus]="false"
                                      [showIcon]="true"
                                      [showStatusIcon]="false"></adapt-link-objective>
                <adapt-link-objectives-page *ngIf="isExternalObjective"
                                            class="px-3"
                                            [team]="objective.team"
                                            [view]="treeViewType"></adapt-link-objectives-page>
                <adapt-display-objective-status [objectiveStatus]="objectiveStatus"></adapt-display-objective-status>
            </div>
            <!-- Need the extra div so the alignment margins will always apply even when the button isn't shown -->
            <div [ngStyle]="keyResultToggleStyles">
                <button *ngIf="isFocussed"
                        [style.visibility]="!isContextualObjective ? 'visible' : 'hidden'"
                        class="btn"
                        (click)="toggleKeyResults()">
                    <i class="key-result-chevron"
                       [ngClass]="keyResultChevronIconClass"></i>
                </button>
            </div>
        </div>
        <ng-container *ngIf="isFocussed && isLG">
            <ng-container *ngTemplateOutlet="progressTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
        </ng-container>

        <adapt-objective-actions *ngIf="hasEditPermissions"
                                 class="ms-3"
                                 [objective]="objective"></adapt-objective-actions>
    </div>
    <adapt-display-labels *ngIf="objective.labelLocations?.length"
                          class="mb-1"
                          [labelLocations]="objective.labelLocations"></adapt-display-labels>
    <div class="objective-list-item-body-sm"
         *ngIf="isFocussed && !isLG">
        <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="progressTemplate"></ng-container>
    </div>
    <ng-template #metadataTemplate>
        <div class="objective-metadata">
            <adapt-link-person [personId]="objective.assigneePersonId"
                               [showImage]="true"></adapt-link-person>
            <div class="objective-due-date">
                <strong>Due:</strong> {{objective.dueDate | adaptDate}}
            </div>
        </div>
    </ng-template>
    <ng-template #progressTemplate>
        <div class="objective-progress">
            <adapt-display-objective-progress [objective]="objective"></adapt-display-objective-progress>
        </div>
    </ng-template>

    <div *ngIf="keyResultsExpanded">
        <div *ngIf="objective.keyResults.length === 0">
            There are no Key Results for this Objective.
        </div>
        <adapt-key-result-list-item *ngFor="let keyResult of objective.keyResults"
                                    [keyResult]="keyResult"
                                    [depth]="depth"></adapt-key-result-list-item>
    </div>
</div>

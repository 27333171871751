import { SurveySupplementaryData } from "@common/ADAPT.Common.Model/organisation/survey-supplementary-data";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Organisation } from "./organisation";
import { OutstandingSurveyResponse } from "./outstanding-survey-response";
import { SurveyResponse } from "./survey-response";
import { SurveyResult } from "./survey-result";
import { Team } from "./team";

export enum SurveyStatus {
    NotStarted = "NotStarted",
    Started = "Started",
    Ended = "Ended",
}

export enum SurveyEmailStatus {
    NotSent = "NotSent",
    InvitationProcessed = "InvitationProcessed",
    ReminderCompleted = "ReminderCompleted",
    InvitationCompleted = "InvitationCompleted",
    CleanupCompleted = "CleanupCompleted",
}

export enum SurveyType {
    EmployeeEngagement = "EmployeeEngagement",
    TeamAssessment = "TeamAssessment",
    ResilientBusinessAssessment = "ResilientBusinessAssessment",
    OrganisationDiagnostic = "OrganisationDiagnostic",
}

export const SurveyTypeLabel: { [type in SurveyType]: string } = {
    EmployeeEngagement: "Employee Engagement",
    TeamAssessment: "Team Assessment",
    ResilientBusinessAssessment: "Resilient Business Assessment",
    OrganisationDiagnostic: "Health Check",
};

export const SurveyTypeManageLabel = "Manage Surveys";

export class Survey extends BaseEntity<Survey> {
    public surveyId!: number;
    public organisationId!: number;
    public surveyType!: SurveyType;
    public teamId?: number;
    public name!: string;
    public startTime!: Date;
    public endTime!: Date;
    public status!: SurveyStatus;
    public createdById!: number;
    public surveyorId!: number;
    public emailStatus!: SurveyEmailStatus;
    public message?: string;

    // nav properties
    public organisation?: Organisation;
    public team?: Team;
    public createdBy?: Person;
    public surveyor?: Person;
    public surveyResponses!: SurveyResponse[];
    public surveyResults!: SurveyResult[];
    public outstandingSurveyResponses!: OutstandingSurveyResponse[];
    public supplementaryData!: SurveySupplementaryData;
}

export const SurveyBreezeModel = new BreezeModelBuilder("Survey", Survey)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .alwaysFetchingNavigationProperty("surveyResults")
    .build();

import { AfterViewChecked, Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Goal, GoalStatus } from "@common/ADAPT.Common.Model/organisation/goal";
import { Measurement, MeasurementType } from "@common/ADAPT.Common.Model/organisation/measurement";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { StrategicViewIcon } from "@org-common/lib/strategy/strategy-view-constants";
import moment from "moment";
import { filter, switchMap } from "rxjs";
import { StrategicGoalsService } from "../strategic-goals.service";

@Component({
    selector: "adapt-strategic-goal",
    templateUrl: "./strategic-goal.component.html",
    styleUrls: ["./strategic-goal.component.scss"],
})
export class StrategicGoalComponent extends BaseComponent implements AfterViewChecked, OnChanges {
    public readonly GoalIconClass = StrategicViewIcon.GoalIcon;

    @Input() public isEditing = false;
    @Input() public goal!: Goal;
    @Input() public isExpanded = false;
    @Input() public isDraggable = false;

    public statusText?: string;
    public graphRendered = false;
    // need this so that graph won't push the width of the card out
    public graphWidth?: number;
    private graphWidthUpdater = this.createThrottledUpdater<number>((width) => this.graphWidth = width);

    public constructor(
        elementRef: ElementRef,
        private goalsService: StrategicGoalsService,
        private dialogService: AdaptCommonDialogService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChanged(Measurement).pipe(
            filter((measurement) => measurement.goalId === this.goal.goalId && measurement.type === MeasurementType.Target),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateStatusText());
    }

    public get expandButtonIcon() {
        return this.isExpanded
            ? "fal fa-chevron-up"
            : "fal fa-chevron-down";
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.goal && this.goal) {
            this.updateStatusText();
        }
    }

    public ngAfterViewChecked() {
        const elementWidth = this.elementRef?.nativeElement.querySelector(".card").offsetWidth;
        if (elementWidth > 48) {
            this.graphWidthUpdater.next(elementWidth - 48); // left and right padding
        }
    }

    public expand(expanding: boolean) {
        this.isExpanded = expanding;
    }

    public saveGoalStatus(status: GoalStatus) {
        this.goal.status = status;
        this.goalsService.saveEntities(this.goal).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    public editGoal() {
        return this.goalsService.editGoal(this.goal);
    }

    @Autobind
    public deleteGoal() {
        const dialogData: IConfirmationDialogData = {
            title: "Deleting Strategic Goal",
            message: `<p>You are about to delete strategic goal with name:</p>
                <blockquote><b>${this.goal.name}</b></blockquote>
                <p>All the corresponding measurements will also be deleted. If you choose to delete, they can no longer be recovered.</p>
                <p>Are you sure you want to continue?</p>`,
            confirmButtonText: "Confirm & Delete",
            cancelButtonText: "Cancel",
            checkboxMessage: "Check here to confirm that you want to delete the goal permanently",
        };
        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            switchMap(() => this.goalsService.remove(this.goal)),
            switchMap(() => this.goalsService.saveEntities(this.goal)),
        );
    }

    @Autobind
    public recordNewMeasurement() {
        return this.goalsService.recordNewMeasurement(this.goal).pipe(
            this.takeUntilDestroyed(),
        );
    }

    private updateStatusText() {
        const targets = this.goal.measurements.filter((measurement) => measurement.type === MeasurementType.Target);
        if (targets.length > 0) {
            this.statusText = `Due date: ${moment(targets[targets.length - 1].timestamp).format("MM/yyyy")}`;
        } else {
            this.statusText = "This goal does not have any milestones defined";
        }
    }
}

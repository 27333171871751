<div class="d-flex flex-column mt-4">
    <div class="alert alert-light d-flex justify-content-center align-items-center">
        <h3 class="mb-0">{{titleText}}</h3>
        <button class="ms-4 btn btn-blank"
                *ngIf="categoryId"
                data-tour="clear-category-summary"
                adaptTooltip="Return to organisation highlights"
                adaptButtonIcon="fal fa-arrow-turn-down-left"
                (click)="clearCategoryId()"></button>
    </div>

    <div class="mt-1 d-flex align-items-stretch">
        <div class="d-flex align-items-stretch"
             data-tour="category-summary-average"
             [ngClass]="getColumnClass(0)"
             *ngIf="gradeSummaryText">
            <div class="d-flex flex-column justify-content-baseline alert alert-secondary w-100">
                <adapt-display-category-percentage class="d-flex justify-content-center"
                                                   text="Average"
                                                   [percentage]="percentageScore"
                                                   [surveyType]="survey?.surveyType"></adapt-display-category-percentage>
                <div class="mt-3">{{gradeSummaryText}}</div>
            </div>
        </div>
        <div class="d-flex align-items-stretch"
             data-tour="category-summary-doing-well"
             [ngClass]="getColumnClass(1)">
            <div class="d-flex flex-column justify-content-baseline alert alert-success w-100">
                <h5>What your team are doing well at</h5>
                <div class="w-100 alert alert-success d-flex good-style"
                     *ngFor="let top of topScoreQuestions">
                    <span class="me-2">{{top.questionText}}</span>
                    <span class="ms-auto">{{top.score}}%</span>
                </div>
                <span *ngIf="topScoreQuestions.length === 0">You have a lot of work to do ☹</span>
            </div>
        </div>
        <div class="d-flex align-items-stretch"
             data-tour="category-summary-need-improvement"
             [ngClass]="getColumnClass(2)">
            <div class="d-flex flex-column justify-content-baseline alert alert-danger w-100">
                <h5>What your team can improve on</h5>
                <div class="w-100 alert alert-danger d-flex bad-style"
                     *ngFor="let top of worstScoreQuestions">
                    <span class="me-2">{{top.questionText}}</span>
                    <span class="ms-auto">{{top.score}}%</span>
                </div>
                <span *ngIf="worstScoreQuestions.length === 0">You're doing great!</span>
            </div>
        </div>
    </div>
</div>

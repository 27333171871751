import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { ISeed } from "../../shared/define-seed/seed.interface";

export type VisionSeed = Exclude<Zone, "ResearchAndDevelopment">;

export const VisionSeeds: ISeed<VisionSeed>[] = [
    {
        key: Zone.EconomicEngine,
        question: ZoneMetadata.Name[Zone.EconomicEngine],
        articleSlug: ImplementationKitArticle.AlignOwnershipVisionEconomicEngine,
    },
    {
        key: Zone.HealthyCulture,
        question: ZoneMetadata.Name[Zone.HealthyCulture],
        articleSlug: ImplementationKitArticle.AlignOwnershipVisionHealthyCulture,
    },
    {
        key: Zone.FinanceAndOwnership,
        question: ZoneMetadata.Name[Zone.FinanceAndOwnership],
        articleSlug: ImplementationKitArticle.AlignOwnershipVisionFinanceAndOwnership,
    },
    {
        key: Zone.OrganisationDesign,
        question: ZoneMetadata.Name[Zone.OrganisationDesign],
        articleSlug: ImplementationKitArticle.AlignOwnershipVisionOrganisationDesign,
    },
];

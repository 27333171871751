<div *adaptLoading="isLoading">
    <div *ngIf="error"
         class="alert alert-danger">
        We're sorry, but there was an issue loading the article content :(
        <br /><br />
        {{error}}
    </div>

    <ng-container *ngIf="article">
        <div [froalaView]="article.answer"
             (contentLoaded)="onContentLoaded()"></div>
    </ng-container>
</div>

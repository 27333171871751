import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { delay, tap } from "rxjs";
import { ICardPartialDetails } from "../card-partial-details.interface";
import { ICardOrganisationIdentifier, PaymentProcessingService } from "../payment-processing.service";

export interface IUpdateCreditCardInput {
    cardDetails?: ICardPartialDetails;
    organisationIdentifier: ICardOrganisationIdentifier;
}

export interface IUpdateCreditCardOutput {
    stripeCard?: stripe.elements.Element,
    cardDetails?: ICardPartialDetails,
}

@Component({
    selector: "adapt-update-credit-card-details",
    templateUrl: "./update-credit-card-details.component.html",
    styleUrls: ["./update-credit-card-details.component.scss"],
})
export class UpdateCreditCardDetailsComponent extends BaseComponent implements OnInit {

    @Input() public updateCreditCardInput!: IUpdateCreditCardInput;
    @Output() public cardSet = new EventEmitter<any>();

    public cardDetails?: ICardPartialDetails;
    public organisationIdentifier!: ICardOrganisationIdentifier;

    public stripeCard?: stripe.elements.Element;
    public stripeCardComplete: boolean = false;

    public constructor(
        private paymentProcessingService: PaymentProcessingService) {
        super();
    }

    public ngOnInit() {
        const self = this;

        this.cardDetails = this.updateCreditCardInput.cardDetails;
        this.organisationIdentifier = this.updateCreditCardInput.organisationIdentifier;

        if (!this.cardDetails) {
            this.cardDetails = {} as ICardPartialDetails;
        }

        self.paymentProcessingService.stripe$.pipe(
            // give angular time to build up the DOM prior to getting Stripe to create the card elements
            delay(0),
            tap(createStripeElements),
        ).subscribe();

        function createStripeElements(stripe: stripe.Stripe) {
            const elements = stripe.elements();
            if (!elements) {
                throw new Error("Can't create credit card elements (1).");
            }

            self.stripeCard = elements.create("card");
            if (!self.stripeCard) {
                throw new Error("Can't create credit card elements (2).");
            }

            self.stripeCard.on("change", handleStripeElementChange);
            self.stripeCard.mount("#card-element");

            function handleStripeElementChange(e: stripe.elements.ElementChangeResponse) {
                self.stripeCardComplete = e.complete;
                self.cardSet.emit(self.stripeCardComplete);
            }
        }
    }

    public saveCreditCard() {
        return this.paymentProcessingService.setCardDetails(this.organisationIdentifier, this.stripeCard!, this.cardDetails!.name);
    }
}

import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "../workflow-identifier.enum";

export const doTheRightWorkWorkflowName = "Do the right work";

export const doTheRightWorkWorkflow = buildLocalWorkflow({
    name: doTheRightWorkWorkflowName,
    workflowId: WorkflowIdentifier.DoTheRightWork,
    type: WorkflowType.Journey,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Make sure your leadership team is doing the 'right' work. Understand the concepts of 'in' & 'on', and how to leverage your time.",
    category: OrganisationCategoryValue.DoTheRightWork,
    articleSlug: ImplementationKitArticle.ComingSoon, // a coming soon HelpJuice document
    /*
    articleSlug: "2076624",
    wrapUpSlug: "2076625",
    workflows: [
        ...createWorkshopScheduleWorkflows(doTheRightWorkWorkflowName, WorkflowIdentifier.DoTheRightWork),
    ],
    */
});

<ng-container *ngIf="meeting">
    <adapt-meeting-integration-prompt [disabled]="disabled"
                                      [showLocal]="showInvitationPrompt"
                                      [linkDisabled]="true"
                                      [contentTemplate]="checkingProviderStatus ? checkingProviderStatusDisplay : integrationOptions"></adapt-meeting-integration-prompt>

    <ng-template #checkingProviderStatusDisplay>
        <div class="not-organiser-warning w-100">
            <adapt-loading-spinner></adapt-loading-spinner>
        </div>
    </ng-template>

    <ng-template #integrationOptions>
        <div *ngIf="!isOrganiser; else canSync"
             class="alert alert-warning not-organiser-warning small p-2">
            Only <b>{{organiserName}}</b> can update the calendar entry.
            <br />
            Please ask them to sync the meeting once you have finished editing the meeting.
        </div>
        <ng-template #canSync>
            <div *ngIf="showInvitationPrompt || showTeamsPrompt"
                 class="d-flex flex-column gap-2 align-self-end">
                <div *ngIf="showInvitationPrompt">
                    <dx-check-box text="Automatically send calendar invitations"
                                  [value]="sendInvitations"
                                  (valueChange)="onSendInvitationsChange($any($event))"></dx-check-box>
                    <div *ngIf="!sendInvitations"
                         class="mt-1 small">Invitations can still be sent manually later on.
                    </div>
                </div>
                <div *ngIf="showTeamsPrompt">
                    <dx-check-box text="Create Microsoft Teams meeting"
                                  [disabled]="!sendInvitations"
                                  [value]="createTeamsMeeting || existingTeamsMeeting"
                                  (valueChange)="onCreateTeamsMeetingChange($any($event))"></dx-check-box>
                </div>
            </div>
        </ng-template>
    </ng-template>

    <ng-container *ngIf="editableDetails">
        <h3>Title</h3>
        <dx-text-box #focus
                     [value]="meeting.name"
                     (valueChange)="onMeetingNameChange($event)"
                     [disabled]="disabled"
                     placeholder="Enter meeting title"
                     [adaptValidateEntity]="meeting"
                     adaptValidateEntityProperty="name"></dx-text-box>
    </ng-container>

    <h3 class="mt-3">Attendees</h3>
    <adapt-select-people [people]="attendeePeople"
                         (peopleChange)="onMeetingAttendeesChange($event)"
                         [teamGroup]="meeting.team"
                         [showClearButton]="false"
                         [filter]="meetingAttendeeFilterFunction"
                         [disabled]="disabled"
                         data-test="add-attendee"></adapt-select-people>
    <adapt-schedule-meeting-display-availability *ngIf="attendeePeople.length > 0 && loadingAvailability"
                                                 [loading]="true"></adapt-schedule-meeting-display-availability>
    <ng-container *ngIf="!loadingAvailability">
        <adapt-schedule-meeting-display-availability *ngIf="peopleAvailable.length > 0"
                                                     [names]="peopleAvailable"
                                                     [available]="true"></adapt-schedule-meeting-display-availability>
        <adapt-schedule-meeting-display-availability *ngIf="peopleUnavailable.length > 0"
                                                     [names]="peopleUnavailable"
                                                     [available]="false"></adapt-schedule-meeting-display-availability>
        <adapt-schedule-meeting-display-availability *ngIf="peopleNoEmail.length > 0 && sendInvitations"
                                                     [names]="peopleNoEmail"
                                                     [available]="false"
                                                     message="{{peopleNoEmail.length > 1 ? 'have' : 'has'}} their login email set to private and therefore will not receive an invite. ">
            <a [href]="PrivateEmailLearnMoreUrl"
               target="_blank">Click here</a> to find out more.
        </adapt-schedule-meeting-display-availability>
    </ng-container>

    <div class="mt-3 row">
        <div class="col-6">
            <h3>Start Time</h3>
            <dx-date-box type="datetime"
                         applyValueMode="instantly"
                         [useMaskBehavior]="true"
                         [value]="meeting.meetingDateTime"
                         (valueChange)="onMeetingStartTimeChange($any($event))"
                         [displayFormat]="meeting.extensions.dateFormat"
                         [disabled]="disabled"
                         data-test="meeting-date">
                <dxo-calendar-options [showTodayButton]="true"></dxo-calendar-options>
            </dx-date-box>
        </div>
        <div class="col-6">
            <h3>Duration</h3>
            <dx-select-box [items]="meeting.extensions.durationOptions"
                           [value]="meeting.extensions.selectedDuration"
                           (valueChange)="onMeetingDurationChange($event)"
                           [searchEnabled]="true"
                           displayExpr="text"
                           placeholder="Select duration..."
                           [showClearButton]="false"
                           [disabled]="disabled"
                           data-test="meeting-duration"></dx-select-box>
            <small class="text-muted">End time: {{meeting.endTime | adaptTime}}</small>
        </div>
    </div>

    <div class="mt-2">
        <h3>Location</h3>
        <adapt-select-meeting-location [location]="meetingLocation"
                                       (locationChange)="onMeetingLocationChange($event)"
                                       [required]="false"
                                       [disabled]="disabled"></adapt-select-meeting-location>
        <adapt-schedule-meeting-display-availability *ngIf="meetingLocation && meetingLocation.emailAddress"
                                                     [loading]="loadingAvailability"
                                                     [names]="[meetingLocation.name ?? meetingLocation.emailAddress]"
                                                     [available]="locationAvailable"></adapt-schedule-meeting-display-availability>
    </div>
</ng-container>

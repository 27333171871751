<nav class="navbar"
     [ngClass]="options?.navClass || 'navbar-light'">
    <a class="masthead-image"
       [routerLink]="options?.mastheadHref || defaultMastheadHref">
        <adapt-storage-image [imageIdentifier]="options?.mastheadImageId"
                             [defaultImageSrc]="options?.defaultMastheadImageSrc"
                             [imageHeight]="40"></adapt-storage-image>
    </a>
    <div *ngIf="options?.textAfterLogo"
         class="mx-1 d-flex align-items-center after-logo">
        <h2>{{options!.textAfterLogo}}</h2>
    </div>

    <adapt-navigation-breadcrumbs id="navigation-breadcrumbs"></adapt-navigation-breadcrumbs>

    <div class="application-bar-items d-print-none">
        <ng-container *ngFor="let item of items">
            <adapt-shell-popover-link-item *ngIf="item.isShown$ | async"
                                           [item]="item"></adapt-shell-popover-link-item>
        </ng-container>
    </div>

    <ul class="navbar-nav d-md-none d-print-none">
        <li class="nav-item">
            <button class="btn nav-link btn-outline-secondary"
                    adaptButtonIcon="fal fa-bars"
                    (click)="openApplicationBarMenu()">Menu</button>
        </li>
    </ul>
</nav>

<ng-container *ngIf="type === searchType.Page">
    <adapt-search-row-page *ngFor="let result of searchResults.Page"
                            [result]="result"></adapt-search-row-page>
</ng-container>
<ng-container *ngIf="type === searchType.People">
    <adapt-search-row-person *ngFor="let result of searchResults.People"
                                [result]="result"></adapt-search-row-person>
</ng-container>
<!-- <ng-container *ngIf="type === searchType.Role">
    <adapt-search-row-role *ngFor="let result of searchResults.Role"
                            [result]="result"></adapt-search-row-role>
</ng-container> -->
<ng-container *ngIf="type === searchType.Team">
    <adapt-search-row-team *ngFor="let result of searchResults.Team"
                            [result]="result"></adapt-search-row-team>
</ng-container>
<!-- <ng-container *ngIf="type === searchType.Kanban">
    <adapt-search-row-kanban *ngFor="let result of searchResults.Kanban"
                                [result]="result"></adapt-search-row-kanban>
</ng-container>
<ng-container *ngIf="type === searchType.Meeting">
    <adapt-search-row-meeting *ngFor="let result of searchResults.Meeting"
                                [result]="result"></adapt-search-row-meeting>
</ng-container>
<ng-container *ngIf="type === searchType.Objective">
    <adapt-search-row-objective *ngFor="let result of sortByTeamId(searchResults.Objective)"
                                [result]="$any(result)"></adapt-search-row-objective>
</ng-container> -->
<ng-container *ngIf="type === searchType.Guidance">
    <ng-container *ngFor="let result of sortByTeamId(searchResults.Guidance)">
        <adapt-search-row-purpose-vision *ngIf="isPurposeVision(result) as purposeVision"
                                            [result]="purposeVision"></adapt-search-row-purpose-vision>
        <adapt-search-row-value *ngIf="isValue(result) as value"
                                [result]="value"></adapt-search-row-value>
        <!-- <adapt-search-row-resilient-business-goal *ngIf="isResilientBusinessGoal(result) as rbg"
                                                    [result]="rbg"></adapt-search-row-resilient-business-goal> -->
    </ng-container>
</ng-container>


import { SurveyDetails } from "../survey-details";
import { ISurveyQuestionCategory, ISurveyQuestions } from "../survey-questions.interface";
import { ISurveyResponseChoice } from "../survey-response-choice.interface";

const teamName = "TEAM_NAME";
const TeamAssessmentQuestionList: { [surveyQuestionId: number]: string } = {
    [1]: "I genuinely apologise to my colleagues for behaviour that is inappropriate or not in the team's best interest",
    [2]: "I feel safe sharing my opinions and ideas with the team",
    [3]: "On the team I can openly acknowledge my weaknesses and mistakes without fear of it being held against me",
    [4]: "The team values and utilises my unique skills and expertise",
    [5]: "I feel safe giving and receiving feedback from the team",
    [6]: "I feel safe bringing up problems and tough issues with the team",

    [7]: "We have a clear and well documented team purpose",
    [8]: "We refer to our team purpose when making decisions and setting priorities",
    [9]: "We have clear and well documented team values",
    [10]: "Within the team, we have a clear understanding of what is considered acceptable behaviour",

    [11]: "The roles within the team are clearly defined",
    [12]: "The roles within the team are well documented and visible to the team",
    [13]: "I have a clear understanding of how my role impacts others in the team",
    [14]: "I have a clear understanding of what the tasks and responsibilities are of my team members",
    [15]: "I have a clear understanding of what my personal tasks and responsibilities are on the team",

    [16]: "We are clear on agreed behaviours in meetings",
    [17]: "Our team meetings are useful and productive",
    [18]: "Our team meetings finish with clear outcomes",
    [19]: "We have a clear and well documented approach to decision making",
    [20]: "All members of the team have visibility into the priorities and progress of team projects",
    [21]: "It is clear what the current priorities of the team are",

    [22]: "We regularly provide feedback on each other's performance and behaviour",
    [23]: "We are all accountable for our own actions and behaviour",
    [24]: "Within the team, there is an expectation to come to meetings prepared",
    [25]: "All members of the team are transparent on the progress of their tasks",
    [26]: "If a team member consistently fails to follow through on commitments, we make it clear it's impacting our team's effectiveness",

    [27]: "We have clear and well documented team objectives",
    [28]: "It is clear how the objectives of the team contribute to the overall objectives of the organisation",
    [29]: "We have a clear plan of how we are going to achieve results that align with our objectives",
    [30]: "We regularly monitor our results to ensure we are making meaningful progress towards our objectives",
    [31]: "We regularly reflect on results to celebrate successes and learn from failures",
    [32]: "We use learnings from past failures to inform how we can better achieve our objectives",
};

const TeamAssessmentQuestionChoices: { [score: number]: string } = {
    [1]: "Strongly Disagree",
    [2]: "Disagree",
    [3]: "Agree",
    [4]: "Strongly Agree",
};

export enum TeamAssessmentCategoryGrade {
    Bad = "Having Major Issues",
    Warning = "Having Minor Issues",
    Good = "Doing Well",
}

const TeamAssessmentQuestionCategories: { [id: number]: ISurveyQuestionCategory } = {
    [1]: {
        categoryName: "Trust and Psychological Safety",
        questionIds: [1, 2, 3, 4, 5, 6],
        gradeSummary: {
            [TeamAssessmentCategoryGrade.Bad]:
                `Your team's score for Trust and Psychological safety is low. Your score
                 indicates that team members may not trust each other and do not feel 'safe'
                 to admit and learn from mistakes. A team in this state will most likely
                 experience low levels of learning and innovation and potentially high levels
                 of gossip and unhealthy conflict.`,
            [TeamAssessmentCategoryGrade.Warning]:
                `Trust and Psychological safety lie at the heart of a healthy, effective
                 team. Your score in this area is medium indicating that you have some work
                 to do. Team members may not always feel ‘safe’ sharing their weaknesses,
                 mistakes, or requesting help when it’s needed. This will inhibit individual
                 and team learning and could impede innovation.`,
            [TeamAssessmentCategoryGrade.Good]:
                `Your score in this area was high which means that your team has a good level
                 of trust and people feel ‘safe’ in the team environment. This supports
                 individual and team learning and is an essential part of effective teams.`,
        },
    },
    [2]: {
        categoryName: "Alignment of Purpose and Values",
        questionIds: [7, 8, 9, 10],
        gradeSummary: {
            [TeamAssessmentCategoryGrade.Bad]:
                `Your team's score is low which indicates that team members are not sure why
                 they exist as a team or have clear values to guide their behaviour. In this situation, they
                 are most likely to feel demotivated and would certainly not have the foundations
                 to be a highly effective, resilient team.`,
            [TeamAssessmentCategoryGrade.Warning]:
                `Your score in this area is medium indicating that team members are not clear
                 and aligned on why they exist as a team
                 or how they are to behave. Some attention to this attribute will give team members
                 clarify and focus on the work they do, improve collaboration and help them deal
                 with conflict.`,
            [TeamAssessmentCategoryGrade.Good]:
                `Your score in this area was high which indicates that your team is clear why they
                 exist as a team and have a shared understanding of how they will behave.`,
        },
    },
    [3]: {
        categoryName: "Effective Stewardship",
        questionIds: [16, 17, 18, 19, 20, 21],
        gradeSummary: {
            [TeamAssessmentCategoryGrade.Bad]:
                `Your team's score is low which indicates that meetings are probably unproductive
                 and the team do not have a clear decision-making protocol and agreement as to the
                 priorities. This will most certainly mean that the team is not as productive as
                 they could be. `,
            [TeamAssessmentCategoryGrade.Warning]:
                `Your score in this area is medium which indicated that the team may not be having
                 effective meetings with clear decision-making protocols and well documented and
                 agreed priorities.`,
            [TeamAssessmentCategoryGrade.Good]:
                `Your team's score is high which indicates that meetings are probably productive
                 and the team has a clear decision-making protocol and agree on the priorities of
                 the work they need to do.`,
        },
    },
    [4]: {
        categoryName: "Clarity of Roles",
        questionIds: [11, 12, 13, 14, 15],
        gradeSummary: {
            [TeamAssessmentCategoryGrade.Bad]:
                `Your teams score is low indicating that the roles within the team are not well
                 documented and understood this could lead to disputes and waste. It would also
                 indicate that there is little chance for the banking of knowledge and guidance
                 to support role handover and process improvement.`,
            [TeamAssessmentCategoryGrade.Warning]:
                `Your score in this area was medium which indicates that your team may need to
                 do some work to get clarity on the work of individuals and the team.  Tasks and
                 responsibilities may not be well documented and clear to everyone.`,
            [TeamAssessmentCategoryGrade.Good]:
                `Your teams score is high indicating that the roles and responsibilities of the
                 team are well documented and understood. This will support your team, banking
                 knowledge and guidance to ensure effective role handover and process improvement.`,
        },
    },
    [5]: {
        categoryName: "Meaningful Progress",
        questionIds: [27, 28, 29, 30, 31, 32],
        gradeSummary: {
            [TeamAssessmentCategoryGrade.Bad]:
                `The team have rated themselves low on meaningful progress. They cannot determine
                 if they are making clear progress as a team because they have not decided what
                 progress looks like. This leads to a lack of motivation and unproductive results.`,
            [TeamAssessmentCategoryGrade.Warning]:
                `Your score in this area is medium which indicates your team may not have defined
                 goals that are measurable and regularly reviewed. Your team may not be reflecting
                 and learning from their mistakes and wins.`,
            [TeamAssessmentCategoryGrade.Good]:
                `Your score in this area is high which indicates your team has clearly defined
                 goals that are measurable and regularly reviewed. Your team is reflecting and
                 learning from their mistakes and wins.`,
        },
    },
    [6]: {
        categoryName: "High Levels of Accountability",
        questionIds: [22, 23, 24, 25, 26],
        gradeSummary: {
            [TeamAssessmentCategoryGrade.Bad]:
                `Your team have rated themselves low on accountability this indicates that
                 individually and collectively they are not held to account for their actions
                 and behaviours. This would be a clear indication that the team is not as
                 effective as it could be. Lack of accountability directly leads to a lack of
                 productivity.`,
            [TeamAssessmentCategoryGrade.Warning]:
                `High levels of personal and team accountability indicate that team members
                 are willing to hold themselves and their peers accountable for unproductive
                 behaviours and the delivery of agreed outcomes. Your Team’s score on
                 accountability was in the medium range indicating that there is room for
                 improvement.`,
            [TeamAssessmentCategoryGrade.Good]:
                `Your score in this area is high which indicates that your team get things
                 done! There is a good level of personal and team accountability.`,
        },
    },
};

export class TeamAssessmentQuestions implements ISurveyQuestions {
    public questionIds = TeamAssessmentQuestions.shuffle(this.orderedQuestionIds);

    public get orderedQuestionIds() {
        return Object.keys(TeamAssessmentQuestionList).map(Number);
    }

    public get categoryIds() {
        return Object.keys(TeamAssessmentQuestionCategories).map(Number);
    }

    public get surveyResponseChoices() {
        return Object.keys(TeamAssessmentQuestionChoices).map(Number)
            .map((value) => ({ text: TeamAssessmentQuestionChoices[value], value } as ISurveyResponseChoice));
    }

    public constructor(private surveyDetails: SurveyDetails) { }

    public static shuffle(questionIds: number[]) {
        const remainingArray = questionIds.slice();
        const results = [];
        while (remainingArray.length > 0) {
            const randomIndex = Math.floor(Math.random() * remainingArray.length);
            results.push(remainingArray[randomIndex]);
            remainingArray.splice(randomIndex, 1);
        }

        return results;
    }

    public getQuestion(questionId: number) {
        const question = TeamAssessmentQuestionList[questionId];
        if (question) {
            return question.replace(teamName, this.surveyDetails.teamName!);
        } else {
            return undefined;
        }
    }

    public getCategory(categoryId: number) {
        return TeamAssessmentQuestionCategories[categoryId];
    }

    public getQuestionIdsForCategory(categoryId: number) {
        const questionCategory = TeamAssessmentQuestionCategories[categoryId];
        if (questionCategory) {
            return questionCategory.questionIds;
        } else {
            return undefined;
        }
    }
}

import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { IActiveEntity } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { FeaturePermission } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, ConnectionBreezeModel, RoleInOrganisation } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Position, PositionBreezeModel } from "@common/ADAPT.Common.Model/organisation/position";
import { DefaultRoleLabel, Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleTypeBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-type";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { PersonDetail, PersonDetailBreezeModel } from "@common/ADAPT.Common.Model/person/person-detail";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ServiceUri } from "@common/configuration/service-uri";
import { IAddPeopleBindingModel } from "@common/identity/add-people-binding-model.interface";
import { IdentityService } from "@common/identity/identity.service";
import { IUserDetailBindingModel } from "@common/identity/user-detail-binding-model.interface";
import { setInitialPasswordPageRoute } from "@common/identity/ux/set-initial-password-page/set-initial-password-page.route";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { RouteService } from "@common/route/route.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IEmailTemplate } from "@org-common/lib/email/email-template.interface";
import moment from "moment";
import { catchError, from, lastValueFrom, of, Subject, throwError } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkQueryUtilities } from "../architecture/integrated-architecture-framework-query-utilities";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { OrganisationService } from "../organisation/organisation.service";

interface IWelcomeEmailBindingModel extends IAddPeopleBindingModel {
    personId?: number;
    customContent?: string;
}

@Injectable({
    providedIn: "root",
})
export class UserManagementService extends BaseOrganisationService {
    private archData = new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService);
    private _peopleAdded$ = new Subject<void>();

    public constructor(
        injector: Injector,
        private routeService: RouteService,
        private identityService: IdentityService,
        private dialogService: AdaptCommonDialogService,
        private httpClient: HttpClient,
        private organisationService: OrganisationService,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector);
    }

    public get peopleAdded$() {
        return this._peopleAdded$.asObservable();
    }

    public emitPeopleAdded() {
        this._peopleAdded$.next();
    }

    public promiseToGetActiveRoleConnections = () => lastValueFrom(this.archData.getAllRoleConnections(true));

    @Autobind
    public roleConnectionHasPermissionFilter(roleConnection: RoleConnection) {
        return roleConnection && roleConnection.role && roleConnection.role.extensions.hasAccessPermissions();
    }

    public getRoleFeaturePermission(role: Role, featurePermission: FeaturePermission) {
        return role.roleFeaturePermissions?.find((roleFeaturePermission) => {
            return roleFeaturePermission.featurePermission.featurePermissionId === featurePermission.featurePermissionId;
        });
    }

    public changeConnectionStartDate(connection: Connection, startDate: Date) {
        connection.startDate = startDate;

        const modifiedEntities: IBreezeEntity[] = [connection];

        const entitiesToUpdate: IActiveEntity[] = [
            ...connection.roleConnections,
            ...connection.positions,
            ...connection.person.culturalLeaderRelationships,
            ...connection.person.culturalCohortRelationships,
        ];

        for (const activeEntity of entitiesToUpdate) {
            // started before the desired date
            if (activeEntity.startDate.getTime() < startDate.getTime()) {
                activeEntity.startDate = startDate;
                ArrayUtilities.addElementIfNotAlreadyExists(modifiedEntities, activeEntity);
            }

            // ended before the desired date
            if (activeEntity.endDate && activeEntity.endDate.getTime() < startDate.getTime()) {
                activeEntity.endDate = startDate;
                ArrayUtilities.addElementIfNotAlreadyExists(modifiedEntities, activeEntity);
            }
        }

        return modifiedEntities;
    }

    public async reactivateAccount(latestConnection: Connection, connectionType: ConnectionType, userType: UserType, roles: Role[], allowLogin: boolean) {
        const today = moment.utc()
            .startOf("day")
            .toDate();

        const connection = new Connection();
        connection.startDate = today;
        connection.endDate = undefined;
        connection.hasAccess = allowLogin;

        connection.userType = userType;
        connection.connectionType = connectionType;
        connection.person = latestConnection.person;
        connection.organisation = latestConnection.organisation;
        connection.roleInOrganisation = RoleInOrganisation.Employee;

        const roleConnections = roles.map((role) => {
            const roleConnection = new RoleConnection();
            roleConnection.startDate = today;
            roleConnection.endDate = undefined;
            roleConnection.role = role;
            return roleConnection;
        });

        connection.roleConnections = roleConnections;

        const createdConnection = await lastValueFrom(this.commonDataService.create(ConnectionBreezeModel, connection));

        if (connection.connectionType === ConnectionType.Employee) {
            const position = new Position();
            position.name = "Employee";
            position.startDate = today;
            position.endDate = undefined;
            position.connection = createdConnection;
            await lastValueFrom(this.commonDataService.create(PositionBreezeModel, position));
        }

        await lastValueFrom(this.commonDataService.save());
    }

    public getAccessLevels(userType?: UserType) {
        return this.commonDataService.getAll(RoleTypeBreezeModel).pipe(
            switchMap(() => this.commonDataService.getByPredicate(RoleBreezeModel, new MethodologyPredicate<Role>("roleTypeId", "!=", null))),
            map((roles) => roles.filter((role) => role.isActive())),
            map((roles) => roles.filter((role) => role.extensions.isCoachAccessRole() || !role.extensions.isSystemAllocatedRole())),
            map((roles) => {
                // filter the available access levels by the user type
                if (!userType) {
                    return roles;
                }

                switch (userType) {
                    case UserType.Leader:
                        return roles.filter((al) => al.extensions.isLeaderAccessRole());
                    case UserType.Collaborator:
                        return roles.filter((al) => al.extensions.isCollaboratorAccessRole());
                    case UserType.Viewer:
                        return roles.filter((al) => al.extensions.isViewerAccessRole());
                    case UserType.Coach:
                        return roles.filter((al) => al.extensions.isCoachAccessRole());
                    case UserType.None:
                        return [];
                    default:
                        throw new Error("unknown user type");
                }
            }),
        );
    }

    public createAccessLevel() {
        return this.archData.getRoleTypeByCode(RoleTypeCode.Leader).pipe(
            emptyIfUndefinedOrNull(),
            switchMap((roleType) => {
                const roleValues: Partial<Role> = {
                    organisationId: this.organisationService.getOrganisationId(),
                    connectionType: ConnectionType.Employee,
                    roleTypeId: roleType.roleTypeId,
                    startDate: new Date(),
                };

                return this.commonDataService.create(RoleBreezeModel, roleValues);
            }),
        );
    }

    public getDefaultAccessLevelLabel(userType: UserType) {
        switch (userType) {
            case UserType.Leader:
                return DefaultRoleLabel.Leader;
            case UserType.Collaborator:
                return DefaultRoleLabel.Collaborator;
            case UserType.Viewer:
                return DefaultRoleLabel.Participant;
            case UserType.None:
                return undefined;
            default:
                throw new Error("unknown user type");
        }
    }

    public promiseToGetDefaultAccessLevel(userType: UserType) {
        return this.promiseToGetAccessLevelRoleWithLabel(userType, this.getDefaultAccessLevelLabel(userType));
    }

    public async promiseToGetAccessLevelRoleWithLabel(userType: UserType, roleLabel?: string) {
        const accessLevels = await this.promiseToGetAccessLevelsByUserType(userType);
        let role = accessLevels.find((r) => r.label === roleLabel);

        // if for some reason the default access level label has been changed, then just return the first one
        if (accessLevels.length && !role) {
            role = accessLevels[0];
        }

        return role;
    }

    public async promiseToGetAccessLevelsByUserType(userType: UserType): Promise<Role[]> {
        let roleTypeCode: string;
        switch (userType) {
            case UserType.Leader:
                roleTypeCode = RoleTypeCode.Leader;
                break;
            case UserType.Collaborator:
                roleTypeCode = RoleTypeCode.Collaborator;
                break;
            case UserType.Viewer:
                roleTypeCode = RoleTypeCode.Viewer;
                break;
            case UserType.Coach:
                roleTypeCode = RoleTypeCode.Coach;
                break;
            default:
                throw new Error("unknown user type");
        }

        const roleType = await lastValueFrom(this.archData.getRoleTypeByCode(roleTypeCode));
        if (roleType) {
            const predicate = new MethodologyPredicate<Role>("roleTypeId", "==", roleType.roleTypeId);
            return lastValueFrom(this.archData.getActiveRolesByPredicate(predicate));
        } else {
            return [];
        }
    }

    public getEmailStatusForPerson(personId: number) {
        const url = `${ServiceUri.AccountServiceUri}/EmailIsConfirmed`;
        let params = new HttpParams();
        params = params.set("personId", personId);
        params = params.set("organisationId", this.organisationId);

        return this.httpClient.get<boolean>(url, { params }).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 403 || err.status === 404) {
                    return of(true);
                }
                return throwError(() => new Error(ErrorHandlingUtilities.getHttpResponseMessage(err)));
            }),
        );
    }

    public confirmSendWelcomeEmail(personId: number) {
        return this.dialogService.openConfirmationDialog({
            title: "Send Invitation Email",
            message: `This will send an invitation e-mail to this user giving them instructions on how to log in to the ${AdaptClientConfiguration.AdaptProjectLabel} platform.`,
            confirmButtonText: "Send invitation email",
            cancelButtonText: "Cancel",
        }).pipe(
            switchMap(() => this.promiseToSendWelcomeEmail(personId)), // confirm -> send
        );
    }

    /**
     * Promise to get all person details for a person.
     * @param {int} personId The identifier for the person.
     * @param {bool} forceRemote A boolean representation of whether to force a remote fetch.
     * @returns {promise} A promise that resolves with the array of person details.
     */
    public promiseToGetPersonDetailsByPersonId(personId: number, forceRemote?: boolean) {
        const predicate = new MethodologyPredicate<PersonDetail>("personId", "==", personId);
        return lastValueFrom(this.commonDataService.getByPredicate(PersonDetailBreezeModel, predicate, forceRemote));
    }

    public async promiseToAddPeople(peopleData: IUserDetailBindingModel[]) {
        const addPeopleViewModel = await this.promiseToGetWelcomeDataModel();
        addPeopleViewModel.userDetails = peopleData;

        try {
            await this.identityService.addPeople(addPeopleViewModel);
            this.emitPeopleAdded();
        } catch (error) {
            const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(error);
            return Promise.reject(new AdaptError(errorMessage));
        }
    }

    /**
     * Promise to send a welcome email for a person to an organisation.
     * @param {int} personId : The identifier for the person to send the email to.
     * @returns {promise} : A promise that resolves when the email has been sent.
     */
    public async promiseToSendWelcomeEmail(personId: number) {
        const sendWelcomeEmailViewModel: IWelcomeEmailBindingModel = await this.promiseToGetWelcomeDataModel();
        sendWelcomeEmailViewModel.personId = personId;

        try {
            await this.identityService.sendWelcomeEmail(sendWelcomeEmailViewModel);
            return this.promiseToGetPersonDetailsByPersonId(personId, true);
        } catch (error) {
            return Promise.reject(ErrorHandlingUtilities.getHttpResponseMessage(error));
        }
    }

    public getWelcomeEmailTemplate(personId: number, customContent?: string) {
        const url = `${ServiceUri.AccountServiceUri}/GetWelcomeEmailPreview`;
        return from(this.promiseToGetWelcomeDataModel()).pipe(
            map((bindingModel: IWelcomeEmailBindingModel) => {
                bindingModel.personId = personId;
                bindingModel.customContent = customContent;
                return bindingModel;
            }),
            switchMap((bindingModel) => this.httpClient.post<IEmailTemplate>(url, bindingModel, {
                responseType: "json",
                headers: {
                    "Content-Type": "application/json",
                },
            })),
        );
    }

    public getActivationUrl(personId: number) {
        const url = `${ServiceUri.AccountServiceUri}/GetActivationUrl`;

        return from(this.promiseToGetWelcomeDataModel()).pipe(
            map((model: IWelcomeEmailBindingModel) => {
                model.personId = personId;
                return model;
            }),
            switchMap((model) => this.httpClient.post<string>(url, model)),
            catchError((error) => this.dialogService.showErrorDialog("Failed to get activation URL", ErrorHandlingUtilities.getHttpResponseMessage(error))),
        );
    }

    private async promiseToGetWelcomeDataModel() {
        const referenceData = await PromiseUtilities.fromObject({
            passwordRedirectUrl: this.routeService.getControllerRoute(setInitialPasswordPageRoute.id),
            welcomeRedirectUrl: this.routeService.getControllerRoute(this.personalDashboardPageRoute.id),
        });

        return {
            organisationId: this.organisationService.getOrganisationId(),
            welcomeRedirectUrl: this.setUrlBase(referenceData.welcomeRedirectUrl),
            setInitialPasswordRedirectUrl: this.setUrlBase(referenceData.passwordRedirectUrl),
        } as IAddPeopleBindingModel;
    }

    private setUrlBase(url: string) {
        let redirect = window.location.href;
        redirect = redirect.replace(window.location.pathname, url);
        return redirect;
    }
}

import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { InputGroup, InputGroupType } from "@common/ADAPT.Common.Model/organisation/input-group";
import { CanvasType, InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { first, forkJoin, of, switchMap } from "rxjs";
import { StrategicInputsService } from "../strategic-inputs.service";

@Component({
    selector: "adapt-competitor-analysis-page",
    templateUrl: "./competitor-analysis-page.component.html",
    styleUrls: ["./competitor-analysis-page.component.scss"],
})
export class CompetitorAnalysisPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;

    public canvas?: InputsCanvas;
    public competitors: InputGroup[] = [];

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private inputsService: StrategicInputsService,
        rxjsBreezeService: RxjsBreezeService,
        private authService: AuthorisationService,
    ) {
        super(injector, elementRef);

        rxjsBreezeService.entityTypeChanged(InputGroup).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            if (this.canvas) {
                this.competitors = this.canvas.inputGroups.filter((i) => i.type === InputGroupType.Competitor);
            }
        });
    }

    public ngOnInit() {
        forkJoin([
            this.inputsService.getCanvasesOfType(CanvasType.CompetitorAnalysis),
            this.authService.getHasAccess(StrategyAuthService.EditStrategicInputs).pipe(first()),
        ]).pipe(
            switchMap(([canvases, canEditStrategy]) => (canvases.length > 0 || !canEditStrategy)
                ? of(canvases[0])
                : this.inputsService.createCanvasOfType(CanvasType.CompetitorAnalysis).pipe(
                    switchMap((canvas) => this.inputsService.saveEntities(canvas)),
                )),
        ).subscribe((canvas: InputsCanvas | undefined) => {
            if (canvas) {
                this.competitors = canvas.inputGroups.filter((i) => i.type === InputGroupType.Competitor);
                this.canvas = canvas;
            }
            this.notifyActivated();
        });
    }

    @Autobind
    public addCompetitor() {
        return this.inputsService.createCompetitor(this.canvas!).pipe(
            switchMap((competitor) => this.inputsService.editInputGroup(competitor)),
            this.takeUntilDestroyed(),
        );
    }
}

export const CompetitorAnalysisPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-competitor-analysis-page", CompetitorAnalysisPageComponent)
    .atOrganisationUrl("/competitor-analysis")
    .withTitle("Competitor Analysis")
    .verifyingFeatures(FeatureName.StrategicInputs)
    .verifyingAccess(StrategyAuthService.ReadStrategicInputs)
    .reloadOnSearch(false)
    .build();

<form role="form"
      [formGroup]="filterForm">
    <div class="form-group">
        <label>Owner</label>
        <adapt-select-person [person]="filter.assignee"
                             (personChange)="onAssigneeChanged($event)"
                             [teamGroup]="team"
                             data-test="filter-objective-assignee"></adapt-select-person>
    </div>

    <div class="form-group">
        <label>Labels</label>
        <adapt-select-label [allowLabelCreation]="false"
                            [labels]="filter.labels"
                            (labelsChange)="onLabelsChanged($event)"
                            data-test="filter-objective-label"></adapt-select-label>
    </div>

    <div class="form-group">
        <label for="preselectionSelectBox">
            Statuses
        </label>
        <dx-tag-box [items]="statuses"
                    [value]="statusSelection"
                    [hideSelectedItems]="true"
                    (valueChange)="onStatusChanged($event)"
                    displayExpr="name"
                    tagTemplate="customTag"
                    data-test="filter-objective-statuses">
            <div *dxTemplate="let data of 'customTag'">
                <div class="dx-tag-content"
                     [ngClass]="data.statusClass">{{data.name}}
                    <div class="dx-tag-remove-button"></div>
                </div>
            </div>

        </dx-tag-box>
    </div>

    <div class="form-group">
        <div class="form-check">
            <input class="form-check-input"
                   type="checkbox"
                   id="showRelatedObjectives"
                   formControlName="showRelatedObjectives"
                   data-test="toggle-show-external-objectives">
            <label class="form-check-label"
                   for="showRelatedObjectives">
                Show {{!!team ? 'organisation objectives this team supports' : 'supporting team objectives' }}
            </label>
        </div>
    </div>

    <div class="form-group">
        <label for="duringYear">
            When showing closed objectives
        </label>
        <dx-select-box [value]="filter.closedOption"
                       [disabled]="!closedSelected"
                       (valueChange)="onClosedOptionsChanged($event)"
                       [items]="closedFilters"
                       data-test="select-closed-option">
        </dx-select-box>
    </div>

    <div class="form-group">
        <label for="duringYear">
            Show Objectives Due in
        </label>
        <dx-date-box id="duringYear"
                     formControlName="duringYear"
                     width="100%"
                     displayFormat="year"
                     [showClearButton]="true"
                     data-test="filter-due-year">
            <dxo-calendar-options maxZoomLevel="decade"></dxo-calendar-options>
        </dx-date-box>
    </div>

    <hr>

    <div class="form-group">
        <label>Focus on</label>
        <dx-select-box [value]="objectiveFilterService.focusType.option$ | async"
                       (valueChange)="onFocussedTypeSelection($event)"
                       [items]="focusOptions"
                       valueExpr="type"
                       displayExpr="label"
                       [showClearButton]="true"
                       data-test="filter-objective-type"></dx-select-box>
    </div>
</form>

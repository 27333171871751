import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "adapt-colour-swatch",
    templateUrl: "./colour-swatch.component.html",
    styleUrls: ["./colour-swatch.component.scss"],
})
export class ColourSwatchComponent implements OnInit {
    @Input() public variable?: string;
    @Input() public textColor?: string;
    @Input() public textColorVariable?: string;
    @Input() public hasSassVariable = true;
    @Input() public cssVariablePrefix = "adapt";
    @Input() public variants?: number[];

    public colorMap: Map<string, string> = new Map<string, string>();
    public colorVariants: {name: string, value: string}[] = [];

    private cvs: HTMLCanvasElement;
    private ctx: CanvasRenderingContext2D | null;

    public constructor() {
        this.cvs = document.createElement("canvas");
        this.cvs.height = 1;
        this.cvs.width = 1;
        this.ctx = this.cvs.getContext("2d");
    }


    public ngOnInit() {
        this.colorMap = this.getStyleMap();
        this.colorVariants = this.getColorVariants();
    }

    public get textColorValue() {
        if (this.textColorVariable && this.colorMap.has(this.textColorVariable)) {
            return this.colorMap.get(this.textColorVariable);
        }

        return this.textColor ?? "#fff";
    }

    public get value() {
        return this.colorMap.get(this.variable!) ?? "#000";
    }

    public get rgb() {
        const rgba = this.colorToRGBA(this.value);
        return `rgb(${rgba[0]}, ${rgba[1]}, ${rgba[2]})`;
    }

    public get hex() {
        return this.colorToHex(this.value);
    }

    public getSassVariableDisplay(name?: string) {
        return `$${name};`;
    }

    public getCssVariableDisplay(name?: string) {
        return `var(--${this.cssVariablePrefix}-${name});`;
    }

    private getStyleMap() {
        const variables = [];
        for (const stylesheet of Array.from(document.styleSheets)) {
            for (const rule of Array.from(stylesheet.cssRules) as CSSStyleRule[]) {
                if (rule.selectorText?.startsWith(":root")) {
                    const matchingVariables = Array.from(rule.style)
                        .filter((name) => name.startsWith(`--${this.cssVariablePrefix}-`));
                    variables.push(...matchingVariables);
                }
            }
        }

        return new Map<string, string>(variables.map((variable) => [
            variable.replace(`--${this.cssVariablePrefix}-`, ""),
            this.colorToHex(getComputedStyle(document.documentElement).getPropertyValue(variable).trim()),
        ]));
    }

    private getColorVariants() {
        return (this.variants ?? []).map((variant) => {
            const variantVariableName = `${this.variable!}-${variant}`;
            return { name: variant.toString(), value: this.colorMap.get(variantVariableName) ?? "#000" };
        });
    }

    /**
     * Returns the color as an array of [r, g, b, a] -- all range from 0 - 255.
     * Color must be a valid canvas fillStyle. This will cover almost anything
     * you'd want to use.
     * @param color css color string
     */
    private colorToRGBA(color: string) {
        if (this.ctx) {
            this.ctx.fillStyle = color;
            this.ctx.fillRect(0, 0, 1, 1);
            return this.ctx.getImageData(0, 0, 1, 1).data;
        }
        return [0, 0, 0, 0];
    }

    /**
     * Turns a number (0-255) into a 2-character hex number (00-ff)
     * @param num number to convert to hex
     */
    private byteToHex(num: number) {
        return ("0" + num.toString(16)).slice(-2);
    }

    /**
     * Convert any CSS color to a hex representation.
     * @example
     * colorToHex('red') // --> '#ff0000'
     * @example
     * colorToHex('rgb(255, 0, 0)') // --> '#ff0000'
     * @param color CSS color to convert
     */
    private colorToHex(color: string) {
        const rgba = this.colorToRGBA(color);
        const hex = [0, 1, 2].map(
            (idx) => this.byteToHex(rgba[idx]),
        ).join("");
        return `#${hex}`;
    }
}

import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BreezePredicateUtilities } from "@common/lib/data/breeze-predicate-utilities";
import { IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IItemFilterParameters } from "./item-filter-parameters.interface";
import { ItemUtilities } from "./item-utilities";

enum ItemDueDate {
    All = "0",
    Overdue = "1",
    OnSchedule = "2",
}

interface IRequestSettings {
    requestKey: string;
    options: IBreezeQueryOptions;
}

export interface IFilterParameterAdditionalOptions {
    top?: number;
    skip?: number;
    orderBy?: string;
    forceRemote?: boolean;
    filterColumns?: any;
}

@Autobind
export class ItemFilterParameterRequestBuilder {
    // TODO: Make this part of configuration?
    private static breezeNodeLimit = 10;

    private filterParameters?: IItemFilterParameters;
    private prefixPath: string = "";

    private standardRequestSettings: IRequestSettings = {
        requestKey: "",
        options: {
            namedParams: {},
        },
    };

    public static builder() {
        return new ItemFilterParameterRequestBuilder();
    }

    public withFilterParameters(filterParameters: IItemFilterParameters) {
        this.validateRequiredFields();

        this.filterParameters = filterParameters;

        // create if not yet defined - it may have been defined by locationDataFactory
        if (!this.standardRequestSettings.options.predicate) {
            this.standardRequestSettings.options.predicate = new MethodologyPredicate<Item>();
        }

        if (filterParameters.team) {
            this.addPrefixedPredicate("board.teamId", "==", filterParameters.team.teamId);
        }

        if (filterParameters.assignee) {
            this.addPrefixedPredicate("assigneeId", "==", filterParameters.assignee.personId || null);
        }

        if (filterParameters.text) {
            const textPred = new MethodologyPredicate<Item>("summary", "contains", filterParameters.text)
                .or(new MethodologyPredicate<Item>("description", "contains", filterParameters.text));

            const itemCodeCandidate = ItemUtilities.getItemCodeBreakDown(filterParameters.text);
            if (itemCodeCandidate.boardAbbreviation) {
                const additionalPredicate = new MethodologyPredicate<Item>("board.itemPrefix", "==", itemCodeCandidate.boardAbbreviation);

                // Not including boardIndex with query predicate as this will be a startsWith
                // operator which cannot be processed by breeze with a non-string/nvarchar
                // columns(cast boardIndex as nvarchar) doesn't work; neither does toString etc.
                textPred.or(additionalPredicate);
            }

            this.addToPredicate(textPred);
        }

        if (filterParameters.dueDates === ItemDueDate.Overdue) {
            const overduePred = BreezePredicateUtilities.getIsYesterdayOrEarlierPredicate(this.prefixPath + "dueDate")
                .and(this.createPrefixedPredicate("dueDate", "!=", null))
                .and(this.createPrefixedPredicate("status", "!=", ItemStatus.Done));

            this.addToPredicate(overduePred as MethodologyPredicate<Item>);
        }

        if (filterParameters.dueDates === ItemDueDate.OnSchedule) {
            const onSchedulePred = BreezePredicateUtilities.getIsTodayOrLaterPredicate(this.prefixPath + "dueDate")
                .and(this.createPrefixedPredicate("dueDate", "!=", null))
                .and(this.createPrefixedPredicate("status", "!=", ItemStatus.Done));

            this.addToPredicate(onSchedulePred as MethodologyPredicate<Item>);
        }

        if (filterParameters.statuses && filterParameters.statuses.length) {
            this.addPrefixedPredicate("status", "in", filterParameters.statuses);
        }

        if (filterParameters.updatedDate) {
            const withinPred = this.createPrefixedPredicate("createdDateTime", ">=", filterParameters.updatedDate)
                .or(this.createPrefixedPredicate("lastUpdatedDateTime", ">=", filterParameters.updatedDate));

            this.addToPredicate(withinPred);
        }

        return this;
    }

    public forModel(model: any) {
        this.standardRequestSettings.requestKey = model.identifier;
        return this;
    }

    public withPrefix(prefix: string) {
        this.prefixPath = prefix + ".";
        return this;
    }

    public useStartsWith() {
        if (!this.filterParameters) {
            throw new Error("You must use filterParameters to use starts with");
        }

        const startsWith = ItemUtilities.getItemCodeBreakDown(this.filterParameters.text).boardIndex;
        if (startsWith) {
            this.standardRequestSettings.options.namedParams!.startsWith = startsWith.toString();
            this.standardRequestSettings.requestKey += "startsWith" + startsWith;
        }

        return this;
    }

    public withAdditionalOptions(additionalOptions?: IFilterParameterAdditionalOptions) {
        if (!additionalOptions) {
            return this;
        }

        // add top settings
        const settings = this.standardRequestSettings;

        if (additionalOptions.top) {
            settings.options.top = additionalOptions.top;
            settings.requestKey += "top" + settings.options.top;
        }

        if (additionalOptions.skip) {
            settings.options.skip = additionalOptions.skip;
            settings.requestKey += "skip" + settings.options.skip;
        }

        if (additionalOptions.orderBy) {
            settings.options.orderBy = additionalOptions.orderBy + ",createdDateTime desc";
        } else {
            settings.options.orderBy = "createdDateTime desc";
        }

        settings.requestKey += "orderBy " + settings.options.orderBy;

        this.addColumnFiltersToPredicate(additionalOptions, settings.options);

        if (!settings.options.forceRemote) {
            settings.options.forceRemote = additionalOptions.forceRemote;
        }

        return this;
    }

    public build(): IRequestSettings[] {
        if (this.filterParameters
            && this.filterParameters.boards
            && this.filterParameters.boards.length > 0) {
            const boardIds: number[] = this.filterParameters.boards.map((b) => b.boardId);
            const allRequestSettings = ArrayUtilities.splitAndProcessArrayChunks(
                boardIds,
                this.getNodeLimit(),
                this.createRequestSettingsFromBoardIds,
            );

            return allRequestSettings;
        } else {
            this.standardRequestSettings.requestKey += this.standardRequestSettings.options.predicate!.getKey();

            return [this.standardRequestSettings];
        }
    }

    private getNodeLimit() {
        // Reduce the node limit by a factor of the number of indirections in the pathPrefix
        // E.g. predicate to "boardId" with prefix of "item" = 2 and
        //      predicate to "boardId" with no prefix = 1
        // There is at least 1 as a standard predicate with no prefix counts as 1
        // Use ceiling function so that the node limit is at least 1
        const predicatePathLength = this.prefixPath.split("").filter((c) => c === ".").length + 1;
        const nodeLimit = Math.ceil(ItemFilterParameterRequestBuilder.breezeNodeLimit / predicatePathLength);

        return nodeLimit;
    }

    private createRequestSettingsFromBoardIds(boardIds: number[]): IRequestSettings {
        // Clone predicate from request settings as merge does not preserve prototype chain in class
        const requestSettings: IRequestSettings = Object.assign({}, this.standardRequestSettings);
        requestSettings.options.predicate = this.standardRequestSettings.options.predicate!.clone();

        const boardsPredicate = this.createPrefixedPredicate("boardId", "in", boardIds);
        requestSettings.options.predicate.and(boardsPredicate);
        requestSettings.requestKey += requestSettings.options.predicate.getKey();

        return requestSettings;
    }

    private addColumnFiltersToPredicate(options: IFilterParameterAdditionalOptions, resultOptions: IBreezeQueryOptions) {
        if (Array.isArray(options.filterColumns) && options.filterColumns.length > 0) {
            const filterPredicate = new MethodologyPredicate<Item>();

            for (const column of options.filterColumns) {
                const columnFilter = new MethodologyPredicate<Item>();

                if (column.filterType === "exclude") {
                    for (const filterValue of column.filterValues) {
                        columnFilter.and(new MethodologyPredicate<Item>(column.dataField, "!=", filterValue));
                    }
                } else { // include filter
                    for (const filterValue of column.filterValues) {
                        columnFilter.or(new MethodologyPredicate<Item>(column.dataField, "==", filterValue));
                    }
                }

                filterPredicate.and(columnFilter);
            }

            if (resultOptions.predicate) {
                resultOptions.predicate.and(filterPredicate);
            } else {
                resultOptions.predicate = filterPredicate;
            }
        }
    }

    private addPrefixedPredicate(...params: ConstructorParameters<typeof MethodologyPredicate<Item>>) {
        this.addToPredicate(this.createPrefixedPredicate(...params));
    }

    private createPrefixedPredicate(...params: ConstructorParameters<typeof MethodologyPredicate<Item>>) {
        return new MethodologyPredicate<Item>(
            (this.prefixPath + params[0]) as ConstructorParameters<typeof MethodologyPredicate<Item>>[0],
            params[1], params[2]);
    }

    private addToPredicate(additionalPredicate: MethodologyPredicate<Item>) {
        this.standardRequestSettings.options.predicate!.and(additionalPredicate);
    }

    private validateRequiredFields() {
        if (!this.standardRequestSettings.requestKey) {
            throw new Error("Did you register your model and ensure it had a identifier field?");
        }
    }
}

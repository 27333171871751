import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Anchor } from "@common/ADAPT.Common.Model/organisation/anchor";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { finalize, switchMap } from "rxjs";
import { StrategyAuthService } from "../../strategy/strategy-auth.service";
import { StrategicAnchorsService } from "../strategic-anchors.service";

@Component({
    selector: "adapt-strategic-anchors-page",
    templateUrl: "./strategic-anchors-page.component.html",
})
export class StrategicAnchorsPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly EditStrategicAnchors = StrategyAuthService.EditStrategicAnchors;

    public anchors: Anchor[] = [];

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private anchorsService: StrategicAnchorsService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector, elementRef);

        rxjsBreezeService.entityTypeChanged(Anchor).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnInit());
    }

    public ngOnInit() {
        this.anchorsService.getAllAnchors().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((anchors) => {
            this.anchors = anchors;
            this.isInitialised = true;
            this.notifyActivated();
        });
    }

    @Autobind
    public addAnchor() {
        return this.anchorsService.createAnchor().pipe(
            switchMap((anchor) => this.anchorsService.editAnchor(anchor)),
            this.takeUntilDestroyed(),
        );
    }

    @Autobind
    public reorderAnchors() {
        return this.anchorsService.reorderAnchors(this.anchors).pipe(
            // dxList already reorder the array even if we discard -> need to sort according to ordinal again
            finalize(() => this.anchorsService.sortAnchors(this.anchors)),
            this.takeUntilDestroyed(),
        );
    }
}

export const StrategicAnchorsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-strategic-anchors-page", StrategicAnchorsPageComponent)
    .atOrganisationUrl("/strategic-anchors")
    .withTitle("Strategic Anchors")
    .verifyingFeatures(FeatureName.StrategicAnchors)
    .verifyingAccess(StrategyAuthService.ReadStrategicAnchors)
    .reloadOnSearch(false)
    .build();

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptContextSidebarModule } from "@common/shell/context-sidebar/context-sidebar.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxSelectBoxModule, DxTabPanelModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptArchitectureModule } from "../architecture/architecture.module";
import { ActivationUrlDialogComponent } from "./activation-url-dialog/activation-url-dialog.component";
import { ConvertConnectionTypeDialogComponent } from "./convert-connection-type-dialog/convert-connection-type-dialog.component";
import { ConvertUserTypeDialogComponent } from "./convert-user-type-dialog/convert-user-type-dialog.component";
import { EndConnectionDialogComponent } from "./end-connection-dialog/end-connection-dialog.component";
import { LinkAccessLevelComponent } from "./link-access-level/link-access-level.component";
import { ManagePeopleGridComponent } from "./manage-people-grid/manage-people-grid.component";
import { ManagePeoplePageComponent, managePeoplePageRoute } from "./manage-people-page/manage-people-page.component";
import { ReactivateAccountDialogComponent } from "./reactivate-account-dialog/reactivate-account-dialog.component";
import { SelectAccessLevelComponent } from "./select-access-level/select-access-level.component";
import { SelectConnectionTypeComponent } from "./select-connection-type/select-connection-type.component";
import { SelectUserTypeComponent } from "./select-user-type/select-user-type.component";
import { UserAccessSummaryDialogComponent } from "./user-access-summary-dialog/user-access-summary-dialog.component";
import { UserAccessSummaryGridComponent } from "./user-access-summary-grid/user-access-summary-grid.component";
import { UserRoleActionsComponent } from "./user-role-actions/user-role-actions.component";

@NgModule({
    imports: [
        CommonModule,

        AdaptTooltipModule,
        AdaptMenuModule,
        AdaptDirectorySharedModule,
        AdaptArchitectureModule,
        AdaptCommonDialogModule,
        AdaptDateModule,
        AdaptFeatureModule,
        AdaptButtonModule,
        AdaptToolbarModule,
        AdaptContextSidebarModule,

        DxTextBoxModule,
        DxDateBoxModule,
        DxDataGridModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        DxTabPanelModule,
        AdaptLoadingSpinnerModule,
    ],
    exports: [
        ManagePeopleGridComponent,
        UserRoleActionsComponent,
        LinkAccessLevelComponent,
        SelectAccessLevelComponent,
        SelectConnectionTypeComponent,
        SelectUserTypeComponent,
        UserAccessSummaryGridComponent,
    ],
    declarations: [
        ManagePeoplePageComponent,
        ManagePeopleGridComponent,
        UserRoleActionsComponent,
        LinkAccessLevelComponent,
        SelectAccessLevelComponent,
        SelectConnectionTypeComponent,
        SelectUserTypeComponent,

        ReactivateAccountDialogComponent,
        ConvertConnectionTypeDialogComponent,
        ConvertUserTypeDialogComponent,
        EndConnectionDialogComponent,
        ActivationUrlDialogComponent,
        UserAccessSummaryDialogComponent,
        UserAccessSummaryGridComponent,
    ],
})
export class AdaptUserManagementSharedModule {
    public static readonly Routes = [
        ...managePeoplePageRoute.routes,
    ];
}

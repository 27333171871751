import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { IObjectiveGroup } from "../objectives.service";

@Component({
    selector: "adapt-display-objectives-hierarchy",
    templateUrl: "./display-objectives-hierarchy.component.html",
    styleUrls: ["./display-objectives-hierarchy.component.scss"],
})
export class DisplayObjectivesHierarchyComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {
    @Input() public objectiveGroup!: IObjectiveGroup;
    @Input() public isCompactView = false;
    @Input() public showChildren = true;
    @Input() public currentTeamId: number | null = null; // have to use null here as breeze entity is setting this to null rather than undefined
    @Output() public heightChange = new EventEmitter();

    @Input() public hasAnnualObjective = false;
    @Output() public hasAnnualObjectiveChange = new EventEmitter<boolean>();

    @Input() public hasExternalParent = false;
    @Output() public hasExternalParentChange = new EventEmitter<boolean>();

    @Input() public isRoot = false;

    public lines: string[] = [];

    private element: JQuery<Element>;

    private linesInvalidated = new Subject<void>();
    private currentBreakpoint = Breakpoint.XL;
    private responsiveSubscription: Subscription;
    private height?: number;

    private hasAnnualObjectiveChangeUpdater = this.createThrottledUpdater((result: boolean) => this.hasAnnualObjectiveChange.emit(result), 10);
    private hasExternalParentChangeUpdater = this.createThrottledUpdater((result: boolean) => this.hasExternalParentChange.emit(result), 10);

    public constructor(
        el: ElementRef,
        private responsiveService: ResponsiveService,
    ) {
        super();
        this.element = jQuery(el.nativeElement);
        this.height = this.element.height();

        // having a 10ms buffering gaps for multiple children size changes,
        // which can happen after children prime links
        this.linesInvalidated.pipe(
            debounceTime(10),
        ).subscribe(() => this.updateLines());

        this.responsiveSubscription = this.responsiveService.currentBreakpoint$.subscribe((breakpoint) => {
            this.currentBreakpoint = breakpoint;
            this.invalidateLines();
        });

    }

    public ngAfterViewChecked() {
        const elementHeight = this.element.height();
        if (elementHeight !== this.height) {
            this.height = elementHeight;
            this.childHeightChanged();
        }
    }

    public get isHorizontal() {
        return this.currentBreakpoint.isDesktopSize;
    }

    public get isExternalObjective() {
        return this.objective.teamId !== this.currentTeamId;
    }

    public get needIndent() {
        return (this.hasAnnualObjective && this.objective.type === ObjectiveType.Quarterly
            || this.hasExternalParent)
            && !this.isExternalObjective
            && this.isRoot;
    }

    public ngOnInit() {
        if (!this.objectiveGroup) {
            throw new TypeError("'objectiveGroup' is required");
        }
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.responsiveSubscription.unsubscribe();
        this.linesInvalidated.complete();
    }

    public ngOnChanges(changesObj: SimpleChanges) {
        if (changesObj.objectiveGroup) {
            if (this.objectiveGroup.objective.type === ObjectiveType.Annual && !this.isExternalObjective) {
                this.hasAnnualObjectiveChangeUpdater.next(true); // only emit if is annual
            }

            if (this.isExternalObjective) {
                this.hasExternalParentChangeUpdater.next(true);
            }
        }

        if (changesObj.hasAnnualObjective) {
            this.invalidateLines();
        }
    }

    public ngAfterViewInit() {
        this.invalidateLines();
    }

    public get objective() {
        return this.objectiveGroup.objective;
    }

    public get hasChildren() {
        return !!this.objectiveGroup && this.objectiveGroup.childGroups.length > 0;
    }

    public toggleShowChildren() {
        this.showChildren = !this.showChildren;
        this.childHeightChanged();
    }

    public childHeightChanged() {
        this.invalidateLines();
        this.heightChange.emit();
    }

    private invalidateLines() {
        this.linesInvalidated.next();
    }

    private updateLines() {
        const parentOffset = this.element.children().first().offset()!;
        this.lines = [];
        if (this.objective) {
            // id defined in display-tree-objective component template included into this component template
            const thisDisplayCard = this.element.find(".display-tree-objective#" + this.objective.objectiveId).parent();
            if (thisDisplayCard.length > 0) {
                const thisDisplayCardOffset = thisDisplayCard.offset()!;
                let startPoint: JQuery.Coordinates;
                if (this.isHorizontal) {
                    startPoint = {
                        top: thisDisplayCardOffset.top - parentOffset.top + 20,
                        left: thisDisplayCardOffset.left - parentOffset.left + thisDisplayCard.width()!,
                    };
                } else {
                    startPoint = {
                        top: thisDisplayCardOffset.top - parentOffset.top + thisDisplayCard.height()!,
                        left: thisDisplayCardOffset.left - parentOffset.left + 20,
                    };
                }

                if (this.hasChildren) {
                    this.objective.childObjectives.forEach((child) => {
                        // id is defined in the corresponding display-tree-objective component instance
                        // which is from the child display-objectives-hierarchy
                        const childCard = this.element.find(".display-tree-objective#" + child.objectiveId);
                        if (childCard.length > 0) {
                            const childCardOffset = childCard.parent().offset()!;
                            if (this.isHorizontal) {
                                this.lines.push(createHorizontalLineToComponentWithOffset(startPoint, childCardOffset));
                            } else {
                                this.lines.push(createVerticalLineToComponentWithOffset(startPoint, childCardOffset));
                            }
                        }
                    });
                }
            }
        }

        function createVerticalLineToComponentWithOffset(startPoint: JQuery.Coordinates, componentOffset: JQuery.Coordinates) {
            const endPoint: JQuery.Coordinates = {
                top: componentOffset.top - parentOffset.top + 20,
                left: componentOffset.left - parentOffset.left,
            };

            return startPoint.left + "," + startPoint.top + " " + startPoint.left + "," + endPoint.top + " " + endPoint.left + "," + endPoint.top;
        }

        function createHorizontalLineToComponentWithOffset(startPoint: JQuery.Coordinates, componentOffset: JQuery.Coordinates) {
            const endPoint: JQuery.Coordinates = {
                top: componentOffset.top - parentOffset.top + 20,
                left: componentOffset.left - parentOffset.left,
            };

            if (endPoint.top === startPoint.top) {
                return startPoint.left + "," + startPoint.top + " " + endPoint.left + "," + endPoint.top;
            } else {
                const vertexOffset = 30;
                return (startPoint.left + vertexOffset) + "," + startPoint.top + " " +
                    (startPoint.left + vertexOffset) + "," + endPoint.top + " " +
                    endPoint.left + "," + endPoint.top;
            }
        }
    }
}

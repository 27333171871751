import { Component, ElementRef, OnInit } from "@angular/core";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UserService } from "@common/user/user.service";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { catchError, finalize, forkJoin, switchMap } from "rxjs";
import { IOrganisationDiagnosticStatusCustomData } from "../organisation-diagnostic-status-custom-data";

@WorkflowStepComponent("adapt-launch-organisation-diagnostic-survey")
@Component({
    selector: "adapt-launch-organisation-diagnostic-survey",
    templateUrl: "./launch-organisation-diagnostic-survey.component.html",
})
export class LaunchOrganisationDiagnosticSurveyComponent extends WorkflowStepComponentAdapter implements OnInit {
    public customMessageText = "";
    public startingSurvey = false;
    public showNote = false;

    public constructor(
        elementRef: ElementRef,
        private commonTeamsService: CommonTeamsService,
        private userService: UserService,
        private surveyService: SurveyService,
        private workflowService: WorkflowService,
    ) {
        super(elementRef);
    }

    public async ngOnInit() {
        const currentPersonId = this.userService.getCurrentPersonId();
        const team = await this.commonTeamsService.promiseToGetLeadershipTeam();
        const otherTeamMembers = team.roleConnections.filter((i) => i.isActive() && i.connection.personId !== currentPersonId);
        this.showNote = otherTeamMembers.length > 0;
    }

    public workflowStepNext() {
        if (!this.workflowConnection) {
            throw new Error("Launch survey workflow step cannot be execute in a stateless workflow - missing workflowConnection!");
        }

        this.startingSurvey = true;
        this.workflowStepErrorMessage.next(undefined); // clear error message before attempting
        return this.surveyService.launchOrganisationDiagnostic(this.customMessageText).pipe(
            switchMap((s) => forkJoin([
                this.surveyService.startSurvey(s),
                this.workflowService.getWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(this.workflowConnection!),
            ])),
            switchMap(([s, customData]) => {
                customData.surveyId = s?.surveyId;
                return this.workflowService.updateWorkflowCustomData(this.workflowConnection!, customData);
            }),
            catchError((e) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
                this.workflowStepErrorMessage.next(errorMessage);
                throw e;
            }),
            finalize(() => this.startingSurvey = false),
        );
    }
}

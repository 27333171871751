<adapt-dialog [maxWidth]="600"
              [expandable]="false">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <p>
            This operation will convert the user account of {{connection.person.fullName}} from
            {{ConnectionTypeLabel.singular(connection.connectionType).toLocaleLowerCase()}}
            to {{ConnectionTypeLabel.singular(destConnectionType).toLocaleLowerCase()}}.
        </p>
        <p *ngIf="isUserTypeUpdateRequired"><b>
                As this user is being converted from an external stakeholder to an employee, the user type will be changed to
                {{UserTypeExtensions.singularLabel(UserType.Viewer)}}.
                <br /><br />
                The implications for this change are:
                <ul>
                    <li>The monthly cost will increase for this user</li>
                    <li>This user's permissions will be set to the standard {{UserTypeExtensions.singularLabel(UserType.Viewer)}} access
                        level</li>
                </ul>
            </b>
        </p>
        <p>
            Please select the following options for the conversion and
            tick the checkbox to confirm that you understand the impact of this conversion to continue.
        </p>

        <div class="mt-2">
            <label>Changeover Date</label>
            <dx-date-box [value]="changeoverDate"
                         (valueChange)="onChangeoverDateChanged($any($event))"
                         type="date"
                         [max]="Now"
                         [min]="connection.startDate"
                         [displayFormat]="DateFormat"></dx-date-box>
        </div>

        <div class="mt-3"
             *ngIf="existingTeamCount > 0">
            <p class="mb-1">{{connection.person.fullName}} is currently associated with {{existingTeamCount}} team(s)</p>
            <dx-check-box [(value)]="keepInTeams"
                          (valueChange)="triggerUpdate.next()"
                          text="Keep {{connection.person.fullName}} in their current team(s) after the conversion"
                          data-test="keep-team-checkbox"></dx-check-box>
        </div>

        <div class="bottom-confirmation">
            <dx-check-box [(value)]="confirmed"
                          text="Check to confirm that you understand the impact of this action cannot be undone"
                          data-test="confirm-checkbox"></dx-check-box>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!confirmed || !hasUnsavedEntity || !isInitialised"
                                   saveText="Convert & Close"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

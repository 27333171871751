import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";
import { WorkflowIdentifier } from "../../workflow-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../../workflow-utilities";

const workflowName = "Determine bullseye";

export const BullseyeWorkflow = buildLocalWorkflow({
    name: workflowName,
    workflowId: WorkflowIdentifier.Bullseye,
    type: WorkflowType.Journey,
    time: "3 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    overview: "Set your organisation's bullseye - what you will do and what you won't do.",
    articleSlug: ImplementationKitArticle.BullseyeOutcomes,
    wrapUpSlug: ImplementationKitArticle.BullseyeWrapUp,
    wrapUpGuidedTourIdentifier: TourIdentifier.BullseyeTour,
    category: OrganisationCategoryValue.BuildStrategy,
    scope: WorkflowScope.Team,
    featuresToEnable: [FeatureName.Bullseye, FeatureName.StrategyBoard],
    compulsoryPrerequisites: [WorkflowIdentifier.StrategicThemes],
    ordinal: 70,
    workflows: [
        createActivityBriefWorkflow(workflowName, WorkflowIdentifier.Bullseye, undefined, ImplementationKitArticle.BullseyeMeetingDescription, ImplementationKitArticle.BullseyeMeetingPreWork),
        ...createWorkshopScheduleWorkflows("Defining Bullseye", WorkflowIdentifier.Bullseye),
    ],
});

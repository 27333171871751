import { Component, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { IHelpJuiceSearchResult } from "../search.interface";
import { SearchService } from "../search.service";
import { SearchRowBaseComponent } from "../search-row-base.component";

@Component({
    selector: "adapt-search-row-implementation-kit",
    templateUrl: "./search-row-implementation-kit.component.html",
})
export class SearchRowImplementationKitComponent extends SearchRowBaseComponent<IHelpJuiceSearchResult> implements OnInit {
    public href$?: Observable<string>;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();
        this.href$ = of(this.result.host + this.result.url);
    }
}

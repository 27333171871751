<ng-container *adaptLoading="!isInitialised">
    <div class="d-flex flex-column flex-lg-row">
        <div class="d-flex statement-column">
            <adapt-styled-implementation-kit [articleId]="ImplementationKitArticle.BullseyeSetCustomStatement"
                                             contentStyle="embedded"></adapt-styled-implementation-kit>


        </div>
        <div class="d-flex flex-column statement-column">
            <adapt-bullseye-view class="pb-1"
                                 [hideOuter]="true"></adapt-bullseye-view>
            <label>Bullseye statement</label>
            <adapt-html-editor [minHeight]="150"
                               [required]="true"
                               [contents]="bullseye!.statement"
                               [placeholderText]="placeholderText"
                               (contentsChange)="onStatementChanged($event)"
                               forceSize="compact"></adapt-html-editor>
            <div class="d-flex mt-2"
                 adaptBlockingButtonGroup>
                <button class="ms-1"
                        adaptButton="wizardLink"
                        [adaptBlockingClick]="defineCustomStatement"
                        adaptTooltip="Using the data from the diagram, use the adapt AI engine to generate a bullseye statement"
                        data-test="generate-bullseye-statement-button">
                    Generate statement from diagram
                </button>
                <button class="ms-3"
                        adaptButton="refreshLink"
                        [adaptBlockingClick]="paraphrase"
                        adaptTooltip="Using the statement set above, use the adapt AI engine to paraphrase your existing bullseye statement"
                        data-test="paraphrase-bullseye-statement-button">
                    Paraphrase the statement
                </button>
            </div>
        </div>
    </div>
</ng-container>

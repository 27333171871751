<dx-popup *ngIf="!expanded"
          [hideOnOutsideClick]="true"
          [dragEnabled]="false"
          [showTitle]="false"
          [visible]="visible"
          (onContentReady)="onContentReady($event)"
          [height]="resizedHeight ? resizedHeight + 25 : resizedHeight"
          [width]="resizedWidth"
          (onHiding)="closePopup()"
          shadingColor="rgba(0, 0, 0, 0.5)">
    <div *dxTemplate="let data of 'content'"
         class="d-flex flex-column">
        <ng-container *ngTemplateOutlet="imgView"></ng-container>
    </div>
</dx-popup>

<div *ngIf="expanded"
     id="imagePopup__expanded"
     class="dx-overlay-wrapper dx-overlay-modal dx-overlay-shader d-flex flex-column"
     (click)="closePopup()">
    <ng-container *ngTemplateOutlet="imgView"></ng-container>
</div>

<ng-template #imgView>
    <button class="btn-close-popup align-self-end"
            data-test="fullscreen-dialog-close"
            (click)="closePopup()">
        <i class="fal fa-times"></i>
    </button>
    <div class="h-100 w-100"
         [ngClass]="{'can-expand': canExpand, 'expanded': expanded}"
         [ngSwitch]="isFrame"
         data-test="fullscreen-dialog-content"
         #scroller>
        <img [src]="src"
             (load)="triggerResize$.next()"
             *ngSwitchCase="false"
             #content
             (click)="toggleExpandImage($event)" />
        <iframe [src]="src"
                *ngSwitchCase="true"
                #content
                frameborder="0"
                allowfullscreen></iframe>
    </div>
</ng-template>

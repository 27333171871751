import { Component } from "@angular/core";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";

@MeetingAgendaItemComponent("adapt-attach-inputs-agenda-item")
@Component({
    selector: "adapt-attach-inputs-agenda-item",
    templateUrl: "./attach-inputs-agenda-item.component.html",
})
export class AttachInputsAgendaItemComponent {
    public pageActivationTours = [TourIdentifier.AttachInputsTour];
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;
}

import { Component, Injector, ViewChild } from "@angular/core";
import { Event } from "@common/ADAPT.Common.Model/organisation/event";
import { EventCadenceCycle } from "@common/ADAPT.Common.Model/organisation/event-cadence-cycle";
import { EventSeries, EventSeriesType } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Meeting, MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { ICycle, IItem, IItemEvent, ISection, TimeSchedulerPeriodYear, TimeSchedulerPeriodYearPlusHalf } from "@common/ux/time-scheduler/time-scheduler.interface";
import { TimeSchedulerWrapperComponent } from "@common/ux/time-scheduler/time-scheduler-wrapper/time-scheduler-wrapper.component";
import { IMeetingLocation } from "@org-common/lib/calendar/calendar.interface";
import { ISetCadenceRunData } from "@org-common/lib/schedule/schedule.interface";
import { ScheduleService } from "@org-common/lib/schedule/schedule.service";
import { IScheduledRecurrence } from "@org-common/lib/schedule/schedule-recurrence/schedule-recurrence.interface";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import moment from "moment/moment";
import { BehaviorSubject, lastValueFrom, of, switchMap } from "rxjs";
import { EventTypePresets, ISetCadenceStepData } from "../establish-cadence-workflows";

@WorkflowStepComponent("adapt-set-cadence-step")
@Component({
    selector: "adapt-set-cadence-step",
    templateUrl: "./set-cadence-step.component.html",
})
export class SetCadenceStepComponent extends WorkflowStepComponentAdapter {
    public eventTypePreset!: EventTypePreset;
    public runData!: ISetCadenceRunData;
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    public lastLocation?: IMeetingLocation;
    public locations: IMeetingLocation[] = [];

    public warning?: string;
    public configDisabled = false;
    public allowUndo = true;

    @ViewChild(TimeSchedulerWrapperComponent) public scheduler?: TimeSchedulerWrapperComponent;
    public sections: ISection[] = [];
    public items: IItem<Event>[] = [];

    public currentPeriod = TimeSchedulerPeriodYearPlusHalf;
    public cycle?: ICycle;

    // start display at this month (doesn't make sense to be scheduling in the past)
    public schedulerStartTime = moment().startOf("month").hour(9);

    public popupItem?: IItemEvent<Event>;
    public popupVisible = false;

    // should we clear all other series on next?
    private clearAllSeries = false;

    private cadenceCycle!: EventCadenceCycle;

    public constructor(
        injector: Injector,
        private scheduleService: ScheduleService,
        private teamsService: CommonTeamsService,
        private dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
    ) {
        super();

        this.subscribeToEmitForEntityTypeChange(injector, Event);
        this.subscribeToEmitForEntityTypeChange(injector, EventSeries);

        // this can be updated due to linked event updating
        this.subscribeToEmitForEntityTypeChange(injector, Meeting);
    }

    public get currentRecurrence() {
        return this.runData?.scheduledPresets.get(this.eventTypePreset);
    }

    public set currentRecurrence(recurrence: IScheduledRecurrence | undefined) {
        if (recurrence) {
            this.runData.scheduledPresets.set(this.eventTypePreset, recurrence);
        }
    }

    public get nextTimes() {
        const eventTypeId = this.currentRecurrence?.eventSeries.eventTypeId;
        return this.items
            .filter((i) => i.sectionId === eventTypeId)
            .map((i) => i.start.toDate());
    }

    public get eventName() {
        const recurrence = this.currentRecurrence;
        if (!recurrence || !recurrence.eventSeries.eventType) {
            return undefined;
        }

        const once = recurrence.eventSeries.eventSeriesType === EventSeriesType.Once;
        const name = recurrence.eventSeries.eventType.name + (!once ? "s" : "");
        const code = `(${recurrence.eventSeries.eventType.code}${!once ? "'s" : ""})`;
        return `${name} ${code}`;
    }

    public async workflowStepOnInit() {
        const data = this.workflowStep?.customData as ISetCadenceStepData;
        if (!data) {
            throw new Error("WorkflowStep customData is not available");
        }
        this.eventTypePreset = data.eventType;

        const team = await this.teamsService.promiseToGetLeadershipTeam();
        if (!team) {
            throw new Error("Leadership team not found");
        }

        const cadenceCycle = await lastValueFrom(this.scheduleService.getEventCadenceCycle());
        if (!cadenceCycle) {
            throw new Error("EventCadenceCycle must exist to continue setting cadence");
        }
        this.cadenceCycle = cadenceCycle;

        const workflow = this.workflowStep?.workflow;
        if (workflow) {
            // runData holds the configured schedules so we can add them all at once at the end
            this.runData = workflow.runData = workflow.runData ?? {
                scheduledPresets: new Map(),
                deletedEntities: [],
            } as ISetCadenceRunData;

            // we only manually populate runData from the result here
            // if we populate cadences we haven't configured yet behaviour gets weird
            const runData = await this.scheduleService.getCadenceRunData(EventTypePresets, team);
            this.updateFromRunData(runData);
        }
    }

    public workflowStepNext() {
        // nothing to do
        if (!this.clearAllSeries) {
            return of(undefined);
        }

        // get all presets that aren't the one we're configuring currently
        const presets = Array.from(this.runData.scheduledPresets.values())
            .filter(({ eventTypePreset }) => eventTypePreset !== this.eventTypePreset);

        const presetListEntries = presets.map(({ eventSeries }) => `<li>${eventSeries.eventType.name}</li>`);

        return this.dialogService.openConfirmationDialog({
            title: "Confirm cadence adjustment",
            message: `<p>${this.warning}</p>
                <p>All events and meetings created as part of the following cadences will be cleared:</p>
                <ul>${presetListEntries.join("\n")}</ul>
                <p>You may cancel if you would like to change the start date.</p>`,
        }).pipe(
            switchMap(() => this.scheduleService.promiseToClearConfiguredCadences(this.runData, this.eventTypePreset)),
        );
    }

    public async onRecurrenceChange(scheduledRecurrence: IScheduledRecurrence) {
        this.currentRecurrence = scheduledRecurrence;
        const { eventSeries } = scheduledRecurrence;

        this.allowUndo = eventSeries.entityAspect.entityState.isModified();
        this.configDisabled = false;
        this.clearAllSeries = false;
        this.warning = undefined;

        if (scheduledRecurrence.eventTypePreset === EventTypePreset.AnnualStrategy) {
            const monthDiff = moment().diff(eventSeries.startDate, "month", true);
            if (Math.abs(monthDiff) >= 1) {
                this.warning = `We recommend scheduling your ${scheduledRecurrence.eventSeries.eventType.name} within a month from today.`;
            }
        }

        if (eventSeries.entityAspect.entityState.isModified()) {
            if (scheduledRecurrence.eventTypePreset === EventTypePreset.AnnualStrategy) {
                const event = this.scheduleService.getLatestEventForEventSeries(eventSeries);
                if (event) {
                    const meeting = this.scheduleService.getMeetingForEvent(event);
                    if (meeting && meeting.status !== MeetingStatus.NotStarted) {
                        this.configDisabled = true;
                        this.warning = "This event has already occurred so you cannot edit it further.";
                    } else {
                        // AO that is already configured has been updated
                        const originalStartDate = eventSeries.entityAspect.originalValues.startDate;
                        if (originalStartDate) {
                            const daysDiff = moment(originalStartDate).diff(eventSeries.startDate, "days");
                            this.log.debug("AO startDate difference is larger than 1 week?", { daysDiff, originalStartDate, startDate: eventSeries.startDate });
                            if (Math.abs(daysDiff) > 7) {
                                this.warning = `Moving your ${scheduledRecurrence.eventSeries.eventType.name} by more than 1 week will require your schedule to be rebuilt. Any changes will be lost.`;
                                this.clearAllSeries = true;
                            }
                        }
                    }
                }
            } else {
                const meeting = this.scheduleService.getLatestMeetingForEventSeries(eventSeries);
                const deletedMeeting = scheduledRecurrence.deletedEntities?.find(ObjectUtilities.createIsInstanceFilter(Meeting));
                if (meeting || deletedMeeting) {
                    this.warning = "Changing this cadence will cause its upcoming meetings to be recreated. Any changes will be lost.";
                }
            }
        }

        const events = await this.getAllEvents();
        this.items = events.map(ScheduleService.SchedulerItemFromEvent);
    }

    public async undoChanges() {
        const recurrence = this.currentRecurrence;
        if (recurrence) {
            await lastValueFrom(this.commonDataService.rejectChanges([
                recurrence.eventSeries,
                ...recurrence.eventSeries.events,
                ...(recurrence.deletedEntities ?? []),
            ]));
            this.onRecurrenceChange(recurrence);
        }
    }

    private updateFromRunData(runData: ISetCadenceRunData) {
        this.sections = [];
        this.locations = [];

        for (const config of runData.scheduledPresets.values()) {
            this.sections.push(ScheduleService.SchedulerSectionFromEventType(config.eventSeries.eventType));

            // populate runData from the given recurrences
            // but only if already configured or if its the current preset
            // behaviour gets odd when you populate ones that are not configured...
            if (config.eventSeries.entityAspect.entityState.isUnchangedOrModified()
                || config.eventTypePreset === this.eventTypePreset) {
                // make sure we copy over the deleted entities for the config
                const existingPreset = this.runData.scheduledPresets.get(config.eventTypePreset!);
                if (existingPreset?.deletedEntities) {
                    config.deletedEntities = (config.deletedEntities ?? []).concat(existingPreset.deletedEntities);
                }
                this.runData.scheduledPresets.set(config.eventTypePreset!, config);
            }

            const location = !config.eventSeries.location
                ? undefined
                : {
                    name: config.eventSeries.location,
                    emailAddress: config.eventSeries.calendarIntegrationLocationId,
                } as IMeetingLocation;

            if (location && !this.locations.find((loc) => loc.name === location.name)) {
                this.locations.push(location);
            }

            if (config.eventTypePreset === this.eventTypePreset) {
                // get the currently defined location or use the last provided location as the default
                this.lastLocation = location ?? this.locations[this.locations.length - 1];

                // only allow continue after a config exists
                this.workflowStepCompleted.next(true);
            }
        }

        this.updateFromCadenceCycle();
    }

    private updateFromCadenceCycle() {
        const today = new Date();
        const cadenceStartDate = moment(DateUtilities.getFirstWorkingDay(today.getFullYear(), today.getMonth(), today.getDate()))
            .hour(9)
            .minute(0)
            .toDate();

        // always use the AS startDate for calculating the cycle endDate
        // if this is the first preset calculation, we need to default to the same cadenceStartDate, as the scheduledPresets map won't have the AS preset yet.
        const annualStartDate = this.runData.scheduledPresets.get(EventTypePreset.AnnualStrategy)?.eventSeries.startDate ?? cadenceStartDate;
        const endDate = this.cadenceCycle.extensions.getEndDate(annualStartDate);

        this.cycle = { start: annualStartDate, end: endDate };

        // update the current period view based on the cycle length
        const cycleMonths = this.cadenceCycle.extensions.getCycleLength(annualStartDate);
        this.currentPeriod = cycleMonths > 13
            ? TimeSchedulerPeriodYearPlusHalf
            : TimeSchedulerPeriodYear;
    }

    private async getAllEvents() {
        const events: Event[] = [];

        for (const stepData of this.runData.scheduledPresets.values()) {
            let conflictingEvents: Event[] | undefined;
            const conflictingEventTypes = stepData.config.skipEventTypeConflict;
            if (conflictingEventTypes) {
                // get all events that match the specified eventTypes.
                // promiseToCreateOrUpdateEventsForSchedule will make sure it skips any conflicting events
                conflictingEvents = Array.from(this.runData.scheduledPresets.entries())
                    .filter(([preset]) => conflictingEventTypes.includes(preset as EventTypePreset))
                    .flatMap(([_, data]) => data.eventSeries.events);
            }

            const eventSeries = await this.scheduleService.promiseToCreateOrUpdateEventsForSchedule(stepData, conflictingEvents);
            events.push(...eventSeries.events);
        }

        return events;
    }
}

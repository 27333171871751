import { Workflow } from "./workflow";
import { WorkflowStep } from "./workflow-step";

export class WorkflowExtensions {

    public constructor(private workflow: Workflow) { }

    public get firstWorkflowStepId() {
        if (!this.workflow.steps?.length) {
            return undefined;
        } else {
            return this.workflow.steps[0].workflowStepId;
        }
    }

    public get lastWorkflowStepId() {
        if (!this.workflow.steps?.length) {
            return undefined;
        } else {
            return this.workflow.steps[this.workflow.steps.length - 1].workflowStepId;
        }
    }

    public get rootWorkflow(): Workflow {
        if (this.workflow.parentWorkflow) {
            return this.workflow.parentWorkflow.extensions.rootWorkflow;
        }

        return this.workflow;
    }

    public get rootWorkflowName(): string {
        if (this.workflow.parentWorkflow) {
            return this.workflow.parentWorkflow.extensions.rootWorkflowName;
        }

        return this.workflow.name;
    }

    public get isComingSoon() {
        return !this.workflow.steps && !this.workflow.workflows;
    }

    public getStepIndex(workflowStep: WorkflowStep) {
        if (!this.workflow.steps?.length) {
            return undefined;
        } else {
            const stepIndex = this.workflow.steps.indexOf(workflowStep);
            return (stepIndex < 0) ? undefined : stepIndex;
        }
    }
}

import { Component, OnInit } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { from, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-search-row-person",
    templateUrl: "./search-row-person.component.html",
})
export class SearchRowPersonComponent extends SearchRowBaseComponent<Person> implements OnInit {
    public href$?: Observable<string>;

    public constructor(
        protected searchService: SearchService,
        private directorySharedService: DirectorySharedService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = from(this.directorySharedService.promiseToGetProfileUrl(this.result) as Promise<string | undefined>).pipe(
            switchMap((profileUrl) => profileUrl
                ? of(profileUrl)
                : this.directorySharedService.promiseToGetDirectoryUrl(this.result) as Promise<string>),
            emptyIfUndefinedOrNull(),
        );
    }
}

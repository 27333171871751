import { Component, Injector, Input, OnChanges } from "@angular/core";
import { OutstandingSurveyResponse } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { Survey, SurveyStatus, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { surveyPageRoute } from "@org-common/lib/survey/survey-page/survey-page.route";
import { organisationDiagnosticSurveySummaryPage } from "@org-common/lib/survey/survey-summary-page/survey-summary-page.component";
import { SurveyUiService } from "@org-common/lib/survey/survey-ui.service";
import { SurveyUtils } from "@org-common/lib/survey/survey-utils";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { IWorkflowStepOverviewComponent, WorkflowStepOverviewComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { forkJoin, merge, of } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { IOrganisationDiagnosticStatusCustomData } from "../organisation-diagnostic-status-custom-data";

@WorkflowStepOverviewComponent("adapt-show-survey-progress")
@Component({
    selector: "adapt-show-survey-progress",
    templateUrl: "./show-survey-progress.component.html",
    styleUrls: ["./show-survey-progress.component.scss"],
})
export class ShowSurveyProgressComponent extends BaseComponent implements OnChanges, IWorkflowStepOverviewComponent {
    @Input() public workflowConnection?: WorkflowConnection;
    @Input() public workflowCard = true;

    public survey?: Survey;
    public participationRate = 0;
    public participationText = "Survey participation 0%";
    public surveyLink?: IAdaptLinkObject;
    public outstandingSurveyResponses: OutstandingSurveyResponse[] = [];
    public surveyCompleted = false;

    public surveySummaryLink?: IAdaptLinkObject;

    public constructor(
        private injector: Injector,
        private workflowService: WorkflowService,
        private surveyService: SurveyService,
        private surveyUiService: SurveyUiService,
        private userService: UserService,
        private routeService: RouteService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        merge(
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
        ).pipe(
            filter((ent) => ent.workflowConnectionId === this.workflowConnection?.workflowConnectionId),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.onDataChanged());

        // update the list of survey responses when a survey finishes
        rxjsBreezeService.entityTypeChanged(OutstandingSurveyResponse).pipe(
            filter((ent) => ent.entityAspect.entityState.isDetached()),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.onDataChanged());
    }

    public ngOnChanges() {
        this.onDataChanged();
    }

    public onDataChanged() {
        if (this.workflowConnection) {
            this.survey = undefined;
            let customData: IOrganisationDiagnosticStatusCustomData = {};
            this.workflowService.getWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(this.workflowConnection).pipe(
                tap((data) => customData = data),
                switchMap((customData) => {
                    if (customData.surveyId) {
                        // need force remote here or we won't be seeing updated survey result which has the progress data
                        return this.surveyService.getSurveyById(customData.surveyId!, !customData.forceLocal);
                    }

                    return of(undefined);
                }),
                filter((survey) => !!survey),
                tap((survey) => {
                    this.survey = survey;
                    if (survey) {
                        const surveyResult = SurveyUtils.getSurveyResultForResponseGroup(survey, SurveyResponseGroup.All);
                        if (surveyResult) {
                            this.participationRate = surveyResult.percentageParticipation ? surveyResult.percentageParticipation : 0;
                            this.participationText = `Survey participation ${this.participationRate.toFixed(0)}%`;
                        }
                    }
                }),
                switchMap((survey) => {
                    this.surveyCompleted = survey?.status === SurveyStatus.Ended;
                    if (this.surveyCompleted) {
                        return organisationDiagnosticSurveySummaryPage.getRouteObject(
                            undefined,
                            setReturnPathSearchParam(this.injector, {}, organisationDiagnosticSurveySummaryPage.id, "Continue pathway"),
                        ).pipe(
                            map((odSummaryRoute) => {
                                this.surveySummaryLink = odSummaryRoute;
                                return survey;
                            }),
                        );
                    } else {
                        return of(survey);
                    }
                }),
                switchMap((survey) => forkJoin([
                    this.surveyService.getOutstandingSurveyResponsesForSurvey(survey!.surveyId, !customData.forceLocal),
                    survey?.surveyType === SurveyType.OrganisationDiagnostic
                        ? this.workflowService.getPersonalPageWithContinueWorkflow(this.workflowConnection!.workflowId)
                        : of(undefined),
                ])),
                switchMap(([outstandingResponses, returnPath]) => {
                    this.outstandingSurveyResponses = outstandingResponses;
                    if (outstandingResponses.length > 0) {
                        const currentPersonId = this.userService.getCurrentPersonId();
                        const myOutstandingResponse = outstandingResponses.find((response) => response.connection?.personId === currentPersonId);
                        if (myOutstandingResponse) {
                            return surveyPageRoute.getRouteObject({}, {
                                token: myOutstandingResponse.token,
                                name: myOutstandingResponse.survey.name,
                                returnPath: returnPath?.url,
                            });
                        }
                    }

                    return of(undefined);
                }),
                tap((surveyLink) => this.surveyLink = surveyLink),
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }

    public viewOutstandingResponses() {
        return this.surveyUiService.viewOutstandingResponsesForSurvey(this.survey!, true).subscribe();
    }

    @Autobind
    public async takeSurvey(event?: CustomEvent) {
        if (this.surveyLink) {
            this.routeService.navigateByUrl(this.surveyLink.url);
        }

        // stop workflow from running on click
        if (event) {
            event.preventDefault();
        }
    }
}

<adapt-dialog [maxWidth]="950">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <h3>Search options</h3>
        <div class="d-flex align-items-center">

            <!-- to be expanded into an actual selector once we allow objectives, minutes, decisions, etc -->
            <h4 class="me-2 d-none">Actions</h4>

            <dx-text-box #focus
                         class="flex-grow-1 me-1"
                         [(value)]="searchTerm"
                         (valueChange)="onValueChange()"
                         placeholder="Search item code, summary or description..."
                         data-test="search-items-textbox">
            </dx-text-box>

            <adapt-select-team [(team)]="team"
                               (teamChange)="onValueChange()"></adapt-select-team>

            <button class="ms-3"
                    adaptButton="iconlessLink"
                    *ngIf="!showAdvanced"
                    (click)="showAdvanced = true">Advanced options</button>
        </div>

        <div class="d-flex row mt-3"
             *ngIf="showAdvanced">
            <div class="col">
                <h4>Statuses</h4>
                <dx-tag-box [items]="allStatusItems"
                            [(value)]="selectedStatusItems"
                            (selectedItemsChange)="onValueChange()"
                            [showDropDownButton]="true"
                            [showSelectionControls]="true"
                            displayExpr="name"
                            placeholder="Select item status..."
                            [width]="300">
                </dx-tag-box>
            </div>

            <div class="col">
                <h4>Assignee</h4>
                <adapt-select-person [(person)]="selectedPerson"
                                     (personChange)="onValueChange()"
                                     [teamGroup]="team"></adapt-select-person>
            </div>

            <div class="col">
                <h4>Updated within</h4>
                <dx-select-box [items]="dates"
                               [showClearButton]="true"
                               [(value)]="updatedDate"
                               (valueChange)="onValueChange()"
                               displayExpr="text"
                               valueExpr="value"
                               placeholder="Select time period..."></dx-select-box>
            </div>
        </div>

        <div class="d-flex align-items-end mt-3 mb-1 border-top">
            <h3 class="pt-3 mb-0">{{showMostRecent ? 'Most Recent' : 'Results'}}
                <span *ngIf="items.length > 0">({{items.length}})</span>
            </h3>
            <button class="ms-auto"
                    adaptButton="iconlessLink"
                    *ngIf="hasFilterChanged"
                    (click)="resetFilter()">Reset Filter</button>
        </div>
        <div class="result-list p-2 d-flex flex-column">
            <div *adaptLoading="loadingItems">
                <div *ngIf="items.length === maxSearchResults"
                     role="alert"
                     class="alert alert-info">
                    Your search returned more than {{maxSearchResults}} results.
                    The number of actions shown has been limited to this number.
                </div>

                <dx-list [items]="items"
                         (onSelectionChanged)="selectionChanged($event)"
                         selectionMode="single"
                         [scrollingEnabled]="false"
                         class="dx-list-boxed">
                    <div *dxTemplate="let item of 'item'">
                        <adapt-item-listing-search [item]="item"></adapt-item-listing-search>
                    </div>
                </dx-list>
            </div>
        </div>
    </div>

    <div adapt-dialog-footer
         class="ms-auto d-flex w-100">
        <button *ngIf="dialogData.linking"
                class="btn btn-primary me-auto"
                adaptButtonIcon="fal fa-fw fa-plus-square"
                (click)="createItem()"
                data-test="create-item-button">Create &amp; link new action</button>
        <button *ngIf="dialogData.linking"
                class="btn btn-primary"
                adaptButtonIcon="fal fa-fw fa-paperclip"
                (click)="select()"
                [disabled]="!selectedItem"
                data-test="add-link-button">Add link</button>
        <button adaptButton="close"
                (click)="resolve({})"></button>
    </div>
</adapt-dialog>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Account, PaymentMethod } from "./account";
import { AccountPayment } from "./account-payment";
import { InvoiceItem } from "./invoice-item";

export class Invoice extends BaseEntity<Invoice> {
    public invoiceId!: number;
    public accountId!: number;
    public date!: Date;
    public periodStartDate!: Date;
    public periodEndDate!: Date;
    public paymentMethod!: PaymentMethod;
    public status!: string;
    public account!: Account | null;
    public accountPayments!: AccountPayment[];
    public invoiceItems!: InvoiceItem[];

    public get total() {
        let amount = 0;
        this.invoiceItems.forEach((item) => amount += item.quantity * item.amount);
        return amount;
    }
}

export const InvoiceBreezeModel = new BreezeModelBuilder("Invoice", Invoice)
    .hasSource()
    .isOrganisationEntity()
    .alwaysFetchingNavigationProperty("invoiceItems")
    .alwaysFetchingNavigationProperty("accountPayments")
    .build();

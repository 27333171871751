import { Component, ElementRef, HostListener, Input } from "@angular/core";
import { Anchor, AnchorMetadata } from "@common/ADAPT.Common.Model/organisation/anchor";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { StrategyAuthService } from "../../strategy/strategy-auth.service";
import { StrategicAnchorsService } from "../strategic-anchors.service";

@Component({
    selector: "adapt-strategic-anchor",
    templateUrl: "./strategic-anchor.component.html",
    styleUrls: ["./strategic-anchor.component.scss"],
})
export class StrategicAnchorComponent extends BaseComponent {
    public readonly EditStrategicAnchors = StrategyAuthService.EditStrategicAnchors;
    public readonly AnchorMetadata = AnchorMetadata;

    @Input() public anchor?: Anchor;
    @Input() public isEditing = true;
    @Input() public isCollapsible = false;
    @Input() public collapsed = false;

    public constructor(
        elementRef: ElementRef,
        private anchorsService: StrategicAnchorsService,
    ) {
        super(elementRef);
    }

    // this won't stop propagation to container (e.g. dxList or dxSortable)
    @HostListener("click", ["$event"])
    public stopPropagation(e: Event) {
        e.stopPropagation();
    }

    @Autobind
    public editAnchor() {
        return this.anchorsService.editAnchor(this.anchor!);
    }
}

import { Component, Inject, OnInit } from "@angular/core";
import { Value } from "@common/ADAPT.Common.Model/organisation/value";
import { ORGANISATION_VALUES_PAGE, TEAM_VALUES_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { IAdaptLinkObject } from "@common/route/route.service";
import { IValueSearchResult } from "@org-common/lib/search/search.interface";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { ValuesConstitutionUtilities } from "@org-common/lib/values-constitution/values-constitution-utilities";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-search-row-value",
    templateUrl: "./search-row-value.component.html",
})
export class SearchRowValueComponent extends SearchRowBaseComponent<IValueSearchResult> implements OnInit {
    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
        @Inject(ORGANISATION_VALUES_PAGE) private organisationValuesRoute?: IAdaptRoute<{}>,
        @Inject(TEAM_VALUES_PAGE) private teamValuesRoute?: IAdaptRoute<{ teamId: number }>,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = this.result.TeamId
            ? this.teamValuesRoute
                ? this.teamValuesRoute!.getRouteObject({ teamId: this.result.TeamId })
                : undefined
            : this.organisationValuesRoute
                ? this.organisationValuesRoute!.getRouteObject({}, { value: ValuesConstitutionUtilities.generateValueId({ name: this.result.Name! } as Value) })
                : undefined;

        this.matches = this.excludeNameBreadcrumb(this.result.Results);
    }
}

import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { Logger } from "@common/lib/logger/logger";
import { SentryLogProvider } from "@common/lib/logger/sentry-log-provider";
import { Trace } from "@common/lib/logger/trace";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import isEqual from "lodash.isequal";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { ISearchGroup, ISearchOptions, ISearchResults, SearchType } from "../search.interface";
import { searchGroupMapping, SearchService, SearchSlowErrorTimeout, SearchSlowTimeout } from "../search.service";
import Timeout = NodeJS.Timeout;

@Component({
    selector: "adapt-search-page",
    templateUrl: "./search-page.component.html",
    styleUrls: ["./search-page.component.scss"],
})
export class SearchPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public searchType = SearchType;

    public searchResults?: ISearchResults;
    public options?: ISearchOptions;
    public isLoading = false;

    public labels: Label[] = [];

    public slowTimeouts: Timeout[] = [];
    public slowLoadingIndicator = false;

    public sentryLogger = Logger.getLogProviderOfType(SentryLogProvider);
    private searchService: SearchService;

    public searchElements = SearchService.SearchElementRegistrar!;

    constructor(injector: Injector) {
        super(injector);

        // This is to break cyclic webpack dependency from cumulus unit test, with:
        //  SearchService -> searchPageRoute -> searchPageComponent -> SearchService
        this.searchService = injector.get(SearchService);
    }

    public ngOnInit() {
        this.searchService.showResults();

        this.restoreFromParams();

        this.searchService.searchOptions$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((options) => this.options = options);

        this.searchService.searchLabels$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((labels) => this.labels = labels);

        this.searchService.searchResults$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((results) => {
            this.searchResults = results;
        });

        this.searchService.isLoading$.pipe(
            distinctUntilChanged(),
            tap((loading) => {
                this.isLoading = loading;
                if (loading) {
                    this.slowTimeouts.push(setTimeout(() => this.slowLoadingIndicator = true, SearchSlowTimeout));
                    this.slowTimeouts.push(setTimeout(() => {
                        this.sentryLogger?.write({
                            level: Trace.Error,
                            timestamp: new Date(),
                            moduleId: this.constructor.name,
                            message: "Search taking a while...",
                            data: [],
                        });
                    }, SearchSlowErrorTimeout));
                } else {
                    this.slowTimeouts.forEach(clearTimeout);
                    this.slowLoadingIndicator = false;
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.notifyActivated();
        this.navigationEnd.subscribe(() => {
            this.searchService.showResults();
            this.restoreFromParams();
            this.notifyActivated();
        });
    }

    public get resultCount() {
        return Object.values(this.searchResults ?? {}).reduce((acc, category) => {
            acc += category?.length ?? 0;
            return acc;
        }, 0);
    }

    public get validQuery() {
        return this.searchService.shouldPerformSearch(this.options?.keyword, this.options?.labelIds);
    }

    public get groups() {
        if (this.searchResults) {
            // only show groups that have results
            return searchGroupMapping.filter((i) =>
                Array.isArray(i.value)
                    ? i.value.some((type) => this.groupHasResults(type))
                    : this.groupHasResults(i.value));
        }

        return [];
    }

    public getGroup(type: SearchType): ISearchGroup {
        return SearchService.SearchTypeMapping[type];
    }

    public getGroupKey(type: SearchType) {
        return SearchType[type] as keyof typeof SearchType;
    }

    public getTypes(group: ISearchGroup): SearchType[] {
        return Array.isArray(group.value) ? group.value : [group.value];
    }


    public getErrors(type: SearchType | SearchType[]) {
        return (Array.isArray(type)
            ? type.map((t) => this.searchService.providerSearchErrors.get(t)).filter((e) => !!e).flat(1)
            : this.searchService.providerSearchErrors.get(type)
        ) ?? [];
    }

    public get searchError$() {
        return this.searchService.searchError$;
    }

    public get showImplementationKitResults() {
        const errors = this.getErrors(SearchType.ImplementationKit);
        return (this.isLoading && this.options?.types.has(SearchType.ImplementationKit))
            || (this.searchResults && (this.hasImplementationKitResults || errors.length > 0));
    }

    private get hasImplementationKitResults() {
        return this.searchResults?.ImplementationKit
            && this.searchResults.ImplementationKit.length > 0
            && !this.isLoading;
    }

    private groupHasResults(type: SearchType) {
        const key = this.getGroupKey(type);
        // filter out Implementation Kit results as we show those separately
        return (this.searchResults![key]?.length ?? 0) > 0
            && type !== SearchType.ImplementationKit;
    }

    private restoreFromParams() {
        // setting the options here can cause multiple searches as this is run again when the searchParams update
        // so make sure the options have actually changed first before setting
        const options = this.searchService.optionsFromSearchParams(this.getSearchParameters()!);
        if (!isEqual(options, this.options)) {
            // make sure we start with the defaults so that label, etc. are overridden
            this.searchService.setSearchOptions({ ...this.searchService.getEmptyOptions(), ...options });
        }
    }
}

import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TEAM_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { ValuesConstitutionAuthService } from "@org-common/lib/values-constitution/values-constitution-auth.service";
import { CategoryWorkflowStatus } from "@org-common/lib/workflow/workflow-map-dashboard-element/category-workflow-status.enum";
import { of, Subject } from "rxjs";
import { filter, startWith, switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-team-dashboard-page",
    templateUrl: "./team-dashboard-page.component.html",
    styleUrls: ["./team-dashboard-page.component.scss"],
})
export class TeamDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly ReadObjectives = ObjectivesAuthService.ReadObjectives;
    public readonly ValuesRead = ValuesConstitutionAuthService.ReadValuesConstitution;
    public readonly PurposeVisionRead = CommonPurposeVisionAuthService.ReadPurposeVision;
    public readonly viewTeamMeetings = CommonTeamsAuthService.ViewTeamMeetings;
    public readonly HiddenStatuses = [CategoryWorkflowStatus.ComingSoon, CategoryWorkflowStatus.Completed, CategoryWorkflowStatus.Blocked, CategoryWorkflowStatus.Current];

    public team$ = new Subject<Team>();
    public configPeopleUrl?: IAdaptLinkObject;

    constructor(
        private teamsService: CommonTeamsService,
        private teamsAuthService: CommonTeamsAuthService,
        injector: Injector,
        @Inject(TEAM_CONFIGURATION_PAGE) private teamConfigurationPageRoute: IAdaptRoute<{ teamId: number }>,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        this.navigationEnd.pipe( // this will handle the case for multiple teams and switching between those team dashboard where component is not reloaded
            startWith(undefined), // to trigger initial page update
            switchMap(() => {
                const teamId = this.getRouteParamInt("teamId");
                if (teamId) {
                    return this.teamsService.getTeamById(teamId).pipe(
                        filter((team) => !!team),
                        tap((team) => this.team$.next(team!)),
                        switchMap((team: Team) => this.teamsAuthService.currentPersonCanEditTeam(team)
                            ? this.teamConfigurationPageRoute.getRouteObject({ teamId: team.teamId }, { section: ConfigArea[ConfigArea.People] })
                            : of(undefined)),
                    );
                }

                // still emit something to activate the page
                return of(undefined);
            }),
        ).subscribe((urlObj) => {
            // configPeopleUrl will be undefined if user doesn't have permission
            this.configPeopleUrl = urlObj;
            this.notifyActivated();
        });
    }
}

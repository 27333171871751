import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "../workflow-identifier.enum";

export const alignTeamPurposeValuesWorkflowName = "Align team on purpose & values";

export const alignTeamPurposeValuesWorkflow = buildLocalWorkflow({
    name: alignTeamPurposeValuesWorkflowName,
    workflowId: WorkflowIdentifier.AlignTeamPurposeValues,
    type: WorkflowType.Journey,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Align your leadership team's purpose and values - why your team exists, what it is doing and how do you behave.",
    category: OrganisationCategoryValue.EffectiveLeadershipTeam,
    articleSlug: ImplementationKitArticle.ComingSoon, // a coming soon HelpJuice document
    ordinal: 30,
});

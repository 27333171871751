<dx-menu [items]="items"
         [disabled]="disabled"
         [orientation]="orientation"
         [showFirstSubmenuMode]="showOnHover ? {name: 'onHover', delay: 0} : { name: 'onClick', delay: { show: 50, hide: 300 } }"
         [hideSubmenuOnMouseLeave]="showOnHover"
         (onSubmenuShowing)="visibleChanged.emit(true)"
         (onSubmenuHidden)="visibleChanged.emit(false)"
         (onItemClick)="onItemClick($event)">
    <div *dxTemplate="let item of 'item'"
         [ngClass]="{'separator-top': item.separator || item.separatorTop, 'separator-bottom': item.separatorBottom}"
         class="p-2"
         [attr.data-tour]="item.text">

        <ng-container [ngTemplateOutlet]="item.templateRef ?? defaultTemplate"
                      [ngTemplateOutletContext]="{$implicit: item}">
        </ng-container>

        <ng-template #defaultTemplate>
            <i [ngClass]="item.icon"></i>

            <span *ngIf="item.text"
                  [adaptTooltip]="item.tooltip"
                  class="ms-1">{{item.text}}</span>
        </ng-template>
    </div>
</dx-menu>

import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamMeetingGuidance } from "@common/ADAPT.Common.Model/organisation/team-meeting-guidance";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { CommonTeamsUiService } from "../common-teams-ui.service";

@Component({
    selector: "adapt-team-meeting-guidance-dashboard-element",
    templateUrl: "./team-meeting-guidance-dashboard-element.component.html",
})
export class TeamMeetingGuidanceDashboardElementComponent {
    // this is the same permission for team meeting guidance edit
    public readonly editTeamKanban = CommonTeamsAuthService.EditTeamKanban;

    @Input() public set team(value: Team) {
        this.team$.next(value);
    }

    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    public teamMeetingGuidance$: Observable<TeamMeetingGuidance | undefined>;
    public canEditTeamGuidance$: Observable<boolean>;

    public constructor(
        authorisationService: AuthorisationService,
        private meetingsService: MeetingsService,
        private commonTeamsUiService: CommonTeamsUiService,
    ) {
        this.teamMeetingGuidance$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => this.meetingsService.getMeetingGuidanceForTeam(team.teamId)),
        );

        this.canEditTeamGuidance$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => authorisationService.promiseToGetHasAccess(CommonTeamsAuthService.EditTeamKanban, team)),
        );
    }

    public editTeamMeetingGuidance() {
        return this.team$.pipe(
            switchMap((team) => this.commonTeamsUiService.configureTeamStewardship(team!)),
        ).subscribe();
    }
}

<adapt-dialog [maxWidth]="640">
    <h3 adapt-dialog-title>{{title}}</h3>
    <div adapt-dialog-content
         class="h-100 d-flex flex-column">
        <div class="d-flex align-items-center">
            <div class="value-column">
                <label>Value</label>
                <dx-number-box format="decimal"
                               [step]="0.1"
                               [(value)]="measurement.value"
                               [adaptValidateEntity]="measurement"
                               adaptValidateEntityProperty="value"></dx-number-box>
            </div>
            <span class="readonly-unit-column mt-3"
                  *ngIf="measurement.goal?.unit">{{measurement.goal!.unit}}</span>
            <div class="date-column">
                <label>Date</label>
                <dx-date-box type="date"
                             pickerType="calendar"
                             displayFormat="dd/MM/yyyy"
                             format="dd/MM/yyyy"
                             [value]="measurement.timestamp"
                             [min]="firstTargetTimestamp"
                             (valueChange)="measurement.timestamp = $any($event)"
                             [adaptValidateEntity]="measurement"
                             adaptValidateEntityProperty="timestamp"></dx-date-box>
            </div>
        </div>

        <div class="comment-section mt-3 w-100">
            <label>Comment</label>
            <dx-text-box [(value)]="measurement.comment"></dx-text-box>
        </div>
    </div>
    <ng-container adapt-dialog-footer>
        <button adaptButton="delete"
                [adaptBlockingClick]="deleteMeasurement"
                adaptTooltip="Delete this measurement"
                *ngIf="!isNew"></button>
        <adapt-standard-dialog-buttons [saveBlockingClick]="saveAndClose"
                                       [saveIsDisabled]="measurement.entityAspect.hasValidationErrors"
                                       (cancelClick)="cancel()"
                                       cancelText="Cancel"></adapt-standard-dialog-buttons>
    </ng-container>
</adapt-dialog>

<div *ngIf="isFullLayout$ | async; else verticalLayoutTemplate"
     class="zone-map-full-layout">
    <div class="not-org-design">
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.EconomicEngine}"></ng-container>

        <div class="not-economic-engine">
            <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.OrganisationDesign}"></ng-container>
            <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.HealthyCulture}"></ng-container>
            <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.FinanceAndOwnership}"></ng-container>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="globalZoneTemplate"></ng-container>
</div>

<ng-template #verticalLayoutTemplate>
    <div class="zone-map-vertical-layout">
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.EconomicEngine}"></ng-container>
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.OrganisationDesign}"></ng-container>
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.HealthyCulture}"></ng-container>
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.FinanceAndOwnership}"></ng-container>

        <ng-container *ngTemplateOutlet="globalZoneTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #zoneDisplayTemplate
             let-zone>
    <div class="zone"
         [attr.data-test]="'zone-' + zone"
         [ngClass]="$any(zoneClassMapping)[zone]">
        <div class="zone-element">
            <div class="d-flex w-100 align-items-center">
                <adapt-zone-label [zone]="zone"></adapt-zone-label>
                <div *ngIf="zoneMenuTemplate as menuTemplate"
                     class="ms-auto">
                    <ng-container [ngTemplateOutlet]="menuTemplate"
                                  [ngTemplateOutletContext]="{$implicit: zone}"></ng-container>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="zoneContentContainerTemplate"
                          [ngTemplateOutletContext]="{$implicit: zone}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #globalZoneTemplate>
    <div class="global-zone zone"
         *ngIf="showGlobalZoneLocation">
        <div class="zone-element">
            <ng-container [ngTemplateOutlet]="globalZoneContentContainerTemplate"></ng-container>
        </div>
    </div>
</ng-template>

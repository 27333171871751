import { Component, Input } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { ITeamKanbanStats, KanbanService } from "../kanban.service";
import { teamKanbanPageRoute } from "../kanban-page/kanban-page.route";
import { KanbanUiService } from "../kanban-ui.service";

interface IItemCount {
    count: number;
    label: string;
}

@Component({
    selector: "adapt-team-work-dashboard-element",
    templateUrl: "./team-work-dashboard-element.component.html",
    styleUrls: ["./team-work-dashboard-element.component.scss"],
})
export class TeamWorkDashboardElementComponent extends BaseComponent {
    public readonly editTeamKanban = CommonTeamsAuthService.EditTeamKanban;

    @Input() public set team(value: Team) {
        this.team$.next(value);
    }
    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    public url$: Observable<string | undefined>;
    public boards: Board[] = [];
    public itemCounts: IItemCount[] = [];
    public isTeamActive = false;
    public kanbanStats?: ITeamKanbanStats;
    public configureTeam = CommonTeamsAuthService.ConfigureTeam;
    public showElement = false;

    public constructor(
        private authService: AuthorisationService,
        private kanbanService: KanbanService,
        private kanbanUiService: KanbanUiService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        combineLatest([
            rxjsBreezeService.entityTypeChanged(Item),
            this.team$,
        ]).pipe(
            filter(([item, team]) => !!(team && item?.board?.team === team)),
            switchMap(([_, team]) => this.setKanbanStats(team!)),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => this.kanbanService.getBoardsByTeam(team.teamId)),
            withLatestFrom(this.team$),
            tap(([boards, team]) => {
                this.isTeamActive = team!.isActive();
                this.boards = boards;
            }),
            switchMap(([boards, team]) => {
                if (boards.length) {
                    return this.setKanbanStats(team!);
                } else {
                    return this.authService.promiseToGetHasAccess(CommonTeamsAuthService.ConfigureTeamKanban, team);
                }
            }),
            tap((showElement) => this.showElement = !!showElement),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.url$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => teamKanbanPageRoute.getRoute(team)),
        );
    }

    @Autobind
    public createNewItem() {
        return this.team$.pipe(
            switchMap((team) => this.kanbanUiService.createItem(team!.boards)),
        ).subscribe();
    }

    @Autobind
    private setKanbanStats(team: Team) {
        return this.kanbanService.getKanbanStatusCounts(team.organisationId, team.teamId).pipe(
            tap((stats) => {
                this.kanbanStats = stats;

                this.itemCounts = [];
                this.itemCounts.push({
                    count: this.kanbanStats!.newItemsLastWeekCount,
                    label: "New actions in the last week",
                });
                this.itemCounts.push({
                    count: this.kanbanStats!.newCommentsLastWeekCount,
                    label: "New comments in the last week",
                });

                const overdueItems = {
                    count: 0,
                    label: "Overdue actions",
                };
                this.kanbanStats!.statusCounts.forEach((status) => overdueItems.count += status.overdueCount);
                this.itemCounts.push(overdueItems);
            }),
        );
    }
}

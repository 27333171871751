<adapt-render-zone-map-internal [zoneContentContainerTemplate]="expandedZoneContentTemplate"
                                [globalZoneContentContainerTemplate]="expandedGlobalZoneContentTemplate"
                                [showGlobalZoneLocation]="showGlobalZoneLocation"></adapt-render-zone-map-internal>

<ng-template #expandedZoneContentTemplate
             let-zone>
    <div class="zone-content-container"
         [ngClass]="zoneContentClass(zone)">
        <div *ngIf="zone !== Zone.EconomicEngine; else economicEngineTemplate"
             class="zone-content">
            <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                          [ngTemplateOutletContext]="{zone: zone}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #economicEngineTemplate>
    <div class="research-and-development-container show-on-left"
         data-test="zone-ResearchAndDevelopment"
         *ngIf="showOnLeft && showResearchAndDevelopment">
        <div class="research-zone-name">
            <adapt-zone-label [zone]="Zone.ResearchAndDevelopment"></adapt-zone-label>
        </div>
        <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                      [ngTemplateOutletContext]="{zone: Zone.ResearchAndDevelopment}"></ng-container>
    </div>
    <div class="economic-engine-sub-container">
        <div class="value-streams-container">
            <div *ngFor="let valueStream of (valueStreams | async)"
                 class="value-stream-container">
                <ng-container *ngIf="valueStreamTitleTemplate"
                              [ngTemplateOutlet]="valueStreamTitleTemplate"
                              [ngTemplateOutletContext]="{valueStream}"></ng-container>
                <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                              [ngTemplateOutletContext]="{valueStream: valueStream}"></ng-container>
            </div>
        </div>
        <div class="economic-engine-container"
             *ngIf="showEconomicEngine">
            <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                          [ngTemplateOutletContext]="{zone: Zone.EconomicEngine}"></ng-container>
        </div>
    </div>

    <div class="research-and-development-container"
         data-test="research-and-development"
         *ngIf="!showOnLeft && showResearchAndDevelopment">
        <div class="research-zone-name">
            <adapt-zone-label [zone]="Zone.ResearchAndDevelopment"></adapt-zone-label>
        </div>
        <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                      [ngTemplateOutletContext]="{zone: Zone.ResearchAndDevelopment}"></ng-container>
    </div>
</ng-template>

<ng-template #expandedGlobalZoneContentTemplate>
    <span class="global-zone-label">{{globalZoneLocationLabel}}</span>
    <div class="zone-content-container">
        <div class="zone-content">
            <ng-container [ngTemplateOutlet]="globalZoneLocationTemplate"></ng-container>
        </div>
    </div>
</ng-template>

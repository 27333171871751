import { Injectable } from "@angular/core";
import { Value } from "@common/ADAPT.Common.Model/organisation/value";
import { ValueQuestion } from "@common/ADAPT.Common.Model/organisation/value-question";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { switchMap } from "rxjs/operators";
import { EditValueDialogComponent } from "./edit-value-dialog/edit-value-dialog.component";
import { EditValueQuestionDialogComponent } from "./edit-value-question-dialog/edit-value-question-dialog.component";
import { ValuesConstitutionService } from "./values-constitution.service";

@Injectable({
    providedIn: "root",
})
export class ValuesConstitutionUiService {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        private vcService: ValuesConstitutionService,
    ) {
    }

    public deleteValue(value: Value) {
        return this.yesNoConfirmation("Delete value", "Are you sure you wish to delete this value?").pipe(
            switchMap(() => this.vcService.deleteValue(value)),
        );
    }

    public editValue(value: Value) {
        return this.dialogService.open(EditValueDialogComponent, value);
    }

    public createAndEditValueQuestion(value: Value) {
        return this.vcService.createQuestionInValue(value).pipe(
            switchMap((vq) => this.editValueQuestion(vq)),
        );
    }

    public editValueQuestion(valueQuestion: ValueQuestion) {
        return this.dialogService.open(EditValueQuestionDialogComponent, valueQuestion);
    }

    public deleteValueQuestion(valueQuestion: ValueQuestion) {
        return this.yesNoConfirmation("Delete question", "Are you sure you wish to delete this question?").pipe(
            switchMap(() => this.vcService.deleteValueQuestion(valueQuestion)),
        );
    }

    private yesNoConfirmation(title: string, message: string) {
        return this.dialogService.openConfirmationDialog({
            title,
            message,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        });
    }
}

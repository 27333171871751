<div *adaptRerender="refresh$"
     data-tour="search-tab-content"
     class="d-flex flex-column dx-swatch-dark search-tab">
    <div class="search-header align-items-center mb-2">
        <h4 class="search-title">
            <i class="fal fa-search me-2"></i> Search
        </h4>

        <div class="search-header-buttons">
            <button adaptButton="resetBorderless"
                    (click)="reset()"
                    class="ms-auto"
                    data-test="search-reset"></button>
        </div>
    </div>

    <div class="search-body"
         *ngIf="searchOptions">
        <div class="form-group">
            <dx-text-box #button
                         placeholder="Search by keyword..."
                         [showClearButton]="true"
                         [value]="searchKeyword"
                         (onKeyUp)="onKeyUp($event)"
                         (onValueChanged)="onKeywordChanged($event)"
                         data-test="search-tab-input">
                <dxi-button name="clear"></dxi-button>
                <dxi-button name="search"
                            location="after"
                            [options]="{
                                disabled: isLoading || !searchService.shouldPerformSearch(button.value, searchOptions.labelIds),
                                text: 'Search',
                                stylingMode: 'contained',
                                type: 'default',
                                onClick: onSearchClicked
                            }"></dxi-button>
            </dx-text-box>
        </div>

        <div class="form-group">
            <div class="d-flex">
                <label class="d-flex flex-grow-1">Labels</label>
            </div>
            <adapt-select-label [allowLabelCreation]="false"
                                [labels]="labels"
                                (labelsChange)="onLabelsChanged($event)"></adapt-select-label>
        </div>

        <div class="form-group">
            <div class="d-flex">
                <label class="d-flex flex-grow-1">
                    Filter by
                </label>
                <div>
                    <button class="btn btn-blank me-2"
                            (click)="onSelectAllClicked()">Select All
                    </button>
                    <button class="btn btn-blank"
                            (click)="onClearClicked()">Clear
                    </button>
                </div>
            </div>
            <div *ngFor="let item of searchGroupMapping"
                 [ngClass]="{active: hasType(searchOptions.types, item.value)}"
                 (click)="searchService.toggleType(item.value)"
                 class="search-type">
                <i [class]="item.icon"></i> {{ item.name }}
                <div class="checkbox">
                    <i [class]="hasType(searchOptions.types, item.value) ? 'fal fa-check-square' : 'fal fa-square'"></i>
                </div>
            </div>
        </div>

        <h4 (click)="accordionOpen = !accordionOpen"
            class="d-flex"
            data-test="search-advanced">
            Advanced Options
            <button adaptButtonIcon="fal fa-fw {{accordionOpen ? 'fa-chevron-up': 'fa-chevron-down'}}"
                    class="ms-auto btn btn-blank align-self-center"></button>
        </h4>

        <div id="advancedOptions"
             class="form-group mt-1"
             [collapse]="!accordionOpen"
             [isAnimated]="true">
            <dx-check-box name="active-only"
                          id="active-only"
                          [ngModel]="!searchOptions.activeOnly"
                          (ngModelChange)="searchService.setSearchOptions({activeOnly: !$event})"
                          text="Show inactive results"
                          data-test="search-inactive"></dx-check-box>
            <dx-tooltip target="#active-only"
                        class="dx-tooltip-element"
                        [showEvent]="{name: 'dxhoverstart', delay: 500}"
                        hideEvent="dxhoverend"
                        [maxWidth]="300"
                        [position]="{my: 'bottom', at: 'top', collision: {x: 'flipfit', y: 'flipfit'}}">
                <div *dxTemplate="let data of 'content'">
                    <div class="adapt-tooltip-content text-start">
                        Inactive refers to the following:
                        <ul class="mt-1 mb-0 list-content">
                            <li>Actions with status <b>Done</b> or <b>Closed</b></li>
                            <li>People, Teams and Roles that are <b>deactivated</b></li>
                            <li>Objectives that are <b>Closed</b></li>
                        </ul>
                    </div>
                </div>
            </dx-tooltip>

            <div class="mt-3">
                <label>Person</label>
                <adapt-select-person [activeOnly]="searchOptions.activeOnly"
                                     [person]="person"
                                     (personChange)="onPersonChange($event)"
                                     data-test="select-search-person"></adapt-select-person>
            </div>

            <div class="mt-3"
                 *adaptIfAlto="false">
                <label>Team</label>
                <adapt-select-team [activeOnly]="searchOptions.activeOnly"
                                   [teamId]="searchOptions.teamId"
                                   (teamChange)="onTeamChange($event)"
                                   data-test="select-search-team"></adapt-select-team>
            </div>

            <div class="mt-3">
                <label>Updated Within</label>
                <dx-select-box [items]="DurationOptions"
                               [value]="searchOptions.updatedSince?.slug"
                               (valueChange)="onUpdatedWithinChange($event)"
                               displayExpr="text"
                               valueExpr="slug"
                               placeholder="Select timespan..."
                               [showClearButton]="true"
                               data-test="select-search-timespan"></dx-select-box>
            </div>
        </div>
    </div>
</div>

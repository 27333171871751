<ng-container *adaptLoading="!isInitialised">
    <adapt-collapsible-dashboard-element [name]="name"
                                         [isCollapsible]="false">
        <div class="d-flex flex-column"
             *ngIf="objectives?.length; else noObjective">
            <adapt-display-tree-objective *ngFor="let objective of objectives; let last = last"
                                          class="tree-node-width"
                                          [ngClass]="{'mb-2': !last}"
                                          [objective]="objective"
                                          [isCompactView]="true"></adapt-display-tree-objective>
        </div>
        <ng-template #noObjective>There are no {{name?.toLowerCase()}} defined.</ng-template>
    </adapt-collapsible-dashboard-element>
</ng-container>

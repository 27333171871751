import { Component, Injector, OnInit } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { PersonDetailNames } from "@common/ADAPT.Common.Model/person/person-detail";
import { UserService } from "@common/user/user.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { PersonFlagService } from "@org-common/lib/person/person-flag.service";
import { OrganisationDiagnosticAuthService } from "@org-common/lib/survey/organisation-diagnostic/organisation-diagnostic-auth.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { StartOnboardingSearchParam, WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { lastValueFrom, startWith, switchMap } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { AltoShellTour } from "../../tours/alto-shell-tour";
import { onboardingWorkflow } from "../../workflows/onboarding/onboarding-workflow";
import { WorkflowIdentifier } from "../../workflows/workflow-identifier.enum";
import { Workflows } from "../../workflows/workflows";

@Component({
    selector: "adapt-personal-dashboard-page",
    templateUrl: "./personal-dashboard-page.component.html",
    styleUrls: ["./personal-dashboard-page.component.scss"],
})
export class PersonalDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly EditOrganisationDiagnostic = OrganisationDiagnosticAuthService.EditOrganisationDiagnostic;
    public readonly CanReadMeetings = CommonTeamsAuthService.ViewAnyTeamMeeting;
    public readonly ReadObjectives = ObjectivesAuthService.ReadObjectives;
    public readonly CanReadStewardship = KanbanAuthService.ViewAnyBoard;

    public person$ = this.userService.currentPerson$;
    public team?: Team;

    public emailConfirmed = false;
    public tourDismissed = false;

    constructor(
        private userService: UserService,
        private workflowService: WorkflowService,
        personFlagService: PersonFlagService,
        injector: Injector,
    ) {
        super(injector);

        this.person$.pipe(
            tap((person) => this.emailConfirmed = !!person?.methodologyUser?.emailConfirmed),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.person$.pipe(
            filter((person) => !!person),
            switchMap((person) => personFlagService.isFlagEnabled$(person!.personId, PersonDetailNames.AltoShellTourDismissed)),
            this.takeUntilDestroyed(),
        ).subscribe((tourDismissed) => this.tourDismissed = tourDismissed);
    }

    public async ngOnInit() {
        this.navigationEnd.pipe(
            startWith(undefined),
            switchMap(() => this.workflowService.continueWorkflowFromSearchParam()),
            this.takeUntilDestroyed(),
        ).subscribe(async () => {
            const workflowStatus = await lastValueFrom(this.workflowService.getLatestStatusForWorkflow(onboardingWorkflow));
            const runOnboarding = this.getSearchParameterBoolValue(StartOnboardingSearchParam);
            if (runOnboarding || workflowStatus && workflowStatus.status === WorkflowStatusEnum.Incomplete) {
                this.runOnboardingWorkflow();
            }
        });

        this.notifyActivated();
    }

    private runOnboardingWorkflow() {
        this.workflowService.executeJourney(Workflows[WorkflowIdentifier.WelcomeOnboarding], false).pipe(
            switchMap(() => this.deleteSearchParameter(StartOnboardingSearchParam)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public runTour() {
        this.guidedTourService.run(AltoShellTour);
    }
}

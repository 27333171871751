<adapt-dialog [maxWidth]="dialogWidth"
              [showHelpButton]="false"
              [expandable]="hasRichText">
    <h3 adapt-dialog-title>{{data.title}}
        <i *ngIf="data.tooltip"
           class="fal fa-info-circle"
           adaptTooltip="{{data.tooltip}}"></i>
    </h3>
    <div adapt-dialog-content>
        <div [ngClass]="{'d-flex flex-column flex-lg-row gap-3': !!data.articleId}">
            <div *ngIf="data.articleId"
                 class="guidance-column"
                 [ngClass]="{'guidance-column': !!data.articleId}">
                <adapt-styled-implementation-kit [articleId]="data.articleId"
                                                 contentStyle="embedded"></adapt-styled-implementation-kit>
            </div>

            <div [ngClass]="{'content-column': !!data.articleId}">
                <div *ngFor="let element of data.entities"
                     class="pb-3">
                    <label *ngIf="element.label">{{element.label}}</label>
                    <adapt-html-editor *ngIf="isRichTextEdit(element)"
                                       [forceSize]="dialogWidth <= 650 || !!data.articleId ? 'compact' : 'standard'"
                                       [minHeight]="150"
                                       [(contents)]="element.entity[element.fieldName]"
                                       #focus></adapt-html-editor>
                    <dx-text-box *ngIf="isTextEdit(element)"
                                 [(value)]="element.entity[element.fieldName]"
                                 [maxLength]="element.maxLength ? element.maxLength : 0"
                                 #focus>
                        <dx-validator>
                            <dxi-validation-rule *ngFor="let customValidator of element.customValidators"
                                                 type="custom"
                                                 [validationCallback]="customValidator.validator"
                                                 [message]="customValidator.message"></dxi-validation-rule>
                            <dxi-validation-rule *ngIf="element.usePropertyValidator"
                                                 [adaptEntity]="element.entity"
                                                 [adaptEntityProperty]="element.fieldName"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                    <dx-text-area *ngIf="isTextAreaEdit(element)"
                                  [(value)]="element.entity[element.fieldName]"
                                  [maxLength]="element.maxLength ? element.maxLength : 0"
                                  [height]="100">
                        <dx-validator>
                            <dxi-validation-rule *ngFor="let customValidator of element.customValidators"
                                                 type="custom"
                                                 [validationCallback]="customValidator.validator"
                                                 [message]="customValidator.message"></dxi-validation-rule>
                            <dxi-validation-rule *ngIf="element.usePropertyValidator"
                                                 [adaptEntity]="element.entity"
                                                 [adaptEntityProperty]="element.fieldName"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-area>
                    <adapt-select-country *ngIf="isCountryEdit(element)"
                                          [(country)]="element.entity[element.fieldName]"></adapt-select-country>
                    <dx-date-box *ngIf="isDateEdit(element)"
                                 type="date"
                                 [(value)]="element.entity[element.fieldName]">
                        <dx-validator>
                            <dxi-validation-rule *ngFor="let customValidator of element.customValidators"
                                                 type="custom"
                                                 [validationCallback]="customValidator.validator"
                                                 [message]="customValidator.message"></dxi-validation-rule>
                            <dxi-validation-rule *ngIf="element.usePropertyValidator"
                                                 [adaptEntity]="element.entity"
                                                 [adaptEntityProperty]="element.fieldName"></dxi-validation-rule>
                        </dx-validator>
                    </dx-date-box>
                </div>
            </div>
        </div>

        <div role="alert"
             class="alert alert-danger mt-3"
             *ngIf="saveError">
            {{saveError}}
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveBlockingClick]="saveAndClose"
                                   [saveIsDisabled]="hasValidationErrors"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

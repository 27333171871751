<adapt-toolbar-title>
    <h3>
        <i class="fal fa-fw fa-list me-2"></i> Search Results
        <ng-container *ngIf="validQuery">
            <span *ngIf="searchResults && !isLoading"
                  class="ms-1">({{resultCount}})</span>
            <span *ngIf="isLoading"
                  class="ms-1">(...)</span>
        </ng-container>
    </h3>
</adapt-toolbar-title>

<div class="m-2">
    <div class="row">
        <ng-container *ngIf="validQuery; else noSearchQuery">
            <h4 class="align-items-center d-flex">
                Showing results for <b class="ms-1"
                   *ngIf="options?.keyword">"{{options!.keyword}}".</b>
                <adapt-display-labels *ngIf="labels.length > 0"
                                      class="ms-2"
                                      [labels]="labels"
                                      [linkDisabled]="true"></adapt-display-labels>
            </h4>

            <div class="search-options mb-3">
                <span *ngIf="!options?.activeOnly">Showing inactive results. </span>
                <span *ngIf="options?.personId as personId">Relating to
                    <adapt-link-person [personId]="personId"></adapt-link-person>. </span>
                <span *ngIf="options?.teamId as teamId">Within team
                    <adapt-link-team [teamId]="teamId"
                                     [showLabels]="false"></adapt-link-team>. </span>
                <span *ngIf="options?.updatedSince as since">Updated within {{since.text}}.</span>

                <div *ngIf="slowLoadingIndicator"
                     class="alert alert-info my-2 py-1">
                    Search is taking a while to return results. Please wait...
                </div>
            </div>

            <div class="col"
                 [ngClass]="{'col-lg-8': isLoading && showImplementationKitResults}">
                <div *ngIf="searchError$ | async as error"
                     class="alert alert-danger mt-0 mb-4">
                    {{error}}
                </div>

                <div *ngIf="searchResults">
                    <div *ngIf="resultCount === 0 && !isLoading"
                         class="alert alert-info">
                        No results found.
                    </div>

                    <div *ngFor="let category of groups"
                         class="mb-4">
                        <h4 class="mb-2 text-dark">
                            <i [class]="category.icon + ' me-1'"></i> {{category.name}}
                        </h4>

                        <div *ngFor="let error of getErrors(category.value)"
                             class="alert alert-danger">{{error}}</div>

                        <div *ngFor="let type of getTypes(category)">
                            <adapt-render-component [component]="searchElements!.component"
                                                    [componentInputs]="{ type, searchResults }">
                            </adapt-render-component>
                        </div>
                    </div>
                    <div *adaptLoading="isLoading"></div>
                </div>
            </div>

            <div *ngIf="showImplementationKitResults"
                 class="col-12"
                 [ngClass]="{'col-lg-4': groups.length > 0 || isLoading}">
                <div *ngIf="getGroup(searchType.ImplementationKit) as group">
                    <h4 class="mb-2"><i [class]="group.icon + ' me-1'"></i> {{group.name}}</h4>

                    <div *ngFor="let error of getErrors(group.value)"
                         class="alert alert-danger">{{error}}</div>

                    <div class="implementation-kit-container p-3">
                        <div *adaptLoading="isLoading">
                            <adapt-search-row-implementation-kit *ngFor="let result of searchResults?.ImplementationKit"
                                                                 [result]="result">
                            </adapt-search-row-implementation-kit>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div *adaptLoading="isLoading && !searchResults"></div>
    </div>

    <ng-template #noSearchQuery>
        <h4 class="mt-3 mb-4">No search keyword or labels provided.</h4>
    </ng-template>
</div>

<adapt-feature-guidance [configItem]="configItem">
    <p>Team Assessment has been designed to assess your teams current level of performance in order to identify opportunities in improve the
        team's effectiveness</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem"
                             [team]="team"
                             (featureChange)="currentStatus = $event"></adapt-feature-configuration>

<adapt-display-impact-surveys [hasChanges]="configurationService.hasChanges()"
                              [currentFeatureStatus]="currentStatus"
                              [team]="team"
                              [surveyType]="surveyType"></adapt-display-impact-surveys>

<div class="new-comment">
    <adapt-person-image class="item-comment-person"
                        [person]="commentingPerson"></adapt-person-image>
    <div class="new-comment-text">
        <adapt-html-editor [contents]="newComment"
                           (contentsChange)="newCommentChange.emit($event)"
                           forceSize="compact"
                           placeholderText="Add a comment"
                           (isValidChange)="showSaveButton = $event"></adapt-html-editor>
        <button *ngIf="allowSave"
                adaptButtonIcon="save"
                class="btn btn-primary btn-small btn-save-comment"
                [hidden]="!showSaveButton"
                [adaptBlockingClick]="save">Save Comment</button>
    </div>
</div>

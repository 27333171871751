import { DefaultBackTourButton, DefaultFinishButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";

export function getHealthCheckPageTour(withContinue: boolean = false) {
    const healthCheckTour: GuidedTour = [{
        title: "Survey summary graph",
        text: "<p>This graph shows you each of the seven categories and the results for each.</p><p>You will notice that each of the categories correspond to an outcome of following the adapt way.</p>",
        attachTo: {
            element: "dx-chart.chart-dimension",
            on: "top",
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Highlight a category",
        text: "<p>Each of these categories are clickable.</p><p><b>Click on it now</b> to focus on the category result.</p>",
        attachTo: {
            element: "dx-chart svg g rect",
            on: "right",
        },
        advanceOn: {
            selector: "dx-chart svg g rect",
            event: "click",
        },
    }, {
        title: "Category results",
        text: "<p>This shows the average score of the category and the comments from our business coach.</p>",
        waitForAndAttachToElementSelector: `[data-tour="category-summary-average"]`,
        attachTo: {
            on: "right",
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Highest scores in category",
        text: "<p>These are the best scoring questions for this category.</p>",
        attachTo: {
            element: `[data-tour="category-summary-doing-well"]`,
            on: "top",
        },
        buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
    }, {
        title: "Lowest scores in category",
        text: "<p>These are the lowest scoring questions for this category.</p>",
        attachTo: {
            element: `[data-tour="category-summary-need-improvement"]`,
            on: "left",
        },
        buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
    }, {
        title: "Return to organisation highlights",
        text: "<p><b>Click on this button</b> here to return to the organisation highlights.</p>",
        attachTo: {
            element: `[data-tour="clear-category-summary"]`,
            on: "bottom",
        },
        advanceOn: {
            selector: `[data-tour="clear-category-summary"]`,
            event: "click",
        },
        buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
    }, {
        title: "Detailed report",
        text: "<p>Further details for the survey can be accessed here.</p>",
        waitForAndAttachToElementSelector: "button",
        elementSelectorTextContent: "Analyse results",
        canClickTarget: false,
        attachTo: {
            on: "left",
        },
        buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
    }];

    if (withContinue) {
        healthCheckTour.push({
            title: "Continue",
            text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b></p>`,
            waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
            attachTo: {
                on: "right",
            },
            canClickTarget: false,
            buttons: DefaultFinishButtons,
        });
    } else {
        healthCheckTour.push({
            title: "Take the tour again",
            text: `This concludes the end of Health Check Page tour. Click this button to take this tour again`,
            waitForAndAttachToElementSelector: `[data-tour="health-check-tour"]`,
            attachTo: {
                on: "right",
            },
            canClickTarget: false,
            buttons: DefaultFinishButtons,
        });
    }

    return healthCheckTour;
}

<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Move Objective</h3>
    <div adapt-dialog-content
         *ngIf="!isDialogClosing">
        <div class="alert alert-info">
            <span *ngIf="originTeam">Team objectives can be moved between teams, or moved to become an organisation objective.</span>
            <span *ngIf="!originTeam">Organisation objectives can be only moved to another team.</span>
        </div>

        <div class="form-group">
            <label class="mb-2"
                   for="objectiveTeam">Destination Team</label>
            <adapt-select-team id="objectiveTeam"
                               [(team)]="objective.team"
                               (teamChange)="onTeamChange()"
                               [filter]="hasObjectivesEnabled"
                               [required]="true"
                               data-test="select-objective-team">
            </adapt-select-team>
        </div>
        <ng-container *ngIf="originTeam">
            <dx-check-box class="mb-3"
                          (onValueChanged)="onMakeOrgObjectiveChange()"
                          [(value)]="makeOrganisationObjective"
                          data-test="make-objective-global"
                          text="Make this an organisation objective">
            </dx-check-box>
        </ng-container>

        <ul class="mb-0 pt-3 border-top">
            <li *ngIf="objective.team">This will move the objective to the <strong>{{objective.team.name}}</strong> team.</li>
            <li *ngIf="!objective.team && originTeam && makeOrganisationObjective">This will move the objective to the
                <strong>Organisation</strong> from the
                <strong>{{originTeam.name}}</strong> team.</li>
            <li>Once the objective is moved, you will be navigated to the new objective location.</li>
        </ul>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveText="Move Objective"
                                   [saveIsDisabled]="saveIsDisabled()"
                                   (saveClick)="save()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTimeSchedulerModule } from "@common/ux/time-scheduler/time-scheduler.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { AdaptScheduleModule } from "@org-common/lib/schedule/schedule.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { DxCheckBoxModule, DxPopoverModule, DxSelectBoxModule, DxTemplateModule } from "devextreme-angular";
import { CalendarIntegrationStepComponent } from "./calendar-integration-step/calendar-integration-step.component";
import { PysWorkshopSetCadenceComponent } from "./pys-workshop/pys-workshop-set-cadence/pys-workshop-set-cadence.component";
import { ReviewCadenceStepComponent } from "./review-cadence-step/review-cadence-step.component";
import { SetCadenceCycleStepComponent } from "./set-cadence-cycle-step/set-cadence-cycle-step.component";
import { SetCadenceStepComponent } from "./set-cadence-step/set-cadence-step.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptDateModule,
        AdaptTimeSchedulerModule,
        AdaptTooltipModule,
        AdaptScheduleModule,
        DxPopoverModule,
        DxTemplateModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        AdaptMeetingsSharedModule,
        AdaptButtonModule,
        AdaptSeeMoreModule,
        AdaptValuesConstitutionModule,
        AdaptLoadingSpinnerModule,
        AdaptAuthorisationModule,
    ],
    declarations: [
        SetCadenceStepComponent,
        ReviewCadenceStepComponent,
        CalendarIntegrationStepComponent,
        PysWorkshopSetCadenceComponent,
        SetCadenceCycleStepComponent,
    ],
})
export class AdaptPlanYourStrategyWorkflowModule {
    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        CalendarIntegrationStepComponent,
        SetCadenceCycleStepComponent,
        SetCadenceStepComponent,
        ReviewCadenceStepComponent,
    ];

    // prevent being optimised by tree-shaker
    public readonly meetingAgendaItemComponents = [
        PysWorkshopSetCadenceComponent,
    ];
}

import { Route } from "@angular/router";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { Subject } from "rxjs";
import { DynamicCallback, INavigationNode, INavigationNodeCustomData, NavigationNodeType } from "./navigation-node.interface";

let nodeSecretId = 0;

export class NavigationNode implements INavigationNode {
    public static readonly ClonedProperties = [
        "id",
        "title",
        "url",
        "ordinal",
        "controller",
    ] as const;
    public static readonly Blank = new NavigationNode();

    public $id: number;
    public id?: string;
    public title: string;
    public route?: Route;
    public url?: string;
    public parent?: INavigationNode;
    public children: INavigationNode[];
    public ordinal?: number;
    public controller?: string;
    public dynamicCallback: DynamicCallback;
    public dynamicNodeCallback?: (referenceNode: INavigationNode, params: { [name: string]: any }) => PromiseLike<INavigationNode>;
    public type: NavigationNodeType;
    public customData: INavigationNodeCustomData;
    public hideIconInBreadcrumb = false;

    private _childAdded$ = new Subject<INavigationNode>();
    private _childRemoved$ = new Subject<INavigationNode>();
    private _currentOrChildSelected$ = new Subject<INavigationNode>();

    public constructor() {
        nodeSecretId++;

        this.$id = nodeSecretId;
        this.title = "";
        this.children = [];
        this.dynamicCallback = {};
        this.type = NavigationNodeType.Static;
        this.customData = {};
    }

    public get childAdded$() {
        return this._childAdded$.asObservable();
    }

    public get childRemoved$() {
        return this._childRemoved$.asObservable();
    }

    // this will emit if current or any of the child node is selected -> this is used by accordion to expand selected branch
    public get currentOrChildSelected$() {
        return this._currentOrChildSelected$.asObservable();
    }

    public get iconClass() {
        return this.customData.iconClass;
    }

    public get iconPositionRight() {
        return this.customData.iconPositionRight ?? false;
    }

    public set iconPositionRight(value: boolean) {
        this.customData.iconPositionRight = value;
    }

    public setCurrentSelection() {
        this._currentOrChildSelected$.next(this);
        this.parent?.setCurrentSelection();
    }

    public setHideIconInBreadcrumb(hide: boolean) {
        this.hideIconInBreadcrumb = hide;
    }

    /**
     * Builds a dynamic version of this node using the functions defined in the
     * dynamicCallback property.
     * @param namedParams parameters in the route
     * @returns The dynamic node, with no relationships filled in.
     */
    @Autobind
    public async promiseToBuildDynamicNode(namedParams: {}) {
        const self = this;

        const dynamicNode = this.clone();
        dynamicNode.type = NavigationNodeType.Dynamic;

        const dynamicPromises = Object.keys(this.dynamicCallback)
            .map(promiseToSetDynamicValue);
        await Promise.all(dynamicPromises);

        return dynamicNode;

        async function promiseToSetDynamicValue<K extends keyof INavigationNode>(dynamicProperty: K) {
            const dynamicCallback = self.dynamicCallback[dynamicProperty]!;
            const dynamicValue = dynamicCallback(self, namedParams) as (INavigationNode[K] | PromiseLike<INavigationNode[K]>);

            dynamicNode[dynamicProperty] = await Promise.resolve(dynamicValue);
        }
    }

    /**
     * Copies the contents of a node, resetting all references to other nodes to preserve
     * the original relationships.
     * @param {Node} nodeToCopy The node to copy.
     * @returns {Node} The copied node.
     */
    @Autobind
    public clone(): INavigationNode {
        const newNode = new NavigationNode();

        for (const property of NavigationNode.ClonedProperties) {
            (newNode as any)[property] = this[property];
        }

        // Retain our custom data as well
        newNode.customData = Object.assign({}, this.customData);

        return newNode;
    }

    public setParent(parent: INavigationNode) {
        parent.addChild(this);
    }

    public addChild(child: INavigationNode) {
        child.parent = this;

        if (child.type === NavigationNodeType.Static) {
            this.children.push(child);
        }

        this._childAdded$.next(child.parent);
    }

    public removeChild(child: INavigationNode) {
        const childIndex = this.children.indexOf(child);

        const parent = child.parent;
        if (childIndex >= 0) {
            this.children.splice(childIndex, 1);
            delete child.parent;
        }

        this._childRemoved$.next(parent!);
    }
}

/* eslint-disable max-classes-per-file */
import { Component, Inject, Injector, ViewEncapsulation } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { FeaturesService } from "../../features/features.service";

export interface IEditGuidingPhilosophyDialogData {
    guidingPhilosophy: GuidingPhilosophy;
    organisationPurpose: Purpose;
    valueStreamPurposes: Purpose[];
}

interface ITabItem {
    readonly title: string;
    content?: string;
}

@Component({
    selector: "adapt-edit-guiding-philosophy-dialog",
    templateUrl: "./edit-guiding-philosophy-dialog.component.html",
    styleUrls: ["./edit-guiding-philosophy-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
@PersistableDialog("EditGuidingPhilosophyDialog")
export class EditGuidingPhilosophyDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IEditGuidingPhilosophyDialogData> {
    public readonly dialogName = "EditGuidingPhilosophy";

    public guidingPhilosophyTabs: ITabItem[];

    public constructor(
        injector: Injector,
        featuresService: FeaturesService,
        @Inject(ADAPT_DIALOG_DATA) private data: IEditGuidingPhilosophyDialogData,
    ) {
        super(injector);
        this.autoResolveData = data;

        const bullseyeTab = new GuidingPhilosophyTabItem("Bullseye", data.guidingPhilosophy, "bullseye");
        this.guidingPhilosophyTabs = [
            new PurposeTabItem(data.organisationPurpose),
            new GuidingPhilosophyTabItem("Organisation Vision", data.guidingPhilosophy, "vision"),
            // only show bullseye tab if the bullseye feature is not enabled
            ...(featuresService.isFeatureActive(FeatureName.Bullseye) ? [] : [bullseyeTab]),
            new GuidingPhilosophyTabItem("Founding Story", data.guidingPhilosophy, "context"),
            ...data.valueStreamPurposes
                .sort((a, b) => a.valueStream.ordinal - b.valueStream.ordinal)
                .map((i) => new PurposeTabItem(i)),
        ];
    }

    public get allUnmodified() {
        return this.entitiesToConfirm.every((i) => i.entityAspect.entityState.isUnchanged());
    }

    public get entitiesToConfirm() {
        return [
            this.data.guidingPhilosophy,
            this.data.organisationPurpose,
            ...this.data.valueStreamPurposes];
    }

    @Autobind
    public saveAndClose() {
        this.data.guidingPhilosophy.lastUpdated = new Date();
        return super.saveAndClose();
    }
}

class GuidingPhilosophyTabItem implements ITabItem {
    public constructor(
        public readonly title: string,
        private gp: GuidingPhilosophy,
        private property: "vision" | "context" | "bullseye",
    ) { }

    public get content() {
        return this.gp[this.property];
    }

    public set content(value: string | undefined) {
        this.gp[this.property] = value;
    }
}

class PurposeTabItem implements ITabItem {
    public readonly title: string;
    public constructor(private p: Purpose) {
        this.title = p.valueStreamId
            ? `${p.valueStream.name} Purpose`
            : `Organisation Purpose`;
    }

    public get content() {
        return this.p.content;
    }

    public set content(value: string | undefined) {
        this.p.content = value;
    }
}

import { Component, Inject, OnInit } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveLink } from "@common/ADAPT.Common.Model/organisation/objective-link";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { dxMenuItem } from "devextreme/ui/menu";
import { dxTreeViewItem, ItemRenderedEvent } from "devextreme/ui/tree_view";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { catchError, groupBy, mapTo, mergeAll, mergeMap, reduce, switchMap, tap, toArray } from "rxjs/operators";
import { ObjectivesService } from "../objectives.service";

interface IObjectiveStatusFilter {
    includeOnTrack: boolean;
    includeSlow: boolean;
    includeStuck: boolean;
    includeClosed: boolean;
}

interface IStatusMenuItem extends dxMenuItem {
    statusFilter?: IObjectiveStatusFilter;
    valueKey?: keyof IObjectiveStatusFilter;
    items?: IStatusMenuItem[];
}

interface IDataTreeViewItem extends dxTreeViewItem {
    teamId?: number;
    objectiveId?: number;
    objective?: Objective;
    items: IDataTreeViewItem[];
}

@Component({
    selector: "adapt-add-objective-link-dialog",
    templateUrl: "./add-objective-link-dialog.component.html",
    styleUrls: ["./add-objective-link-dialog.component.scss"],
})
export class AddObjectiveLinkDialogComponent extends BaseDialogComponent<Objective> implements OnInit {
    public readonly dialogName = "AddObjectiveLink";

    public objectivesFilter: IObjectiveStatusFilter = {
        includeOnTrack: true,
        includeSlow: true,
        includeStuck: true,
        includeClosed: false,
    };
    public statusFilterItems: IStatusMenuItem[] = [
        {
            icon: "menu",
            items: [
                {
                    text: ObjectiveStatusMetadata.OnTrack.name,
                    selected: this.objectivesFilter.includeOnTrack,
                    statusFilter: this.objectivesFilter,
                    valueKey: "includeOnTrack",
                    template: "selectItem",
                },
                {
                    text: ObjectiveStatusMetadata.Slow.name,
                    selected: this.objectivesFilter.includeSlow,
                    statusFilter: this.objectivesFilter,
                    valueKey: "includeSlow",
                    template: "selectItem",
                },
                {
                    text: ObjectiveStatusMetadata.Stuck.name,
                    selected: this.objectivesFilter.includeStuck,
                    statusFilter: this.objectivesFilter,
                    valueKey: "includeStuck",
                    template: "selectItem",
                },
                {
                    text: ObjectiveStatusMetadata.Closed.name,
                    selected: this.objectivesFilter.includeClosed,
                    statusFilter: this.objectivesFilter,
                    valueKey: "includeClosed",
                    template: "selectItem",
                },
            ],
        },
    ];
    public treeData$: Observable<IDataTreeViewItem[]> = of([]);
    public selectedObjectives: Objective[] = [];

    public constructor(
        private objectivesService: ObjectivesService,
        private teamsService: CommonTeamsService,
        @Inject(ADAPT_DIALOG_DATA) public readonly objective: Objective,
    ) {
        super();
    }

    public ngOnInit() {
        if (!this.objective) {
            throw new AdaptError("Cannot add objective link without an objective");
        }

        this.objectivesService.getObjectiveLinksForObjective(this.objective)
            .subscribe(() => this.updateOtherObjectives());
    }

    @Autobind
    public save() {
        const createOps = this.selectedObjectives.map((destObjective) => {
            const initData: Partial<ObjectiveLink> = {
                objective1: this.objective,
                objective2: destObjective,
            };
            return this.objectivesService.createObjectiveLink(initData);
        });

        return forkJoin(createOps).pipe(
            switchMap((newObjectiveLinks) => this.objectivesService.saveEntities(newObjectiveLinks)),
            tap(() => this.resolve(this.objective)),
            catchError((err: AdaptError) => {
                this.setErrorMessage(err.message);
                return throwError(() => err);
            }),
        );
    }

    public updateOtherObjectives() {
        const allOtherObjectives$ = this.objectivesService.getObjectivesByPredicate(this.objectivesPredicate);
        this.treeData$ = allOtherObjectives$.pipe(
            switchMap((objectives) => // prime team
                forkJoin(objectives.map((objective) => objective.teamId ? this.teamsService.getTeamById(objective.teamId) : of(undefined)))
                    .pipe(mapTo(objectives)),
            ),
            mergeAll(),
            groupBy((objective) => objective.team, (objective) => objective),
            mergeMap((group$) => group$.pipe(
                reduce<Objective, IDataTreeViewItem>(
                    (acc, objective) => {
                        const objectiveNode: IDataTreeViewItem = {
                            objectiveId: objective.objectiveId,
                            objective,
                            text: objective.title,
                            disabled: this.alreadyLinked(objective),
                            template: "objectiveTemplate",
                            items: [],
                        };
                        if (this.alreadyLinked(objective)) {
                            objectiveNode.disabled = true;
                            objectiveNode.selected = true;
                        }

                        acc.items.push(objectiveNode);
                        return acc;
                    },
                    {
                        teamId: group$.key ? group$.key.teamId : -1,
                        text: group$.key ? group$.key.name : "",
                        expanded: true,
                        template: "teamTemplate",
                        items: [] as IDataTreeViewItem[],
                    },
                ),
            )),
            toArray(),
        );
    }

    public onTreeItemRendered(e: ItemRenderedEvent<IDataTreeViewItem>) {
        if (e.itemData) {
            const itemData = e.itemData as IDataTreeViewItem;
            if (itemData.teamId && e.itemElement) {
                const checkboxElement = (e.itemElement as any as JQuery<HTMLElement>).parent().find(".dx-checkbox") as any;
                checkboxElement.dxCheckBox("instance").option("visible", false);
            }
        }
    }

    public updateScrollbarOption(e: any) {
        // This is to always show the scrollbar only if there is something to scroll
        // from https://www.devexpress.com/Support/Center/Question/Details/T478191/dxtreeview-how-to-always-show-scrollbar
        e.element.find(".dx-scrollable").dxScrollable("instance").option("showScrollbar", "always");
    }

    public onObjectiveSelected(e: any) {
        const nodeData = e.itemData as IDataTreeViewItem;
        if (nodeData.selected) {
            if (nodeData.objective && this.selectedObjectives.indexOf(nodeData.objective) < 0) {
                this.selectedObjectives.push(nodeData.objective);
            }
        } else if (nodeData.objective) {
            const removeIndex = this.selectedObjectives.indexOf(nodeData.objective);
            if (removeIndex >= 0) {
                this.selectedObjectives.splice(removeIndex, 1);
            }
        }
    }

    public showObjectiveStatus(objective: Objective) {
        return objective.status === ObjectiveStatus.Closed;
    }

    private alreadyLinked(obj2: Objective) {
        return !!this.objective.objectiveLinks.find((l) => l.objective2Id === obj2.objectiveId);
    }

    private get objectivesPredicate() {
        const predicate = new MethodologyPredicate<Objective>("objectiveId", "!=", this.objective.objectiveId);
        if (this.objectivesFilter.includeOnTrack || this.objectivesFilter.includeClosed) {
            const statusPredicate = new MethodologyPredicate<Objective>();
            if (this.objectivesFilter.includeOnTrack) {
                statusPredicate.or(new MethodologyPredicate<Objective>("status", "==", ObjectiveStatus.OnTrack));
            }

            if (this.objectivesFilter.includeSlow) {
                statusPredicate.or(new MethodologyPredicate<Objective>("status", "==", ObjectiveStatus.Slow));
            }

            if (this.objectivesFilter.includeStuck) {
                statusPredicate.or(new MethodologyPredicate<Objective>("status", "==", ObjectiveStatus.Stuck));
            }

            if (this.objectivesFilter.includeClosed) {
                statusPredicate.or(new MethodologyPredicate<Objective>("status", "==", ObjectiveStatus.Closed));
            }

            predicate.and(statusPredicate);
        } // if no tick, will show all. What's the point of not showing anything!?

        return predicate;
    }
}

<div *adaptLoading="!goal"
     class="d-flex flex-column">

    <div class="d-flex flex-column">
        <h3 class="d-flex">{{goal?.name}}
            <ng-container [ngTemplateOutlet]="editButton"
                          [ngTemplateOutletContext]="{step: 0}"></ng-container>
        </h3>
        <div class="d-flex mb-3 align-items-stretch">
            <div class="d-flex flex-column">
                <label>Zone</label>
                <adapt-zone-label [zone]="goal!.zone"
                                  class="name-location-value"></adapt-zone-label>
            </div>
            <div class="d-flex flex-column flex-grow-1 ms-3"
                 [ngClass]="{'me-3': !isNew}"
                 *ngIf="goal?.theme">
                <label>Theme</label>
                <dx-text-box [value]="goal?.theme?.name"
                             [readOnly]="true"
                             class="name-location-value"></dx-text-box>
            </div>
        </div>
        <div *ngIf="!isNew">
            <label>Status</label>
            <adapt-goal-status [status]="goal?.status"
                               class="name-location-value"></adapt-goal-status>
        </div>
    </div>

    <div class="mt-3 d-flex flex-column">
        <h3 class="d-flex">Measurements
            <ng-container [ngTemplateOutlet]="editButton"
                          [ngTemplateOutletContext]="{step: 1}"></ng-container>
        </h3>

        <adapt-goal-measurements-graph *ngIf="goal!.measurements.length > 0; else noMeasurementsTemplate"
                                       #measurementsGraph
                                       [goal]="goal"
                                       [isEditing]="false"></adapt-goal-measurements-graph>
        <ng-template #noMeasurementsTemplate>No measurements defined for the goal</ng-template>
    </div>

    <div class="mt-3 d-flex flex-column"
         *ngIf="goal?.description">
        <h3 class="d-flex">Goal Description
            <ng-container [ngTemplateOutlet]="editButton"
                          [ngTemplateOutletContext]="{step: 2}"></ng-container>
        </h3>
        <div [froalaView]="goal?.description"
             class="field-value"></div>
    </div>

</div>

<ng-template #editButton
             let-step="step">
    <button adaptTooltip="Edit"
            (click)="navigateToStep(step)"
            role="button"
            class="btn btn-blank ms-auto"><i class="fal fa-fw fa-edit"></i></button>
</ng-template>

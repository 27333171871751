import { Injectable } from "@angular/core";
import { BoardBreezeModel } from "@common/ADAPT.Common.Model/organisation/board";
import { ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { DiagramBreezeModel } from "@common/ADAPT.Common.Model/organisation/diagram";
import { FeatureStatusBreezeModel } from "@common/ADAPT.Common.Model/organisation/feature-status";
import { GuidingPhilosophyBreezeModel } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { ItemBreezeModel } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemCommentBreezeModel } from "@common/ADAPT.Common.Model/organisation/item-comment";
import { ItemLinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/item-link";
import { KeyResultBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-result";
import { KeyResultValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { LayoutBreezeModel } from "@common/ADAPT.Common.Model/organisation/layout";
import { Location, LocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/location";
import { MeetingBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItemBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaItemSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item-supplementary-data";
import { MeetingAttendeeBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { MeetingItemBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-item";
import { MeetingNoteBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-note";
import { MeetingSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-supplementary-data";
import { ObjectiveBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveCommentBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective-comment";
import { ObjectiveItemLinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective-item-link";
import { ObjectiveLinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective-link";
import { OrganisationDetailBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { OutstandingSurveyResponseBreezeModel } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { ProcessMapBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStepBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepGuidanceMaterialBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step-guidance-material";
import { ProcessStepSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step-supplementary-data";
import { PurposeBreezeModel } from "@common/ADAPT.Common.Model/organisation/purpose";
import { RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleFeaturePermissionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-feature-permission";
import { RoleSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-supplementary-data";
import { SurveyBreezeModel } from "@common/ADAPT.Common.Model/organisation/survey";
import { SystemComponentBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemComponentSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-component-supplementary-data";
import { SystemDocumentBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-document";
import { SystemEntityBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemKnowledgeExpertBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-knowledge-expert";
import { SystemLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-location";
import { TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/team-location";
import { ValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/value";
import { ValueQuestionBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-question";
import { ValuesConstitutionBreezeModel } from "@common/ADAPT.Common.Model/organisation/values-constitution";
import { PersonContact, PersonContactBreezeModel } from "@common/ADAPT.Common.Model/person/person-contact";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { DateFormats } from "@common/ux/date-formats";
import moment from "moment";
import { forkJoin, Observable, of } from "rxjs";
import { EntityAutoUpdaterTemplateBuilder } from "./entity-auto-updater-template-builder";
import { EntitySignalRHub } from "./entity-signalr-hub.service";

@Injectable({
    providedIn: "root",
})
export class EntitySignalRRegistrarService {
    public constructor(private commonDataService: CommonDataService) { }

    public registerUpdaterTemplates() {
        this.registerOrganisationTemplates();
        this.registerRoleFeatureTemplates();
        this.registerSystemisationTemplates();
        this.registerValuesConstitutionTemplates();
        this.registerGuidingPhilosophyTemplates();
        this.registerObjectiveTemplates();
        this.registerKanbanTemplates();
        this.registerMeetingTemplates();
        this.registerLocationTemplates();
        this.registerTeamTemplates();
        this.registerSurveyTemplates();
    }

    private registerOrganisationTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(OrganisationDetailBreezeModel)
            .withSummaryHtml(() => `Organisation detail`)
            .fetchAddedEntityIfRelatedEntityInCache()
            .build());
    }

    private registerSurveyTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(SurveyBreezeModel)
            .withSummaryHtml((i) => `Survey <strong>${i.name}</strong>`)
            .fetchingAddedEntityWhen(() => true) // always fetch new team
            .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(OutstandingSurveyResponseBreezeModel)
            .withSummaryHtml((i) => `Outstanding survey response for survey <strong>${i.survey.name}</strong>`)
            .fetchAddedEntityIfRelatedEntityInCache()
            .build());
    }

    private registerTeamTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(TeamBreezeModel)
            .withSummaryHtml((i) => `Team <strong>${i.name}</strong>`)
            .fetchingAddedEntityWhen(() => true) // always fetch new team
            .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(TeamLocationBreezeModel)
                .withSummaryHtml((i) => i.team
                    ? `Location for team <strong>${i.team.name}</strong>`
                    : "Location for unavailable team")
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(FeatureStatusBreezeModel)
            .withSummaryHtml((i) => `Feature ${i.feature?.name} for team ${i.team?.name}`)
            .fetchingAddedEntityWhen(() => true) // when creating new team, feature status, team and roles pushed through in a single array
            // - so cannot fetchingAddIfRelatedEntityInCache the same as team Role (which is also fetch-always)
            .build());
    }

    private registerRoleFeatureTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(RoleBreezeModel)
                .withSummaryHtml((i) => `<strong>${i.label}</strong>`)
                .fetchingAddedEntityWhen(() => true)
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(RoleSupplementaryDataBreezeModel)
                .withSummaryHtml((i) => `Supplementary data for role <i>${i.role.label}</i>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(RoleConnectionBreezeModel)
                .withSummaryHtml((i) => `Connection data for role <i>${i.role.label}</i>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .primeAfterFetch((rc) => {
                    const primeObservables: Observable<unknown>[] = [];
                    if (rc.teamId && !rc.team) {
                        primeObservables.push(this.commonDataService.getById(TeamBreezeModel, rc.teamId!));
                    }

                    if (rc.connection?.connectionType === ConnectionType.Employee) {
                        // prime position through navproperty of connection
                        if (!rc.connection.positions || rc.connection.positions.length < 1) {
                            primeObservables.push(this.commonDataService.getById(ConnectionBreezeModel, rc.connectionId, true));
                        }
                    }

                    if (rc.connection?.personId && (!rc.connection?.person?.personContacts || rc.connection.person.personContacts.length < 1)) {
                        // prime person contacts - need force remote or otherwise it will just access cache and from previous getAll call
                        primeObservables.push(this.commonDataService.getByPredicate(PersonContactBreezeModel, new MethodologyPredicate<PersonContact>("personId", "==", rc.connection.personId), true));
                    }

                    if (primeObservables.length > 0) {
                        return forkJoin(primeObservables);
                    } else {
                        return of(undefined);
                    }
                })
                // user can't do anything about these changes, auto-integrate them
                .conflictsShouldBeAutomaticallyIntegrated(true)
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(RoleFeaturePermissionBreezeModel)
                .withSummaryHtml((i) => `Feature permission <i>${i.featurePermission.name}</i> for role <i>${i.role.label}</i>`)
                // no endpoint to update this data specifically
                .fetchingAddedEntityWhen(() => false)
                // user can't do anything about these changes, auto-integrate them
                .conflictsShouldBeAutomaticallyIntegrated(true)
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ConnectionBreezeModel)
                .withSummaryHtml((i) => `Connection for person <i>${i.person?.fullName}</i>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
    }

    private registerSystemisationTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(SystemEntityBreezeModel)
            .withSummaryHtml((i) => `System <strong>${i.name}</strong>`)
            .fetchingAddedEntityWhen(() => true)
            .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(SystemKnowledgeExpertBreezeModel)
            .withSummaryHtml((i) => `Knowledge expert <strong>${i.knowledgeExpert.fullName}</strong> for system <strong>${i.system.name}</strong>`)
            .fetchAddedEntityIfRelatedEntityInCache()
            .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(SystemComponentBreezeModel)
            .withSummaryHtml((i) => `<strong>${i.name}</strong> element in system <strong>${i.system.name}</strong>`)
            .fetchAddedEntityIfRelatedEntityInCache()
            .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(SystemComponentSupplementaryDataBreezeModel)
            .withSummaryHtml((i) => `Supplementary data for system element <strong>${i.systemComponent.name}</strong>`)
            .fetchAddedEntityIfRelatedEntityInCache()
            .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(DiagramBreezeModel)
            .withSummaryHtml(() => `System diagram entity`)
            .fetchAddedEntityIfRelatedEntityInCache()
            .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(SystemDocumentBreezeModel)
            .withSummaryHtml(() => `System document entity`)
            .fetchAddedEntityIfRelatedEntityInCache()
            .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(LayoutBreezeModel)
            .withSummaryHtml(() => `Layout entity`)
            .fetchingAddedEntityWhen(() => true)
            .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(EntityAutoUpdaterTemplateBuilder.forModel(SystemLocationBreezeModel)
            .withSummaryHtml((i) => `system location data for system <strong>${i.system.name}</strong>`)
            .fetchAddedEntityIfRelatedEntityInCache()
            .build());

        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ProcessMapBreezeModel)
                .withSummaryHtml((i) => `<strong>${i.name}</strong>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ProcessStepBreezeModel)
                .withSummaryHtml((i) => `<strong>${i.name}</strong>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ProcessStepSupplementaryDataBreezeModel)
                .withSummaryHtml((i) => `Supplementary data for process step <strong>${i.processStep?.name}</strong>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ProcessStepGuidanceMaterialBreezeModel)
                .withSummaryHtml((i) => `Guidance material <i>${i.guidanceMaterial?.name}</i> for process step <strong>${i.processStep?.name}</strong>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
    }

    private registerValuesConstitutionTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ValuesConstitutionBreezeModel)
                .withSummaryHtml((i) => {
                    const target = i.teamId
                        ? `${i.team.name} team`
                        : "Organisation";
                    return `${target} values`;
                })
                .fetchAddedEntityIfAnyExistingOptionalTeamEntityInCache()
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ValueBreezeModel)
                .withSummaryHtml((i) => {
                    const target = i.valuesConstitution.teamId
                        ? `${i.valuesConstitution.team.name} team`
                        : "Organisation";
                    return `${target} value: ${i.name}`;
                })
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ValueQuestionBreezeModel)
                .withSummaryHtml((i) => `Question for ${i.value.name}: ${i.question}`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
    }

    private registerGuidingPhilosophyTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(GuidingPhilosophyBreezeModel)
                .withSummaryHtml(() => `Organisation purpose and vision`)
                // The only time we want to fetch this is when it is already in the cache
                .fetchingAddedEntityWhen(() => false)
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(PurposeBreezeModel)
                .withSummaryHtml((i) => {
                    const target = i.teamId
                        ? `${i.team.name} team`
                        : "Organisation";
                    return `${target} purpose`;
                })
                .fetchAddedEntityIfAnyExistingOptionalTeamEntityInCache()
                .build());
    }

    private registerObjectiveTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ObjectiveBreezeModel)
                .withSummaryHtml((o) => `<strong>${o.typeCode}</strong> ${o.title}`)
                .fetchAddedEntityIfAnyExistingOptionalTeamEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ObjectiveCommentBreezeModel)
                .withSummaryHtml((c) => `Comment on ${c.objective.title}: <i>"${c.comment}"</i>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ObjectiveLinkBreezeModel)
                .withSummaryHtml((ol) => `Link between ${ol.objective1.title} and ${ol.objective2.title}`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ObjectiveItemLinkBreezeModel)
                .withSummaryHtml((e) => `Link between ${e.item.code} and ${e.objective.title}`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(KeyResultBreezeModel)
                .withSummaryHtml((kr) => `<strong>${kr.title}</strong>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(KeyResultValueBreezeModel)
                .withSummaryHtml((krv) => `Key Result progress update on ${moment(krv.dateTime).format(DateFormats.moment.short)}: ${krv.formattedValue}`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
    }

    private registerKanbanTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(BoardBreezeModel)
                .withSummaryHtml((e) => `Board ${e.name}`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ItemBreezeModel)
                .withSummaryHtml((i) => i.extensions.getSummaryHtml())
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ItemCommentBreezeModel)
                .withSummaryHtml((e) => `Comment on <strong>${e.item.code}</strong>: <i>"${e.comment}"</i>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(ItemLinkBreezeModel)
                .withSummaryHtml((e) => `Link between <strong>${e.primaryItem.code}</strong> and <strong>${e.secondaryItem.code}</strong>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
    }

    private registerMeetingTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(MeetingBreezeModel)
                .withSummaryHtml((i) => `<strong>${i.name}</strong>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(MeetingAttendeeBreezeModel)
                .withSummaryHtml((i) => `Meeting <i>${i.meeting.name}</i>: Attendee <i>${i.attendee.fullName}</i>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(MeetingAgendaItemBreezeModel)
                .withSummaryHtml((i) => `Meeting agenda item <i>${i.name}</i>`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(MeetingAgendaItemSupplementaryDataBreezeModel)
                // CM-5316: meetingAgendaItem was null
                // (brought up conflicting changes dialog, not sure how you can have conflicting changes if you don't have the entity...)
                .withSummaryHtml((i) => "Supplementary data for " + (i.meetingAgendaItem ? `agenda item <i>${i.meetingAgendaItem.name}</i>` : "unknown agenda item"))
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(MeetingItemBreezeModel)
                .withSummaryHtml((i) => `Meeting Item: ${i.item.extensions.getSummaryHtml()}`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(MeetingNoteBreezeModel)
                .withSummaryHtml((i) => `Meeting ${i.type}: ${i.content}`)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(MeetingSupplementaryDataBreezeModel)
                .withSummaryHtml((i) => "Supplementary data for " + (i.meeting ? `meeting <i>${i.meeting.name}</i>` : "unknown meeting"))
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());
    }

    private registerLocationTemplates() {
        EntitySignalRHub.addEntityAutoUpdaterTemplate(
            EntityAutoUpdaterTemplateBuilder.forModel(LocationBreezeModel)
                .withSummaryHtml(generateLocationSummaryHtml)
                .fetchAddedEntityIfRelatedEntityInCache()
                .build());

        function generateLocationSummaryHtml(location: Location, relatedEntities: IBreezeEntity[]) {
            // For now, we can assume that the related entity is an item, this will likely change
            // in future though as we add more entities to be synced (tasks?)
            const relatedItem = relatedEntities.find((e) => e.entityType.shortName === ItemBreezeModel.toType);

            return relatedItem
                ? `<strong>${relatedItem.code}</strong>'s location: <i>"${location.extensions.label}"</i>`
                : `Location to <i>"${location.extensions.label}"</i>`;
        }
    }
}

import { Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Input as StrategicInput, InputType } from "@common/ADAPT.Common.Model/organisation/input";
import { InputGroup } from "@common/ADAPT.Common.Model/organisation/input-group";
import { InputTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { CanvasInputTypes } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { switchMap, tap } from "rxjs";
import { StrategicInputsService } from "../strategic-inputs.service";

@Component({
    selector: "adapt-input-group",
    templateUrl: "./input-group.component.html",
})
export class InputGroupComponent extends BaseComponent implements OnChanges {
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;
    public readonly InputTypeMetadata = InputTypeMetadata;

    @Input() public inputGroup?: InputGroup;

    public inputTypes: InputType[] = [];
    public inputsOfType: Map<InputType, StrategicInput[]> = new Map();

    public constructor(
        elementRef: ElementRef,
        private inputsService: StrategicInputsService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChanged(StrategicInput).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateInputs());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.inputGroup && this.inputGroup) {
            this.inputTypes = CanvasInputTypes[this.inputGroup.canvas.type];
            this.updateInputs();
        }
    }

    @Autobind
    public editInputGroup() {
        return this.inputsService.editInputGroup(this.inputGroup!).pipe(
            this.takeUntilDestroyed(),
        );
    }

    @Autobind
    public createInputOfType(inputType: InputType) {
        return this.inputsService.createInputOfType(inputType, this.inputGroup!.canvas).pipe(
            tap((input) => input.inputGroup = this.inputGroup),
            switchMap((input) => this.inputsService.editInput(input)),
            this.takeUntilDestroyed(),
        );
    }

    private updateInputs() {
        this.inputsOfType.clear();
        for (const inputType of this.inputTypes) {
            this.inputsOfType.set(inputType, this.inputGroup!.inputs.filter((i) => i.type === inputType));
        }
    }
}

import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { ConfigureKanbanBoardsComponent } from "@org-common/lib/configuration/common/configure-kanban-boards/configure-kanban-boards.component";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";

@Component({
    selector: "adapt-configure-team-kanban",
    templateUrl: "./configure-team-kanban.component.html",
})
export class ConfigureTeamKanbanComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    @Input() public team!: Team;
    @Output() public featureChanged = new EventEmitter<void>();

    @ViewChild(ConfigureKanbanBoardsComponent) public kanbanConfigureComponent?: ConfigureKanbanBoardsComponent;

    public readonly configureTeamKanban = CommonTeamsAuthService.ConfigureTeamKanban;
    public isOrganisationMeetingFeatureEnabled = false;
    public isMeetingsFeatureEnabled = false;

    public features = {
        useStewardshipKanban: false,
        useMeetingFramework: false,
    };
    public canEditTeamStewardshipGuidance = false;

    public constructor(
        configurationService: ConfigurationService,
        private featuresService: FeaturesService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        [this.isOrganisationMeetingFeatureEnabled, this.isMeetingsFeatureEnabled] = await Promise.all([
            this.featuresService.promiseToCheckIfFeatureActive(FeatureName.StewardshipWorkMeetings),
            this.featuresService.promiseToCheckIfFeatureActive(FeatureName.StewardshipWorkMeetings, this.team),
        ]);
        this.kanbanConfigureComponent?.refresh();
        this.featureChanged.emit();
    }

    public async onMeetingsFeatureStatusValueChanged(value: boolean) {
        this.isMeetingsFeatureEnabled = value;
        await this.featuresService.promiseToSetFeatureStatus(FeatureName.StewardshipWorkMeetings, this.team, this.isMeetingsFeatureEnabled);
    }
}

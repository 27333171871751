export enum StrategicViewOption {
    Goals = "Goals",
    Themes = "Themes",
    SWTInputs = "SWTInputs",
    CAInputs = "CAInputs",
    Bullseye = "Bullseye",
    HealthCheck = "HealthCheck",
}

export enum StrategicViewIcon {
    GoalIcon = "fal fa-fw fa-bullseye-pointer",
    ThemeIcon = "fal fa-fw fa-signs-post",
    ThemeDescriptionIcon = "fal fa-fw fa-object-group",
    InputsCanvasIcon = "fal fa-fw fa-lightbulb-exclamation-on",
    BullseyeIcon = "fal fa-fw fa-crosshairs",
    CompetitorAnalysisIcon = "fal fa-fw fa-scale-balanced",
}

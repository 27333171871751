<adapt-toolbar-content alignment="center">
    <div class="d-flex">
        <ng-container *ngFor="let displayView of AllViewButtons">
            <button *ngIf="hasPermissionMap.get(displayView)"
                    [adaptButtonIcon]="views[displayView].icon"
                    (click)="expandView(displayView)">{{views[displayView].text}}</button>
        </ng-container>
    </div>
</adapt-toolbar-content>

<adapt-call-to-action *ngIf="!hasContent"
                      [showGetStartedBadge]="false">
    <adapt-call-to-action-title>Strategy on a Page</adapt-call-to-action-title>
    <p>
        As you progress through the pathways, your strategy content will appear on this page.
    </p>
    <p>Once you complete the relevant pathways, you'll be able to see the following content on this page:</p>
    <ul class="list-content">
        <li *ngFor="let name of viewNames">{{name}}</li>
    </ul>
</adapt-call-to-action>

<div class="d-flex flex-column gap-3">
    <adapt-collapsible-dashboard-element #objectivesElement
                                         *ngIf="hasPermissionMap.get(DisplayViewKey.ObjectivesView)"
                                         [class.pulsing-element]="selectedView === DisplayViewKey.ObjectivesView"
                                         [name]="views[DisplayViewKey.ObjectivesView].text"
                                         [(collapsed)]="views[DisplayViewKey.ObjectivesView].collapsed"
                                         [iconClass]="views[DisplayViewKey.ObjectivesView].icon"
                                         [url]="views[DisplayViewKey.ObjectivesView].route | async">
        <div class="split-entry">
            <!-- <adapt-monthly-actions-dashboard-element class="column-dashboard-width"></adapt-monthly-actions-dashboard-element> -->
            <adapt-org-objectives-dashboard-element name="Quarterly Objectives"
                                                    [objectiveType]="ObjectiveType.Quarterly"></adapt-org-objectives-dashboard-element>
            <adapt-org-objectives-dashboard-element name="Annual Objectives"
                                                    [objectiveType]="ObjectiveType.Annual"></adapt-org-objectives-dashboard-element>
        </div>
    </adapt-collapsible-dashboard-element>

    <adapt-collapsible-dashboard-element #strategyBoardElement
                                         *ngIf="hasPermissionMap.get(DisplayViewKey.StrategyGoalsView)"
                                         [class.pulsing-element]="selectedView === DisplayViewKey.StrategyGoalsView"
                                         [name]="views[DisplayViewKey.StrategyGoalsView].text"
                                         [iconClass]="views[DisplayViewKey.StrategyGoalsView].icon"
                                         [(collapsed)]="views[DisplayViewKey.StrategyGoalsView].collapsed"
                                         [url]="views[DisplayViewKey.StrategyGoalsView].route | async">
        <adapt-menu *ngIf="strategyBoardMenu[0].items?.length && !views[DisplayViewKey.StrategyGoalsView].collapsed"
                    adapt-collapsible-header-action-right
                    class="ms-auto me-3 my-n2"
                    [items]="strategyBoardMenu"></adapt-menu>
        <adapt-strategy-board [isEditing]="false"
                              [views]="[StrategicViewOption.Goals]"></adapt-strategy-board>
    </adapt-collapsible-dashboard-element>

    <adapt-collapsible-dashboard-element #strategicAnchorsElement
                                         *ngIf="hasPermissionMap.get(DisplayViewKey.StrategicAnchorsView)"
                                         [class.pulsing-element]="selectedView === DisplayViewKey.StrategicAnchorsView"
                                         [name]="views[DisplayViewKey.StrategicAnchorsView].text"
                                         [iconClass]="views[DisplayViewKey.StrategicAnchorsView].icon"
                                         [(collapsed)]="views[DisplayViewKey.StrategicAnchorsView].collapsed"
                                         [url]="views[DisplayViewKey.StrategicAnchorsView].route | async">
        <adapt-strategic-anchors [anchors]="anchors"
                                 [isEditing]="false"></adapt-strategic-anchors>
    </adapt-collapsible-dashboard-element>

    <div *ngIf="hasPermissionMap.get(DisplayViewKey.VisionView) || hasPermissionMap.get(DisplayViewKey.BullseyeView)"
         class="split-entry">
        <adapt-collapsible-dashboard-element #visionElement
                                             *ngIf="hasPermissionMap.get(DisplayViewKey.VisionView)"
                                             [class.pulsing-element]="selectedView === DisplayViewKey.VisionView"
                                             [name]="views[DisplayViewKey.VisionView].text"
                                             [iconClass]="views[DisplayViewKey.VisionView].icon"
                                             [(collapsed)]="views[DisplayViewKey.VisionView].collapsed"
                                             [url]="views[DisplayViewKey.VisionView].route | async">
            <div class="card inner-card">
                <div *ngIf="vision?.vision as visionContent; else noVisionTemplate"
                     class="card-body"
                     [froalaView]="visionContent"></div>
                <ng-template #noVisionTemplate>
                    <div class="card-body">No vision has been defined.</div>
                </ng-template>
            </div>
        </adapt-collapsible-dashboard-element>

        <adapt-collapsible-dashboard-element #bullseyeElement
                                             *ngIf="hasPermissionMap.get(DisplayViewKey.BullseyeView)"
                                             [class.pulsing-element]="selectedView === DisplayViewKey.BullseyeView"
                                             [name]="views[DisplayViewKey.BullseyeView].text"
                                             [iconClass]="views[DisplayViewKey.BullseyeView].icon"
                                             [(collapsed)]="views[DisplayViewKey.BullseyeView].collapsed"
                                             [url]="views[DisplayViewKey.BullseyeView].route | async">
            <div class="card inner-card">
                <div *ngIf="bullseye?.statement as bullseyeContent; else noBullseyeTemplate"
                     class="card-body"
                     [froalaView]="bullseyeContent"></div>
                <ng-template #noBullseyeTemplate>
                    <div class="card-body">No purpose has been defined.</div>
                </ng-template>
            </div>
        </adapt-collapsible-dashboard-element>
    </div>


    <div *ngIf="hasPermissionMap.get(DisplayViewKey.PurposeView) || hasPermissionMap.get(DisplayViewKey.ValuesView)"
         class="split-entry">
        <adapt-collapsible-dashboard-element #purposeElement
                                             *ngIf="hasPermissionMap.get(DisplayViewKey.PurposeView)"
                                             [class.pulsing-element]="selectedView === DisplayViewKey.PurposeView"
                                             [name]="views[DisplayViewKey.PurposeView].text"
                                             [iconClass]="views[DisplayViewKey.PurposeView].icon"
                                             [(collapsed)]="views[DisplayViewKey.PurposeView].collapsed"
                                             [url]="views[DisplayViewKey.PurposeView].route | async">
            <div class="card inner-card">
                <div *ngIf="purpose?.content as purposeContent; else noPurposeTemplate"
                     class="card-body"
                     [froalaView]="purposeContent"></div>
                <ng-template #noPurposeTemplate>
                    <div class="card-body">No purpose has been defined.</div>
                </ng-template>
            </div>
        </adapt-collapsible-dashboard-element>

        <adapt-collapsible-dashboard-element #valuesElement
                                             *ngIf="hasPermissionMap.get(DisplayViewKey.ValuesView)"
                                             [class.pulsing-element]="selectedView === DisplayViewKey.ValuesView"
                                             [name]="views[DisplayViewKey.ValuesView].text"
                                             [iconClass]="views[DisplayViewKey.ValuesView].icon"
                                             [(collapsed)]="views[DisplayViewKey.ValuesView].collapsed"
                                             [url]="views[DisplayViewKey.ValuesView].route | async">
            <adapt-values-constitution-list-view></adapt-values-constitution-list-view>
        </adapt-collapsible-dashboard-element>
    </div>
</div>

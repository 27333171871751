/* eslint-disable max-classes-per-file */
import { Injectable } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ObjectiveFilter } from "./objective-filter";

@Injectable()
export class ObjectiveFilterService {
    public filter = new DisplayOption(new ObjectiveFilter());
    public focusType = new DisplayOption<ObjectiveType | undefined>(undefined);

    public reset() {
        this.filter.resetToDefault();
        this.focusType.resetToDefault();
    }

    public isFocussedObjective(objective$: Observable<Objective>) {
        const focusType$ = this.focusType.option$;
        return combineLatest([objective$, focusType$]).pipe(
            map(([objective, focusType]) => {
                return !focusType || focusType === objective.type;
            }),
        );
    }

    public get isDefault() {
        return this.filter.defaultValue.equals(this.filter.currentValue)
            && this.focusType.defaultValue === this.focusType.currentValue;
    }
}

class DisplayOption<T> {
    private _optionSubject$: BehaviorSubject<T>;

    public constructor(
        public readonly defaultValue: T,
    ) {
        this._optionSubject$ = new BehaviorSubject<T>(defaultValue);
    }

    public next(value: T) {
        this._optionSubject$.next(value);
    }

    public resetToDefault() {
        this.next(this.defaultValue);
    }

    public get option$() {
        return this._optionSubject$.asObservable();
    }

    public get currentValue() {
        return this._optionSubject$.value;
    }
}

<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.BullseyeOverview"
                                          context="page"
                                          [fullScreen]="true"></adapt-context-sidebar-implementation-kit>

<ng-container *adaptLoading="!isInitialised">
    <div *adaptIfAuthorised="EditBullseye"
         adaptToolbarContent>
        <button adaptButtonIcon="edit"
                [adaptBlockingClick]="runBullseyeWorkflow"
                data-test="edit-bullseye-button"
                data-tour="edit-bullseye-button">Edit bullseye</button>
    </div>

    <div class="d-flex flex-column flex-lg-row"
         *ngIf="bullseye?.extensions?.areQuadrantsCompleted(); else noBullseye">
        <div class="d-flex flex-column left-column mt-3 me-3">
            <adapt-bullseye-view class="bullseye-container"
                                 [showZoom]="true"
                                 data-tour="bullseye"></adapt-bullseye-view>
        </div>
        <div class="d-flex flex-column right-column">
            <adapt-collapsible-dashboard-element *ngIf="bullseye!.statement"
                                                 class="mt-4 bullseye-statement"
                                                 name="Bullseye statement"
                                                 [isCollapsible]="false"
                                                 data-tour="custom-bullseye-statement">
                <div [froalaView]="bullseye!.statement"></div>
            </adapt-collapsible-dashboard-element>
        </div>
    </div>
</ng-container>

<ng-template #noBullseye>
    <adapt-call-to-action>
        <adapt-call-to-action-title>Bullseye</adapt-call-to-action-title>
        <p>A business can quickly go off track, becoming distracted by perceived opportunities or overly focused on perceived threats. Or
            the owner simply gets bored! It is important to define what you do so you can be clear about what you will not do.
            This then becomes your bullseye.
        </p>
        <button *adaptIfAuthorised="EditBullseye"
                adaptCallToActionButton
                adaptButtonIcon="edit"
                [adaptBlockingClick]="runBullseyeWorkflow">
            Set bullseye
        </button>
    </adapt-call-to-action>
</ng-template>

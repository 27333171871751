<dx-select-box [dataSource]="existingThemes"
               [disabled]="disabled"
               [value]="theme"
               displayExpr="name"
               (valueChange)="onThemeValueChanged($event)"
               [searchEnabled]="allowCreation"
               [acceptCustomValue]="allowCreation"
               (onCustomItemCreating)="onCustomThemeCreating($event)"
               [showClearButton]="true"
               [height]="height"
               fieldTemplate="field">
    <div *dxTemplate="let field of 'field'"
         class="h-100 d-flex align-items-center">
        <dx-text-box [value]="field?.name"
                     [placeholder]="placeholderText"
                     [maxLength]="60"></dx-text-box>
    </div>
</dx-select-box>

import { WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { buttonPreset } from "@common/ux/button/button-preset";
import { IMeetingLocation, IMeetingOrganiserInfo } from "../../calendar/calendar.interface";
import { buildLocalWorkflow } from "../../workflow/workflow.interface";

export interface IScheduleMeetingWorkflowRunData {
    meeting: Meeting;
    entitiesToConfirm: IBreezeEntity[];
    meetingLocation?: IMeetingLocation;
    hasConflicts?: boolean;
    organiserInfo?: IMeetingOrganiserInfo;
    sendInvitations?: boolean;
    createTeamsMeeting?: boolean;
}

function buildScheduleMeetingWorkflow(editing = false) {
    const name = editing ? "Edit meeting" : "Schedule meeting";
    const agendaStepText = "Meeting agenda";

    return buildLocalWorkflow({
        name,
        workflowId: "ScheduleMeetingLocal",
        type: WorkflowType.Workflow,
        dialogWidth: WorkflowDialogWidth.Medium,
        private: true,
        isStateless: true,
        steps: [{
            name,
            ordinal: 0,
            allowBack: true,
            canSkip: false,
            componentSelector: "adapt-schedule-meeting-schedule-step",
            workflowStepNextText: `Next - ${agendaStepText}`,
        }, {
            name: `${name} - ${agendaStepText}`,
            ordinal: 1,
            allowBack: true,
            canSkip: false,
            workflowStepNextIcon: buttonPreset.saveAndClose.iconClass,
            workflowStepNextText: buttonPreset.saveAndClose.buttonText,
            componentSelector: "adapt-schedule-meeting-agenda-step",
        }],
    });
}

export const scheduleMeetingWorkflow = buildScheduleMeetingWorkflow(false);
export const editMeetingWorkflow = buildScheduleMeetingWorkflow(true);

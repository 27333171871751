import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { BullseyeStatementLocation } from "@common/ADAPT.Common.Model/organisation/bullseye-statement-location";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { InputLocation } from "@common/ADAPT.Common.Model/organisation/input-location";
import { CanvasType } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSortableEvent } from "@common/ux/dx.types";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { StrategicGoalComponent } from "@org-common/lib/strategic-goals/strategic-goal/strategic-goal.component";
import { StrategicGoalsService } from "@org-common/lib/strategic-goals/strategic-goals.service";
import { StrategicInputsService } from "@org-common/lib/strategic-inputs/strategic-inputs.service";
import { StrategyService } from "@org-common/lib/strategy/strategy.service";
import { StrategicViewIcon, StrategicViewOption } from "@org-common/lib/strategy/strategy-view-constants";
import { debounceTime, forkJoin, lastValueFrom, map, merge, of, Subject, switchMap, tap } from "rxjs";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { StrategyAuthService } from "../../strategy/strategy-auth.service";

const CAT_NAME_KEY = "data-cat-name";

interface ICategorisedInputLocations {[category: string]: InputLocation[]}

@Component({
    selector: "adapt-strategy-zone",
    templateUrl: "./strategy-zone.component.html",
    styleUrls: ["./strategy-zone.component.scss", "../../strategic-goals/strategic-goal/strategic-goal.component.scss"],
})
export class StrategyZoneComponent extends BaseComponent implements OnChanges, AfterViewChecked {
    @Input() public isEditing = false;
    @Input() public zone?: Zone;
    @Input() public views = [StrategicViewOption.Goals];
    @Input() public expandGoals = false;

    public readonly CanvasType = CanvasType;
    public readonly StrategicViewIcon = StrategicViewIcon;
    public readonly StrategicViewOption = StrategicViewOption;

    public includesThemesView = false;
    public includesGoalsView = false;
    public includesSWTInputsView = false;
    public includesCAInputsView = false;
    public includesBullseyeView = false;

    public goals: Goal[] = [];
    public categorisedGoals: {[category: string]: Goal[]} = {};
    public categorisedSWTInputs: ICategorisedInputLocations = {};
    public categorisedCAInputs: ICategorisedInputLocations = {};
    public categorisedBullseyeStatements: {[category: string]: BullseyeStatementLocation[]} = {};
    public orderedThemeNames: string[] = [];
    public uncategorisedGoals: Goal[] = [];
    public uncategorisedSWTInputLocations: InputLocation[] = [];
    public uncategorisedCAInputLocations: InputLocation[] = [];
    public uncategorisedBullseyeStatements: BullseyeStatementLocation[] = [];

    public categoryDragOrientation = "vertical";
    public goalDropGroup = "Unknown";

    public isDraggingGoal = false;
    public isDraggingCategorisedGoal = false;
    public isDraggingTheme = false;
    public orderedThemes: Theme[] = [];
    public isSingleColumn = false;

    public isDraggingInputTheme?: string | null; // null is uncategorised, undefined is no drag
    public draggingInputType?: CanvasType;
    public isDraggingBullseyeStatementTheme?: string | null;
    public hasAttachedSWTInputs = false;
    public hasAttachedCAInputs = false;
    public hasAttachedBullseye = false;
    public hasDisplayGoals = false;
    public hasDisplayThemes = false;

    private readonly ThemeLaneDisplayOrder = [StrategicViewOption.Themes, StrategicViewOption.Goals, StrategicViewOption.SWTInputs, StrategicViewOption.CAInputs, StrategicViewOption.Bullseye];
    private dragOrientationSet = false;
    // This is to make sure only a single updateZone happening (from ngChanges or entity type changed: Goal, Theme and InputLocation)
    private triggerUpdateZone = new Subject<void>();
    private themeMenuItemsMap: {[themeName: string]: IAdaptMenuItem[]} = {};

    @ViewChildren(StrategicGoalComponent) private goalComponentList?: QueryList<StrategicGoalComponent>;

    public constructor(
        elementRef: ElementRef,
        private goalsService: StrategicGoalsService,
        private inputsService: StrategicInputsService,
        private bullseyeService: BullseyeService,
        private strategyService: StrategyService,
        private authorisationService: AuthorisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        merge(
            rxjsBreezeService.entityTypeChanged(Goal),
            rxjsBreezeService.entityTypeChanged(Theme),
            rxjsBreezeService.entityTypeChanged(InputLocation),
            rxjsBreezeService.entityTypeChanged(BullseyeStatementLocation),
            this.triggerUpdateZone.asObservable(),
        ).pipe(
            debounceTime(500),
            switchMap(() => this.updateZone()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get isHorizontal() {
        return this.categoryDragOrientation === "horizontal";
    }

    public get isThemeDraggable() {
        return this.isEditing && this.orderedThemeNames.length > 1;
    }

    public get isGoalDraggable() {
        return this.isEditing && (this.goals.length > 1 || this.orderedThemeNames.length > 0);
    }

    public get hasAnyContent() {
        return this.hasAttachedSWTInputs || this.hasAttachedCAInputs || this.hasDisplayThemes || this.hasDisplayGoals || this.hasAttachedBullseye;
    }

    public detectChanges() {
        this.goalComponentList?.forEach((goalComponent) => goalComponent.expand(this.expandGoals));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.views) {
            this.isInitialised = false;
            this.includesThemesView = this.views.includes(StrategicViewOption.Themes);
            this.includesGoalsView = this.views.includes(StrategicViewOption.Goals);
            this.includesSWTInputsView = this.views.includes(StrategicViewOption.SWTInputs);
            this.includesCAInputsView = this.views.includes(StrategicViewOption.CAInputs);
            this.includesBullseyeView = this.views.includes(StrategicViewOption.Bullseye);
            this.triggerUpdateZone.next();
        }
    }

    // cannot use AfterViewInit as the that's triggered before the goals query finishes
    public ngAfterViewChecked() {
        if (this.isInitialised && !this.dragOrientationSet) {
            // this is needed to allow wrapping of goal cards in Econ zone while not pushing width off the screen for other zones
            const element = this.elementRef?.nativeElement as HTMLElement;
            const parentElementWidth = element.parentElement?.offsetWidth;
            if (parentElementWidth && parentElementWidth > 200) {
                this.dragOrientationSet = true;
                const allCategoryNodes = element.querySelectorAll(".category");
                // Check category nodes - if node offset top doesn't have 2 identical -> vertical, else horizontal
                // this is just for the dxSortable for categories
                const offsetTops: number[] = [];
                allCategoryNodes.forEach((categoryNode: HTMLElement) => offsetTops.push(categoryNode.offsetTop));

                // this is from afterViewChecked -> which will result in ExpressionChangedAfterItHasBeenCheckedError
                // if this drag orientation which is bounded in the component template is changed here
                setTimeout(() => {
                    this.isSingleColumn = parentElementWidth < 500;
                    this.categoryDragOrientation = !this.isSingleColumn && ArrayUtilities.distinct(offsetTops).length < allCategoryNodes.length
                        ? "horizontal" // at least 2 at the same top -> can drag horizontally
                        : "vertical";
                }, 50);
            }
        }
    }

    @HostListener("window:resize")
    public onWindowResize() {
        this.dragOrientationSet = false;
    }

    public hasDisplayContent(themeName: string, currentViewOption?: StrategicViewOption) {
        let hasPreviousContent = false;
        for (const previousOption of this.ThemeLaneDisplayOrder) {
            if ((previousOption === currentViewOption) || hasPreviousContent) {
                break;
            }

            hasPreviousContent = this.hasViewOptionContent(themeName, previousOption);
        }
        return hasPreviousContent;
    }

    private hasViewOptionContent(themeName: string, viewOption: StrategicViewOption) {
        let hasContent = false;
        switch (viewOption) {
            case StrategicViewOption.Themes:
                hasContent = this.includesThemesView;
                break;
            case StrategicViewOption.Goals:
                hasContent = this.includesGoalsView &&
                    (!!this.categorisedGoals[themeName]?.length || this.isDraggingGoal);
                break;
            case StrategicViewOption.SWTInputs:
                hasContent = this.includesSWTInputsView && !!this.categorisedSWTInputs[themeName]?.length;
                break;
            case StrategicViewOption.CAInputs:
                hasContent = this.includesCAInputsView && !!this.categorisedCAInputs[themeName]?.length;
                break;
            case StrategicViewOption.Bullseye:
                hasContent = this.includesBullseyeView && !!this.categorisedBullseyeStatements[themeName]?.length;
                break;
            default:
                break;
        }

        return hasContent;
    }

    public onThemeDragStart(e: IDxSortableEvent<string[]>) {
        // not going to allow drag/drop if there is only 1 theme or the uncategorised group
        e.cancel = !this.isThemeDraggable || e.fromIndex! >= this.orderedThemeNames.length;
        if (!e.cancel) {
            this.isDraggingTheme = true;
            if (this.isHorizontal) {
                // need to refresh the sortable as we will take away flex-wrap to workaround issue with wrapped item
                // - can't update from callback - need to do it next digest cycle
                setTimeout(() => e.component?.update());
            }
        }
    }

    public onGoalDragStart(e: IDxSortableEvent<Goal[]>) {
        e.cancel = !this.isEditing;
        if (!e.cancel) {
            this.isDraggingGoal = true;

            const draggedGoal = e.fromData![e.fromIndex!];
            this.isDraggingCategorisedGoal = !!draggedGoal?.themeId;

            if (!this.isDraggingCategorisedGoal) {
                // need to refresh sortable as the whole sortable may have been pushed down by the appearing of drop zone above it:
                // - need to do this next digest cycle as this is a callback from the same sortable
                // - without this, you cannot reorder any uncategorised goals as the displacement for the sortable are all shifted
                setTimeout(() => e.component?.update());
            }
        }
    }

    public onInputDragStart(e: IDxSortableEvent<InputLocation[]>) {
        e.cancel = !this.isEditing;
        if (!e.cancel) {
            const draggedInputLocation = e.fromData![e.fromIndex!];
            this.isDraggingInputTheme = draggedInputLocation?.theme?.name ?? null;
            this.draggingInputType = draggedInputLocation?.input.canvas.type;
        }
    }

    public onBullseyeStatementDragStart(e: IDxSortableEvent<BullseyeStatementLocation[]>) {
        e.cancel = !this.isEditing;
        if (!e.cancel) {
            const draggedStatementLocation = e.fromData![e.fromIndex!];
            this.isDraggingBullseyeStatementTheme = draggedStatementLocation?.theme?.name ?? null;
        }
    }

    public preventDragToUncategorisedArea(e: IDxSortableEvent<string[]>) {
        // I need the uncategorised container to be in the same container as the category container so that they can be
        // equally stretched. Having the uncategorised container outside this sortable will result in weird scaling.
        // - so need this to prevent item from being dragged into the uncategorised area - want to keep that at the end
        e.cancel = e.toIndex! >= this.orderedThemeNames.length;
    }

    public detachBullseyeStatementLocation(bullseyeStatementLocation: BullseyeStatementLocation) {
        const detachThemeName = bullseyeStatementLocation.theme?.name;
        const locationsGroup = detachThemeName ? this.categorisedBullseyeStatements[detachThemeName] : this.uncategorisedBullseyeStatements;
        const remainingLocations = locationsGroup.filter((i) => i !== bullseyeStatementLocation);
        this.bullseyeService.detachBullseyeStatementLocation(bullseyeStatementLocation).pipe(
            switchMap(() => (remainingLocations.length > 0)
                ? this.reIndexBullseyeStatementsOrdinal(remainingLocations)
                : of(undefined)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public reorderBullseyeStatement(e: IDxSortableEvent<BullseyeStatementLocation[]>) {
        this.isDraggingBullseyeStatementTheme = undefined;
        if (e.fromIndex !== e.toIndex) {
            SortUtilities.moveItemInArray(e.fromData!, e.fromIndex!, e.toIndex!);
            this.reIndexBullseyeStatementsOrdinal(e.fromData!).subscribe();
        }
    }

    public reorderInput(e: IDxSortableEvent<InputLocation[]>) {
        this.isDraggingInputTheme = undefined;
        this.draggingInputType = undefined;
        if (e.fromIndex !== e.toIndex) {
            SortUtilities.moveItemInArray(e.fromData!, e.fromIndex!, e.toIndex!);
            this.reIndexInputLocationsOrdinal(e.fromData!).subscribe();
        }
    }

    public reorderGoal(e: IDxSortableEvent<Goal[]>) {
        // this is called on drag end
        this.isDraggingGoal = false;
        this.isDraggingCategorisedGoal = false;

        let hasChanges = false;
        // Checked and confirmed that fromData and toData are referring to the same array instance
        // - ordering of fromData will also also sort toData and the source collection passed to [data] of dxSortable
        // - so only need to order fromData/toData and re-index all should work
        if (e.fromData === e.toData) {
            if (e.fromIndex !== e.toIndex) {
                SortUtilities.moveItemInArray(e.fromData!, e.fromIndex!, e.toIndex!);
                hasChanges = true;
            } else if (e.event?.clientX && e.event?.clientY) {
                // this is to handle dxSortable issue where we want to drop at the bottom of another sortable,
                // the bottom is being pushed (i.e. last item has extra 48px margin-bottom) but if you drop, event is not reflecting that.
                const dropTheme = this.getThemeFromPoint(e.event.clientX, e.event.clientY);
                if (dropTheme) { // if no theme, this is not dropping to an acceptable zone
                    const movedItem = e.fromData![e.fromIndex!];
                    if (movedItem.theme !== dropTheme) {
                        e.fromData!.splice(e.fromIndex!, 1);
                        movedItem.theme = dropTheme;
                        const catGoals = this.getGoalsForTheme(dropTheme);
                        catGoals.push(movedItem);

                        // need to clear the margin bottom of the last item (which was inserted during the drag)
                        const catSortable = this.getSortableElementFromPoint(e.event.clientX, e.event.clientY);
                        catSortable?.querySelectorAll("adapt-strategic-goal").forEach((element: HTMLElement) => {
                            if (element.style.marginBottom) {
                                // this is the style inserted by dx and left a gap there before last and newly inserted goal
                                // - remove it
                                element.style.marginBottom = "";
                            }
                        });
                        hasChanges = true;
                    }
                }
            }
        } else {
            const movedItem = e.fromData!.splice(e.fromIndex!, 1)[0];
            // if toData is empty, will need to figure out the theme from drop point as we can now drop to any empty theme
            const destinationTheme = e.toData?.length ? e.toData[0].theme : this.getThemeFromPoint(e.event?.clientX, e.event?.clientY);
            movedItem.theme = destinationTheme;
            const destGroup = e.toData ?? this.getGoalsForTheme(destinationTheme);
            destGroup.splice(e.toIndex!, 0, movedItem);
            hasChanges = true;
        }

        if (hasChanges) {
            this.reIndexGoalsOrdinal().subscribe();
        }
    }

    public reorderThemes(e: IDxSortableEvent<string[]>) {
        this.isDraggingTheme = false;
        if (e.fromIndex === e.toIndex) {
            return;
        }

        SortUtilities.moveItemInArray(this.orderedThemeNames, e.fromIndex!, e.toIndex!);
        this.reorderThemesWithNamesOrder(this.orderedThemeNames).pipe(
            switchMap(() => this.reIndexGoalsOrdinal()),
        ).subscribe();
    }

    public getThemeByName(name: string) {
        return this.orderedThemes.find((theme) => theme.name === name);
    }

    @Autobind
    public editTheme(theme: Theme) {
        return this.strategyService.editTheme(theme);
    }

    public detachSWTInputLocation(inputLocation: InputLocation) {
        const detachThemeName = inputLocation.theme?.name;
        const inputLocationsGroup = detachThemeName ? this.categorisedSWTInputs[detachThemeName] : this.uncategorisedSWTInputLocations;
        this.detachInputLocation(inputLocation, inputLocationsGroup);
    }

    public detachCAInputLocation(inputLocation: InputLocation) {
        const detachThemeName = inputLocation.theme?.name;
        const detachCollection = detachThemeName ? this.categorisedCAInputs[detachThemeName] : this.uncategorisedCAInputLocations;
        this.detachInputLocation(inputLocation, detachCollection);
    }

    private detachInputLocation(inputLocation: InputLocation, collection: InputLocation[]) {
        const remainingInputLocationsInGroup = collection.filter((i) => i !== inputLocation);
        this.inputsService.detachInputLocation(inputLocation).pipe(
            switchMap(() => {
                if (remainingInputLocationsInGroup.length > 0) {
                    return this.reIndexInputLocationsOrdinal(remainingInputLocationsInGroup);
                } else {
                    return of(undefined);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    private reIndexGoalsOrdinal() {
        let ordinal = 0;
        for (const category of this.orderedThemeNames) {
            const goals = this.categorisedGoals[category];
            if (goals) {
                for (const goal of goals) {
                    goal.ordinal = ordinal++;
                }
            }
        }

        for (const goal of this.uncategorisedGoals) {
            goal.ordinal = ordinal++;
        }

        return this.goalsService.saveEntities(this.goals);
    }

    private reIndexInputLocationsOrdinal(inputLocations: InputLocation[]) {
        let ordinal = 0;
        for (const inputLocation of inputLocations) {
            inputLocation.ordinal = ++ordinal;
        }

        return this.inputsService.saveEntities(inputLocations);
    }

    private reIndexBullseyeStatementsOrdinal(bullseyeStatementLocations: BullseyeStatementLocation[]) {
        let ordinal = 0;
        for (const location of bullseyeStatementLocations) {
            location.ordinal = ++ordinal;
        }

        return this.bullseyeService.saveEntities(bullseyeStatementLocations);
    }

    public getMenuItemsForTheme(themeName: string) {
        return this.themeMenuItemsMap[themeName];
    }

    private async createMenuItemsForTheme(theme: Theme) {
        const menuItems: IAdaptMenuItem[] = [{
            icon: MenuComponent.SmallRootMenu.icon,
            items: [],
        }];

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditStrategicInputs)) {
            menuItems[0].items?.push(this.inputsService.getAttachSWTInputMenuItem(theme.zone, theme));
            menuItems[0].items?.push(this.inputsService.getAttachCompetitorAnalysisInputMenuItem(theme.zone, theme));
        }

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditBullseye)) {
            menuItems[0].items?.push(this.bullseyeService.getAttachBullseyeStatementMenuItem(theme.zone, theme));
        }

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditStrategicGoals)) {
            menuItems[0].items?.push(this.goalsService.getAddStrategicGoalMenuItem(theme.zone, theme));
        }

        return menuItems;
    }

    private updateZone() {
        if (this.zone) {
            this.goalDropGroup = `allowableGoalDropForZone${this.zone}`;
            return forkJoin([
                this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategicGoals),
                this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategyBoard),
                this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategicInputs),
                this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadBullseye),
            ]).pipe(
                // only get content we have read access for
                switchMap(([canReadGoals, canReadBoard, canReadInputs, canReadBullseye]) => forkJoin([
                    canReadGoals ? this.goalsService.getGoalsByZone(this.zone!) : of([]),
                    canReadBoard ? this.strategyService.getThemesByZone(this.zone!) : of([]),
                    canReadInputs ? this.inputsService.getInputLocationsForZone(this.zone!) : of([]),
                    canReadBullseye ? this.bullseyeService.getBullseyeStatementLocationsForZone(this.zone!) : of([]),
                ])),
                switchMap(([goals, themes, inputLocations, bullseyeStatementLocations]) => {
                    // need to prime canvases first
                    let unprimedCanvasIds = inputLocations
                        .filter((i) => i.input.canvasId && !i.input.canvas)
                        .map((i) => i.input.canvasId);
                    unprimedCanvasIds = ArrayUtilities.distinct(unprimedCanvasIds);
                    if (unprimedCanvasIds.length > 0) {
                        return forkJoin(unprimedCanvasIds.map((canvasId) => this.inputsService.getCanvasById(canvasId))).pipe(
                            map(() => ({ goals, themes, inputLocations, bullseyeStatementLocations })),
                        );
                    } else {
                        return of({ goals, themes, inputLocations, bullseyeStatementLocations });
                    }
                }),
                switchMap((content) => {
                    // build theme menus
                    const themeToMenuMap = Object.fromEntries(content.themes
                        .map((theme) => [theme.name, this.createMenuItemsForTheme(theme)]));

                    if (Object.keys(themeToMenuMap).length === 0) {
                        return of(content);
                    }

                    return forkJoin(themeToMenuMap).pipe(
                        tap((menuItems) => this.themeMenuItemsMap = menuItems),
                        map(() => content),
                    );
                }),
                switchMap(({ goals, themes, inputLocations, bullseyeStatementLocations }) => {
                    this.orderedThemes = themes;
                    this.categoriseInputLocations(inputLocations);
                    this.categoriseBullseyeStatements(bullseyeStatementLocations);

                    return this.categoriseGoals(goals);
                }),
                tap(() => this.isInitialised = true),
                this.takeUntilDestroyed(),
            );
        } else {
            return of(undefined);
        }
    }

    private categoriseInputLocations(inputLocations: InputLocation[]) {
        this.categorisedSWTInputs = {};
        this.categorisedCAInputs = {};
        this.uncategorisedSWTInputLocations = [];
        this.uncategorisedCAInputLocations = [];
        this.hasAttachedSWTInputs = this.includesSWTInputsView && inputLocations.filter((i) => i.input.canvas.type === CanvasType.StrengthsWeaknessesTrends).length > 0;
        this.hasAttachedCAInputs = this.includesCAInputsView && inputLocations.filter((i) => i.input.canvas.type === CanvasType.CompetitorAnalysis).length > 0;
        inputLocations.forEach((inputLocation) => this.getCategorisedInputsGroup(inputLocation).push(inputLocation));
    }

    private getCategorisedInputsGroup(inputLocation: InputLocation) {
        switch (inputLocation.input.canvas.type) {
            case CanvasType.StrengthsWeaknessesTrends:
                return this.getInputLocationsGroup(inputLocation, this.uncategorisedSWTInputLocations, this.categorisedSWTInputs);
            case CanvasType.CompetitorAnalysis:
                return this.getInputLocationsGroup(inputLocation, this.uncategorisedCAInputLocations, this.categorisedCAInputs);
            default:
                throw new Error("TODO: this input type is not yet implemented: " + inputLocation.input.canvas.type);
        }

    }

    private getInputLocationsGroup(inputLocation: InputLocation, uncategorisedInputLocations: InputLocation[], categorisedInputLocations: ICategorisedInputLocations) {
        if (!inputLocation.theme) {
            return uncategorisedInputLocations;
        } else {
            let inputLocations = categorisedInputLocations[inputLocation.theme.name];
            if (!inputLocations) {
                inputLocations = [];
                categorisedInputLocations[inputLocation.theme.name] = inputLocations;
            }

            return inputLocations;
        }
    }

    private categoriseBullseyeStatements(locations: BullseyeStatementLocation[]) {
        this.categorisedBullseyeStatements = {};
        this.uncategorisedBullseyeStatements = [];
        this.hasAttachedBullseye = this.includesBullseyeView && locations.length > 0;
        locations.forEach((location) => {
            if (!location.theme) {
                this.uncategorisedBullseyeStatements.push(location);
            } else {
                let catLocations = this.categorisedBullseyeStatements[location.theme.name];
                if (!catLocations) {
                    catLocations = [];
                    this.categorisedBullseyeStatements[location.theme.name] = catLocations;
                }

                catLocations.push(location);
            }
        });
    }

    private async categoriseGoals(goals: Goal[]) {
        this.goals = goals;
        this.categorisedGoals = {};
        this.uncategorisedGoals = [];
        goals.forEach((goal) => {
            if (!goal.theme) {
                this.uncategorisedGoals.push(goal);
            } else {
                let categorisedGoals = this.categorisedGoals[goal.theme.name];
                if (!categorisedGoals) {
                    categorisedGoals = [];
                    this.categorisedGoals[goal.theme.name] = categorisedGoals;
                }

                categorisedGoals.push(goal);
            }
        });

        this.orderedThemeNames = Object.keys(this.categorisedGoals);
        await lastValueFrom(this.reorderThemesWithNamesOrder(this.orderedThemeNames));
        // still needs the above even for themes so that we can re-index the goals ordinal when re-ordering themes

        // this will make all themes to be visible regardless of view options
        this.orderedThemeNames = this.orderedThemes.map((theme) => theme.name);
        this.hasDisplayGoals = this.includesGoalsView && this.goals.length > 0;
        this.hasDisplayThemes = this.orderedThemeNames.length > 0; // Display theme is an odd one where it will be shown even if theme description is unticked
        this.dragOrientationSet = false; // this will trigger recalculate of the drag orientation
    }

    private reorderThemesWithNamesOrder(orderedThemeNames: string[]) {
        // orderedThemes will include all themes including those without goals -> so just need to swap current visible ones without touching the invisible
        for (let i = 0; i < orderedThemeNames.length; i++) {
            const themeName = orderedThemeNames[i];
            const themeIndex = this.orderedThemes.findIndex((theme) => theme.name === themeName);
            const namesAfter = orderedThemeNames.slice(i + 1);
            for (let j = 0; j < themeIndex; j++) {
                const theme = this.orderedThemes[j];
                // get the 1st theme before themeIndex that's in namesAfter and swap them
                if (namesAfter.includes(theme.name)) {
                    // swap j and themeIndex
                    [this.orderedThemes[j], this.orderedThemes[themeIndex]] = [this.orderedThemes[themeIndex], this.orderedThemes[j]];
                    break;
                }
            }
        }

        let index = 0;
        this.orderedThemes.forEach((theme) => theme.ordinal = index++);
        // let breeze does its magic - there won't be a save POST if orderedThemes are unchanged
        return this.goalsService.saveEntities(this.orderedThemes);
    }

    private getThemeFromPoint(x?: number, y?: number) {
        const catSortable = this.getSortableElementFromPoint(x, y);
        if (catSortable) {
            const catName = catSortable.getAttribute(CAT_NAME_KEY);
            if (catName) {
                return this.orderedThemes.find((theme) => theme.name === catName);
            }
        }

        return undefined;
    }

    private getSortableElementFromPoint(x?: number, y?: number) {
        if (!x || !y) {
            return undefined;
        }

        const elements = window.document.elementsFromPoint(x, y);
        return elements.find((element) => element.hasAttribute(CAT_NAME_KEY));
    }

    private getGoalsForTheme(theme?: Theme) {
        let goals: Goal[];
        if (!theme) {
            goals = this.uncategorisedGoals;
        } else {
            goals = this.categorisedGoals[theme.name];
            if (!goals) {
                // category not previous has any goal
                goals = [];
                this.categorisedGoals[theme.name] = goals;
            }
        }

        return goals;
    }
}

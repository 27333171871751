import { Component, Inject, ViewChild } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { IUpdateCreditCardInput, UpdateCreditCardDetailsComponent } from "@common/payment-processing/update-credit-card-details/update-credit-card-details.component";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { catchError, EMPTY, switchMap, tap } from "rxjs";

@Component({
    selector: "adapt-set-initial-subscription-dialog",
    templateUrl: "./set-initial-subscription-dialog.component.html",
})
export class SetInitialSubscriptionDialogComponent extends BaseDialogComponent<Account> {
    public readonly dialogName = "SetInitialSubscriptionDialogComponent";

    public monthlyPrice = this.account.extensions.fixedMonthlyDollars;
    public cardSetCorrectly = false;

    @ViewChild(UpdateCreditCardDetailsComponent) public updateCreditCardDetailsComponent?: UpdateCreditCardDetailsComponent;

    public creditCardInput: IUpdateCreditCardInput = {
        cardDetails: undefined,
        organisationIdentifier: {
            organisationId: this.account.organisationId,
            eulaToken: undefined,
        },
    };

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public account: Account,
        private commonDataService: CommonDataService,
        private paymentService: PaymentProcessingService,
    ) {
        super();
    }

    public cancel() {
        this.commonDataService.rejectChanges(this.account)
            .subscribe(() => super.cancel());
    }

    @Autobind
    public saveAndClose() {
        return this.updateCreditCardDetailsComponent!.saveCreditCard().pipe(
            switchMap(() => this.paymentService.saveEntities(this.account)),
            switchMap(() => this.paymentService.commenceSubscription(this.account.organisationId, true)),
            tap(() => super.resolve(this.account)),
            catchError((e) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
        );
    }
}

import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { AdaptCommonModule } from "@common/lib/common.module";
import { registerGuidedTour } from "@common/lib/guided-tour/guided-tour-registrar";
import { provideSubscribeOrganisationPageRoute } from "@common/page-route-providers";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { RouteService } from "@common/route/route.service";
import { RouteEventsService } from "@common/route/route-events.service";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptChangeManagerModule } from "@common/ux/change-manager/change-manager.module";
import { ContentWithSplashImageModule } from "@common/ux/content-with-splash-image/content-with-splash-image.module";
import { AdaptDocumentSelectorModule } from "@common/ux/document-selector/document-selector.module";
import { AdaptSteppedProgressBarModule } from "@common/ux/stepped-progress-bar/stepped-progress-bar.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptEntitySyncModule } from "@org-common/lib/entity-sync/entity-sync.module";
import { AdaptErrorPageModule } from "@org-common/lib/error-page/error-page.module";
import { AdaptEventTrackingModule } from "@org-common/lib/event-tracking/event-tracking.module";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { AdaptMyOrganisationsModule } from "@org-common/lib/my-organisations/my-organisations.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptOrganisationModule } from "@org-common/lib/organisation/organisation.module";
import { AdaptOrgCommonShellModule } from "@org-common/lib/shell/org-common-shell.module";
import { AdaptStrategicGoalsModule } from "@org-common/lib/strategic-goals/strategic-goals.module";
import { AdaptStrategicInputsModule } from "@org-common/lib/strategic-inputs/strategic-inputs.module";
import { AdaptStrategyBoardModule } from "@org-common/lib/strategy-board/strategy-board.module";
import { OrganisationDiagnosticAuthService } from "@org-common/lib/survey/organisation-diagnostic/organisation-diagnostic-auth.service";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { AdaptCommonTeamsModule } from "@org-common/lib/teams/common-teams.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { provideEditValueAction } from "@org-common/lib/values-constitution/values-constitution-page/values-constitution-page.component";
import { WorkflowRunDialogComponent } from "@org-common/lib/workflow/workflow-run-dialog/workflow-run-dialog.component";
import { IntercomModule } from "@supy-io/ngx-intercom";
import { AdaptAltoAboutModule } from "./about/about.module";
import { AdaptAccountModule } from "./account/account.module";
import { SignupPageRoute } from "./account/signup-page/signup-page.component";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AdaptConfigurationModule } from "./configuration/configuration.module";
import { AdaptNavigationModule } from "./navigation/navigation.module";
import { AdaptAltoOrganisationModule } from "./organisation/organisation.module";
import { AdaptPersonModule } from "./person/person.module";
import { AdaptPurposeVisionModule } from "./purpose-vision/purpose-vision.module";
import { registerRouteEventsHandler } from "./route-events-handler";
import { AdaptSearchModule } from "./search/search.module";
import { AdaptAltoSidebarModule } from "./sidebar/sidebar.module";
import { AdaptAltoTeamModule } from "./team/team.module";
import { AddStrategicGoalsTour } from "./tours/add-strategic-goals-tour";
import { OwnersVisionAlignmentTour } from "./tours/align-ownership-with-vision";
import { AltoShellTour } from "./tours/alto-shell-tour";
import { AttachBullseyeStatementsTour } from "./tours/attach-bullseye-statements-tour";
import { AttachInputsTour } from "./tours/attach-inputs-tour";
import { BullseyeTour } from "./tours/bullseye-tour";
import { CreateKeyResultsForAnnualObjectiveTour } from "./tours/create-key-results-for-annual-obj-tour";
import { CreateKeyResultsForQuarterlyObjectiveTour } from "./tours/create-key-results-for-quarterly-obj-tour";
import { HealthCheckTour } from "./tours/health-check-tour";
import { ObjectivesWrapUpTour } from "./tours/objectives-wrapup-tour";
import { OwnersWhyTour } from "./tours/owners-why-tour";
import { PurposeStatementTour } from "./tours/purpose-statement-tour";
import { RecordAnchorsTour } from "./tours/record-anchors-tour";
import { RecordAnnualObjectivesTour } from "./tours/record-annual-objectives-tour";
import { RecordQuarterlyObjectivesTour } from "./tours/record-quarterly-objectives-tour";
import { SetValuesTour } from "./tours/set-values-tour";
import { StrategicAnchorsWrapUpTour } from "./tours/strategic-anchors-wrap-up-tour";
import { StrategicThemesTour } from "./tours/strategic-themes-tour";
import { TourIdentifier } from "./tours/tour-identifier.enum";
import { VisionStatementTour } from "./tours/vision-statement-tour";
import { createValueWorkflow, ICreateValueData } from "./workflows/create-value/create-value-workflow";
import { AdaptAltoWorkflowsModule } from "./workflows/workflows.module";

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,

        // AppId won't be accessible at this time due to async config fetching
        // (also can't use functions in decorators) so do in constructor instead.
        IntercomModule.forRoot({
            appId: "",
        }),

        AdaptCommonModule.forRoot(),
        AdaptCommonRouteModule,
        AdaptChangeManagerModule,
        AdaptShellModule,
        AdaptButtonModule,
        ContentWithSplashImageModule,
        AdaptDocumentSelectorModule.forRoot(),
        AdaptEventTrackingModule.forRoot(),
        AdaptNavigationModule.forRoot(),
        AdaptAltoSidebarModule,
        AdaptMyOrganisationsModule.forRoot(),

        AdaptOrganisationModule,
        AdaptConfigurationModule,
        AdaptSurveyModule,
        AdaptAuthorisationModule,
        AdaptEntitySyncModule,
        AdaptChangeManagerModule,
        AdaptStrategyBoardModule,
        AdaptStrategicGoalsModule,
        AdaptStrategicInputsModule,

        AdaptAccountModule,
        AdaptPersonModule,
        AdaptAltoWorkflowsModule,
        AdaptAltoOrganisationModule,
        AdaptAltoTeamModule,
        AdaptSearchModule,
        AdaptSteppedProgressBarModule,
        AdaptObjectivesModule,
        AdaptCommonTeamsModule,
        AdaptKanbanModule,
        AdaptMeetingsSharedModule,
        AdaptPurposeVisionModule,
        AdaptValuesConstitutionModule,
        AdaptAltoAboutModule,
        AdaptOrgCommonShellModule,
        AdaptErrorPageModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [RouteEventsService, RouteService, AdaptCommonDialogService],
            useFactory: registerRouteEventsHandler,
        },
        provideSubscribeOrganisationPageRoute(SignupPageRoute),
        provideEditValueAction((injector, value) => {
            const dialogService = injector.get(AdaptCommonDialogService);
            return dialogService.open(WorkflowRunDialogComponent, {
                workflow: createValueWorkflow,
                runData: {
                    valuesConstitution: value.valuesConstitution,
                    value,
                } as ICreateValueData,
                titleOverride: value.entityAspect.entityState.isUnchangedOrModified()
                    ? "Edit a value"
                    : undefined,
            });
        }),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    public constructor(
        surveyService: SurveyService,
        authService: AuthorisationService,
    ) {
        surveyService.registerLearnMoreUrlForSurveyType(SurveyType.OrganisationDiagnostic, ImplementationKitService.GetArticleLink(ImplementationKitArticle.HealthCheckFeatureHelp));
        OrganisationDiagnosticAuthService.registerAccessVerifiers(authService);

        registerGuidedTour(TourIdentifier.HealthCheckTour, HealthCheckTour);
        registerGuidedTour(TourIdentifier.BullseyeTour, BullseyeTour);
        registerGuidedTour(TourIdentifier.StrategicThemesTour, StrategicThemesTour);
        registerGuidedTour(TourIdentifier.OwnersWhyTour, OwnersWhyTour);
        registerGuidedTour(TourIdentifier.PurposeStatementTour, PurposeStatementTour);
        registerGuidedTour(TourIdentifier.VisionStatementTour, VisionStatementTour);
        registerGuidedTour(TourIdentifier.SetValuesTour, SetValuesTour);
        registerGuidedTour(TourIdentifier.OwnersVisionAlignmentTour, OwnersVisionAlignmentTour);
        registerGuidedTour(TourIdentifier.AttachInputsTour, AttachInputsTour);
        registerGuidedTour(TourIdentifier.RecordAnchorsTour, RecordAnchorsTour);
        registerGuidedTour(TourIdentifier.StrategicAnchorsWrapUpTour, StrategicAnchorsWrapUpTour);
        registerGuidedTour(TourIdentifier.AttachBullseyeStatementsTour, AttachBullseyeStatementsTour);
        registerGuidedTour(TourIdentifier.AddStrategicGoalsTour, AddStrategicGoalsTour);
        registerGuidedTour(TourIdentifier.AltoShellTour, AltoShellTour);
        registerGuidedTour(TourIdentifier.RecordAnnualObjectivesTour, RecordAnnualObjectivesTour);
        registerGuidedTour(TourIdentifier.RecordQuarterlyObjectivesTour, RecordQuarterlyObjectivesTour);
        registerGuidedTour(TourIdentifier.CreateKeyResultsForAnnualObjectiveTour, CreateKeyResultsForAnnualObjectiveTour);
        registerGuidedTour(TourIdentifier.CreateKeyResultsForQuarterlyObjectiveTour, CreateKeyResultsForQuarterlyObjectiveTour);
        registerGuidedTour(TourIdentifier.ObjectivesWrapUpTour, ObjectivesWrapUpTour);
    }
}

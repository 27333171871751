import { DefaultBackTourButton, DefaultFinishButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export const OwnersWhyTour: GuidedTour = [{
    title: "Open the menu",
    text: `<p>This menu allows you to navigate between pages on the platform.
        Your Owners ‘Why’ answers can be accessed from under the <b>Organisation</b> menu section.
        <p><b>Click on here now</b> to open the menu.</p>`,
    attachTo: {
        element: "#sidebar-menu i",
        on: "right",
    },
    advanceOn: {
        selector: "#sidebar-menu",
        event: "click",
    },
    showOn: () => {
        // this step will only be shown if Purpose & Vision node is not there
        const purposeVisionNode = GuidedTourUtils.getFirstElementWithTextContent(
            "adapt-display-link-hierarchy adapt-display-navigation-node span",
            "Purpose & Vision");
        return !GuidedTourUtils.isElementVisible(purposeVisionNode);
    },
}, {
    waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
    elementSelectorTextContent: "Purpose & Vision", // looking for <adapt-display-navigation-node> with span content of this text
    advanceOnAttachedElementEvent: "click",
    attachTo: {
        on: "bottom-end",
    },
    title: `Access purpose & vision`,
    text: `<p>Your organisation's Owners ‘Why’ answers are available here.</p>
        <p><b>Click on it now</b> to show it.</p>`,
    modalOverlayOpeningPadding: 3,
}, {
    title: "See owners ‘why’ answers",
    text: "Here are the answers you just provided.",
    canClickTarget: false,
    waitForAndAttachToElementSelector: `[data-tour="owners-why-card"]`,
    attachTo: {
        on: "bottom",
    },
    buttons: DefaultNextButtons,
}, {
    title: "Edit owners ‘why’ answers",
    text: "In the future, you can edit your Owners ‘Why’ answers using this option, which will run you through the pathway again.",
    canClickTarget: false,
    waitForAndAttachToElementSelector: `[data-tour="owners-why-edit"]`,
    attachTo: {
        on: "bottom",
    },
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Continue",
    text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
    waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
    attachTo: {
        on: "right",
    },
    buttons: [DefaultBackTourButton, DefaultFinishButtons[0]],
}];

<adapt-dashboard-element *adaptIfAlto="false"
                         name="{{title}}"
                         [url]="url$ | async">
    <ng-container [ngTemplateOutlet]="cardContent"></ng-container>
</adapt-dashboard-element>

<adapt-dashboard-element-hq *adaptIfAlto="true"
                            [name]="title"
                            [url]="url$ | async"
                            linkText="View {{linkText?.toLowerCase() ?? title}}"
                            [contentCard]="true">
    <ng-container [ngTemplateOutlet]="cardContent"></ng-container>
</adapt-dashboard-element-hq>

<ng-template #cardContent>
    <div *adaptLoadingUntilFirstEmit="let content of content$">
        <div *ngIf="content; else noContent">
            <p [froalaView]="content"></p>
        </div>
        <ng-template #noContent>
            <adapt-call-to-action-inline [featureUrl]="url$ | async">
                {{blurb}}
            </adapt-call-to-action-inline>
        </ng-template>
    </div>
</ng-template>

export enum TourIdentifier {
    HealthCheckTour = "HealthCheckTour",
    BullseyeTour = "BullseyeTour",
    StrategicThemesTour = "StrategicThemesTour",
    OwnersWhyTour = "OwnersWhyTour",
    PurposeStatementTour = "PurposeStatementTour",
    VisionStatementTour = "VisionStatementTour",
    SetValuesTour = "SetValuesTour",
    OwnersVisionAlignmentTour = "OwnersVisionAlignmentTour",
    AttachInputsTour = "AttachInputsTour",
    AttachBullseyeStatementsTour = "AttachBullseyeStatementsTour",
    AddStrategicGoalsTour = "AddStrategicGoalsTour",
    AltoShellTour = "AltoShellTour",
    RecordAnchorsTour = "AttachAnchorsTour",
    StrategicAnchorsWrapUpTour = "StrategicAnchorsWrapUpTour",
    RecordAnnualObjectivesTour = "RecordAnnualObjectivesTour",
    RecordQuarterlyObjectivesTour = "RecordQuarterlyObjectivesTour",
    CreateKeyResultsForAnnualObjectiveTour = "CreateKeyResultsForAnnualObjectiveTour",
    CreateKeyResultsForQuarterlyObjectiveTour = "CreateKeyResultsForQuarterlyObjectiveTour",
    ObjectivesWrapUpTour = "SetFirstObjectivesWrapUpTour",
}

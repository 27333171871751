import { Type } from "@angular/core";
import { Route } from "@angular/router";
import { Logger } from "@common/lib/logger/logger";
import { ILogger } from "@common/lib/logger/logger.interface";
import { Observable } from "rxjs";
import { AuthenticatedGuard } from "./authenticated.guard";
import { RedirectionGuard } from "./redirection.guard";
import { UnauthenticatedGuard } from "./unauthenticated.guard";

export enum ActivationRequirement {
    Unauthenticated,
    Authenticated,
    Coach,
}
export type UrlParamGetter = Observable<string>;
export interface IRouteData {
    url: string;
    title: string;
    id: string;
    help: string;
    searchKeywords: string[];
    paramGetters: Record<string, Observable<string | undefined>>;
    redirectTo: string;
    accessVerifier: string;
    enableNonNavigationNodeSearch: boolean;
    reloadOnSearch: boolean;
}

export class AdaptRouteBuilder {
    public redirectedRoutes: Route[] = [];
    protected route: Route;

    protected activationRequirements: { [key: number]: boolean };

    protected log: ILogger;

    public constructor() {
        this.route = {};
        this.route.pathMatch = "full";
        this.route.data = {
            reloadOnSearch: true,
        };
        this.route.canActivate = [];

        this.activationRequirements = {};
        this.activationRequirements[ActivationRequirement.Authenticated] = true;

        this.log = Logger.getLogger(this.constructor.name);
    }

    /* Parameter Methods */
    public setPath(path: string) {
        path = this.stripLeadingSlash(path);
        this.route.path = path;
        this.routeData.url = this.addPrecedingSlashIfRequired(path);

        return this;
    }

    public get url() {
        return this.routeData.url;
    }

    public reloadOnSearch(reload = true) {
        // This will be checked to filter out emitting NavigationEnd events typically in meeting and kanban
        // where we do not want a full refresh when selecting items within the page which will change
        // search param (that will always emit route event - as url changes)
        this.routeData.reloadOnSearch = reload;
    }

    public setComponent(component: Type<unknown>) {
        this.route.component = component;

        return this;
    }

    public get component() {
        return this.route.component;
    }

    public setTitle(title: string) {
        this.routeData.title = title;

        return this;
    }

    public addUrlParamGetter(paramName: string, getter: Observable<string | undefined>) {
        if (!this.routeData.paramGetters) {
            this.routeData.paramGetters = {};
        }

        this.routeData.paramGetters[paramName] = getter;
    }

    public setId(id: string) {
        this.routeData.id = id;

        return this;
    }

    public get Id() {
        return this.routeData.id;
    }

    public setHelp(help: string) {
        this.routeData.help = help;
    }

    public requiresUnauthentication(value: boolean = true) {
        this.activationRequirements[ActivationRequirement.Unauthenticated] = value;

        return this;
    }

    public requiresAuthentication(value: boolean = true) {
        this.activationRequirements[ActivationRequirement.Authenticated] = value;

        return this;
    }

    public withSearchKeyword(keyword: string) {
        let keywords: string[] = this.routeData.searchKeywords;
        if (!keywords || !Array.isArray(keywords)) {
            keywords = [];
            this.routeData.searchKeywords = keywords;
        }

        if (!keywords.find((k) => k.toLowerCase() === keyword.toLocaleLowerCase())) {
            keywords.push(keyword);
        }

        return this;
    }

    public enableNonNavigationNodeSearch() {
        this.routeData.enableNonNavigationNodeSearch = true;
        return this;
    }

    public redirectFromUrl(fromUrl: string) {
        const redirectedRoute = new AdaptRouteBuilder()
            .setComponent(this.component!) // will get error without setting component as we are not using route.redirectTo with the reason below
            .setPath(fromUrl)
            .setRedirectTo(this.route.path!)
            // inherit authentication requirement from the route this is forwarding to
            .requiresAuthentication(this.activationRequirements[ActivationRequirement.Authenticated])
            .build();
        redirectedRoute.data!.paramGetters = this.routeData.paramGetters;
        this.redirectedRoutes.push(redirectedRoute);
    }

    protected setRedirectTo(url: string) {
        if (this.route.redirectTo) {
            throw new Error(`This route is already redirected to ${this.route.redirectTo}; requested redirection to ${url} discarded. Developer's error!`);
        }

        // this.route.redirectTo = url;
        // Cannot use redirectTo here as we are getting NavigationError right after NavigationStart
        // - not even going to hit guard yet (that's after resolve start/end and guard check start)
        // - so using data to set the redirection and handle that in RedirectionGuard
        this.routeData.redirectTo = url;
        this.route.canActivate!.push(RedirectionGuard);

        return this;
    }

    public get routeData(): IRouteData {
        return this.route.data as IRouteData;
    }

    /* Builder Methods */
    public build() {
        this.validateRoute();
        this.setActivationRequirements();

        if (this.route.canActivate?.length === 0) {
            this.route.canActivate = undefined;
        }

        return this.route;
    }

    protected validateRoute() {
        if (this.route.path === undefined) {
            throw new Error("Route path cannot be undefined");
        }

        if (this.route.component === undefined && this.route.redirectTo === undefined && !this.routeData?.redirectTo) {
            throw new Error("Route component and redirectTo cannot be undefined");
        }
    }

    protected setActivationRequirements() {
        if (this.activationRequirements[ActivationRequirement.Unauthenticated]) {
            this.route.canActivate!.push(UnauthenticatedGuard);
        }

        if (this.activationRequirements[ActivationRequirement.Authenticated]) {
            this.route.canActivate!.push(AuthenticatedGuard);
        }
    }

    /* Utility Methods */
    protected stripLeadingSlash(value: string) {
        if (value?.startsWith("/")) {
            value = value.substr(1);
        }

        return value;
    }

    protected addPrecedingSlashIfRequired(partialUrl: string) {
        if (partialUrl.length && partialUrl[0] !== "/") {
            return "/" + partialUrl;
        }

        return partialUrl;
    }
}

<adapt-configuration-page-base [configGroups]="configGroups"
                               configTitle="Organisation Configuration"
                               (selectedItemChange)="selectedItem = $event">
    <!-- system -->
    <adapt-configure-organisation-profile *ngIf="selectedItem?.area === ConfigArea.OrganisationProfile"
                                          [configItem]="selectedItem"></adapt-configure-organisation-profile>
    <adapt-configure-billing *ngIf="selectedItem?.area === ConfigArea.Billing"
                             [configItem]="selectedItem">
    </adapt-configure-billing>
    <adapt-configure-calendar-integration *ngIf="selectedItem?.area === ConfigArea.CalendarIntegration"
                                          [configItem]="selectedItem"></adapt-configure-calendar-integration>
    <!-- <adapt-configure-feature-flags *ngIf="selectedItem?.area === ConfigArea.FeatureFlags"
                                   [configItem]="selectedItem"></adapt-configure-feature-flags> -->
    <adapt-configure-pathway-completion *ngIf="selectedItem?.area === ConfigArea.PathwayCompletion"
                                        [configItem]="selectedItem"></adapt-configure-pathway-completion>

    <!-- organisation-->
    <adapt-configure-labels *ngIf="selectedItem?.area === ConfigArea.Labels"
                            [configItem]="selectedItem"></adapt-configure-labels>
    <adapt-configure-personal-profile *ngIf="selectedItem?.area === ConfigArea.PersonalProfileCategories"
                                      [configItem]="selectedItem"></adapt-configure-personal-profile>

    <!-- strategy -->
    <adapt-configure-purpose-and-vision *ngIf="selectedItem?.area === ConfigArea.PurposeAndVision"
                                        [configItem]="selectedItem"></adapt-configure-purpose-and-vision>
    <adapt-configure-values-constitution *ngIf="selectedItem?.area === ConfigArea.ValuesConstitution"
                                         [configItem]="selectedItem"></adapt-configure-values-constitution>

    <ng-container *ngIf="hasGenericConfig(selectedItem?.area)">
        <adapt-feature-guidance [configItem]="selectedItem">
        </adapt-feature-guidance>
        <adapt-feature-configuration [configItem]="selectedItem"></adapt-feature-configuration>
    </ng-container>

    <adapt-configure-objectives *ngIf="selectedItem?.area === ConfigArea.ObjectivesAndKeyResults"
                                [configItem]="selectedItem"></adapt-configure-objectives>
    <!-- teams -->
    <adapt-configure-teams *ngIf="selectedItem?.area === ConfigArea.Team"
                           [configItem]="selectedItem"></adapt-configure-teams>
    <adapt-configure-work *ngIf="selectedItem?.area === ConfigArea.Kanban"
                          [configItem]="selectedItem"></adapt-configure-work>
</adapt-configuration-page-base>

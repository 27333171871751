import { Component, Inject, Injector } from "@angular/core";
import { KeyResult } from "@common/ADAPT.Common.Model/organisation/key-result";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ObjectivesService } from "../objectives.service";

@PersistableDialog("EditKeyResultDialogComponent")
@Component({
    selector: "adapt-edit-key-result-dialog",
    templateUrl: "./edit-key-result-dialog.component.html",
    styleUrls: ["./edit-key-result-dialog.component.scss"],
})
export class EditKeyResultDialogComponent extends BaseDialogWithDiscardConfirmationComponent<KeyResult> {
    public readonly dialogName = "EditKeyResult";

    public constructor(
        private objectivesService: ObjectivesService,
        @Inject(ADAPT_DIALOG_DATA) public keyResult: KeyResult,
        injector: Injector,
        private entityPersistentService: EntityPersistentService,
    ) {
        super(injector);
    }

    public get entitiesToConfirm() {
        return [this.keyResult];
    }

    public get hasUnsavedEntity() {
        return this.entityPersistentService.isUnsavedEntity(this.keyResult);
    }

    public get isNew() {
        return this.keyResult.entityAspect.entityState.isAdded();
    }

    public get hasValue() {
        return this.keyResult.values.length > 0;
    }

    public get hasValidationErrors() {
        return this.keyResult.entityAspect.hasValidationErrors;
    }

    @Autobind
    public save() {
        if (this.isNew && this.keyResult.targetValue === 100 && !this.keyResult.targetValueSuffix && !this.keyResult.targetValuePrefix) {
            this.keyResult.targetValueSuffix = "%";
        }

        return this.objectivesService.saveEntities(this.keyResult)
            .pipe(
                tap(() => this.resolve(this.keyResult)),
                catchError((e) => {
                    this.setErrorMessage(e);
                    return throwError(() => e);
                }),
            );
    }
}

<div *adaptIfAlto="true"
     class="d-flex pt-5 w-100 flex-column align-items-center h-100 justify-content-center">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</div>
<adapt-page-with-splash-image *adaptIfAlto="false">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</adapt-page-with-splash-image>

<ng-template #pageContent>
    <img src="/content/shell/images/adaptplatformlogo.svg"
         width="140"
         class="mb-4" />

    <div class="row w-100 justify-content-center">
        <div *adaptIfAlto="true"
             class="col-12 col-sm-9 col-lg-6 col-xl-3">
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
        </div>
        <div *adaptIfAlto="false">
            <h1>Reset Password</h1>
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
        </div>
    </div>

    <ng-template #formContent>
        <p [ngSwitch]="emailProvided">
            <ng-container *ngSwitchCase="true">
                Please enter your new password for the account <b>"{{email}}"</b>.
            </ng-container>
            <ng-container *ngSwitchDefault>
                Please enter your login email address and your new password.
            </ng-container>
        </p>

        <form (ngSubmit)="submit()">
            <dx-validation-group #validationGroup>
                <div *ngIf="!emailProvided"
                     class="form-group">
                    <label class="visually-hidden"
                           for="emailInput">Email</label>

                    <dx-text-box mode="email"
                                 id="emailInput"
                                 [(value)]="email"
                                 (onValueChanged)="validateGroup()"
                                 placeholder="Email address"
                                 data-test="email-input">
                        <dx-validator>
                            <dxi-validation-rule type="required"
                                                 message="Email is required"></dxi-validation-rule>
                            <dxi-validation-rule type="email"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>

                <div class="form-group">
                    <label class="visually-hidden"
                           for="passwordInput">Password</label>
                    <dx-text-box mode="password"
                                 id="passwordInput"
                                 name="passwordInput"
                                 [(value)]="password"
                                 (onValueChanged)="validateGroup()"
                                 placeholder="Password"
                                 data-test="password-input">
                        <dx-validator>
                            <dxi-validation-rule type="required"
                                                 message="Password is required"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength"
                                                 [min]="5"
                                                 message="Password must be at least five characters"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>

                <div class="form-group">
                    <label class="visually-hidden"
                           for="confirmPasswordInput">Confirm Password</label>
                    <dx-text-box mode="password"
                                 id="confirmPasswordInput"
                                 name="confirmPasswordInput"
                                 [(value)]="confirmPassword"
                                 (onValueChanged)="validateGroup()"
                                 placeholder="Confirm password"
                                 data-test="confirm-password-input">
                        <dx-validator>
                            <dxi-validation-rule type="compare"
                                                 [comparisonTarget]="passwordComparison"
                                                 message="Passwords do not match"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>

                <button [adaptBlockingClick]="submit"
                        [unsubscribeOnDestroy]="false"
                        [disabled]="isDisabled || isProcessing"
                        class="btn btn-primary w-100"
                        data-test="submit-button">Reset Password
                </button>
            </dx-validation-group>
        </form>

        <div *ngIf="submitMessage"
             role="alert"
             class="alert alert-danger my-3">{{submitMessage}}
        </div>
    </ng-template>
</ng-template>

<ng-container *adaptLoading="!isInitialised">
    <div class="d-flex flex-column flex-lg-row">
        <div class="d-flex flex-column left-column">
            <adapt-strategic-guidance [articleId]="stepData?.guidanceArticleSlug"
                                      area="bullseye"></adapt-strategic-guidance>
        </div>

        <!-- h-100 and justify-content-center is not working to center this element as in the mock up as we need to set h-100 all the
            way up to dx-scrollable-content (including all parents) - unless we want to set a fixed value. Remove it for now - doesn't
            look good when centralised anyway -->
        <div class="d-flex flex-column flex-fill">
            <h4>{{quadrantQuestion}}</h4>
            <div *ngFor="let statement of statements"
                 class="mt-2 w-100 d-flex">
                <dx-text-box [(value)]="statement.statement"
                             (valueChange)="triggerValidationCheck()"
                             [maxLength]="MaxStatementLength"
                             class="flex-fill"
                             data-test="bullseye-statement-input"></dx-text-box>
                <button adaptButton="deleteBorderless"
                        class="ms-2"
                        adaptTooltip="Remove this statement"
                        (click)="deleteStatement(statement)"
                        data-test="bullseye-statement-delete"></button>
            </div>
            <div>
                <button adaptButton="addLink"
                        class="mt-2"
                        adaptTooltip="Add another statement"
                        (click)="addStatement()"
                        data-test="bullseye-statement-add"></button>
            </div>
        </div>
    </div>
</ng-container>

<adapt-edit-strategic-goal-base [goal]="goal"
                                [guidanceArticleId]="GuidanceArticleId">
    <h3>{{goal?.name}}</h3>

    <label class="mt-3">Description</label>
    <adapt-html-editor [(contents)]="goal!.description"
                       forceSize="compact"
                       [minHeight]="240"></adapt-html-editor>

    <div class="d-flex mt-2"
         adaptBlockingButtonGroup>
        <button class="ms-1"
                adaptButton="wizardLink"
                [adaptBlockingClick]="generateGoalDescription"
                adaptTooltip="Using the goal name, where you are at and what you try to achieve, use the adapt AI engine to generate a goal description">
            Generate goal description
        </button>
        <button class="ms-3"
                adaptButton="refreshLink"
                [adaptBlockingClick]="paraphrase"
                adaptTooltip="Using the statement set above, use the adapt AI engine to paraphrase your existing goal description">
            Paraphrase the goal description
        </button>
    </div>
</adapt-edit-strategic-goal-base>

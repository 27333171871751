import { AfterViewInit, Component, Input } from "@angular/core";
import { GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { RouteService } from "@common/route/route.service";
import { ReturnPathTour } from "./return-path-tour";

@Component({
    selector: "adapt-return-path-button",
    templateUrl: "./return-path-button.component.html",
})
export class ReturnPathButtonComponent implements AfterViewInit {
    private readonly returnPathTourCountKey = "returnPathCount";
    private readonly maxTourCount = 1;

    @Input() public returnPath?: string;
    @Input() public buttonText = "Return";

    public constructor(
        private routeService: RouteService,
        private guidedTourService: GuidedTourService,
    ) { }

    public ngAfterViewInit() {
        const currentTourCount = this.getReturnPathTourCount();
        if (currentTourCount < this.maxTourCount) {
            this.updateReturnPathTourCount();
            this.guidedTourService.run(ReturnPathTour);
        }
    }

    private getReturnPathTourCount() {
        const rawStoredTourCount = LocalStorage.get<number>(this.returnPathTourCountKey);
        return typeof (rawStoredTourCount) === "number" ? rawStoredTourCount : 0;
    }

    private updateReturnPathTourCount() {
        const numStoredTourCount = this.getReturnPathTourCount();
        const currentTourCount = numStoredTourCount + 1;
        LocalStorage.set(this.returnPathTourCountKey, currentTourCount);
        return currentTourCount;
    }

    public return() {
        this.routeService.navigateByUrl(this.returnPath!);
    }
}

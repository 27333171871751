import { Component } from "@angular/core";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ValuesConstitutionService } from "@org-common/lib/values-constitution/values-constitution.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import { ICreateValueData } from "../create-value-workflow";

@WorkflowStepComponent("adapt-create-value-review")
@Component({
    selector: "adapt-create-value-review",
    templateUrl: "./create-value-review.component.html",
    styleUrls: ["./create-value-review.component.scss"],
})
export class CreateValueReviewComponent extends WorkflowStepComponentAdapter {
    public data?: ICreateValueData;

    public workflowStepCompleted = new BehaviorSubject<boolean>(false);
    public workflowGoToStep = new BehaviorSubject<number | undefined>(undefined);

    public constructor(
        private commonDataService: CommonDataService,
        private vcService: ValuesConstitutionService,
    ) {
        super();
    }

    public get entitiesToConfirm(): IBreezeEntity[] {
        if (!this.data || !this.data.value || !this.data.valuesConstitution) {
            return [];
        }

        return [this.data.valuesConstitution, this.data.value, ...this.data.value.valueQuestions];
    }

    public workflowStepNext() {
        return this.commonDataService.saveEntities(this.entitiesToConfirm).pipe(
            tap(() => {
                if (this.data?.value) {
                    this.vcService.valueAdded$.next(this.data.value);
                }
            }),
        );
    }

    public async workflowStepOnInit() {
        this.data = this.workflowStep?.workflow.runData;
        if (!this.data) {
            throw new Error("Workflow data is not available");
        }

        this.validateEntities();
    }

    public validateEntities() {
        if (this.data?.value) {
            const entities = this.entitiesToConfirm;
            entities.forEach((e) => this.emitEntityChange(e));
            this.workflowStepCompleted.next(entities.every((e) => e.entityAspect.validateEntity()));
            return;
        }
        this.workflowStepCompleted.next(false);
    }

    public navigateToStep(stepIndex: number) {
        this.workflowGoToStep.next(stepIndex);
    }
}

import { Component, ElementRef, OnInit } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsUiService } from "@org-common/lib/teams/common-teams-ui.service";

@MeetingAgendaItemComponent("adapt-meeting-behaviours-agenda-item")
@Component({
    selector: "adapt-meeting-behaviours-agenda-item",
    templateUrl: "./meeting-behaviours-agenda-item.component.html",
})
export class MeetingBehavioursAgendaItemComponent extends BaseComponent implements OnInit {
    public team?: Team;
    public readonly StewardshipWorkKanbanEdit = KanbanAuthService.EditAllStewardship;

    public constructor(
        elementRef: ElementRef,
        private commonTeamsService: CommonTeamsService,
        private commonTeamsUiService: CommonTeamsUiService,
    ) {
        super(elementRef);
    }

    public async ngOnInit() {
        this.team = await this.commonTeamsService.promiseToGetLeadershipTeam();
    }

    @Autobind
    public showGuidance() {
        return this.commonTeamsUiService.configureTeamStewardship(this.team!);
    }
}

import { IActiveEntity } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { BaseEntity } from "./base-entity";

export abstract class BaseActiveEntity<T> extends BaseEntity<T> implements IActiveEntity {
    public startDate!: Date;
    public endDate?: Date;

    public isActive = () => ActiveEntityUtilities.isActive(this);
    public isActiveAt = (date: Date) => ActiveEntityUtilities.isActiveAt(this, date);
}

import { ErrorHandler, Injectable } from "@angular/core";
import { Autobind } from "../autobind.decorator/autobind.decorator";
import { Logger } from "../logger/logger";
import { AdaptError } from "./adapt-error";

type Throwable = string | Error | AdaptError;

@Injectable()
export class AdaptErrorHandler implements ErrorHandler {
    private readonly log = Logger.getLogger("app");

    // Cause not required for Angular error handler, but AngularJs is shimmed to use this as well
    @Autobind
    public handleError(exception: Throwable, cause?: string): void {
        let error;

        // Handle Zone.js wrapped error: https://github.com/angular/angular/issues/27840
        // Should be able to be removed once issue fixed.
        if (this.isZoneJsWrappedError(exception)) {
            exception = exception.rejection;
        }

        if (exception instanceof AdaptError) {
            error = exception;
        } else if (exception instanceof Error) {
            error = AdaptError.fromError(exception);
        } else {
            error = new AdaptError(exception);
        }

        error.cause = cause;

        if (error.shouldBeLogged) {
            this.log.error(error.message, error);
        }
    }

    private isZoneJsWrappedError(error: any): error is { rejection: Throwable } {
        return error instanceof Object && !!error.rejection;
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { IConfigGroup } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";

export const allTeamConfigGroups = (): IConfigGroup[] => [
    {
        key: "Team",
        items: [
            {
                name: "Profile",
                area: ConfigArea.TeamProfile,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                supportArticleId: ImplementationKitArticle.TeamProfileOverview,
                supportText: "Find out more about configuring your team profile in adapt HQ",
            },
            {
                name: "People",
                area: ConfigArea.People,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                supportArticleId: ImplementationKitArticle.TeamPeopleOverview,
                supportText: "Find out more about configuring the people in the team in adapt HQ",
            },
            // {
            //     name: "External Dashboards",
            //     area: ConfigArea.ExternalDashboard,
            //     accessVerifier: CommonTeamsAuthService.ConfigureTeam,
            //     featureName: FeatureName.OrganisationExternalDashboard,
            // },
            // {
            //     name: "Objectives & Key Results",
            //     area: ConfigArea.ObjectivesAndKeyResults,
            //     accessVerifier: CommonTeamsAuthService.ConfigureTeam,
            //     featureName: FeatureName.StewardshipObjectives,
            //     supportArticleId: ImplementationKitArticle.ObjectivesOverview,
            //     supportText: "Find out more about objectives in adapt HQ",
            // },
            // {
            //     name: "Values",
            //     area: ConfigArea.ValuesConstitution,
            //     accessVerifier: CommonTeamsAuthService.ConfigureTeam,
            //     featureName: FeatureName.CompassValuesConstitution,
            //     supportArticleId: ImplementationKitArticle.ValuesOverview,
            //     supportText: "Find out more about values in adapt HQ",
            // },
            {
                name: "Actions",
                area: ConfigArea.Kanban,
                accessVerifier: CommonTeamsAuthService.ConfigureTeam,
                featureName: FeatureName.StewardshipWorkKanban,
                supportArticleId: ImplementationKitArticle.WorkOverview,
                supportText: "Find out more about using the team action boards in adapt HQ",
            },
            // {
            //     name: "Team Assessment",
            //     area: ConfigArea.TeamAssessment,
            //     accessVerifier: CommonTeamsAuthService.ConfigureTeam,
            //     featureName: FeatureName.CulturalNetworkTeamAssessment,
            //     supportUrl: SupportUrl.TeamAssessment,
            //     supportText: "Find out more about team assessment in adapt HQ",
            // },
            {
                name: "Access Permissions",
                area: ConfigArea.AccessPermissions,
                accessVerifier: CommonTeamsAuthService.ViewTeamAccessPermissions,
            },
        ],
    },
];

import { Component, Inject, Input, OnInit } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { TEAM_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-search-row-team",
    templateUrl: "./search-row-team.component.html",
    styleUrls: ["./search-row-team.component.scss"],
})
export class SearchRowTeamComponent extends SearchRowBaseComponent<Team> implements OnInit {
    @Input() public showLabels = true;

    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
        @Inject(TEAM_DASHBOARD_PAGE) private teamDashboardPageRoute: IAdaptRoute<{ teamId: number }>,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = this.teamDashboardPageRoute.getRouteObject({ teamId: this.result.teamId }).pipe(
            emptyIfUndefinedOrNull(),
        );
    }
}

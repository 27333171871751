<ng-container *ngIf="seedAnswers.length > 0; else noSeedAnswers">
    <ng-container *ngFor="let seedAnswer of seedAnswers; let last = last">
        <strong>{{seedAnswer.question}}</strong>
        <div [froalaView]="seedAnswer.answer"
             [ngClass]="{'mb-3': !last}"></div>
    </ng-container>
</ng-container>
<ng-template #noSeedAnswers>
    <p>No answers have been given.</p>
</ng-template>

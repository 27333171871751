import { Component, ComponentRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaItemSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item-supplementary-data";
import { MeetingItem } from "@common/ADAPT.Common.Model/organisation/meeting-item";
import { MeetingNote, MeetingNoteType } from "@common/ADAPT.Common.Model/organisation/meeting-note";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { forkJoin, merge, of, Subject } from "rxjs";
import { debounceTime, filter, finalize, switchMap, tap } from "rxjs/operators";
import { IMeetingAgendaItemComponent, MeetingAgendaItemComponentRegistry } from "../meeting-agenda-component-registry";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";

@Component({
    selector: "adapt-edit-agenda-item",
    templateUrl: "./edit-agenda-item.component.html",
    styleUrls: ["./edit-agenda-item.component.scss"],
})
export class EditAgendaItemComponent extends BaseComponent implements OnChanges {
    @Input() public item!: MeetingAgendaItem;
    @Output() public itemDelete = new EventEmitter<MeetingAgendaItem>();
    @Output() public itemChange = new EventEmitter<MeetingAgendaItem>();
    @Input() public readonly = false;
    @Input() public expandOnInit = false;
    @Input() public hideProgressIcon = false;
    @Input() public hideNotesAndItems = false;
    @Input() public saveOnChange = true;
    @Output() public expandDetailsChange = new EventEmitter<boolean>();
    @Output() public canExpandChange = new EventEmitter<boolean>();

    @ViewChild("container", { read: ViewContainerRef })
    public set content(element: ViewContainerRef) {
        this.checkLoadComponentSelectorComponent(element);
    }

    public expandDetails = false;
    public agendaNotes: MeetingNote[] = [];
    public agendaActions: MeetingItem[] = [];
    public canExpand = false;

    public progressColor = "not-started";
    private progressColorUpdater = this.createThrottledUpdater<string>((color) => this.progressColor = color);
    private currentCustomComponent?: ComponentRef<IMeetingAgendaItemComponent>;

    private triggerUpdate$ = new Subject<void>();

    constructor(
        private commonDialogService: AdaptCommonDialogService,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.triggerUpdate$.pipe(
            filter(() => !!this.item),
            debounceTime(10),
            // won't query if not going to show notes and items
            switchMap(() => this.hideNotesAndItems
                ? of([[], []] as [MeetingNote[], MeetingItem[]])
                : forkJoin([
                    this.meetingsService.getMeetingNotesForAgendaItem(this.item),
                    this.meetingsService.getMeetingItemsForAgendaItem(this.item),
                ])),
            this.takeUntilDestroyed(),
        ).subscribe(([agendaNotes, agendaActions]) => {
            this.progressColorUpdater.next(this.getProgressColor());

            this.agendaNotes = agendaNotes;
            this.agendaActions = agendaActions;
            this.updateCanExpand();
            if (!this.expandDetails) {
                // only do this if not already expanded - otherwise keep whatever user has set
                this.expandDetails = this.canExpand && this.expandOnInit;
                this.expandDetailsChange.emit(this.expandDetails);
            }
        });

        merge(
            rxjsBreezeService.entityTypeChanged(MeetingAgendaItem),
            rxjsBreezeService.entityTypeChanged(MeetingAgendaItemSupplementaryData),
        ).pipe(
            filter((itemOrSuppData) => itemOrSuppData.meetingAgendaItemId === this.item.meetingAgendaItemId),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());

        rxjsBreezeService.entityTypeChanged(MeetingNote).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((note) => {
            if (note.meetingAgendaItemId === this.item.meetingAgendaItemId) {
                this.triggerUpdate$.next();
            }
        });

        rxjsBreezeService.entityTypeChanged(MeetingItem).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((meetingItem) => {
            if (meetingItem.meetingAgendaItemId === this.item.meetingAgendaItemId) {
                this.triggerUpdate$.next();
            }
        });
    }

    public get isMeetingInProgress() {
        return this.item.meeting?.extensions.isInProgress;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.item && this.item) {
            this.triggerUpdate$.next();
        }
    }

    public getProgressColor() {
        if (this.item) {
            if (this.item.extensions.isEnded) {
                return "completed";
            } else if (this.item.extensions.isInProgress) {
                return "in-progress";
            }
        }

        return "not-started";
    }

    @Autobind
    public editItemDetails() {
        this.rxjsBreezeService.entityTypeChangeBlocker = true;
        return this.meetingsUiService.editMeetingAgendaItem(this.item, this.saveOnChange).pipe(
            tap((agendaItem) => this.itemChange.emit(agendaItem)),
            tap(() => this.updateCanExpand()),
            finalize(() => this.rxjsBreezeService.entityTypeChangeBlocker = false),
        );
    }

    @Autobind
    public deleteItem() {
        const dialogData: IConfirmationDialogData = {
            title: "Delete Meeting Agenda Item",
            message: "<p>Are you sure you want to delete this meeting agenda item?</p>",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        };

        this.rxjsBreezeService.entityTypeChangeBlocker = true;
        return forkJoin([
            this.meetingsService.getMeetingNotesForAgendaItem(this.item),
            this.meetingsService.getMeetingItemsForAgendaItem(this.item),
        ]).pipe(
            switchMap(([notes, items]) => {
                const decisionCount = notes.filter((n) => n.type === MeetingNoteType.Decision).length;
                const minuteCount = notes.filter((n) => n.type === MeetingNoteType.Minute).length;
                if (notes.length > 0 || items.length > 0) {
                    dialogData.message += "<p class='mb-1'>The followings will be affected by the deletion:</p><ul>";
                    if (decisionCount > 0) {
                        dialogData.message += `<li>${decisionCount} decision(s) made for this agenda item will deleted</li>`;
                    }

                    if (minuteCount > 0) {
                        dialogData.message += `<li>${minuteCount} minute(s) recorded for this agenda item will be deleted</li>`;
                    }

                    if (items.length > 0) {
                        dialogData.message += `<li>${items.length} work item(s) will be unlinked from the meeting</li>`;
                    }

                    dialogData.message += "</ul>";
                }

                return this.commonDialogService.openConfirmationDialogWithBoolean(dialogData);
            }),
            filter((confirmDelete) => !!confirmDelete),
            tap(() => {
                this.itemDelete.emit(this.item);
            }),
            filter(() => this.saveOnChange),
            // only remove and save if saveOnChange - otherwise container dialog will take care of deleted item
            switchMap(() => this.meetingsService.remove(this.item)),
            switchMap(() => this.meetingsService.saveEntities([this.item])),
            finalize(() => this.rxjsBreezeService.entityTypeChangeBlocker = false),
        );
    }

    public toggleExpand() {
        this.expandDetails = !this.expandDetails;
        this.expandDetailsChange.emit(this.expandDetails);
    }

    private updateCanExpand() {
        this.canExpand = !!this.item.supplementaryData?.itemDescription || ((this.agendaNotes.length > 0 || this.agendaActions.length > 0) && !this.hideNotesAndItems);
        this.canExpandChange.emit(this.canExpand);
    }

    // todo: this should be consolidated into a generic agenda-item component selector since almost identical code is used in the active meeting
    private checkLoadComponentSelectorComponent(container: ViewContainerRef) {
        try {
            if (this.currentCustomComponent) {
                this.currentCustomComponent = undefined;
            }

            if (this.item?.componentSelector && container) {
                const type = MeetingAgendaItemComponentRegistry.get(this.item.componentSelector);
                if (!type) {
                    throw new Error(`component is not registered - ${this.item.componentSelector} - needs to use the MeetingAgendaComponent class decorator`);
                }

                container.clear();
                setTimeout(() => {
                    // need to load this on next digest cycle as this is called right after the template initiated the container
                    // - calling this without timeout will result in ExpressionChangedAfterItHasBeenChecked error.
                    // Note that even variable initialised before constructor will cause the above error as the template is loaded first
                    // - throttledUpdater won't help there.
                    this.currentCustomComponent = container.createComponent<IMeetingAgendaItemComponent>(type);
                    const instance = this.currentCustomComponent.instance;
                    instance.meetingAgendaItem = this.item;

                    if (FunctionUtilities.isFunction(instance.onDataChanged)) {
                        instance.onDataChanged!();
                    }
                });
            }
        } catch {
            // swallow exception temporarily!
        }
    }
}

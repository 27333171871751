<ng-container *ngIf="isTeamViewWithoutBoard; else showContent">
    <adapt-call-to-action [learnMoreUrl]="learnMoreUrl">
        <adapt-call-to-action-title>No boards configured</adapt-call-to-action-title>
        <p>
            Actions are a visual system for managing work as it moves through a process. It
            visualises both the process (the workflow) and the actual work passing through
            that process. The goal is to identify potential bottlenecks in your
            process and fix them so work can flow through it cost-effectively at an optimal
            speed or throughput.
        </p>

        <div *adaptIfAuthorised="ConfigureTeamVerifier; Entity:team">
            <p>You can add a board from 'Configure Team / Actions'</p>
        </div>
        <button *adaptIfAuthorised="ConfigureTeamVerifier; Entity:team"
                adaptCallToActionButton
                adaptButton="configure"
                (click)="configureTeam()">
            Team
        </button>
    </adapt-call-to-action>
</ng-container>
<ng-template #showContent>
    <adapt-two-column-layout [fullPage]="true"
                             [removeColumnPadding]="true"
                             leftColumnWidthClasses="col-12 col-md-6 col-lg-7 col-xl-8 pe-1"
                             rightColumnWidthClasses="col-12 col-md-6 col-lg-5 col-xl-4 box-shadow">
        <!-- The board will only show assignees if is team board -->
        <adapt-board left-column
                     [items]="items"
                     [showAssignees]="!!team"
                     [selectedItem]="selectedItem"
                     (showBacklogClick)="showBacklogColumn($event)"
                     (selectedItemChange)="onSelectedItemChanged($event)"
                     (itemDialogOpened)="itemDialogOpened.emit($event)"
                     (itemDialogClosed)="itemDialogClosed.emit($event)"
                     [showStatuses]="visibleItemStatuses"></adapt-board>
        <adapt-item-preview right-column
                            *ngIf="selectedItem && isMD"
                            [item]="selectedItem"
                            (itemDialogOpened)="itemDialogOpened.emit($event)"
                            (itemDialogClosed)="itemDialogClosed.emit($event)"
                            (closeClicked)="previewClosed = true; onSelectedItemChanged(undefined)"
                            class="d-flex flex-grow-1 h-100"
                            data-test="item-preview"></adapt-item-preview>
    </adapt-two-column-layout>

    <div adaptToolbarContent
         alignment="left"
         class="d-flex align-items-center">
        <ng-container *ngIf="isXL || showLeftToolbarInSmallerScreen">
            <adapt-select-board class="kanban-board-toolbar-selection"
                                [team]="team"
                                [boards]="filterByBoards.length ? filterByBoards : undefined"
                                (boardsChange)="onBoardSelectionChanged($event)"
                                [allowMultiSelection]="true"
                                [showTeamBoards]="!!team"
                                data-test="board-dropdown"></adapt-select-board>
            <div class="kanban-board-toolbar-buttons"
                 *ngIf="hasEditableBoard$ | async">
                <button class="btn-item-add"
                        adaptButton="add"
                        [adaptBlockingClick]="addItem">action</button>
            </div>

            <div class="ms-3 align-self-center btn-link"
                 *ngIf="!isXL"
                 (click)="showLeftToolbarInSmallerScreen = false">
                <i class="fal fa-chevron-right"></i>
            </div>
        </ng-container>
        <ng-container *ngIf="isXL || !showLeftToolbarInSmallerScreen">
            <div class="ms-3 align-self-center btn-link"
                 *ngIf="!isXL"
                 (click)="showLeftToolbarInSmallerScreen = true">
                <i class="fal fa-chevron-left"></i>
            </div>
            <dx-text-box class="ms-3"
                         placeholder="Filter by keyword"
                         mode="search"
                         [width]="150"
                         (valueChange)="onSearchTextValueChanged($event)"
                         data-test="keyword-filter"></dx-text-box>
        </ng-container>
    </div>

    <div class="d-flex align-items-center"
         adaptToolbarContent
         [ordinal]="89">
        <button class="btn btn-default"
                [adaptButtonToggle]="backlogShown"
                (adaptButtonToggleChange)="toggleBacklog()">
            <i class="fal fa-fw"
               [ngClass]="BacklogIconClass"></i>
            Backlog
            <span class="badge bg-primary"
                  *ngIf="showBacklogBadge">{{backlogCount}}</span>
        </button>
    </div>

    <button adaptToolbarContent
            [ordinal]="90"
            adaptButtonIcon="browse"
            [adaptBlockingClick]="searchItem">Search</button>

    <!-- kanban filter will insert a filter button on the toolbar with ordinal 100 -->
    <adapt-kanban-filter [team]="team"
                         [filter]="filter"
                         (filterChange)="onFilterChanged($event)"></adapt-kanban-filter>
</ng-template>

<div class="display-objectives-hierarchy"
     [ngClass]="{'horizontal': isHorizontal, 'vertical': !isHorizontal,'compact': isCompactView}"
     *ngIf="objectiveGroup">
    <svg *ngIf="hasChildren"
         class="overlay-svg">
        <polyline *ngFor="let line of lines"
                  stroke="white"
                  stroke-width="4"
                  fill="none"
                  [attr.points]="line"></polyline>
        <polyline *ngFor="let line of lines"
                  stroke="grey"
                  stroke-width="2"
                  fill="none"
                  [attr.points]="line"></polyline>
    </svg>
    <div class="d-flex flex-column flex-md-row">
        <ng-container *ngIf="needIndent && isHorizontal">
            <div class="objective-card horizontal me-5"
                 [ngClass]="{'compact': isCompactView}"></div>
            <span class="ps-2 pe-1 ms-0 ms-md-2"></span> <!-- This will match the <i> for the expand/collapse icon -->
        </ng-container>
        <adapt-display-tree-objective class="objective-card"
                                      data-test="objective-tree-item"
                                      [ngClass]="{'horizontal': isHorizontal, 'vertical': !isHorizontal,'compact': isCompactView}"
                                      [objective]="objective"
                                      [isExternalObjective]="isExternalObjective"
                                      [isCompactView]="isCompactView"
                                      (heightChange)="childHeightChanged()"></adapt-display-tree-objective>
        <i *ngIf="hasChildren"
           class="expand-collapse-icon fal ms-0 ms-md-2 mt-1 mt-md-0"
           [ngClass]="showChildren ? 'fa-minus-square' : 'fa-plus-square'"
           (click)="toggleShowChildren()"></i>
        <div *ngIf="hasChildren && showChildren"
             class="d-flex flex-column">
            <adapt-display-objectives-hierarchy *ngFor="let childGroup of objectiveGroup.childGroups; let first=first"
                                                class="ms-5 pe-3"
                                                [currentTeamId]="currentTeamId"
                                                [ngClass]="{'mt-3': !first}"
                                                (heightChange)="childHeightChanged()"
                                                [objectiveGroup]="childGroup"
                                                [isCompactView]="isCompactView">
            </adapt-display-objectives-hierarchy>
        </div>
    </div>
</div>

import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { RoleInOrganisationLabel } from "@common/ADAPT.Common.Model/organisation/connection";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom, merge } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { OrganisationOutcomes } from "../organisation-outcomes";
import { WorkflowActionableStates, WorkflowActionState } from "../workflow.interface";
import { WorkflowService } from "../workflow.service";
import { WorkflowAuthService } from "../workflow-auth.service";

@Component({
    selector: "adapt-workflow-card-action",
    templateUrl: "./workflow-card-action.component.html",
    styleUrls: ["./workflow-card-action.component.scss"],
})
export class WorkflowCardActionComponent extends BaseComponent implements OnChanges {
    @Input() public connection?: WorkflowConnection;
    @Input() public workflow?: Workflow;

    @Input() public disabled = false;
    @Input() public disabledTooltip?: string;

    // Action function to call when action is clicked.
    // Receives an event which allows you to call preventDefault on it to skip the default action (start/continue pathway)
    @Input() public action?: (event: CustomEvent) => Promise<any>;
    @Input() public actionText?: string;
    @Input() public actionIcon?: string;

    @Output() public workflowState = new EventEmitter<WorkflowActionState>();

    public readonly WorkflowStatuses = WorkflowStatusEnum;
    public readonly WorkflowState = WorkflowActionState;
    public readonly RoleInOrganisationLabel = RoleInOrganisationLabel;

    public loading = true;

    public categoryMetadata = OrganisationOutcomes.Others;
    public compulsoryPrerequisiteWorkflows: Workflow[] = [];

    private currentStatus?: WorkflowStatusEnum;
    private hasWorkflowAccess = true;
    private prerequisitesSatisfied = false;
    private canStartWorkflow = false;
    private canContinueWorkflow = false;

    public constructor(
        private authorisationService: AuthorisationService,
        private workflowService: WorkflowService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        merge(
            authorisationService.getHasAccess(WorkflowAuthService.EditWorkflow),
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateData());
    }

    public get state(): WorkflowActionState {
        if (!this.hasWorkflowAccess) {
            return WorkflowActionState.NoPermission;
        }

        if (!this.workflow || this.disabled) {
            return WorkflowActionState.Disabled;
        }

        if (this.workflow.extensions.isComingSoon) {
            return WorkflowActionState.ComingSoon;
        } else if (this.hasRequiredWorkflows && !this.prerequisitesSatisfied) {
            return WorkflowActionState.PrerequisitesNotSatisfied;
        } else if (this.currentStatus === WorkflowStatusEnum.Current && !this.canContinueWorkflow) {
            return this.workflowService.currentPersonHasRequiredRoles(this.workflow)
                ? WorkflowActionState.NotInStartedTeam
                : WorkflowActionState.MissingRoles;
        } else if (this.currentStatus === WorkflowStatusEnum.Incomplete && !this.canStartWorkflow) {
            return this.workflowService.currentPersonHasRequiredRoles(this.workflow)
                ? WorkflowActionState.NotInLeadershipTeam
                : WorkflowActionState.MissingRoles;
        } else if (this.currentStatus === WorkflowStatusEnum.Completed && this.canStartWorkflow) {
            return WorkflowActionState.Completed;
        }

        return this.currentStatus === WorkflowStatusEnum.Current
            ? WorkflowActionState.Current
            : WorkflowActionState.NotStarted;
    }

    public get startWorkflowButtonText() {
        switch (this.state) {
            case WorkflowActionState.NoPermission:
                return "No access";
            case WorkflowActionState.PrerequisitesNotSatisfied:
                return "Finish pathways to unlock";
            case WorkflowActionState.ComingSoon:
                return "Coming soon!";
            case WorkflowActionState.Completed:
                return "Completed";
            default:
                if (this.actionText) {
                    return this.actionText;
                }
                return this.state === WorkflowActionState.Current
                    ? "Continue Pathway"
                    : "Start Pathway";
        }
    }

    public get startWorkflowButtonIcon() {
        if (this.loading) {
            return undefined;
        }

        switch (this.state) {
            case WorkflowActionState.Completed:
                return this.actionIcon ?? "fal fa-fw fa-check";
            case WorkflowActionState.Current:
            case WorkflowActionState.NotStarted:
            // TODO: should we allow custom icon when disabled?
            case WorkflowActionState.Disabled:
                return this.actionIcon ?? `${this.currentStatus === WorkflowStatusEnum.Current ? "fas" : "fal"} fa-fw fa-play`;
            default:
                return "fal fa-fw fa-lock";
        }
    }

    public get hasRequiredWorkflows() {
        return this.compulsoryPrerequisiteWorkflows &&
            this.compulsoryPrerequisiteWorkflows.length > 0;
    }

    public get isLocked() {
        return !WorkflowActionableStates.includes(this.state);
    }

    public async ngOnChanges() {
        await this.updateData();
        this.loading = false;
    }

    public async onActionClicked() {
        if (this.isLocked) {
            return;
        }

        if (this.action) {
            const event = new CustomEvent("cardActionClicked", { cancelable: true });
            await this.action?.(event);
            if (event.defaultPrevented) {
                return;
            }
        }

        // do default click action here (start/continue pathway)
        this.startWorkflow();
    }

    private async updateData() {
        const connectionWorkflow = this.workflowService.getWorkflow(this.connection);
        if (!this.workflow) {
            this.workflow = connectionWorkflow;
        } else if (!this.connection) {
            this.connection = await lastValueFrom(this.workflowService.getLatestWorkflowConnectionForWorkflow(this.workflow));
        }

        if (!this.workflow) {
            return;
        }

        if (this.workflow.category) {
            this.categoryMetadata = OrganisationOutcomes[this.workflow.category];
        }

        const status = await lastValueFrom(this.workflowService.getLatestStatusForWorkflow(this.workflow));
        this.currentStatus = status?.status ?? WorkflowStatusEnum.Incomplete;

        this.hasWorkflowAccess = await this.authorisationService.promiseToGetHasAccess(WorkflowAuthService.EditWorkflow);
        this.canContinueWorkflow = await lastValueFrom(this.workflowService.canContinueWorkflow(this.workflow));
        this.canStartWorkflow = await lastValueFrom(this.workflowService.canStartWorkflow(this.workflow));

        this.compulsoryPrerequisiteWorkflows = this.workflowService.getAllPrerequisiteWorkflows(this.workflow);
        this.prerequisitesSatisfied = await lastValueFrom(this.workflowService.areWorkflowPrerequisitesSatisfied(this.workflow));

        this.workflowState.emit(this.state);
    }

    private startWorkflow() {
        // this will get the existing connection, or create a new one (if we're restarting)
        this.workflowService.createWorkflowConnectionForWorkflow(this.workflow!).pipe(
            tap(({ workflowConnection, workflowStatus }) => {
                this.connection = workflowConnection;
                this.currentStatus = workflowStatus?.status;
            }),
            switchMap(({ workflowConnection }) => this.workflowService.executeJourneyForConnection(workflowConnection!, true)),
        ).subscribe();
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { TourIdentifier } from "../../tours/tour-identifier.enum";
import { WorkflowIdentifier } from "../workflow-identifier.enum";
import { createActivityBriefWorkflowStep, createWorkshopScheduleWorkflows } from "../workflow-utilities";
import { IOrganisationDiagnosticStatusCustomData } from "./organisation-diagnostic-status-custom-data";

export const organisationDiagnosticWorkflowName = "Understand the journey";
export const organisationHealthCheckWorkflow = buildLocalWorkflow({
    name: "Health check",
    workflowId: WorkflowIdentifier.HealthCheck,
    type: WorkflowType.Journey,
    wrapUpGuidedTourIdentifier: TourIdentifier.HealthCheckTour,
    category: OrganisationCategoryValue.Others,
    scope: WorkflowScope.Team,
    featuresToEnable: [
        FeatureName.OrganisationDiagnostic,
        FeatureName.StewardshipWorkKanban,
        FeatureName.StewardshipWorkMeetings,
    ],
    private: true,
    workflows: [
        {
            name: organisationDiagnosticWorkflowName,
            workflowId: "ODInitiateSurvey",
            type: WorkflowType.Workflow,
            dialogWidth: WorkflowDialogWidth.Large,
            continueOnFinish: true,
            articleSlug: ImplementationKitArticle.OrganisationHealthCheckInitiateSurvey,
            steps: [
                createActivityBriefWorkflowStep(organisationDiagnosticWorkflowName, ImplementationKitArticle.UnderstandJourneyActivityBrief, undefined, undefined),
                {
                    name: "Health check - start survey",
                    ordinal: 1,
                    allowBack: true,
                    canSkip: false,
                    articleSlug: ImplementationKitArticle.OrganisationHealthCheckLaunchSurveyIntro,
                    componentSelector: "adapt-launch-organisation-diagnostic-survey",
                    workflowStepNextText: "Send survey invitations",
                }, {
                    name: "Pending survey",
                    ordinal: 2,
                    allowBack: false,
                    canSkip: false,
                    hideTitle: true,
                    componentSelector: "adapt-pending-organisation-diagnostic-survey",
                    overviewComponentSelector: "adapt-show-survey-progress",
                    workflowStepNextText: "Schedule meeting",
                }],
        },
        ...createWorkshopScheduleWorkflows("Health check debrief", WorkflowIdentifier.UnderstandJourney, {
            name: "Health check debrief",
            scheduleArticleSlug: ImplementationKitArticle.OrganisationHealthCheckScheduleDebrief,
            runArticleSlug: ImplementationKitArticle.OrganisationHealthCheckRunDebrief,
            scheduleOverviewComponentSelector: "adapt-show-survey-meeting",
            runOverviewComponentSelector: "adapt-show-survey-meeting",
            customData: {
                meetingCustomData: (workflowCustomData?: IOrganisationDiagnosticStatusCustomData) => ({ surveyId: workflowCustomData?.surveyId }),
            },
        }),
    ],
});

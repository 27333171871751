import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "../workflow-identifier.enum";

export const meaningfulConversationsWorkflowName = "Have meaningful conversations";

export const meaningfulConversationsWorkflow = buildLocalWorkflow({
    name: meaningfulConversationsWorkflowName,
    workflowId: WorkflowIdentifier.MeaningfulConversations,
    type: WorkflowType.Journey,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "By using a framework to have conversations, we believe you can more effectively discuss issues and concerns with your fellow leadership team members.",
    category: OrganisationCategoryValue.EffectiveLeadershipTeam,
    articleSlug: ImplementationKitArticle.ComingSoon, // a coming soon HelpJuice document
    //articleSlug: "2076657", // the actual outcomes HelpJuice document
    wrapUpSlug: ImplementationKitArticle.MeaningfulConversationsWrapUp,
    ordinal: 50,
});

import { Component, Input, TemplateRef } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { of } from "rxjs";

const zoneClass: { [zone in Zone]: string } = {
    [Zone.OrganisationDesign]: "organisation-design",
    [Zone.EconomicEngine]: "economic-engine",
    [Zone.HealthyCulture]: "healthy-culture",
    [Zone.FinanceAndOwnership]: "finance-and-ownership",
    [Zone.ResearchAndDevelopment]: "research-development",
};

@Component({
    selector: "adapt-render-expanded-zone-map",
    templateUrl: "./render-expanded-zone-map.component.html",
    styleUrls: ["./render-expanded-zone-map.component.scss"],
})
export class RenderExpandedZoneMapComponent {
    @Input() public expandedZoneLocationTemplate?: TemplateRef<unknown>;
    @Input() public globalZoneLocationTemplate?: TemplateRef<unknown>;
    @Input() public valueStreamTitleTemplate?: TemplateRef<unknown>;
    @Input() public showGlobalZoneLocation = false;
    @Input() public globalZoneLocationLabel: string = "";
    @Input() public showResearchAndDevelopment = true;
    @Input() public showEconomicEngine = true;
    @Input() public valueStreams = of([] as ValueStream[]);

    public Zone = Zone;
    public showOnLeft = true;

    public constructor(
        orgService: OrganisationService,
    ) {
        orgService.getOrganisationConfiguration().pipe()
            .subscribe((config) => this.showOnLeft = config.productZonePosition === 0);
    }

    public zoneContentClass(zone: Zone) {
        return zoneClass[zone];
    }
}

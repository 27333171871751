<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Import Meeting Agenda</h3>

    <ng-container adapt-dialog-content>
        <div *adaptLoadingUntilFirstEmit="let dataSource of recentMeetingsDataSource$">
            <h3>Recent Meetings
                <i class="fal fa-info-circle"
                   adaptTooltip="Meetings within the last 6 months"></i>
            </h3>
            <dx-select-box [dataSource]="dataSource"
                           [showClearButton]="false"
                           [searchEnabled]="true"
                           [searchExpr]="$any(['name','team.name'])"
                           (valueChange)="meetingSelected($event)"
                           displayExpr="name"
                           itemTemplate="item"
                           [grouped]="true"
                           groupTemplate="group"
                           placeholder="Select a meeting to import agenda items..."
                           noDataText="No other meeting found."
                           data-test="select-meeting-agenda">
                <div *dxTemplate="let data of 'group'">
                    <i class="fal fa-users"></i>
                    {{data.key}}
                </div>
                <div *dxTemplate="let meeting of 'item'"
                     class="d-flex">
                    <span>{{meeting.name}}</span>
                    <small class="ms-auto text-muted">{{meeting.meetingDateTime | adaptDate}}</small>
                </div>
            </dx-select-box>

            <ng-container *ngIf="selectedMeeting">
                <h3 class="mt-4">Agenda Items</h3>
                <div *adaptLoading="loadingItems">
                    <adapt-display-agenda-items [agendaItems]="agendaItems"
                                                [hideProgressIcon]="true"
                                                [hideNotesAndItems]="true"
                                                class="import-agenda-items"></adapt-display-agenda-items>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   (cancelClick)="cancel()"
                                   [saveIsDisabled]="!selectedMeeting || loadingItems"
                                   saveButtonPreset="add"
                                   [saveText]="dialogData.saveOnClose ? 'Import & Save' : 'Import'"
                                   [saveBlockingClick]="importItems"></adapt-standard-dialog-buttons>
</adapt-dialog>

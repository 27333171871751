<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Set Subscription Details</h3>

    <ng-container adapt-dialog-content>
        <div role="alert"
             class="alert alert-info">
            <h4>Ok, now we need some details to make it all official!</h4>
        </div>

        <div class="row">
            <div class="col-6">
                <h3>Billing Contact Details
                    <i class="fal fa-info-circle"
                       adaptTooltip="So we know where to send invoices and billing enquiries">
                    </i>
                </h3>
                <adapt-update-billing-details [account]="account"></adapt-update-billing-details>
            </div>
            <div class="col-6">
                <h3>Credit Card Details</h3>
                <adapt-update-credit-card-details [updateCreditCardInput]="creditCardInput"
                                                  (cardSet)="cardSetCorrectly = $event"></adapt-update-credit-card-details>
            </div>
        </div>

        <div role="alert"
             class="alert alert-warn mt-3">
            <strong>Starting the subscription will charge your credit card ${{monthlyPrice}} <small>(ex. GST)</small> for the upcoming
                month, and every month thereafter until the subscription is cancelled.</strong>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   (cancelClick)="cancel()"
                                   saveButtonPreset="primary"
                                   [saveBlockingClick]="saveAndClose"
                                   [saveIsDisabled]="!cardSetCorrectly || !account.contactName || !account.contactEmail"
                                   saveText="Start subscription!"></adapt-standard-dialog-buttons>
</adapt-dialog>

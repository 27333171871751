<adapt-confirmation-dialog (closed)="onDialogClosed($event)"
                           (initialised)="onInitialised($event)">
    <p>Are you sure you wish to delete this board?</p>

    <div *ngIf="board.items.length">
        The following items are located in this board
        <ul class="ps-0">
            <li *ngFor="let item of board.items">
                <adapt-item-link [item]="item"
                                 [showSummary]="true"></adapt-item-link>
            </li>
        </ul>
        <div class="mt-3"><b>Deleting this board will result in these {{board.items.length}} items being deleted.</b></div>
    </div>
</adapt-confirmation-dialog>

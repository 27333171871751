import { Component, Input, OnChanges } from "@angular/core";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxChartCustomizePoint, IDxChartCustomizeTooltip } from "@common/ux/dx.types";
import { IItemStatusCount } from "../kanban.service";

interface IItemStatus {
    name: string;
    onScheduleCount: number;
    overdueCount: number;
    colour: string;
}

@Component({
    selector: "adapt-boards-item-statuses-graph",
    templateUrl: "./boards-item-statuses-graph.component.html",
})
export class BoardsItemStatusesGraphComponent extends BaseComponent implements OnChanges {
    @Input() public statusCounts?: IItemStatusCount[];

    public readonly onScheduleSeriesName = "On Schedule";
    public readonly overdueSeriesName = "Overdue";
    private readonly overdueSeriesColour = "#cd2f2e";
    private readonly excludedStatuses = [ItemStatus.Backlog, ItemStatus.Closed];

    public itemStatuses: IItemStatus[] = [];

    @Autobind
    public customizePoint(e: IDxChartCustomizePoint) {
        return {
            color: e.seriesName === this.overdueSeriesName
                ? this.overdueSeriesColour
                : this.itemStatuses[e.index].colour,
        };
    }

    @Autobind
    public customizeTooltip(e: IDxChartCustomizeTooltip) {
        return {
            text: `${e.seriesName} (${e.value})`,
        };
    }

    public ngOnChanges() {
        if (this.statusCounts) {
            this.setupBoardData();
        }
    }

    @Autobind
    private setupBoardData() {
        this.itemStatuses = this.statusCounts!
            .filter((statusCount) => !this.excludedStatuses.find((excludedStatus) => excludedStatus === statusCount.status))
            .map((statusCount) => ({
                name: ItemStatusMetadata.ByStatus[statusCount.status].name,
                onScheduleCount: statusCount.count - statusCount.overdueCount,
                overdueCount: statusCount.overdueCount,
                colour: ItemStatusMetadata.ByStatus[statusCount.status].color,
            } as IItemStatus));
    }
}

import { Component, ElementRef, Injector } from "@angular/core";
import { CanvasType, InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategicInputsService } from "@org-common/lib/strategic-inputs/strategic-inputs.service";
import { InputsCanvasIdSearchParam, StrategicInputsPageRoute } from "@org-common/lib/strategic-inputs/strategic-inputs-page/strategic-inputs-page.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import moment from "moment";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";

@MeetingAgendaItemComponent("adapt-gather-inputs-swt-agenda-item")
@Component({
    selector: "adapt-gather-inputs-swt-agenda-item",
    templateUrl: "./gather-inputs-swt-agenda-item.component.html",
})
export class GatherInputsSWTAgendaItemComponent extends BaseComponent {
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;

    public constructor(
        elementRef: ElementRef,
        private strategicInputsService: StrategicInputsService,
        private injector: Injector,
    ) {
        super(elementRef);
    }

    @Autobind
    public recordSWT() {
        const yesterday = moment().add("-1", "day");
        return this.strategicInputsService.getLatestCanvasOfType(CanvasType.StrengthsWeaknessesTrends).pipe(
            switchMap((canvas) => {
                // if there is a canvas created from yesterday, use that, otherwise create a new one
                if (!canvas || canvas && moment(canvas.canvasDate).isBefore(yesterday)) {
                    return this.strategicInputsService.createCanvasOfType(CanvasType.StrengthsWeaknessesTrends).pipe(
                        switchMap((newCanvas) => this.strategicInputsService.saveEntities(newCanvas)),
                    );
                }
                return of(canvas);
            }),
            switchMap((canvas: InputsCanvas) => StrategicInputsPageRoute.gotoRoute(
                undefined,
                setReturnPathSearchParam(
                    this.injector,
                    { [InputsCanvasIdSearchParam]: canvas?.inputsCanvasId },
                    StrategicInputsPageRoute.id,
                    "Return to meeting"),
            )),
        );
    }
}

<p>Please review your cadences below. You can make any changes by clicking on the navigation bar below to jump to that specific event.</p>

<div class="my-3">
    <adapt-review-recurrences *ngIf="recurrences"
                              [scheduledRecurrences]="recurrences"></adapt-review-recurrences>
</div>

<adapt-time-scheduler-wrapper [items]="items"
                              [sections]="sections"
                              [start]="schedulerStartTime"
                              [currentPeriod]="currentPeriod"></adapt-time-scheduler-wrapper>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDashboardElementHqModule } from "@common/ux/dashboard-element-hq/dashboard-element-hq.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTimeSchedulerModule } from "@common/ux/time-scheduler/time-scheduler.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { DxAccordionModule, DxDateBoxModule, DxListModule, DxNumberBoxModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AdaptCalendarModule } from "../calendar/calendar.module";
import { AdaptKanbanSharedModule } from "../kanban/kanban-shared.module";
import { AdaptWorkflowModule } from "../workflow/workflow.module";
import { DisplayAgendaItemsComponent } from "./display-agenda-items/display-agenda-items.component";
import { DisplayMeetingParticipantsComponent } from "./display-meeting-participants/display-meeting-participants.component";
import { DisplayMeetingSummaryComponent } from "./display-meeting-summary/display-meeting-summary.component";
import { EditAgendaItemDialogComponent } from "./edit-agenda-item-dialog/edit-agenda-item-dialog.component";
import { EditAgendaTemplateDialogComponent } from "./edit-agenda-template-dialog/edit-agenda-template-dialog.component";
import { ImportFromAgendaTemplateDialogComponent } from "./import-from-agenda-template-dialog/import-from-agenda-template-dialog.component";
import { ImportFromOtherMeetingAgendaDialogComponent } from "./import-from-other-meeting-agenda-dialog/import-from-other-meeting-agenda-dialog.component";
import { LinkTeamMeetingComponent } from "./link-team-meeting/link-team-meeting.component";
import { MeetingDashboardCardComponent } from "./meeting-dashboard-card/meeting-dashboard-card.component";
import { MeetingDetailsComponent } from "./meeting-details/meeting-details.component";
import { MeetingDetailsMenuComponent } from "./meeting-details-menu/meeting-details-menu.component";
import { MeetingPreWorkDialogComponent } from "./meeting-pre-work-dialog/meeting-pre-work-dialog.component";
import { AdaptMeetingsSharedModule } from "./meetings-shared.module";
import { ScheduleMeetingAgendaStepComponent } from "./schedule-meeting-workflow/schedule-meeting-agenda-step/schedule-meeting-agenda-step.component";
import { ScheduleMeetingScheduleStepComponent } from "./schedule-meeting-workflow/schedule-meeting-schedule-step/schedule-meeting-schedule-step.component";
import { ShowWorkflowMeetingComponent } from "./show-workflow-meeting/show-workflow-meeting.component";
import { TeamActiveMeetingPageComponent } from "./team-active-meeting-page/team-active-meeting-page.component";
import { teamActiveMeetingPageRoute } from "./team-active-meeting-page/team-active-meeting-page.route";
import { TeamMeetingsDashboardElementComponent } from "./team-meetings-dashboard-element/team-meetings-dashboard-element.component";
import { TeamMeetingsPageComponent } from "./team-meetings-page/team-meetings-page.component";
import { teamMeetingsPageRoute } from "./team-meetings-page/team-meetings-page.route";
import { TeamMeetingsWithCadencePageComponent, TeamMeetingsWithCadencePageRoute } from "./team-meetings-with-cadence-page/team-meetings-with-cadence-page.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxTextBoxModule,
        DxNumberBoxModule,
        DxListModule,
        DxDateBoxModule,
        DxSelectBoxModule,
        DxScrollViewModule,
        AdaptCallToActionModule,
        AdaptMeetingsSharedModule,
        AdaptKanbanSharedModule,
        AdaptDirectorySharedModule,
        AdaptLoadingSpinnerModule,
        AdaptButtonModule,
        AdaptDateModule,
        AdaptTooltipModule,
        AdaptCommonDialogModule,
        AdaptEntityValidatorModule,
        AdaptHtmlEditorModule,
        AdaptMenuModule,
        AdaptShellModule,
        AdaptAuthorisationModule,
        AdaptTwoColumnModule,
        AdaptDashboardElementModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
        DxAccordionModule,
        CollapseModule,
        AdaptCalendarModule,
        AdaptFeatureModule,
        AdaptSeeMoreModule,
        AdaptTimeSchedulerModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptWorkflowModule,
        AdaptDashboardElementHqModule,
    ],
    declarations: [
        LinkTeamMeetingComponent,
        MeetingDashboardCardComponent,
        TeamMeetingsDashboardElementComponent,
        TeamMeetingsPageComponent,
        TeamActiveMeetingPageComponent,
        MeetingDetailsComponent,
        DisplayMeetingSummaryComponent,
        DisplayMeetingParticipantsComponent,
        EditAgendaTemplateDialogComponent,
        EditAgendaItemDialogComponent,
        ImportFromAgendaTemplateDialogComponent,
        DisplayAgendaItemsComponent,
        ImportFromOtherMeetingAgendaDialogComponent,
        MeetingDetailsMenuComponent,
        MeetingPreWorkDialogComponent,
        TeamMeetingsWithCadencePageComponent,
        ScheduleMeetingScheduleStepComponent,
        ScheduleMeetingAgendaStepComponent,
        ShowWorkflowMeetingComponent,
    ],
    exports: [
        LinkTeamMeetingComponent,
        MeetingDashboardCardComponent,
        TeamMeetingsDashboardElementComponent,
        DisplayMeetingSummaryComponent,
        ShowWorkflowMeetingComponent,
    ],
})
export class AdaptCommonMeetingsModule {
    public static readonly Routes = [
        ...teamMeetingsPageRoute.routes,
        ...teamActiveMeetingPageRoute.routes,
        ...TeamMeetingsWithCadencePageRoute.routes,
    ];

    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        ScheduleMeetingScheduleStepComponent,
        ScheduleMeetingAgendaStepComponent,
    ];
}

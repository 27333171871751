import { Component, Inject, OnInit } from "@angular/core";
import { ORGANISATION_PURPOSE_VISION_PAGE, TEAM_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { IAdaptLinkObject } from "@common/route/route.service";
import { IPurposeVisionSearchResult } from "@org-common/lib/search/search.interface";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-search-row-purpose-vision",
    templateUrl: "./search-row-purpose-vision.component.html",
})
export class SearchRowPurposeVisionComponent extends SearchRowBaseComponent<IPurposeVisionSearchResult> implements OnInit {
    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
        @Inject(ORGANISATION_PURPOSE_VISION_PAGE) private organisationPurposeVisionRoute: IAdaptRoute<{}>,
        @Inject(TEAM_DASHBOARD_PAGE) private teamDashboardPageRoute: IAdaptRoute<{ teamId: number }>,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = this.result.TeamId
            ? this.teamDashboardPageRoute.getRouteObject({ teamId: this.result.TeamId })
            : this.organisationPurposeVisionRoute.getRouteObject();

        this.matches = this.excludeNameBreadcrumb(this.result.Results);
    }
}

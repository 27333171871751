import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { IEntityWithEventLocations } from "./entity-with-event-locations";
import { EventLocation } from "./event-location";
import { MeetingAgendaItem } from "./meeting-agenda-item";
import { MeetingAttendee } from "./meeting-attendee";
import { MeetingExtensions } from "./meeting-extensions";
import { MeetingItem } from "./meeting-item";
import { MeetingSupplementaryData } from "./meeting-supplementary-data";
import { Organisation } from "./organisation";
import { Team } from "./team";

export enum MeetingStatus {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Ended = "Ended",
}

export class Meeting extends BaseEntity<Meeting> implements IEntityWithEventLocations {
    public meetingId!: number;
    public organisationId!: number;
    public teamId!: number;
    public meetingDateTime!: Date;
    public lastUpdatedDateTime!: Date;
    public createdDateTime!: Date;
    public endTime!: Date;
    public status!: MeetingStatus;
    public name!: string;
    public customData?: string;
    public location?: string;

    public organisation!: Organisation;
    public team?: Team;
    public supplementaryData?: MeetingSupplementaryData;
    public meetingAttendees!: MeetingAttendee[];
    public meetingItems!: MeetingItem[];
    public meetingAgendaItems!: MeetingAgendaItem[];
    public eventLocations!: EventLocation[];

    @EntityExtensions(MeetingExtensions)
    public extensions!: MeetingExtensions;
}

export const MeetingBreezeModel = new BreezeModelBuilder("Meeting", Meeting)
    .orderByDesc("meetingDateTime")
    .hasSource()
    .withIdField("meetingId")
    .withLabelField("name")
    .alwaysFetchingNestedNavigationProperty("eventLocations.event")
    .isOrganisationEntity()
    .build();

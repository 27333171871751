<i class="fal fa-circle-info"
   [attr.id]="themeIdAttr"></i>
<!-- dxhover* events are better which will work for tablet too - pointerenter/end not working properly for tablet -->
<dx-popover [target]="'#' + themeIdAttr"
            [showEvent]="{name: 'dxhoverstart', delay: 500}"
            [hideEvent]="{ name: 'dxhoverend', delay: 500 }"
            [(visible)]="popoverVisible"
            (onHiding)="onPopoverHiding($event)"
            [width]="400"
            height="auto"
            maxHeight="70vh">
    <div *dxTemplate="let data of 'content'"
         class="p-2"
         (pointerenter)="preventHide()"
         (pointerleave)="allowHide()"
         (pointerdown)="allowHide()">
        <h4>Theme description</h4>
        <div [froalaView]="theme?.description"></div>
    </div>
</dx-popover>

import { Component, Inject } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

export interface IUserAccessSummaryDialogData {
    person?: Person;
    connection?: Connection;
}

@Component({
    selector: "adapt-user-access-summary-dialog",
    templateUrl: "./user-access-summary-dialog.component.html",
    styleUrls: ["./user-access-summary-dialog.component.scss"],
})
export class UserAccessSummaryDialogComponent extends BaseDialogComponent<IUserAccessSummaryDialogData> {
    public readonly dialogName = "UserAccessSummaryDialog";

    public title: string;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IUserAccessSummaryDialogData,
    ) {
        super();

        let fullName = "Unknown Person";
        if (data.person) {
            fullName = data.person.fullName;
        } else if (data.connection) {
            fullName = data.connection.person.fullName;
        }

        this.title = `Access Level Summary for ${fullName}`;
    }
}

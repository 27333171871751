import { Component, EventEmitter, Inject, Output, ViewChild } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { EMPTY } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ICardPartialDetails } from "../card-partial-details.interface";
import { ICardOrganisationIdentifier } from "../payment-processing.service";
import { UpdateCreditCardDetailsComponent } from "../update-credit-card-details/update-credit-card-details.component";

export interface IUpdateCreditCardInput {
    cardDetails?: ICardPartialDetails;
    organisationIdentifier: ICardOrganisationIdentifier;
}

export interface IUpdateCreditCardOutput {
    stripeCard?: stripe.elements.Element,
    cardDetails?: ICardPartialDetails,
}

@Component({
    selector: "adapt-update-credit-card-dialog",
    templateUrl: "./update-credit-card-dialog.component.html",
    styleUrls: ["./update-credit-card-dialog.component.scss"],
})
export class UpdateCreditCardDialogComponent extends BaseDialogComponent<void>{

    @Output() public cardIsSet = new EventEmitter<boolean>();
    @ViewChild(UpdateCreditCardDetailsComponent) public cardDetails?: UpdateCreditCardDetailsComponent;

    public readonly dialogName = "UpdateCreditCardDialog";

    public stripeCardComplete: boolean = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public updateCreditCardInput: IUpdateCreditCardInput) {
        super(DialogResolveData.NotRequired);
    }

    @Autobind
    public saveCreditCard() {
        return this.cardDetails?.saveCreditCard().pipe(
            catchError((e: any) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
            tap(() => this.resolve()),
        ) ?? EMPTY;
    }
}

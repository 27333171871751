import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { ShellStyleConstants } from "@common/shell/shell-style.constants";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSortableEvent } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import dxSortable from "devextreme/ui/sortable";
import { DxScrollViewComponent } from "devextreme-angular";
import { of } from "rxjs";
import { KanbanService } from "../kanban.service";

@Component({
    selector: "adapt-board",
    templateUrl: "./board.component.html",
    styleUrls: ["./board.component.scss"],
})
export class BoardComponent extends BaseComponent implements OnChanges, AfterViewInit, OnDestroy {
    public readonly ItemStatuses = ItemStatusMetadata.ByStatus;
    public readonly Backlog = ItemStatus.Backlog;

    @Input() public showAssignees = true;

    // passed in from page based on query param to have item selected
    @Input() public selectedItem?: Item;
    @Output() public selectedItemChange = new EventEmitter<Item>();

    @Input() public showStatuses = [ItemStatus.ToDo, ItemStatus.InProgress, ItemStatus.Done];
    @Input() public items: Item[] = [];

    @Output() public itemDialogOpened = new EventEmitter<Item>();
    @Output() public itemDialogClosed = new EventEmitter<Item>();

    @Output() public showBacklogClick = new EventEmitter<boolean>();

    public itemCollection: { [key in ItemStatus]: Item[] } = this.initialItemCollection;
    public loadingItems = true;
    public backlogSuggestionVisible = false;
    public popoverCancelHiding = false;
    public refreshRequired = false;

    @ViewChildren(DxScrollViewComponent) private scrollViewList!: QueryList<DxScrollViewComponent>;
    private mainView: HTMLElement | null = null; // type returned from HTMLElement.closest() - can't use ?: HTMLElement
    private isDragging = false;

    public verticalLaneStyle?: { [klass: string]: any };
    public sortableStyle?: { [klass: string]: any };

    public constructor(
        elementRef: ElementRef,
        private responsiveService: ResponsiveService,
        private kanbanService: KanbanService,
    ) {
        super(elementRef);
    }

    public getVerticalLaneContentClass(status: ItemStatus) {
        const contentClasses = [];
        if (this.isDragging) {
            contentClasses.push("on-drag");
        }

        if (!this.itemCollection[status].length) {
            contentClasses.push("no-item");
        }

        return contentClasses.join(" ");
    }

    public ngOnChanges(changes: SimpleChanges) {
        let refreshViewStyle = false;
        if (changes.items) {
            if (this.items) {
                this.itemCollection = this.initialItemCollection;
                this.items.forEach((item) => {
                    this.itemCollection[item.status].push(item);
                });

                for (const displayStatus of this.showStatuses) {
                    this.itemCollection[displayStatus].sort((a, b) => a.rank - b.rank);
                }

                this.loadingItems = false;
                refreshViewStyle = true;
            } else {
                this.loadingItems = true;
            }
        }

        if (changes.showStatuses && this.items) {
            refreshViewStyle = true;
        }

        if (refreshViewStyle) {
            // do within timeout so occurs once items are ready
            setTimeout(() => this.updateStyling());
            // without this an unneeded horizontal scroll shows up
            this.updateLaneScrollViews();
        }
    }

    public ngAfterViewInit() {
        this.mainView = (this.elementRef?.nativeElement as HTMLElement).closest<HTMLElement>(ShellStyleConstants.MainViewClassSelect);

        window.addEventListener("resize", this.resizeUpdateStyling);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        window.removeEventListener("resize", this.resizeUpdateStyling);
    }

    public onDragStart(e: IDxSortableEvent<Item[]>) {
        const item = e.fromData![e.fromIndex!];
        if (item.extensions.currentPersonCanEdit) { // this is set from kanban card
            this.isDragging = true;
        } else {
            e.cancel = true;
        }
    }

    public onDragEnd(e: IDxSortableEvent<Item[]>) {
        this.isDragging = false;

        if (e.fromData !== e.toData) {
            // move across lane
            const changeItem = e.fromData![e.fromIndex!];
            e.fromData!.splice(e.fromIndex!, 1);
            e.toData!.splice(e.toIndex!, 0, changeItem);
            changeItem.rank = this.kanbanService.getProposedKanbanItemRank(e.toData!, e.toIndex!);

            this.updateItemCollection();
        }
    }

    public onItemSelected(item?: Item) {
        this.selectedItem = item;
        this.selectedItemChange.emit(item);
    }

    public onDragMoved(e: IDxSortableEvent<Item[]>) {
        if (e.toComponent instanceof dxSortable && this.refreshRequired) {
            this.refreshRequired = false;
            e.toComponent.update();
        }
    }

    public onOrderChanged(e: IDxSortableEvent<Item[]>) {
        const movedItem = e.fromData!.splice(e.fromIndex!, 1)[0];
        e.toData!.splice(e.toIndex!, 0, movedItem);
        movedItem.rank = this.kanbanService.getProposedKanbanItemRank(e.toData!, e.toIndex!);

        this.saveItems([movedItem]).subscribe();
    }

    public showBacklogSuggestion(status: ItemStatus) {
        // only show backlog suggestion if currently board not showing backlog
        return status === ItemStatus.ToDo && this.itemCollection.ToDo.length > 8 &&
            this.showStatuses.indexOf(ItemStatus.Backlog) < 0;
    }

    public onPopoverContentReady(e: any) {
        const self = this;
        // this is derived from:
        // https://www.devexpress.com/Support/Center/Question/Details/T546073/how-to-close-dx-popover-on-event-dxhoverend-angular
        //
        // Note that dxpointerdown will close the popover when the action link is clicked. The previous implementation of using actionPerformed
        // never worked (see production where you click on 'View Access' from person popover - the popover remains on top of the access
        // dialog). That's because person actions are registering promise function and waiting for promise resolution before actionPerformed
        // is being called. So provided the dialog is not closed, actionPerformed won't be toggled.
        // Not changing that behaviour as profile controller is relying on that to refresh the controller after changes from action.
        // Only need action started notification here - so might as well add the dxpointerdown to close popover instead.
        //
        // Also notice that personLink popover not showing for tablet and phone which was the behaviour before as the <a> will navigate
        // to the person dashboard immediate upon touch down.
        e.component.content().on("dxpointerleave", () => { this.backlogSuggestionVisible = false; this.popoverCancelHiding = false; });
        e.component.content().on("dxpointerdown", handlePointerDown);
        e.component.content().on("dxpointerenter", () => this.popoverCancelHiding = true);

        function handlePointerDown(event: JQuery.Event) {
            // Don't process right click events on the popover (so the user can open in new tab/window for example)
            if (event.which !== 3) {
                // use a longish timeout, as otherwise there is an intermittent error where the click doesn't register properly
                // this event is registered so that events that the popover is hidden for events that just spawn dialog boxes (e.g. change password)
                setTimeout(() => { self.backlogSuggestionVisible = false; self.popoverCancelHiding = false; }, 200);
            }
        }
    }

    public onPopoverHiding(e: any) {
        if (this.popoverCancelHiding) {
            e.cancel = true;
        }
    }

    private get initialItemCollection() {
        return {
            [ItemStatus.ToDo]: [],
            [ItemStatus.InProgress]: [],
            [ItemStatus.Done]: [],
            [ItemStatus.Backlog]: [],
            [ItemStatus.Closed]: [],
        };
    }

    private updateItemCollection() {
        const changedItems: Item[] = [];
        // only update a single lane after save as destination lane is already updated from the drag n drop
        // - only source lane refresh required
        // - update dest lane will result in scroll view position reset
        const updateLanes: ItemStatus[] = [];
        for (const status of this.showStatuses) {
            const items = this.itemCollection[status];
            for (const item of items) {
                if (item.status !== status) {
                    updateLanes.push(item.status);
                    updateLanes.push(status);
                    item.status = status;
                    changedItems.push(item);
                }
            }
        }

        this.saveItems(changedItems).subscribe(() => this.updateLaneScrollViews(updateLanes));
    }

    private saveItems(items: Item[]) {
        if (items.length > 0) {
            return this.kanbanService.saveEntities(items).pipe(
                this.takeUntilDestroyed(),
            );
        } else {
            return of(undefined);
        }
    }

    private resizeUpdateStyling = () => {
        this.updateStyling();

        // some resize types (like maximising window) may cause scrollbars to get stuck until next action
        this.updateLaneScrollViews();
    };

    private updateStyling() {
        this.verticalLaneStyle = this.getVerticalLaneStyle();
        this.sortableStyle = this.getSortableStyle();
    }

    private getVerticalLaneStyle() {
        let calculatedHeight: string;
        if (this.items?.length) {
            calculatedHeight = this.responsiveService.currentBreakpoint.isDesktopSize
                ? `${(this.mainView?.clientHeight ?? 10) - 20}px` // 5px top and bottom margin + a small 10px gap
                : `calc(100vh - ${70 + (this.mainView?.offsetTop ?? 0)}px)`; // cannot use clientHeight for mobile
        } else {
            // no item, don't use full screen height
            // -> constant height for the header + padding
            // There will be text placeholder element
            calculatedHeight = "50px";
        }

        return {
            width: `calc(${100 / this.showStatuses.length}%)`,
            height: calculatedHeight,
        };
    }

    private getSortableStyle() {
        const laneStyle = this.verticalLaneStyle!;
        return {
            "min-height": `calc(${laneStyle.height} - 72px)`,
        };
    }

    private updateLaneScrollViews(updateStatuses?: ItemStatus[]) {
        // this is required after sortable [data] is updated so that scrollbar won't show up unnecessarily
        setTimeout(() => {
            this.scrollViewList.forEach((i) => {
                if (!updateStatuses) {
                    i.instance.update();
                } else {
                    // CM-5081 DX 21.1 is supposed to be returning a HTML element, but it actually returns a JQLite element.
                    const element = jQuery(i.instance.element());
                    const attribute = element[0].getAttribute("id");
                    if (this.laneIdOfStatuses(attribute!, updateStatuses)) {
                        i.instance.update();
                    }
                }
            });
        });
    }

    private laneIdOfStatuses(id: string, statuses: ItemStatus[]) {
        let result = false;
        for (const status of statuses) {
            if (id === `scrollViewStatus${status}`) {
                result = true;
                break;
            }
        }

        return result;
    }
}

<dx-select-box [showClearButton]="!required"
               [dataSource]="dataSource"
               [grouped]="false"
               [(value)]="zone"
               [disabled]="disabled"
               fieldTemplate="field"
               (valueChange)="zoneChange.emit($event)"
               [height]="44"
               [isValid]="!required || !!zone">
    <div *dxTemplate="let zone of 'item'">
        <adapt-zone-label [zone]="zone"></adapt-zone-label>
    </div>
    <div *dxTemplate="let zone of 'field'">
        <div *ngIf="!!zone; else noValueTemplate"
             class="zone-label-field-template">
            <adapt-zone-label [zone]="zone"></adapt-zone-label>
            <dx-text-box class="d-none"
                         value="hidden element required by dxTemplate"
                         [readOnly]="true"></dx-text-box>
        </div>
        <ng-template #noValueTemplate>
            <dx-text-box value=""
                         [readOnly]="true"></dx-text-box>
        </ng-template>
    </div>
</dx-select-box>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxScrollViewModule } from "devextreme-angular";
import { AdaptFeaturesModule } from "../features/features.modules";
import { AdaptMeetingsSharedModule } from "../meetings/meetings-shared.module";
import { MeetingTabContentComponent } from "./meeting-tab-content/meeting-tab-content.component";
import { OrgCommonShellWrapperComponent } from "./org-common-shell-wrapper/org-common-shell-wrapper.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxScrollViewModule,
        AdaptShellModule,
        AdaptButtonModule,
        AdaptMeetingsSharedModule,
        AdaptDateModule,
        AdaptFeatureModule,
        AdaptTooltipModule,
        AdaptFeaturesModule,
    ],
    declarations: [
        OrgCommonShellWrapperComponent,
        MeetingTabContentComponent,
    ],
    exports: [
        OrgCommonShellWrapperComponent,
    ],
})
export class AdaptOrgCommonShellModule { }

import { Injectable } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { from, of } from "rxjs";
import { tap } from "rxjs/operators";
import { ISearchProviderOptions, SearchType } from "../search.interface";
import { SearchProvider } from "../search-provider";

@Injectable()
export class PeopleSearchProvider extends SearchProvider<Person> {
    public readonly Type = SearchType.People;

    public people: Person[] = [];

    public constructor(
        private directorySharedService: DirectorySharedService,
    ) {
        super();
    }

    public shouldSkip(options: ISearchProviderOptions): boolean {
        return !!options.teamId || !!options.personId || !!options.updatedSince || (!!options.labelIds && options.labelIds.size > 0);
    }

    public initialise() {
        return from(this.directorySharedService.promiseToGetAllPeople()).pipe(
            tap((people: Person[]) => this.people = people),
        );
    }

    public execute({ keyword, activeOnly }: ISearchProviderOptions) {
        return of(this.people
            .filter((p) => p.fullName.toLowerCase().includes(keyword.toLowerCase()) && (!activeOnly || p.isActive()))
            .slice(0, 10));
    }
}

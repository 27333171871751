<div *adaptLoading="!survey && !meeting"
     class="d-flex flex-column mw-100 gap-2">
    <ng-container *ngIf="survey">
        <adapt-display-survey-result *ngIf="survey.status !== SurveyStatus.Ended"
                                     [survey]="survey"
                                     [hideName]="true"
                                     class="survey-result"></adapt-display-survey-result>
        <div class="d-flex w-100 center-content">
            <button adaptButton="iconlessLink"
                    (click)="viewSurveySummary()">View survey summary</button>
        </div>
    </ng-container>

    <adapt-show-workflow-meeting [workflowConnection]="workflowConnection"></adapt-show-workflow-meeting>
</div>

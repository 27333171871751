import { Component, ComponentRef, HostListener, Inject, Injector, ViewChild, ViewContainerRef } from "@angular/core";
import { Workflow, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStep, WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { IRating, IWorkflowRating } from "@common/ADAPT.Common.Model/organisation/workflow-rating";
import { WorkflowStatus, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { PersonDetailNames } from "@common/ADAPT.Common.Model/person/person-detail";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { DisableEntityPersistent } from "@common/lib/data/persistable-dialog.decorator";
import { GuidedTour, GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourRegistry } from "@common/lib/guided-tour/guided-tour-registrar";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { IProgressStep } from "@common/ux/stepped-progress-bar/stepped-progress-bar.component";
import { DxScrollViewComponent } from "devextreme-angular";
import { EMPTY, forkJoin, lastValueFrom, Observable, of, Subject, Subscription } from "rxjs";
import { catchError, finalize, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { PersonFlagService } from "../../person/person-flag.service";
import { DisplayWorkflowActivityBriefComponentSelector, IActivityBriefData } from "../display-workflow-activity-brief/display-workflow-activity-brief.component";
import { WorkflowService } from "../workflow.service";
import { IWorkflowStepComponent, IWorkflowStepFooterTemplate, WorkflowStepComponentRegistry } from "../workflow-component-registry";
import { WorkflowConfirmDialogComponent } from "../workflow-confirm-dialog/workflow-confirm-dialog.component";
import { WrapUpText } from "../workflow-journey-page/workflow-journey-page.component";

export interface IWorkflowRunData {
    // need both, workflow is defined in embed for all, connection is the current one connected to a user, which will have status
    workflowConnection?: WorkflowConnection;
    workflow: Workflow;
    skipOutcomes?: boolean;
    titleOverride?: string;
    dismissDialog?: IConfirmationDialogData;
    runData?: any;
}

@Component({
    selector: "adapt-workflow-run-dialog",
    templateUrl: "./workflow-run-dialog.component.html",
    styleUrls: ["./workflow-run-dialog.component.scss"],
})
@DisableEntityPersistent()
export class WorkflowRunDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IWorkflowRunData, undefined> {
    public readonly dialogName = "WorkflowRunDialog";
    public readonly dialogWidthMappings: { [key in WorkflowDialogWidth]: string } = {
        [WorkflowDialogWidth.FullWidth]: "90%",
        [WorkflowDialogWidth.ExtraLarge]: "1200px",
        [WorkflowDialogWidth.Large]: "900px",
        [WorkflowDialogWidth.Medium]: "700px",
        [WorkflowDialogWidth.Small]: "500px",
    };
    public readonly WorkflowStepGuidancePosition = WorkflowStepGuidancePosition;
    public readonly dialogWidthBootstrapRowBreakpointClasses: { [key in WorkflowDialogWidth]: string } = {
        [WorkflowDialogWidth.FullWidth]: "flex-column flex-xl-row",
        [WorkflowDialogWidth.ExtraLarge]: "flex-column flex-xl-row",
        [WorkflowDialogWidth.Large]: "flex-column flex-lg-row",
        [WorkflowDialogWidth.Medium]: "flex-column flex-md-row",
        [WorkflowDialogWidth.Small]: "flex-column flex-sm-row",
    };

    public readonly ratings: IRating[] = [
        { checked: false, title: "Poor" },
        { checked: false, title: "Needs Improvement" },
        { checked: false, title: "Average" },
        { checked: false, title: "Very Good" },
        { checked: false, title: "Excellent" },
    ];

    @ViewChild("container", { read: ViewContainerRef }) public container!: ViewContainerRef;
    public currentWorkflowStep?: WorkflowStep;
    public progressSteps: IProgressStep<WorkflowStep>[] = [];
    public currentStepCompleted = false;
    public currentStepSkippable = true;
    public isBusy = false;
    public errorMessage?: string;
    public footerTemplates?: IWorkflowStepFooterTemplate[];

    public fullWidthImplementationKit = false;
    @ViewChild("implementationKitScrollView") private implementationKitScrollViewComponent?: DxScrollViewComponent;
    @ViewChild("stepComponentScrollView") private stepComponentScrollViewComponent?: DxScrollViewComponent;
    public fullWidthImplementationKitUpdater = this.createThrottledUpdater<boolean>((isFullWidth) => {
        this.fullWidthImplementationKit = isFullWidth;
    });

    public dialogWidth = this.dialogWidthMappings[WorkflowDialogWidth.Large];
    public isCompleted = false;
    public wrapUpTour?: GuidedTour;
    public wrapUpTourPersonDetailName?: PersonDetailNames;
    public wrapUpSlug?: ImplementationKitArticle;
    public workflowRating: IWorkflowRating = {
        rating: 0,
        liked: "",
        improveOn: "",
        workflowId: this.data.workflow.workflowId,
        workflowName: this.data.workflow.name,
        workflowConnectionId: this.data.workflowConnection?.workflowConnectionId,
    };

    // holds any entity changed during the workflow steps emitted through workflowStepEntityChange subscription
    protected entitiesToConfirm: IBreezeEntity<any>[] = [];

    private stepUpdater = this.createThrottledUpdater((step?: WorkflowStep) => {
        const dialogWidthOverride = step && this.currentWorkflowStep?.isOutcomeStep
            ? WorkflowDialogWidth.FullWidth
            : undefined;
        this.setDialogWidth(step?.workflow ?? this.data.workflow, dialogWidthOverride);

        this.checkLoadComponentSelectorComponent();
    });
    private currentCustomComponent?: ComponentRef<IWorkflowStepComponent>;
    private stepCompletionSubscription?: Subscription;
    private stepSkippableSubscription?: Subscription;
    private stepEntityChangeSubscription?: Subscription;
    private goToStepSubscription?: Subscription;
    private errorMessageSubscription?: Subscription;
    private finishCurrentSubscription?: Subscription;
    private footerTemplateSubscription?: Subscription;
    private postWorkflowSteps: { [stepOrdinal: number]: () => Observable<unknown> } = {};

    private lastCompletedStepIdx = -1;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IWorkflowRunData,
        private workflowService: WorkflowService,
        private dialogService: AdaptCommonDialogService,
        private guidedTourService: GuidedTourService,
        private personFlagService: PersonFlagService,
        private implementationKitService: ImplementationKitService,
        injector: Injector,
    ) {
        super(injector, DialogResolveData.NotRequired);

        this.initialise(data);

        if (this.data.workflowConnection) {
            // only persist dialog state if workflow is execute with a connection (with status tracking); otherwise, no restore!
            this.workflowService.updateWorkflowRunSearchParam(this.data.workflow.workflowId);
        }

        this.events.subscribe({
            complete: async () => {
                this.submitRating();
                await this.workflowService.updateWorkflowStopSearchParam(this.data.workflow.workflowId);
            },
        });
    }

    private async initialise(data: IWorkflowRunData) {
        this.data = data;
        const connectionWorkflow = this.workflowService.getWorkflow(data.workflowConnection)!;
        if (!data.workflow && data.workflowConnection) {
            if (connectionWorkflow.type === WorkflowType.Journey && connectionWorkflow.workflows?.length) {
                data.workflow = connectionWorkflow.workflows[0];
            } else {
                data.workflow = connectionWorkflow;
            }
        }

        if (data.workflow) {
            // set width ASAP so it doesn't flash
            this.setDialogWidth(data.workflow);

            data.workflow.runData = data.runData; // clear on start

            // enable any required features ASAP so any components will be ready
            if (data.workflow.featuresToEnable) {
                await lastValueFrom(this.workflowService.enableFeaturesForWorkflow(data.workflow));
            }

            // make sure the workflow steps are sorted by ordinal,
            // as navigation properties are sorted by primary key by default.
            data.workflow.steps?.sort(SortUtilities.getSortByFieldFunction<WorkflowStep>("ordinal"));
            if (!data.workflow.isStateless && data.workflowConnection) {
                this.workflowService.getOrSetCurrentStepForWorkflow(data.workflowConnection!, data.workflow).pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((step) => {
                    if (step) {
                        this.setCurrentWorkflowStep(step);

                        if (data.skipOutcomes && step.isOutcomeStep) {
                            this.skip();
                        }
                    }
                });
            } else if (data.workflow.steps?.length) {
                this.setCurrentWorkflowStep(data.workflow.steps[0]);
            } else {
                throw new Error("Cannot execute a workflow without steps");
            }
        } else {
            throw new Error("Cannot execute workflow without a journey or workflow defined.");
        }
    }

    public get dialogTitle() {
        if (this.isCompleted && !!this.wrapUpSlug) {
            // showing wrap up slug
            return WrapUpText;
        }

        if (this.data.titleOverride) {
            return this.data.titleOverride;
        }

        // use the workflow name as the dialog title if using breadcrumbs
        if (this.data.workflow.showBreadcrumbs && !this.currentWorkflowStep?.isOutcomeStep) {
            return this.data.workflow.name;
        }

        if (this.currentWorkflowStep?.deepDive?.title) {
            return this.currentWorkflowStep.deepDive.title;
        }

        return this.currentWorkflowStep?.name;
    }

    public get stepLocation() {
        if (this.data.workflow.steps?.length === 1 || !this.data.workflow.showBreadcrumbs) {
            return "";
        }

        if (!this.currentWorkflowStep || this.currentWorkflowStep.ordinal === undefined) {
            return "";
        }

        return `Step ${this.currentWorkflowStep.ordinal + 1} of ${this.data.workflow.steps?.length}`;
    }

    public get isFirstStep() {
        if (this.currentWorkflowStep) {
            return this.data.workflow.extensions.firstWorkflowStepId === this.currentWorkflowStep.workflowStepId;
        } else {
            return false;
        }
    }

    public get isLastStep() {
        if (this.currentWorkflowStep) {
            return this.data.workflow.extensions.lastWorkflowStepId === this.currentWorkflowStep.workflowStepId;
        } else {
            return false;
        }
    }

    public get nextWorkflowStep() {
        if (this.currentWorkflowStep && !this.isLastStep) {
            const currentIndex = this.data.workflow.extensions.getStepIndex(this.currentWorkflowStep);
            return (currentIndex === undefined) ? undefined : this.data.workflow.steps![currentIndex + 1]; // !lastStep and currentIndex is defined -> steps will be defined
        }

        return undefined;
    }

    public get previousWorkflowStep() {
        if (this.currentWorkflowStep && !this.isFirstStep) {
            const currentIndex = this.data.workflow.extensions.getStepIndex(this.currentWorkflowStep);
            return (currentIndex === undefined) ? undefined : this.data.workflow.steps![currentIndex - 1]; // !firstStep and index found
        }

        return undefined;
    }

    public get nextStepText() {
        const nextStep = this.nextWorkflowStep;
        const name = nextStep?.hideTitle ? undefined : nextStep?.name;
        return this.getNextStepText(name ? `Next - ${name}` : "Next");
    }

    public get closeText() {
        return this.getNextStepText(this.rootWorkflow?.wrapUpSlug ? "Wrap Up" : "Close");
    }

    public get footerLeft() {
        return this.footerTemplates?.filter((template) => template.alignment === "left");
    }

    public get footerCenter() {
        return this.footerTemplates?.filter((template) => template.alignment === "center");
    }

    public get footerRight() {
        return this.footerTemplates?.filter((template) => template.alignment === "right");
    }

    private get rootWorkflow() {
        return this.workflowService.getWorkflow(this.data.workflowConnection);
    }

    private getNextStepText(fallback: string) {
        if (this.currentCustomComponent && FunctionUtilities.isFunction(this.currentCustomComponent.instance.workflowStepNextText)) {
            const text = this.currentCustomComponent.instance.workflowStepNextText();
            if (text) {
                return text;
            }
        }

        return this.currentWorkflowStep?.workflowStepNextText ?? fallback;
    }

    public previous() {
        let doBeforePrevious$: Observable<unknown>;
        if (this.currentCustomComponent && FunctionUtilities.isFunction(this.currentCustomComponent.instance.workflowStepPrevious)) {
            doBeforePrevious$ = this.currentCustomComponent.instance.workflowStepPrevious() as Observable<unknown>;
        } else {
            doBeforePrevious$ = of(undefined);
        }

        this.errorMessage = undefined;
        doBeforePrevious$.pipe(
            catchError((error) => this.errorMessage = error.message ?? error),
        ).subscribe(() => {
            this.currentWorkflowStep = this.previousWorkflowStep;
            this.resetStepCompletion();
            this.stepUpdater.next(this.currentWorkflowStep);

            if (!this.data.workflow!.isStateless && this.data.workflowConnection) {
                const updatedEntities: WorkflowStatus[] = [];
                const updateIncomplete = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.nextWorkflowStep!, WorkflowStatusEnum.Incomplete);
                updatedEntities.push(...updateIncomplete);
                const updateCurrent = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.currentWorkflowStep!, WorkflowStatusEnum.Current);
                updatedEntities.push(...updateCurrent);
                this.workflowService.saveEntities(updatedEntities).subscribe();
            }
        });
    }

    @Autobind
    public blockingNext() {
        this.isBusy = true;
        if (this.currentCustomComponent && FunctionUtilities.isFunction(this.currentCustomComponent.instance.workflowStepNext)) {
            this.errorMessage = undefined;
            const interruptShortcut = new Subject<void>();
            return this.currentCustomComponent.instance.workflowStepNext!(interruptShortcut).pipe(
                tap(() => this.next()),
                finalize(() => this.isBusy = false),
                this.takeUntilDestroyed(),
                takeUntil(interruptShortcut.asObservable()),
                catchError((error) => this.errorMessage = error.message ?? error),
            );
        } else {
            this.next();
            this.isBusy = false;
            return Promise.resolve();
        }
    }

    /**
     * @param skippedStatusEntities Status entities to be saved from the previous skip
     * @param skipPrevious boolean flag to indicate the previous step is skipped
     *      - need this instead of relying on skippedStatusEntities to determine if the previous step is skipped as
     *        there won't be any skip status entities if the workflow is stateless.
     */
    private next(skippedStatusEntities?: WorkflowStatus[], skipPrevious = false) {
        of(undefined).pipe(
            switchMap(() => {
                this.currentWorkflowStep = this.nextWorkflowStep;
                this.preloadNextStepGuidance();

                if (this.currentWorkflowStep?.skipIfPreviousSkipped && skipPrevious) {
                    if (skippedStatusEntities) {
                        // if skipped and is stateful will have this collection - otherwise, won't have to update current step to SKIPPED as it is stateless
                        // - the following call will set this step to skipped as well due to 'skipIfPreviousSkipped
                        skippedStatusEntities.push(...this.updateCurrentStepStatusToSkipped()!);
                    }

                    if (this.isLastStep) {
                        // not going to load the skip step - so need to flag complete that not to do workflowStepNext as this is skipped
                        this.currentWorkflowStep = this.previousWorkflowStep; // revert back or next step will be loaded in the template during the following save
                        return this.saveUpdateWorkflowStepStatus(skippedStatusEntities).pipe(
                            switchMap(() => this.complete(true)),
                        );
                    } else {
                        // can only skip 1 step, won't do cascade skip,
                        // - i.e. skip, next step will skip if previous skip, next step won't skip even with skipIfPreviousSkipped
                        //   as previous is not skipped (it was skipped if previous skip)
                        // - The above updateCurrentStepStatusToSkipped has already updated the step status to skipped - so just move onto next
                        this.currentWorkflowStep = this.nextWorkflowStep;
                        this.preloadNextStepGuidance();
                    }
                }

                this.resetStepCompletion();
                this.setDialogWidth(this.currentWorkflowStep?.workflow);
                this.stepUpdater.next(this.currentWorkflowStep);

                return this.saveUpdateWorkflowStepStatus(skippedStatusEntities, this.currentWorkflowStep);
            }),
            tap(() => {
                if (this.previousWorkflowStep?.closeAfterNext) {
                    setTimeout(() => this.cancel());
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    /**
     * This will ONLY be called from next(), i.e. current is already pointing to current step and there will be a previous step
     * (unless it comes from the outcome step which will be flagged from the wasWorkflowOutcomeStep param).
     * It is splitted into a separate function as it can be called under 2 conditions: 1) next 2) skip
     * If skipped, skipped status will be set and there will be status entities awaiting to be saved - previous step will not be touched
     * If next'ed, there won't be skippedStatusEntities and previous workflow step is guaranteed to be there (we just moved from there in 'next').
     *
     * Nothing will be done if the workflow is stateless.
     *
     * @param skippedStatusEntities Status entities that has been set to SKIPPED and needed to be saved (i.e. when skipping the previous step)
     * @param workflowStepToBeCurrent  WorkflowStep that will be set to CURRENT - can be undefined skipped to completion
     * @param wasWorkflowOutcomeStep Indicates the previous step was a outcome step which is not really a workflow step and won't be a status change
     * @returns Observable to be subscribed to
     */
    private saveUpdateWorkflowStepStatus(skippedStatusEntities?: WorkflowStatus[], workflowStepToBeCurrent?: WorkflowStep) {
        if (!this.data.workflow!.isStateless && this.data.workflowConnection) {
            const updatedEntities: WorkflowStatus[] = [];
            if (!skippedStatusEntities) { // previous step was not skipped
                const updateCompleted = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.previousWorkflowStep!, WorkflowStatusEnum.Completed);
                updatedEntities.push(...updateCompleted);
            } else {
                updatedEntities.push(...skippedStatusEntities);
            }

            if (workflowStepToBeCurrent) {
                const updateCurrent = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection!, workflowStepToBeCurrent, WorkflowStatusEnum.Current);
                updatedEntities.push(...updateCurrent);
            }

            return this.workflowService.saveEntities(updatedEntities);
        } else {
            return of(undefined);
        }
    }

    public skip() {
        this.next(this.updateCurrentStepStatusToSkipped(), true);
    }

    private updateCurrentStepStatusToSkipped() {
        return !this.data.workflow!.isStateless && this.data.workflowConnection
            ? this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.currentWorkflowStep!, WorkflowStatusEnum.Skipped)
            : undefined;
    }

    public showDismissDialog() {
        if (this.data.dismissDialog) {
            this.dialogService.open(WorkflowConfirmDialogComponent, this.data.dismissDialog).pipe(
                switchMap((dialogResult) => dialogResult!.result ? of(void 0) : EMPTY),
                tap(() => this.resolve(undefined)),
            ).subscribe();
        }
    }

    @Autobind
    public complete(skipToComplete = false, continueAfter = false) {
        const interruptShortcut = new Subject<void>();
        let workflowStepNext$: Observable<unknown>;
        if (!skipToComplete && this.currentCustomComponent && FunctionUtilities.isFunction(this.currentCustomComponent.instance.workflowStepNext)) {
            workflowStepNext$ = this.currentCustomComponent.instance.workflowStepNext!(interruptShortcut);
        } else {
            workflowStepNext$ = of(undefined);
        }

        return workflowStepNext$.pipe(
            switchMap(() => {
                if (!this.data.workflow!.isStateless && this.data.workflowConnection && !skipToComplete) {
                    const updateCompleted = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.currentWorkflowStep!, WorkflowStatusEnum.Completed);
                    return this.workflowService.saveEntities(updateCompleted);
                } else {
                    return of(undefined);
                }
            }),
            switchMap(() => {
                const stepOrdinals = Object.keys(this.postWorkflowSteps)
                    .map((key) => Number(key))
                    .sort();
                if (stepOrdinals.length > 0) {
                    return forkJoin(stepOrdinals.map((ordinal) => this.postWorkflowSteps[ordinal]()));
                } else {
                    return of(undefined);
                }
            }),
            map(() => {
                if (this.data.workflow.parentWorkflow?.workflows) {
                    const currentWorkflowIndex = this.data.workflow.parentWorkflow.workflows.indexOf(this.data.workflow);
                    if (currentWorkflowIndex < (this.data.workflow.parentWorkflow.workflows.length - 1)) {
                        const nextWorkflow = this.data.workflow.parentWorkflow.workflows[currentWorkflowIndex + 1];
                        return nextWorkflow;
                    }
                    // workflow is part of a journey, check if the next workflow in the journey is incomplete -> start
                }

                return undefined;
            }),
            switchMap((nextWorkflow) => {
                if (nextWorkflow && this.data.workflowConnection) {
                    return this.workflowService.getStatusForWorkflow(this.data.workflowConnection, nextWorkflow).pipe(
                        map((statusEntity) => ({
                            workflow: nextWorkflow,
                            status: statusEntity?.status ?? WorkflowStatusEnum.Incomplete,
                        })),
                    );
                }
                return of({ workflow: nextWorkflow, status: WorkflowStatusEnum.Incomplete });
            }),
            switchMap((nextWorkflowStatus) => {
                const continueStatuses = [WorkflowStatusEnum.Incomplete, WorkflowStatusEnum.Current];
                if ((this.data.workflow.continueOnFinish || continueAfter) && nextWorkflowStatus.workflow && continueStatuses.includes(nextWorkflowStatus?.status)) {
                    // carry on with the next workflow
                    this.initialise({
                        workflowConnection: this.data.workflowConnection,
                        workflow: nextWorkflowStatus.workflow,
                    });
                    return EMPTY;
                }

                return of(undefined);
            }),
            switchMap(() => this.data.workflowConnection
                ? this.workflowService.getStatusForWorkflow(this.data.workflowConnection).pipe(
                    map((statusEntity) => statusEntity?.status ?? WorkflowStatusEnum.Incomplete),
                )
                : of(WorkflowStatusEnum.Incomplete)),
            tap((rootStatus) => {
                this.isCompleted = true;
                if (rootStatus === WorkflowStatusEnum.Completed) {
                    const rootWorkflow = this.rootWorkflow;
                    if (rootWorkflow?.wrapUpSlug) {
                        this.wrapUpSlug = rootWorkflow.wrapUpSlug;
                        this.wrapUpTour = rootWorkflow.wrapUpGuidedTourIdentifier
                            ? GuidedTourRegistry.get(rootWorkflow.wrapUpGuidedTourIdentifier)
                            : undefined;
                        this.wrapUpTourPersonDetailName = rootWorkflow?.wrapUpGuidedTourPersonDetailName;
                        return;

                    }
                }

                this.closeDialog();
            }),
            takeUntil(interruptShortcut.asObservable()),
        );
    }

    public runWrapUpTour() {
        if (this.wrapUpTour) {
            this.closeDialog();
            // only run tour after the dialog is closed
            setTimeout(async () => {
                if (this.wrapUpTourPersonDetailName) {
                    await this.personFlagService.setFlagAndRunTour(this.wrapUpTourPersonDetailName, this.wrapUpTour);
                } else {
                    this.guidedTourService.run(this.wrapUpTour);
                }
            });
        }
    }

    public closeDialog() {
        // only get rid of the dialog 1 digest cycle after everything completed
        setTimeout(() => this.resolve(undefined));
    }

    private checkLoadComponentSelectorComponent() {
        if (this.currentCustomComponent) {
            this.currentCustomComponent = undefined;

            // cleanup previous subscription
            this.stepEntityChangeSubscription?.unsubscribe();
            this.stepEntityChangeSubscription = undefined;
            this.goToStepSubscription?.unsubscribe();
            this.goToStepSubscription = undefined;
            this.stepCompletionSubscription?.unsubscribe();
            this.stepCompletionSubscription = undefined;
            this.stepSkippableSubscription?.unsubscribe();
            this.stepSkippableSubscription = undefined;
            this.errorMessageSubscription?.unsubscribe();
            this.errorMessageSubscription = undefined;
            this.finishCurrentSubscription?.unsubscribe();
            this.finishCurrentSubscription = undefined;
            this.footerTemplateSubscription?.unsubscribe();
            this.footerTemplateSubscription = undefined;
            this.footerTemplates = undefined;
            this.errorMessage = undefined;
        }

        if (this.currentWorkflowStep?.componentSelector && this.container) {
            const type = WorkflowStepComponentRegistry.get(this.currentWorkflowStep.componentSelector);
            if (!type) {
                throw new Error(`component is not registered - ${this.currentWorkflowStep.componentSelector} - needs to use the WorkflowComponent class decorator`);
            }

            this.container.clear();
            this.currentCustomComponent = this.container.createComponent<IWorkflowStepComponent>(type);
            const instance = this.currentCustomComponent.instance;
            instance.workflowStep = this.currentWorkflowStep;
            instance.workflowConnection = this.data.workflowConnection;
            if (instance.workflowStepFinishCurrent) {
                this.finishCurrentSubscription = instance.workflowStepFinishCurrent.pipe(
                    switchMap((continueAfter = false) => {
                        continueAfter = typeof continueAfter === "boolean" ? continueAfter : false;
                        return this.isLastStep ? this.complete(false, continueAfter) : this.blockingNext();
                    }),
                    this.takeUntilDestroyed(),
                ).subscribe();
            }

            if (instance.workflowStepErrorMessage) {
                this.errorMessage = undefined;
                this.errorMessageSubscription = instance.workflowStepErrorMessage.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((msg) => this.errorMessage = msg);
            }

            if (instance.workflowStepFooterTemplates) {
                this.footerTemplateSubscription = instance.workflowStepFooterTemplates.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((templates) => this.footerTemplates = templates);
            }

            if (instance.workflowStepEntityChange) {
                this.stepEntityChangeSubscription = instance.workflowStepEntityChange.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((changedEntity) => ArrayUtilities.addElementIfNotAlreadyExists(this.entitiesToConfirm, changedEntity));
            }

            if (instance.workflowGoToStep) {
                this.goToStepSubscription = instance.workflowGoToStep.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((step) => {
                    if (step !== undefined && this.data?.workflow?.steps?.[step]) {
                        this.setCurrentWorkflowStep(this.data.workflow.steps[step]);
                    }
                });
            }

            if (FunctionUtilities.isFunction(instance.workflowStepOnInit)) {
                // this will be called if the step has the method implemented regardless of whether other steps have defined their data
                // - this is to allow step initialization after setting data
                instance.workflowStepOnInit!();
            }

            // record lastCompletedStepIdx so we can jump between completed steps
            if (instance.workflowStepCompleted) {
                this.currentStepCompleted = false;
                this.stepCompletionSubscription = instance.workflowStepCompleted.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((completed) => {
                    const currentOrdinal = this.currentWorkflowStep?.ordinal ?? 0;
                    this.lastCompletedStepIdx = completed
                        ? Math.max(this.lastCompletedStepIdx, currentOrdinal)
                        : currentOrdinal;
                    this.currentStepCompleted = completed;
                    this.progressSteps = this.getProgressSteps();
                });
            }

            // need a way to disable skip button too
            if (this.currentWorkflowStep.canSkip && instance.workflowStepSkipEnabled) {
                this.currentStepSkippable = false;
                this.stepSkippableSubscription = instance.workflowStepSkipEnabled.pipe(
                    this.takeUntilDestroyed(),
                    finalize(() => this.currentStepSkippable = true),
                ).subscribe((skippable) => this.currentStepSkippable = skippable);
            } else { // no observable defined in step - nothing stopping the step from being skipped
                this.currentStepSkippable = true;
            }

            if (instance.postWorkflow) {
                this.postWorkflowSteps[this.currentWorkflowStep.ordinal] = instance.postWorkflow.bind(instance);
            }

            // if component load is slow, will need to update as it may be wrapped after loading.
            this.updateImplementationKitSize();
        }
    }

    private setCurrentWorkflowStep(step?: WorkflowStep) {
        this.currentWorkflowStep = step;
        this.resetStepCompletion();
        this.stepUpdater.next(this.currentWorkflowStep);
        this.progressSteps = this.getProgressSteps();
        this.preloadNextStepGuidance();
    }

    private preloadNextStepGuidance() {
        const articleSlugs: ImplementationKitArticle[] = [];

        const nextStep = this.nextWorkflowStep;
        if (nextStep) {
            if (nextStep.articleSlug) {
                articleSlugs.push(nextStep.articleSlug);
            }

            if (nextStep.componentSelector === DisplayWorkflowActivityBriefComponentSelector) {
                const customData = nextStep.customData as IActivityBriefData;
                if (customData?.meetingDescriptionArticle) {
                    articleSlugs.push(customData.meetingDescriptionArticle);
                }
                if (customData?.meetingPreWorkArticle) {
                    articleSlugs.push(customData.meetingPreWorkArticle);
                }
            }
        } else if (this.isLastStep) {
            const rootWorkflow = this.rootWorkflow;
            if (rootWorkflow && rootWorkflow.wrapUpSlug) {
                articleSlugs.push(rootWorkflow.wrapUpSlug);
            }
        }

        if (articleSlugs.length > 0) {
            forkJoin(articleSlugs.map((slug) => this.implementationKitService.getArticle(slug))).subscribe();
        }
    }

    private resetStepCompletion() {
        if (!this.currentWorkflowStep?.deepDiveId) {
            // not deep dive -> step is already completed on entrance
            this.lastCompletedStepIdx = Math.max(this.lastCompletedStepIdx, this.currentWorkflowStep?.ordinal ?? 0);
            this.currentStepCompleted = true;
        } else {
            this.currentStepCompleted = false;
        }
    }

    private setDialogWidth(workflow?: Workflow, override?: WorkflowDialogWidth) {
        if (override) {
            this.dialogWidth = this.dialogWidthMappings[override];
            return;
        }

        if (workflow?.dialogWidth) {
            this.dialogWidth = this.dialogWidthMappings[workflow.dialogWidth];
        }
    }

    private getProgressSteps(): IProgressStep<WorkflowStep>[] {
        return this.data.workflow.steps!
            .filter((step) => !step.isConfirmationStep)
            .map((step, stepIndex) => ({
                title: step.name,
                data: step,
                completed: stepIndex < this.lastCompletedStepIdx,
                onClick: (targetStep, idx) => {
                    // first step typically does not have allowBack enabled (of course...)
                    if ((this.currentWorkflowStep!.allowBack || this.currentWorkflowStep!.ordinal === 0)
                        && this.lastCompletedStepIdx >= idx) {
                        this.setCurrentWorkflowStep(targetStep.data);
                    }
                    return of(undefined);
                },
            }));
    }

    public setRating(index: number) {
        this.workflowRating.rating = index + 1;
        this.ratings.forEach((rating, i) => {
            rating.checked = i <= index;
        });
    }

    public submitRating() {
        if (this.canSubmitRating()) {
            if (this.data.workflowConnection) {
                this.workflowRating = {
                    ...this.workflowRating,
                    workflowConnectionId: this.data.workflowConnection.workflowConnectionId,
                    organisationId: this.data.workflowConnection.organisationId,
                    organisationName: this.data.workflowConnection.organisation.name,
                };
                this.workflowService.submitRating(this.workflowRating).subscribe();
            } else {
                this.log.error("Failed to submit rating. workflowConnection is undefined");
            }
        }
    }

    public canSubmitRating() {
        return this.ratings.some((r) => r.checked) || this.workflowRating.improveOn || this.workflowRating.liked;
    }

    @HostListener("window:resize")
    public updateImplementationKitSize() {
        // there is only one of each in the template
        const parentElement = document.querySelector<HTMLElement>(".workflow-step-content");
        const implementationKitElement = document.querySelector<HTMLElement>(".implementation-kit");
        // only do this if there are implementation kit
        if (parentElement && implementationKitElement) {
            this.fullWidthImplementationKitUpdater.next(Math.floor(implementationKitElement.offsetWidth) >= Math.floor(parentElement.offsetWidth));
        }

        // need these to make the 'always' shown scrollbar to show or go away (if the page is not high enough to trigger the scroll)
        setTimeout(() => {
            this.implementationKitScrollViewComponent?.instance.update();
            this.stepComponentScrollViewComponent?.instance.update();
        });
    }
}

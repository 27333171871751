import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";

export class ExternalDashboard extends BaseEntity<ExternalDashboard> {
    public externalDashboardId!: number;
    public organisationId!: number;
    public teamId?: number;
    public name!: string;
    public url!: string;
    public isPublicRead!: boolean;
}

export const ExternalDashboardBreezeModel = new BreezeModelBuilder("ExternalDashboard", ExternalDashboard)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .build();

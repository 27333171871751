/* eslint-disable max-classes-per-file */
import { Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { KeyResultValue } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveComment } from "@common/ADAPT.Common.Model/organisation/objective-comment";
import { ObjectiveItemLink } from "@common/ADAPT.Common.Model/organisation/objective-item-link";
import { ObjectiveLink } from "@common/ADAPT.Common.Model/organisation/objective-link";
import { ObjectiveStatus } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { cacheLatest } from "@common/lib/utilities/rxjs-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { CommentChainComponent, IChainComment, IChainUpdate } from "@org-common/lib/comment-chain/comment-chain/comment-chain.component";
import { EntityUpdateUtilities } from "@org-common/lib/entity-sync/entity-update-utilities";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { EMPTY, merge, Observable, of, ReplaySubject, Subscription } from "rxjs";
import { filter, first, map, startWith, switchMap, take, tap } from "rxjs/operators";
import { KeyResultListItemLayout } from "../key-result-list-item.component/key-result-list-item.component";
import { IObjectiveTeamGroup, ObjectivesService } from "../objectives.service";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesRouteService } from "../objectives-route.service";
import { ObjectivesUiService } from "../objectives-ui.service";

@Component({
    selector: "adapt-edit-objective-page",
    templateUrl: "./edit-objective-page.component.html",
    styleUrls: ["./edit-objective-page.component.scss"],
})
export class EditObjectivePageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly FeatureName = FeatureName;

    //TODO: refactor in CM-5628
    public objectiveId$ = new ReplaySubject<number>(1);
    public primedObjective$: Observable<Objective>;
    public childObjectives$: Observable<IObjectiveTeamGroup[]>;
    public newComment$: Observable<ObjectiveChainComment> = EMPTY;
    public chainComments$: Observable<IChainComment<ObjectiveComment>[]>;
    public chainUpdates$: Observable<IChainUpdate<KeyResultValue>[]>;
    public lastObjective?: Objective;
    public hasEditPermissions = false;
    public isXL$: Observable<boolean>;
    public listItemLayout = KeyResultListItemLayout.Compact;

    @ViewChild(CommentChainComponent)
    public commentChain?: CommentChainComponent<ObjectiveComment, KeyResultValue>;

    private toastrSubscription: Subscription;
    private objectivesRouteService: ObjectivesRouteService;

    private accessSubscription?: Subscription;

    public constructor(
        private objectivesService: ObjectivesService,
        private objectivesUiService: ObjectivesUiService,
        private objectivesAuthService: ObjectivesAuthService,
        private responsiveService: ResponsiveService,
        entityUpdateUtilities: EntityUpdateUtilities,
        labellingService: LabellingService,
        injector: Injector,
    ) {
        super(injector);
        this.objectivesRouteService = injector.get(ObjectivesRouteService);
        this.primedObjective$ = this.objectiveId$.pipe(
            switchMap((objectiveId) => this.objectivesService.getPrimedObjective(objectiveId)),
            filter(ObjectUtilities.createIsInstanceFilter(Objective)),
            // prime label for objective
            switchMap((objective) => labellingService.getLabelLocationsForObjective(objective.objectiveId).pipe(
                map(() => objective),
            )),
            cacheLatest(), // Prevent unnecessary re-queries for each subscription.
            tap(() => this.notifyActivated()),
        );
        this.childObjectives$ = this.primedObjective$.pipe(
            map((obj) => objectivesService.groupObjectivesByTeam(obj.childObjectives)),
        );

        const remoteObjectiveUpdate$ = entityUpdateUtilities.onEntityChange(this.primedObjective$);
        this.chainUpdates$ = merge(this.primedObjective$, remoteObjectiveUpdate$).pipe(
            map(this.mapToCommentChainUpdate),
        );
        this.chainComments$ = merge(this.primedObjective$, remoteObjectiveUpdate$).pipe(
            map((objective) => objective.comments.map((c) =>
                new ObjectiveChainComment(c, this.hasEditPermissions))),
        );

        this.primedObjective$.subscribe((objective) => this.lastObjective = objective);

        this.primedObjective$.pipe(
            take(1),
            switchMap((objective) => this.objectivesAuthService.hasWriteAccessToObjective(objective.teamId)),
        ).subscribe((hasAccess) => this.hasEditPermissions = hasAccess);

        this.isXL$ = this.responsiveService.currentBreakpoint$.pipe(
            map((breakpoint) => breakpoint.is(Breakpoint.XL)),
        );

        this.toastrSubscription = entityUpdateUtilities
            .displayToasterOnEntityChange(this.primedObjective$, (o) => `<i>${o.title}</i>`)
            .subscribe();
    }

    public get isClosed() {
        return this.lastObjective && this.lastObjective.status === ObjectiveStatus.Closed;
    }

    @Autobind
    private mapToCommentChainUpdate(objective: Objective): IChainUpdate<KeyResultValue>[] {
        const keyResultUpdates = objective.keyResults.flatMap((kr) => kr.values)
            .map((keyResultValue) => new KeyResultChainUpdate(keyResultValue, this.hasEditPermissions));

        return keyResultUpdates;
    }

    public ngOnInit() {
        this.navigationEnd.pipe(
            startWith(undefined),
        ).subscribe(() => this.refresh());
    }

    @Autobind
    public refresh() {
        const objectiveId = this.getRouteParamInt("objectiveId");
        this.objectiveId$.next(objectiveId!);

        const teamId = this.getRouteParamInt("teamId");
        this.accessSubscription?.unsubscribe();
        this.accessSubscription = this.objectivesAuthService.hasWriteAccessToObjective(teamId).subscribe((hasAccess) => {
            if (hasAccess) {
                this.newComment$ = this.primedObjective$.pipe(
                    first(),
                    switchMap((objective) => this.objectivesService.createObjectiveComment(objective)),
                    map((comment) => new ObjectiveChainComment(comment, true)),
                );
            } else {
                this.newComment$ = EMPTY;
            }
        });

        this.verifyHasAccessToRoute(this.objectivesAuthService.hasReadAccessToObjective(teamId));
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.objectiveId$.complete();
        this.toastrSubscription.unsubscribe();
        this.accessSubscription?.unsubscribe();
    }

    public removeObjectiveLink(link: ObjectiveLink) {
        this.objectivesUiService.promptToDeleteObjectiveLink(link)
            .subscribe();
    }

    public removeObjectiveItemLink(link: ObjectiveItemLink) {
        this.objectivesUiService.promptToDeleteObjectiveItemLink(link)
            .subscribe();
    }

    @Autobind
    public onObjectiveDeleted(deletedObjective: Objective) {
        this.objectivesRouteService.navigateToObjectivePageRoute(deletedObjective.teamId);
    }

    @Autobind
    public deleteChainUpdate(chainUpdate: IChainUpdate<KeyResultValue>) {
        this.primedObjective$.pipe(
            take(1),
            switchMap((objective) => {
                const keyResultValue = chainUpdate.updateEntity;
                if (keyResultValue) {
                    const updateComment = this.findMatchingComment(objective, keyResultValue);
                    return this.objectivesUiService.promptToDeleteKeyResultValue(keyResultValue, updateComment).pipe(
                        tap(() => this.refresh()),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ).subscribe();
    }

    @Autobind
    public editChainUpdate(chainUpdate: IChainUpdate<KeyResultValue>) {
        this.primedObjective$.pipe(
            take(1),
            switchMap((objective) => {
                const keyResultValue = chainUpdate.updateEntity;
                if (keyResultValue) {
                    const updateComment = this.findMatchingComment(objective, keyResultValue);
                    if (!updateComment) {
                        return this.objectivesService.createObjectiveComment(objective).pipe(
                            tap((comment) => comment.dateTime = chainUpdate.dateTime),
                            map((newComment) => ({ updateComment: newComment, keyResultValue })),
                        );
                    } else {
                        return of({ updateComment, keyResultValue });
                    }
                } else {
                    return EMPTY;
                }
            }),
            switchMap((updateData) => {
                const sortedValues = updateData.keyResultValue.keyResult.values.sort(
                    (a, b) => a.dateTime.getTime() - b.dateTime.getTime());
                const editValueIndex = sortedValues.indexOf(updateData.keyResultValue);
                const previousValue = editValueIndex > 0 ? sortedValues[editValueIndex - 1] : undefined;

                return this.objectivesUiService.editKeyResultValue(updateData.keyResultValue, updateData.updateComment, previousValue)
                    .pipe(tap(() => this.refresh()));
            }),
        ).subscribe();
    }

    public addKeyResult() {
        this.primedObjective$.pipe(
            first(),
            switchMap((objective) => this.objectivesUiService.createKeyResultForObjective(objective)),
        ).subscribe();
    }

    public addObjectiveLink() {
        this.primedObjective$.pipe(
            first(),
            switchMap((objective) => this.objectivesUiService.addObjectiveLinkForObjective(objective)),
        ).subscribe();
    }

    public addObjectiveItemLink() {
        this.primedObjective$.pipe(
            first(),
            switchMap((objective) => this.objectivesUiService.addObjectiveItemLinkForObjective(objective)),
        ).subscribe();
    }

    private findMatchingComment(objective: Objective, keyResultValue: KeyResultValue) {
        return objective.comments.find((comment) =>
            comment.dateTime.getTime() === keyResultValue.dateTime.getTime());
    }
}

class ObjectiveChainComment implements IChainComment<ObjectiveComment> {
    public constructor(
        public readonly entity: ObjectiveComment,
        public readonly canUpdate: boolean,
    ) { }

    public get person() {
        return this.entity.person;
    }

    public get dateTime() {
        return this.entity.dateTime;
    }

    public get comment() {
        return this.entity.comment;
    }

    public set comment(value: string) {
        this.entity.comment = value;
    }
}

class KeyResultChainUpdate implements IChainUpdate<KeyResultValue> {
    public constructor(
        public readonly updateEntity: KeyResultValue,
        public readonly canUpdate: boolean,
    ) { }

    public get person() {
        return this.updateEntity.person;
    }

    public get dateTime() {
        return this.updateEntity.dateTime;
    }

    public get iconClass() {
        if (this.updateEntity.keyResult) {
            return this.updateEntity.keyResult.iconClass;
        } else {
            // keyResult won't be there if key result value is deleted. same for text below.
            return "fal fa-trash-alt";
        }
    }

    public get text() {
        if (this.updateEntity.keyResult) {
            return `Updated key result '${this.updateEntity.keyResult.title}' to ${this.updateEntity.formattedValue}`;
        } else {
            return "<key result value deleted>";
        }

    }
}

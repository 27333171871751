import { SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { getHealthCheckPageTour } from "@org-common/lib/survey/health-check-tour";


export const HealthCheckTour: GuidedTour = [{
    title: "Open the menu",
    text: `<p>This menu allows you to navigate between pages on the platform.
        The Health Check result can be accessed from under the <b>Organisation</b> menu section.
        <p><b>Click on here now</b> to open the menu.</p>`,
    attachTo: {
        element: "#sidebar-menu i",
        on: "right",
    },
    advanceOn: {
        selector: "#sidebar-menu",
        event: "click",
    },
    showOn: () => {
        // this step will only be shown if Organisation Diagnostic node is not there
        const orgDiagNode = GuidedTourUtils.getFirstElementWithTextContent(
            "adapt-display-link-hierarchy adapt-display-navigation-node span",
            SurveyTypeLabel.OrganisationDiagnostic);
        return !GuidedTourUtils.isElementVisible(orgDiagNode);
    },
}, {
    waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
    elementSelectorTextContent: SurveyTypeLabel.OrganisationDiagnostic, // looking for <adapt-display-navigation-node> with span content of this text
    // cannot use 'advanceOn' too as the selector is only accepting string selector - cannot pass in element like attachTo
    // and this is a dynamic node - cannot use [data-tour="identifier"] selector
    advanceOnAttachedElementEvent: "click",
    attachTo: {
        on: "bottom-end",
    },
    title: `Access Health Check`,
    text: `<p>The result of your health check survey is available here.</p>
        <p><b>Click on it now</b> to show the summary report.</p>`,
    modalOverlayOpeningPadding: 3,
}, {
    title: "Survey summary page",
    text: "This is the health check survey summary page.",
    buttons: DefaultNextButtons,
}, ...getHealthCheckPageTour(true)];



import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { IConfigGroup } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";

export const allOrganisationConfigGroups = (): IConfigGroup[] => [
    {
        key: "System",
        items: [
            {
                name: "Organisation Profile",
                area: ConfigArea.OrganisationProfile,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.OrganisationProfileOverview,
                supportText: "Find out more about configuring your organisation profile in adapt HQ",
            }, {
                name: "Subscription & Billing",
                area: ConfigArea.Billing,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisationBilling,
                supportArticleId: ImplementationKitArticle.BillingOverview,
                supportText: "Find out more about billing in adapt HQ",
            }, {
                name: "Calendar Integration",
                area: ConfigArea.CalendarIntegration,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.CalendarIntegrationOverview,
                supportText: "Find out more about calendar integration in adapt HQ",
            }, {
                name: "Feature Flags",
                area: ConfigArea.FeatureFlags,
                accessVerifier: ConfigurationAuthService.StakeholderManagerAccess,
            }, {
                name: "Pathway Completion",
                area: ConfigArea.PathwayCompletion,
                accessVerifier: ConfigurationAuthService.StakeholderManagerAccess,
            },
        ],
    }, {
        key: "Organisation",
        items: [
            {
                name: "Personal Profile Categories",
                area: ConfigArea.PersonalProfileCategories,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.PersonalProfileCategoriesOverview,
                supportText: "Find out more about personal profile categories in adapt HQ",
            }, {
                name: "Labels",
                area: ConfigArea.Labels,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.LabelsOverview,
                supportText: "Find out more about effective use of labels in adapt HQ",
            },
        ],
    }, {
        key: "Strategy",
        items: [
            {
                name: "Purpose & Vision",
                area: ConfigArea.PurposeAndVision,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.PurposeAndVision,
                supportArticleId: ImplementationKitArticle.PurposeOverview,
                supportText: "Find out more about purpose and vision in adapt HQ",
            }, {
                name: "Values",
                area: ConfigArea.ValuesConstitution,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CompassValuesConstitution,
                supportArticleId: ImplementationKitArticle.ValuesOverview,
                supportText: "Find out more about values in adapt HQ",
            }, {
                name: "Strategy Board & Strategic Themes",
                area: ConfigArea.StrategyBoard,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategyBoard,
                supportArticleId: ImplementationKitArticle.ThemesOverview,
                supportText: "Find out more about the strategy board and strategic themes in adapt HQ",
            }, {
                name: "Strategic Inputs",
                area: ConfigArea.StrategicInputs,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategicInputs,
                supportArticleId: ImplementationKitArticle.InputsOverview,
                supportText: "Find out more about strategic inputs in adapt HQ",
            }, {
                name: "Bullseye",
                area: ConfigArea.Bullseye,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.Bullseye,
                supportArticleId: ImplementationKitArticle.BullseyeOverview,
                supportText: "Find out more about bullseye in adapt HQ",
            }, {
                name: "Strategic Anchors",
                area: ConfigArea.StrategicAnchors,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategicAnchors,
                supportArticleId: ImplementationKitArticle.StrategicAnchorsOverview,
                supportText: "Find out more about strategic anchors in adapt HQ",
            }, {
                name: "Strategic Goals",
                area: ConfigArea.StrategicGoals,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategicGoals,
                supportArticleId: ImplementationKitArticle.StrategicGoalsOverview,
                supportText: "Find out more about strategic goals in adapt HQ",
            }, {
                name: "Objectives & Key Results",
                area: ConfigArea.ObjectivesAndKeyResults,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StewardshipObjectives,
                supportArticleId: ImplementationKitArticle.ObjectivesOverview,
                supportText: "Find out more about objectives in adapt HQ",
            },
        ],
    }, {
        key: "Teams",
        items: [
            {
                name: "Teams",
                area: ConfigArea.Team,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.TeamsOverview,
                supportText: "Find out more about teams in the adapt HQ",
            }, {
                name: "Actions",
                area: ConfigArea.Kanban,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StewardshipWorkKanban,
                supportArticleId: ImplementationKitArticle.WorkOverview,
                supportText: "Find out more about using the team action boards in adapt HQ",
            },
        ],
    }, {
        key: "Systems",
        items: [],
    }, {
        key: "Culture",
        items: [],
    },
];

import { Injector } from "@angular/core";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { Logger } from "@common/lib/logger/logger";
import { SignalRInvokeError } from "@common/lib/signalr-provider/signalr-invoke-error";
import { lastValueFrom } from "rxjs";
import { IEntityAutoUpdaterTemplate } from "../entity-auto-updater-template.interface";
import { EntityUpdateType, IEntityUpdate } from "../entity-updates.interface";
import { AddedEntityAutoUpdater } from "./added-entity-auto-updater";
import { DeletedEntityAutoUpdater } from "./deleted-entity-auto-updater";
import { IEntityAutoUpdater } from "./entity-auto-updater.interface";
import { ModifiedEntityAutoUpdater } from "./modified-entity-auto-updater";
import { NonImplementedAutoUpdater } from "./not-implemented-auto-updater";

export class EntityAutoUpdaterFactory {
    private commonDataService: CommonDataService;

    private autoUpdateTemplatesByType: { [type: string]: IEntityAutoUpdaterTemplate } = {};

    public constructor(
        private injector: Injector,
        autoUpdateTemplates: IEntityAutoUpdaterTemplate[],
    ) {
        this.commonDataService = injector.get(CommonDataService);

        for (const autoUpdateTemplate of autoUpdateTemplates) {
            this.autoUpdateTemplatesByType[autoUpdateTemplate.entityModel.toType] = autoUpdateTemplate;
        }
    }

    public async fromEntityUpdate(entityUpdate: IEntityUpdate): Promise<IEntityAutoUpdater> {
        const autoUpdaterTemplate = this.autoUpdateTemplatesByType[entityUpdate.EntityType];
        if (!autoUpdaterTemplate) {
            return new NonImplementedAutoUpdater(entityUpdate);
        }

        const localEntity = this.commonDataService.getLocalModelEntityById(autoUpdaterTemplate.entityModel, entityUpdate.EntityId);
        switch (entityUpdate.UpdateType) {
            case EntityUpdateType.Added:
                return new AddedEntityAutoUpdater(this.injector, autoUpdaterTemplate, entityUpdate, localEntity);
            case EntityUpdateType.Modified:
                let entityFromServer: IBreezeEntity | undefined;
                try {
                    entityFromServer = await lastValueFrom(this.commonDataService.getById(autoUpdaterTemplate.entityModel, entityUpdate.EntityId, true));
                } catch (e) {
                    // if no permission, can be this person no longer has access to the entity
                    entityFromServer = undefined;
                    Logger.getLogger(EntityAutoUpdaterFactory.name).error(`${SignalRInvokeError.SignalRMessageId}: Exception fetching entity from update`, e);
                }

                // Example of this is where kanban item is moved between boards (not new entity but just a 'modified' - user may gain or lose access to the item)
                if (!entityFromServer && localEntity) {
                    // there is a local entity but cannot get another one from server -> delete (item moved to another board)
                    return new DeletedEntityAutoUpdater(this.injector, autoUpdaterTemplate, entityUpdate, localEntity);
                } else if (!localEntity && entityFromServer) {
                    // no local entity but there is an entity from server -> add (item moved back to the board acessible by current person)
                    return new AddedEntityAutoUpdater(this.injector, autoUpdaterTemplate, entityUpdate, localEntity);
                } else if (entityFromServer) { // only modify if still has access to entity from server
                    return new ModifiedEntityAutoUpdater(this.injector, autoUpdaterTemplate, entityUpdate, localEntity);
                }

                Logger.getLogger(EntityAutoUpdaterFactory.name).warn(`Modified entity fell through case statement - investigate!`);
                return new DeletedEntityAutoUpdater(this.injector, autoUpdaterTemplate, entityUpdate, localEntity);

            case EntityUpdateType.Deleted:
                return new DeletedEntityAutoUpdater(this.injector, autoUpdaterTemplate, entityUpdate, localEntity);
            default:
                throw new Error("Invalid EntityUpdateType in entityAutoUpdater");
        }
    }
}

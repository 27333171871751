import { Component, Input } from "@angular/core";
import { SearchType } from "@org-common/lib/search/search.interface";
import { SearchService } from "@org-common/lib/search/search.service";

@Component({
    selector: "adapt-search-row-elements",
    templateUrl: "./search-row-elements.component.html",
})
export class SearchRowElementsComponent {
    @Input() public type!: SearchType;
    @Input() public searchResults: any;

    public searchType = SearchType;
    public sortByTeamId = SearchService.SortByTeamId;
    public isValueStream = SearchService.isValueStream;
    public isKeyFunction = SearchService.isKeyFunction;
    public isPurposeVision = SearchService.isPurposeVision;
    public isProduct = SearchService.isProduct;
    public isValue = SearchService.isValue;
    public isResilientBusinessGoal = SearchService.isResilientBusinessGoal;
}

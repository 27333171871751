<div class="card-header"
     [ngClass]="{'collapsible': isCollapsible, 'collapsed': collapsed}"
     (click)="toggle()">
    <i *ngIf="iconClass"
       [ngClass]="iconClass"
       class="flex-shrink-0 me-2"
       [adaptTooltip]="iconTooltip"></i>

    <h2>{{name}}
        <i *ngIf="info"
           class="ms-1 fal fa-circle-info"
           [adaptTooltip]="info"></i>
    </h2>

    <div class="ms-lg-auto mt-2 mt-lg-0 d-flex align-items-center gap-3">
        <div *ngIf="linkText">
            <a *ngIf="linkObject; else noUrlTemplate"
               [routerLink]="linkObject.path"
               [queryParams]="linkObject.queryParams">
                {{linkText}} <i class="ms-1 fas fa-arrow-right"></i>
            </a>

            <ng-template #noUrlTemplate>
            <span *ngIf="hasClickSubscribers"
                  class="clickable-header"
                  (click)="onHeaderClicked()">
                {{linkText}} <i class="ms-1 fas fa-arrow-right"></i>
            </span>
            </ng-template>
        </div>

        <ng-content select="[adapt-collapsible-header-action-right]"></ng-content>

        <i *ngIf="isCollapsible && isExpandable"
           class="fal {{collapsed ? 'fa-chevron-down' : 'fa-chevron-up'}}"></i>
    </div>
</div>

<div *ngIf="isExpandable"
     class="card-body"
     [class.nested]="nested"
     [collapse]="collapsed"
     [isAnimated]="true">
    <div *ngIf="contentCard; else content"
         class="card">
        <div class="card-body inner-card-body">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
    </div>

    <ng-template #content><ng-content></ng-content></ng-template>
</div>

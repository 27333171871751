import { Component, Input } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";

@Component({
    selector: "adapt-item-listing-search",
    templateUrl: "./item-listing-search.component.html",
    styleUrls: ["./item-listing-search.component.scss"],
})
export class ItemListingSearchComponent {
    @Input() public item!: Item;

    public itemStatusMetadata = ItemStatusMetadata;
}

import { DefaultBackTourButton, DefaultFinishButtons, DefaultNextButtons, DefaultOKButtons, DefaultStartButtons, GuidedTour, GuidedTourStepOptions } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";


const TakeTourAgainStep: GuidedTourStepOptions = {
    title: "Take the tour again",
    text: "You can take this tour again by clicking this button.",
    waitForAndAttachToElementSelector: `[data-tour="personal-dashboard-tour"]`,
    attachTo: {
        on: "bottom-start",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultOKButtons[0]],
};

export const AltoShellTourButton: GuidedTour = [{
    ...TakeTourAgainStep,
    title: "Take the tour later.",
    text: "Take the tour anytime by clicking this button.",
    buttons: DefaultOKButtons,
}];

export const AltoShellTour: GuidedTour = [{
    title: "Go to your personal dashboard",
    text: `<p>This menu allows you to navigate between pages on the platform.
        Your Vision can be accessed from under the <b>Organisation</b> menu section.
        <p><b>Click on here now</b> to open the menu.</p>`,
    attachTo: {
        element: `[id="sidebar-personal"]`,
        on: "right",
    },
    advanceOn: {
        selector: `[id="sidebar-personal"]`,
        event: "click",
    },
    showOn: () => {
        // this step will only be shown if Purpose & Vision node is not there
        const personalDashboardTourNode = GuidedTourUtils.getFirstElementWithTextContent(
            `[data-tour="personal-dashboard-tour"]`);
        return !GuidedTourUtils.isElementVisible(personalDashboardTourNode);
    },
}, {
    title: "Personal dashboard",
    text: "Clicking here will take you to your personal dashboard.<br/><br/>There you can view your active pathways, meetings, actions and objectives.",
    waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
    attachTo: {
        on: "right",
    },
    modalOverlayOpeningPadding: 3,
    buttons: DefaultStartButtons,
}, {
    title: "Team dashboard",
    text: "Clicking here will take you to the team dashboard.<br/><br/>There you can view the leadership team, upcoming meetings, actions and team guidance.",
    waitForAndAttachToElementSelector: `[id="sidebar-team"]`,
    attachTo: {
        on: "right",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Organisation dashboard",
    text: "Clicking here will take you to your organisation dashboard.<br/><br/>There you can view the organisation's purpose, vision and values.",
    waitForAndAttachToElementSelector: `[id="sidebar-organisation"]`,
    attachTo: {
        on: "right",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Strategy on a page",
    text: "Clicking here will take you to your 'strategy on a page' dashboard.<br/><br/>This is the primary page to see your entire organisation's strategy on a single page.",
    waitForAndAttachToElementSelector: `[id="sidebar-strategy"]`,
    attachTo: {
        on: "right",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Search",
    text: `The search bar will allow you to search for anything inside the adapt HQ platform, such as people, teams, roles, work, objectives or support articles.`,
    waitForAndAttachToElementSelector: `[id="sidebar-search"]`,
    attachTo: {
        on: "right",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Configuration",
    text: "Here you can manage the platforms configuration, such as your subscription, billing, calendar integration and other features.",
    waitForAndAttachToElementSelector: `[id="sidebar-configuration"]`,
    attachTo: {
        on: "right",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Help",
    text: "Get support articles, help & documentation for the adapt HQ platform.",
    waitForAndAttachToElementSelector: `[id="support-application-bar-item"]`,
    attachTo: {
        on: "right",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Chat with us",
    text: "Or you can chat with us if you have any issues or need help with the platform - this is available by clicking the speech bubble in the bottom right corner.",
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Menu",
    text: `Click here to collapse/expand the menu. <br/><br/>
    You can also navigate to the main menu by clicking here or on either the personal, team, organisation or strategy on a page icons.`,
    waitForAndAttachToElementSelector: `[id="sidebar-menu"]`,
    attachTo: {
        on: "right",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Toggle menu",
    text: "Collapse & expand the menu using this button to give you more space to work with.",
    waitForAndAttachToElementSelector: `[data-tour="sidebar-toggle"]`,
    attachTo: {
        on: "right",
    },
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Navigation breadcrumbs",
    text: "Page breadcrumbs show here in the top navigation area. The breadcrumbs are clickable for easy navigation.",
    waitForAndAttachToElementSelector: `[id="navigation-breadcrumbs"]`,
    attachTo: {
        on: "bottom",
    },
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Everything about you",
    text: "From here you can edit your profile (such as your name & photo), or simply logout of the platform.",
    waitForAndAttachToElementSelector: `[data-tour="personal-menu"]`,
    attachTo: {
        on: "left",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    ...TakeTourAgainStep,
    buttons: [DefaultBackTourButton, DefaultFinishButtons[0]],
}];

import { Component, Input, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import isEqual from "lodash.isequal";
import { EMPTY, merge } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ColumnSizePresets, ILayoutBase } from "../layout.interface";
import { LayoutManagerComponent } from "../layout-manager/layout-manager.component";

@Component({
    selector: "adapt-layout-manager-controls",
    templateUrl: "./layout-manager-controls.component.html",
    styleUrls: ["./layout-manager-controls.component.scss"],
})
export class LayoutManagerControlsComponent extends BaseComponent implements OnInit {
    @Input() public layoutManager!: LayoutManagerComponent;

    public saveError?: string;
    public menuItems!: IAdaptMenuItem[];

    public ngOnInit() {
        merge(
            this.layoutManager.initialised,
            this.layoutManager.layoutUpdated$,
            this.layoutManager.isEditing$,
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateMenu());

        // clear error when entering/exiting edit mode
        this.layoutManager.isEditing$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.saveError = undefined);
    }

    @Autobind
    public saveLayout() {
        this.saveError = undefined;
        return this.layoutManager.saveLayout().pipe(
            catchError((e) => {
                this.saveError = ErrorHandlingUtilities.getHttpResponseMessage(e);
                return EMPTY;
            }),
            switchMap(() => this.layoutManager.toggleEditing(false)),
        );
    }

    private getColumnSizes(layout?: ILayoutBase) {
        if (!layout) {
            return [];
        }
        return layout.columns.filter((col) => col.options.enabled)
            .map((col) => col.size ?? "");
    }

    private updateMenu() {
        const presets = { ...ColumnSizePresets };

        const enabledColumnSizes = this.getColumnSizes(this.layoutManager.currentLayout);

        this.menuItems = [
            {
                text: "Page Columns",
                icon: "fal fa-columns",
                items: Object.entries(presets).map(([text, sizes]) => ({
                    text,
                    icon: "",
                    onClick: () => this.layoutManager.resizeLayout(sizes).subscribe(),
                    isChecked: isEqual(enabledColumnSizes, sizes),
                })) as IAdaptMenuItem[],
            },
        ];
    }
}

import { Component, EventEmitter, Inject, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemLink } from "@common/ADAPT.Common.Model/organisation/item-link";
import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { ObjectiveItemLink } from "@common/ADAPT.Common.Model/organisation/objective-item-link";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { UserService } from "@common/user/user.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { SelectPersonComponent } from "@org-common/lib/directory-shared/select-person/select-person.component";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { ObjectivesService } from "@org-common/lib/objectives/objectives.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { lastValueFrom, Observable, of } from "rxjs";
import { filter, map, startWith, switchMap, tap } from "rxjs/operators";
import { KanbanService } from "../../kanban.service";
import { KanbanAuthService } from "../../kanban-auth.service";
import { ItemUtilities } from "../item-utilities";

export interface IEditItemDialogOptions {
    item: Item;
    forceAllBoards: boolean;
    hidePersonalBoards?: boolean;
}

export type EditItemMode = "Add" | "Edit" | "View";

export enum EditItemAction {
    Move = "move",
    Delete = "delete",
    Close = "close",
}

@Component({
    selector: "adapt-edit-item-dialog",
    templateUrl: "./edit-item-dialog.component.html",
    styleUrls: ["./edit-item-dialog.component.scss"],
})
@PersistableDialog("EditItemDialog")
export class EditItemDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Item> implements OnInit {
    public readonly EditItemAction = EditItemAction;
    public readonly dialogName = "adaptEditItem";

    @Input() public onEditingLinkedItem?: (item: Item) => PromiseLike<boolean>;
    @Output() public itemAction = new EventEmitter<EditItemAction>();

    @ViewChildren(SelectPersonComponent) public selectPersonComponents?: QueryList<SelectPersonComponent>;

    public mode: EditItemMode = "View";
    public addMode = false;
    public canEdit = false;
    private hasEditPermission = false;
    public itemHasBeenDeleted = false;
    private currentPerson?: Person;
    public objectiveItemLinks: ObjectiveItemLink[] = [];
    public isValidBoardSelection = true;
    public itemTeam?: Team;
    private boardTeamMembers: Person[] = [];
    public teamBoardsOnly = false;
    public loadingRelatedInfo = true;
    public forceAllBoards = false;
    public hidePersonalBoards = false;
    public item!: Item;
    public isMobileSize = false;

    public itemLabels$: Observable<Label[]> = of([]);
    public changedLabelLocations: LabelLocation[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IEditItemDialogOptions,
        private dialogService: AdaptCommonDialogService,
        protected commonDataService: CommonDataService,
        private userFactory: UserService,
        private kanbanService: KanbanService,
        private kanbanAuthService: KanbanAuthService,
        private commonTeamsService: CommonTeamsService,
        private objectivesService: ObjectivesService,
        private rxjsBreezeService: RxjsBreezeService,
        private entityPersistentService: EntityPersistentService,
        private labellingService: LabellingService,
        responsiveService: ResponsiveService,
    ) {
        super();
        this.item = data.item;
        this.forceAllBoards = data.forceAllBoards ?? false;
        this.hidePersonalBoards = data.hidePersonalBoards ?? false;

        this.autoResolveData = this.item;

        responsiveService.currentBreakpoint$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((breakpoint) => this.isMobileSize = breakpoint.isMobileSize);

        // issuing a separate query instead of relying on the kanban to do a single query
        // as this dialog can possibly be spawn from other pages other than kanban page
        // - should only do this if label location is not primed however we can't really tell
        //   if an item has no label or not primed (both Array(0))
        const getLabelLocations = this.item.labelLocations?.length
            ? of(this.item.labelLocations)
            : this.labellingService.getLabelLocationsForItem(this.item.itemId);
        this.itemLabels$ = getLabelLocations.pipe(
            map((labelLocations) => labelLocations.map((i) => i.label)),
        );
    }

    public get entitiesToConfirm() {
        return [
            this.item,
            ...this.commentsToSave,
            ...this.item.links,
            ...this.changedLabelLocations,
        ].filter((entity) => !!entity);
    }

    private get commentsToSave() {
        // don't try to save comments which are invalid
        return this.item.comments.filter((ent) => !ent.entityAspect.entityState.isAdded() || ent.entityAspect.validateEntity());
    }

    public get hasUnsavedEntity() {
        return this.entitiesToConfirm.some((entity: IBreezeEntity) => this.entityPersistentService.isUnsavedEntity(entity)) ||
            // label location won't be persisted - so have to check differently
            !!this.item.labelLocations.find((i) => !i.entityAspect.entityState.isUnchanged()) || this.changedLabelLocations.length > 0;
    }

    public get isInvalidOrNoChanges() {
        // only check items that are not added for validation
        return this.entitiesAreUnmodifiedOrInvalid || !this.hasUnsavedEntity || !this.isValidBoardSelection;
    }

    public get itemIsClosed() {
        return this.item.status === ItemStatus.Closed;
    }

    public async ngOnInit() {
        this.rxjsBreezeService.entityTypeChanged(Item).pipe(
            filter((item) => item === this.item),
            startWith(this.item),
            tap(this.checkForItemDeletionAndRefreshDisplayedData),
            switchMap((item) => this.objectivesService.getObjectiveItemLinksForItem(item)),
            this.takeUntilDestroyed(),
            this.takeUntilDialogFinalised(),
        ).subscribe((objectiveItemLinks) => {
            this.objectiveItemLinks = objectiveItemLinks;
        });

        if (!this.forceAllBoards) {
            this.itemTeam = this.item.board?.team;
        }

        let board: Board | undefined;
        let hasPersonalBoardAccess: boolean;
        [this.currentPerson, board, hasPersonalBoardAccess, this.hasEditPermission] = await Promise.all([
            this.userFactory.getCurrentPerson(),
            lastValueFrom(this.kanbanService.getBoardById(this.item.boardId)),
            lastValueFrom(this.kanbanAuthService.canEditPersonalStewardshipKanban()),
            lastValueFrom(this.kanbanAuthService.hasEditAccessToItem(this.item)),
        ]);

        this.teamBoardsOnly = !hasPersonalBoardAccess || this.hidePersonalBoards;

        if (board && board.teamId) {
            await lastValueFrom(this.commonTeamsService.getTeamById(board.teamId));
        }

        this.setItemData();
    }

    private sortLinks() {
        if (this.item && this.item.links && this.item.links.length > 0) {
            const statusAndItemCodeComparator = ItemUtilities.getItemLinkStatusAndItemCodeSortComparator(this.item);
            this.item.links.sort(statusAndItemCodeComparator);
        }
    }

    public async deleteLink(link: ItemLink) {
        const confirmDelete = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean({
            title: "Delete Link",
            message: "Are you sure you wish to delete this link? Link deletion will be saved immediately.",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }));

        if (confirmDelete) {
            await lastValueFrom(this.commonDataService.remove(link));
            await lastValueFrom(this.commonDataService.saveEntities(link));
            this.sortLinks();
        }
    }

    public setItemData() {
        this.loadingRelatedInfo = true;

        if (this.item) {
            this.onBoardChange(this.item.board);

            this.addMode = this.item.entityAspect.entityState.isAdded();
            this.mode = this.addMode
                ? "Add"
                : this.hasEditPermission
                    ? "Edit"
                    : "View";
            this.refreshEdit();

            // prime the data, but lets not wait for it
            this.kanbanService.primeRelatedItemData(this.item).pipe(
                tap(() => {
                    this.sortLinks();
                    this.loadingRelatedInfo = false;
                }),
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }

    public refreshEdit() {
        this.canEdit = (this.mode === "Add" || this.mode === "Edit") && !this.itemIsClosed;
    }

    public onBoardChange(newBoard?: Board) {
        if (newBoard && newBoard.isPersonalBoard) {
            this.item.assignee = this.currentPerson! as Person;
        }

        this.item.board = newBoard;

        if (newBoard && newBoard.isTeamBoard) {
            this.commonTeamsService.promiseToGetActiveTeamMembers(newBoard.team! as any as Team)
                .then(this.updateTeamMembers);
        }

        this.isValidBoardSelection = !!newBoard;
    }

    @Autobind
    public updateTeamMembers(teamMembers: Person[]) {
        this.boardTeamMembers = teamMembers;

        if (this.selectPersonComponents) {
            this.selectPersonComponents.forEach((component) => component.reload());
        }
    }

    @Autobind
    public hasAccessToView(person: Person) {
        const isActiveCoach = !!person.getLatestConnection(ConnectionType.Coach)?.isActive();
        return this.kanbanAuthService.personCanViewBoard(person, this.item.board)
            // Filter out people like TSLs who have Global write but we don't want them in the list
            && (this.item.board?.isPublicReadAccess || this.boardTeamMembers.indexOf(person) >= 0 || isActiveCoach);
    }

    @Autobind
    public checkForItemDeletionAndRefreshDisplayedData(item: Item) {
        if (!item || item.entityAspect.entityState.isDetached() || item.entityAspect.entityState.isDeleted()) {
            this.itemHasBeenDeleted = true;
            return;
        }

        this.sortLinks();
    }
}

<div *adaptLoading="!initialised">
    <dx-chart class="display-survey-history-chart"
              [dataSource]="chartData"
              [customizePoint]="customiseChartPoint"
              [adjustOnZoom]="false"
              [barGroupWidth]="hasMultiResponseGroups ? 80 : undefined"
              (onPointClick)="onPointClicked($event)"
              (onDone)="updateChartDimensionAndSubscribeSizeChange($event)">
        <dxo-tooltip [enabled]="true"
                     [customizeTooltip]="customiseChartTooltip"></dxo-tooltip>
        <dxo-argument-axis type="discrete">
            <dxo-label [customizeText]="customizeDate"></dxo-label>
        </dxo-argument-axis>
        <dxi-value-axis name="score"
                        position="left"
                        [valueMarginsEnabled]="false"
                        [showZero]="true"
                        [visualRange]="[0, 100]"
                        visualRangeUpdateMode="keep">
            <dxo-grid [visible]="true"></dxo-grid>
            <dxo-title text="Average Score, %"></dxo-title>
        </dxi-value-axis>
        <dxi-value-axis name="participation"
                        [valueMarginsEnabled]="false"
                        [showZero]="true"
                        [visualRange]="[0, 100]"
                        visualRangeUpdateMode="keep"
                        position="right">
            <dxo-grid [visible]="true"></dxo-grid>
            <dxo-title text="Participation Rate, %"></dxo-title>
        </dxi-value-axis>
        <dxi-series valueField="score"
                    axis="score"
                    tagField="scoreTag"
                    type="bar"
                    [showInLegend]="false"
                    name="Average Score">
            <dxo-point [visible]="true"></dxo-point>
        </dxi-series>
        <ng-container *ngIf="hasMultiResponseGroups">
            <dxi-series valueField="leaderScore"
                        axis="score"
                        tagField="leaderTag"
                        type="bar"
                        [showInLegend]="false"
                        name="Average Leader Score">
                <dxo-point [visible]="true"></dxo-point>
            </dxi-series>
            <dxi-series valueField="nonLeaderScore"
                        axis="score"
                        tagField="nonLeaderTag"
                        type="bar"
                        [showInLegend]="false"
                        name="Average Employee Score">
                <dxo-point [visible]="true"></dxo-point>
            </dxi-series>
        </ng-container>
        <dxi-series *ngFor="let series of legendSeries"
                    [name]="series.name"
                    [color]="series.color">
            <dxo-label [visible]="false"></dxo-label>
        </dxi-series>
        <dxi-series valueField="participation"
                    axis="participation"
                    tagField="participationTag"
                    argumentField="endTime"
                    type="spline"
                    color="#60a69f"
                    [width]="0.5"
                    name="Participation Rate">
            <dxo-label [visible]="false"></dxo-label>
            <dxo-point [visible]="true"
                       color="#5f8b95"
                       symbol="cross"
                       [size]="8"></dxo-point>
            <dxo-hover-style [width]=1></dxo-hover-style>
        </dxi-series>
        <dxo-common-series-settings valueField="zero"
                                    type="scatter"
                                    argumentField="endTime">
            <dxo-point [visible]="false"></dxo-point>
            <dxo-label [visible]="hasMultiResponseGroups"
                       [rotationAngle]="-90"
                       position="inside"
                       backgroundColor="#00000000"
                       [verticalOffset]="2000"
                       [horizontalOffset]="-2"
                       [customizeText]="customiseChartLabel">
                <dxo-font [size]="16"></dxo-font>
            </dxo-label>
        </dxo-common-series-settings>
        <dxo-legend verticalAlignment="bottom"
                    [columnCount]="4"
                    itemTextPosition="right"
                    horizontalAlignment="center"></dxo-legend>
    </dx-chart>
</div>

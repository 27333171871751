import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { CanvasType } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Measurement } from "@common/ADAPT.Common.Model/organisation/measurement";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ToolbarService } from "@common/shell/toolbar/toolbar.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StrategicGoalsService } from "@org-common/lib/strategic-goals/strategic-goals.service";
import { StrategicInputsService } from "@org-common/lib/strategic-inputs/strategic-inputs.service";
import { StrategyService } from "@org-common/lib/strategy/strategy.service";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { StrategicViewOption } from "@org-common/lib/strategy/strategy-view-constants";
import { debounceTime, forkJoin, merge, of, switchMap } from "rxjs";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { StrategyBoardComponent } from "../strategy-board/strategy-board.component";

export const StrategyBoardViewParam = "view";

@Component({
    selector: "adapt-strategy-board-page",
    templateUrl: "./strategy-board-page.component.html",
    styleUrls: ["./strategy-board-page.component.scss"],
})
export class StrategyBoardPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly EditStrategyBoard = StrategyAuthService.EditStrategyBoard;

    public expandGoals = false;
    public hasGoal = false;
    public hasTheme = false;

    public pageMenuItems: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [],
    }];
    public expandGoalsItemWithSeparator?: IAdaptMenuItem;
    public views = [StrategicViewOption.SWTInputs, StrategicViewOption.CAInputs, StrategicViewOption.Bullseye, StrategicViewOption.Goals];

    private mapComponentCaller = new QueuedCaller<StrategyBoardComponent | undefined>();
    // need this or you will get ExpressionChangedAfterItHasBeenCheckedError
    private mapComponentCallerUpdater = this.createThrottledUpdater<StrategyBoardComponent | undefined>(
        (component) => this.mapComponentCaller.setCallee(component));

    @ViewChild(MenuComponent) private menuInstance?: MenuComponent;
    @ViewChild(StrategyBoardComponent) public set mapComponent(component: StrategyBoardComponent | undefined) {
        this.mapComponentCallerUpdater.next(component);
    }

    public get mapComponent() {
        return this.mapComponentCaller.isSet
            ? this.mapComponentCaller.definedCallee
            : undefined;
    }

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private strategyService: StrategyService,
        private strategicGoalsService: StrategicGoalsService,
        rxjsBreezeService: RxjsBreezeService,
        private toolbarService: ToolbarService,
        private inputsService: StrategicInputsService,
        private bullseyeService: BullseyeService,
        private authorisationService: AuthorisationService,
        featuresService: FeaturesService,
    ) {
        super(injector, elementRef);
        merge(
            rxjsBreezeService.entityTypeChanged(Theme),
            rxjsBreezeService.entityTypeChanged(Goal),
            rxjsBreezeService.entityTypeChanged(Measurement),
        ).pipe(
            debounceTime(100), // just do 1 init even with multiple measurements and goal changes in a single save
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnInit());

        if (featuresService.isFeatureActive(FeatureName.StrategicInputs)) {
            this.inputsService.inputsAttachmentChanged$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe((canvasType) => {
                switch (canvasType) {
                    case CanvasType.CompetitorAnalysis:
                        this.ensureViewIsSelected(StrategicViewOption.CAInputs);
                        break;
                    case CanvasType.StrengthsWeaknessesTrends:
                        this.ensureViewIsSelected(StrategicViewOption.SWTInputs);
                        break;
                    default:
                        this.ensureViewIsSelected(StrategicViewOption.SWTInputs);
                        this.ensureViewIsSelected(StrategicViewOption.CAInputs);
                        break;
                }
            });
        }

        if (featuresService.isFeatureActive(FeatureName.Bullseye)) {
            this.bullseyeService.bullseyeAttachmentChanged$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe(() => this.ensureViewIsSelected(StrategicViewOption.Bullseye));
        }

        if (featuresService.isFeatureActive(FeatureName.StrategicGoals)) {
            this.strategicGoalsService.goalAdded$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe(() => this.ensureViewIsSelected(StrategicViewOption.Goals));
        }

        this.strategyService.themeAdded$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ensureViewIsSelected(StrategicViewOption.Themes));
    }

    public ngOnInit() {
        forkJoin([
            this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategicGoals),
            this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategyBoard),
        ]).pipe(
            switchMap(([canReadGoals, canReadBoard]) => forkJoin([
                canReadGoals ? this.strategicGoalsService.getAllGoals() : of([]),
                canReadBoard ? this.strategyService.getAllThemes() : of([]),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([goals, themes]) => {
            this.hasGoal = goals.length > 0;
            this.hasTheme = themes.length > 0;
            this.notifyActivated();
        });

        const viewValueString = this.getSearchParameterValue(StrategyBoardViewParam);
        if (viewValueString) {
            this.views = viewValueString.split(",") as StrategicViewOption[];
        }

        this.updateView(this.views);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        this.toolbarService.resetToolbar();
    }

    @Autobind
    public addTheme() {
        return this.strategyService.editThemeAfterCreate();
    }

    public updateView(selectedViews: StrategicViewOption[]) {
        this.views = selectedViews;
        if (selectedViews.length > 0) {
            this.setSearchParameterValue(StrategyBoardViewParam, selectedViews.join(","));
        } else {
            this.deleteSearchParameter(StrategyBoardViewParam);
        }

        this.mapComponentCaller.call((mapComponent) => {
            if (mapComponent) {
                if (!this.expandGoalsItemWithSeparator) {
                    this.expandGoalsItemWithSeparator = { ...mapComponent.expandGoalsItem };
                    this.expandGoalsItemWithSeparator.separator = true;
                }

                if (selectedViews.includes(StrategicViewOption.Goals)) {
                    if (!this.pageMenuItems[0].items!.includes(this.expandGoalsItemWithSeparator)) {
                        this.pageMenuItems[0].items!.push(this.expandGoalsItemWithSeparator, mapComponent.collapseGoalsItem);
                    }
                } else {
                    ArrayUtilities.removeElementFromArray(this.expandGoalsItemWithSeparator, this.pageMenuItems[0].items!);
                    ArrayUtilities.removeElementFromArray(mapComponent.collapseGoalsItem, this.pageMenuItems[0].items!);
                }

                this.menuInstance?.refresh();
            }
        });
    }

    // after adding goal/theme, the view should be selected or else the newly added entity will not be visible.
    private ensureViewIsSelected(view: StrategicViewOption) {
        this.mapComponentCaller.call((mapComponent) => {
            if (mapComponent) {
                if (!mapComponent.isViewSelected(view)) {
                    mapComponent.toggleView(view);
                }
            }
        });
    }
}

export const StrategyBoardPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-strategy-board-page", StrategyBoardPageComponent)
    .atOrganisationUrl("/strategy-board")
    .withTitle("Strategy Board")
    .verifyingFeatures(FeatureName.StrategyBoard)
    .verifyingAccess(StrategyAuthService.ReadStrategyBoard)
    .reloadOnSearch(false)
    .build();

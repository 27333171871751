import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { OrganisationCategoryValue, Workflow, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { filter, finalize, map, switchMap, tap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { WorkflowService } from "../workflow.service";
import { WorkflowAuthService } from "../workflow-auth.service";
import { RunWorkflowSearchParam } from "../workflow-journey-page/workflow-journey-page.component";

@Component({
    selector: "adapt-display-workflow-outcome-header",
    templateUrl: "./display-workflow-outcome-header.component.html",
    styleUrls: ["./display-workflow-outcome-header.component.scss"],
})
export class DisplayWorkflowOutcomeHeaderComponent extends BaseComponent implements OnInit {
    public readonly WorkflowType = WorkflowType;
    public readonly WorkflowStatusEnum = WorkflowStatusEnum;
    public readonly OrganisationCategoryValue = OrganisationCategoryValue;

    @Input() public workflow?: Workflow;
    @Input() public showStartButton: boolean = false;
    @Input() public style: "burgundy" | "wise-lime" = "burgundy";
    @Input() public small = false;
    @Input() public startWorkflow$: Observable<void> = new Subject();
    @Input() public skipOutcomeStep = false;
    @Output() public isCompleted = new EventEmitter<boolean>();

    public workflowConnection?: WorkflowConnection;
    public workflowStatus?: WorkflowStatus;
    public prerequisitesSatisfied = false;
    public canStartWorkflow = false;
    public canContinueWorkflow = false;
    public hasRequiredRoles = false;
    public hasWorkflowAccess = true;

    // keep these numbers in sync with "@common/shell/images/people/person (x).png"
    private personImages = Array(12)
        .fill(0)
        .map((_, idx) => `/content/shell/images/people/person (${idx + 1}).png`);

    // get a random person image each time this component is created
    public personImage = this.personImages[this.personImages.length * Math.random() | 0];

    private workflowRunning = false;

    public constructor(
        private authorisationService: AuthorisationService,
        private routeService: RouteService,
        private workflowService: WorkflowService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        rxjsBreezeService.entityTypeChanged(WorkflowStatus).pipe(
            filter((statusEntity) => statusEntity.workflowConnection === this.workflowConnection
                && !statusEntity.workflowId
                && !statusEntity.workflowStepId),
            switchMap((statusEntity) => this.updateCanContinueWorkflow().pipe(
                map(() => statusEntity),
            )),
            this.takeUntilDestroyed(),
        ).subscribe((statusEntity) => {
            this.workflowStatus = statusEntity;
            this.isCompleted.emit(this.workflowStatus?.status === WorkflowStatusEnum.Completed);
        });
    }

    public async ngOnInit() {
        this.hasWorkflowAccess = await this.authorisationService.promiseToGetHasAccess(WorkflowAuthService.EditWorkflow);

        // get status of workflow
        this.workflowService.getLatestWorkflowConnectionForWorkflow(this.workflow!).pipe(
            tap((workflowConnection) => this.workflowConnection = workflowConnection),
            switchMap((workflowConnection) => workflowConnection
                ? this.workflowService.getStatusForWorkflow(workflowConnection, this.workflow)
                : of(undefined)),
            tap((status) => {
                this.workflowStatus = status;
                this.isCompleted.emit(this.workflowStatus?.status === WorkflowStatusEnum.Completed);
            }),
            switchMap(() => this.workflowService.areWorkflowPrerequisitesSatisfied(this.workflow!)),
            tap((prerequisitesSatisfied) => this.prerequisitesSatisfied = prerequisitesSatisfied),
            this.takeUntilDestroyed(),
        ).subscribe();

        forkJoin([
            this.workflowService.canContinueWorkflow(this.workflow!),
            this.workflowService.canStartWorkflow(this.workflow!),
        ]).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(([canContinueWorkflow, canStartWorkflow]) => {
            this.canContinueWorkflow = canContinueWorkflow; // once off and then each time status is changed
            this.canStartWorkflow = canStartWorkflow; // once off
            this.hasRequiredRoles = this.workflowService.currentPersonHasRequiredRoles(this.workflow!);
        });

        this.startWorkflow$.pipe(
            filter(() => !this.workflowRunning),
            switchMap(() => this.startWorkflow()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get startButtonText() {
        switch (this.workflowStatus?.status) {
            case WorkflowStatusEnum.Current:
                return "Continue";
            case WorkflowStatusEnum.Completed:
                return "Restart";
            default:
                return "Start";
        }
    }

    public get isStarted() {
        return this.workflowStatus?.status === WorkflowStatusEnum.Current;
    }

    public get isStartButtonDisabled() {
        return !this.hasWorkflowAccess
            || !this.prerequisitesSatisfied
            || (this.isStarted && !this.canContinueWorkflow)
            || (!this.isStarted && !this.canStartWorkflow);
    }

    public get startButtonTooltip() {
        if (!this.hasWorkflowAccess) {
            return "You do not have permission to run pathways";
        } else if (this.workflow?.extensions.isComingSoon) {
            return "This pathway is coming soon!";
        } else if (!this.prerequisitesSatisfied) {
            return "Prerequisite pathways are not completed";
        } else if (this.isStarted && !this.canContinueWorkflow) {
            return this.hasRequiredRoles
                ? "You cannot continue the pathway as you are not in the team that started it"
                : "Unfortunately, you do not possess the necessary roles within the organisation to continue this pathway";
        } else if (!this.isStarted && !this.canStartWorkflow) {
            return this.hasRequiredRoles
                ? "You cannot start the pathway because you are not in the leadership team"
                : "Unfortunately, you do not possess the necessary roles within the organisation to start this pathway";
        } else {
            return undefined;
        }
    }

    @Autobind
    public startWorkflow() {
        return this.workflowService.createWorkflowConnectionForWorkflow(this.workflow!).pipe(
            tap(({ workflowConnection, workflowStatus }) => {
                this.workflowConnection = workflowConnection;
                this.workflowStatus = workflowStatus;
            }),
            tap(() => {
                // we only show the start button on the journey page, so we should update the runWorkflow param when starting
                if (this.showStartButton) {
                    this.routeService.updateSearchParameterValue(RunWorkflowSearchParam, true, true);
                }
                this.workflowRunning = true;
            }),
            switchMap(({ workflowConnection }) => this.executeJourney(workflowConnection!)),
        );
    }

    private executeJourney(connection: WorkflowConnection) {
        return this.workflowService.executeJourneyForConnection(connection, true, this.skipOutcomeStep).pipe(
            finalize(() => { // using 'tap' is not enough - it still flagged the workflow as running after you cancelled
                this.workflowRunning = false;
                this.routeService.updateSearchParameterValue(RunWorkflowSearchParam, undefined, true);
            }),
        );
    }

    private updateCanContinueWorkflow() {
        return this.workflowService.canContinueWorkflow(this.workflow!).pipe(
            tap((canContinueWorkflow) => this.canContinueWorkflow = canContinueWorkflow),
        );
    }
}

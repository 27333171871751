<ng-container *ngIf="eventTypePreset">
    <p>Please select from the dropdown boxes below when you and your team would like to conduct your {{eventName}}.</p>

    <div class="d-flex justify-content-center">
        <adapt-schedule-recurrence *ngIf="currentRecurrence as recurrence"
                                   [recurrence]="recurrence"
                                   [nextTimes]="nextTimes"
                                   [location]="lastLocation"
                                   [potentialLocations]="locations"
                                   [disabled]="configDisabled"
                                   (scheduledRecurrence)="onRecurrenceChange($event)"></adapt-schedule-recurrence>
    </div>

    <div *ngIf="warning"
         class="alert alert-warning d-flex"
         role="alert">
        {{warning}}
        <div *ngIf="allowUndo"
             class="ms-auto">
            <button type="button"
                    adaptButtonIcon="refresh"
                    class="btn btn-link"
                    (click)="undoChanges()">Undo changes</button>
        </div>
    </div>

    <adapt-time-scheduler-wrapper [items]="items"
                                  [sections]="sections"
                                  [start]="schedulerStartTime"
                                  [currentPeriod]="currentPeriod"
                                  [cycle]="cycle"></adapt-time-scheduler-wrapper>
</ng-container>

import { BaseEntity } from "@common/ADAPT.Common.Model/base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Meeting } from "./meeting";

export class MeetingSupplementaryData extends BaseEntity<MeetingSupplementaryData> {
    public meetingId!: number;
    public purpose!: string;

    public meeting!: Meeting;
}

export const MeetingSupplementaryDataBreezeModel = new BreezeModelBuilder("MeetingSupplementaryData", MeetingSupplementaryData)
    .hasSource()
    .withPluralName("MeetingSupplementaryData")
    .isOrganisationEntity()
    .build();

<ng-container *adaptLoadingUntilFirstEmit="let team of team$">
    <div class="dashboard px-0 px-md-3">
        <adapt-dashboard-element-hq name="{{team.name}} team members"
                                    linkText="Add another person to the team"
                                    [url]="configPeopleUrl"
                                    [contentCard]="true">
            <adapt-team-membership [team]="team"></adapt-team-membership>
        </adapt-dashboard-element-hq>

        <div class="split">
            <adapt-team-guidance-dashboard-element *adaptIfAuthorised="PurposeVisionRead;Entity:team"
                                                   [team]="team"
                                                   dashboardTitle="Team guidance"
                                                   data-test="purpose-dashboard-element"></adapt-team-guidance-dashboard-element>
            <adapt-team-meeting-guidance-dashboard-element *adaptIfAuthorised="viewTeamMeetings;Entity:team"
                                                           [team]="team"></adapt-team-meeting-guidance-dashboard-element>
        </div>

        <adapt-workflows-dashboard-element [team]="team"></adapt-workflows-dashboard-element>
    </div>
</ng-container>

import { DateFormats } from "@common/ux/date-formats";
import moment from "moment";
import { Meeting, MeetingStatus } from "./meeting";
import { MeetingAgendaItem, MeetingAgendaItemStatus } from "./meeting-agenda-item";

export interface IMeetingDuration {
    text: string;
    minuteOffset: number;
}

const DURATION_INTERVAL = 15;
const DURATION_MAX_MINUTES = 60 * 8;

export class MeetingExtensions {
    public readonly dateFormat = DateFormats.globalize.full;

    public durationOptions: IMeetingDuration[] = this.getMeetingDurationOptions();
    public selectedDuration = this.durationOptions[3];

    public constructor(private meeting: Meeting) {
        if (this.meeting.status !== MeetingStatus.Ended && this.meeting.entityAspect?.entityState.isAdded()) {
            this.updateSelectedDuration();
        }
    }

    public get isNotStarted() {
        return this.meeting.status === MeetingStatus.NotStarted;
    }

    public get isInProgress() {
        return this.meeting.status === MeetingStatus.InProgress;
    }

    public get isEnded() {
        return this.meeting.status === MeetingStatus.Ended;
    }

    public get allAgendaItemsEnded() {
        return this.meeting.meetingAgendaItems.every((item) => item.extensions.isEnded);
    }

    public get shouldBeEnded() {
        return !this.isEnded && this.allAgendaItemsEnded;
    }

    public get startTime() {
        return this.meeting.meetingDateTime;
    }

    public get firstInProgressItem() {
        return this.meeting.meetingAgendaItems.find((i) => i.extensions.isInProgress);
    }

    public get hasPreWork() {
        return this.meeting.meetingAgendaItems.find((i) => i.extensions.isPreWork);
    }

    public getCustomData<T>() {
        if (!this.meeting.customData) {
            return {} as T;
        }

        return JSON.parse(this.meeting.customData) as T;
    }

    public updateCustomData<T>(customData: T) {
        this.meeting.customData = JSON.stringify(customData);
    }

    public getMeetingDurationOptions() {
        const durations: IMeetingDuration[] = [];

        let minutes = 0;
        while (minutes < DURATION_MAX_MINUTES) {
            minutes += DURATION_INTERVAL;

            const duration = moment.duration({ minutes });

            const hourText = duration.hours() > 1 ? " hours" : " hour";
            const minuteText = duration.minutes() ? `${duration.minutes()} minutes` : "";

            durations.push({
                text: duration.hours() > 0
                    ? `${duration.hours()}${hourText} ${minuteText}`
                    : minuteText,
                minuteOffset: minutes,
            });
        }

        return durations;
    }

    public getClosestDurationForEndTime() {
        if (!this.meeting
            || !this.meeting.endTime
            // below can occur when breeze creates the meeting entity
            || this.meeting.endTime.getTime() === new Date(0, 0).getTime()) {
            // default to 1 hr
            return this.durationOptions[3];
        }

        // find the duration closest to the end time
        return this.durationOptions.reduce((currentHighest, duration) => {
            const startDiff = moment(this.meeting!.meetingDateTime)
                .add({ minutes: duration.minuteOffset })
                .diff(this.meeting!.endTime, "minutes");
            return startDiff < DURATION_INTERVAL
                ? duration
                : currentHighest;
        });
    }

    public updateSelectedDuration() {
        this.selectedDuration = this.getClosestDurationForEndTime();
        this.setMeetingEndDuration(this.selectedDuration);
    }

    public setMeetingDate(date: Date) {
        if (date !== null) {
            this.meeting.meetingDateTime = date;
            if (this.meeting.status !== MeetingStatus.Ended) {
                this.setMeetingEndDuration(this.selectedDuration);
            }
        }
    }

    public setMeetingEndDuration(endDuration: IMeetingDuration) {
        this.selectedDuration = endDuration;
        this.meeting.endTime = moment(this.meeting.meetingDateTime)
            .add({ minutes: endDuration.minuteOffset })
            .toDate();
    }

    public startMeeting() {
        const meetingDuration = moment.duration(moment(this.meeting.endTime).diff(this.meeting.meetingDateTime));
        this.meeting.meetingDateTime = new Date();
        this.meeting.endTime = moment(this.meeting.meetingDateTime).add(meetingDuration).toDate();
        this.meeting.status = MeetingStatus.InProgress;

        let changedEntities: (Meeting | MeetingAgendaItem)[] = [this.meeting];
        const firstItem = this.firstUnfinishedAgendaItem;
        if (firstItem) {
            const changedItems = firstItem.extensions.startItem();
            changedEntities = changedEntities.concat(changedItems);
        }

        const unsavedAgendaItems = this.meeting.meetingAgendaItems
            .filter((i) => i.entityAspect.entityState.isAdded() && !i.entityAspect.hasValidationErrors);

        changedEntities = changedEntities.concat(unsavedAgendaItems);

        return changedEntities;
    }

    public endMeeting() {
        this.meeting.endTime = new Date();
        this.meeting.status = MeetingStatus.Ended;

        // ending the meeting will also reset the in-progress agenda item
        const changedAgendaItems = this.meeting.meetingAgendaItems.filter((i) => i.extensions.isInProgress);
        changedAgendaItems.forEach((i) => i.status = MeetingAgendaItemStatus.NotStarted);

        return [this.meeting, ...changedAgendaItems];
    }

    private get firstUnfinishedAgendaItem() {
        if (this.meeting.meetingAgendaItems.length > 0) {
            return this.meeting.meetingAgendaItems
                .sort((a, b) => a.ordinal - b.ordinal)
                .find((i) => !i.extensions.isEnded);
        }

        return undefined;
    }
}

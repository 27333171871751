<adapt-toolbar-title>
    <div class="d-flex align-items-center"
         data-tour="active-meeting-title">
        <h2>{{meeting?.name}}</h2>
    </div>
</adapt-toolbar-title>

<div adaptToolbarContent
     class="d-flex"
     alignment="left">
    <button *ngIf="meeting && meeting.supplementaryData && meeting.supplementaryData.purpose"
            type="button"
            [class.pulsing-element]="showDescriptionOnLoad"
            adaptButtonIcon="align-self-center fal fa-fw {{descriptionExpanded ? 'fa-chevron-up': 'fa-chevron-down'}}"
            [adaptTooltip]="descriptionExpanded ? 'Hide Description': 'Show Description'"
            class="ms-2 btn btn-blank"
            (click)="descriptionExpanded = !descriptionExpanded"></button>
    <div class="ms-3 me-3 align-self-center"
         data-tour="active-meeting-time-elapsed">
        <i class="fal fa-clock me-1"></i>
        <span>{{meeting?.meetingDateTime | timeFromTimer$ | async}}</span>
    </div>
</div>

<div adaptToolbarContent
     *ngIf="meeting"
     data-tour="active-meeting-toolbar-actions">
    <button *ngIf="canEditTeamMeeting && isLgBreakpoint"
            class="align-self-center btn btn-danger d-flex"
            [ngClass]="{'end-meeting-pulse': meeting.extensions.shouldBeEnded}"
            adaptButtonIcon="fal fa-fw fa-stop-circle"
            [adaptBlockingClick]="endMeeting"
            [adaptBlockingClickParam]="meeting"
            [unsubscribeOnDestroy]="false"
            data-tour="stop-meeting-button"
            data-test="stop-meeting-button">End meeting</button>
    <adapt-meeting-details-menu [meeting]="meeting"
                                [showTour]="true"
                                data-test="active-meeting-actions-menu"
                                [extraMenuOptions]="isLgBreakpoint ? undefined : activeMeetingOptions"
                                menuSize="sm"></adapt-meeting-details-menu>
</div>

<div class="px-1"
     data-test="active-meeting-page"
     data-tour="active-meeting-page">
    <div [class.pulsing-element]="showDescriptionOnLoad"
         *ngIf="meeting && meeting.supplementaryData && meeting.supplementaryData.purpose && descriptionExpanded">
        <div class="mb-4 p-3 meeting-description"
             [froalaView]="meeting.supplementaryData.purpose"></div>
    </div>

    <adapt-meeting-sidebar-agenda *ngIf="meeting"
                                  [meeting]="meeting"
                                  (endMeetingCalled)="endMeeting(meeting).subscribe()"
                                  data-tour="meeting-agenda"></adapt-meeting-sidebar-agenda>
</div>

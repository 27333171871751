<ng-container *ngIf="remainingMatches.length === 0">
    <!-- mimic the dx accordion markup so that results without the caret line up correctly -->
    <div class="dx-accordion d-flex flex-grow-1">
        <div class="dx-accordion-item-title dx-accordion-item-title-no-caret mb-0">
            <ng-container *ngTemplateOutlet="itemRow"></ng-container>
        </div>
    </div>
</ng-container>

<dx-accordion *ngIf="remainingMatches.length > 0"
              [dataSource]="[result]"
              itemTemplate="detail"
              [collapsible]="true"
              [multiple]="true"
              [selectedItems]="[]"
              keyExpr="ItemId"
              (onInitialized)="accordionInitialised($event)">
    <div *dxTemplate="let result of 'title'"
         class="d-flex">
        <ng-container *ngTemplateOutlet="itemRow"></ng-container>
    </div>
    <div *dxTemplate="let result of 'detail'"
         class="py-1 px-0">
        <div *ngFor="let match of remainingMatches"
             class="row d-flex pt-1 align-items-center">
            <div class="col">
                <ng-container *ngTemplateOutlet="crumbTemplate || crumbs; context: {match: match}"></ng-container>
            </div>
            <div class="col-auto snippet text-muted text-end">
                <ng-container *ngTemplateOutlet="snippetTemplate || defaultSnippetTemplate; context: {match: match}"></ng-container>
            </div>
        </div>
    </div>
</dx-accordion>

<ng-template #itemRow>
    <ng-container *ngIf="getRouterLink(href$ | async) as link; else newTabLink">
        <a class="d-flex flex-grow-1 flex-wrap align-content-center search-result-row"
           *ngIf="!openInNewTab; else newTabLink"
           [ngClass]="{'no-matches': remainingMatches.length === 0}"
           [routerLink]="link.path"
           [queryParams]="link.queryParams">
            <ng-container *ngTemplateOutlet="itemRowContent"></ng-container>
        </a>
    </ng-container>

    <ng-template #newTabLink>
        <a class="d-flex flex-grow-1 flex-wrap align-content-center search-result-row"
           target="_blank"
           [ngClass]="{'no-matches': remainingMatches.length === 0}"
           [href]="href$ | async">
            <ng-container *ngTemplateOutlet="itemRowContent"></ng-container>
        </a>
    </ng-template>

    <ng-template #itemRowContent>
        <ng-content></ng-content>

        <div class="d-flex flex-column flex-grow-1 align-items-end justify-content-center flex-wrap snippet text-muted"
             *ngIf="firstMatch as match">
            <div *ngIf="showFirstCrumb">
                <ng-container *ngTemplateOutlet="crumbTemplate || crumbs; context: {match: match}"></ng-container>
            </div>
            <div>
                <ng-container *ngTemplateOutlet="snippetTemplate || defaultSnippetTemplate; context: {match: match}"></ng-container>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #defaultSnippetTemplate
             let-match="match">
    <em *ngIf="match.Snippet"
        class="flex-basis-100 ms-1">"
        <adapt-highlighted-text [needle]="keyword"
                                [haystack]="match.Snippet"></adapt-highlighted-text>
        "</em>
</ng-template>

<ng-template #crumbs
             let-match="match">
    <ng-container *ngFor="let crumb of match.Field; let last = last">
        <span>{{crumb}}</span>
        <span *ngIf="!last"
              class="px-1">&gt;</span>
    </ng-container>
</ng-template>

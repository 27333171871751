import { Component, Inject } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { EMPTY, lastValueFrom } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { KanbanService } from "../../kanban.service";
import { KanbanNavigationService } from "../../kanban-navigation.service";

@Component({
    selector: "adapt-move-item-dialog",
    templateUrl: "./move-item-dialog.component.html",
})
export class MoveItemDialogComponent extends BaseDialogComponent<Item> {
    public readonly dialogName = "MoveItemDialog";

    public constructor(
        private kanbanService: KanbanService,
        private kanbanNavService: KanbanNavigationService,
        @Inject(ADAPT_DIALOG_DATA) public item: Item,
    ) {
        super();
    }

    @Autobind
    public async save() {
        const saveEntities: IBreezeEntity[] = [this.item];

        return lastValueFrom(this.kanbanService.saveEntities(saveEntities).pipe(
            tap(() => this.resolve(this.item)),
            switchMap(() => this.kanbanNavService.gotoItemPage(this.item, false)),
            catchError((e) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
        )); // return a promise do to
    }

    public cancel() {
        this.kanbanService.rejectChanges(this.item).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => super.cancel());
    }
}

<adapt-edit-strategic-goal-base [goal]="goal"
                                [guidanceArticleId]="GuidanceArticleId">
    <div class="d-flex mb-3 align-items-stretch">
        <div class="d-flex flex-column">
            <label>Zone</label>
            <adapt-select-zone [(zone)]="goal!.zone"
                               [required]="true"
                               [filter]="filterZone"></adapt-select-zone>
        </div>
        <div class="d-flex flex-column flex-grow-1 ms-2"
             [ngClass]="{'me-2': !isNew}">
            <label>Theme (optional)</label>
            <adapt-select-theme [zone]="goal!.zone"
                                [(theme)]="goal!.theme"
                                [allowCreation]="false"
                                placeholderText="Select an optional theme for the goal..."></adapt-select-theme>
        </div>
        <div class="ms-auto d-flex flex-column"
             *ngIf="!isNew">
            <label>Status</label>
            <adapt-select-goal-status [(status)]="goal!.status"
                                      class="flex-grow-1"></adapt-select-goal-status>
        </div>
    </div>
    <label class="mt-3">Goal name</label>
    <dx-text-box [(value)]="goal!.name"
                 (valueChange)="updateCompletion()"
                 [maxLength]="60"
                 [adaptValidateEntity]="goal"
                 adaptValidateEntityProperty="name"></dx-text-box>
</adapt-edit-strategic-goal-base>

<adapt-feature-guidance [configItem]="configItem">
    <p>
        Teams can use actions to visualise their work and limit work in progress to enable more effective teamwork.
    </p>
    <p>
        A meeting framework is provided for teams that would benefit from tracking meeting attendance and minutes.
    </p>
</adapt-feature-guidance>

<adapt-feature-configuration [team]="team"
                             [configItem]="configItem"
                             (featureChange)="initialiseData()">
    <!-- TODO CM-3810 we shouldn't need this extra permission check. Org.Team.Configure should be enough -->
    <h3>Action boards</h3>
    <ng-container *adaptIfAuthorised="configureTeamKanban; entity: team; else noPermissionTemplate">
        <adapt-configure-kanban-boards [team]="team"></adapt-configure-kanban-boards>
    </ng-container>
    <ng-template #noPermissionTemplate>
        <div class="alert alert-warning">
            You do not have permission to edit boards for this team
        </div>
    </ng-template>

    <hr />
    <dx-check-box text="Enable Meeting Framework"
                  [disabled]="!isOrganisationMeetingFeatureEnabled"
                  [value]="isMeetingsFeatureEnabled"
                  (valueChange)="onMeetingsFeatureStatusValueChanged($any($event))"></dx-check-box>
    <adapt-icon *ngIf="!isOrganisationMeetingFeatureEnabled"
                class="ms-1 align-middle"
                icon="fal fa-info-circle"
                adaptTooltip="Meetings are disabled for the whole organisation"></adapt-icon>

    <adapt-configure-meeting-agenda-templates *ngIf="isMeetingsFeatureEnabled"
                                              [team]="team"></adapt-configure-meeting-agenda-templates>
</adapt-feature-configuration>

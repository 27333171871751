import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";

@Component({
    selector: "adapt-select-item-status",
    templateUrl: "./select-item-status.component.html",
})
export class SelectItemStatusComponent {
    @Input() public set status(value: ItemStatus) {
        this.itemStatusSelection = value;
    }

    @Output() public statusChange = new EventEmitter<ItemStatus>();
    @Input() public disabled?: boolean;

    public itemStatusSelection?: ItemStatus;

    public itemStatuses = ItemStatusMetadata.All.map((metadata) => metadata.status);

    public itemChange(selection?: ItemStatus) {
        this.statusChange.emit(selection ?? undefined);
    }
}

import { AfterContentInit, AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemLink } from "@common/ADAPT.Common.Model/organisation/item-link";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { ItemUtilities } from "../items/item-utilities";
import { KanbanAuthService } from "../kanban-auth.service";

@Component({
    selector: "adapt-kanban-card",
    templateUrl: "./kanban-card.component.html",
    styleUrls: ["./kanban-card.component.scss"],
})
export class KanbanCardComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentInit {
    @Input() public item!: Item;
    @Input() public showAssignee = true;
    @Input() public draggable = true;
    @Input() public highlight = false;

    // cannot use click and it clashes with ng (click)
    @Output() public itemSelected = new EventEmitter<Item | undefined>();
    @Output() public dialogOpened = new EventEmitter<Item>();
    @Output() public dialogClosed = new EventEmitter<Item>();
    @Output() public contentRendered = new EventEmitter<void>();

    public get editable() {
        return this.item?.extensions.currentPersonCanEdit && this.draggable;
    }

    public denyDrag = false;
    private nonEditableDragStart = false;

    public sortedLinks?: ItemLink[];

    public constructor(
        elementRef: ElementRef,
        private kanbanAuthService: KanbanAuthService,
        private orgService: OrganisationService,
    ) {
        super(elementRef);
    }

    public ngOnInit() {
        this.sortedLinks = this.item.links?.sort(ItemUtilities.getItemLinkStatusAndItemCodeSortComparator(this.item));
        this.kanbanAuthService.hasEditAccessToItem(this.item)
            .subscribe((canEdit) => this.item.extensions.currentPersonCanEdit = canEdit);
    }

    public ngAfterViewChecked() {
        // This check for org id here is to prevent the initialization after logging out (i.e. application bar and toolbar disappear, causing
        // more items to get into the view), which will cause error toasters
        if (this.orgService.getOrganisationId() > 0) {
            this.setInitialiseWhenElementComesIntoView(() => this.contentRendered.emit());
        }
    }

    public ngAfterContentInit() {
        this.ngAfterViewChecked();
    }

    @HostListener("click")
    public onClick() {
        this.itemSelected.emit(this.item);
    }

    @HostListener("pointerdown")
    public pointerDown() {
        if (!this.editable) {
            this.nonEditableDragStart = true;
        }
    }

    @HostListener("pointermove")
    public pointerMove() {
        if (this.nonEditableDragStart) {
            this.denyDrag = true;
        }
    }

    @HostListener("pointerleave")
    @HostListener("pointerup")
    public pointerUp() {
        this.nonEditableDragStart = false;
        this.denyDrag = false;
    }
}

<adapt-dialog [maxWidth]="600"
              [minHeight]="200">
    <h3 adapt-dialog-title>{{title}}</h3>
    <div adapt-dialog-content
         style="min-height: 80px;">
        <label for="name">Profile Category Name</label>
        <dx-text-box #focus
                     id="name"
                     placeholder="Enter profile category name"
                     [(value)]="profileCategory!.label"
                     data-test="profile-category-name-input">
            <dx-validator>
                <dxi-validation-rule type="custom"
                                     [validationCallback]="validateDuplicates"
                                     message="Category name already in use"></dxi-validation-rule>
                <dxi-validation-rule [adaptEntity]="profileCategory"
                                     adaptEntityProperty="label"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!isValid"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

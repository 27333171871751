import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { ContactType } from "@common/ADAPT.Common.Model/embed/contact-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { OutstandingSurveyResponse, OutstandingSurveyResponseStatus } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { Survey } from "@common/ADAPT.Common.Model/organisation/survey";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { DateFormats } from "@common/ux/date-formats";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import moment from "moment";
import { Observable } from "rxjs";
import { catchError, finalize, switchMap, take, tap } from "rxjs/operators";
import { SurveyService } from "../survey.service";
import { surveyPageRoute } from "../survey-page/survey-page.route";

// This is only used in this component - if going to be used in other places, move it to outstanding-survey-response.ts next to the enum
const OutstandingResponseStatusDisplay: { [status in OutstandingSurveyResponseStatus]: string } = {
    New: "Not Sent",
    InvitationProcessed: "Invitation Sent",
    ReminderProcessed: "Reminder Sent",
    InvitationFailed: "Invitation Failed",
    ReminderFailed: "Reminder Failed",
};

export interface IViewSurveyOutstandingResponsesData {
    survey: Survey;
    showEndTime?: boolean;
}

@Component({
    selector: "adapt-view-survey-outstanding-responses",
    templateUrl: "./view-survey-outstanding-responses-dialog.component.html",
})
export class ViewSurveyOutstandingResponsesDialogComponent extends BaseDialogComponent<Survey> {
    public readonly dialogName = "ViewSurveyOutstandingResponses";
    public readonly dateTimeDisplayFormat = DateFormats.globalize.shortdatetime;
    public readonly adaptProjectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    public outstandingSurveyResponses$: Observable<OutstandingSurveyResponse[]>;
    public reloading: { [connectionId: number]: boolean } = {};
    public isStakeholderManager = false;
    public survey: Survey;
    public showEndTime = false;
    public minEndTime: Date;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) data: IViewSurveyOutstandingResponsesData,
        directorySharedService: DirectorySharedService,
        authService: AuthorisationService,
        private surveyService: SurveyService,
    ) {
        super();

        this.survey = data.survey;
        this.showEndTime = !!data.showEndTime;
        const dayAfterStart = moment(this.survey.startTime).add(1, "d").startOf("day");
        const today = moment().startOf("day");
        this.minEndTime = dayAfterStart.isAfter(today) ? dayAfterStart.toDate() : today.toDate();

        authService.promiseToCheckIsStakeholderManager().then((isStakeholderManager) => this.isStakeholderManager = isStakeholderManager);
        this.outstandingSurveyResponses$ = this.surveyService.getOutstandingSurveyResponsesForSurvey(this.survey.surveyId, this.survey.surveyId > 0)
            .pipe(
                switchMap(async (outstandingSurveyResponses) => {
                    await directorySharedService.promiseToGetAllConnections();
                    return outstandingSurveyResponses;
                }), // prime connection
                tap((outstandingSurveyResponses) =>  // prime person contacts; otherwise no email
                    outstandingSurveyResponses.forEach((o) => directorySharedService.promiseToGetContactDetailsByPersonId(o.connection!.personId))),
            );
    }

    public getEmail(rowData: OutstandingSurveyResponse) {
        const contacts = rowData.connection?.person.getContactsOfType(ContactType.Email);
        return (contacts && contacts?.length > 0) ? contacts[0].value : undefined;
    }

    public getStatus(rowData: OutstandingSurveyResponse) {
        return OutstandingResponseStatusDisplay[rowData.status];
    }

    public resendEmail(token: string, connection: Connection) {
        this.reloading[connection.connectionId] = true;
        this.setErrorMessage();
        this.surveyService.resendSurveyEmail(token)
            .pipe(
                // update outstanding entity -> status may be updated from sending email
                switchMap(() => this.surveyService.getOutstandingSurveyResponsesForPerson(true, connection.personId)),
                finalize(() => this.reloading[connection.connectionId] = false),
                this.takeUntilDestroyed(),
            )
            .subscribe({
                next: () => this.setErrorMessage(),
                error: (err: HttpErrorResponse) => this.setErrorMessage(err.error.Message),
            });
    }

    public copySurveyLink(token: string) {
        surveyPageRoute.getRoute({}, {
            token,
            name: this.survey.name,
        })
            .pipe(take(1))
            .subscribe((route) => window.navigator.clipboard.writeText(`${window.location.origin}${route}`));
    }

    public onEndTimeChanged(endTime: Date) {
        this.survey.endTime = endTime;
        this.surveyService.saveEntities(this.survey).pipe(
            catchError((e) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
                this.setErrorMessage(errorMessage);
                throw e;
            }),
        ).subscribe();
    }
}

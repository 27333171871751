import { Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { TooltipHelper, TooltipPlacement } from "./tooltip-helper";

@Directive({
    selector: "[adaptTooltip]",
})
export class TooltipDirective implements OnChanges, OnDestroy {
    @Input("adaptTooltip") public tooltipContent = "";
    @Input("adaptTooltipIsHtml") public tooltipIsHtml = false;
    @Input() public tooltipPlacement?: TooltipPlacement;
    @Input() public tooltipEnabled = true;

    private tooltipHelper?: TooltipHelper;

    constructor(
        private el: ElementRef<HTMLElement>,
    ) { }

    public ngOnChanges(changes: SimpleChanges) {
        if (!this.tooltipHelper) {
            const element = jQuery(this.el.nativeElement);
            this.tooltipHelper = new TooltipHelper(element, setTimeout, this.tooltipPlacement);
        }

        if (changes.tooltipContent || changes.tooltipIsHtml) {
            this.tooltipHelper.setTooltipContent(this.tooltipContent, this.tooltipIsHtml);
        }
        if (changes.tooltipEnabled) {
            this.tooltipHelper.setTooltipIsEnabled(this.tooltipEnabled);
        }
    }

    public ngOnDestroy() {
        if (this.tooltipHelper) {
            this.tooltipHelper.destroyTooltip();
        }
    }

    @HostListener("pointerleave")
    public hideIfNotVisible() {
        // Handle the case where the tooltip is on an element which is only shown on
        // hover and clicking it opens a dialog. When you click the element to open the
        // dialog, the element gets hidden but the dxpointerleave event doesn't get
        // triggered, which leaves the tooltip behind.
        // This heuristic for visibility borrowed from https://stackoverflow.com/a/21696585
        if (this.el.nativeElement.offsetParent === null) {
            if (this.tooltipHelper) {
                this.tooltipHelper.hideTooltip();
            }
        }
    }

    @HostListener("focusout")
    public hideOnBlurred() {
        // this will hide the tooltip on focusout,
        // e.g. tooltip for button, click on button and dialog appear with tooltip on top of dialog
        if (this.tooltipHelper) {
            this.tooltipHelper.hideTooltip();
        }
    }
}

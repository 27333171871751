import { Component, Inject, OnInit } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseConfirmationDialogComponent } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/base-confirmation-dialog.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { DateFormats } from "@common/ux/date-formats";
import moment from "moment";
import { DirectorySharedService } from "../../directory-shared/directory-shared.service";

interface IEndConnectionDialogData extends IConfirmationDialogData {
    connection: Connection;
}

@Component({
    selector: "adapt-end-connection-dialog",
    templateUrl: "./end-connection-dialog.component.html",
})
export class EndConnectionDialogComponent extends BaseConfirmationDialogComponent implements OnInit {
    public readonly dialogName = "EndConnectionDialog";

    public displayFormat = DateFormats.globalize.short;
    public platformName = AdaptClientConfiguration.AdaptProjectLabel;

    public connection: Connection;
    public endDate: Date;
    public maxEndDate: Date;

    public canEdit?: boolean;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IEndConnectionDialogData,
        private directorySharedService: DirectorySharedService,
    ) {
        super(dialogData);

        this.connection = dialogData.connection;
        this.endDate = moment().toDate();
        this.maxEndDate = this.endDate;

        this.dialogData.title = `Deactivate ${this.connection.person.fullName}'s account`;
        dialogData.confirmButtonText = "Deactivate account";
    }

    public async ngOnInit() {
        const hasPermission = await this.directorySharedService.promiseToVerifyAccessToManagePositionAndRoles(this.connection.person);
        this.canEdit = hasPermission && this.connection.isActive();
    }

    public async onClosed(accept: boolean) {
        if (accept) {
            await this.directorySharedService.promiseToEndConnection(this.connection, this.endDate);
        }
        this.onDialogClosed(accept);
    }
}

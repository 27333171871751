import { DefaultBackTourButton, DefaultCancelTourButton, DefaultFinishButtons, DefaultNextButtons, GuidedTour, nextStep } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export const AttachInputsTour: GuidedTour = [{
    title: "Introduction",
    text: `<p>This tour will walk you through the process of attaching strategic inputs to the strategy board.</p>
        <p>If you already know how to do it, you can cancel this tour.</p>`,
    buttons: [DefaultCancelTourButton, {
        classes: "btn btn-primary",
        text: "Proceed",
        action: nextStep,
    }],
}, {
    title: "Attaching inputs to a zone",
    text: `<p>To attach inputs to a zone, use the corresponding action item in the menu.</p>
        <p><b>Click on it now</b> to reveal the associated options.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="zone-action-menu-EconomicEngine"]`,
    attachTo: {
        on: "left",
    },
    advanceOnAttachedElementEvent: "click",
    modalOverlayOpeningPadding: 3,
}, {
    title: "Attach inputs dialog",
    text: `<p>This is the menu item which will display the action dialog to attach SWT inputs to the zone.</p>
        <p>This is not clickable during the tour as it would disrupt the flow. You can try interacting with it later after completing the tour.</p>
        <p><b>Click 'Next' to continue</b>.</p>`,
    waitForAndAttachToElementSelector: ".dx-menu-item",
    elementSelectorTextContent: "Attach SWT Inputs",
    modalOverlayOpeningPadding: 3,
    attachTo: {
        on: "left",
    },
    canClickTarget: false, // have to prevent attached element from being clickable or the menu item will disappear and the guided will lose the attach handle
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Attaching competitor analysis inputs",
    text: `<p>This is the menu item which will display the action dialog to attach Competitor Analysis inputs to the zone.</p>
    <p><b>Click 'Next' to continue</b>.</p>`,
    waitForAndAttachToElementSelector: ".dx-menu-item",
    elementSelectorTextContent: "Attach Competitor Analysis Inputs",
    modalOverlayOpeningPadding: 3,
    attachTo: {
        on: "left",
    },
    canClickTarget: false, // have to prevent attached element from being clickable or the menu item will disappear and the guided will lose the attach handle
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
}, {
    title: "Attaching inputs to a theme",
    text: `<p>Besides attaching inputs to a zone, you can also attach inputs to a strategic theme.</p>
        <p>This can be done through a similar action from the menu here.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="theme-action-menu"]`,
    attachTo: {
        on: "bottom",
    },
    modalOverlayOpeningPadding: 3,
    canClickTarget: false,
    buttons: [DefaultBackTourButton, DefaultNextButtons[0]],
    showOn: () => {
        // this step will only be shown if Organisation Diagnostic node is not there
        const themeMenu = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="theme-action-menu"]`);
        return GuidedTourUtils.isElementVisible(themeMenu);
    },
}, {
    title: "End of attach inputs tour",
    text: `<p>This concludes the tour to attach inputs to your strategy board.</p>
        <p>Now, proceed to attach inputs to your preferred zones or themes.</p>`,
    buttons: [DefaultBackTourButton, DefaultFinishButtons[0]],
}];

import { Component, Input, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { InitializedEvent } from "devextreme/ui/accordion";
import { Observable, Subscription } from "rxjs";
import { SearchService } from "../search.service";
import { SearchRowBaseComponent } from "../search-row-base.component";

@Component({
    selector: "adapt-collapsible-search-row",
    templateUrl: "./collapsible-search-row.component.html",
    styleUrls: ["./collapsible-search-row.component.scss"],
})
export class CollapsibleSearchRowComponent extends SearchRowBaseComponent<any> implements OnDestroy {
    @Input() public href$?: Observable<IAdaptLinkObject | string>;
    @Input() public crumbTemplate?: TemplateRef<any>;
    @Input() public snippetTemplate?: TemplateRef<any>;

    @Input() public openInNewTab = false;
    @Input() public showFirstCrumb = true;

    @ViewChild("defaultSnippetTemplate") public defaultSnippetTemplate?: TemplateRef<any>;

    private sidebarSubscription?: Subscription;

    public constructor(
        protected searchService: SearchService,
        private shellUiService: ShellUiService,
        private routeService: RouteService,
    ) {
        super(searchService);
    }

    public getRouterLink(href: IAdaptLinkObject | string | null) {
        return this.routeService.parseRouterObject(href || undefined)!;
    }

    public accordionInitialised(event: InitializedEvent) {
        // update dimensions of accordion when sidebar changes state
        // weird wrapping occurs otherwise as accordions have fixed height
        this.sidebarSubscription = this.shellUiService.sidebarState$.subscribe(() => event.component?.updateDimensions());
    }

    public ngOnDestroy() {
        if (this.sidebarSubscription) {
            this.sidebarSubscription.unsubscribe();
        }
    }
}

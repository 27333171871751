<adapt-feature-guidance [configItem]="configItem">
    <p>Set up items that can be used to enhance personal profiles with data relevant to the people in your organisation.</p>
    <p>For example, you might like to store things like personal values, DiSC profiles, qualifications or emergency contacts for each person
        in your organisation.</p>
    <p><b>Note that you will only be able to see these categories in the profiles of active employees. These will not be visible to
            coaches, external stakeholders or people who have left the organisation.</b>
    </p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <div *ngIf="categories"
         class="row">
        <!-- categories -->
        <div class="col-12 col-md-6 col-lg-5">
            <h3>Profile Categories</h3>
            <dx-list #categoryList
                     [items]="categories"
                     selectionMode="single"
                     [focusStateEnabled]="false"
                     [activeStateEnabled]="false"
                     noDataText="No profile categories defined."
                     (onSelectionChanged)="onCategorySelectionChanged($event)"
                     (onItemReordered)="updateCategoryOrdinals($event)">
                <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>
                <div *dxTemplate="let category of 'item'"
                     class="category-item"
                     data-test="category-item">
                    <span>{{category.label}}</span>
                    <button adaptButtonIcon="edit"
                            class="btn btn-link"
                            [adaptBlockingClick]="editCategory"
                            [adaptBlockingClickParam]="category"
                            data-test="edit-category-button"></button>
                    <button adaptButtonIcon="delete"
                            class="btn btn-link"
                            [adaptBlockingClick]="deleteCategory"
                            [adaptBlockingClickParam]="category"
                            data-test="delete-category-button"></button>
                </div>
            </dx-list>
            <button adaptButton="add"
                    [adaptBlockingClick]="addCategory"> Profile Category</button>
        </div>

        <!-- category items -->
        <div class="col-12 col-md-6 col-lg-7"
             *ngIf="selectedCategory">
            <h3>{{selectedCategory.label}} Profile Category</h3>
            <dx-list [items]="categoryItems"
                     [focusStateEnabled]="false"
                     [activeStateEnabled]="false"
                     noDataText="No items for this category defined."
                     selectionMode="single"
                     (onItemReordered)="updateItemOrdinals($event)">
                <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>
                <div *dxTemplate="let item of 'item'"
                     class="item-item"
                     data-test="item-item">

                    <div class="details">
                        <div>{{item.label}}
                            <span *ngIf="item.isPrivate"
                                  class="fal fa-lock"
                                  adaptTooltip="This information is only visible to this person and those with permission to view private profile data"></span>
                        </div>
                        <small>{{getItemTypeLabel(item.itemType)}}</small>
                    </div>

                    <button adaptButtonIcon="edit"
                            class="btn btn-link"
                            [adaptBlockingClick]="editCategoryItem"
                            [adaptBlockingClickParam]="item"
                            data-test="edit-item-button"></button>
                    <button adaptButtonIcon="delete"
                            class="btn btn-link"
                            [adaptBlockingClick]="deleteCategoryItem"
                            [adaptBlockingClickParam]="item"
                            [unsubscribeOnDestroy]="false"
                            data-test="delete-item-button"></button>
                </div>
            </dx-list>
            <button adaptButton="add"
                    [adaptBlockingClick]="addCategoryItem"> Category Item</button>
        </div>
    </div>
</adapt-feature-configuration>

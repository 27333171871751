<ng-container *ngIf="meetingItem?.item">
    <div class="my-1 d-flex flex-row align-items-start note-action-container action-container">
        <i class="me-2"
           [ngClass]="MeetingItemIconClass"
           adaptTooltip="Action"></i>
        <adapt-item-link [item]="meetingItem.item"
                         [showSummary]="true"></adapt-item-link>
        <div *ngIf="showStatus"
             [ngClass]="ItemStatusMetadata.ByStatus[meetingItem.item.status].iconClass"
             class="ms-auto badge">
            {{ItemStatusMetadata.ByStatus[meetingItem.item.status].name}}
        </div>
    </div>
</ng-container>

import { Account, OrganisationType, PaymentMethod, SubscriptionStatus } from "./account";

export class AccountExtensions {
    public constructor(private account: Account) { }

    public get isActive() {
        return this.account.status === SubscriptionStatus.Active;
    }

    public get isTrial() {
        return this.account.status === SubscriptionStatus.Trial;
    }

    public get isInactive() {
        return this.account.status === SubscriptionStatus.Inactive;
    }

    public get isBilledUsingCreditCard() {
        return this.account.paymentMethod === PaymentMethod.CreditCard;
    }

    public get isCreditCardSet() {
        return !!this.account.paymentProcessorCustomerId;
    }

    public get isStandardOrganisation() {
        return this.account.organisationType === OrganisationType.Standard;
    }

    public get isUsingPricingModel() {
        return !!this.account.pricingModelId;
    }

    /**
      * Get the fixed annual cost of this subscription.
      * This does NOT take into account any per-user costs that may apply.
    */
    public get fixedAnnualFeeDollars() {
        return this.account.extensions.fixedMonthlyDollars * 12;
    }

    /**
     * Get the fixed monthly cost of this subscription.
     * This does NOT take into account any per-user costs that may apply.
     */
    public get fixedMonthlyDollars() {
        if (this.account.pricingModel) {
            return this.account.pricingModel.monthlyFeeDollars;
        }

        return this.account.monthlyFeeDollars;
    }

    public get isFree() {
        if (this.account.pricingModel) {
            return this.account.pricingModel.extensions.isFree;
        }

        return this.account.monthlyFeeDollars === 0;
    }

    public get billingPeriodLabel() {
        const billingMethod = this.isBilledUsingCreditCard
            ? "credit card payments"
            : "invoices";
        switch (this.account.billingPeriod) {
            case 1: return `monthly ${billingMethod}`;
            case 3: return `quarterly ${billingMethod}`;
            case 12: return `yearly ${billingMethod}`;
            default: return `${billingMethod} every ${this.account.billingPeriod} months`;
        }
    }

    public getLatestInvoice() {
        if (!this.account.invoices || !this.account.invoices.length) {
            return undefined;
        }

        let latestInvoice = this.account.invoices[0];
        this.account.invoices.forEach((invoice) => {
            if (invoice.date > latestInvoice.date) {
                latestInvoice = invoice;
            }
        });

        return latestInvoice;
    }
}

import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-dashboard-element-hq",
    templateUrl: "./dashboard-element-hq.component.html",
    styleUrls: ["./dashboard-element-hq.component.scss"],
})
export class DashboardElementHqComponent extends BaseComponent {
    @Input() public name?: string;
    @Input() public info?: string;
    @Input() public linkText?: string;
    @Input() public contentCard = false;
    @Input() @HostBinding("class.nested") public nested = false;
    @Input() @HostBinding("class.background") public background = true;

    @Input() public iconClass?: string;
    @Input() public iconTooltip?: string;

    @Input() public isExpandable = true;
    @Input() public isCollapsible = false;
    @Input() public collapsed = false;
    @Output() public collapsedChange = new EventEmitter<boolean>();

    @HostBinding("class.card") public card = true;

    public linkObject?: IAdaptLinkObject;
    @Input() public set url(value: string | IAdaptLinkObject | undefined) {
        this.linkObject = this.routeService.parseRouterObject(value);
    }

    @Input() public disableClick = false;
    @Output() public headerClicked = new EventEmitter<any>();

    public constructor(
        private routeService: RouteService,
        protected elementRef: ElementRef,
    ) {
        super(elementRef);
    }

    public get hasClickSubscribers() {
        return !this.disableClick && this.headerClicked.observers.length > 0;
    }

    public onHeaderClicked() {
        this.headerClicked.emit();
    }

    public scrollIntoView() {
        this.elementRef.nativeElement.scrollIntoView({ behavior: "smooth" });
    }

    public toggle() {
        if (this.isCollapsible) {
            this.collapsed = !this.collapsed;
            this.collapsedChange.emit(this.collapsed);
        }
    }
}

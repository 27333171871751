<ng-container *ngIf="anchors.length > 0; else noAnchor">
    <adapt-strategic-anchor *ngFor="let anchor of anchors"
                            [anchor]="anchor"
                            [isEditing]="isEditing"
                            [isCollapsible]="!isEditing"
                            [collapsed]="!isEditing"></adapt-strategic-anchor>
</ng-container>

<ng-template #noAnchor>
    <div class="alert alert-info">There are no strategic anchors defined</div>
</ng-template>

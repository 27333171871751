import { Component, Input } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Survey, SurveyStatus } from "@common/ADAPT.Common.Model/organisation/survey";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { ISurveyQuestions } from "@org-common/lib/survey/survey-questions.interface";
import { organisationDiagnosticSurveySummaryPage } from "@org-common/lib/survey/survey-summary-page/survey-summary-page.component";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { IWorkflowStepOverviewComponent, WorkflowStepOverviewComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { forkJoin, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { IOrganisationDiagnosticStatusCustomData } from "../organisation-diagnostic-status-custom-data";

@WorkflowStepOverviewComponent("adapt-show-survey-meeting")
@Component({
    selector: "adapt-show-survey-meeting",
    templateUrl: "./show-survey-meeting.component.html",
    styleUrls: ["./show-survey-meeting.component.scss"],
})
export class ShowSurveyMeetingComponent implements IWorkflowStepOverviewComponent {
    public readonly SurveyStatus = SurveyStatus;

    @Input() public workflowConnection?: WorkflowConnection;

    public survey?: Survey;
    public meeting?: Meeting;
    public surveyQuestions?: ISurveyQuestions;

    public constructor(
        private workflowService: WorkflowService,
        private surveyService: SurveyService,
        private meetingsService: MeetingsService,
    ) { }

    public onDataChanged() {
        if (this.workflowConnection) {
            this.workflowService.getWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(this.workflowConnection).pipe(
                switchMap((customData) => {
                    if (customData.surveyId) {
                        return forkJoin([
                            this.surveyService.getSurveyById(customData.surveyId),
                            // survey will always be there when this component is shown but meeting may not necessarily be schedule yet
                            customData.meetingId ? this.meetingsService.getMeetingById(customData.meetingId) : of(undefined),
                        ]);
                    }

                    return of([undefined, undefined]);
                }),
            ).subscribe(([survey, meeting]) => {
                if (survey) {
                    this.surveyQuestions = this.surveyService.getQuestionsForSurvey(survey);
                }

                this.survey = survey;
                this.meeting = meeting;
            });
        }
    }

    public viewSurveySummary() {
        organisationDiagnosticSurveySummaryPage.gotoRoute()
            .subscribe();
    }
}

import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { BreezeModelUtilities } from "@common/lib/data/breeze-model-utilities";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { ContactType } from "../embed/contact-type";
import { UserType } from "../embed/user-type";
import { MethodologyUser } from "../methodologyUser";
import { Board } from "../organisation/board";
import { CareerValuation } from "../organisation/career-valuation";
import { Connection } from "../organisation/connection";
import { CulturalIndex } from "../organisation/cultural-index";
import { CulturalRelationship } from "../organisation/cultural-relationship";
import { Practitioner } from "../practitioner/practitioner";
import { PersonContact } from "./person-contact";
import { PersonDetail } from "./person-detail";
import { PersonPreferences } from "./person-preferences";
import { PersonProfileItemValue } from "./person-profile-item-value";

export class Person extends BaseEntity<Person>{
    public personId!: number;
    public firstName!: string;
    public lastName!: string;
    public userId?: string;
    public imageIdentifier?: string;

    public boards!: Board[];
    public careerValuations!: CareerValuation[];
    public connections!: Connection[];
    public culturalCohortRelationships!: CulturalRelationship[];
    public culturalIndexes!: CulturalIndex[];
    public culturalLeaderRelationships!: CulturalRelationship[];
    public personContacts!: PersonContact[];
    public personDetails!: PersonDetail[];
    public personProfileItemValues!: PersonProfileItemValue[];
    public practitioners!: Practitioner[];
    public preferences!: PersonPreferences;
    public methodologyUser?: MethodologyUser;

    public get fullName() {
        const names: string[] = [];

        if (this.firstName) {
            names.push(this.firstName);
        }

        if (this.lastName) {
            names.push(this.lastName);
        }

        return names.join(" ");
    }

    public get initials() {
        return this.firstName && this.lastName
            ? `${this.firstName[0]}${this.lastName[0]}`
            : `${this.firstName[0]}`;
    }

    public getLoginEmail() {
        return this.personContacts.find((contact) => contact.contactType === ContactType.Email && contact.isPreferred);
    }

    public getContactsOfType(type: ContactType) {
        return this.personContacts
            .filter((contact) => contact.contactType === type);
    }

    public isCoach() {
        if (!this.practitioners || (this.practitioners.length === 0)) {
            return false;
        }

        return ActiveEntityUtilities.isActive(this.practitioners[0]);
    }

    public getDetailValue(detailName: string): string | undefined {
        if (!this.personDetails || (this.personDetails.length === 0)) {
            return undefined;
        }

        const record = this.personDetails.find((detail) => detail.name === detailName);
        if (!record) {
            return undefined;
        }

        return record.value;
    }

    public getLatestEmployeeConnection() {
        return this.getLatestConnection(ConnectionType.Employee);
    }

    /**
     * Gets the latest connection based on the passed in connection types
     * If a connection type is not specified, then we will search in order of active Employee, Stakeholder and Coach connections
     * then by any connection (active or not) and any type (employee, stakeholder, coach)
     *
     * @param connectionType Filter the latest connection by this/these connection types
     */
    public getLatestConnection(connectionType?: ConnectionType | ConnectionType[]): Connection | undefined {
        let connections = this.connections;

        if (connectionType) {
            if (!Array.isArray(connectionType)) {
                connectionType = [connectionType];
            }

            const connectionTypesToFilterBy = connectionType;
            connections = connections.filter((c) => connectionTypesToFilterBy.some((ct) => ct === c.connectionType));
        } else {
            // no connection type specified so do in order Employee -> Stakeholder -> Coach
            let connection = this.getLatestConnection(ConnectionType.Employee);
            if (connection && connection.isActive()) {
                return connection;
            }

            connection = this.getLatestConnection(ConnectionType.Stakeholder);
            if (connection && connection.isActive()) {
                return connection;
            }

            connection = this.getLatestConnection(ConnectionType.Coach);
            if (connection && connection.isActive()) {
                return connection;
            }

            // if it gets here, then just return the latest or active connection amongst all of our connections (the previous behaviour)
        }

        return BreezeModelUtilities.getLatestOrActive(connections);
    }

    public isActive() {
        if (!this.connections.length) {
            return false;
        }

        return this.getLatestConnection()!.isActive();
    }

    public getActiveConnections() {
        return BreezeModelUtilities.getActive(this.connections);
    }

    public get isStakeholderManager() {
        // NOTE: this assumes that all connections are primed!
        return this.connections.length === 0;
    }

    public get isParticipant() {
        return this.getLatestConnection()?.userType === UserType.Viewer;
    }
}

export const PersonBreezeModel = new BreezeModelBuilder("Person", Person)
    .withIdField()
    .hasSource()
    .isOrganisationEntity()
    .withPluralName("People")
    .withSortField("fullName")
    .withLabelField("fullName")
    .withActivePath("connections")
    .withSearchField("concat(firstName, concat('' '', lastName))")
    .build();

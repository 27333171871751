import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import moment from "moment";

export class DateUtilities {
    /**
     * Determines if the supplied date is out of range for a 'normal' date (no dates in adapt are before 2000 or so for example)
     * This is likely to be used for the purpose of filtering out records based on these empty/null/very early dates
     *
     * When a null date value comes across in a breeze entity it comes across as year 0
     * When a empty date is created in TS, then the minimum seems to be 1899 (which corresponds to year 0)
     * JS dates seem to use two digit numbering so a year of 2 is actually 1901, year of 1 is actually 1900, etc
     *
     * @param date - date to check
     */
    public static isOutOfRange(date: Date): boolean {
        if (!date) {
            return true;
        }

        return date.getFullYear() <= 1900;
    }

    /**
     * Determines whether entity is active at given date.
     * Checks entity's start date is before given date and
     * entity's end date is either null or after given date
     *
     * @param entity - entity to check
     * @param date - date to check
     */
    public static entityIsActiveAt(entity: IBreezeEntity, date: Date) {
        return !!entity
            && entity.startDate <= date
            && (entity.endDate === null || entity.endDate >= date);
    }

    /**
     * returns a JS Date object that is equal to the start of the day in the user's current timezone.
     */
    public static getStartOfTodayLocalTime() {
        return moment().local()
            .startOf("day")
            .toDate();
    }

    /**
     * returns a JS Date object representing the first working day in the given year/month.
     */
    public static getFirstWorkingDay(year: number, month: number, day?: number) {
        let firstWorkingDay = moment(day ? [year, month, day] : [year, month]);
        // add days until the day is a working day
        while (firstWorkingDay.day() % 6 === 0) {
            firstWorkingDay = firstWorkingDay.add(1, "day");
        }
        return firstWorkingDay.toDate();
    }

    // TODO: write replaceDate tests
    /**
     * Replace the date of oldDate with the date of newDate, without changing the time.
     * @param oldDate date to replace
     * @param newDate desired date
     */
    public static replaceDate(oldDate: Date, newDate: Date) {
        const outDate = new Date(oldDate);
        outDate.setUTCFullYear(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate());
        return outDate;
    }

    // TODO: write replaceTime tests
    /**
     * Replace the time of oldDate with the time of newDate, without changing the date.
     * @param oldDate time to replace
     * @param newDate desired time
     */
    public static replaceTime(oldDate: Date, newDate: Date) {
        const outDate = new Date(oldDate);
        outDate.setUTCHours(newDate.getUTCHours(), newDate.getUTCMinutes(), newDate.getUTCSeconds(), newDate.getUTCMilliseconds());
        return outDate;
    }
}

// This is to be kept in-sync with server's Security/FeaturePermissionNames.cs
export enum FeaturePermissionName {

    CulturalNetworkCulturalLeadershipCohortRead = "CulturalNetwork.CulturalLeadership.Cohort.Read",
    CulturalNetworkCulturalLeadershipCohortConfigure = "CulturalNetwork.CulturalLeadership.Cohort.Configure",
    CulturalNetworkCulturalIndexPersonalUseAndRead = "CulturalNetwork.CulturalIndex.PersonalUseAndRead",
    CulturalNetworkCulturalIndexPersonalEdit = "CulturalNetwork.CulturalIndex.PersonalEdit",
    CulturalNetworkCulturalIndexRead = "CulturalNetwork.CulturalIndex.Read",
    CulturalNetworkCulturalIndexAnonymousRead = "CulturalNetwork.CulturalIndex.AnonymousRead",
    CulturalNetworkCulturalIndexQuantitativeRead = "CulturalNetwork.CulturalIndex.QuantitativeRead",
    CulturalNetworkCareerValuationPersonalUseAndRead = "CulturalNetwork.CareerValuation.PersonalUseAndRead",
    CulturalNetworkCareerValuationPersonalEdit = "CulturalNetwork.CareerValuation.PersonalEdit",
    CulturalNetworkCareerValuationRead = "CulturalNetwork.CareerValuation.Read",
    CulturalNetworkCareerValuationAnonymousRead = "CulturalNetwork.CareerValuation.AnonymousRead",
    CulturalNetworkCareerValuationQuantitativeRead = "CulturalNetwork.CareerValuation.QuantitativeRead",
    CulturalNetworkEmployeeEngagementRead = "CulturalNetwork.EmployeeEngagement.Read",
    CulturalNetworkEmployeeEngagementEdit = "CulturalNetwork.EmployeeEngagement.Edit",
    CulturalNetworkTeamAssessmentRead = "CulturalNetwork.TeamAssessment.Read",
    CulturalNetworkTeamAssessmentEdit = "CulturalNetwork.TeamAssessment.Edit",
    CulturalNetworkResilientBusinessAssessmentRead = "CulturalNetwork.ResilientBusinessAssessment.Read",
    CulturalNetworkResilientBusinessAssessmentEdit = "CulturalNetwork.ResilientBusinessAssessment.Edit",
    OrganisationDiagnosticRead = "Organisation.Diagnostic.Read",
    OrganisationDiagnosticEdit = "Organisation.Diagnostic.Edit",

    PeoplePeerCatchUpPersonalUseAndRead = "People.PeerCatchUp.PersonalUseAndRead",
    PeoplePeerCatchUpPersonalEdit = "People.PeerCatchUp.PersonalEdit",
    PeoplePeerCatchUpRead = "People.PeerCatchUp.Read",
    PeoplePeerCatchUpEdit = "People.PeerCatchUp.Edit",
    PeoplePeerCatchUpAnonymousRead = "People.PeerCatchUp.AnonymousRead",

    PeopleProfilePersonalUseAndRead = "People.Profile.PersonalUseAndRead",
    PeopleProfilePersonalEdit = "People.Profile.PersonalEdit",
    PeopleProfilePublicRead = "People.Profile.PublicRead",
    PeopleProfileRead = "People.Profile.Read",
    PeopleProfileEdit = "People.Profile.Edit",
    PeoplePositionPersonalEdit = "People.Position.PersonalEdit",
    PeoplePositionEdit = "People.Position.Edit",

    CompassGuidingPhilosophyRead = "Compass.GuidingPhilosophy.Read",
    CompassGuidingPhilosophyEdit = "Compass.GuidingPhilosophy.Edit",
    CompassValuesConstitutionRead = "Compass.ValuesConstitution.Read",
    CompassValuesConstitutionEdit = "Compass.ValuesConstitution.Edit",
    CompassResilientBusinessGoalsRead = "Compass.ResilientBusinessGoals.Read",
    CompassResilientBusinessGoalsEdit = "Compass.ResilientBusinessGoals.Edit",

    StewardshipWorkKanbanPersonalEdit = "Stewardship.Work.Kanban.PersonalEdit",
    StewardshipWorkKanbanRead = "Stewardship.Work.Kanban.Read",
    StewardshipWorkKanbanEdit = "Stewardship.Work.Kanban.Edit",
    StewardshipWorkKanbanPublicRead = "Stewardship.Work.Kanban.PublicRead",
    StewardshipWorkKanbanPublicEdit = "Stewardship.Work.Kanban.PublicEdit",
    StewardshipObjectivesRead = "Stewardship.Objectives.Read",
    StewardshipObjectivesPublicRead = "Stewardship.Objectives.PublicRead",
    StewardshipObjectivesEdit = "Stewardship.Objectives.Edit",

    OrganisationTeamRead = "Organisation.Team.Read",
    OrganisationTeamConfigure = "Organisation.Team.Configure",
    OrganisationConfigure = "Organisation.Configure",
    OrganisationAccessManagementConfigure = "Organisation.AccessManagement.Configure",
    OrganisationBillingConfigure = "Organisation.Billing.Configure",
    OrganisationExternalDashboardRead = "Organisation.ExternalDashboard.Read",
    OrganisationExternalDashboardPublicRead = "Organisation.ExternalDashboard.PublicRead",

    PlatformStandardRead = "Platform.Standard.Read",
    PlatformBillingEdit = "Platform.Billing.Edit",
    PlatformWorkflowEdit = "Platform.Workflow.Edit",

    ArchitectureValueStreamContentsRead = "Architecture.ValueStreamContents.Read",
    ArchitectureValueStreamContentsEdit = "Architecture.ValueStreamContents.Edit",
    ArchitectureTier1Read = "Architecture.Tier1.Read",
    ArchitectureTier1Edit = "Architecture.Tier1.Edit",
    ArchitectureTier2Read = "Architecture.Tier2.Read",
    ArchitectureTier2Edit = "Architecture.Tier2.Edit",
    ArchitectureTier2Configure = "Architecture.Tier2.Configure",
    ArchitectureTier2PersonalEdit = "Architecture.Tier2.PersonalEdit",

    StakeholdersPeopleRead = "Stakeholders.People.Read",

    BullseyeRead = "Strategy.Bullseye.Read",
    BullseyeEdit = "Strategy.Bullseye.Edit",
    StrategyBoardRead = "Strategy.StrategyBoard.Read",
    StrategyBoardEdit = "Strategy.StrategyBoard.Edit",
    StrategicInputsRead = "Strategy.StrategicInputs.Read",
    StrategicInputsEdit = "Strategy.StrategicInputs.Edit",
    StrategicGoalsRead = "Strategy.StrategicGoals.Read",
    StrategicGoalsEdit = "Strategy.StrategicGoals.Edit",
    StrategicAnchorsRead = "Strategy.StrategicAnchors.Read",
    StrategicAnchorsEdit = "Strategy.StrategicAnchors.Edit",
}

<div class="card"
     *adaptLoadingUntilFirstEmit="let account of account$">
    <div class="card-header">
        <h3 class="card-title">Subscription</h3>
    </div>
    <div class="card-body">
        <ng-container *ngIf="account && account.eulaAcceptedDate; else eulaNotAcceptedTemplate">
            <p>Click <button adaptButton="iconlessLink"
                        (click)="showEula()">here</button> to view the Customer License Agreement.</p>
        </ng-container>
        <ng-container *ngIf="account && account.extensions.isActive; else notBilledTemplate">
            <ng-container *ngIf="account.extensions.isFree; else chargedSubscriptionTemplate">
                You are on a free plan.
            </ng-container>

            <button *adaptIfAlto="true"
                    class="mt-2"
                    adaptButton="primary"
                    [adaptBlockingClick]="accountService.cancelSubscriptionDialog"
                    [unsubscribeOnDestroy]="false">Cancel subscription
            </button>
        </ng-container>
    </div>

    <ng-template #eulaNotAcceptedTemplate>
        <div class="mb-3">
            <div class="alert alert-warning"
                 role="alert">
                <span class="fal fa-info-circle"></span>
                The customer license agreement has not been accepted for this organisation.
            </div>

            <!-- TODO Only coach? Another permission for this? -->
            An organisation leader must sign the agreement before any user access is allowed.
            Please select the organisation leader from the list below to accept the agreement.
            <adapt-select-person class="ms-1"
                                 [(person)]="eulaPerson"
                                 [userTypes]="allowedEulaPersonUserTypes"
                                 [filter]="isEmployee"></adapt-select-person>
            <button adaptButtonIcon="fal fa-file-signature"
                    [disabled]="!eulaPerson"
                    [adaptBlockingClick]="sendEulaInvite"
                    [adaptBlockingClickParam]="eulaPerson">
                Send License Agreement Email
            </button>

            <div *ngIf="emailSentSuccessfully"
                 class="alert alert-info my-1"
                 role="alert">
                Email sent successfully
            </div>

            <div *ngIf="account?.eulaPerson && !emailSentSuccessfully"
                 class="mt-2">
                The customer license agreement has previously been sent to {{account!.eulaPerson!.fullName}}.
            </div>
        </div>
    </ng-template>

    <ng-template #chargedSubscriptionTemplate>
        <ng-container *ngIf="account && account.extensions.isUsingPricingModel; else legacyBillingModelTemplate">
            <p>
                Your
                <span *adaptIfAlto="false">embedADAPT</span>
                <span *adaptIfAlto="true">adapt HQ</span>
                subscription
                <span *ngIf="account.pricingModel?.monthlyFeeDollars">has a fixed monthly fee of
                    ${{account.pricingModel?.monthlyFeeDollars}} <small>(ex. GST)</small></span>
                <span *ngIf="!account.pricingModel?.monthlyFeeDollars">is based on the amount of users:</span>
            </p>
            <adapt-display-pricing-breakdown *ngIf="!account.pricingModel?.monthlyFeeDollars"
                                             [pricingModel]="account.pricingModel">
            </adapt-display-pricing-breakdown>
        </ng-container>

        <ng-template #legacyBillingModelTemplate>
            <p>Billed {{account?.monthlyFeeDollars | currency:account?.currency?.shortName}}/month <small>(ex. GST)</small></p>
        </ng-template>

        <div *ngIf="invoice$ | async as invoice">
            <p>
                Next payment is on <strong>{{invoice.date | adaptDate}}</strong>
            </p>
        </div>
    </ng-template>

    <ng-template #notBilledTemplate>
        <p>Currently, your organisation does not have an active subscription to ADAPT.</p>

        <div *ngIf="commenceSubscriptionTooltip$ | async"
             role="alert"
             class="alert alert-warning">
            <span class="fal fa-info-circle"></span>
            {{commenceSubscriptionTooltip$ | async}}
        </div>

        <ng-container *ngIf="(canChargeOrganisation$ | async) else noPermissionToChargeSubscriptionTemplate">
            <button class="btn btn-primary"
                    adaptButtonIcon="fal fa-dollar-sign"
                    [disabled]="commenceSubscriptionTooltip$ | async"
                    [adaptBlockingClick]="commenceSubscription"
                    [unsubscribeOnDestroy]="false">
                Commence ADAPT subscription
            </button>

            <div role="alert"
                 class="alert alert-info mt-3">
                <h4><span class="fal fa-info-circle me-1"></span>Potential Subscription Costs</h4>

                <label>How much would we be charging you?</label>
                <p *ngIf="account?.extensions?.isFree; else potentialChargedSubscriptionTemplate">
                    Nothing! You are on a free plan.
                </p>

                <ng-template #potentialChargedSubscriptionTemplate>
                    <ng-container *ngIf="account?.extensions?.isUsingPricingModel; else potentialLegacyBillingModelTemplate">
                        <p>You would be billed on a per user basis. The amount charged will be based on the number of active users
                            on the day of billing.
                        </p>
                        <adapt-display-pricing-breakdown *ngIf="account?.pricingModel as pricingModel"
                                                         [pricingModel]="pricingModel">
                        </adapt-display-pricing-breakdown>
                    </ng-container>
                    <ng-template #potentialLegacyBillingModelTemplate>
                        <p>Annual Fee: {{account?.extensions?.fixedAnnualFeeDollars | currency:account?.currency?.shortName}} ex. GST</p>
                    </ng-template>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #noPermissionToChargeSubscriptionTemplate>
            <ng-container *adaptIfAlto="false">Please see your resilient business coach to initiate a subscription.</ng-container>
        </ng-template>
    </ng-template>
</div>

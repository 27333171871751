<div *adaptLoading="!isInitialised"
     class="m-3 d-flex flex-column w-100">
    <ng-container *ngIf="item; else noSelectedItem">
        <div class="d-flex align-items-baseline">
            <adapt-item-link [item]="item"
                             [displayAsBadge]="true"
                             (itemDialogOpened)="itemDialogOpened.emit($event)"
                             (itemDialogClosed)="itemDialogClosed.emit($event)"></adapt-item-link>

            <adapt-display-item-status [itemStatus]="item.status"
                                       class="mx-1"></adapt-display-item-status>

            <div class="ms-auto">
                <button adaptButton="editBorderless"
                        [adaptBlockingClick]="openItem"
                        data-test="open-preview-item"></button>
                <button adaptButton="closeBorderless"
                        (click)="closeClicked.emit()"></button>
            </div>
        </div>
        <adapt-display-labels class="mt-2"
                              [labelLocations]="item.labelLocations"></adapt-display-labels>

        <div class="mt-4 d-flex flex-row align-items-center">
            <div>
                <adapt-link-person [person]="item.assignee"
                                   [imageOnly]="true"
                                   class="me-1"></adapt-link-person>
            </div>
            <div>
                <h3 class="mb-0 text-wrap"
                    data-test="preview-summary">{{item.summary}}</h3>
                <small *ngIf="item.dueDate"
                       class="mt-1 d-block">Due: {{item.dueDate | adaptDate}}</small>
            </div>
        </div>
        <div class="d-flex">
            <adapt-see-more [maxHeight]="110">
                <div class="mt-3"
                     [froalaView]="item.description"
                     data-test="preview-description"></div>
            </adapt-see-more>
        </div>

        <div class="mt-4 d-flex flex-column"
             *ngIf="item.links?.length || item.objectiveItemLinks?.length">
            <h4>Links</h4>
            <div class="mt-1 d-flex flex-column">
                <adapt-item-link *ngFor="let link of item.links"
                                 [item]="link.secondaryItem"
                                 [showDialogLink]="true"
                                 [showStatus]="true"
                                 [showSummary]="true"></adapt-item-link>
                <adapt-link-objective *ngFor="let link of item.objectiveItemLinks"
                                      [objective]="link.objective"></adapt-link-objective>
            </div>
        </div>

        <div class="mt-4 d-flex flex-column flex-grow-1">
            <h4>Comments</h4>
            <adapt-comment-chain class="d-flex flex-column flex-grow-1 mb-n3 add-comment-smaller-width"
                                 [comments]="itemComments"
                                 [allowNewCommentSave]="!!newComment$"
                                 [createComment$]="newComment$"
                                 [createNewCommentAfterSave]="false"
                                 [focusAddCommentOnInit]="true"
                                 (saved)="onNewCommentSaved()"
                                 (newCommentDiscarded)="newComment$ = undefined"></adapt-comment-chain>
        </div>

        <ng-container *ngIf="hasEditAccessToItem$ | async">
            <div class="panel-bottom align-items-end pb-3"
                 *ngIf="!newComment$">
                <adapt-add-comment-place-holder [item]="item"
                                                (newCommentAdded)="onNewCommentAdded($event)"
                                                data-test="add-comment-place-holder"></adapt-add-comment-place-holder>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #noSelectedItem>
        <span>No action selected</span>
    </ng-template>
</div>

export enum ObjectiveType {
    Annual = "Annual",
    Quarterly = "Quarterly",
}

export class ObjectiveTypeMetadata {
    public static readonly Annual = new ObjectiveTypeMetadata(
        ObjectiveType.Annual,
        "AO",
        "Annual Objective",
        "far fa-th-large",
    );
    public static readonly Quarterly = new ObjectiveTypeMetadata(
        ObjectiveType.Quarterly,
        "QO",
        "Quarterly Objective",
        "far fa-cube",
    );

    public static readonly ByType: { [type in ObjectiveType]: ObjectiveTypeMetadata } = {
        [ObjectiveType.Annual]: ObjectiveTypeMetadata.Annual,
        [ObjectiveType.Quarterly]: ObjectiveTypeMetadata.Quarterly,
    };

    /** Lookup by the enum value from the backend */
    public static readonly ByIntValue: { [intValue: number]: ObjectiveTypeMetadata } = {
        0: ObjectiveTypeMetadata.Annual,
        1: ObjectiveTypeMetadata.Quarterly,
    };

    private constructor(
        public readonly type: ObjectiveType,
        public readonly code: string,
        public readonly name: string,
        public readonly iconClass: string,
    ) { }

    public get pluralName() {
        return this.name + "s";
    }
}

<adapt-dialog [maxWidth]="800"
              [minWidth]="480">
    <h3 adapt-dialog-title>Outstanding responses for survey: {{survey.name}}</h3>

    <div adapt-dialog-content>
        <div *adaptLoadingUntilFirstEmit="let outstandingSurveyResponses of outstandingSurveyResponses$">
            <div class="alert alert-info"
                 *ngIf="outstandingSurveyResponses.length < 1; else showTable">
                There are currently no outstanding survey responses for this survey. All responses have been submitted.
            </div>
            <ng-template #showTable>
                <dx-data-grid [dataSource]="outstandingSurveyResponses"
                              [rowAlternationEnabled]="true"
                              [hoverStateEnabled]="true"
                              [showRowLines]="true"
                              [showBorders]="true">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true"
                               [allowedPageSizes]="[5, 10, 20]"
                               [showInfo]="true"></dxo-pager>
                    <dxi-column caption="Name"
                                [allowSorting]="true"
                                dataField="connection.person.fullName"
                                cellTemplate="fullNameCellTemplate">
                    </dxi-column>
                    <dxi-column caption="Email"
                                [allowSorting]="true"
                                [calculateCellValue]="getEmail"></dxi-column>
                    <dxi-column caption="Status"
                                [allowSorting]="true"
                                [sortIndex]="0"
                                sortOrder="asc"
                                [width]="150"
                                cellTemplate="statusTemplate"
                                [calculateCellValue]="getStatus"></dxi-column>
                    <dxi-column type="buttons"
                                [width]="isStakeholderManager ? 60 : 30"
                                cellTemplate="actionTemplate"></dxi-column>

                    <adapt-link-person *dxTemplate="let item of 'fullNameCellTemplate'"
                                       [person]="item.data.connection?.person"></adapt-link-person>
                    <div *dxTemplate="let item of 'statusTemplate'">
                        <span>{{item.value}}</span>
                        <i class="ms-1 fal fa-info-circle"
                           *ngIf="item.data.statusMessage"
                           [adaptTooltip]="item.data.statusMessage"></i>
                    </div>
                    <div *dxTemplate="let item of 'actionTemplate'">
                        <!-- Note: Can't use blockingButtonGroup and blockingClick here as the tooltip won't go away
                            TODO: leave that for another day when not waiting for this ticket to be released -->
                        <ng-container *ngIf="reloading[item.data.connectionId]; else showButtons">
                            <i class="fal fa-spinner fa-spin"></i>
                        </ng-container>
                        <ng-template #showButtons>
                            <button class="btn btn-link"
                                    adaptTooltip="Resend email"
                                    adaptButtonIcon="fal fa-fw fa-share"
                                    (click)="resendEmail(item.data.token, item.data.connection)"></button>
                            <button class="btn btn-link ms-2"
                                    *ngIf="isStakeholderManager"
                                    adaptTooltip="Copy survey link"
                                    adaptButtonIcon="fal fa-fw fa-copy"
                                    (click)="copySurveyLink(item.data.token)"></button>
                        </ng-template>
                    </div>
                </dx-data-grid>
            </ng-template>
        </div>
        <div class="mt-3 alert alert-info">
            Note that {{adaptProjectLabel}} will only track survey responses which have not been submitted. Once a response is submitted, it
            can no longer be tracked.
        </div>
        <div class="mt-3 d-flex align-items-center"
             *ngIf="showEndTime">
            <span class="me-3">The deadline for the survey is:</span>
            <dx-date-box type="datetime"
                         [openOnFieldClick]="true"
                         [displayFormat]="dateTimeDisplayFormat"
                         [showAnalogClock]="false"
                         [min]="minEndTime"
                         [value]="survey.endTime"
                         (valueChange)="onEndTimeChanged($any($event))"
                         data-test="survey-end-date-input"></dx-date-box>
        </div>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button #focus
                adaptButton="close"
                (click)="cancel()"></button>
    </div>
</adapt-dialog>

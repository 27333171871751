<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.IntroducingObjectivesAndKeyResults"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div adaptToolbarContent
     adaptBlockingButtonGroup
     *ngIf="hasEditPermissions && (primedObjective$ | async) as objective">
    <adapt-objective-actions [objective]="objective"
                             (objectiveDeleted)="onObjectiveDeleted($event)"></adapt-objective-actions>
</div>

<div class="edit-objective-page-component-container"
     *ngIf="(primedObjective$ | async) as objective; else notFoundTemplate">
    <adapt-toolbar-title *ngIf="isXL$ | async">
        <adapt-link-objective class="objective-title"
                              [objective]="objective"
                              [generateHref]="false"
                              [showIcon]="true"
                              [showStatus]="true"
                              [showStatusIcon]="false"></adapt-link-objective>
    </adapt-toolbar-title>

    <div class="card card-borderless">
        <div class="card-body">
            <div class="row">
                <div class="mainbar col-md-6 col-lg-8 d-flex flex-column">
                    <div class="objective-section"
                         *ngIf="(isXL$ | async) === false">
                        <adapt-link-objective class="objective-title"
                                              [objective]="objective"
                                              [generateHref]="false"
                                              [showIcon]="true"
                                              [showStatus]="true"
                                              [showStatusIcon]="false"></adapt-link-objective>
                    </div>
                    <adapt-display-labels [labelLocations]="objective.labelLocations"
                                          *ngIf="objective.labelLocations.length > 0"
                                          class="mb-3"
                                          data-test="objective-labels-display"></adapt-display-labels>
                    <div class="objective-section">
                        <label class="objective-section-title">Description</label>
                        <div [froalaView]="objective.description ? objective.description : 'No description'"></div>
                    </div>

                    <div class="objective-section"
                         *ngIf="objective.parentObjective as parentObjective">
                        <label class="objective-selection-title">In support of:</label>
                        <adapt-link-objective [objective]="parentObjective"></adapt-link-objective>
                    </div>

                    <ng-container *ngIf="(childObjectives$ | async) as childObjectives">
                        <div class="objective-section"
                             *ngIf="childObjectives.length > 0">
                            <label class="objective-selection-title">Supported by:</label>
                            <ul>
                                <li *ngFor="let group of childObjectives; let first = first">
                                    <ul [ngClass]="{'mt-2': !first}">
                                        <adapt-link-objectives-page [team]="group.team"
                                                                    [addObjectiveTextSuffix]="true"></adapt-link-objectives-page>
                                        <ul class="ms-3">
                                            <li *ngFor="let childObjective of group.objectives">
                                                <adapt-link-objective [objective]="childObjective"></adapt-link-objective>
                                            </li>
                                        </ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </ng-container>

                    <div class="objective-section objective-section-activity">
                        <label class="objective-section-title">Activity</label>
                        <adapt-comment-chain class="adapt-comment-chain-container"
                                             (chainUpdateDeleted)="deleteChainUpdate($event)"
                                             (chainUpdateEdited)="editChainUpdate($event)"
                                             (updateRequired)="refresh()"
                                             [comments]="chainComments$ | async"
                                             [updates]="chainUpdates$ | async"
                                             [createComment$]="newComment$"
                                             noCommentsText="No activity yet."></adapt-comment-chain>
                    </div>
                </div>
                <div class="sidebar col-md-6 col-lg-4">
                    <ng-container *ngIf="objective.keyResults.length > 0; else noScoreGuidance">
                        <div class="form-group objective-section">
                            <label class="objective-section-title">Score</label>
                            <adapt-display-objective-progress [objective]="objective"
                                                              [scaleHeight]="2"
                                                              data-test="objective-score"></adapt-display-objective-progress>
                        </div>

                        <div class="form-group objective-section">
                            <label class="objective-section-title">
                                Key Results
                            </label>
                            <button *ngIf="!isClosed && hasEditPermissions"
                                    adaptButtonIcon="add"
                                    class="btn btn-link"
                                    adaptTooltip="Add key result"
                                    (click)="addKeyResult()"></button>

                            <adapt-key-result-list-item *ngFor="let keyResult of objective.keyResults"
                                                        [keyResult]="keyResult"
                                                        [isEditing]="hasEditPermissions"
                                                        [layout]="listItemLayout"
                                                        [onDeletion]="refresh"
                                                        (updated)="refresh()"></adapt-key-result-list-item>
                        </div>
                    </ng-container>
                    <ng-template #noScoreGuidance>
                        <div class="alert alert-info">
                            <p>This objective cannot be scored as no Key Results have been defined yet.</p>
                            <div *ngIf="hasEditPermissions && !isClosed">
                                <p>Add a Key Result to start scoring.</p>
                                <button adaptButtonIcon="add"
                                        class="btn btn-primary btn-small"
                                        (click)="addKeyResult()"
                                        adaptTooltip="Add key result"
                                        data-test="add-key-result-button">Add Key Result</button>
                            </div>
                        </div>
                    </ng-template>

                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6 objective-section">
                                <label class="objective-section-title">When</label>
                                <div>
                                    <span>{{objective.dueDate | adaptDate}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 objective-section">
                                <label class="objective-section-title">Owner</label>
                                <div>
                                    <adapt-link-person [person]="objective.assigneePerson"
                                                       [showImage]="true"></adapt-link-person>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group objective-section">
                        <div>
                            <label class="objective-section-title">Linked objectives</label>
                            <button adaptButtonIcon="add"
                                    *ngIf="!isClosed && hasEditPermissions"
                                    class="btn btn-link"
                                    adaptTooltip="Add a link to an existing objective"
                                    (click)="addObjectiveLink()"
                                    data-test="add-related-objective-button">
                            </button>
                        </div>
                        <div *ngIf="objective.objectiveLinks.length === 0">No linked objectives.</div>
                        <div *ngFor="let link of objective.objectiveLinks"
                             class="link-section d-flex justify-content-between">
                            <div class="d-inline-flex me-2">
                                <adapt-link-objective [objective]="link.objective2"></adapt-link-objective>
                                <button adaptButtonIcon="delete"
                                        *ngIf="!isClosed && hasEditPermissions"
                                        class="btn btn-link link-delete-button"
                                        adaptTooltip="Remove related objective"
                                        (click)="removeObjectiveLink(link)"></button>
                            </div>
                            <adapt-link-objectives-page class="team-link "
                                                        [team]="link.objective2.team"></adapt-link-objectives-page>
                        </div>
                    </div>

                    <div *adaptIfFeatureActive="FeatureName.StewardshipWorkKanban"
                         class="form-group objective-section">
                        <div>
                            <label class="objective-section-title">Linked actions</label>
                            <button adaptButtonIcon="add"
                                    *ngIf="!isClosed && hasEditPermissions"
                                    class="btn btn-link"
                                    adaptTooltip="Add a link to a new or existing action"
                                    (click)="addObjectiveItemLink()"
                                    data-test="add-related-item-button">
                            </button>
                        </div>
                        <div *ngIf="objective.itemLinks.length === 0">No linked actions.</div>
                        <div *ngFor="let link of objective.itemLinks"
                             class="link-section">
                            <adapt-item-link [item]="link.item"
                                             [showStatus]="true"
                                             [showSummary]="true"></adapt-item-link>
                            <button adaptButtonIcon="delete"
                                    *ngIf="!isClosed && hasEditPermissions"
                                    class="btn btn-link link-delete-button"
                                    adaptTooltip="Remove related action"
                                    (click)="removeObjectiveItemLink(link)"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #notFoundTemplate>
    <div class="row">
        <div class="alert alert-info col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <p>
                We were unable to find that objective.
                Either it has been deleted or you don't have permission to read it.
            </p>
        </div>
    </div>
</ng-template>

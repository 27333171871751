import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export class Anchor extends BaseEntity<Anchor> {
    public anchorId!: number;
    public name!: string;
    public description?: string;
    public organisationId!: number;
    public ordinal!: number;
    public updateTime?: Date;

    public organisation!: Organisation;
}

export const AnchorMetadata = {
    iconClass: "fal fa-fw fa-anchor",
    singularLabel: "Strategic Anchor",
    pluralLabel: "Strategic Anchors",
    articleId: ImplementationKitArticle.EditStrategicAnchorIntro,
};

export const AnchorBreezeModel = new BreezeModelBuilder("Anchor", Anchor)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .orderBy("ordinal")
    .build();

<adapt-feature-guidance [configItem]="configItem">
    <p>In a team, to enable effective stewardship, the work of the team should be tracked. The actions feature inside the platform allows
        you to visualise and track actions within a team.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <adapt-feature-guidance>
        <p>Enabling the meeting feature allows you to use the meeting framework within a team.</p>
    </adapt-feature-guidance>
    <dx-check-box text="Enable Meetings"
                  [value]="isMeetingsFeatureEnabled"
                  (valueChange)="onMeetingsFeatureStatusValueChanged($any($event))"></dx-check-box>
    <adapt-configure-meeting-agenda-templates *ngIf="isMeetingsFeatureEnabled"></adapt-configure-meeting-agenda-templates>
</adapt-feature-configuration>

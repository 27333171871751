<ng-container *ngIf="this.canvas; else showCTA">
    <adapt-toolbar-content *adaptIfAuthorised="EditStrategicInputs"
                           alignment="right">
        <button adaptButton="add"
                [adaptBlockingClick]="addCompetitor">competitor</button>
    </adapt-toolbar-content>
    <adapt-toolbar-title>
        <h3>Competitors</h3>
    </adapt-toolbar-title>

    <div *ngIf="competitors.length > 0; else showCTA"
         class="competitors">
        <adapt-input-group *ngFor="let competitor of competitors"
                           [inputGroup]="competitor"></adapt-input-group>
    </div>
</ng-container>

<ng-template #showCTA>
    <adapt-call-to-action>
        <adapt-call-to-action-title>Competitor analysis</adapt-call-to-action-title>
        <p>
            The competitor's analysis page is designed to help you gain valuable insights into your competitors and their performance.
            Here, you have the opportunity to add information about your competitors, including their 'how they win against you', 'how they
            lose against you', and 'how they compete with you'.
        </p>
        <button *adaptIfAuthorised="EditStrategicInputs"
                adaptCallToActionButton
                adaptButtonIcon="add"
                [adaptBlockingClick]="addCompetitor">
            Add competitor
        </button>
    </adapt-call-to-action>
</ng-template>

import { Component, Inject, Injector } from "@angular/core";
import { Anchor } from "@common/ADAPT.Common.Model/organisation/anchor";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxListItemReorderedEvent } from "@common/ux/dx.types";

@Component({
    selector: "adapt-reorder-anchors-dialog",
    templateUrl: "./reorder-anchors-dialog.component.html",
    styleUrls: ["../edit-anchor-dialog/edit-anchor-dialog.component.scss"],
})
export class ReorderAnchorsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Anchor[]> {
    public readonly dialogName = "ReorderAnchors";
    public articleId = ImplementationKitArticle.ReorderStrategicAnchorsWhy;

    protected entitiesToConfirm: Anchor[] = [];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public anchors: Anchor[],
    ) {
        super(injector);
        this.entitiesToConfirm = anchors;
        this.autoResolveData = anchors;
    }

    public get hasNoChanges() {
        return this.anchors.every((anchor) => anchor.entityAspect.entityState.isUnchanged());
    }

    public reorderAnchors(e: IDxListItemReorderedEvent<Anchor[]>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.anchors, "ordinal", e.fromIndex, e.toIndex);
    }
}

import { AfterViewChecked, Component, ElementRef, HostBinding, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BaseComponent } from "../../base.component/base.component";

@Component({
    selector: "adapt-see-more",
    templateUrl: "./see-more.component.html",
    styleUrls: ["./see-more.component.scss"],
})
export class SeeMoreComponent extends BaseComponent implements AfterViewChecked, OnChanges {
    @Input() public maxHeight = 200;

    public clippedContentStyle = {
        "max-height.px": this.maxContentHeight,
    };
    public showAllContent = false;
    public contentOverflows = false;

    private scrollHeight$ = this.createThrottledUpdater((scrollHeight: number) => this.contentOverflows = scrollHeight > this.maxHeight);

    public constructor(
        elementRef: ElementRef<HTMLElement>,
    ) {
        super(elementRef);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.maxHeight) {
            this.clippedContentStyle["max-height.px"] = this.maxContentHeight;
        }
    }

    @HostBinding("style.max-height.px")
    public get maxHeightStyle() {
        return this.showAllContent
            ? null
            : this.maxHeight + 19;
    }

    public ngAfterViewChecked() {
        const element = this.elementRef!.nativeElement;
        // content of first div is the projected content and that's what we are interested in
        const projectedContent = element.firstElementChild?.firstElementChild;
        if (projectedContent) {
            this.scrollHeight$.next(projectedContent.scrollHeight);
        }
    }

    public toggleShowMore() {
        this.showAllContent = !this.showAllContent;

        if (!this.showAllContent) {
            this.elementRef!.nativeElement.scrollIntoView({ behavior: "smooth" });
        }
    }

    public get buttonText() {
        return this.showAllContent
            ? "Show less"
            : "Show more";
    }

    private get maxContentHeight() {
        return `${this.maxHeight}`;
    }
}

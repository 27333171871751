import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStep } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { showWorkflowMeetingComponentSelector } from "@org-common/lib/meetings/show-workflow-meeting/show-workflow-meeting.component";
import { DisplayWorkflowActivityBriefComponentSelector, IActivityBriefData } from "@org-common/lib/workflow/display-workflow-activity-brief/display-workflow-activity-brief.component";
import { LocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { IRunWorkshopStepData } from "./shared/run-workshop-step/run-workshop-step.component";
import { IScheduleMeetingStepData } from "./shared/schedule-meeting-step/schedule-meeting-step.component";
import { WorkflowIdentifier } from "./workflow-identifier.enum";

export interface IWorkshopScheduleWorkflowOptions {
    // This name will override the workshopName, bypassing the "Workshop" suffix
    name?: string;

    // Article to show on the schedule step
    scheduleArticleSlug?: ImplementationKitArticle;

    // Article to show on the run step
    runArticleSlug?: ImplementationKitArticle;

    // overview component for schedule workflow
    scheduleOverviewComponentSelector?: string;

    // overview component for run workflow
    runOverviewComponentSelector?: string;

    // Custom data to be passed to adapt-schedule-meeting-step
    customData?: Partial<IScheduleMeetingStepData>;

    // Any features that need to be enabled for the meeting
    featuresToEnable?: FeatureName[];
}

/**
 * Create the workshop schedule and run workflows.
 * @param workshopName name of the workshop
 * @param workflowIdentifier ID of the workshop/workflow (used for meetingTemplateCode)
 */
export function createWorkshopScheduleWorkflows(workshopName: string, workflowIdentifier: WorkflowIdentifier, options?: IWorkshopScheduleWorkflowOptions): LocalWorkflow[] {
    const name = options?.name ?? `‘${workshopName}’ Meeting`;
    return [
        {
            name: `Schedule ${name}`,
            workflowId: `${workflowIdentifier}ScheduleMeeting`,
            type: WorkflowType.Workflow,
            dialogWidth: WorkflowDialogWidth.Large,
            continueOnFinish: false,
            featuresToEnable: [
                FeatureName.StewardshipWorkKanban,
                FeatureName.StewardshipWorkMeetings,
                ...(options?.featuresToEnable ?? []),
            ],
            steps: [{
                name: `Schedule ${name}`,
                ordinal: 0,
                allowBack: true,
                canSkip: false,
                articleSlug: options?.scheduleArticleSlug,
                componentSelector: "adapt-schedule-meeting-step",
                overviewComponentSelector: options?.scheduleOverviewComponentSelector ?? showWorkflowMeetingComponentSelector,
                closeAfterNext: true,
                customData: {
                    meetingTemplateCode: workflowIdentifier,
                    showConfirmationAfterSchedule: true,
                    ...(options?.customData ?? {}),
                } as IScheduleMeetingStepData,
            }],
        },
        {
            name: `Run ${name}`,
            workflowId: `${workflowIdentifier}RunWorkshop`,
            type: WorkflowType.Workflow,
            dialogWidth: WorkflowDialogWidth.Large,
            continueOnFinish: true,
            steps: [{
                name: `Run ${name}`,
                ordinal: 0,
                allowBack: true,
                canSkip: false,
                articleSlug: options?.runArticleSlug,
                componentSelector: "adapt-run-workshop-step",
                overviewComponentSelector: options?.runOverviewComponentSelector ?? showWorkflowMeetingComponentSelector,
                customData: {
                    notStartedContent: `<h5>The ${name} has been scheduled</h5>
                        <p>The meeting is not yet started. You can still click on the meeting link below to edit meeting
                            details or resend invitation emails to meeting participants.</p>`,
                    inProgressContent: `<h5>The ${name} is currently live</h5>
                        <p>Please proceed to the meeting page to participate in the meeting.
                            This step cannot be completed until meeting is ended.</p>`,
                    endedContent: `<h5>The ${name} has ended</h5>
                        <p>Well done! You have completed the meeting. This pathway can now be ended.</p>`,
                } as IRunWorkshopStepData,
            }],
        },
    ];
}

export function createActivityBriefWorkflow(
    activityName: string,
    workflowIdentifier: WorkflowIdentifier,
    article?: ImplementationKitArticle,
    meetingDescriptionArticle?: ImplementationKitArticle,
    meetingPreWorkArticle?: ImplementationKitArticle): LocalWorkflow {

    return {
        name: `Activity brief ${activityName}`,
        workflowId: `${workflowIdentifier}ActivityBrief`,
        type: WorkflowType.Workflow,
        dialogWidth: WorkflowDialogWidth.Large,
        continueOnFinish: true,
        steps: [createActivityBriefWorkflowStep(activityName, article, meetingDescriptionArticle, meetingPreWorkArticle)],
    };
}

export function createActivityBriefWorkflowStep(
    activityName: string,
    article?: ImplementationKitArticle,
    meetingDescriptionArticle?: ImplementationKitArticle,
    meetingPreWorkArticle?: ImplementationKitArticle): Partial<WorkflowStep> {

    const activityData = {
        meetingDescriptionArticle,
        meetingPreWorkArticle,
    } as IActivityBriefData;

    return {
        name: `${activityName}: Activity brief`,
        ordinal: 1,
        allowBack: true,
        canSkip: false,
        articleSlug: article,
        customData: activityData,
        componentSelector: DisplayWorkflowActivityBriefComponentSelector,
        isActivityStep: true,
        workflowStepNextIcon: "fal fa-fw fa-chevron-right",
        workflowStepNextText: "Next",
    };
}

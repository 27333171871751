export enum ObjectiveStatus {
    OnTrack = "OnTrack",
    Slow = "Slow",
    Stuck = "Stuck",
    Closed = "Closed",
}

export class ObjectiveStatusMetadata {
    public static readonly OnTrack = new ObjectiveStatusMetadata(ObjectiveStatus.OnTrack, "On Track", "objective-status-on-track");
    public static readonly Slow = new ObjectiveStatusMetadata(ObjectiveStatus.Slow, "Slow", "objective-status-slow");
    public static readonly Stuck = new ObjectiveStatusMetadata(ObjectiveStatus.Stuck, "Stuck", "objective-status-stuck");
    public static readonly Closed = new ObjectiveStatusMetadata(ObjectiveStatus.Closed, "Closed", "objective-status-closed");

    public static readonly All = [
        ObjectiveStatusMetadata.OnTrack,
        ObjectiveStatusMetadata.Slow,
        ObjectiveStatusMetadata.Stuck,
        ObjectiveStatusMetadata.Closed,
    ];

    public static readonly ByStatus: { [status in ObjectiveStatus]: ObjectiveStatusMetadata } = {
        [ObjectiveStatus.OnTrack]: ObjectiveStatusMetadata.OnTrack,
        [ObjectiveStatus.Slow]: ObjectiveStatusMetadata.Slow,
        [ObjectiveStatus.Stuck]: ObjectiveStatusMetadata.Stuck,
        [ObjectiveStatus.Closed]: ObjectiveStatusMetadata.Closed,
    };

    public static readonly ByIntValue: { [value: number]: ObjectiveStatusMetadata } = {
        0: ObjectiveStatusMetadata.OnTrack,
        1: ObjectiveStatusMetadata.Slow,
        2: ObjectiveStatusMetadata.Stuck,
        3: ObjectiveStatusMetadata.Closed,
    };

    private constructor(
        public readonly status: ObjectiveStatus,
        public readonly name: string,
        public readonly statusClass: string,
    ) { }
}

import { Injector } from "@angular/core";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { UserService } from "@common/user/user.service";
import { EMPTY } from "rxjs";
import { CommonTeamsService } from "../../teams/common-teams.service";
import { analyseTeamAssessmentSurveyPageRoute } from "../analyse-survey-page/analyse-survey-page.route";
import { SurveyDetails } from "../survey-details";
import { ISurveyTypeUtils } from "../survey-type-utils.interface";
import { manageTeamAssessmentsPageRoute } from "./manage-team-assessments-page/manage-team-assessments-page.route";
import { TeamAssessmentConstants } from "./team-assessment.constants";
import { TeamAssessmentQuestions } from "./team-assessment-questions";

export class TeamAssessmentUtils implements ISurveyTypeUtils {
    // this will get overwritten by the corresponding project module constructor using
    // - SurveyService.registerLearnMoreUrlForSurveyType()
    // If that's not called, the CTA simply won't show the learn more button.
    public learnMoreUrl = "";

    public get resultCategories() {
        return TeamAssessmentConstants.Categories;
    }

    public surveyDescription = "The objective of the Team Assessment survey is to collect data from everyone in your team about how your team functions. Your anonymous input will help you and your Team Leader better understand your team and determine where improvements can be made.";
    public singularParticipant = "team member";
    public pluralParticipant = "team members";
    public analyseTargetLabel = "Team Attribute";
    public responseGroups = [SurveyResponseGroup.All];
    public goodPercentageThreshold = TeamAssessmentConstants.GoodPercentageThreshold;
    public filterQuestionResponse = () => true;

    public getManageSurveyPageRoute$(teamId: number) {
        if (!teamId) {
            return EMPTY;
        }

        return manageTeamAssessmentsPageRoute.getRoute({ teamId });
    }

    public gotoManageSurveyPageRoute$(teamId: number) {
        return manageTeamAssessmentsPageRoute.gotoRoute({ teamId });
    }

    public getAnalyseSurveyPageRoute$(surveyId?: number, teamId?: number) {
        if (!teamId) { // this happens when team is not primed - where caller's combineLatest will get another emit when entity is available
            return EMPTY;
        } else {
            return analyseTeamAssessmentSurveyPageRoute.getRouteObject({ teamId }, { surveyId });
        }
    }

    public gotoAnalyseSurveyPageRoute$(surveyId?: number, teamId?: number) {
        if (!teamId) {
            return EMPTY;
        } else {
            return analyseTeamAssessmentSurveyPageRoute.gotoRoute({ teamId }, { surveyId });
        }
    }

    public getSurveyQuestions(surveyDetails: SurveyDetails) {
        return new TeamAssessmentQuestions(surveyDetails);
    }

    public convertScoreToPercentage(score: number) {
        return (score - 1) * 100 / (TeamAssessmentConstants.MaxScore - 1);
    }

    public getStatusColorFromPercentage(percentage: number) {
        if (percentage >= TeamAssessmentConstants.GoodPercentageThreshold) {
            return TeamAssessmentConstants.GoodColor;
        } else if (percentage >= TeamAssessmentConstants.WarningPercentageThreshold) {
            return TeamAssessmentConstants.WarningColor;
        } else {
            return TeamAssessmentConstants.BadColor;
        }
    }

    public getStatusBackgroundFromPercentage(percentage: number) {
        if (percentage >= TeamAssessmentConstants.GoodPercentageThreshold) {
            return TeamAssessmentConstants.GoodBackgroundColor;
        } else if (percentage >= TeamAssessmentConstants.WarningPercentageThreshold) {
            return TeamAssessmentConstants.WarningBackgroundColor;
        } else {
            return TeamAssessmentConstants.BadBackgroundColor;
        }
    }

    public getResultCategoryChartTooltipText(count: number, totalResponses: number, resultCategoryText: string) {
        return `${count} out of ${totalResponses} team members assessed the team as ${resultCategoryText}`;
    }

    public getScoreGrade(percentage: number) {
        for (const resultCategory of TeamAssessmentConstants.Categories) {
            if (percentage >= resultCategory.percentageThreshold) {
                return resultCategory.name;
            }
        }

        return "No Grade";
    }

    public async getPotentialSurveyInvitees(injector: Injector, team?: Team) {
        if (!team) {
            throw new Error("Team not defined");
        }
        const connection = await injector.get(CommonTeamsService).promiseToGetTeamMemberRoleConnections(team, true);
        const currentPersonId = injector.get(UserService).getCurrentPersonId();
        return connection.filter((rc) => rc.connection.personId !== currentPersonId).map((rc) => rc.connection);
    }
}

import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output, Renderer2 } from "@angular/core";
import { AdaptCommonDialogService } from "../adapt-common-dialog/adapt-common-dialog.service";

const viewClass = "fr-view";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[froalaView]",
})
export class EditorViewDirective implements AfterViewInit {
    @Output() public contentLoaded = new EventEmitter<HTMLElement>();

    private _element: HTMLElement;

    constructor(
        private renderer: Renderer2,
        private dialogService: AdaptCommonDialogService,
        element: ElementRef,
    ) {
        this._element = element.nativeElement;
    }

    // update content model as it comes
    @Input() public set froalaView(content: string | undefined) {
        this._element.innerHTML = content ?? "";

        // add click handlers to all images within this element
        const images: HTMLImageElement[] = Array.prototype.slice.call(
            this._element.querySelectorAll("img"),
        );
        for (const image of images) {
            image.style.cursor = "zoom-in";
            image.onclick = (e: Event) => {
                this.open((e.target as HTMLImageElement).src);
            };
        }

        this.contentLoaded.emit(this._element);
    }

    public ngAfterViewInit() {
        this.renderer.addClass(this._element, viewClass);
    }

    private open(imageSrc: string) {
        this.dialogService.openFullscreenDialog({ image: imageSrc });
    }
}

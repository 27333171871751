import { GuardFailureType, RouteEventsService } from "@common/route/route-events.service";
import { AdaptClientConfiguration } from "../configuration/adapt-client-configuration";
import { IdentityStorageService } from "./identity-storage.service";

export class IdentityInterceptorHelper {
    constructor(
        private identityStorageService: IdentityStorageService,
        private routeEventsService: RouteEventsService,
    ) {
    }

    public getRequestAccessToken(url: string) {
        const baseUri = AdaptClientConfiguration.ApiBaseUri;

        if (url.slice(0, baseUri.length) === baseUri) {
            return this.identityStorageService.accessToken;
        }
    }

    public handleError(errorStatus: number, url: string | null, hasHeadersFn?: ((token: string) => boolean)) {
        if (errorStatus === 401 && this.identityStorageService.isLoggedIn) {
            // only clear auth data, if it comes from our base api - things like an unauthorised from Asana should be ignored
            if (url && url.slice(0, AdaptClientConfiguration.ApiBaseUri.length) === AdaptClientConfiguration.ApiBaseUri
                && !(hasHeadersFn && hasHeadersFn("Embed-Relay-Response-For"))) {
                // only clearAuthenticationData if not relaying response from another service through our server
                this.identityStorageService.clearSessionData();

                // on authorisation failure we redirect to the login screen (which in our case is equivalent to /)
                // we add the reasonCode and current path to the search params to attempt to force a redirect after a subsequent login
                // if there is an existing redirect in place, then we use that
                // (this happens for instance when we send off multiple requests to the server and they all return unauthorised at the same time)
                // NOTE: this code isnt using common code, since it mostly in the application or common.route modules (which this does not have access to)
                this.routeEventsService.emitGuardFailureType(GuardFailureType.AuthenticatedGuardFailed);
            }
        }
    }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { EventSeriesType, IEventSeriesFields } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventSeriesDayOfWeek } from "@common/ADAPT.Common.Model/organisation/event-series-day-of-week";
import { EventSeriesWeekIndex } from "@common/ADAPT.Common.Model/organisation/event-series-week-index";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import { DateFormats } from "@common/ux/date-formats";
import { IMeetingLocation } from "@org-common/lib/calendar/calendar.interface";
import moment from "moment";
import { IScheduledRecurrence, IScheduleRecurrenceField } from "./schedule-recurrence.interface";

@Component({
    selector: "adapt-schedule-recurrence",
    templateUrl: "./schedule-recurrence.component.html",
    styleUrls: ["./schedule-recurrence.component.scss"],
})
export class ScheduleRecurrenceComponent implements OnInit, OnChanges {
    @Input() public recurrence!: IScheduledRecurrence;
    @Input() public nextTimes?: Date[];
    @Input() public location?: IMeetingLocation;
    @Input() public potentialLocations?: IMeetingLocation[];

    @Input() public disabled = false;

    @Output() public scheduledRecurrence = new EventEmitter<IScheduledRecurrence>();

    public readonly EventSeriesType = EventSeriesType;
    public readonly today = moment()
        .startOf("day")
        .toDate();

    public get upcomingTimes() {
        const nextTimes = this.nextTimes ?? this.recurrence.eventSeries.extensions.getNextTimes(this.recurrence.eventSeries.startDate, this.recurrence.eventSeries.endDate);
        if (nextTimes) {
            // don't need to show too many times here...
            const formattedTimes = nextTimes.map((d) => moment(d).format(DateFormats.moment.long));
            const trimmedTimes = formattedTimes.slice(0, 3);
            return [trimmedTimes.join(", ") + (trimmedTimes.length !== nextTimes.length ? "..." : ""), formattedTimes.join(", ")];
        }

        return undefined;
    }

    public get duration() {
        return moment.duration(this.recurrence.eventSeries.eventType?.durationInMinutes, "minutes").humanize();
    }

    public ngOnInit() {
        if (!this.recurrence || !this.recurrence.eventSeries) {
            throw new Error("config[.eventSeries] must be provided");
        }

        if (this.recurrence.eventSeries.location && !this.location) {
            this.location = { name: this.recurrence.eventSeries.location, emailAddress: this.recurrence.eventSeries.calendarIntegrationLocationId };
        }

        // this will call updateData();
        this.onLocationChange(this.location);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            this.updateData();
        }
    }

    public async updateData() {
        if (!this.recurrence.eventSeries.weekIndex) {
            this.recurrence.eventSeries.weekIndex = EventSeriesWeekIndex.First;
        }
        if (!this.recurrence.eventSeries.dayOfWeek) {
            this.recurrence.eventSeries.dayOfWeek = EventSeriesDayOfWeek.Monday;
        }
        if (!this.recurrence.eventSeries.month) {
            this.recurrence.eventSeries.month = this.recurrence.eventSeries.startDate.getMonth() + 1;
        }

        this.scheduledRecurrence.emit(this.recurrence);
    }

    public updateField<K extends keyof IEventSeriesFields>(field: IScheduleRecurrenceField, value: IEventSeriesFields[K]) {
        Object.assign(this.recurrence.eventSeries, { [field.eventSeriesField]: value });
        field.afterFieldUpdated?.(this.recurrence.eventSeries);

        this.updateData();
    }

    public onLocationChange(location?: IMeetingLocation) {
        this.location = location;

        if (this.recurrence.eventSeries.location !== this.location?.name) {
            this.recurrence.eventSeries.location = this.location?.name;
        }
        if (this.recurrence.eventSeries.calendarIntegrationLocationId !== this.location?.emailAddress) {
            this.recurrence.eventSeries.calendarIntegrationLocationId = this.location?.emailAddress ?? undefined;
        }

        this.updateData();
    }

    public setSeriesStartTime(startTime: Date | null) {
        const previousDate = this.recurrence.eventSeries.startDate;

        if (startTime !== null) {
            // get the currently set date, and replace the HH:MM:SS with the new input
            this.recurrence.eventSeries.startDate = DateUtilities.replaceTime(this.recurrence.eventSeries.startDate, startTime);
        }

        // only update if anything changed
        if (previousDate !== this.recurrence.eventSeries.startDate) {
            this.updateData();
        }
    }

    public setSeriesStartDate(startDate: Date | null) {
        const previousDate = this.recurrence.eventSeries.startDate;

        if (startDate !== null) {
            // get the currently set date, and replace the DD/MM/YYYY with the new input
            this.recurrence.eventSeries.startDate = DateUtilities.replaceDate(this.recurrence.eventSeries.startDate, startDate);
        }

        // only update if anything changed
        if (previousDate !== this.recurrence.eventSeries.startDate) {
            this.updateData();
        }
    }
}

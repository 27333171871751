<div *ngIf="!!meeting"
     class="card">
    <div class="card-header pt-2 d-flex align-items-center">
        <h3 class="mb-0"
            data-test="meeting-name-heading">{{meeting.name}}</h3>
        <button *ngIf="meeting && meeting.supplementaryData && meeting.supplementaryData.purpose"
                adaptButtonIcon="align-self-center fal fa-fw {{purposeExpanded ? 'fa-chevron-up': 'fa-chevron-down'}}"
                [adaptTooltip]="purposeExpanded ? 'Hide Description': 'Show Description'"
                class="ms-2 btn btn-blank"
                (click)="purposeExpanded = !purposeExpanded"
                data-test="meeting-description-display-toggle"></button>

        <div class="ms-auto d-flex me-2">
            <button *ngIf="canStart"
                    adaptButtonIcon="fal fa-fw fa-play-circle"
                    [adaptBlockingClick]="startMeeting"
                    class="btn btn-primary text-nowrap"
                    [unsubscribeOnDestroy]="false"
                    data-test="start-meeting-button">Start
            </button>
            <adapt-meeting-details-menu [meeting]="meeting"
                                        [update$]="triggerUpdate$"
                                        [(showAgendaEditor)]="showAgendaEditor"
                                        (meetingChange)="meetingChange.emit($event)"
                                        (meetingDeleted)="meetingDeleted.emit($event)"></adapt-meeting-details-menu>
        </div>

    </div>
    <div class="card-body">
        <dx-scroll-view #meetingDetailsScrollView
                        (onScroll)="meetingDetailsScrolled.emit($event)">

            <div *ngIf="nonStartableMeetingInfo"
                 class="alert alert-warning mb-3">{{nonStartableMeetingInfo}}
            </div>

            <div class="d-flex align-items-center">
                <div class="d-flex flex-column flex-grow-1">
                    <div title="When this meeting will take place">
                        <span><i class="fal fa-fw fa-clock me-1"></i>{{meeting.meetingDateTime | adaptDate}}</span>
                        <span class="mx-2">/</span>
                        <span>{{meeting.meetingDateTime | adaptTime}}</span>
                        <ng-container *ngIf="meeting.endTime">
                            <span class="mx-1">-</span>
                            <span>{{meeting.endTime | adaptTime}}</span>
                        </ng-container>
                    </div>
                    <div *ngIf="meeting.location"
                         title="Where this meeting will take place">
                        <span><i class="fal fa-fw fa-location-dot me-1"></i>{{meeting.location}}</span>
                    </div>
                    <adapt-link-external-meeting *ngIf="currentPersonIsAttendee"
                                                 [meeting]="meeting"></adapt-link-external-meeting>
                </div>
            </div>

            <adapt-display-meeting-participants class="align-self-baseline meeting-participants pb-3"
                                                [participants]="participants"></adapt-display-meeting-participants>

            <adapt-dashboard-element *ngIf="meeting && meeting.supplementaryData && meeting.supplementaryData.purpose"
                                     [collapse]="!purposeExpanded"
                                     [isAnimated]="true"
                                     [nested]="true"
                                     class="mb-0"
                                     name="Description"
                                     data-test="meeting-description-display">
                <div class="pb-4"
                     [froalaView]="meeting.supplementaryData.purpose"></div>
            </adapt-dashboard-element>

            <adapt-dashboard-element name="Agenda"
                                     [nested]="true">
                <div *ngIf="isInProgress && isCurrentPersonInMeeting"
                     class="alert alert-info">
                    The agenda for this meeting can only be modified from the live meeting sidebar or page.
                </div>
                <ng-container adapt-dashboard-title-buttons>
                    <button adaptButtonIcon="align-self-center fal fa-fw {{agendaExpanded ? 'fa-chevron-up': 'fa-chevron-down'}}"
                            *ngIf="canExpand"
                            class="btn btn-blank"
                            (click)="toggleExpand()">{{agendaExpanded ? 'Collapse' : 'Expand'}} All Agenda Items
                    </button>
                </ng-container>

                <!-- Only meeting that's not started is editable -->
                <adapt-edit-agenda *ngIf="showAgendaEditor && canEditMeeting; else readonlyMeeting"
                                   [meeting]="meeting"
                                   [expandItemsOnInit]="false"
                                   (expandChange)="agendaExpanded = $event"
                                   (canExpandChange)="canExpand = $event"></adapt-edit-agenda>
                <ng-template #readonlyMeeting>
                    <adapt-display-agenda-items [agendaItems]="agendaItems"
                                                [expandItemsOnInit]="true"
                                                (expandChange)="agendaExpanded = $event"
                                                (canExpandChange)="canExpand = $event"></adapt-display-agenda-items>
                </ng-template>
            </adapt-dashboard-element>
        </dx-scroll-view>
    </div>
</div>

import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { HtmlEditorService } from "@common/ux/html-editor/html-editor.service";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptCommentChainModule } from "@org-common/lib/comment-chain/comment-chain.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { DxChartModule, DxDateBoxModule, DxListModule, DxPopoverModule, DxPopupModule, DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule, DxSortableModule, DxTagBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptCommonMeetingsModule } from "../meetings/common-meetings.module";
import { AdaptObjectivesSharedModule } from "../objectives/objectives-shared.module";
import { AddCommentPlaceHolderComponent } from "./add-comment-place-holder/add-comment-place-holder.component";
import { BoardComponent } from "./board/board.component";
import { BoardsItemStatusesGraphComponent } from "./boards-item-statuses-graph.component/boards-item-statuses-graph.component";
import { ConfigureBoardDetailsDialogComponent } from "./configure-board-details-dialog/configure-board-details-dialog.component";
import { DeleteBoardDialogComponent } from "./delete-board-dialog/delete-board-dialog.component";
import { DeleteItemDialogComponent } from "./items/delete-item-dialog/delete-item-dialog.component";
import { DisplayItemStatusComponent } from "./items/display-item-status/display-item-status.component";
import { EditItemCommentsComponent } from "./items/edit-item-comments.component/edit-item-comments.component";
import { EditItemDialogComponent } from "./items/edit-item-dialog/edit-item-dialog.component";
import { ItemLinkingFroalaAction } from "./items/item-linking-froala-action";
import { ItemListingSearchComponent } from "./items/item-listing-search/item-listing-search.component";
import { ItemPageComponent } from "./items/item-page/item-page.component";
import { itemPageRoute } from "./items/item-page/item-page.route";
import { ItemPreviewComponent } from "./items/item-preview/item-preview.component";
import { MoveItemDialogComponent } from "./items/move-item-dialog/move-item-dialog.component";
import { SelectItemDialogComponent } from "./items/select-item-dialog/select-item-dialog.component";
import { SelectItemStatusComponent } from "./items/select-item-status/select-item-status.component";
import { KanbanAuthService } from "./kanban-auth.service";
import { KanbanCardComponent } from "./kanban-card/kanban-card.component";
import { KanbanFilterComponent } from "./kanban-filter/kanban-filter.component";
import { KanbanPageComponent } from "./kanban-page/kanban-page.component";
import { myKanbanPageRoute, personalKanbanPageRoute, teamKanbanPageRoute } from "./kanban-page/kanban-page.route";
import { AdaptKanbanSharedModule } from "./kanban-shared.module";
import { KanbanUiService } from "./kanban-ui.service";
import { KanbanViewComponent } from "./kanban-view/kanban-view.component";
import { LinkMeetingAgendaItemDialogComponent } from "./link-meeting-agenda-item-dialog/link-meeting-agenda-item-dialog.component";
import { MonthlyActionsDashboardElementComponent } from "./monthly-actions-dashboard-element/monthly-actions-dashboard-element.component";
import { PersonalWorkDashboardElementComponent } from "./personal-work-dashboard-element/personal-work-dashboard-element.component";
import { TeamWorkDashboardElementComponent } from "./team-work-dashboard-element/team-work-dashboard-element.component";

@NgModule({
    imports: [
        CommonModule,
        DxScrollViewModule,
        DxSelectBoxModule,
        DxSortableModule,
        DxPopoverModule,
        DxPopupModule,
        DxTextBoxModule,
        DxListModule,
        DxTagBoxModule,
        DxDateBoxModule,
        DxRadioGroupModule,
        DxChartModule,
        AdaptAuthorisationModule,
        AdaptKanbanSharedModule,
        AdaptDirectorySharedModule,
        AdaptLoadingSpinnerModule,
        AdaptTwoColumnModule,
        AdaptButtonModule,
        AdaptDateModule,
        AdaptHtmlEditorModule,
        AdaptCommentChainModule,
        AdaptSeeMoreModule,
        AdaptShellModule,
        AdaptTooltipModule,
        AdaptCommonDialogModule,
        AdaptCommonMeetingsModule,
        AdaptObjectivesSharedModule,
        AdaptMenuModule,
        AdaptEntityValidatorModule,
        AdaptFeaturesModule,
        AdaptTeamDashboardSharedModule,
        AdaptCallToActionModule,
        AdaptLabellingModule,
        AdaptDashboardElementModule,
        AdaptCollapsibleDashboardElementModule,
    ],
    declarations: [
        KanbanPageComponent,
        BoardComponent,
        KanbanCardComponent,
        ItemPreviewComponent,
        AddCommentPlaceHolderComponent,
        KanbanFilterComponent,
        KanbanViewComponent,
        MoveItemDialogComponent,
        DeleteBoardDialogComponent,
        LinkMeetingAgendaItemDialogComponent,
        DeleteItemDialogComponent,
        ItemPageComponent,
        SelectItemStatusComponent,
        EditItemDialogComponent,
        SelectItemDialogComponent,
        ItemListingSearchComponent,
        EditItemCommentsComponent,
        BoardsItemStatusesGraphComponent,
        ConfigureBoardDetailsDialogComponent,
        PersonalWorkDashboardElementComponent,
        TeamWorkDashboardElementComponent,
        DisplayItemStatusComponent,
        MonthlyActionsDashboardElementComponent,
    ],
    exports: [
        KanbanCardComponent,
        ItemPageComponent,
        EditItemDialogComponent,
        SelectItemDialogComponent,
        BoardsItemStatusesGraphComponent,
        ConfigureBoardDetailsDialogComponent,
        PersonalWorkDashboardElementComponent,
        TeamWorkDashboardElementComponent,
        DisplayItemStatusComponent,
        MonthlyActionsDashboardElementComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [KanbanUiService, HtmlEditorService],
            useFactory: (kanbanUiService: KanbanUiService, htmlEditorService: HtmlEditorService) =>
                () => {
                    if (AdaptClientConfiguration.AdaptProjectName !== AdaptProject.Alto) {
                        htmlEditorService.registerEditorActionCallback(() => ItemLinkingFroalaAction.registerAction(kanbanUiService));
                    }
                },
        },
    ],
})
export class AdaptKanbanModule {
    public static readonly Routes = [
        ...itemPageRoute.routes,
        ...personalKanbanPageRoute.routes,
        ...teamKanbanPageRoute.routes,
        ...myKanbanPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        KanbanAuthService.registerAccess(authorisationService);
    }
}

<div *ngIf="survey$ | async as survey"
     class="card survey-result-card">
    <div class="survey-result-container hidden-panel-container">
        <div class="survey-result-details"
             *ngIf="!hideDate || !hideName">
            <div class="d-inline-flex align-self-baseline"
                 *ngIf="!hideName">
                <a [routerLink]="analyseSurveyPageObject?.path"
                   [queryParams]="analyseSurveyPageObject?.queryParams">
                    <h3 class="mt-1 survey-title">{{survey.name}}</h3>
                </a>
                <div class="hidden-panel">
                    <button *ngIf="canRestoreSurvey"
                            adaptButtonIcon="fal fa-reply-all"
                            class="bg-transparent override-button-color p-0 ms-2"
                            (click)="restoreSurvey(survey)"
                            adaptTooltip="Re-open ended survey"></button>
                    <button *ngIf="allowRestore"
                            adaptButtonIcon="browse"
                            class="bg-transparent override-button-color p-0 ms-2"
                            adaptTooltip="Show participants"
                            [adaptBlockingClick]="showRespondents"
                            [adaptBlockingClickParam]="survey"></button>
                </div>
            </div>
            <span *ngIf="!hideDate"
                  class="align-self-baseline text-nowrap ms-2">Closed: {{survey.endTime | adaptDate}}</span>
        </div>

        <div class="flex-grow-1">
            <adapt-progress-bar [percentageProgress]="surveyResult?.averagePercentageScore"
                                [colour]="scoreColor"
                                [backgroundColour]="scoreBackground"
                                [leftAligned]="true"
                                class="d-flex mt-1 mb-1"
                                [overrideText]="scoreText"></adapt-progress-bar>

            <adapt-progress-bar [percentageProgress]="surveyResult?.percentageParticipation"
                                [colour]="participationColor"
                                [backgroundColour]="participationBackground"
                                [leftAligned]="true"
                                [overrideText]="participationText"></adapt-progress-bar>
        </div>
    </div>
</div>

<ng-container *adaptLoading="!anchor">
    <adapt-collapsible-dashboard-element [name]="anchor!.name"
                                         [iconClass]="AnchorMetadata.iconClass"
                                         [iconTooltip]="AnchorMetadata.singularLabel"
                                         [isCollapsible]="isCollapsible"
                                         [collapsed]="collapsed">
        <ng-container *adaptIfAuthorised="EditStrategicAnchors"
                      adapt-collapsible-header-action-right>
            <button *ngIf="isEditing"
                    adaptButton="editBorderless"
                    [adaptBlockingClick]="editAnchor"
                    [stopPropagation]="true"
                    data-tour="edit-anchor-button"></button>
        </ng-container>
        <div [froalaView]="anchor!.description"></div>
        <div class="mt-4 d-flex">
            <span *ngIf="anchor?.updateTime"
                  class="update-time ms-auto">Last updated: {{anchor!.updateTime | timeAgo}}</span>
        </div>
    </adapt-collapsible-dashboard-element>
</ng-container>

import { Component, OnInit } from "@angular/core";
import { ValuesConstitution } from "@common/ADAPT.Common.Model/organisation/values-constitution";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { ValuesConstitutionService } from "@org-common/lib/values-constitution/values-constitution.service";
import { ValuesConstitutionAuthService } from "@org-common/lib/values-constitution/values-constitution-auth.service";
import { IWorkflowRunData, WorkflowRunDialogComponent } from "@org-common/lib/workflow/workflow-run-dialog/workflow-run-dialog.component";
import { lastValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { createValueWorkflow, ICreateValueData } from "../../../create-value/create-value-workflow";

@MeetingAgendaItemComponent("adapt-set-values-workshop-record-values")
@Component({
    selector: "adapt-set-values-workshop-record-values",
    templateUrl: "./set-values-workshop-record-values.component.html",
})
export class SetValuesWorkshopRecordValuesComponent extends BaseComponent implements OnInit {
    public valuesConstitution?: ValuesConstitution;
    public canEdit = false;

    public constructor(
        private dialogService: AdaptCommonDialogService,
        private valuesConstitutionService: ValuesConstitutionService,
        private authorisationService: AuthorisationService,
    ) {
        super();
    }

    public async ngOnInit() {
        await this.updateData();

        this.authorisationService.getHasAccess(ValuesConstitutionAuthService.EditValuesConstitution).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((canEdit) => this.canEdit = canEdit);
    }

    private async updateData() {
        this.valuesConstitution = await lastValueFrom(this.valuesConstitutionService.getValuesConstitution());
    }

    @Autobind
    public recordValue() {
        return this.dialogService.open(WorkflowRunDialogComponent, {
            workflow: createValueWorkflow,
            runData: { valueConstitution: this.valuesConstitution } as ICreateValueData,
        } as IWorkflowRunData).pipe(
            tap(() => this.updateData()),
        );
    }
}

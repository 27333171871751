import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Survey } from "@common/ADAPT.Common.Model/organisation/survey";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { of, Subscription, switchMap, tap } from "rxjs";
import { ISurveyQuestionScore, SurveyService } from "../survey.service";
import { ISurveyQuestionCategory, ISurveyQuestions } from "../survey-questions.interface";
import { SurveyUtils } from "../survey-utils";

@Component({
    selector: "adapt-category-summary",
    templateUrl: "./category-summary.component.html",
    styleUrls: ["./category-summary.component.scss"],
})
export class CategorySummaryComponent extends BaseComponent implements OnChanges {
    @Input() public survey?: Survey;
    @Input() public surveyQuestions?: ISurveyQuestions;
    @Input() public categoryId?: number;
    @Output() public categoryIdChange = new EventEmitter<number | undefined>();

    public titleText = "";
    public topScoreQuestions: ISurveyQuestionScore[] = [];
    public worstScoreQuestions: ISurveyQuestionScore[] = [];
    public gradeSummaryText = "";
    public percentageScore?: number;
    private querySubscription?: Subscription;

    public constructor(
        elementRef: ElementRef,
        private surveyService: SurveyService,
    ) {
        super(elementRef);
    }

    public ngOnChanges() {
        if (this.survey && this.surveyQuestions) {
            this.gradeSummaryText = "";
            let category: ISurveyQuestionCategory | undefined;
            if (this.categoryId) {
                category = this.surveyQuestions.getCategory(this.categoryId);
                this.titleText = `Highlights from '${category?.categoryName}'`;
            } else {
                this.titleText = "Highlights from your organisation";
            }

            if (!category && this.categoryId !== undefined) {
                // invalid category id from query param -> clear it
                // - do it next digest cycle as this is usually within the trigger from parent which will cause
                //   ExpressionChangedAfterItHasBeenChecked otherwise.
                setTimeout(() => this.categoryIdChange.emit(undefined));
            }

            this.querySubscription?.unsubscribe();
            const topCount = 2;
            this.querySubscription = this.surveyService.getTopScoreQuestions(this.survey, topCount, this.surveyQuestions, this.categoryId).pipe(
                tap((topQuestions) => this.topScoreQuestions = topQuestions),
                switchMap(() => this.surveyService.getTopScoreQuestions(this.survey!, topCount, this.surveyQuestions!, this.categoryId, false)),
                tap((worstQuestions) => this.worstScoreQuestions = worstQuestions),
                switchMap(() => this.categoryId && category?.gradeSummary
                    ? this.surveyService.getCategoryPercentageScoreForSurvey(this.categoryId, this.survey!, this.surveyQuestions!)
                    : of(undefined)),
                this.takeUntilDestroyed(),
            ).subscribe((percentageScore) => {
                this.percentageScore = percentageScore;
                if (percentageScore !== undefined && this.survey && category?.gradeSummary) {
                    const scoreGrade = SurveyUtils.forSurveyType(this.survey.surveyType).getScoreGrade(percentageScore);
                    this.gradeSummaryText = category.gradeSummary[scoreGrade];
                }
            });
        }
    }

    public getColumnClass(columnIndex: number) {
        if (this.gradeSummaryText) {
            switch (columnIndex) {
                case 0:
                    return "col-lg-4 pe-2";
                case 1:
                    return "col-lg-4 px-1";
                case 2:
                    return "col-lg-4 ps-1";
                default:
                    break;
            }

            return "col-lg-4 ps-2";
        } else {
            return columnIndex > 1 ? "col-lg-6 ps-1" : "col-lg-6 pe-1";
        }
    }

    public clearCategoryId() {
        // don't have to trigger ngOnChanges here as id changes emitted to the container will result in the categoryId
        // changed from there and passed back into this component which will trigger the ngOnChanges()
        this.categoryIdChange.emit(undefined);
    }
}

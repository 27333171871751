import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Params } from "@angular/router";
import { Invoice, InvoiceBreezeModel } from "@common/ADAPT.Common.Model/account/invoice";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ServiceUri } from "@common/configuration/service-uri";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { DomUtilities } from "@common/lib/utilities/dom-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseService } from "@common/service/base.service";
import saveAs from "file-saver";
import { lastValueFrom, Observable, throwError } from "rxjs";
import { catchError, first, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { ICardPartialDetails } from "./card-partial-details.interface";
import { ISimpleInvoice } from "./simple-invoice.interface";

export interface ICardOrganisationIdentifier {
    organisationId?: number;
    eulaToken?: string;
}

@Injectable({
    providedIn: "root",
})
export class PaymentProcessingService extends BaseService {
    public static readonly Id = "adapt.payment-processing.service";

    /** A hot observable that emits a lazy loaded Stripe object */
    private _stripe$: Observable<stripe.Stripe>;

    constructor(
        private httpClient: HttpClient,
        injector: Injector,
    ) {
        super(injector);
        this._stripe$ = DomUtilities.loadJsScript("https://js.stripe.com/v3/").pipe(
            map(() => Stripe(AdaptClientConfiguration.StripePublicKey)),
            shareReplay(1),
        );
    }

    /** A cold observable that emits a Stripe object once then completes */
    public get stripe$() {
        return this._stripe$.pipe(
            first(),
        );
    }

    public setCardDetails(identifier: ICardOrganisationIdentifier, stripeCard: stripe.elements.Element, cardName?: string) {
        const self = this;

        this.validateCardIdentifier(identifier);

        const tokenData = {
            name: cardName,
        };

        return this.stripe$.pipe(
            switchMap((stripe) => stripe.createToken(stripeCard, tokenData)),
            switchMap(setToken),
            catchError(this.handleError),
        );

        function setToken(response: stripe.TokenResponse) {
            if (response.error) {
                return throwError(() => response.error!.message);
            }

            const data = {
                ...identifier,
                stripeToken: response.token!.id,
            };

            return self.httpClient.post(ServiceUri.PaymentServiceBaseUri + "/SetCreditCardDetails", data);
        }
    }

    public getCreditCardPartialDetails(identifier: ICardOrganisationIdentifier) {
        this.validateCardIdentifier(identifier);

        let params = new HttpParams();
        for (const param of Object.entries(identifier)) {
            if (param[1]) {
                params = params.set(param[0], String(param[1]));
            }
        }

        return this.httpClient.get<ICardPartialDetails | null>(ServiceUri.PaymentServiceBaseUri + "/GetCreditCardPartialDetails", { params })
            .pipe(
                map(handleResponse),
                catchError(this.handleError),
            );

        function handleResponse(response: ICardPartialDetails | null) {
            if (!response) {
                return undefined;
            }

            return response;
        }
    }

    public async getInvoices(organisationId?: number): Promise<Invoice[]> {
        if (organisationId != null) {
            const predicate = new MethodologyPredicate<Invoice>("account.organisationId", "==", organisationId);
            return await lastValueFrom(this.commonDataService.getByPredicate(InvoiceBreezeModel, predicate));
        }
        return await lastValueFrom(this.commonDataService.getAll(InvoiceBreezeModel));
    }

    public exportInvoicePdf(invoiceId: number, organisationId: number, filename: string) {
        const params: Params = {
            invoiceId: invoiceId.toString(),
            organisationId: organisationId.toString(),
        };

        const uri = `${ServiceUri.PaymentServiceBaseUri}/InvoicePdfExport`;

        return this.httpClient.post(
            uri,
            null,
            {
                params,
                responseType: "blob", // makes response.body a Blob object
                observe: "response", // without this, response.body will be unknown
            },
        ).pipe(
            tap((response) => saveAs(response.body!, filename)),
        );
    }

    public getNextInvoice(organisationId: number) {
        let params = new HttpParams();
        params = params.set("organisationId", organisationId.toString());

        return this.httpClient.get<ISimpleInvoice | null>(ServiceUri.PaymentServiceBaseUri + "/GetNextInvoice", { params })
            .pipe(
                map(handleResponse),
                catchError(this.handleError),
            );

        function handleResponse(response: ISimpleInvoice | null): ISimpleInvoice | undefined {
            if (!response) {
                return undefined;
            }

            return {
                total: response.total,
                date: new Date(response.date),
                periodStartDate: new Date(response.periodStartDate),
                periodEndDate: new Date(response.periodEndDate),
            };
        }
    }

    public chargeOrganisationReview(organisationId: number) {
        const uri = `${ServiceUri.PaymentServiceBaseUri}/BillOrganisationReview`;
        return this.httpClient.post(uri, null, {
            params: { organisationId: String(organisationId) },
        });
    }

    public commenceSubscription(organisationId: number, chargeImmediately = false) {
        const uri = `${ServiceUri.PaymentServiceBaseUri}/CommenceSubscription`;
        return this.httpClient.post(uri, null, {
            params: {
                organisationId: String(organisationId),
                chargeImmediately,
            },
        });
    }

    private handleError(e: HttpErrorResponse) {
        return throwError(() => ErrorHandlingUtilities.getHttpResponseMessage(e));
    }

    private validateCardIdentifier(identifier: ICardOrganisationIdentifier) {
        const isAlto = AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto;
        if (!identifier.organisationId && (!identifier.eulaToken && !isAlto)) {
            throw new Error("organisationId or eulaToken must be defined");
        }
    }
}

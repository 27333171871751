import { Component, HostBinding, Input, OnChanges } from "@angular/core";
import { IFocusable } from "../adapt-common-dialog/focusable";
import { BaseButtonComponent } from "./base-button.component";
import { buttonPreset, IButtonType } from "./button-preset";

@Component({
    // eslint-disable-next-line  @angular-eslint/component-selector
    selector: "button[adaptButton]",
    template: `
        <adapt-icon *ngIf="buttonConfig?.iconClass as iconClass"
                    [icon]="iconClass"></adapt-icon>
        <!-- no whitespace so :empty works -->
        <span class="button-content">{{buttonConfig?.buttonText ? buttonConfig?.buttonText + " " : ""}}<ng-content></ng-content></span>
    `,
    styleUrls: ["./common-button.scss"],
})
export class ButtonComponent extends BaseButtonComponent implements OnChanges, IFocusable {
    @Input("adaptButton") public buttonType?: keyof typeof buttonPreset | IButtonType;
    @HostBinding("class.adapt-button") public readonly isAdaptButton = true;

    public buttonConfig?: IButtonType;

    @HostBinding("class") public get buttonClasses() {
        return this.buttonConfig?.buttonClass;
    }

    public ngOnChanges() {
        this.buttonConfig = typeof this.buttonType === "object"
            ? this.buttonType
            : this.buttonType && buttonPreset[this.buttonType];
    }

    public getElementToFocus() {
        return this.elementRef;
    }
}

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { IdentityStorageService } from "@common/identity/identity-storage.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { StorageService } from "@common/storage/storage.service";
import FroalaEditor, { FroalaOptions, ToolbarButtons } from "froala-editor";
import { IFocusable } from "../../adapt-common-dialog/focusable";
import { HtmlEditorService } from "../html-editor.service";
import { ParagraphFormatFroalaAction } from "./paragraph-format-froala-action";

@Component({
    selector: "adapt-html-editor-froala",
    template: `
        <div class="adaptEditor">
            <div [style.min-height.px]="minHeight"
                 [froalaEditor]="froalaOptions"
                 [froalaModel]="froalaContents"></div>
        </div>`,
    styleUrls: ["./html-editor-froala.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AdaptHtmlEditorFroalaComponent implements OnInit, OnChanges, IFocusable {
    private static hasBeenInitialised = false;

    @Input() public contents?: string | null;
    @Output() public contentsChange = new EventEmitter<string>();
    @Output() public isValidChange = new EventEmitter<boolean>();

    @Input() public minHeight?: number;
    @Input() public forceSize?: "standard" | "compact";
    @Input() public placeholderText = "Type something";
    @Input() public required?: boolean;
    @Input() public onlyShowToolbarWhenFocussed?: boolean;
    @Input() public readonly = false;

    public editorQueuedCaller = new QueuedCaller<FroalaEditor>();
    public froalaOptions?: Partial<FroalaOptions>;
    public froalaContents?: string;

    private readonly uploadUrl: string;
    private _token?: string;
    private currentContents?: string;
    private hasFocus = false;
    private maskInternalContentsUpdate = false;
    private froalaContentHasBeenInitialised = false;

    public constructor(
        storageService: StorageService,
        private identityStorage: IdentityStorageService,
        htmlEditorService: HtmlEditorService,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        if (!AdaptHtmlEditorFroalaComponent.hasBeenInitialised) {
            htmlEditorService.registerEditorActionCallback(() => ParagraphFormatFroalaAction.registerAction());
            htmlEditorService.initialiseRegisteredActionCallbacks();
            AdaptHtmlEditorFroalaComponent.hasBeenInitialised = true;
        }

        this.uploadUrl = storageService.storeUri();
    }

    public ngOnInit() {
        this._token = this.identityStorage.accessToken;
        this.froalaOptions = this.generateFroalaOptions();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.contents) {
            this.updateFroalaContent(changes.contents.currentValue);
        }

        if (changes.readonly) {
            this.setIsDisabled(this.readonly);
        }

        // wait until options has been set already, else we get error that token is not set.
        if (this.froalaOptions && (changes.placeholder || changes.forceSize)) {
            this.froalaOptions = this.generateFroalaOptions();
        }
    }

    public getElementToFocus() {
        // wait for editor to be ready
        setTimeout(() => {
            const element = this.elementRef.nativeElement.querySelector<HTMLElement>(".fr-element");

            // focus element
            element?.focus();
            this.moveCursorToEnd();
        });

        // don't return anything - we have handled the focus
        return undefined;
    }

    private get token() {
        return this._token;
    }

    public updateFroalaContent(newContent?: string) {
        if (newContent === this.currentContents) {
            return;
        }

        // Only update the internal contents for a change not caused by the user
        // We'll manage user initiated changes in this component
        this.currentContents = newContent;
        if (this.currentContents !== this.froalaContents && !this.maskInternalContentsUpdate) {
            this.froalaContents = this.currentContents;

            if (!this.froalaContentHasBeenInitialised) {
                this.froalaContentHasBeenInitialised = true;
            }

            // The froala event sometimes doesn't fire, so just do it explicitly.
            this.handleHtmlUpdate(this.currentContents);
        }
    }

    public setIsDisabled(isDisabled: boolean) {
        this.editorQueuedCaller.call((editor) => {
            if (isDisabled) {
                editor.edit.off();

                if (this.onlyShowToolbarWhenFocussed) {
                    editor.toolbar.hide();
                }
            } else {
                editor.edit.on();
            }
        });
    }

    public moveCursorToEnd() {
        this.editorQueuedCaller.call((editor) => {
            // this is from Froala support: https://stackoverflow.com/questions/34245599/set-the-caret-at-the-end-of-the-content-in-froala-2
            editor.selection.setAtEnd(editor.$el.get(0));
            editor.selection.restore();
        });
    }

    public generateFroalaOptions() {
        const self = this;

        const defaultToolbars = generateDefaultToolbars();
        const defaultButtons = generateDefaultButtons();
        const froalaOptions: Partial<FroalaOptions> = {
            attribution: false,
            key: AdaptClientConfiguration.FroalaKey,
            placeholderText: this.placeholderText,
            listAdvancedTypes: false,
            toolbarSticky: false,            // it would be great to enable this and get it to work - maybe our css overrides are killing it?
            videoResponsive: true,
            videoUpload: false,
            imageUploadURL: this.uploadUrl,
            imageDefaultWidth: 0, // this will remove the 300px default from Froala - Laura asked for it for Suzie
            toolbarButtons: defaultToolbars.standard,
            toolbarButtonsMD: defaultToolbars.standard,
            toolbarButtonsSM: defaultToolbars.compact,
            toolbarButtonsXS: defaultToolbars.compact,
            paragraphFormat: generateDefaultParagraphFormat(),
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            requestHeaders: {
                Accept: "application/json, text/plain, */*",
                Authorization: "Bearer " + this.token,          // used by froala's image upload plugin
            },
            dragInline: false,
            requestWithCORS: true,
            requestWithCredentials: true,
            imageInsertButtons: defaultButtons.imageInsertButtons,
            imageEditButtons: defaultButtons.imageEditButtons,
            linkEditButtons: defaultButtons.linkEditButtons,
            pasteAllowedStyleProps: ["background-color", "text-align", "vertical-align", "width"],
            pasteDeniedAttrs: ["class", "id"],
            events: {
                initialized() {
                    // froala provides the editor as this.
                    self.onInitialized(this);
                },
                blur() {
                    self.onBlur(this);
                },
                focus() {
                    self.onFocus(this);
                },
                contentChanged() {
                    self.handleHtmlUpdate(this.html.get(), true);
                },
            },
            iconsTemplate: "font_awesome_5",
            colorsHEXInput: false,
        };

        if (this.forceSize) {
            const toolbars = generateDefaultToolbars();
            const toolbar = toolbars[this.forceSize];

            if (toolbar) {
                froalaOptions.toolbarButtons = toolbar;
                froalaOptions.toolbarButtonsMD = toolbar;
                froalaOptions.toolbarButtonsSM = toolbar;
                froalaOptions.toolbarButtonsXS = toolbar;
            }
        }

        return froalaOptions;

        function generateDefaultToolbars() {
            // taken from https://www.froala.com/wysiwyg-editor/docs/options#toolbarButtons
            // from html-editor.component.ts
            return {
                standard: {
                    moreParagraph: {
                        buttons: ["paragraphFormat", "fontFamily", "fontSize", "paragraphStyle", "lineHeight", "quote"],
                        buttonsVisible: 1,
                    },
                    moreMisc: {
                        buttons:
                            [
                                "bold", "italic", "underline",
                                "align", "formatOL", "formatUL", "outdent",
                                "indent", "textColor", "backgroundColor",
                                "insertTable", "insertDocLink", "insertImage", "insertVideo", "insertEmbedLink",
                                // the 15 buttons above are visible by default - the ones below are not
                                "fullscreen", "help", "horizontalLine", "html",
                            ],
                        buttonsVisible: 15,
                        align: "left",
                    },
                } as Partial<ToolbarButtons>,
                compact: {
                    moreMisc: {
                        buttons: [
                            "bold", "italic", "underline", "formatOL", "formatUL", "insertTable", "insertDocLink", "insertImage", "insertVideo", "insertEmbedLink",
                            // the 10 buttons above are visible by default - the ones below are not
                            "paragraphFormat", "fontFamily", "fontSize", "paragraphStyle", "lineHeight", "quote",
                            "align", "outdent", "indent",
                            "textColor", "backgroundColor",
                            "fullscreen", "help", "horizontalLine", "html",
                        ],
                        buttonsVisible: 10,
                        align: "left",
                    },
                } as Partial<ToolbarButtons>,
            };
        }

        function generateDefaultButtons() {
            return {
                imageInsertButtons: ["imageUpload"],
                imageEditButtons: ["imageReplace", "imageAlign", "imageRemove", "-", "imageDisplay", "imageStyle", "imageSize"],
                linkEditButtons: ["linkOpen", "editDocLink", "linkRemove"],
            };
        }

        function generateDefaultParagraphFormat() {
            return {
                H1: "Heading 1",
                H2: "Heading 2",
                H3: "Heading 3",
                H4: "Heading 4",
                N: "Paragraph",
                PRE: "Code",
            };
        }
    }

    @Autobind
    private onInitialized(editor: FroalaEditor) {
        this.editorQueuedCaller.setCallee(editor);

        // in the case where html-editor is only loaded after ng-if, contents will be blank
        // need to call this to set the contents (which is already defined in $onInit)
        editor.html.set(this.froalaContents ?? "");
        // Setting the content programmatically doesn't fire the froalaEditor.html.get event
        this.handleHtmlUpdate(this.froalaContents);

        if (this.onlyShowToolbarWhenFocussed) {
            editor.toolbar.hide();
        }
    }

    @Autobind
    private onBlur(editor: FroalaEditor) {
        // save selection to be restored when we gain focus again
        editor.selection.save();
        this.hasFocus = false;
        this.maskInternalContentsUpdate = false;
        this.froalaContents = this.currentContents;

        if (this.onlyShowToolbarWhenFocussed) {
            editor.toolbar.hide();
        }
    }

    @Autobind
    private onFocus(editor: FroalaEditor) {
        this.hasFocus = true;
        editor.selection.restore();

        if (this.onlyShowToolbarWhenFocussed && !this.readonly) {
            editor.toolbar.show();
        }
    }

    @Autobind
    private handleHtmlUpdate(html?: string, fromFroalaEvent = false) {
        const processedContent = this.sanitizeAndProcessHtml(html);
        const hasValidContent = this.validateHtml(processedContent);

        // Update the model content everytime the user makes changes to handle the edge case
        // where a user makes changes that require processing (e.g. trailing new lines)
        // and clicks the save button while they have focus in the editor. This way when clicking
        // save they will save the latest content (but not necessarily see this in the editor)
        if (this.froalaContentHasBeenInitialised) {
            this.currentContents = processedContent;
            if (fromFroalaEvent) {
                // maskInternalContentsUpdate is used to block content being updated after you have manually updated the content
                // in the editor and still has focus.
                // It is set to false onBlur and should only be set to hasFocus this is a callback from froala editor event.
                // Note that froala editor event won't be raised if the content is changed from model but not through the editor.
                //
                // This will also limit the component @Output event to only emit if it is changed from the editor.
                // i.e. if the content is changed from component @Input, there is no output required.
                // (this will stop the [(contents)] from being toggled repeatedly if contents is changed quickly
                //  value1, value2, emit changed contents to value1 again which get passed into component, emit for value2..,
                //  emit for value1 again from the previous emit with value1, emit for value 2...)
                this.maskInternalContentsUpdate = this.hasFocus;

                if (html !== this.contents) {
                    this.contents = html;
                    this.contentsChange.emit(this.contents);
                }

                this.isValidChange.emit(hasValidContent);
            }
        }
    }

    private validateHtml(html?: string) {
        const hasValidContent = !!html;

        if (this.elementRef.nativeElement && this.required) {
            const children = Array.from(this.elementRef.nativeElement.children);
            if (hasValidContent) {
                children.forEach((child) => child.classList.remove("adaptEditor-required"));
            } else {
                children.forEach((child) => child.classList.add("adaptEditor-required"));
            }
        }

        return hasValidContent;
    }

    private sanitizeAndProcessHtml(html?: string) {
        if (!html) {
            return "";
        }

        return StringUtilities.trimHtml(html);
    }
}

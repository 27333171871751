import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { provideSidebarTab } from "@common/shell/common-sidebar/sidebar-tab";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptRenderComponentModule } from "@common/ux/render-component/render-component.module";
import { AdaptRerenderModule } from "@common/ux/rerender/rerender.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxAccordionModule, DxAutocompleteModule, DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxTooltipModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { AdaptKanbanSharedModule } from "../kanban/kanban-shared.module";
import { AdaptLabellingModule } from "../labelling/labeling.module";
import { AdaptObjectivesSharedModule } from "../objectives/objectives-shared.module";
import { AdaptTeamDashboardSharedModule } from "../teams/team-dashboard-shared/team-dashboard-shared.module";
import { ApplicationBarSearchItemComponent } from "./application-bar-search-item/application-bar-search-item.component";
import { CollapsibleSearchRowComponent } from "./collapsible-search-row/collapsible-search-row.component";
import { HighlightedTextComponent } from "./highlighted-text/highlighted-text.component";
import { searchPageRoute } from "./search-page.route";
import { SearchPageComponent } from "./search-page/search-page.component";
import { provideSearchProvider } from "./search-provider";
import { HelpJuiceSearchProvider } from "./search-providers/search-provider-helpjuice";
import { PageSearchProvider } from "./search-providers/search-provider-page";
import { PeopleSearchProvider } from "./search-providers/search-provider-people";
import { TeamSearchProvider } from "./search-providers/search-provider-team";
import { SearchRowImplementationKitComponent } from "./search-row-implementation-kit/search-row-implementation-kit.component";
import { SearchRowKanbanComponent } from "./search-row-kanban/search-row-kanban.component";
import { SearchRowMeetingComponent } from "./search-row-meeting/search-row-meeting.component";
import { SearchRowObjectiveComponent } from "./search-row-objective/search-row-objective.component";
import { SearchRowPageComponent } from "./search-row-page/search-row-page.component";
import { SearchRowPersonComponent } from "./search-row-person/search-row-person.component";
import { SearchRowPurposeVisionComponent } from "./search-row-purpose-vision/search-row-purpose-vision.component";
import { SearchRowTeamComponent } from "./search-row-team/search-row-team.component";
import { SearchRowValueComponent } from "./search-row-value/search-row-value.component";
import { SearchTab } from "./search-tab-content/search-tab";
import { SearchTabContentComponent } from "./search-tab-content/search-tab-content.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
        AdaptLoadingSpinnerModule,
        DxAccordionModule,
        AdaptShellModule,
        AdaptCommonRouteModule,
        AdaptButtonModule,
        DxAutocompleteModule,
        DxCheckBoxModule,
        DxTooltipModule,
        DxTextBoxModule,
        FormsModule,
        DxSelectBoxModule,
        AdaptRerenderModule,
        AdaptDirectorySharedModule,
        AdaptLabellingModule,
        AdaptTeamDashboardSharedModule,
        AdaptRenderComponentModule,
        AdaptFeatureModule,
        AdaptKanbanSharedModule,
        AdaptObjectivesSharedModule,
        CollapseModule,
    ],
    declarations: [
        SearchPageComponent,
        HighlightedTextComponent,
        CollapsibleSearchRowComponent,
        SearchRowImplementationKitComponent,
        SearchTabContentComponent,
        ApplicationBarSearchItemComponent,
        SearchRowPageComponent,
        SearchRowPurposeVisionComponent,
        SearchRowValueComponent,
        SearchRowPersonComponent,
        SearchRowTeamComponent,
        SearchRowKanbanComponent,
        SearchRowMeetingComponent,
        SearchRowObjectiveComponent,
    ],
    exports: [
        SearchPageComponent,
        HighlightedTextComponent,
        CollapsibleSearchRowComponent,
        SearchRowImplementationKitComponent,
        SearchTabContentComponent,
        ApplicationBarSearchItemComponent,
        SearchRowPageComponent,
        SearchRowPurposeVisionComponent,
        SearchRowValueComponent,
        SearchRowPersonComponent,
        SearchRowTeamComponent,
        SearchRowKanbanComponent,
        SearchRowMeetingComponent,
        SearchRowObjectiveComponent,
    ],
    providers: [
        provideSearchProvider(PageSearchProvider),
        provideSearchProvider(HelpJuiceSearchProvider),
        provideSearchProvider(PeopleSearchProvider),
        provideSearchProvider(TeamSearchProvider),

        provideSidebarTab(SearchTab),
    ],
})
export class AdaptSearchCommonModule {
    public static readonly Routes = [
        ...searchPageRoute.routes,
    ];
}

<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Cancel subscription - Are you sure?</h3>

    <ng-container adapt-dialog-content>
        <p>If you need to leave, please tell us how we can improve things for you in the future, it is much appreciated!</p>
        <div class="form-group">
            <label>Why are you cancelling?</label>
            <dx-radio-group [items]="CancellationReasons"
                            [(value)]="cancellationReason"></dx-radio-group>
        </div>
        <div class="form-group">
            <label>How can we improve?</label>
            <dx-text-box [(value)]="improvementText"></dx-text-box>
        </div>

        <div class="alert alert-info"
             role="alert">
            If you choose to cancel your subscription, your data will remain on our service for a year from the cancellation data.
            During this time, data will be in a read-only state, and you can log in at any time to retrieve your data.
            <br />
            If you decide you want to reactivate your subscription, then please contact support.
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="primary"
                                   saveText="Yes, cancel!"
                                   [saveIsDisabled]="isEmpty(cancellationReason)"
                                   cancelText="Keep subscription"
                                   (saveClick)="cancelSubscription()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

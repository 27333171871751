import { Component, Input, OnInit } from "@angular/core";
import { OrganisationCategoryValue, Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom, merge, Observable, Subject, switchMap } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { WorkflowService } from "../workflow.service";
import { WorkflowsPageRoute } from "../workflows-page/workflows-page.component";

const MAX_WORKFLOWS = 3;

@Component({
    selector: "adapt-workflows-dashboard-element",
    templateUrl: "./workflows-dashboard-element.component.html",
    styleUrls: ["./workflows-dashboard-element.component.scss"],
})
export class WorkflowsDashboardElementComponent extends BaseComponent implements OnInit {
    public readonly categories = Object.values(OrganisationCategoryValue)
        .filter((category: string) => category !== OrganisationCategoryValue.Others);
    public categoriesWorkflows: IGroupedData<OrganisationCategoryValue | undefined, Workflow>[] = [];

    @Input() public person?: Person;
    @Input() public team?: Team;

    public url$?: Observable<string>;
    public recommendedWorkflows: Workflow[] = [];
    public outstandingWorkflowConnections: WorkflowConnection[] = [];
    public outstandingWorkflowConnectionsCount = 0;
    private triggerRecommendedPathwayUpdate = new Subject<void>();

    public constructor(
        rxjsBreezeService: RxjsBreezeService,
        private userService: UserService,
        private workflowService: WorkflowService,
    ) {
        super();

        merge(
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
        ).pipe(
            switchMap(() => this.updateFlowConnections()),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.triggerRecommendedPathwayUpdate.pipe(
            debounceTime(100),
            switchMap(() => this.setRecommendedPathways()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public async ngOnInit() {
        this.url$ = WorkflowsPageRoute.getRoute();
        await this.updateFlowConnections();
    }

    public get recommendationCount() {
        return Math.max(0, MAX_WORKFLOWS - this.outstandingWorkflowConnections.length);
    }

    public get activePathwaysTooltip() {
        return this.outstandingWorkflowConnectionsCount > this.outstandingWorkflowConnections.length
            ? `You have ${this.outstandingWorkflowConnectionsCount} pathways active. Only the ${MAX_WORKFLOWS} most recently started are shown here.`
            : undefined;
    }

    private async updateFlowConnections() {
        if (!this.person) {
            this.person = this.userService.currentPerson;
        }

        const outstandingWorkflows = await lastValueFrom(this.workflowService.getOutstandingWorkflowConnectionsForPerson(this.person!.personId, this.team?.teamId));
        this.outstandingWorkflowConnections = outstandingWorkflows.slice(0, MAX_WORKFLOWS);
        this.outstandingWorkflowConnectionsCount = outstandingWorkflows.length;

        this.triggerRecommendedPathwayUpdate.next();
    }

    private async setRecommendedPathways() {
        this.recommendedWorkflows = [];
        for (const category of this.categories) {
            const categoryWorkflows = this.workflowService.getWorkflowsByCategory(category)
                .filter((i) => (!i.private || category === OrganisationCategoryValue.Onboarding) && !i.isStateless)
                .sort(SortUtilities.getSortByFieldFunction<Workflow>("ordinal"));

            for (const workflow of categoryWorkflows) {
                if (this.recommendedWorkflows.length >= this.recommendationCount) {
                    break;
                }

                if (!workflow.extensions.isComingSoon) {
                    const prerequisitesSatisfied = await lastValueFrom(this.workflowService.areWorkflowPrerequisitesSatisfied(workflow));
                    if (prerequisitesSatisfied) {
                        const workflowConnection = await lastValueFrom(this.workflowService.getLatestWorkflowConnectionForWorkflow(workflow));
                        if (!workflowConnection) {
                            this.recommendedWorkflows.push(workflow);
                        }
                    }
                }
            }
            if (this.recommendedWorkflows.length >= this.recommendationCount) {
                break;
            }
        }
    }
}

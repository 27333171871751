import { Component, Injector, OnInit } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemLink } from "@common/ADAPT.Common.Model/organisation/item-link";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { KeyResult } from "@common/ADAPT.Common.Model/organisation/key-result";
import { KeyResultValue } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Meeting, MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { OutstandingSurveyResponse } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Survey, SurveyStatus, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { SurveyResult } from "@common/ADAPT.Common.Model/organisation/survey-result";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { buttonPreset, IButtonType } from "@common/ux/button/button-preset";

@Component({
    selector: "adapt-org-common-components-page",
    templateUrl: "./org-common-components-page.component.html",
    styleUrls: ["./org-common-components-page.component.scss"],
})
export class OrgCommonComponentsPageComponent extends BaseRoutedComponent implements OnInit {
    public date: Date = new Date();
    public objectiveStatus = ObjectiveStatusMetadata.All;

    public annualObjectives: Objective[];
    public quarterlyObjective: Objective;
    public survey: Survey;
    public outstandingSurveyResponse: OutstandingSurveyResponse;
    public ongoingMeeting: Meeting;
    public keyFunction: KeyFunction;
    public processMap: ProcessMap;
    public zone1 = Zone.ResearchAndDevelopment;
    public zone2 = Zone.EconomicEngine;
    public zone3 = Zone.FinanceAndOwnership;
    public zone4 = Zone.HealthyCulture;
    public zone5 = Zone.OrganisationDesign;

    public role: Role;
    public person: Person;
    public team: Team;
    public item: Item;
    public personalItem: Item;
    public itemsByStatus: { status: ItemStatusMetadata, item: Item }[];
    public label: Label;

    constructor(
        injector: Injector,
    ) {
        super(injector);

        this.label = new Label();
        this.label.name = "Test Label";

        this.team = new Team();
        this.team.organisationId = 1;
        this.team.teamId = 1;
        this.team.name = "Team";

        this.person = new Person();
        this.person.personId = 1;
        this.person.firstName = "Tim";
        this.person.lastName = "Apple";
        this.person.connections = [];

        this.ongoingMeeting = new Meeting();
        this.ongoingMeeting.meetingAgendaItems = [];
        this.ongoingMeeting.name = "Ongoing Meeting Name";
        this.ongoingMeeting.status = MeetingStatus.InProgress;
        this.ongoingMeeting.meetingDateTime = new Date();
        this.ongoingMeeting.createdDateTime = new Date();
        this.ongoingMeeting.endTime = new Date();
        this.ongoingMeeting.team = this.team;

        const keyResult = new KeyResult();
        keyResult.targetValue = 12;

        const keyResultValue = new KeyResultValue();
        keyResultValue.value = 4;
        keyResult.values = [
            keyResultValue,
        ];

        this.annualObjectives = ObjectiveStatusMetadata.All.map((status) => {
            const annualObjective = new Objective();
            annualObjective.title = `${status.name} Objective Title`;
            annualObjective.description = "Objective Description";
            annualObjective.type = ObjectiveType.Annual;
            annualObjective.status = status.status;
            annualObjective.objectiveId = 1;
            annualObjective.dueDate = new Date();
            annualObjective.objectiveLinks = [];
            annualObjective.itemLinks = [];
            annualObjective.keyResults = [keyResult];
            return annualObjective;
        });

        this.quarterlyObjective = new Objective();
        this.quarterlyObjective.title = "Quarterly Title";
        this.quarterlyObjective.description = "Quarterly Description";
        this.quarterlyObjective.type = ObjectiveType.Quarterly;
        this.quarterlyObjective.status = ObjectiveStatus.OnTrack;
        this.quarterlyObjective.objectiveId = 2;
        this.quarterlyObjective.dueDate = new Date();
        this.quarterlyObjective.objectiveLinks = [];
        this.quarterlyObjective.itemLinks = [];
        this.quarterlyObjective.keyResults = [keyResult];

        const quarterlyObjectiveLabelLocation = new LabelLocation();
        quarterlyObjectiveLabelLocation.label = this.label;
        this.quarterlyObjective.labelLocations = [quarterlyObjectiveLabelLocation];

        const surveyResult = new SurveyResult();
        surveyResult.responseGroup = SurveyResponseGroup.All;
        surveyResult.averagePercentageScore = 50;
        surveyResult.percentageParticipation = 50;

        this.survey = new Survey();
        this.survey.surveyType = SurveyType.EmployeeEngagement;
        this.survey.name = "Survey";
        this.survey.endTime = new Date();
        this.survey.status = SurveyStatus.Started;
        this.survey.surveyResults = [surveyResult];

        this.outstandingSurveyResponse = new OutstandingSurveyResponse();
        this.outstandingSurveyResponse.survey = this.survey;

        this.processMap = new ProcessMap();
        this.processMap.name = "Process Map";
        this.processMap.processMapId = 1;
        this.processMap.organisationId = 1;

        this.role = new Role();
        this.role.label = "Role";
        this.role.roleId = 1;

        const roleConnection = new RoleConnection();
        roleConnection.role = this.role;
        roleConnection.roleId = 1;
        roleConnection.roleConnectionId = 1;

        this.role.roleConnections = [roleConnection];

        this.keyFunction = new KeyFunction();
        this.keyFunction.name = "Key Function";
        this.keyFunction.keyFunctionId = 1;
        this.keyFunction.leaderRoleId = 1;
        this.keyFunction.leaderRole = this.role;

        this.itemsByStatus = ItemStatusMetadata.All.map((status, idx) => {
            const item = new Item();
            item.itemId = idx + 1;
            item.summary = "Item Summary";
            item.board = {} as Board;
            item.board.itemPrefix = "AAPL";
            item.boardIndex = idx + 1;
            item.status = status.status;
            return { status, item };
        });
        this.item = this.itemsByStatus[0].item;
        const labelLocation = new LabelLocation();
        labelLocation.label = this.label;
        this.item.labelLocations = [labelLocation];

        const personalBoard = new Board();
        personalBoard.personId = 1;
        personalBoard.itemPrefix = "AAPL";
        this.personalItem = new Item();
        this.personalItem.itemId = 1;
        this.personalItem.summary = "Item Summary";
        this.personalItem.board = personalBoard;
        this.personalItem.boardIndex = 1;
        this.personalItem.status = ItemStatus.ToDo;

        const itemLink = new ItemLink();
        itemLink.primaryItem = this.personalItem;
        itemLink.secondaryItem = this.item;
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public get buttons() {
        return Object.entries(buttonPreset).map(([preset, data]) => ({ preset, ...data }));
    }

    public buttonTrackBy(_: number, item: IButtonType & { preset: string }) {
        return item.preset;
    }

    public showDialog() {
        alert("aaa");
    }
}

<adapt-dialog [maxWidth]="1200">
    <h3 *ngIf="data?.zone || data?.theme; else globalAttach"
        class="d-flex align-items-center"
        adapt-dialog-title>Attach bullseye inputs to
        <adapt-zone-label *ngIf="data?.zone as zone"
                          class="ms-2"
                          [zone]="zone"></adapt-zone-label>
        <ng-container *ngIf="data?.theme as theme">
            <i [ngClass]="StrategicViewIcon.ThemeIcon"
               adaptTooltip="Theme"
               class="mx-1"></i>
            {{theme.name}}
        </ng-container>
    </h3>
    <ng-template #globalAttach>
        <h3 adapt-dialog-title>Attach bullseye inputs to strategy board</h3>
    </ng-template>

    <div adapt-dialog-content
         class="h-100">
        <div class="d-flex flex-column flex-xl-row">
            <div class="half-width-column">
                <adapt-styled-implementation-kit [articleId]="ImplementationKitArticle.BullseyeAttachStatements"
                                                 contentStyle="embedded"></adapt-styled-implementation-kit>
            </div>
            <div *ngIf="hasBullseyeStatements; else noStatement"
                 class="half-width-column">
                <ng-container *ngIf="!(data?.zone || data?.theme)">
                    <span class="mt-3">Choose the destination to attach:</span>
                    <div class="mt-3 d-flex">
                        <div class="d-flex flex-column pe-3">
                            <label>Zone</label>
                            <adapt-select-zone [zone]="selectedZone"
                                               (zoneChange)="onZoneChanged($event)"
                                               [required]="true"
                                               [disabled]="entitiesToConfirm.length > 0"
                                               [filter]="filterZone"></adapt-select-zone>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                            <label>Theme</label>
                            <adapt-select-theme [zone]="selectedZone"
                                                [theme]="selectedTheme"
                                                (themeChange)="onThemeChanged($event)"
                                                [allowCreation]="false"
                                                [height]="44"
                                                [disabled]="entitiesToConfirm.length > 0"
                                                placeholderText="Optionally select a theme..."></adapt-select-theme>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedZone">
                    <span class="mt-3 mb-2">Select one or more bullseye inputs to attach:</span>
                    <adapt-bullseye-view [hideOuter]="true"
                                         [isSelecting]="true"
                                         [selectedStatements]="selectedStatements"
                                         (statementSelect)="onStatementSelected($event)"
                                         (statementDeselect)="onStatementDeselected($event)"></adapt-bullseye-view>
                    <span class="mt-3">Note: Click on the currently selected input to remove.</span>
                </ng-container>
            </div>
            <ng-template #noStatement>
                <div class="alert alert-warning doc-column"
                     *adaptLoading="!isInitialised">
                    <p>
                        There are currently no bullseye inputs to attach to the strategy board.
                    </p>
                </div>
            </ng-template>
        </div>
    </div>

    <div adapt-dialog-footer
         class="d-flex w-100">
        <adapt-standard-dialog-buttons [saveIsDisabled]="hasValidationErrors"
                                       [saveBlockingClick]="saveAndClose"
                                       saveText="Attach & Close"
                                       (cancelClick)="cancel()"
                                       cancelText="Cancel">
        </adapt-standard-dialog-buttons>
    </div>
</adapt-dialog>

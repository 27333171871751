import { Injectable } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { ExternalDashboard } from "@common/ADAPT.Common.Model/organisation/external-dashboard";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { BullseyePageRoute } from "@org-common/lib/bullseye/bullseye-page/bullseye-page.component";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { organisationObjectivesPageRoute } from "@org-common/lib/objectives/objectives-page/objectives-page.route";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { BaseOrgCommonNavigationHierarchyService } from "@org-common/lib/sidebar/base-org-common-navigation-hierarchy.service";
import { StrategicAnchorsPageRoute } from "@org-common/lib/strategic-anchors/strategic-anchors-page/strategic-anchors-page.component";
import { CompetitorAnalysisPageRoute } from "@org-common/lib/strategic-inputs/competitor-analysis-page/competitor-analysis-page.component";
import { StrategicInputsPageRoute } from "@org-common/lib/strategic-inputs/strategic-inputs-page/strategic-inputs-page.component";
import { StrategyBoardPageRoute } from "@org-common/lib/strategy-board/strategy-board-page/strategy-board-page.component";
import { analyseOrganisationDiagnosticPageRoute } from "@org-common/lib/survey/analyse-survey-page/analyse-survey-page.route";
import { manageOrganisationDiagnosticsPageRoute } from "@org-common/lib/survey/organisation-diagnostic/manage-organisation-diagnostics-page/manage-organisation-diagnostics-page.component";
import { organisationDiagnosticSurveySummaryPage } from "@org-common/lib/survey/survey-summary-page/survey-summary-page.component";
import { valuesConstitutionRoute } from "@org-common/lib/values-constitution/values-constitution-page/values-constitution-page.route";
import { WorkflowsPageRoute } from "@org-common/lib/workflow/workflows-page/workflows-page.component";
import { merge } from "rxjs";
import { organisationDashboardPageRoute } from "../organisation/organisation-dashboard-page/organisation-dashboard-page.route";
import { purposeVisionPageRoute } from "../purpose-vision/purpose-vision-page/purpose-vision-page.route";

@Injectable()
export class OrganisationHierarchyNavigationService extends BaseOrgCommonNavigationHierarchyService {
    public static readonly HierarchyId = "ADAPT.Organisation.Navigation.Hierarchy";
    public static readonly IconClass = "fal fa-fw fa-buildings";

    public id = OrganisationHierarchyNavigationService.HierarchyId;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
        rxjsBreezeService: RxjsBreezeService,
        private featuresService: FeaturesService,
    ) {
        super(orgService, authNotification);

        this.rebuildHierarchy$ = merge(
            rxjsBreezeService.entityTypeChangedInSave(Organisation),
            rxjsBreezeService.entityTypeChangedInSave(ValueStream),
            rxjsBreezeService.entityTypeChangedInSave(ExternalDashboard),
            rxjsBreezeService.entityTypeChanged(SystemLocation),
        );
    }

    protected async buildHierarchy() {
        const self = this;

        const hasTier1Access = true; // await this.authorisationFactory.promiseToGetHasAccess(Tier1ArchitectureAuthService.ReadTier1);
        let orgBuilder = hasTier1Access
            ? this.navUtilitiesFactory.nodeBuilderForControllerAndParams(organisationDashboardPageRoute.id)
            : this.navUtilitiesFactory.nodeBuilder();

        orgBuilder = orgBuilder
            .keepChildrenInAddedOrder()
            .setHideIconInBreadcrumb(true)
            .setTitle("Organisation")
            .setCustomKeyValue("displayAsAccordionInHierarchy", true)
            .setCustomKeyValue("expandOnLoad", true)
            .promiseToAddChildController(WorkflowsPageRoute.id)
            .promiseToAddChild(self.promiseToBuildFoundationsNode())
            .promiseToAddChild(self.promiseToBuildBuildStrategyNode())
            .promiseToAddChild(self.promiseToBuildImplementStrategyNode());

        return orgBuilder.promiseToBuild();
    }

    @Autobind
    private promiseToBuildOrganisationDiagnosticNode() {
        return this.navUtilitiesFactory.nodeBuilderForControllerAndParams(organisationDiagnosticSurveySummaryPage.id)
            .keepChildrenInAddedOrder()
            .promiseToAddChildController(manageOrganisationDiagnosticsPageRoute.id)
            .promiseToAddChildController(analyseOrganisationDiagnosticPageRoute.id)
            .promiseToBuild();
    }

    @Autobind
    private promiseToBuildFoundationsNode() {
        return this.navUtilitiesFactory.nodeBuilder().setTitle("Foundations")
            .setCustomKeyValue("expandOnLoad", true)
            .setCustomKeyValue("displayAsAccordionInHierarchy", true)
            .setIconClass("")
            .keepChildrenInAddedOrder()
            .promiseToAddChildController(purposeVisionPageRoute.id)
            .promiseToAddChildController(valuesConstitutionRoute.id)
            .promiseToBuild();
    }

    @Autobind
    private promiseToBuildBuildStrategyNode() {
        return this.navUtilitiesFactory.nodeBuilder().setTitle("Build Strategy")
            .setCustomKeyValue("expandOnLoad", true)
            .setCustomKeyValue("displayAsAccordionInHierarchy", true)
            .keepChildrenInAddedOrder()
            .promiseToAddChildController(StrategyBoardPageRoute.id)
            .promiseToAddChild(this.promiseToBuildStrategicInputsNode())
            .promiseToAddChildController(StrategicAnchorsPageRoute.id)
            .promiseToAddChildController(BullseyePageRoute.id)
            .promiseToAddChild(this.promiseToBuildOrganisationDiagnosticNode())
            .promiseToBuild();
    }

    private promiseToBuildStrategicInputsNode() {
        return this.featuresService.isFeatureActive(FeatureName.StrategicInputs)
            ? this.navUtilitiesFactory.nodeBuilder()
                .keepChildrenInAddedOrder()
                .setTitle("Strategic Inputs")
                .setCustomKeyValue("expandOnLoad", true)
                .setCustomKeyValue("onClickGoToController", StrategicInputsPageRoute.id)
                .promiseToAddChildController(StrategicInputsPageRoute.id)
                .promiseToAddChildController(CompetitorAnalysisPageRoute.id)
                .promiseToBuild()
            : Promise.reject();
    }

    @Autobind
    private promiseToBuildImplementStrategyNode() {
        return this.navUtilitiesFactory.nodeBuilder().setTitle("Implement Strategy")
            .setCustomKeyValue("expandOnLoad", true)
            .setCustomKeyValue("displayAsAccordionInHierarchy", true)
            .keepChildrenInAddedOrder()
            .promiseToAddChildController(organisationObjectivesPageRoute.id)
            .promiseToBuild();
    }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EntityUpdateUtilities } from "@org-common/lib/entity-sync/entity-update-utilities";
import { ReplaySubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ObjectiveFilterService } from "../objective-filter/objective-filter.service";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { ObjectivesAuthService } from "../objectives-auth.service";

@Component({
    selector: "adapt-display-tree-objective",
    templateUrl: "./display-tree-objective.component.html",
    styleUrls: ["./display-tree-objective.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DisplayTreeObjectiveComponent extends BaseComponent implements OnChanges {
    @Input() public objective!: Objective;
    @Input() public isExternalObjective = false;
    @Input() public isCompactView = false;
    @Output() public heightChange = new EventEmitter();

    public readonly treeViewType = ObjectiveViewType.ObjectiveTreeView;
    public isFocussed = true;
    public hasEditPermissions = false;
    private objective$ = new ReplaySubject<Objective>(1);

    constructor(
        private objectivesAuthService: ObjectivesAuthService,
        filterService: ObjectiveFilterService,
        entityUpdateUtilities: EntityUpdateUtilities,
    ) {
        super();

        filterService.isFocussedObjective(this.objective$).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((isFocussedObjective) => {
            this.isFocussed = isFocussedObjective;
            this.heightChange.emit();
        });

        entityUpdateUtilities.onEntityChange(() => this.objective).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((objective) => {
            this.heightChange.emit(objective);
        });

        this.objective$.pipe(
            this.takeUntilDestroyed(),
            switchMap(() => this.objectivesAuthService.hasWriteAccessToObjective(this.objective.teamId)),
        ).subscribe((hasPermissions) => this.hasEditPermissions = hasPermissions);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.objective && changes.objective.currentValue) {
            this.objective$.next(this.objective);
            setTimeout(() => this.heightChange.emit(this.objective));
        }
    }

    public get objectiveStatus() {
        return ObjectiveStatusMetadata.ByStatus[this.objective.status];
    }

    public get showProgress() {
        return this.objective.currentProgress > 0 && this.isFocussed;
    }
}

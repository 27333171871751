<ng-container *ngIf="workflow">
    <div class="outcome-header {{style}}"
         [class.outcome-header-sm]="small">
        <div class="outcome-header-content">
            <h1>{{workflow.name}}</h1>

            <div class="workflow-info">
                <div *ngIf="workflow.time">
                    <i class="far fa-timer me-2"></i>Time required: {{workflow.time}}
                </div>
                <div *ngIf="workflow.people">
                    <i class="far fa-user-group me-2"></i>People required: {{workflow.people}}
                </div>
                <div *ngIf="workflow.mindset">
                    <i class="far fa-head-side-brain me-2"></i>Mindset: {{workflow.mindset}}
                </div>
            </div>

            <div *ngIf="showStartButton"
                 [adaptTooltip]="startButtonTooltip">
                <button type="button"
                        [adaptBlockingClick]="startWorkflow"
                        [disabled]="isStartButtonDisabled"
                        class="btn btn-primary">
                    <i *ngIf="isStartButtonDisabled"
                       class="fas fa-lock me-2"></i>
                    {{startButtonText}} Pathway
                </button>
            </div>
        </div>

        <img class="outcome-header-image"
             height="256"
             [src]="personImage" />
    </div>
</ng-container>

import { Type } from "@angular/core";

export const persistableDialogs: { [name: string]: Type<any> } = {};

export function PersistableDialog(name: string) {
    return (classType: Type<any>) => {
        persistableDialogs[name] = classType;
    };
}

const disabledEntityPersistentDialogs: Type<unknown>[] = [];

export function DisableEntityPersistent() {
    return (classType: Type<unknown>) => {
        disabledEntityPersistentDialogs.push(classType);
    };
}

export function isDisabledEntityPersistentDialog(dialogType: Type<unknown>) {
    return disabledEntityPersistentDialogs.includes(dialogType);
}

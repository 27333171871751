<adapt-dashboard-element *adaptIfAlto="false"
                         name="Meetings"
                         [url]="meetingsUrl$ | async">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-dashboard-element>

<adapt-dashboard-element-hq *adaptIfAlto="true"
                            name="Meetings"
                            linkText="View all meetings"
                            [url]="meetingsUrl$ | async">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-dashboard-element-hq>

<ng-template #content>
    <div class="d-flex flex-column gap-3">
        <ng-container *adaptLoadingUntilFirstEmit="let ongoingMeetings of ongoingMeetings$">
            <div *ngIf="ongoingMeetings.length > 0"
                 class="dashboard-meetings">
                <h4>In Progress</h4>
                <adapt-meeting-dashboard-card *ngFor="let meeting of ongoingMeetings"
                                              [meeting]="meeting"
                                              [showTeamName]="showTeamName"></adapt-meeting-dashboard-card>
            </div>
        </ng-container>
        <div *adaptLoadingUntilFirstEmit="let upcomingMeetings of upcomingMeetings$"
             class="dashboard-meetings">
            <ng-container *ngIf="upcomingMeetings.length > 0; else noUpcomingMeetingsTemplate">
                <h4>Upcoming
                    <span class="fal fa-info-circle small"
                          adaptTooltip="Shows the next two meetings scheduled in the next 2 weeks"></span>
                </h4>
                <adapt-meeting-dashboard-card *ngFor="let meeting of upcomingMeetings"
                                              [meeting]="meeting"
                                              [showTeamName]="showTeamName"></adapt-meeting-dashboard-card>
            </ng-container>
            <ng-template #noUpcomingMeetingsTemplate>
                <h4>Upcoming</h4>
                There are no meetings scheduled in the next 2 weeks.
            </ng-template>
        </div>
        <ng-container *adaptLoadingUntilFirstEmit="let recentMeetings of recentMeetings$">
            <div *ngIf="recentMeetings.length > 0"
                 class="dashboard-meetings">
                <h4>Recent
                    <span class="fal fa-info-circle small"
                          adaptTooltip="Shows the two most recently held meetings in the last 2 weeks"></span>
                </h4>
                <adapt-meeting-dashboard-card *ngFor="let meeting of recentMeetings"
                                              [meeting]="meeting"
                                              [showTeamName]="showTeamName"></adapt-meeting-dashboard-card>
            </div>
        </ng-container>
        <ng-container *ngIf="(team$ | async) as team">
            <button adaptButtonIcon="add"
                    class="btn btn-primary"
                    *adaptIfAuthorised="editTeamKanban;Entity:team"
                    [adaptBlockingClick]="createNewTeamMeeting"
                    [adaptBlockingClickParam]="team">Schedule a meeting</button>
        </ng-container>
    </div>
</ng-template>

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { TourIdentifier } from "../../tours/tour-identifier.enum";
import { WorkflowIdentifier } from "../workflow-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../workflow-utilities";

export const setFirstObjectivesWorkflowName = "Set objectives";

export const setFirstObjectivesWorkflow = buildLocalWorkflow({
    name: setFirstObjectivesWorkflowName,
    workflowId: WorkflowIdentifier.SetFirstObjectives,
    type: WorkflowType.Journey,
    time: "6 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Set your annual strategy based on all of your organisations guidance and inputs from across the business.",
    category: OrganisationCategoryValue.ImplementStrategy,
    articleSlug: ImplementationKitArticle.SetFirstObjectivesOutcomes,
    wrapUpSlug: ImplementationKitArticle.SetFirstObjectivesWrapUp,
    wrapUpGuidedTourIdentifier: TourIdentifier.ObjectivesWrapUpTour,
    ordinal: 20,
    compulsoryPrerequisites: [WorkflowIdentifier.EstablishBusinessCadence],
    featuresToEnable: [FeatureName.StewardshipObjectives],
    workflows: [
        createActivityBriefWorkflow(setFirstObjectivesWorkflowName, WorkflowIdentifier.SetFirstObjectives, undefined, ImplementationKitArticle.SetFirstObjectivesMeetingDescription, ImplementationKitArticle.SetFirstObjectivesMeetingPreWork),
        ...createWorkshopScheduleWorkflows(setFirstObjectivesWorkflowName, WorkflowIdentifier.SetFirstObjectives),
    ],
});

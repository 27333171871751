import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptIdentityModule } from "@common/identity/identity.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptStorageModule } from "@common/storage/storage.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { ContentWithSplashImageModule } from "@common/ux/content-with-splash-image/content-with-splash-image.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptProgressBarModule } from "@common/ux/progress-bar/progress-bar.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DxChartModule, DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxLinearGaugeModule, DxListModule, DxLoadPanelModule, DxMenuModule, DxPieChartModule, DxPolarChartModule, DxProgressBarModule, DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { AdaptEmailModule } from "../email/email.module";
import { AdaptTeamDashboardSharedModule } from "../teams/team-dashboard-shared/team-dashboard-shared.module";
import { AnalyseSurveyComponent } from "./analyse-survey/analyse-survey.component";
import { AnalyseSurveyPageComponent } from "./analyse-survey-page/analyse-survey-page.component";
import { analyseEngagementSurveyPageRoute, analyseOrganisationDiagnosticPageRoute, analyseResilientBusinessAssessmentSurveyPageRoute, analyseTeamAssessmentSurveyPageRoute } from "./analyse-survey-page/analyse-survey-page.route";
import { CategorySummaryComponent } from "./category-summary/category-summary.component";
import { DisplayCategoriesSummaryComponent } from "./display-categories-summary/display-categories-summary.component";
import { DisplayCategoryPercentageComponent } from "./display-category-percentage/display-category-percentage.component";
import { DisplayCategoryQuestionsComponent } from "./display-category-questions/display-category-questions.component";
import { DisplayMultiGroupSurveyResultComponent } from "./display-multi-group-survey-result/display-multi-group-survey-result.component";
import { DisplayOngoingSurveyPersonalDashboardElementComponent } from "./display-ongoing-survey-personal-dashboard-element/display-ongoing-survey-personal-dashboard-element.component";
import { DisplayQuestionResponseDistributionComponent } from "./display-question-response-distribution/display-question-response-distribution.component";
import { DisplaySurveyComponent } from "./display-survey/display-survey.component";
import { DisplaySurveyCategoryChartComponent } from "./display-survey-category-chart/display-survey-category-chart.component";
import { DisplaySurveyHistoryComponent } from "./display-survey-history/display-survey-history.component";
import { DisplaySurveyQuestionStatsComponent } from "./display-survey-question-stats/display-survey-question-stats.component";
import { DisplaySurveyResultComponent } from "./display-survey-result/display-survey-result.component";
import { EditSurveyDialogComponent } from "./edit-survey-dialog/edit-survey-dialog.component";
import { EmployeeEngagementDashboardElementComponent } from "./employee-engagement/ee-dashboard-element/ee-dashboard-element.component";
import { EmployeeEngagementAuthService } from "./employee-engagement/employee-engagement-auth.service";
import { ManageEmployeeEngagementsPageComponent } from "./employee-engagement/manage-employee-engagements-page/manage-employee-engagements-page.component";
import { manageEmployeeEngagementsPageRoute } from "./employee-engagement/manage-employee-engagements-page/manage-employee-engagements-page.route";
import { ManageSurveysComponent } from "./manage-surveys/manage-surveys.component";
import { ManageOrganisationDiagnosticsPageComponent, manageOrganisationDiagnosticsPageRoute } from "./organisation-diagnostic/manage-organisation-diagnostics-page/manage-organisation-diagnostics-page.component";
import { PersonalOngoingSurveyComponent } from "./personal-ongoing-survey/personal-ongoing-survey.component";
import { ManageRBAssessmentsPageComponent } from "./resilient-business-assessment/manage-rb-assessments-page/manage-rb-assessments-page.component";
import { manageRBAssessmentsPageRoute } from "./resilient-business-assessment/manage-rb-assessments-page/manage-rb-assessments-page.route";
import { ResilientBusinessAssessmentDashboardElementComponent } from "./resilient-business-assessment/rb-assessment-dashboard-element/rb-assessment-dashboard-element.component";
import { ResilientBusinessAssessmentAuthService } from "./resilient-business-assessment/resilient-business-assessment-auth.service";
import { SurveyPageComponent } from "./survey-page/survey-page.component";
import { surveyPageRoute } from "./survey-page/survey-page.route";
import { SurveySelectionBoxComponent } from "./survey-selection-box/survey-selection-box.component";
import { organisationDiagnosticSurveySummaryPage, SurveySummaryPageComponent } from "./survey-summary-page/survey-summary-page.component";
import { ManageTeamAssessmentsPageComponent } from "./team-assessment/manage-team-assessments-page/manage-team-assessments-page.component";
import { manageTeamAssessmentsPageRoute } from "./team-assessment/manage-team-assessments-page/manage-team-assessments-page.route";
import { TeamAssessmentAuthService } from "./team-assessment/team-assessment-auth.service";
import { TeamAssessmentDashboardElementComponent } from "./team-assessment/team-assessment-dashboard-element/team-assessment-dashboard-element.component";
import { ViewSurveyOutstandingResponsesDialogComponent } from "./view-survey-outstanding-responses-dialog/view-survey-outstanding-responses-dialog.component";
import { ViewSurveyRespondentsDialogComponent } from "./view-survey-respondents-dialog/view-survey-respondents-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptCommonDialogModule,
        DxTextBoxModule,
        DxDateBoxModule,
        AdaptButtonModule,
        AdaptEntityValidatorModule,
        DxListModule,
        DxRadioGroupModule,
        DxProgressBarModule,
        AdaptIdentityModule,
        AdaptDateModule,
        AdaptProgressBarModule,
        DxPolarChartModule,
        DxMenuModule,
        AdaptShellModule,
        DxScrollViewModule,
        DxChartModule,
        DxSelectBoxModule,
        AdaptTooltipModule,
        AdaptLoadingSpinnerModule,
        DxLinearGaugeModule,
        DxPieChartModule,
        AdaptEditorViewModule,
        DxDataGridModule,
        AdaptCommonUserModule,
        AdaptTwoColumnModule,
        ContentWithSplashImageModule,
        AdaptStorageModule,
        DxCheckBoxModule,
        DxTextAreaModule,
        AdaptDashboardElementModule,
        AdaptCallToActionModule,
        AdaptDirectorySharedModule,
        AdaptTeamDashboardSharedModule,
        AdaptEmailModule,
        AdaptFeatureModule,
        DxLoadPanelModule,
    ],
    declarations: [
        EditSurveyDialogComponent,
        SurveyPageComponent,
        PersonalOngoingSurveyComponent,
        DisplayOngoingSurveyPersonalDashboardElementComponent,
        DisplaySurveyComponent,
        DisplaySurveyResultComponent,
        ManageEmployeeEngagementsPageComponent,
        DisplaySurveyHistoryComponent,
        AnalyseSurveyPageComponent,
        DisplaySurveyQuestionStatsComponent,
        AnalyseSurveyComponent,
        SurveySelectionBoxComponent,
        DisplayQuestionResponseDistributionComponent,
        DisplaySurveyCategoryChartComponent,
        ManageSurveysComponent,
        ManageTeamAssessmentsPageComponent,
        DisplayCategoriesSummaryComponent,
        DisplayCategoryQuestionsComponent,
        ManageRBAssessmentsPageComponent,
        DisplayMultiGroupSurveyResultComponent,
        DisplayCategoryPercentageComponent,
        ViewSurveyOutstandingResponsesDialogComponent,
        EmployeeEngagementDashboardElementComponent,
        ResilientBusinessAssessmentDashboardElementComponent,
        TeamAssessmentDashboardElementComponent,
        ViewSurveyRespondentsDialogComponent,
        ManageOrganisationDiagnosticsPageComponent,
        SurveySummaryPageComponent,
        CategorySummaryComponent,
    ],
    exports: [
        DisplaySurveyComponent,
        EmployeeEngagementDashboardElementComponent,
        ResilientBusinessAssessmentDashboardElementComponent,
        DisplaySurveyResultComponent,
        DisplayMultiGroupSurveyResultComponent,
        DisplayCategoriesSummaryComponent,
        TeamAssessmentDashboardElementComponent,
        DisplayOngoingSurveyPersonalDashboardElementComponent,
        PersonalOngoingSurveyComponent,
        CategorySummaryComponent,
    ],
})
export class AdaptSurveyModule {
    public static readonly CommonRoutes = [
        ...surveyPageRoute.routes,
    ];

    public static readonly OrganisationDiagnosticRoutes = [
        ...manageOrganisationDiagnosticsPageRoute.routes,
        ...analyseOrganisationDiagnosticPageRoute.routes,
        ...organisationDiagnosticSurveySummaryPage.routes,
    ];

    public static readonly EngagementRoutes = [
        ...manageEmployeeEngagementsPageRoute.routes,
        ...analyseEngagementSurveyPageRoute.routes,
    ];

    public static readonly TeamAssessmentRoutes = [
        ...manageTeamAssessmentsPageRoute.routes,
        ...analyseTeamAssessmentSurveyPageRoute.routes,
    ];

    public static readonly ResilientAssessmentRoutes = [
        ...manageRBAssessmentsPageRoute.routes,
        ...analyseResilientBusinessAssessmentSurveyPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        EmployeeEngagementAuthService.registerAccessVerifiers(authorisationService);
        TeamAssessmentAuthService.registerAccessVerifiers(authorisationService);
        ResilientBusinessAssessmentAuthService.registerAccessVerifiers(authorisationService);
    }
}

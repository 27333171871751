import { Observable } from "rxjs";

export enum NavigationNodeType {
    Static,
    Dynamic,
}

export interface INavigationNodeCustomData {
    urlPriority?: number;
    iconClass?: string;
    iconPositionRight?: boolean;
    displayAsAccordionInHierarchy?: boolean;
    expandOnLoad?: boolean;
    exclusiveExpand?: boolean;
    onClickGoToController?: string;
    isHiddenInBreadcrumbs?: boolean;
    isCollapsed?: boolean;
}

export interface INavigationNode {
    $id: number;
    id?: string;
    title: string;
    url?: string;
    parent?: INavigationNode;
    children: INavigationNode[];
    ordinal?: number;
    controller?: string;
    dynamicCallback: DynamicCallback;
    dynamicNodeCallback?: (referenceNode: INavigationNode, params: { [name: string]: any }) => PromiseLike<INavigationNode>;
    type: NavigationNodeType;
    customData: INavigationNodeCustomData;
    visible?: boolean; // only used by dx-accordion to hide entire branch if no children
    childAdded$: Observable<INavigationNode>;
    childRemoved$: Observable<INavigationNode>;
    currentOrChildSelected$: Observable<INavigationNode>;
    iconClass?: string;
    hideIconInBreadcrumb?: boolean;
    iconPositionRight?: boolean;

    clone(): INavigationNode;
    promiseToBuildDynamicNode(params: any): Promise<INavigationNode>;
    setParent(parent: INavigationNode): void;
    addChild(child: INavigationNode): void;
    removeChild(child: INavigationNode): void;
    setCurrentSelection(): void;
}

export type DynamicCallback = {
    [P in keyof INavigationNode]?: DynamicCallbackFn<P>;
};

export type DynamicCallbackFn<P extends keyof INavigationNode> = (referenceNode: INavigationNode, params: { [name: string]: any }) => PromiseLike<INavigationNode[P]>;

<dx-select-box [dataSource]="itemStatuses"
               [value]="itemStatusSelection"
               (valueChange)="itemChange($event)"
               [disabled]="disabled"
               [openOnFieldClick]="true"
               fieldTemplate="field">
    <div *dxTemplate="let itemStatus of 'field'"
         class="d-flex">
        <div class="center-content m-2 d-flex flex-row">
            <adapt-display-item-status [itemStatus]="itemStatus"
                                       data-test="select-item-status-field"></adapt-display-item-status>
            <!-- Just need dx-text-box here to avoid dx error - not used for anything else -->
            <dx-text-box class="d-none"
                         [readOnly]="true"></dx-text-box>
        </div>
    </div>
    <div *dxTemplate="let itemStatus of 'item'"
         class="center-content">
        <adapt-display-item-status [itemStatus]="itemStatus"></adapt-display-item-status>
    </div>
</dx-select-box>

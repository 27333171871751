import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { AdaptHtmlEditorComponent } from "@common/ux/html-editor/html-editor.component";

@Component({
    selector: "adapt-add-comment",
    templateUrl: "./add-comment.component.html",
    styleUrls: ["./add-comment.component.scss"],
    encapsulation: ViewEncapsulation.None, // For migration from edit-item-comments
})
export class AddCommentComponent extends BaseComponent implements AfterViewInit {
    @Input() public newComment?: string;
    @Output() public newCommentChange = new EventEmitter<string>();
    @Input() public commentingPerson?: Person;
    @Input() public allowSave = true;
    @Input() public focusOnInit = false;

    @ViewChild(AdaptHtmlEditorComponent) private htmlEditor?: AdaptHtmlEditorComponent;
    public showSaveButton = false;

    private showSaveButtonUpdater = this.createThrottledUpdater((value: boolean) => this.showSaveButton = value);

    @Input() public doSave = () => Promise.resolve();

    public ngAfterViewInit() {
        if (this.focusOnInit) {
            this.htmlEditor?.getElementToFocus();
        }

        // showing save button after a restore from previously typed new comment
        this.showSaveButtonUpdater.next(this.allowSave && !!this.newComment);
    }

    @Autobind
    public async save() {
        await this.doSave();
        this.showSaveButtonUpdater.next(false);
    }
}

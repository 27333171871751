import dxConfig from "devextreme/core/config";
import dxDataGrid, { ExportingEvent, Properties as dxDataGridOptions } from "devextreme/ui/data_grid";
import dxDateBox from "devextreme/ui/date_box";
import dxList from "devextreme/ui/list";
import dxLoadPanel, { Properties as dxLoadPanelOptions } from "devextreme/ui/load_panel";
import dxNumberBox, { Properties as dxNumberBoxOptions } from "devextreme/ui/number_box";
import { baseZIndex as setBaseZIndex } from "devextreme/ui/overlay";
import dxTextArea, { Properties as dxTextAreaOptions } from "devextreme/ui/text_area";
import dxTextBox, { Properties as dxTextBoxOptions } from "devextreme/ui/text_box";
import { currentPalette } from "devextreme/viz/palette";
import { currentTheme, registerTheme } from "devextreme/viz/themes";
import toastr from "toastr";
import { DateFormats } from "../ux/date-formats";
import { DxUtilities } from "./utilities/dx-utilities";

export const baseZIndex = 2000;

// initialise UX dependencies (DevExpress, toastr)
export function initialiseUx() {
    initialiseToastr();
    initialiseDx();
}

function initialiseToastr() {
    // Configure Toastr
    toastr.options.timeOut = 4000;
    toastr.options.positionClass = "toast-bottom-right";
    toastr.options.toastClass = "toastr";
}

function initialiseDx() {
    // Configure DevExpress
    setBaseZIndex(2000);
    currentPalette("Office");
    const fontFamily = "var(--bs-body-font-family)";

    registerTheme({
        name: "ADAPTbyDesign",
        tooltip: {
            zIndex: 2000,
        },
        chart: {
            commonAxisSettings: {
                visible: false,
                color: "#d3d3d3",
                minorTick: {
                    color: "#d3d3d3",
                    opacity: 0.3,
                    length: 8,
                    shift: 0,
                },
                tick: {
                    visible: false,
                    color: "#d3d3d3",
                    length: 8,
                    shift: 0,
                },
                label: {
                    font: {
                        family: fontFamily,
                    },
                },
                title: { // need this for the value axis title
                    font: {
                        family: fontFamily,
                    },
                },
            },
            legend: {
                font: {
                    family: fontFamily,
                },
            },
            loadingIndicator: {
                font: {
                    family: fontFamily,
                },
            },
            title: {
                font: {
                    family: fontFamily,
                },
                subtitle: {
                    font: {
                        family: fontFamily,
                    },
                },
            },
            tooltip: {
                font: {
                    family: fontFamily,
                },
                // this will cause issue if we spawn a dialog from point click - tooltip above dialog! Use @ViewChild(DxTooltipComponent)
                // from the angular component containing the dxChart to call tooltipComponent.instance.hide() to hide the tooltip on click
                zIndex: 2100, // need this or the tooltip for a chart won't be visible in a dialog
            },
        },
        pie: {
            commonSeriesSettings: {
                label: {
                    font: {
                        family: fontFamily,
                    },
                },
            },
            legend: {
                font: {
                    family: fontFamily,
                },
            },
            loadingIndicator: {
                font: {
                    family: fontFamily,
                },
            },
            title: {
                font: {
                    family: fontFamily,
                },
                subtitle: {
                    font: {
                        family: fontFamily,
                    },
                },
            },
            tooltip: {
                font: {
                    family: fontFamily,
                },
            },
        },
        polar: {
            commonAxisSettings: {
                color: "#d3d3d3",
                minorTick: {
                    color: "#d3d3d3",
                    opacity: 0.3,
                    length: 8,
                    shift: 0,
                },
                tick: {
                    visible: false,
                    color: "#d3d3d3",
                    length: 8,
                    shift: 0,
                },
                label: {
                    font: {
                        family: fontFamily,
                    },
                },
            },
            commonSeriesSettings: {
                label: {
                    font: {
                        family: fontFamily,
                    },
                },
            },
            legend: {
                font: {
                    family: fontFamily,
                },
            },
            loadingIndicator: {
                font: {
                    family: fontFamily,
                },
            },
            title: {
                font: {
                    family: fontFamily,
                },
                subtitle: {
                    font: {
                        family: fontFamily,
                    },
                },
            },
            tooltip: {
                font: {
                    family: fontFamily,
                },
            },
        },
    }, currentTheme());
    currentTheme("ADAPTbyDesign");
    dxConfig({ editorStylingMode: "outlined" });
    dxDateBox.defaultOptions({
        device: () => true,
        options: {
            calendarOptions: {
                showTodayButton: true,
            },
            min: "1/1/1900",
            openOnFieldClick: true,
            displayFormat: DateFormats.globalize.short,
        },
    });
    dxList.defaultOptions({
        device: () => true, // all devices
        options: {
            itemDragging: {
                boundary: "div[data-dx-list]",
            },
        },
    });

    const loadPanelOptions: dxLoadPanelOptions = {
        indicatorSrc: "/content/ux/loading-spinner/assets/loading-spinner.svg",
    };
    dxLoadPanel.defaultOptions({
        device: () => true,
        options: loadPanelOptions,
    });
    dxDataGrid.defaultOptions({
        device: () => true,
        options: {
            loadPanel: loadPanelOptions,
            onExporting: ($event: ExportingEvent) => DxUtilities.exportGridToExcel(($event.component.option("export") as any).fileName, $event.component),
        } as dxDataGridOptions,
    });
    dxTextBox.defaultOptions({
        device: () => true, // applied to all devices
        options: {
            valueChangeEvent: "keyup change blur input focusout",
        } as dxTextBoxOptions,
    });
    dxTextArea.defaultOptions({
        device: () => true,
        options: {
            valueChangeEvent: "keyup change blur input focusout",
        } as dxTextAreaOptions,
    });
    dxNumberBox.defaultOptions({
        device: () => true,
        options: {
            valueChangeEvent: "keyup change blur input focusout",
        } as dxNumberBoxOptions,
    });
}

import { Component, Input } from "@angular/core";
import { ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";

@Component({
    selector: "adapt-display-objective-status",
    templateUrl: "./display-objective-status.component.html",
    styleUrls: ["./display-objective-status.component.scss"],
})
export class DisplayObjectiveStatusComponent {
    @Input() public objectiveStatus?: ObjectiveStatusMetadata;
    @Input() public isCompactView = false;
}

import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "../workflow-identifier.enum";

export const assessEffectivenessWorkflowName = "Assess team effectiveness";

export const assessEffectivenessWorkflow = buildLocalWorkflow({
    name: assessEffectivenessWorkflowName,
    workflowId: WorkflowIdentifier.AssessEffectiveness,
    type: WorkflowType.Journey,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Assess the effectiveness of your leadership team through a survey designed to highlight your leadership team's performance.",
    category: OrganisationCategoryValue.EffectiveLeadershipTeam,
    articleSlug: ImplementationKitArticle.ComingSoon, // a coming soon HelpJuice document
    ordinal: 40,
});

import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatus } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import moment from "moment";

export enum ClosedFilters {
    ShowSupporting = "Show Only Supporting",
    ShowAll = "Show All",
}

export class ObjectiveFilter {
    public objectiveStatuses = new Set<ObjectiveStatus>([
        ObjectiveStatus.OnTrack,
        ObjectiveStatus.Slow,
        ObjectiveStatus.Stuck,
    ]);

    public duringYear?: Date;
    public closedOption: ClosedFilters = ClosedFilters.ShowSupporting;

    // Show External objectives is to show Objectives that are not part of a team/org's objectives but relate to it
    public showExternalObjectives = true;
    public assignee?: Person;
    public labels: Label[] = [];

    public setFilterStatuses(tags: ObjectiveStatus[]) {
        this.objectiveStatuses = new Set(tags);
        return this;
    }

    public clone() {
        const clone = new ObjectiveFilter();
        clone.objectiveStatuses = new Set(this.objectiveStatuses);
        clone.duringYear = this.duringYear;
        clone.closedOption = this.closedOption;
        clone.showExternalObjectives = this.showExternalObjectives;
        clone.assignee = this.assignee;
        clone.labels = [...this.labels];
        return clone;
    }

    public buildObjectivesPredicate(teamId?: number) {
        let predicate: MethodologyPredicate<Objective>;

        if (this.closedOption === ClosedFilters.ShowSupporting && this.objectiveStatuses.has(ObjectiveStatus.Closed)) {
            const clonedFilter = this.clone();
            clonedFilter.objectiveStatuses.delete(ObjectiveStatus.Closed);

            predicate = clonedFilter.buildPredicate(teamId, true);
        } else {
            predicate = this.buildPredicate(teamId);
        }

        return predicate;
    }

    public equals(other: ObjectiveFilter) {
        if (this === other) {
            return true;
        }

        return this.objectiveStatuses.size === other.objectiveStatuses.size
            && Array.from(this.objectiveStatuses).every((s) => other.objectiveStatuses.has(s))
            && ((!this.duringYear && !other.duringYear)
                || (!!this.duringYear && !!other.duringYear && this.duringYear.getTime() === other.duringYear.getTime()))
            && this.closedOption === other.closedOption
            && this.showExternalObjectives === other.showExternalObjectives
            && this.assignee === other.assignee
            && ArrayUtilities.contentsAreEqual(this.labelIds, other.labelIds);
    }

    private buildPredicate(teamId?: number, showSupportingClosed?: boolean) {
        const predicate = new MethodologyPredicate<Objective>();
        const objectiveRootPredicate = new MethodologyPredicate<Objective>("teamId", "==", teamId ?? null);

        if (this.showExternalObjectives) {
            objectiveRootPredicate.or(new MethodologyPredicate<Objective>("parentObjectiveId", "!=", null)
                .and(new MethodologyPredicate<Objective>("parentObjective.teamId", "==", teamId ?? null)));
        }

        predicate.and(objectiveRootPredicate);

        if (this.objectiveStatuses.size > 0) {
            const statusPredicate = new MethodologyPredicate<Objective>("status", "in", [...this.objectiveStatuses]);

            if (showSupportingClosed) {
                const closedSupportPredicate = new MethodologyPredicate<Objective>("status", "==", ObjectiveStatus.Closed);
                closedSupportPredicate.and(new MethodologyPredicate<Objective>("parentObjectiveId", "!=", null));
                closedSupportPredicate.and(new MethodologyPredicate<Objective>("parentObjective.status", "!=", ObjectiveStatus.Closed));

                statusPredicate.or(closedSupportPredicate);
            }

            predicate.and(statusPredicate);
        }

        if (this.duringYear) {
            const startOfYear = moment(this.duringYear)
                .startOf("year")
                .toDate();
            predicate.and(new MethodologyPredicate<Objective>("dueDate", ">=", startOfYear));

            const endOfYear = moment(this.duringYear)
                .endOf("year")
                .toDate();
            predicate.and(new MethodologyPredicate<Objective>("dueDate", "<=", endOfYear));
        }

        if (this.assignee) {
            predicate.and(new MethodologyPredicate<Objective>("assigneePersonId", "==", this.assignee.personId));
        }

        return predicate;
    }

    public get labelIds() {
        return this.labels.map((l) => l.labelId);
    }

    /**
     * When the filter has the Closed status only and 'Closed Objectives' == 'Show Only Supporting',
     * the query will always return everything, so this function allows us to easily check this condition and return quickly
     */
    public get onlyClosedAndSupporting() {
        return this.objectiveStatuses.has(ObjectiveStatus.Closed)
            && this.objectiveStatuses.size === 1
            && this.closedOption === ClosedFilters.ShowSupporting;
    }
}

<adapt-collapsible-search-row #row
                              [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [showFirstCrumb]="false">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 me-2 align-items-center text-muted">
            <div class="d-flex align-items-center result-icon vs-icon"
                 adaptTooltip="Purpose & vision">
                <i class="fal fa-bullseye fa-inverse"></i>
            </div>

            <div class="ms-2">
                <adapt-link-team *ngIf="result.TeamId; else orgText"
                                 class="me-2"
                                 [labelLinkDisabled]="true"
                                 [teamId]="result.TeamId"></adapt-link-team>
                <ng-template #orgText>
                    <span class="me-2">
                        Organisation
                    </span>
                </ng-template>
                &gt;
            </div>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            {{ firstMatch.Field[0] }}
        </div>
    </div>
</adapt-collapsible-search-row>

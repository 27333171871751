export enum Zone {
    OrganisationDesign = "OrganisationDesign",
    EconomicEngine = "EconomicEngine",
    HealthyCulture = "HealthyCulture",
    FinanceAndOwnership = "FinanceAndOwnership",
    ResearchAndDevelopment = "ResearchAndDevelopment",
}

export class ZoneMetadata {
    public static readonly Name: { [key in Zone]: string } = {
        [Zone.OrganisationDesign]: "Organisation Design",
        [Zone.EconomicEngine]: "Economic Engine",
        [Zone.HealthyCulture]: "Leadership & Culture",
        [Zone.FinanceAndOwnership]: "Financial Security",
        [Zone.ResearchAndDevelopment]: "Research & Development",
    };

    public static readonly Ordinal: { [key in Zone]: number } = {
        [Zone.ResearchAndDevelopment]: 0,
        [Zone.HealthyCulture]: 1,
        [Zone.OrganisationDesign]: 2,
        [Zone.FinanceAndOwnership]: 3,
        [Zone.EconomicEngine]: 4,
    };

    public static readonly Icon: { [key in Zone]: string } = {
        [Zone.OrganisationDesign]: "fal fa-pen-ruler",
        [Zone.EconomicEngine]: "fal fa-train-subway",
        [Zone.ResearchAndDevelopment]: "fal fa-magnifying-glass-dollar",
        [Zone.HealthyCulture]: "fal fa-leaf",
        [Zone.FinanceAndOwnership]: "fal fa-circle-dollar",
    };

    public static readonly Style: { [key in Zone]: string } = {
        [Zone.OrganisationDesign]: "zone-organisation-design",
        [Zone.EconomicEngine]: "zone-economic-engine",
        [Zone.ResearchAndDevelopment]: "zone-research-development",
        [Zone.HealthyCulture]: "zone-healthy-culture",
        [Zone.FinanceAndOwnership]: "zone-finance-ownership",
    };

    // These correspond with the colours in the scss
    public static readonly Colour: { [key in Zone]: string } = {
        [Zone.OrganisationDesign]: "#4aae81",
        [Zone.EconomicEngine]: "#7442a3",
        [Zone.ResearchAndDevelopment]: "#9f69d1",
        [Zone.HealthyCulture]: "#cb2370",
        [Zone.FinanceAndOwnership]: "#ef8b25",
    };

    public static readonly VisibleZones = [Zone.EconomicEngine, Zone.HealthyCulture, Zone.FinanceAndOwnership, Zone.OrganisationDesign];

    public static readonly InOrder = [
        Zone.EconomicEngine,
        Zone.ResearchAndDevelopment,
        Zone.HealthyCulture,
        Zone.OrganisationDesign,
        Zone.FinanceAndOwnership,
    ];
}

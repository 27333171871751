<adapt-dialog [maxWidth]="1200">
    <h3 *ngIf="data.zone || data.theme; else globalAttach"
        class="d-flex align-items-center"
        adapt-dialog-title>Attach {{canvasTypeLabel}} inputs to
        <adapt-zone-label *ngIf="data.zone as zone"
                          class="ms-2"
                          [zone]="zone"></adapt-zone-label>
        <ng-container *ngIf="data.theme as theme">
            <i [ngClass]="StrategicViewIcon.ThemeIcon"
               adaptTooltip="Theme"
               class="mx-1"></i>
            {{theme.name}}
        </ng-container>
    </h3>
    <ng-template #globalAttach>
        <h3 adapt-dialog-title>Attach inputs to strategy board</h3>
    </ng-template>
    <div adapt-dialog-content
         class="h-100">
        <div class="d-flex flex-column flex-xl-row">
            <div class="doc-column">
                <adapt-styled-implementation-kit [articleId]="implementationKitArticle"
                                                 contentStyle="embedded"></adapt-styled-implementation-kit>
            </div>
            <div *ngIf="hasCanvas; else noCanvas"
                 class="flex-grow-1 d-flex flex-column mt-4 mt-xl-0">
                <ng-container *ngIf="!(data.zone || data.theme)">
                    <span>Choose the destination to attach:</span>
                    <div class="mt-3 d-flex">
                        <div class="d-flex flex-column pe-3">
                            <label>Zone</label>
                            <adapt-select-zone [zone]="selectedZone"
                                               (zoneChange)="onZoneChanged($event)"
                                               [required]="true"
                                               [disabled]="entitiesToConfirm.length > 0"
                                               [filter]="filterZone"></adapt-select-zone>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                            <label>Theme</label>
                            <adapt-select-theme [zone]="selectedZone"
                                                [theme]="selectedTheme"
                                                (themeChange)="onThemeChanged($event)"
                                                [allowCreation]="false"
                                                [height]="44"
                                                [disabled]="entitiesToConfirm.length > 0"
                                                placeholderText="Optionally select a theme..."></adapt-select-theme>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="selectedZone && isInitialised"
                     class="mt-3 d-flex flex-column">
                    <span>Choose inputs to attach to the destination above:</span>
                    <ng-container *ngIf="showCanvasSelector">
                        <label class="mt-3">Canvas</label>
                        <adapt-select-canvas (canvasChange)="selectedCanvasUpdater.next($event)"
                                             [canvasTypes]="[data.canvasType]"></adapt-select-canvas>
                    </ng-container>

                    <label class="mt-2">Input</label>
                    <adapt-select-input [canvas]="selectedCanvas"
                                        [inputFilter]="inputFilter"
                                        (inputChange)="onInputSelected($event)"></adapt-select-input>

                    <div *ngIf="existingLocations.length > 0"
                         class="mt-4">
                        <span>Attached {{canvasTypeLabel}} inputs:</span>
                        <dx-list *ngIf="existingLocations.length > 0"
                                 [activeStateEnabled]="false"
                                 [hoverStateEnabled]="false"
                                 [focusStateEnabled]="false"
                                 [dataSource]="existingLocations"
                                 (onItemReordered)="reorderInputLocations($event)">
                            <dxo-item-dragging [data]="existingLocations"
                                               [allowReordering]="true"></dxo-item-dragging>
                            <div *dxTemplate="let inputLocation of 'item'">
                                <adapt-strategic-input [input]="inputLocation.input"
                                                       [showIcon]="true"
                                                       [showCanvasNameInContent]="true"
                                                       [isDetachable]="true"
                                                       (detach)="onInputDetached($event)"
                                                       [isCollapsible]="true"></adapt-strategic-input>
                            </div>
                        </dx-list>
                    </div>
                </div>

                <ng-template #selectDestinationFirst>
                    <div class="alert alert-light">Select a zone or theme first</div>
                </ng-template>
            </div>
            <ng-template #noCanvas>
                <div class="alert alert-warning doc-column"
                     *adaptLoading="!isInitialised">
                    <p>
                        There is currently no strategic input canvas and hance won't have any input to attach.
                    </p>
                    <p *ngIf="editInputsRoute$ | async as route">
                        Please go through Gather Inputs pathway first before proceeding,
                        manually creating Strategic Inputs canvas <a [routerLink]="route">here</a>,
                        or cancel this operation using the Cancel button below.
                    </p>
                </div>
            </ng-template>
        </div>
    </div>
    <div adapt-dialog-footer
         class="d-flex w-100">
        <adapt-standard-dialog-buttons [saveIsDisabled]="hasValidationErrors"
                                       [saveBlockingClick]="saveAndClose"
                                       (cancelClick)="cancel()"
                                       cancelText="Cancel">
        </adapt-standard-dialog-buttons>
    </div>
</adapt-dialog>

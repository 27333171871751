<span class="item-link"
      *ngIf="item && item.board"
      [ngClass]="{
          'item-link-personal-item': item.board.isPersonalBoard,
          'item-link-bold-code': useBoldItemCode
      }">
    <span *ngIf="showStatus"
          class="item-status-badge"
          [ngClass]="itemStatusBadgeClass"
          [adaptTooltip]="itemStatusTooltip">
    </span>
    <a *ngIf="showDialogLink"
       (click)="openItem($event)"
       class="item-code"
       [ngClass]="{
        'badge rounded-pill': displayAsBadge
       }"
       data-test="item-code"
       [href]="(itemUrl$ | async)"
       target="_blank">
        {{item!.code}}
        <i class="fal fa-lock-alt"
           aria-hidden="true"
           *ngIf="item!.board?.isPersonalBoard; else privateTeamIcon"
           adaptTooltip="Item is only visible to you">
        </i>
    </a>
    <span *ngIf="!showDialogLink"
          class="item-code"
          [ngClass]="{
            'badge rounded-pill': displayAsBadge
           }"
          data-test="item-code">
        {{item!.code}}
        <i class="fal fa-lock-alt"
           aria-hidden="true"
           *ngIf="item!.board?.isPersonalBoard; else privateTeamIcon"
           adaptTooltip="Item is only visible to you">
        </i>
    </span>
    <ng-template #privateTeamIcon>
        <adapt-team-private-icon [team]="item.board.team"></adapt-team-private-icon>
    </ng-template>
    <ng-container *ngIf="showSummary && item!.summary">
        <span class="ms-1 text-break"
              data-test="item-link-summary">
            {{item!.summary}}
        </span>
    </ng-container>
</span>

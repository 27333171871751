import { Injectable, Injector } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { IEntityWithRequiredTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { BaseService } from "@common/service/base.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { from, of } from "rxjs";
import { combineAll, map, switchMap, take } from "rxjs/operators";
import { CommonTeamsService } from "../teams/common-teams.service";

@Injectable({
    providedIn: "root",
})
export class ObjectivesAuthService extends BaseService {
    public static readonly ReadObjectives = "readObjectives";
    public static readonly EditObjectives = "editObjectives";
    public static readonly ReadPublicObjectives = "readPublicObjectives";

    public constructor(
        injector: Injector,
        private teamsService: CommonTeamsService,
        private authService: AuthorisationService,
    ) {
        super(injector);
    }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            ObjectivesAuthService.ReadPublicObjectives,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipObjectivesPublicRead,
                    FeaturePermissionName.StewardshipObjectivesRead,
                    FeaturePermissionName.StewardshipObjectivesEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            ObjectivesAuthService.ReadObjectives,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipObjectivesRead,
                    FeaturePermissionName.StewardshipObjectivesEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            ObjectivesAuthService.EditObjectives,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipObjectivesEdit,
                ],
            },
        );
    }

    public get hasAnyReadAccessToObjective$() {
        return this.hasReadAccessToObjective().pipe(
            switchMap((hasOrgRead) => {
                if (hasOrgRead) {
                    return of(true);
                } else {
                    return this.initialisation$.pipe(
                        take(1),
                        switchMap(() => this.teamsService.promiseToGetActiveTeamsForCurrentPerson()),
                        switchMap((teams) => teams.length > 0
                            ? teams.map((team) => this.hasReadAccessToObjective(team.teamId))
                            : [of(false)]),
                        combineAll(),
                        map((hasTeamReads) => hasTeamReads.indexOf(true) >= 0),
                    );
                }
            }),
        );
    }

    public hasReadAccessToObjective(teamId?: number) {
        if (!teamId) {
            return from(this.authService.promiseToGetHasAccess(ObjectivesAuthService.ReadPublicObjectives));
        } else {
            return from(this.authService.promiseToGetHasAccess(ObjectivesAuthService.ReadObjectives, { teamId }));
        }
    }

    public hasWriteAccessToObjective(teamId?: number) {
        return from(this.authService.promiseToGetHasAccess(ObjectivesAuthService.EditObjectives, { teamId }));
    }

    public personCanEditObjectivesForTeam(person: Person, teamEntity: IEntityWithRequiredTeam) {
        return this.authService.personHasPermission(person, FeaturePermissionName.StewardshipObjectivesEdit, teamEntity);
    }
}

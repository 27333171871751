import { Component, Injector, OnInit } from "@angular/core";
import { ValuesConstitution } from "@common/ADAPT.Common.Model/organisation/values-constitution";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { ValuesConstitutionService } from "@org-common/lib/values-constitution/values-constitution.service";
import { ValuesConstitutionAuthService } from "@org-common/lib/values-constitution/values-constitution-auth.service";
import { combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
    selector: "adapt-organisation-dashboard-page",
    templateUrl: "./organisation-dashboard-page.component.html",
})
export class OrganisationDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly PurposeVisionRead = CommonPurposeVisionAuthService.ReadPurposeVision;
    public readonly ValuesRead = ValuesConstitutionAuthService.ReadValuesConstitution;

    public valuesConstitution?: ValuesConstitution;
    public hasAccess = true;

    constructor(
        injector: Injector,
        private authorisationService: AuthorisationService,
        private valuesService: ValuesConstitutionService,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        combineLatest([
            this.authorisationService.getHasAccess(this.PurposeVisionRead),
            this.authorisationService.getHasAccess(this.ValuesRead),
        ]).pipe(
            map((hasAccess) => hasAccess.includes(true)),
            this.takeUntilDestroyed(),
        ).subscribe((hasAccess) => this.hasAccess = hasAccess);

        this.valuesService.getValuesConstitution().pipe(
            tap((vc) => this.valuesConstitution = vc),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.notifyActivated();
    }
}

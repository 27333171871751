import { Component, OnInit } from "@angular/core";
import { EventSeries } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { OAuthService } from "@org-common/lib/oauth/oauth.service";
import { IReviewRecurrencesMap } from "@org-common/lib/schedule/review-recurrences/review-recurrences.component";
import { ScheduleService } from "@org-common/lib/schedule/schedule.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { IWorkflowRunData, WorkflowRunDialogComponent } from "@org-common/lib/workflow/workflow-run-dialog/workflow-run-dialog.component";
import { combineLatest, forkJoin, lastValueFrom, switchMap, take } from "rxjs";
import { map, tap } from "rxjs/operators";
import { planYourStrategyCadenceWithCalendarIntegrationWorkflow, planYourStrategyCadenceWorkflow } from "../../establish-cadence-workflows";

@MeetingAgendaItemComponent("adapt-pys-workshop-set-cadence")
@Component({
    selector: "adapt-pys-workshop-set-cadence",
    templateUrl: "./pys-workshop-set-cadence.component.html",
})
export class PysWorkshopSetCadenceComponent implements OnInit {
    public recurrences?: IReviewRecurrencesMap;
    public loading = true;
    public readonly StewardshipWorkKanbanEdit = KanbanAuthService.EditAllStewardship;

    private eventTypePresets = [
        EventTypePreset.AnnualStrategy,
        EventTypePreset.QuarterlyStrategy,
        EventTypePreset.MonthlyStrategy,
        EventTypePreset.OKRCheckIn,
    ];

    public constructor(
        private dialogService: AdaptCommonDialogService,
        private scheduleService: ScheduleService,
        private teamsService: CommonTeamsService,
        private oauthService: OAuthService,
    ) {
    }

    public async ngOnInit() {
        await this.updateData();
    }

    @Autobind
    public setCadence() {
        // only show the Calendar Integration step if the user is not already authed with local
        return combineLatest([this.oauthService.isAuthenticated$, this.oauthService.authProvider$]).pipe(
            take(1),
            switchMap(([authed, provider]) => {
                const noIntegrationPromptForProviders = [CalendarIntegrationProvider.Local, CalendarIntegrationProvider.None];
                return this.dialogService.open(WorkflowRunDialogComponent, {
                    workflow: (provider && noIntegrationPromptForProviders.includes(provider.id)) || authed
                        ? planYourStrategyCadenceWorkflow
                        : planYourStrategyCadenceWithCalendarIntegrationWorkflow,
                } as IWorkflowRunData).pipe(
                    tap(() => this.updateData()),
                );
            }),
        );
    }

    private async updateData() {
        this.loading = true;

        const team = await this.teamsService.promiseToGetLeadershipTeam();
        if (!team) {
            throw new Error("Leadership team not found");
        }

        this.recurrences = await lastValueFrom(this.getCurrentRecurrencesMap(team));

        this.loading = false;
    }

    private getCurrentRecurrencesMap(team: Team) {
        return this.scheduleService.getEventTypesForTeam(team.teamId).pipe(
            // map eventTypes to their code so we can match them with the presets
            map((eventTypes) => new Map(eventTypes.map((e) => [e.code as EventTypePreset, e]))),
            switchMap((eventTypeMap) => {
                const currentEventSeriesByPreset = this.eventTypePresets
                    .filter((preset) => eventTypeMap.has(preset))
                    .map((preset) => this.scheduleService.getCurrentEventSeriesForEventType(eventTypeMap.get(preset)!).pipe(
                        map((series) => [preset, series]),
                    ));
                return forkJoin(currentEventSeriesByPreset);
            }),
            // filter out undefined event series
            map((presetEventSeries) => presetEventSeries.filter(([_, series]) => !!series) as [EventTypePreset, EventSeries][]),
            map((definedEventSeries) => new Map<EventTypePreset, EventSeries>(definedEventSeries)),
        );
    }
}

import { Component, Input, OnInit } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { KanbanService } from "@org-common/lib/kanban/kanban.service";
import { KanbanUiService } from "@org-common/lib/kanban/kanban-ui.service";
import { switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-configure-kanban-boards",
    styleUrls: ["./configure-kanban-boards.component.scss"],
    templateUrl: "./configure-kanban-boards.component.html",
})
export class ConfigureKanbanBoardsComponent extends BaseComponent implements OnInit {
    @Input() public team?: Team;
    @Input() public person?: Person;

    public boards: Board[] = [];

    public constructor(
        private kanbanService: KanbanService,
        private kanbanUiService: KanbanUiService,
    ) {
        super();
    }

    public ngOnInit() {
        this.refresh();
    }

    public refresh() {
        this.fetchBoards().pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    public addBoard() {
        const createBoard$ = this.person
            ? this.kanbanUiService.createBoardForPerson(this.person)
            : this.kanbanUiService.createBoardForTeam(this.team!);

        return createBoard$.pipe(
            switchMap(() => this.fetchBoards()),
        );
    }

    @Autobind
    public editBoard(board: Board) {
        return this.kanbanUiService.openEditBoardDialog(board);
    }

    @Autobind
    public removeBoard(board: Board) {
        return this.kanbanUiService.deleteBoard(board).pipe(
            switchMap(() => this.fetchBoards()),
        );
    }

    @Autobind
    public onItemReordered(e: IDxListItemReorderedEvent<Board>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.boards, "ordinal", e.fromIndex, e.toIndex);
    }

    @Autobind
    private fetchBoards() {
        // force refresh these upon change so that the boards will show after work enable.
        const fetchBoards$ = this.person
            ? this.kanbanService.getBoardsByPerson(this.person.personId, true)
            : this.kanbanService.getBoardsByTeam(this.team!.teamId, true);

        return fetchBoards$.pipe(
            tap((boards) => this.boards = boards),
        );
    }
}

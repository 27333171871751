import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { BreezeModelUtilities } from "@common/lib/data/breeze-model-utilities";
import { BaseActiveEntity } from "../base-active-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Board } from "./board";
import { IEntityWithLabelLocations } from "./entity-with-label-locations";
import { LabelLocation } from "./label-location";
import { Meeting } from "./meeting";
import { Organisation } from "./organisation";
import { Role } from "./role";
import { RoleConnection } from "./role-connection";
import { TeamLocation } from "./team-location";
import { TeamMeetingGuidance } from "./team-meeting-guidance";

export enum TeamType {
    Standard = "Standard",          /* aka intact / standing team */
    Leadership = "Leadership",
    // Project     /* not defining until we need/use
}

export class Team extends BaseActiveEntity<Team> implements IEntityWithLabelLocations {
    public teamId!: number;
    public organisationId!: number;
    public name!: string;
    public teamLeaderPersonId?: number;
    public startDate!: Date;
    public isPrivate!: boolean;
    public endDate?: Date;
    public parentTeamId?: number;
    public teamType!: TeamType;

    public boards!: Board[];
    public childTeams!: Team[];
    public meetings!: Meeting[];
    public organisation!: Organisation;
    public parentTeam?: Team;
    public roleConnections!: RoleConnection[];
    public roles!: Role[];
    public teamLeaderPerson!: Person;
    public teamLocations!: TeamLocation[];
    public teamMeetingGuidance!: TeamMeetingGuidance;
    public labelLocations!: LabelLocation[];

    public readonly iconClass = "fal fa-fw fa-users";

    public readonly isInactive = () => ActiveEntityUtilities.isInactive(this);
    public readonly isHistoric = () => ActiveEntityUtilities.isHistoric(this);
    public readonly isNotHistoric = () => ActiveEntityUtilities.isNotHistoric(this);

    public get rootTeam(): Team {
        if (!this.parentTeam) {
            return this;
        }

        // parent cannot be unprimed as we call getAllTeams on startup -> so only possibility is you don't have access to the parent
        // team -> will be shown as global?

        // if (this.parentTeamId == null) {
        //     return this;
        // }

        // if (!this.parentTeam) {
        //     throw new Error("Parent team not primed");
        // }

        return this.parentTeam.rootTeam;
    }
}

export const TeamBreezeModel = new BreezeModelBuilder("Team", Team)
    .hasSource()
    .orderBy("name")
    .isOrganisationEntity()
    .withIdField("teamId")
    .withLabelField("name")
    .withSortField("name")
    .withPropertyValidator("name", BreezeModelUtilities.NonEmptyStringPropertyValidator)
    .instanceIsActivePath()
    .build();

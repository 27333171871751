import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";

export enum OrganisationFeatureFlag {
    // Flag not being used anymore, however a string must be present in the enum for typescript to know its a string enum
    ActiveMeetingPage = "Active Meeting Page",
}

// functions called for each flag to check if they should be default enabled
export const OrganisationFeatureFlagDefaults: { [k in OrganisationFeatureFlag]?: () => boolean } = {
    [OrganisationFeatureFlag.ActiveMeetingPage]: () => AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto,
};

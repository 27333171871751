<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ResilientBusinessGoals"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div adaptToolbarContent
     *ngIf="isActivated && pageMenuItems[0]?.items?.length"
     adaptBlockingButtonGroup>
    <adapt-menu [items]="pageMenuItems"></adapt-menu>
</div>
<!-- @ViewChild is null; not undefined before view contents are loaded -->
<ng-container [ngTemplateOutlet]="mapComponent?.viewToggleButtonsTemplate ?? undefined"
              adaptToolbarContent
              alignment="center"></ng-container>

<adapt-strategy-board *ngIf="hasGoal || hasTheme; else strategicCta"
                      [expandGoals]="expandGoals"
                      [isEditing]="true"
                      [views]="views"
                      (viewsChange)="updateView($event)"></adapt-strategy-board>

<ng-template #strategicCta>
    <adapt-call-to-action leftColumnWidthClasses="col-xl-6">
        <adapt-call-to-action-title>Strategy board</adapt-call-to-action-title>

        <p>The strategy board is a working area to store the current state of your business. It is made up of these components:</p>
        <ul class="list-content">
            <li>Strategic themes</li>
            <li>Inputs (strengths, weaknesses, trends, bullseye, competitor analysis)</li>
        </ul>
        <br />

        <p>Strategic themes are a way to organise your thoughts around your vision into some kind of coherent framework -
            and understanding the inputs allows you to understand your current state and the market you are playing in.</p>

        <button adaptCallToActionButton
                *adaptIfAuthorised="EditStrategyBoard"
                adaptButtonIcon="add"
                [adaptBlockingClick]="addTheme">Add strategic theme</button>
    </adapt-call-to-action>
</ng-template>

import { AfterViewInit, Component, HostListener, Injector, OnInit } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Event } from "@common/ADAPT.Common.Model/organisation/event";
import { EventSeries } from "@common/ADAPT.Common.Model/organisation/event-series";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { IDxScrollViewEvent } from "@common/ux/dx.types";
import { IItem, ISection, TimeSchedulerPeriod6Months } from "@common/ux/time-scheduler/time-scheduler.interface";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ScheduleService } from "@org-common/lib/schedule/schedule.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { DxScrollViewComponent } from "devextreme-angular";
import moment from "moment";
import { forkJoin, map, of, switchMap } from "rxjs";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";
import { TeamMeetingsPageComponent } from "../team-meetings-page/team-meetings-page.component";

@Component({
    selector: "adapt-team-meetings-with-cadence-page",
    templateUrl: "./team-meetings-with-cadence-page.component.html",
    styleUrls: ["./team-meetings-with-cadence-page.component.scss"],
})
export class TeamMeetingsWithCadencePageComponent extends TeamMeetingsPageComponent implements OnInit, AfterViewInit {
    public sections: ISection[] = [];
    public items: IItem<any>[] = [];
    public schedulerStartTime = moment().startOf("month").hour(9);
    public selectedCadenceItem?: IItem;
    public defaultCadencePeriod = TimeSchedulerPeriod6Months;

    public cadenceCollapsed = false;
    public cadenceCollapseManualOverride = false;

    // hides the adapt-meeting-details overflow while the collapse is animating
    // otherwise we get double scrollbar during the animation
    public hideOverflow = false;

    private meetingDetailsComponent = new QueuedCaller<DxScrollViewComponent>();

    public constructor(
        teamsService: CommonTeamsService,
        meetingsService: MeetingsService,
        meetingsUiService: MeetingsUiService,
        rxjsBreezeService: RxjsBreezeService,
        injector: Injector,
        private scheduleService: ScheduleService,
    ) {
        super(teamsService, meetingsService, meetingsUiService, rxjsBreezeService, injector);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.meetingsFetched$.pipe(
            switchMap(() => this.scheduleService.getEventTypesForTeam(this.teamId!)),
            switchMap((eventTypes) => {
                if (eventTypes.length > 0) {
                    return forkJoin(eventTypes.map((eventType) => this.scheduleService.getCurrentEventSeriesForEventType(eventType)));
                } else {
                    return of([]);
                }
            }),
            map((eventSeriesArray) => eventSeriesArray.filter((i) => !!i) as EventSeries[]),
            this.takeUntilDestroyed(),
        ).subscribe((eventSeriesArray) => {
            this.sections = [];
            this.items = [];

            eventSeriesArray.forEach((series) => {
                this.sections.push(ScheduleService.SchedulerSectionFromEventType(series.eventType));
                this.items.push(...series.events.map(ScheduleService.SchedulerItemFromEvent));
            });

            this.sections.push(ScheduleService.SchedulerSectionForAdhocMeeting());
            this.items.push(...this.endedMeetings.filter((m) => !m.eventLocations.length).map(ScheduleService.SchedulerItemFromMeeting));
            this.items.push(...this.upcomingMeetings.filter((m) => !m.eventLocations.length).map(ScheduleService.SchedulerItemFromMeeting));

            setTimeout(() => this.updateMeetingDetailsScroll());
        });

        this.meetingListSelectionChanged$.pipe(
            map(() => {
                if (!this.selectedMeeting) {
                    this.selectedCadenceItem = undefined;
                    return;
                }

                this.selectedCadenceItem = this.items.find((item) => {
                    if (item && item.metadata && item.metadata.meeting) {
                        return item.metadata.meeting === this.selectedMeeting;
                    }

                    if (item && item.metadata && item.metadata.eventLocations && item.metadata.eventLocations.length > 0) {
                        return item.metadata?.eventLocations[0].meeting === this.selectedMeeting;
                    }

                    return false;
                });
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public ngAfterViewInit() {
        super.ngAfterViewInit();
        this.updateMeetingDetailsScroll();
    }

    public meetingDetailsScrollViewComponentInitialised(value: DxScrollViewComponent) {
        this.meetingDetailsComponent.setCallee(value);
    }

    @HostListener("window:resize")
    public updateMeetingDetailsScroll() {
        this.hideOverflow = false;
        setTimeout(() => {
            this.meetingDetailsComponent.call((scrollViewComponent) => scrollViewComponent.instance.update());
        });
    }

    public onCadenceCollapseChanged(collapsed: boolean) {
        this.cadenceCollapseManualOverride = true;
        this.cadenceCollapsed = collapsed;
    }

    public meetingDetailsScrolled(e: IDxScrollViewEvent) {
        if (e.reachedTop && !e.reachedBottom) { // if both reachedTop and reachedBottom - panel doesn't need scrolling
            this.cadenceCollapseManualOverride = false;
            if (this.cadenceCollapsed) {
                this.cadenceCollapsed = false;
                this.hideOverflow = true;
            }
        } else if (!e.reachedTop) {
            if (!this.cadenceCollapseManualOverride && !this.cadenceCollapsed) {
                this.cadenceCollapsed = true;
                this.hideOverflow = true;
            }
        }
    }

    public handleCadenceSelectionChange(e: IItem | undefined) {
        if (e) {
            if (e.metadata && e.metadata.eventLocations && e.metadata.eventLocations.length > 0) {
                this.selectedMeeting = (e.metadata! as Event)!.eventLocations[0].meeting;
                this.updateMeetingListSelection();
            } else if (e.metadata && e.metadata.meeting) {
                this.selectedMeeting = e.metadata.meeting;
                this.updateMeetingListSelection();
            }
        }
    }
}

export const TeamMeetingsWithCadencePageRoute = new OrganisationPageRouteBuilder<{ teamId: number }>()
    .usingNgComponent("adapt-team-meetings-with-cadence-page", TeamMeetingsWithCadencePageComponent)
    .atOrganisationUrl("/team/:teamId/meetings")
    .verifyingFeatures(FeatureName.StewardshipWorkMeetings)
    .withTitle("Meetings")
    .withSearchKeyword("Ongoing Meetings")
    .withSearchKeyword("Upcoming Meetings")
    .withSearchKeyword("Past Meetings")
    .reloadOnSearch(false)
    .build();


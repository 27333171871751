import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DurationSelector } from "@common/ux/duration-selector";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { KeyUpEvent, ValueChangedEvent } from "devextreme/ui/text_box";
import isEqual from "lodash.isequal";
import { from, of, skip, Subject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { ISearchOptions, SearchType } from "../search.interface";
import { searchGroupMapping, SearchService } from "../search.service";

@Component({
    selector: "adapt-search-tab-content",
    templateUrl: "./search-tab-content.component.html",
    styleUrls: ["./search-tab-content.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SearchTabContentComponent extends BaseComponent implements OnInit {
    public readonly DurationOptions = DurationSelector.DataSource;
    public readonly searchGroupMapping = searchGroupMapping;

    public isLoading = false;
    public accordionOpen = false;
    public refresh$ = new Subject<void>();

    public searchKeyword?: string;
    private searchKeywordUpdater = this.createThrottledUpdater<string | undefined>((keyword) => this.searchKeyword = keyword);

    public searchOptions?: ISearchOptions;
    public person: Person | null = null;
    public labels: Label[] = [];

    public constructor(
        private directoryService: DirectorySharedService,
        public searchService: SearchService,
        private orgService: OrganisationService,
    ) {
        super();
    }

    public ngOnInit() {
        this.searchService.isLoading$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((loading) => this.isLoading = loading);

        this.searchService.searchOptions$.pipe(
            this.takeUntilDestroyed(),
            switchMap((options) => {
                if (options.personId) {
                    return from(this.directoryService.promiseToGetPersonById(options.personId)).pipe(
                        tap((person: Person) => this.person = person),
                        map(() => options),
                    );
                } else {
                    this.person = null;
                }
                return of(options);
            }),
            tap((options) => {
                this.searchOptions = options;
                this.searchKeywordUpdater.next(options.keyword);
            }),
        ).subscribe();

        this.searchService.searchLabels$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((labels) => this.labels = labels);

        // only want to run this once the organisation has changed
        this.orgService.organisation$.pipe(
            skip(1),
        ).subscribe((newOrg) => {
            if (newOrg) {
                this.refresh$.next();
                this.resetTabContent();
            }
        });
    }

    public reset() {
        this.searchService.reset();
        this.resetTabContent();
    }

    private resetTabContent() {
        this.person = null;
        this.labels = [];
    }

    @Autobind
    public onSearchClicked() {
        this.searchService.clearResultCache();
        this.searchService.setKeyword(this.searchOptions?.keyword);
        this.searchService.showResults();
    }

    public hasType(types: Set<SearchType>, type: SearchType | SearchType[]) {
        return Array.isArray(type)
            ? type.every((t) => types.has(t))
            : types.has(type);
    }

    public onSelectAllClicked() {
        this.searchService.setTypes(searchGroupMapping.flatMap((type) => type.value));
    }

    public onClearClicked() {
        this.searchService.setTypes([]);
    }

    public onKeywordChanged(event: ValueChangedEvent) {
        if (event.value !== event.previousValue && event.value !== this.searchOptions?.keyword) {
            // make sure empty text gets stored as undefined instead to avoid double search query
            this.searchService.setKeyword(event.value || undefined);
            this.searchService.showResults();
        }
    }

    public onKeyUp(event: KeyUpEvent) {
        if ((event.event as unknown as JQuery.KeyPressEvent).key === "Enter") {
            this.onSearchClicked();
        }
    }

    public onLabelsChanged(labels: Label[]) {
        const currentLabelIds = this.labels.map((l) => l.labelId).sort();
        const incomingLabelIds = labels.map((l) => l.labelId).sort();

        // infinite loop if we don't check equality here.
        // sometimes the entities aren't exactly the same, so just compare labelIds instead
        if (!isEqual(currentLabelIds, incomingLabelIds)) {
            let labelIds: number[] = [];

            if (labels.length === 0) {
                this.labels = [];
            } else {
                labelIds = incomingLabelIds;
            }

            this.searchService.setSearchOptions({ labelIds: new Set<number>(labelIds) });
        }
    }

    public onPersonChange(person: Person | null) {
        this.person = person;
        this.searchService.setSearchOptions({ personId: person ? person.personId : undefined });
    }

    public onTeamChange(team: Team | null) {
        this.searchService.setSearchOptions({ teamId: team ? team.teamId : undefined });
    }

    public onUpdatedWithinChange(value: string) {
        const updatedSince = this.DurationOptions.find((i) => i.slug === value);
        this.searchService.setSearchOptions({ updatedSince });
    }
}

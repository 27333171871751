<div class="d-flex flex-column"
     *ngIf="survey">
    <div *ngIf="workflowCard; else notCard"
         class="d-flex flex-column gap-2">
        <ng-container *ngIf="surveyCompleted; else notCompletedCard">
            <a *ngIf="surveySummaryLink"
               [routerLink]="surveySummaryLink.path"
               [queryParams]="surveySummaryLink.queryParams">View survey summary</a>

            <adapt-progress-bar [percentageProgress]="participationRate"
                                [overrideText]="participationText"></adapt-progress-bar>

            <adapt-workflow-card-action [connection]="workflowConnection">
                <div action-content>Survey complete, please continue by clicking below</div>
            </adapt-workflow-card-action>
        </ng-container>
        <ng-template #notCompletedCard>
            <button class="btn btn-link"
                    (click)="viewOutstandingResponses()">Manage survey
            </button>

            <adapt-progress-bar [percentageProgress]="participationRate"
                                [overrideText]="participationText"></adapt-progress-bar>

            <adapt-workflow-card-action [connection]="workflowConnection"
                                        [actionText]="surveyLink ? 'Take survey now' : undefined"
                                        [actionIcon]="surveyLink ? 'fas fa-fw fa-arrow-right' : undefined"
                                        [action]="surveyLink ? takeSurvey : undefined">
                <div action-content>Survey will end {{survey.endTime | adaptDateTime}}</div>
            </adapt-workflow-card-action>
        </ng-template>
    </div>

    <ng-template #notCard>
        <ng-container *ngIf="surveyCompleted; else notCompleted">
            <p>The survey has been completed.
                <span *ngIf="surveySummaryLink">The result of the survey can be viewed <a [routerLink]="surveySummaryLink.path"
                                                                                          [queryParams]="surveySummaryLink.queryParams">here</a></span>.
                You are now ready to move on to next step.
            </p>
        </ng-container>
        <ng-template #notCompleted>
            <ng-container *ngIf="outstandingSurveyResponses.length > 0; else allCompleted">
                <div>
                    <h5>Outstanding responses</h5>
                    <div *ngFor="let response of outstandingSurveyResponses">
                        <adapt-link-person *ngIf="response.connection"
                                           [personId]="response.connection.personId"
                                           imageSize="xs"
                                           [showImage]="true"></adapt-link-person>
                    </div>
                </div>

                <div *ngIf="surveyLink"
                     class="py-3 border-bottom">
                    <button class="btn btn-secondary"
                            adaptButtonIcon="fal fa-clipboard-list-check"
                            (click)="takeSurvey()">Take survey now
                    </button>
                </div>

                <p class="mt-3">
                    <small>
                        The survey will end {{survey.endTime | adaptDateTime}}.
                        <br />
                        To send reminders or change the end date of the survey, you can manage the survey
                        <button class="btn btn-link"
                                (click)="viewOutstandingResponses()">here
                        </button>
                        .
                    </small>
                </p>
            </ng-container>
            <ng-template #allCompleted>
                <p>All survey responses have been submitted.</p>
            </ng-template>
        </ng-template>
    </ng-template>
</div>

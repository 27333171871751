import { Component, OnInit } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, RoleInOrganisation } from "@common/ADAPT.Common.Model/organisation/connection";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IAddPeopleBindingModel } from "@common/identity/add-people-binding-model.interface";
import { IdentityService } from "@common/identity/identity.service";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UserService } from "@common/user/user.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, catchError, lastValueFrom, map, of, switchMap } from "rxjs";
import { WorkflowIdentifier } from "../../workflow-identifier.enum";
import { Workflows } from "../../workflows";

@WorkflowStepComponent("adapt-invite-business-leaders-add-leadership-team")
@Component({
    selector: "adapt-invite-business-leaders-add-leadership-team",
    templateUrl: "./invite-business-leaders-add-leadership-team.component.html",
})
export class InviteBusinessLeadersAddLeadershipTeamComponent extends WorkflowStepComponentAdapter implements OnInit {
    public validationGroupValid = new BehaviorSubject<boolean>(false);
    public workflowStepCompleted = this.validationGroupValid.asObservable().pipe(
        switchMap((isValid) => isValid
            // if valid, cannot just use map((isValid) => isValid && this.hasOwner) as we will allow it if user tick the box
            ? this.skipOwnerTask.pipe(
                map((skipped) => skipped || this.hasOwner))
            : of(isValid)),
    );
    public skipOwnerTask = new BehaviorSubject<boolean>(false);
    public workflowStepSkipEnabled = this.skipOwnerTask.asObservable().pipe(
        map((skipped) => skipped || this.currentUserIsOwner),
    );

    public addPeopleModel?: IAddPeopleBindingModel;
    public organisation?: Organisation;
    public currentPerson: Person;
    public currentPersonConnection: Connection;
    public includeCustomMessage = false;

    private currentUserIsOwner = false;
    private hasOwnersInOrg = false;

    public constructor(
        private identityService: IdentityService,
        private commonTeamsService: CommonTeamsService,
        private commonDirectoryService: DirectorySharedService,
        private workflowService: WorkflowService,
        private orgService: OrganisationService,
        private commonDataService: CommonDataService,
        userService: UserService,
    ) {
        super();

        this.currentPerson = userService.currentPerson!;
        this.currentPersonConnection = this.currentPerson.getLatestConnection()!;
    }

    public get hasOwner() {
        return this.currentUserIsOwner ||
            this.hasOwnersInOrg ||
            !!this.addPeopleModel?.userDetails.some((userDetail) => userDetail.roleInOrganisation === RoleInOrganisation.OwnerLeader);
    }

    public async ngOnInit() {
        this.organisation = await lastValueFrom(this.orgService.getOrganisationWithSupplementaryData(this.orgService.getOrganisationId()));
        this.updateSkippable();
    }

    public async currentPersonRoleChanged(role: RoleInOrganisation) {
        this.currentPersonConnection.roleInOrganisation = role;
        await lastValueFrom(this.commonDataService.saveEntities(this.currentPersonConnection));
        this.updateSkippable();
    }

    public addPeopleBindingModelChanged(bindingModel: IAddPeopleBindingModel) {
        this.addPeopleModel = bindingModel;
        this.updateSkippable();
    }

    public postWorkflow() {
        // assign the understand journey workflow to the user so it shows in their active pathways
        const understandJourneyWorkflow = Workflows[WorkflowIdentifier.UnderstandJourney];
        return this.workflowService.createWorkflowConnectionForWorkflow(understandJourneyWorkflow).pipe(
            switchMap(() => this.hasOwner
                ? of(undefined)
                : this.skipOwnersPathways()));
    }

    public workflowStepNext() {
        return this.commonDataService.saveEntities(this.organisation).pipe(
            switchMap(async () => {
                await this.identityService.addPeople(this.addPeopleModel!);

                const leadershipTeam = await this.commonTeamsService.promiseToGetLeadershipTeam();

                // need to force get people and contacts since they will be cached by the platform already (and add people doesn't return entities)
                const allPeople = await this.commonDirectoryService.promiseToGetAllPeople(true);
                await this.commonDirectoryService.promiseToGetAllPersonContacts(true);

                for (const p of this.addPeopleModel!.userDetails) {
                    // don't add non leaders to the leadership team
                    if ((p.roleInOrganisation === RoleInOrganisation.Other)
                        || (p.roleInOrganisation === RoleInOrganisation.Employee)) {
                        continue;
                    }

                    const person = allPeople.find((i) => i.getLoginEmail()?.value === p.email);
                    if (person) {
                        await this.commonTeamsService.promiseToAddTeamMember(leadershipTeam, person.getLatestConnection()!, UserType.Leader);
                    }
                }
            }),
            switchMap(() => this.commonDataService.save()),
            catchError((error) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(error);
                this.workflowStepErrorMessage.next(errorMessage);
                throw error;
            }),
        );
    }

    private updateSkippable() {
        this.currentUserIsOwner = this.currentPersonConnection.roleInOrganisation === RoleInOrganisation.OwnerLeader;
        this.hasOwnersInOrg = this.organisation?.connections.some((c) => c.roleInOrganisation === RoleInOrganisation.OwnerLeader) ?? false;
        this.skipOwnerTask.next(false);
    }

    private async skipOwnersPathways() {
        // only complete 1 at a time as both workflows will enable PurposeAndVision feature - will be race condition if done in
        // a forkJoin
        await this.workflowService.completeWorkflow(Workflows[WorkflowIdentifier.DefineOwnersWhy]);
        await this.workflowService.completeWorkflow(Workflows[WorkflowIdentifier.AlignOwnershipVision]);
    }
}

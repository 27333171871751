import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxChartModule, DxDateBoxModule, DxNumberBoxModule, DxPopoverModule, DxScrollViewModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { AdaptStrategyModule } from "../strategy/strategy.module";
import { AdaptZoneMapModule } from "../zone-map/zone-map.module";
import { DisplayGoalDescriptionComponent } from "./display-goal-description/display-goal-description.component";
import { EditGoalDescriptionComponent } from "./edit-goal-description/edit-goal-description.component";
import { EditGoalLocationNameComponent } from "./edit-goal-location-name/edit-goal-location-name.component";
import { EditGoalMeasurementsComponent } from "./edit-goal-measurements/edit-goal-measurements.component";
import { EditGoalReviewComponent } from "./edit-goal-review/edit-goal-review.component";
import { EditMeasurementScoreDialogComponent } from "./edit-measurement-score-dialog/edit-measurement-score-dialog.component";
import { EditStrategicGoalBaseComponent } from "./edit-strategic-goal-base/edit-strategic-goal-base.component";
import { GoalMeasurementsGraphComponent } from "./goal-measurements-graph/goal-measurements-graph.component";
import { GoalStatusComponent } from "./goal-status/goal-status.component";
import { SelectGoalStatusComponent } from "./select-goal-status/select-goal-status.component";
import { StrategicGoalComponent } from "./strategic-goal/strategic-goal.component";


@NgModule({
    declarations: [
        EditStrategicGoalBaseComponent,
        GoalMeasurementsGraphComponent,
        StrategicGoalComponent,
        GoalStatusComponent,
        EditMeasurementScoreDialogComponent,
        SelectGoalStatusComponent,
        EditGoalLocationNameComponent,
        EditGoalMeasurementsComponent,
        EditGoalDescriptionComponent,
        EditGoalReviewComponent,
        DisplayGoalDescriptionComponent,
    ],
    imports: [
        CommonModule,
        AdaptCallToActionModule,
        AdaptImplementationKitModule,
        AdaptHtmlEditorModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptCommonDialogModule,
        AdaptLoadingSpinnerModule,
        AdaptEntityValidatorModule,
        AdaptStrategyModule,
        AdaptMenuModule,
        AdaptZoneMapModule,

        DxTextBoxModule,
        DxTextAreaModule,
        DxSelectBoxModule,
        DxNumberBoxModule,
        DxDateBoxModule,
        DxChartModule,
        DxScrollViewModule,
        DxPopoverModule,

        CollapseModule,
    ],
    exports: [
        StrategicGoalComponent,
    ],
})
export class AdaptStrategicGoalsModule {
    public readonly workflowStepComponents = [
        EditGoalLocationNameComponent,
        EditGoalMeasurementsComponent,
        EditGoalDescriptionComponent,
        EditGoalReviewComponent,
    ];
}

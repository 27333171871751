import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Event, EventBreezeModel } from "@common/ADAPT.Common.Model/organisation/event";
import { EventCadenceCycle, EventCadenceCycleBreezeModel } from "@common/ADAPT.Common.Model/organisation/event-cadence-cycle";
import { EventSeries, EventSeriesBreezeModel, EventSeriesType } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventType, EventTypeBreezeModel, EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Meeting, MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ServiceUri } from "@common/configuration/service-uri";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { IItem, ISection } from "@common/ux/time-scheduler/time-scheduler.interface";
import moment from "moment";
import { from, lastValueFrom, of, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { OrganisationService } from "../organisation/organisation.service";
import { CommonTeamsService } from "../teams/common-teams.service";
import { EventSeriesDefaults } from "./event-series-defaults";
import { ISetCadenceRunData } from "./schedule.interface";
import { IScheduledRecurrence } from "./schedule-recurrence/schedule-recurrence.interface";

@Injectable({
    providedIn: "root",
})
export class ScheduleService {
    constructor(
        private httpClient: HttpClient,
        private commonDataService: CommonDataService,
        private organisationService: OrganisationService,
        private teamsService: CommonTeamsService,
    ) {
    }

    public static SchedulerItemFromEvent(event: Event): IItem<Event> {
        const eventType = event.eventSeries.eventType;

        // call this just to make sure sectionIds are consistent
        const section = ScheduleService.SchedulerSectionFromEventType(eventType);
        return {
            sectionId: section.id,
            name: moment(event.startDate).format("ddd"),
            start: moment(event.startDate),
            end: moment(event.endDate),
            styles: eventType.colour
                ? `background-color: ${eventType.colour}`
                : undefined,
            metadata: event,
            disabled: event.eventLocations.length === 0,
        };
    }

    public static SchedulerItemFromMeeting(meeting: Meeting): IItem<any> {
        const section = ScheduleService.SchedulerSectionForAdhocMeeting();
        return {
            sectionId: section.id,
            name: moment(meeting.meetingDateTime).format("ddd"),
            start: moment(meeting.meetingDateTime),
            end: moment(meeting.endTime),
            styles: section.colour
                ? `background-color: ${section.colour}`
                : undefined,
            metadata: {
                label: meeting.name,
                meeting,
            },
            disabled: false,
        };
    }

    public static SchedulerSectionFromEventType(eventType: EventType): ISection {
        return {
            name: eventType.name,
            code: eventType.code,
            id: eventType.eventTypeId,
            colour: eventType.colour,
            visible: true,
        };
    }

    public static SchedulerSectionForAdhocMeeting(): ISection {
        return {
            name: "Adhoc Meeting",
            code: "AM",
            id: -999,
            colour: "var(--adapt-new-day-yellow-500)",
            visible: true,
        };
    }

    public static RecurrenceConfigToString({ eventSeries, config }: IScheduledRecurrence) {
        let output = "";

        if (eventSeries.eventSeriesType === EventSeriesType.Once) {
            // should only be one event
            const item = ArrayUtilities.getSingleFromArray(eventSeries.events);
            output += moment(item?.startDate).format("dddd, MMMM Do YYYY [at] h:mm a");
        } else {
            output = "every ";

            for (const field of config.fields) {
                const value = field.choices.find((c) => c.value === eventSeries[field.eventSeriesField]);
                if (value) {
                    if (field.prefix) {
                        output += field.prefix + " ";
                    }
                    output += value.text + " ";
                    if (field.suffix) {
                        output += field.suffix + " ";
                    }
                    output += " ";
                }
            }
        }

        if (eventSeries.location) {
            output += ` at ${eventSeries.location}`;
        }

        return output.trim();
    }

    public getAllEventTypes() {
        return this.commonDataService.getAll(EventTypeBreezeModel);
    }

    public getLatestEventAndSeriesForEventTypePreset(preset: EventTypePreset) {
        return from(this.teamsService.promiseToGetLeadershipTeam()).pipe(
            switchMap((team) => this.getEventTypeForTeam(preset, team.teamId)),
            switchMap((eventType) => {
                if (!eventType) {
                    return of(undefined);
                }
                return this.getCurrentEventSeriesForEventType(eventType);
            }),
            map((eventSeries) => {
                if (eventSeries) {
                    return { event: this.getLatestEventForEventSeries(eventSeries), series: eventSeries };
                }
            }),
        );
    }

    public getEventTypesForTeam(teamId: number) {
        const predicate = new MethodologyPredicate<EventType>("teamId", "==", teamId);
        return this.commonDataService.getWithOptions(EventTypeBreezeModel, this.getTeamEventTypesEncompassingKey(teamId), {
            predicate,
            navProperty: "meetingAgendaTemplate",
        });
    }

    public getEventTypeForTeam(code: string, teamId: number) {
        const predicate = new MethodologyPredicate<EventType>("teamId", "==", teamId)
            .and(new MethodologyPredicate<EventType>("code", "==", code));
        return this.commonDataService.getWithOptions(EventTypeBreezeModel, predicate.getKey(EventTypeBreezeModel.identifier), {
            predicate,
            encompassingKey: this.getTeamEventTypesEncompassingKey(teamId),
            navProperty: "meetingAgendaTemplate",
        }).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public getCurrentEventSeriesForEventType(eventType: EventType) {
        const predicate = new MethodologyPredicate<EventSeries>("eventTypeId", "==", eventType.eventTypeId);
        return this.commonDataService.getWithOptions(EventSeriesBreezeModel, predicate.getKey(EventSeriesBreezeModel.identifier), {
            predicate,
            orderBy: "startDate DESC",
            top: 1,
            navProperty: "events.eventLocations.meeting",
        }).pipe(
            map(ArrayUtilities.getSingleFromArray<EventSeries>),
        );
    }

    public getEventsInSeries(eventSeries: EventSeries) {
        const predicate = new MethodologyPredicate<Event>("eventSeriesId", "==", eventSeries.eventSeriesId);
        return this.commonDataService.getByPredicate(EventBreezeModel, predicate);
    }

    public getLatestEventForEventSeries(eventSeries: EventSeries) {
        return Array.from(eventSeries.events)
            .sort(SortUtilities.getSortByFieldFunction<Event>("sequenceId"))
            .find((e) => eventSeries.lastSequenceId === undefined || e.sequenceId === eventSeries.lastSequenceId);
    }

    public getLatestMeetingForEventSeries(eventSeries: EventSeries) {
        const currentEvent = this.getLatestEventForEventSeries(eventSeries);
        return currentEvent
            ? this.getMeetingForEvent(currentEvent)
            : undefined;

    }

    public getMeetingForEvent(event: Event) {
        return event.eventLocations.find((loc) => !!loc.meeting)?.meeting;
    }

    public createEventSeries(
        eventType: EventType,
        options?: Partial<EventSeries>,
    ) {
        return this.commonDataService.create(EventSeriesBreezeModel, {
            eventType,
            ...(options ?? {}),
        });
    }

    public getOrCreateEventCadenceCycle(options?: Partial<EventCadenceCycle>) {
        return this.getEventCadenceCycle().pipe(
            switchMap((cadenceCycle) => cadenceCycle
                ? of(cadenceCycle)
                : this.createEventCadenceCycle(options)),
        );
    }

    public getEventCadenceCycle() {
        return this.commonDataService.getById(EventCadenceCycleBreezeModel, this.organisationService.getOrganisationId());
    }

    public createEventCadenceCycle(options?: Partial<EventCadenceCycle>) {
        return this.commonDataService.create(EventCadenceCycleBreezeModel, {
            organisationId: this.organisationService.getOrganisationId(),
            ...(options ?? {}),
        });
    }

    public async getCadenceRunData(eventTypePresets: EventTypePreset[], team: Team, createRecurrences = true) {
        const runData = {
            scheduledPresets: new Map(),
            deletedEntities: [],
        } as ISetCadenceRunData;

        // can't do in Promise.all as its stateful and other event types need the startDate of the AS.
        for (const preset of eventTypePresets) {
            const recurrence = await this.getRecurrenceForEventTypePreset(preset, team, runData, createRecurrences);
            if (recurrence) {
                runData.scheduledPresets.set(recurrence.eventTypePreset!, recurrence);
            }
        }

        return runData;
    }

    private async getRecurrenceForEventTypePreset(eventTypePreset: EventTypePreset, team: Team, runData: ISetCadenceRunData, createRecurrences = true) {
        const eventType = await lastValueFrom(this.getEventTypeForTeam(eventTypePreset, team.teamId));
        if (!eventType) {
            throw new Error(`Event type ${eventTypePreset} not found for teamId=${team.teamId}`);
        }

        const cadenceCycle = await lastValueFrom(this.getEventCadenceCycle());
        if (!cadenceCycle) {
            throw new Error("EventCadenceCycle must exist to continue setting cadence");
        }

        const today = new Date();
        const cadenceStartDate = moment(DateUtilities.getFirstWorkingDay(today.getFullYear(), today.getMonth(), today.getDate()))
            .hour(9)
            .minute(0)
            .toDate();

        // always use the AS startDate for calculating the cycle endDate
        // if this is the first preset calculation, we need to default to the same cadenceStartDate, as the scheduledPresets map won't have the AS preset yet.
        const annualStartDate = runData.scheduledPresets.get(EventTypePreset.AnnualStrategy)?.eventSeries.startDate ?? cadenceStartDate;
        const endDate = cadenceCycle.extensions.getEndDate(annualStartDate);

        // already have a configuration for this preset, use that
        const configuredSeries = runData.scheduledPresets.get(eventTypePreset);
        if (configuredSeries) {
            return configuredSeries;
        }

        // the date to start generating events from
        const startDate = EventSeriesDefaults[eventTypePreset]?.getStartDate?.(runData.scheduledPresets) ?? cadenceStartDate;

        return lastValueFrom(this.getCurrentEventSeriesForEventType(eventType).pipe(
            switchMap((eventSeries) => {
                if (eventSeries) {
                    return of(this.getRecurrenceForEventSeries(eventTypePreset, eventSeries, startDate, endDate));
                }

                return createRecurrences
                    ? this.createRecurrenceForEventTypePreset(eventTypePreset, eventType, startDate, endDate)
                    : of(undefined);
            }),
        ));
    }

    private getRecurrenceForEventSeries(eventTypePreset: EventTypePreset, eventSeries: EventSeries, startDate?: Date, endDate?: Date) {
        const scheduleDefaults = EventSeriesDefaults[eventTypePreset];
        // we only want to update the startDate if the eventSeries has not been saved yet
        if (startDate && (eventSeries.entityAspect.entityState.isAdded() || eventSeries.entityAspect.entityState.isModified())) {
            eventSeries.startDate = startDate;
            eventSeries.endDate = endDate ?? eventSeries.endDate;
            eventSeries.month = startDate.getMonth() + 1; // month is indexed from 1
        }
        return {
            eventTypePreset,
            eventSeries,
            config: { ...scheduleDefaults },
        } as IScheduledRecurrence;
    }

    private createRecurrenceForEventTypePreset(eventTypePreset: EventTypePreset, eventType: EventType, startDate: Date, endDate: Date) {
        const scheduleDefaults = EventSeriesDefaults[eventTypePreset];

        return this.createEventSeries(eventType, {
            month: startDate.getMonth() + 1, // month is indexed from 1
            ...scheduleDefaults.eventSeriesDefaults,
            startDate,
            endDate,
        }).pipe(
            map((eventSeries) => ({
                eventTypePreset,
                eventSeries,
                config: { ...scheduleDefaults },
            } as IScheduledRecurrence)),
        );
    }

    public async promiseToClearConfiguredCadences(runData: ISetCadenceRunData, skipPreset?: EventTypePreset) {
        const presets = Array.from(runData.scheduledPresets.values())
            .filter(({ eventTypePreset }) => eventTypePreset !== skipPreset);

        // remove the series and its events
        const removePromises = presets.map(async (config) => {
            const deletedEntities = await this.promiseToDeleteEventSeries(config.eventSeries);
            config.deletedEntities = (config.deletedEntities ?? []).concat(deletedEntities);
            return config;
        });
        const configs = await Promise.all(removePromises);

        // remove the deleted configs from the workflow runData, will be recreated when going next step
        configs.forEach((config) => {
            // need to store the deletedEntities in the runData else we'll lose references to them after deleting the preset
            runData.deletedEntities = runData.deletedEntities.concat(config.deletedEntities ?? []);
            runData.scheduledPresets.delete(config.eventTypePreset!);
        });
    }

    public async promiseToDeleteEventSeries(eventSeries: EventSeries) {
        const removedEntities: IBreezeEntity[] = [];

        const entities = await this.promiseToDeleteEventsInSeries(eventSeries);
        removedEntities.push(...entities);

        await lastValueFrom(this.commonDataService.remove(eventSeries));
        removedEntities.push(eventSeries);

        return removedEntities;
    }

    public async promiseToDeleteEventsInSeries(eventSeries: EventSeries) {
        const removedEntities: IBreezeEntity[] = [];

        // make a copy of the array as the length changes when we remove events
        const events = Array.from(eventSeries.events);
        for (const event of events) {
            // need to remove meeting before the event itself else the nav prop will be gone
            for (const eventLocation of event.eventLocations) {
                const meeting = eventLocation.meeting;
                // remove attached meeting so it can be recreated by processEventSeries
                // but only delete meeting if it hasn't occurred yet.
                if (meeting) {
                    if (meeting.status === MeetingStatus.NotStarted) {
                        await lastValueFrom(this.commonDataService.remove(meeting));
                        ArrayUtilities.addElementIfNotAlreadyExists(removedEntities, meeting);
                        // TODO: send cancellation invites?
                    } else {
                        // unlink the event from the completed meeting
                        await lastValueFrom(this.commonDataService.remove(eventLocation));
                    }
                }

                // don't need to delete the eventLocation explicitly, will be deleted by the meeting
                // however we do need to track it for the save
                ArrayUtilities.addElementIfNotAlreadyExists(removedEntities, eventLocation);
            }

            await lastValueFrom(this.commonDataService.remove(event));
            ArrayUtilities.addElementIfNotAlreadyExists(removedEntities, event);
        }

        return removedEntities;
    }

    public async promiseToCreateOrUpdateEventsForSchedule(recurrence: IScheduledRecurrence, potentialConflicts?: Event[]) {
        const { eventSeries } = recurrence;

        // eventSeries didn't change, don't modify events
        if (eventSeries.entityAspect.entityState.isUnchanged()) {
            return eventSeries;
        }

        const nextTimes = eventSeries.extensions.getNextTimes(eventSeries.startDate, eventSeries.endDate)
            // skip potential conflict when month&year are the same
            .filter((date) => !potentialConflicts?.some(({ startDate }) => startDate.getMonth() === date.getMonth() && startDate.getFullYear() === date.getFullYear()));

        const eventDates = eventSeries.events.map((e) => e.startDate);

        // if any date in next times does not exist in the current events
        const datesChanged = eventDates.length !== nextTimes.length
            || nextTimes.some((date) => !eventDates.find((d) => d.getTime() === date.getTime()));

        // dates haven't changed so no work to do here
        if (!datesChanged) {
            return eventSeries;
        }

        if (eventSeries.entityAspect.entityState.isAdded()) {
            // events haven't been saved yet, we can clear them without issue
            await lastValueFrom(this.commonDataService.rejectChanges(eventSeries.events));
        } else if (eventSeries.entityAspect.entityState.isModified()) {
            const removedEntities: IBreezeEntity[] = [];

            // remove existing events
            if (eventSeries.events.length > 0 || nextTimes.length === 0) {
                const entities = await this.promiseToDeleteEventsInSeries(eventSeries);
                removedEntities.push(...entities);
            }

            // track deletedEntities in the recurrence so we can save them
            recurrence.deletedEntities = (recurrence.deletedEntities ?? []).concat(removedEntities);
        }

        const events = await Promise.all(nextTimes.length > 0
            ? nextTimes.map((startDate, i) => lastValueFrom(this.createEvent(eventSeries, startDate, i)))
            : []);

        if (events.length > 0) {
            // const lastEvent = events[events.length - 1];
            // eventSeries.endDate = lastEvent.endDate;
            // TODO: do we need to keep the previous sequenceId so we don't generate meetings for events that have occurred already?
            eventSeries.lastSequenceId = undefined;
        }

        return eventSeries;
    }

    // Creates necessary meetings for the given event series
    public processEventSeries(eventSeries: EventSeries) {
        return this.httpClient.post<number[]>(
            `${ServiceUri.MethodologyServicesServiceBaseUri}/ProcessEventSeries`,
            null,
            { params: { eventSeriesId: eventSeries.eventSeriesId, organisationId: eventSeries.eventType.organisationId } },
        );
    }

    private createEvent(eventSeries: EventSeries, startDate: Date, sequenceId: number) {
        return this.commonDataService.create(EventBreezeModel, {
            organisationId: this.organisationService.getOrganisationId(),
            eventSeries,
            label: eventSeries.eventType.meetingAgendaTemplate?.name ?? eventSeries.eventType.name,
            sequenceId,
        }).pipe(
            map((event) => this.updateEventFromEventSeries(event, eventSeries, startDate)),
        );
    }

    private updateEventFromEventSeries(event: Event, eventSeries: EventSeries, startDate?: Date) {
        event.eventSeries = eventSeries;
        event.location = eventSeries.location;
        event.calendarIntegrationLocationId = eventSeries.calendarIntegrationLocationId;

        if (startDate) {
            event.startDate = startDate;
            event.endDate = moment(event.startDate)
                .add(eventSeries.eventType.durationInMinutes, "minutes")
                .toDate();
        }

        return event;
    }

    private getTeamEventTypesEncompassingKey(teamId: number) {
        return `eventTypesForTeamId=${teamId}`;
    }
}

import { Injectable } from "@angular/core";
import { IdentityService } from "@common/identity/identity.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { Logger } from "@common/lib/logger/logger";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { IStorageUriParams, IStoreResponseType, StorageService } from "@common/storage/storage.service";
import dxFileUploader, { dxFileUploaderOptions } from "devextreme/ui/file_uploader";
import { DocumentDescriptor } from "../document-descriptor";
import { DocumentSelectorService } from "../document-selector.service";
import { ISetupComponentParameters, IStorageProvider, StorageProviderGroup } from "./storage-provider.interface";
import { StorageProviderUtilities } from "./storage-provider-utilities";

interface IOpenedChooserInfo {
    resolve: (document: DocumentDescriptor | null) => void;
    reject: (e: any) => void;
}

// we have extracted these out from the service due to an interaction with Angular 15.1 (https://github.com/angular/angular/issues/48764) and TS (https://github.com/microsoft/TypeScript/issues/52004)
const Megabytes = 1048576;

@Injectable()
export class FileUploaderProviderService implements IStorageProvider {
    public static readonly ServiceName = "UploadFile";
    public static fileIdentifier: string = "_adaptfile";

    // This max value should be consistent with webapi. Web.Config: requestLimits maxAllowedContentLength
    private static readonly MaxAllowedFileSize = 50 * Megabytes;

    public disabled: boolean = false;
    private log = Logger.getLogger("FileUploaderProviderService");

    // can have multiple instances of select-document.component identified by the instance id
    private fileUploading: { [id: number]: boolean } = {};
    // need this to set uploadUrl to replace as we can only open file dialog within user events and need to set url
    // before that - can't use bindingOptions!
    private dxFileUploaderInstance: { [id: number]: dxFileUploader } = {};
    // there can be only 1 opened chooser at any time
    // - chooser opened from dxSelectBox -> when chooser opened, new modal window is spawned
    // Cannot open another one unless the modal window is closed
    private openedChooserInfo?: IOpenedChooserInfo = undefined;
    private accessToken?: string = undefined;

    constructor(
        private documentSelectorService: DocumentSelectorService,
        private storageFactory: StorageService,
        private identityService: IdentityService,
    ) {
    }

    public setupComponent(params: ISetupComponentParameters) {
        const componentInstanceId = params.componentInstanceId;
        const self = this;
        let addedDiv: JQuery;

        this.identityService.promiseToGetAccessToken()
            .then((token) => this.accessToken = token);

        if (!params.isReadOnly && !self.dxFileUploaderInstance[componentInstanceId]) {
            // CM-5081 DX 21.1 has screwed up types - remove the prefix
            const fileUploaderSettings: dxFileUploaderOptions = {
                uploadMethod: "POST",
                onUploadStarted,
                onUploaded: onFileUploaded,
                onUploadAborted,
                onUploadError,
                onInitialized,
            };

            addedDiv = $("<div></div>");
            new dxFileUploader(addedDiv[0], fileUploaderSettings);
            params.setEditorDomElement(addedDiv[0]);

            addedDiv.hide(); // hide the added div
            // also hide this dx input wrapper to prevent another popup while uploading or overlapping drag/drop that
            // messed up my cancel/X dialog detection
            addedDiv.find(".dx-fileuploader-input-wrapper").hide();
        }

        return Promise.resolve();

        function onInitialized(e: any) {
            self.dxFileUploaderInstance[componentInstanceId] = e.component;
        }

        function onUploadStarted(e: any) {
            if (e && ObjectUtilities.isObject(e.file) && e.file.size >= FileUploaderProviderService.MaxAllowedFileSize) {
                let errorMessage = e.file.name + " has a size of " + (e.file.size / Megabytes).toFixed(2)
                    + "MB and is larger than the allowed limit of "
                    + (FileUploaderProviderService.MaxAllowedFileSize / Megabytes).toFixed(0) + "MB.";

                if (StringUtilities.isString(e.file.name) && isPowerpointFile(e.file.name.toLowerCase())) {
                    errorMessage += " Refer to: <a href=\"https://www.wikihow.com/Reduce-Powerpoint-File-Size\" target=\"_blank\">How to reduce Powerpoint file size</a>.";
                }

                rejectUpload(errorMessage);

                if (ObjectUtilities.isObject(e.request) && FunctionUtilities.isFunction(e.request.abort)) {
                    e.request.abort();
                } else {
                    self.log.error("Cannot access abort function from request.");
                }

                return;
            }

            // disabling the component buttons to block selection while file is uploading
            params.disableSelection();

            // need this to abort the upload (e.g. between the gap of upload completed and response available which we
            // won't get abort event from dx) this is not available before upload starts
            addedDiv.find(".dx-fileuploader-cancel-button").click(onUploadAborted);

            self.fileUploading[componentInstanceId] = true;

            if (addedDiv) {
                addedDiv.show();
            }

            function isPowerpointFile(lowercaseFile: string): boolean {
                // relevant extensions from:
                // https://support.office.com/en-us/article/file-formats-that-are-supported-in-powerpoint-252c6fa0-a4bc-41be-ac82-b77c9773f9dc
                const powerpointExts = [".ppt", ".pptx", ".pptm", ".pot", ".potx", ".potm", ".pps", ".ppsx", ".ppsm", ".ppa", ".ppam"];
                return powerpointExts.some((ext) => lowercaseFile.endsWith(ext));
            }
        }

        function onFileUploaded(e: any) {
            if (self.openedChooserInfo) {
                if (ObjectUtilities.isObject(e.request)
                    && StringUtilities.isString(e.request.response)
                    && ObjectUtilities.isObject(e.file)
                ) {
                    const fileName = e.file.name;
                    const response = JSON.parse(e.request.response) as IStoreResponseType;
                    if (response.link) {
                        let accessLink = response.link;
                        accessLink = StorageProviderUtilities.addUrlServiceNameIdentifier(accessLink, self);
                        accessLink = UrlUtilities.setQueryParam(accessLink, FileUploaderProviderService.fileIdentifier, fileName);
                        self.openedChooserInfo.resolve(new DocumentDescriptor(fileName, accessLink));
                    } else {
                        self.openedChooserInfo.reject(
                            "Unexpected response from uploading file to StorageController. Expected property 'link' not found from "
                            + e.request.response);
                    }
                } else {
                    self.openedChooserInfo.reject(null);
                }

                delete self.openedChooserInfo;

                if (addedDiv) {
                    addedDiv.hide();
                }
            }
        }

        function onUploadAborted() {
            rejectUpload(null);
        }

        function onUploadError(e: any) {
            rejectUpload("File upload error: " + e.request.responseText);
        }

        function rejectUpload(message: string | null) {
            if (addedDiv) {
                addedDiv.hide();
            }

            if (self.openedChooserInfo) {
                self.openedChooserInfo.reject(message);
                delete self.openedChooserInfo;
            }
        }
    }

    public cleanupComponent(componentInstanceId: number): void {
        if (this.fileUploading[componentInstanceId]) {
            delete this.fileUploading[componentInstanceId];
        }

        if (this.dxFileUploaderInstance[componentInstanceId]) {
            delete this.dxFileUploaderInstance[componentInstanceId];
        }
    }

    public onOrganisationChanged(): void { /* Nothing to do */ }

    public getName(): string {
        return FileUploaderProviderService.ServiceName;
    }

    public getDisplayName(): string {
        return "Upload File";
    }

    public getGroupName = () => StorageProviderGroup.LOCAL;

    public getIconClass(): string {
        return "fal fa-fw fa-upload";
    }

    public getSelectionInProgressText(): string {
        return "Please wait for the file to be uploaded.";
    }

    public getDocument(url: string): DocumentDescriptor | null {
        if (StringUtilities.isString(url)) {
            if (StorageProviderUtilities.getUrlServiceNameIdentifier(url) === FileUploaderProviderService.ServiceName) {
                const name = UrlUtilities.getQueryParamValue(url, FileUploaderProviderService.fileIdentifier);

                if (name) {
                    const result = new DocumentDescriptor(decodeURIComponent(name), url);

                    result.setIconClass(this.getIconClass());
                    return result;
                }
            }
        }

        return null;
    }

    @Autobind
    public openChooser(componentInstanceId: number, data?: DocumentDescriptor): Promise<DocumentDescriptor | null> {
        return new Promise((resolve, reject) => this.doChoose(componentInstanceId, data, resolve, reject));
    }

    private doChoose(componentInstanceId: number, data: DocumentDescriptor | undefined, resolve: (document: DocumentDescriptor | null) => void, reject: (e: any) => void) {
        if (this.dxFileUploaderInstance[componentInstanceId]) {
            // setting this every time the chooser is opened as we may be toggling between different storage types
            // - can be previously replacing, then selecting other storage type and input data no longer corresponding
            //   to file uploader type, which in this case replaceUri would fail -> need to store instead.
            let uploadUrl = this.storageFactory.storeFileUri({
                ownerId: this.documentSelectorService.getOrganisationId(),
            } as IStorageUriParams);

            if (data) {
                const url = data.getUrl();
                if (url && StorageProviderUtilities.getUrlServiceNameIdentifier(url) === FileUploaderProviderService.ServiceName
                    && UrlUtilities.getQueryParamValue(url, "itemId")) {
                    // replacing existing item rather keep inserting new one if existing selection has itemId of type
                    // file-uploader
                    uploadUrl = this.storageFactory.replaceFileUri({
                        itemId: UrlUtilities.getQueryParamValue(url, "itemId"),
                        ownerId: this.documentSelectorService.getOrganisationId(),
                    } as IStorageUriParams);
                }
            }
            this.openedChooserInfo = {
                resolve,
                reject,
            };
            this.fileUploading[componentInstanceId] = false;
            this.dxFileUploaderInstance[componentInstanceId].option("uploadUrl", uploadUrl);
            this.dxFileUploaderInstance[componentInstanceId].option("uploadHeaders", {
                Accept: "application/json, text/plain, */*",
                Authorization: "Bearer " + this.accessToken,
            });

            // From
            // https://www.devexpress.com/Support/Center/Question/Details/T292191/dxfileupload-how-to-simulate-the-click-action
            // Undocumented feature though supplied by devExpress support.
            (this.dxFileUploaderInstance[componentInstanceId] as any)._isCustomClickEvent = true;
            (this.dxFileUploaderInstance[componentInstanceId] as any)._$fileInput.click();
        } else {
            reject(null);
        }
    }
}

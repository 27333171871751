import { Validator } from "breeze-client";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { IEntityWithLabelLocations } from "./entity-with-label-locations";
import { LabelLocation } from "./label-location";
import { ProcessMap } from "./process-map";
import { ProcessStepExtensions } from "./process-step-extensions";
import { ProcessStepSupplementaryData } from "./process-step-supplementary-data";
import { ProcessStepType } from "./process-step-type";
import { Role } from "./role";

export class ProcessStep extends BaseEntity<ProcessStep> implements IEntityWithLabelLocations {
    public processStepId!: number;
    public name!: string;
    public processMapId!: number;
    public type!: ProcessStepType;
    public parentStepId!: number | null;
    public parentStepOrdinal!: number;
    public lastUpdated!: Date;
    public roleId!: number | null;
    public linkedProcessMapId!: number | null;

    public processMap!: ProcessMap | null;
    public supplementaryData!: ProcessStepSupplementaryData | null;
    public parentStep!: ProcessStep | null;
    public role!: Role | null;
    public linkedProcessMap!: ProcessMap | null;
    public childSteps!: ProcessStep[];
    public labelLocations!: LabelLocation[];

    @EntityExtensions(ProcessStepExtensions)
    public extensions!: ProcessStepExtensions;
}

export const validLinkStepValidator = new Validator(
    "validLinkStepValidator",
    (processStep: ProcessStep) => !processStep.extensions.isProcessMapLink || !!processStep.linkedProcessMapId,
    { messageTemplate: "Linked Process Map is required" },
);

export const ProcessStepBreezeModel = new BreezeModelBuilder("ProcessStep", ProcessStep)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .withEntityValidator(validLinkStepValidator)
    .build();

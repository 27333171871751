import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDashboardElementHqModule } from "@common/ux/dashboard-element-hq/dashboard-element-hq.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptIncompleteWorkModule } from "@common/ux/incomplete-work/incomplete-work.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { DxCheckBoxModule, DxListModule, DxSelectBoxModule, DxSortableModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { ConfigureTeamStewardshipComponent } from "./configure-team-stewardship/configure-team-stewardship.component";
import { ConfigureTeamStewardshipDialogComponent } from "./configure-team-stewardship-dialog/configure-team-stewardship-dialog.component";
import { TeamMeetingGuidanceDashboardElementComponent } from "./team-meeting-guidance-dashboard-element/team-meeting-guidance-dashboard-element.component";
import { TeamMembershipComponent } from "./team-membership/team-membership.component";
import { TeamPeopleDashboardElementComponent } from "./team-people-dashboard-element/team-people-dashboard-element.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptLoadingSpinnerModule,
        AdaptCommonDialogModule,
        AdaptDirectorySharedModule,
        AdaptIncompleteWorkModule,
        AdaptFeaturesModule,
        AdaptLoadingSpinnerModule,
        AdaptShellModule,
        AdaptAuthorisationModule,
        AdaptTeamDashboardSharedModule,
        DxSortableModule,
        DxSelectBoxModule,
        DxListModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        AdaptHtmlEditorModule,
        AdaptEntityValidatorModule,
        AdaptMenuModule,
        AdaptDashboardElementModule,
        AdaptCallToActionModule,
        AdaptImplementationKitModule,
        AdaptDashboardElementHqModule,
        AdaptFeatureModule,
    ],
    exports: [
        TeamMembershipComponent,
        TeamPeopleDashboardElementComponent,
        TeamMeetingGuidanceDashboardElementComponent,
        ConfigureTeamStewardshipDialogComponent,
    ],
    declarations: [
        TeamMembershipComponent,
        TeamPeopleDashboardElementComponent,
        TeamMeetingGuidanceDashboardElementComponent,
        ConfigureTeamStewardshipDialogComponent,
        ConfigureTeamStewardshipComponent,
    ],
})

export class AdaptCommonTeamsModule {
    constructor(authorisationService: AuthorisationService) {
        CommonTeamsAuthService.registerAccess(authorisationService);
    }
}


import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { TourIdentifier } from "../../tours/tour-identifier.enum";
import { WorkflowIdentifier } from "../workflow-identifier.enum";
import { organisationDiagnosticWorkflowName, organisationHealthCheckWorkflow } from "./organisation-diagnostic-workflow";


export const onboardingOrganisationDiagnosticWorkflow = buildLocalWorkflow({
    name: organisationDiagnosticWorkflowName,
    workflowId: WorkflowIdentifier.UnderstandJourney,
    type: WorkflowType.Journey,
    iconStyle: "fal fa-compass",
    overview: `Run a health check survey on your business to determine your current state. Examine the results in the platform, and get your team to commit to follow the adapt way.`,
    articleSlug: ImplementationKitArticle.UnderstandJourneyOutcomes,
    wrapUpSlug: ImplementationKitArticle.UnderstandJourneyWrapUp,
    wrapUpGuidedTourIdentifier: TourIdentifier.HealthCheckTour,
    scope: WorkflowScope.Team,
    featuresToEnable: [FeatureName.OrganisationDiagnostic],
    category: OrganisationCategoryValue.Onboarding,
    time: "3 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    workflows: [
        ...organisationHealthCheckWorkflow.workflows!,
    ],
});

<dx-chart [dataSource]="chartData"
          (onDone)="updateChartDimensionAndSubscribeSizeChange($event)">
    <dxo-animation [enabled]="false"></dxo-animation>
    <dxi-series type="bar"
                axis="ResponseCount"
                valueField="count"
                argumentField="label"
                name="Response Distribution">
    </dxi-series>
    <dxi-value-axis name="ResponseCount"
                    [allowDecimals]="false"
                    [valueMarginsEnabled]="false"
                    [showZero]="true"
                    [visualRange]="[0, maxResponseCount]"
                    visualRangeUpdateMode="keep">
        <dxo-title text="Response Count"></dxo-title>
    </dxi-value-axis>
    <dxo-argument-axis>
        <dxo-title text="Response Distribution"></dxo-title>
        <dxo-label overlappingBehavior="stagger"></dxo-label>
    </dxo-argument-axis>
    <dxo-tooltip [enabled]="true"
                 [customizeTooltip]="customiseChartTooltip"></dxo-tooltip>
    <dxo-legend [visible]="false"></dxo-legend>
</dx-chart>

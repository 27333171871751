import { Injectable, Injector } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Input, InputBreezeModel, InputType } from "@common/ADAPT.Common.Model/organisation/input";
import { InputGroup, InputGroupBreezeModel, InputGroupType } from "@common/ADAPT.Common.Model/organisation/input-group";
import { InputLocation, InputLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/input-location";
import { InputTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { CanvasType, InputsCanvas, InputsCanvasBreezeModel } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import { map, Subject, switchMap, tap } from "rxjs";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { StrategicViewIcon } from "../strategy/strategy-view-constants";
import { AttachInputsDialogComponent, IAttachInputsDialogData } from "./attach-inputs-dialog/attach-inputs-dialog.component";
import { EditInputDialogComponent } from "./edit-input-dialog/edit-input-dialog.component";
import { EditInputGroupDialogComponent } from "./edit-input-group-dialog/edit-input-group-dialog.component";

@Injectable({
    providedIn: "root",
})
export class StrategicInputsService extends BaseOrganisationService {
    private inputsAttachmentChanged = new Subject<CanvasType | undefined>();

    public constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
    }

    public get inputsAttachmentChanged$() {
        return this.inputsAttachmentChanged.asObservable();
    }

    public getAttachSWTInputMenuItem(zone?: Zone, theme?: Theme) {
        return this.getAttachInputMenuItem(
            "Attach SWT Inputs",
            CanvasType.StrengthsWeaknessesTrends,
            StrategicViewIcon.InputsCanvasIcon,
            zone,
            theme,
        );
    }

    public getAttachCompetitorAnalysisInputMenuItem(zone?: Zone, theme?: Theme) {
        return this.getAttachInputMenuItem(
            "Attach Competitor Analysis Inputs",
            CanvasType.CompetitorAnalysis,
            StrategicViewIcon.CompetitorAnalysisIcon,
            zone,
            theme,
        );
    }

    private getAttachInputMenuItem(text: string, canvasType: CanvasType, iconClasses: string, zone?: Zone, theme?: Theme) {
        return {
            text,
            icon: iconClasses,
            onClick: () => this.attachInput(canvasType, zone, theme).pipe(
                tap((inputLocations) => this.inputsAttachmentChanged.next(inputLocations[0]?.input?.canvas?.type)), // so many ? to handle deletion as well
            ).subscribe(),
        } as IAdaptMenuItem;
    }

    public getCanvasById(canvasId: number) {
        return this.commonDataService.getById(InputsCanvasBreezeModel, canvasId);
    }

    public getLatestCanvas() {
        // excluding CA as that will only have a single canvas and will be shown in a separate page
        const predicate = new MethodologyPredicate<InputsCanvas>("type", "!=", CanvasType.CompetitorAnalysis);
        const options: IBreezeQueryOptions = {
            predicate,
            top: 1,
            orderBy: "canvasDate DESC",
            navProperty: "inputs",
        };
        return this.commonDataService.getWithOptions(
            InputsCanvasBreezeModel,
            `top1Latest${InputsCanvasBreezeModel.identifier}`,
            options,
        ).pipe(
            map((results) => ArrayUtilities.getSingleFromArray(results)),
        );
    }

    public getLatestCanvasOfType(canvasType: CanvasType) {
        const predicate = new MethodologyPredicate<InputsCanvas>("type", "==", canvasType);
        const options: IBreezeQueryOptions = {
            predicate,
            top: 1,
            orderBy: "canvasDate DESC",
            navProperty: "inputs",
        };
        return this.commonDataService.getWithOptions(
            InputsCanvasBreezeModel,
            predicate.getKey(`top1${InputsCanvasBreezeModel.identifier}`),
            options,
        ).pipe(
            map((results) => ArrayUtilities.getSingleFromArray(results)),
        );
    }

    public getCanvasesOfType(canvasType: CanvasType) {
        return this.getAllCanvases().pipe(
            map((canvases) => canvases.filter((canvas) => canvas.type === canvasType)),
        );
    }

    public getAllCanvases() {
        return this.commonDataService.getAll(InputsCanvasBreezeModel).pipe(
            map((canvases) => canvases.sort((c1, c2) => c2.canvasDate.getTime() - c1.canvasDate.getTime())),
        );
    }

    public createCanvasOfType(canvasType: CanvasType) {
        return this.commonDataService.create(InputsCanvasBreezeModel, {
            type: canvasType,
            organisationId: this.organisationId,
            canvasDate: new Date(),
        });
    }

    public createCompetitor(canvas: InputsCanvas) {
        return this.commonDataService.create(InputGroupBreezeModel, {
            canvas,
            type: InputGroupType.Competitor,
        });
    }

    public editInputGroup(inputGroup: InputGroup) {
        return this.dialogService.open(EditInputGroupDialogComponent, inputGroup);
    }

    public createInputOfType(inputType: InputType, canvas: InputsCanvas) {
        return this.commonDataService.create(InputBreezeModel, {
            type: inputType,
            canvas,
        });
    }

    public createInputLocationWithInput(input?: Input) {
        return this.commonDataService.create(InputLocationBreezeModel, {
            input,
            ordinal: 0,
            organisationId: this.organisationId,
        });
    }

    public editInput(input: Input) {
        return this.dialogService.open(EditInputDialogComponent, input);
    }

    public attachInput(canvasType: CanvasType, zone?: Zone, theme?: Theme) {
        return this.dialogService.open(AttachInputsDialogComponent, { canvasType, zone, theme } as IAttachInputsDialogData);
    }

    public getInputLocationsForZoneTheme(zone: Zone, theme?: Theme) {
        const predicate = new MethodologyPredicate<InputLocation>("zone", "==", zone);
        if (theme) {
            predicate.and(new MethodologyPredicate<InputLocation>("themeId", "==", theme.themeId));
        } else {
            predicate.and(new MethodologyPredicate<InputLocation>("themeId", "==", null));
        }
        return this.commonDataService.getByPredicate(InputLocationBreezeModel, predicate).pipe(
            map((inputLocations) => inputLocations.sort((l1, l2) => l1.ordinal - l2.ordinal)),
        );
    }

    public getInputLocationsForZone(zone: Zone) {
        const predicate = new MethodologyPredicate<InputLocation>("zone", "==", zone);
        return this.commonDataService.getByPredicate(InputLocationBreezeModel, predicate).pipe(
            map((inputLocations) => inputLocations.sort((l1, l2) => l1.ordinal - l2.ordinal)),
        );
    }

    public updateInputLocationOrdinal(inputLocation: InputLocation) {
        return this.getInputLocationsForZoneTheme(inputLocation.zone, inputLocation.theme).pipe(
            tap((inputLocations) => {
                const maxOrdinal = inputLocations
                    .filter((i) => i.inputLocationId !== inputLocation.inputLocationId)
                    .reduce((max, i) => i.ordinal > max ? i.ordinal : max, 0);
                inputLocation.ordinal = maxOrdinal + 1;
            }),
        );
    }

    public detachInputLocation(inputLocation: InputLocation) {
        const singularLabel = InputTypeMetadata[inputLocation.input.type].singularLabel;
        const dialogData: IConfirmationDialogData = {
            title: `Detaching ${singularLabel}...`,
            message: `<p>You are about to detach ${singularLabel} with name:</p>
                <blockquote><b>${inputLocation.input.name}</b></blockquote>
                <p>from the current location.</p>
                <p>Are you sure you want to continue?</p>`,
            confirmButtonText: "Confirm & Detach",
            cancelButtonText: "Cancel",
        };
        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            // don't have to delete input locations as InputRepositoryEntity will handle that and return deleted associated entities
            // in saveMap to clean up our breeze manager cache
            switchMap(() => this.remove(inputLocation)),
            switchMap(() => this.saveEntities(inputLocation)),
        );
    }
}

<adapt-confirmation-dialog (closed)="onClosed($event)"
                           (initialised)="onInitialised($event)">
    <div role="alert"
         class="alert alert-danger">
        Deactivating {{connection.person.fullName}}'s account will remove the ability for {{connection.person.firstName}} to
        log in to {{platformName}} as well as remove any assigned roles and access levels.
    </div>

    <div>
        <label>Start Date</label>
        <p>{{connection.startDate | adaptDate}}</p>
    </div>

    <div>
        <label>End Date</label>

        <div *ngIf="connection.endDate">
            <div *ngIf="!canEdit || connection.endDate">
                <p>{{connection.endDate | adaptDate}}</p>
            </div>
        </div>

        <div *ngIf="canEdit && !connection.endDate">
            <dx-date-box [disabled]="!!connection.endDate"
                         type="date"
                         [displayFormat]="displayFormat"
                         [min]="connection.startDate"
                         [max]="maxEndDate"
                         [value]="endDate"
                         (valueChange)="endDate = $any($event)"
                         [width]="200"></dx-date-box>
        </div>
    </div>
</adapt-confirmation-dialog>

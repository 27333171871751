import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { IEntityWithEventLocations } from "./entity-with-event-locations";
import { EventLocation } from "./event-location";
import { EventSeries } from "./event-series";
import { Organisation } from "./organisation";

export class Event extends BaseEntity<Event> implements IEntityWithEventLocations {
    public eventId!: number;
    public organisationId!: number;
    public eventSeriesId!: number;
    public label!: string;
    public sequenceId!: number;
    public startDate!: Date;
    public endDate!: Date;
    public location?: string;
    public calendarIntegrationId?: string;
    public calendarIntegrationLocationId?: string;

    public organisation!: Organisation;
    public eventSeries!: EventSeries;
    public eventLocations!: EventLocation[];
}

export const EventBreezeModel = new BreezeModelBuilder("Event", Event)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .alwaysFetchingNestedNavigationProperty("eventLocations.meeting")
    .build();

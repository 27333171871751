<div class="common-shell-component"
     [ngClass]="{'user-logged-out' : !userIsLoggedIn}">
    <header class="shell-header">
        <div class="shell-banner"
             *ngFor="let bannerSpec of banners"
             [ngClass]="bannerSpec.class">
            {{bannerSpec.text}}
            <button *ngIf="bannerSpec.buttonText && bannerSpec.buttonAction"
                    class="btn btn-secondary m-1 py-1 px-2"
                    (click)="bannerSpec.buttonAction()">
                {{bannerSpec.buttonText}}
            </button>

            <button *ngIf="bannerSpec.isDismissible"
                    type="button"
                    class="btn-close align-middle"
                    title="Dismiss banner"
                    aria-label="Close"
                    (click)="removeBanner(bannerSpec)">
            </button>
        </div>
    </header>
    <section id="content"
             class="shell-content">
        <adapt-common-sidebar id="common-sidebar-component"
                              class="shell-sidebar"
                              [ngClass]="extraSidebarClasses"
                              *ngIf="userIsLoggedIn && sidebarIsVisible">
        </adapt-common-sidebar>
        <div class="mainbar">
            <adapt-application-bar *ngIf="userIsLoggedIn"
                                   [options]="options.applicationBarOptions"></adapt-application-bar>
            <adapt-toolbar class="shell-toolbar"
                           *ngIf="userIsLoggedIn && toolbarIsVisible"></adapt-toolbar>
            <!-- Vertical scrollbar for any page will be triggered by the overflow of this element -->
            <!-- base-scroll-persisting.component.ts is going to search for '.mainview' to restore scroll position if that's to be persisted -->
            <!-- mainview style class is stored in shell-style.constants.ts -->
            <div class="mainview">
                <adapt-loading-spinner *ngIf="viewIsLoading"
                                       class="w-100 h-100"
                                       [largeSpinner]="true"></adapt-loading-spinner>

                <!-- Must do hidden to allow the view to specify when it has loaded -->
                <div class="shell-container"
                     [hidden]="viewIsLoading"
                     [ngClass]="{'shell-filter-hidden': !filterDisplayed}">
                    <div [ngClass]="containerClass">
                        <div class="h-100">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
                <adapt-filter class="shell-filter dx-swatch-dark"
                              [hidden]="viewIsLoading"
                              (isDisplayedChange)="filterDisplayedUpdater.next($event)"></adapt-filter>
            </div>
        </div>
        <adapt-context-sidebar></adapt-context-sidebar>
    </section>
    <dx-load-panel [shadingColor]="curtainShadingColor"
                   [position]="{ of: '#content' }"
                   [visible]="isSaving$ | async"
                   [showIndicator]="true"
                   [showPane]="false"
                   [shading]="true"
                   message=""
                   [hideOnOutsideClick]="false">
    </dx-load-panel>
    <adapt-dialog-root></adapt-dialog-root>
</div>

import { InjectionToken, Provider } from "@angular/core";
import { IAdaptRoute } from "./route/page-route-builder";

type AdaptRoute = IAdaptRoute<{}>;
type AdaptTeamRoute = IAdaptRoute<{ teamId: number }>;

export const TEAM_DASHBOARD_PAGE = new InjectionToken<AdaptTeamRoute>("TEAM_DASHBOARD_PAGE_TOKEN");
export function provideTeamDashboardPageRoute(route: AdaptTeamRoute): Provider {
    return {
        provide: TEAM_DASHBOARD_PAGE,
        useValue: route,
        multi: false,
    };
}

export const PERSONAL_DASHBOARD_PAGE = new InjectionToken<AdaptRoute>("PERSONAL_DASHBOARD_PAGE_TOKEN");
export function providePersonalDashboardPageRoute(route: AdaptRoute): Provider {
    return {
        provide: PERSONAL_DASHBOARD_PAGE,
        useValue: route,
        multi: false,
    };
}

export const ORGANISATION_DASHBOARD_PAGE = new InjectionToken<AdaptRoute>("ORGANISATION_DASHBOARD_PAGE_TOKEN");
export function provideOrganisationDashboardPageRoute(route: AdaptRoute): Provider {
    return {
        provide: ORGANISATION_DASHBOARD_PAGE,
        useValue: route,
        multi: false,
    };
}

export const NAVIGATE_TEAMS_PAGE = new InjectionToken<AdaptRoute>("NAVIGATE_TEAMS_PAGE_TOKEN");
export function provideNavigateTeamsPageRoute(route: AdaptRoute): Provider {
    return {
        provide: NAVIGATE_TEAMS_PAGE,
        useValue: route,
        multi: false,
    };
}

export const TEAM_GUIDANCE_PAGE = new InjectionToken<AdaptTeamRoute>("TEAM_GUIDANCE_PAGE_TOKEN");
export function provideTeamGuidancePageRoute(route: AdaptTeamRoute): Provider {
    return {
        provide: TEAM_GUIDANCE_PAGE,
        useValue: route,
        multi: false,
    };
}

export const TEAM_CONFIGURATION_PAGE = new InjectionToken<AdaptTeamRoute>("TEAM_CONFIGURATION_PAGE");
export function provideTeamConfigurationPageRoute(route: AdaptTeamRoute): Provider {
    return {
        provide: TEAM_CONFIGURATION_PAGE,
        useValue: route,
        multi: false,
    };
}

export const PERSONAL_CONFIGURATION_PAGE = new InjectionToken<AdaptRoute>("PERSONAL_CONFIGURATION_PAGE");
export function providePersonalConfigurationPageRoute(route: AdaptRoute): Provider {
    return {
        provide: PERSONAL_CONFIGURATION_PAGE,
        useValue: route,
        multi: false,
    };
}

export const ORGANISATION_CONFIGURATION_PAGE = new InjectionToken<AdaptRoute>("ORGANISATION_CONFIGURATION_PAGE");
export function provideOrganisationConfigurationPageRoute(route: AdaptRoute): Provider {
    return {
        provide: ORGANISATION_CONFIGURATION_PAGE,
        useValue: route,
        multi: false,
    };
}

export const MANAGE_ACCESS_PAGE = new InjectionToken<AdaptRoute>("MANAGE_ACCESS_PAGE");
export function provideManageAccessPageRoute(route: AdaptRoute): Provider {
    return {
        provide: MANAGE_ACCESS_PAGE,
        useValue: route,
        multi: false,
    };
}

export const ORGANISATION_PURPOSE_VISION_PAGE = new InjectionToken<AdaptRoute>("ORGANISATION_PURPOSE_VISION_PAGE_TOKEN");
export function provideOrganisationPurposeVisionPageRoute(route: AdaptRoute): Provider {
    return {
        provide: ORGANISATION_PURPOSE_VISION_PAGE,
        useValue: route,
        multi: false,
    };
}

export const ORGANISATION_VALUES_PAGE = new InjectionToken<AdaptRoute>("ORGANISATION_VALUES_PAGE_TOKEN");
export function provideOrganisationValuesPageRoute(route: AdaptRoute): Provider {
    return {
        provide: ORGANISATION_VALUES_PAGE,
        useValue: route,
        multi: false,
    };
}

export const TEAM_VALUES_PAGE = new InjectionToken<AdaptTeamRoute>("TEAM_VALUES_PAGE_TOKEN");
export function provideTeamValuesPageRoute(route: AdaptTeamRoute): Provider {
    return {
        provide: TEAM_VALUES_PAGE,
        useValue: route,
        multi: false,
    };
}

export const SUBSCRIBE_ORGANISATION_PAGE = new InjectionToken<AdaptRoute>("SUBSCRIBE_ORGANISATION_PAGE");
export function provideSubscribeOrganisationPageRoute(route: AdaptRoute): Provider {
    return {
        provide: SUBSCRIBE_ORGANISATION_PAGE,
        useValue: route,
        multi: false,
    };
}

export const PURPOSE_VISION_PAGE = new InjectionToken<AdaptRoute>("PURPOSE_VISION_PAGE");
export function providePurposeVisionPageRoute(route: AdaptRoute): Provider {
    return {
        provide: PURPOSE_VISION_PAGE,
        useValue: route,
        multi: false,
    };
}

export const KEY_FUNCTION_PAGE = new InjectionToken("KEY_FUNCTION_PAGE");
export function provideKeyFunctionPageRoute(route: IAdaptRoute<{}>): Provider {
    return {
        provide: KEY_FUNCTION_PAGE,
        useValue: route,
        multi: false,
    };
}

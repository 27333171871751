import { DefaultFinishButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export const StrategicThemesTour: GuidedTour = [{
    title: "Open the menu",
    text: `<p>This menu allows you to navigate between pages on the platform.
        Your strategic themes can be accessed from under the <b>Organisation</b> menu section.
        <p><b>Click on here now</b> to open the menu.</p>`,
    attachTo: {
        element: "#sidebar-menu i",
        on: "right",
    },
    advanceOn: {
        selector: "#sidebar-menu",
        event: "click",
    },
    showOn: () => {
        // this step will only be shown if Organisation Diagnostic node is not there
        const strategicThemesNode = GuidedTourUtils.getFirstElementWithTextContent(
            "adapt-display-link-hierarchy adapt-display-navigation-node span",
            "Strategy Board");
        return !GuidedTourUtils.isElementVisible(strategicThemesNode);
    },
}, {
    waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
    elementSelectorTextContent: "Strategy Board", // looking for <adapt-display-navigation-node> with span content of this text
    advanceOnAttachedElementEvent: "click",
    attachTo: {
        on: "bottom-end",
    },
    title: "Access strategic themes",
    text: `<p>Strategic themes are available on your strategy board.</p>
        <p><b>Click on it now</b> to show it.</p>`,
    modalOverlayOpeningPadding: 3,
}, {
    title: "Your strategy board",
    text: `<p>Your strategy board is a central spot to store important inputs into your strategy.</p>
        <p>The board is broken up into the four zones.</p><p>As you gather more data, you will add it to this board.</p>`,
    buttons: DefaultNextButtons,
}, {
    title: "Toggle theme descriptions",
    text: "<p>By default you just see the theme names. You can toggle the display of theme descriptions using this button</p><p><b>Click on it now</b> to show the theme descriptions.</p>",
    waitForAndAttachToElementSelector: `[data-tour="themes-descriptions"]`,
    advanceOnAttachedElementEvent: "click",
    attachTo: {
        on: "bottom",
    },
}, {
    title: "Edit strategic theme",
    text: "You can edit your strategic theme using this button.",
    canClickTarget: false,
    waitForAndAttachToElementSelector: `[data-tour="edit-theme"]`,
    attachTo: {
        on: "bottom",
    },
    buttons: DefaultNextButtons,
}, {
    title: "Continue",
    text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
    waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
    attachTo: {
        on: "right",
    },
    buttons: DefaultFinishButtons,
}];

<div class="d-flex flex-column flex-lg-row">
    <div class="d-flex flex-column guidance-column">
        <adapt-styled-implementation-kit [articleId]="stepData?.guidanceArticleSlug"
                                         contentStyle="embedded"></adapt-styled-implementation-kit>
    </div>
    <div class="d-flex flex-column flex-fill">
        <adapt-bullseye-view class="pb-1"
                             [quadrants]="quadrants"
                             (bullseyeStatementChange)="onBullseyeStatementChanged($event)"
                             [isEditing]="true"></adapt-bullseye-view>
    </div>
</div>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Zone } from "../methodology/zone";
import { Input } from "./input";
import { Organisation } from "./organisation";
import { Theme } from "./theme";

export class InputLocation extends BaseEntity<InputLocation> {
    public inputLocationId!: number;
    public inputId!: number;
    public zone!: Zone;
    public themeId?: number;
    public ordinal!: number;
    public organisationId!: number;

    public organisation!: Organisation;
    public input!: Input;
    public theme?: Theme;
}

export const InputLocationBreezeModel = new BreezeModelBuilder("InputLocation", InputLocation)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .alwaysFetchingNavigationProperty("input")
    .alwaysFetchingNavigationProperty("theme")
    .build();

import { Observable } from "rxjs";

// this should be kept in sync with $context-sidebar-width in scss
export const contextSidebarWidth = 380;

export enum ContextSidebarState {
    Closed,
    Popup,
    Open,
    Fullscreen,
}

export type SidebarOpenStates = Exclude<ContextSidebarState, ContextSidebarState.Closed>;

export enum ContextSidebarSource {
    Page = "page",
    Dialog = "dialog",
}

export type ContextSidebarSourceInput = Lowercase<keyof typeof ContextSidebarSource>

export interface ISidebarContent {
    content: HTMLElement;
    source: ContextSidebarSource;
    default?: boolean;
}

export interface IContextSidebar {
    contextSidebarState$: Observable<ContextSidebarState>;

    hasDialogContent: boolean;

    setIsOpen(isOpen: boolean, state?: ContextSidebarState): void;

    setContent(content: ISidebarContent["content"], source?: ContextSidebarSource): void;

    setTitle(title: ISidebarContent["content"], source?: ContextSidebarSource): void;

    setMaxWidth(width?: number): void;

    clearContent(source?: ContextSidebarSource): void;

    clearTitle(source?: ContextSidebarSource): void;
}